import { Box, BoxProps } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { BaseHeader } from '~src/components'

function FormArea(props: BoxProps) {
  return <Box flexGrow={1} overflow="auto" px={12} py={6} {...props} />
}

export const Wiz = {
  FormArea,
}

type PublishShellProps = { children: ReactNode; header: ReactNode }

export function PublishShell(props: PublishShellProps) {
  return (
    <BaseHeader.Container>
      {props.header}
      <BaseHeader.Body overflowX="hidden">{props.children}</BaseHeader.Body>
    </BaseHeader.Container>
  )
}
