import { BoxProps, Grid, GridProps } from '@chakra-ui/react'
import { times } from 'lodash'
import { Fragment, ReactNode } from 'react'
import { VSep } from '~src/components'

type EqColGrid = Omit<GridProps, 'children'> & {
  columns: ReactNode[]
  separatorProps?: BoxProps
}

export function EqColGrid(props: EqColGrid) {
  const { columns, separatorProps, ...rest } = props
  const templateColumns = times(columns.length, () => 'minmax(0,1fr)').join(
    ' auto '
  )

  const childArr = []
  columns.forEach((col, idx) => {
    if (idx !== 0) {
      childArr.push(<VSep key={`sep-${idx}`} boxProps={separatorProps} />)
    }
    childArr.push(<Fragment key={idx}>{col}</Fragment>)
  })

  return (
    <Grid
      flexGrow={1}
      gridColumnGap={8}
      gridTemplateColumns={templateColumns}
      gridTemplateRows="100%"
      height="100%"
      overflow="hidden"
      p="1px" // for outline
      {...rest}
    >
      {childArr}
    </Grid>
  )
}
