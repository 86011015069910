import { IcoDiscountIlluminate } from '@paper/icons'
import { useRouter } from '@paper/route'
import { MainMenuDrawer } from '~src/blocks/mainMenu/drawer'
import { AppTitle, BaseHeader } from '~src/components'
import { RD_IlluminateLookup } from '~src/routelist'
import { QLookup } from './qLookupShared'

export function IlluminateLookupPage() {
  const { dispatchStay, routeData } = useRouter<RD_IlluminateLookup>()

  return (
    <BaseHeader.Container>
      <AppTitle title="Illuminate answer key lookup" />
      <BaseHeader stackGap="1rem">
        <MainMenuDrawer icon={IcoDiscountIlluminate} />
        Illuminate answer key lookup
      </BaseHeader>
      <BaseHeader.Body px={12}>
        <QLookup
          onTitleSubmitted={(title) => dispatchStay({ title })}
          title={routeData.title}
        />
      </BaseHeader.Body>
    </BaseHeader.Container>
  )
}
