import { SchoolYear } from './school'

export type AuthRole = 'admin'

export type AuthDomain = {
  domain: string
}

/** WebApp user data */
export type WebappUser = {
  domain: string
  email: string
  familyName: string
  givenName: string
  name: string
  picture: string
  teacherId: string
  roles: AuthRole[]
  token: string
  uid: string
}

/** Subset of `WebAppUser` stored in mongo */
export type AuthUser = Omit<WebappUser, 'teacherId'>
/** Subset of `AuthUser` specified in the connector  */
export type ConnectorUser = Pick<AuthUser, 'email' | 'roles'>

export type LoginData = {
  user: WebappUser
  year: {
    load: SchoolYear
    pe: SchoolYear
    sw: SchoolYear
  }
  years: SchoolYear[]
}
