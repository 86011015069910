import { Modal, ModalOverlay } from '@chakra-ui/react'
import { Formik, FormikConfig } from 'formik'
import { ReactNode } from 'react'

export type PublicFormDialogProps<T> = {
  data: T
  isOpen: boolean
  onClose(): void
}

type FormDialogProps<T> = PublicFormDialogProps<T> &
  Pick<FormikConfig<T>, 'onSubmit' | 'validationSchema'> & {
    children: ReactNode
  }

// todo: this curries some default props, but not sure if it will be flexible enough
// todo: copy/pasted from CrossPacketDialog
export function FormDialog<T>(props: FormDialogProps<T>) {
  const { data, isOpen, onClose, children, ...formik } = props

  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isCentered={true}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay
        data-cy="form-dialog"
        // todo: better way to do this? otherwise the dialog scrolls when large, which isn't what we want
        sx={{ '.chakra-modal__content-container': { overflow: 'hidden' } }}
      >
        <Formik
          initialValues={data}
          onReset={() => onClose()}
          validateOnMount={true}
          {...formik}
        >
          {children}
        </Formik>
      </ModalOverlay>
    </Modal>
  )
}
