import type { AfterResponseHook } from 'ky'
import ky from 'ky-universal'

export type HTTPError = ky.HTTPError
export type Fetcher = typeof ky // Ky has no meaning, and not impossible we'll swap out.

export const getInternalUrl = (origin: string, path: string) => {
  // todo: with the GCP-east plan, local and non-local have different function paths
  // todo: for now, I'm passing those in concatenated in `origin`
  // todo: (this was previously: `${origin}/.netlify/functions/api/${path}`)
  return `${origin}/${path}`
}

// todo: The previous fetch library had no timeout
// todo: ...and some things are failing with ky's default
export const fetcher = ky.create({ timeout: false })

type CreateFetch = (props: {
  bearer?: string
  nonBearer?: string
  origin: string
  on401?(err: HTTPError): void
}) => Fetcher

export const createFetch: CreateFetch = ({
  bearer,
  nonBearer,
  on401,
  origin,
}) => {
  // Resolve authorization header
  const authorization = bearer ? `Bearer ${bearer}` : nonBearer

  // Special request handling
  const after: AfterResponseHook = async (request, options, response) => {
    if (response.status === 401 && on401) {
      on401?.(new ky.HTTPError(response))
      return response
    } else if (process.env.NODE_ENV === 'test' && response.status === 400) {
      // todo: Handy when headless fails
      console.log(await response.json())
    }
  }

  // Create instance
  return ky.create({
    headers: { authorization },
    hooks: { afterResponse: [after] },
    prefixUrl: getInternalUrl(origin, ''),
    timeout: false, // previous fetch library didn't have a default timeout
  })
}
