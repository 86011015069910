import { ButtonGroup, ListItem, UnorderedList } from '@chakra-ui/react'
import { PdfGrain, PdfSorter, PrintManifest } from '@paper/schema'
import { ReactNode } from 'react'
import { Ex, HStack, ToggleGroup, Txt, VSep } from '~src/components'

type PdfOpts = Pick<PrintManifest, 'grain' | 'sortedBy'> & {
  _sortedByDisplay?: PdfSorter[]
}

export const PdfOptionSets: { [grain in PdfGrain]?: PdfOpts } = {
  teacher: {
    grain: 'teacher',
    sortedBy: ['section', 'student'],
  },
  packet: {
    grain: 'packet',
    sortedBy: ['student', 'teacher', 'section'], // including all for unnamed
    _sortedByDisplay: ['student'], // but it'll be confusing if all are listed
  },
}

type PdfBuilderGrainPickerProps = {
  isDisabled: boolean
  onChange(grain: PdfGrain): void
  value: PdfGrain
}

export function PdfBuilderGrainPicker(props: PdfBuilderGrainPickerProps) {
  const { isDisabled, onChange, value } = props

  return (
    <HStack gap={4} py={1}>
      {/* todo: label properly */}
      <Txt>1 PDF per:</Txt>
      <ToggleGroup.Root
        colorScheme="blue"
        disabled={isDisabled}
        onChange={onChange}
        preventNone={true}
        type="single"
        value={value}
      >
        <ButtonGroup isAttached={true}>
          {Object.values(PdfOptionSets).map(({ grain }) => (
            <ToggleGroup.Button
              key={grain}
              value={grain}
              textTransform="capitalize"
            >
              {grain}
            </ToggleGroup.Button>
          ))}
        </ButtonGroup>
      </ToggleGroup.Root>
      <VSep boxProps={{ mx: 4 }} />
      <SortLabel grain={value} />
    </HStack>
  )
}

const SortLabels: Record<PdfSorter, ReactNode> = {
  section: <>Section name</>,
  student: (
    <>
      Student <Ex>last, first</Ex>
    </>
  ),
  teacher: (
    <>
      Teacher <Ex>last, first</Ex>
    </>
  ),
}

type SortLabelProps = { grain: PdfGrain }

function SortLabel(props: SortLabelProps) {
  const pdfOpts = PdfOptionSets[props.grain]
  const sortedBy = pdfOpts._sortedByDisplay ?? pdfOpts.sortedBy
  return (
    <>
      <Txt as="span">Sorted by:</Txt>
      <UnorderedList fontSize="xs" spacing={1}>
        {sortedBy.map((sorterKey) => (
          <ListItem key={sorterKey}>{SortLabels[sorterKey]}</ListItem>
        ))}
      </UnorderedList>
    </>
  )
}
