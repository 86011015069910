import { useMemo, useState } from 'react'
import { Filters, useFilters } from '~src/utils/useFilters'
import { ComboBoxProps } from './comboBox'

type ComboBoxItemsProps<T, V> = {
  items: T[]
  value: V
  selector?: (item: T, value: V) => boolean
  filterer: Filterer<T>
  limit?: number
}

type UseComboBoxItemsReturnValue<T> = {
  onInputValueChange: ComboBoxProps<T>['onInputValueChange']
  items: T[]
  selectedItem: T
}

/**
 * Handles producing and filtering `items` and `selectedItems`
 * `selector`, `filter`, and `limit` are assumed to be immutable
 * @example
 * const comboProps = useComboBoxItems({ ... })
 * return <ComboBox {...comboProps} />
 */
export function useComboBoxItems<T, V>(
  props: ComboBoxItemsProps<T, V>
): UseComboBoxItemsReturnValue<T> {
  // Get filters
  const filters = useFilters()

  const [inputValue, setInputValue] = useState<string>()
  const result = useMemo(() => {
    // console.time('useMemo(cb items)')
    const { selector, limit, value } = props
    let items = props.items ?? []
    // selectedItem is picked before filtering (e.g. we don't unselect just because someone has started typing)
    const selectedItem = !props.selector
      ? null
      : items.find((item) => selector(item, value)) ?? null
    // then filter based on inputValue
    if (inputValue?.trim()) {
      items = items.filter((item) => props.filterer(filters, item, inputValue))
    }
    // todo: virtualization instead?
    if (limit) {
      items = items.slice(0, limit)
    }
    //console.timeEnd('useMemo(cb items)')
    return { items, selectedItem }
  }, [inputValue, props.items, props.value])

  return {
    ...result,
    onInputValueChange: (changes) => setInputValue(changes.inputValue),
  }
}

///////////////////////
// Filters
///////////////////////

// react-aria providers a hook wrapper for filters
// but makes for a bit of an awkward api
export type Filterer<T = any> = (
  filters: Filters,
  item: T,
  inputValue: string
) => boolean
