import { z } from '@paper/schema/validation'
import { YearCodeRegex } from '@paper/utils'

export const z_year = z.object({
  // todo: things I want:
  // * strip extra values
  // * ?? invalid values - seems like it would be nice to include that next to routeData as a warning
  // * BUT, don't want the app to crash
  yearCode: z
    .string()
    .regex(YearCodeRegex, `School year must be YY-YY`)
    .catch((ctx) => ''),
})
