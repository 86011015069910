import { defineApi } from '../defineApi'

export type SignedUrls = { [key: string]: string }

/**
 * Sign cloudfront urls
 */
export const signUrls = defineApi<{
  method: 'post'
  body: string[]
  result: SignedUrls
}>({
  method: 'post',
  urlFactory: `signurls`,
})
