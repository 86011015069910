import { ENDASH } from '@paper/utils'
import { HStack, Txt, VSep, VStack } from '~src/components'
import { LoadingDots } from '~src/components/status'
import { intlFormatDateTime } from '~src/utils/messages'
import { SupportMailTo } from '../miSupport'
import { LoaderSchedArea } from './loaderSchedArea'
import { ScanStatusArea } from './scanDelayArea'
import { SystemStatusIndicator } from './systemStatusIndicator'
import { useSystemStatus } from './systemStatusProvider'

type SystemStatusMenuAreaProps = {}

export function SystemStatusMenuArea(props: SystemStatusMenuAreaProps) {
  const { isOnLine, qResult } = useSystemStatus()
  if (qResult.isLoading) {
    return (
      <VStack alignSelf="stretch">
        <LoadingDots />
      </VStack>
    )
  }

  const fetchedAt = qResult.isSuccess ? (
    <Txt fontSize="sm">
      {ENDASH}{' '}
      <Txt as="span" fontSize="xs">
        as of
      </Txt>{' '}
      {intlFormatDateTime(qResult.data.fetchedAt)}
    </Txt>
  ) : null

  const details = qResult.isSuccess ? (
    <HStack alignItems="start" alignSelf="stretch" gap={2}>
      <ScanStatusArea />
      <VSep />
      <LoaderSchedArea />
    </HStack>
  ) : !isOnLine ? (
    <HStack whiteSpace="nowrap">You are currently offline</HStack>
  ) : (
    <HStack whiteSpace="nowrap">
      We couldn't load the system status. If refreshing doesn't help
      <SupportMailTo flexShrink={0} leftIcon={null} mx={1} px={2}>
        Contact Support
      </SupportMailTo>
    </HStack>
  )

  return (
    <VStack alignItems="stretch" alignSelf="stretch" gap={3} py={3}>
      <HStack data-cy="system-status-menu-header" gap={1.5}>
        <SystemStatusIndicator ifNormal="nothing" size="3xl" type="system" />
        <HStack alignItems="baseline" flexShrink={0} fontSize="lg" gap={1.5}>
          <Txt>System data status</Txt>
          {fetchedAt}
        </HStack>
      </HStack>
      {details}
    </VStack>
  )
}
