import { fetcher } from '@paper/utils'

/**
 * Load pdf bytes and PDFDocument from url
 */
export async function loadPdf(url: string) {
  const { PDFWrap } = await import('@paper/pdf')
  const srcBuf = await fetcher.get(url).arrayBuffer()
  const srcDoc = await PDFWrap.load(srcBuf)
  return { srcBuf, srcDoc }
}
