export const NumericCollator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base',
})

type Predicate<T> = (item: T) => any
type OrArrayOf<T> = T | T[]

/**
 * Sorts `arr` **in place** with `NumericCollator`
 * @example
 * sortNumeric(arr, student => student.lastfirst)
 * sortNumeric(arr, [
 *  sns => getLastFirst(sns.section.teacher),
 *  sns => sns.section.name
 * ])
 */
export function sortNumeric<T>(arr: T[], pred: OrArrayOf<Predicate<T>>) {
  let cmps = Array.isArray(pred) ? pred : [pred]

  // todo: not currently precalculating p(item)
  // todo: should probably switch to lodash to remedy (they support custom compare functions) see below
  // todo: theoretical downside to precalc if predicates [pCheap, pExpensive], and pExpensive is rarely needed
  // todo: will need to be careful on switch since orderBy is not in place

  /**
   * @example
   * // Sort by `user` then by `age` using custom compare functions for each
   * orderBy(users, ['user', 'age'], [
   *   (a, b) => a.localeCompare(b, 'de', { sensitivity: 'base' }),
   *   (a, b) => a - b,
   * ])
   */
  return arr.sort((a, b) => {
    let cmp: number
    // run comparisions until different or we run out
    for (let p of cmps) {
      let pa = p(a)
      let pb = p(b)

      // todo: it appears to be treating `undefined` as a string...
      if (pa == null && pb == null) {
        cmp = 0
      } else if (pa == null || pb == null) {
        cmp = pa == null ? 1 : -1
      } else {
        cmp = NumericCollator.compare(p(a), p(b))
      }

      if (cmp !== 0) {
        return cmp
      }
    }
    return cmp
  })
}

export const startsWithInsensitive = (value: string, query = '') => {
  return value?.toLowerCase().startsWith(query.toLowerCase())
}

export const includesInsensitive = (value: string, query = '') => {
  return value?.toLowerCase().includes(query.toLowerCase())
}
