import { LastUpdate } from './packetmeta'
import { PdfStyle } from './pdf'
import { Student, Teacher } from './school'
import { ScanStatus } from './types.imgp'
import { DirPacket } from './types.packet'
import { Xpacket } from './types.xpacket'

type ScanFixCandidateStatus = 'blank' | 'fixed' | 'missing' | 'success'

export type ScanFixCandidatePage = {
  by: LastUpdate
  course: string
  curriculumId: string
  packet: Packetino
  /** index within the packet (as opposed to the scanbatch) */
  packetIndex: number
  qrb: string
  status: ScanFixCandidateStatus
  student: Student
  xpacketId: string
  xpageId: string
}

/**
 * Baby packet for the scanlog
 */
export type Packetino = Pick<DirPacket, 'id' | 'name' | 'number' | 'pages'> & {
  style?: PdfStyle
}

/**
 * Set of xpackets for scan log
 */
export type XpacketSet = {
  course: string
  curriculumId: string
  packet: Packetino
  teacher: Teacher
  xpackets: Xpacket[]
}

export type ImagesThat = ScanStatus | 'unfinished'
