import { Vector2 } from '@use-gesture/react'
import { MutableRefObject, useRef } from 'react'
import { pctVectorToOrigin } from '~src/blocks/imageViewer/useDragTransform'

export type PanEntangler = MutableRefObject<UghPan>

/**
 * @deprecated this is a huge mess!
 */
export const usePanEntangler = (mode: UghPanMode) => {
  // rerendering this context on every pan is kind of expensive
  // but want the images to pan together
  const panEntangler = useRef<UghPan>()
  if (!panEntangler.current) {
    panEntangler.current = new UghPan([0, 0], mode)
  }

  return panEntangler
}

type UghPanMode = 'contain' | 'cover'

type UghPanArg = MutableRefObject<HTMLElement>
/**
 * How to actually do this?
 * I want to entangle the image pan, but rerendering the entire tree on each pan event could be sluggish
 * Could put it further down the tree, but still inconvenient...
 */
class UghPan {
  #entangled: Set<UghPanArg>
  #mode: UghPanMode
  #origin: Vector2

  constructor(origin: Vector2, mode: UghPanMode) {
    this.#entangled = new Set()
    this.#mode = mode // todo: need to entangle this with other props!
    this.#origin = origin
  }

  onPan(delta: Vector2) {
    this.setOrigin(this.#origin.map((p, idx) => p - delta[idx]) as Vector2)
  }

  setOrigin(origin: Vector2) {
    this.#origin = origin
    this.#entangled.forEach((ref) => this.#updateRef(ref))
  }

  register(ref: UghPanArg) {
    if (ref) {
      this.#entangled.add(ref)
      this.#updateRef(ref)
    } else {
      console.warn('ref passed to `UghPan.register` is null')
    }
  }

  unregister(ref: UghPanArg) {
    this.#entangled.delete(ref)
  }

  #updateRef(ref: UghPanArg) {
    if (this.#mode === 'contain') {
      ref.current.style.transformOrigin = pctVectorToOrigin(this.#origin)
    } else {
      // todo: this obviously precludes other transforms...
      const translation = this.#origin.map((c) => `${-c}%`).join(',')
      ref.current.style.transform = `translate(${translation})`
    }
  }
}
