import { Icon, IconProps, ListIcon, Tooltip } from '@chakra-ui/react'
import { IcoCheckmark, IcoDiffMatch, IcoDiffMismatch, IcoX } from '@paper/icons'
import { DiffStatus } from '@paper/schema'

type MatchIconProps = {
  Component?: typeof Icon | typeof ListIcon
  status: DiffStatus
  withTooltip?: boolean
}

export function MatchIcon(props: MatchIconProps) {
  const { Component = Icon, status, withTooltip } = props

  let as: any
  let color: IconProps['color']
  let label: string
  let zIndex = 1800 // todo:

  switch (status) {
    case 'doesntMatch':
      as = IcoDiffMismatch
      color = 'red.600'
      label = `Doesn't match`
      break
    case 'match':
      as = IcoDiffMatch
      label = 'Matches'
      break
    case 'ref':
      as = IcoDiffMatch
      label = 'Reference value'
      break
  }

  let result = <Component aria-label={label} as={as} color={color} />

  if (withTooltip) {
    result = (
      <Tooltip label={label} zIndex={zIndex}>
        {result}
      </Tooltip>
    )
  }

  return result
}

// todo: uncopy/paste with MatchIcon
type ValidationIconProps = {
  Component?: typeof Icon | typeof ListIcon
  isValid: boolean
  withTooltip?: boolean
}

export function ValidationIcon(props: ValidationIconProps) {
  const { Component = Icon, isValid, withTooltip } = props

  let as: any
  let color: IconProps['color']
  let label: string
  let zIndex = 1800 // todo:

  if (isValid) {
    as = IcoCheckmark
    label = 'Valid'
  } else {
    as = IcoX
    color = 'red.600'
    label = 'Invalid'
  }

  let result = <Component aria-label={label} as={as} color={color} />

  if (withTooltip) {
    result = (
      <Tooltip label={label} zIndex={zIndex}>
        {result}
      </Tooltip>
    )
  }

  return result
}
