import { Tag, TagProps } from '@chakra-ui/react'
import {
  IcoEdit,
  IcoHomeSchool,
  IcoList,
  IcoMultiparter,
  IcoPacket,
  IcoPin,
  IcoRuGood,
  IcoTimeGrid,
} from '@paper/icons'
import { Curriculum, DirPacket, School, Teacher } from '@paper/schema'
import { getFullName } from '@paper/utils'
import { ReactNode } from 'react'
import { HStack, TextStack, Txt, VStack } from '~src/components'
import { Routes } from '~src/routelist'
import { IcoQ, IcoStd, IcoTeacher } from '../swMenu/swMenuIcons'
import {
  MonsterBoxAction,
  MonsterBoxActionState,
  NavAction,
} from './monsterState'

export type ColorScheme = TagProps['colorScheme']

type DirTeacher = Teacher & { families?: string }

type DirItemMap = {
  curriculum: Curriculum
  packet: DirPacket
  school: School
  teacher: DirTeacher
}

type DirItemKey = keyof DirItemMap

type DirAdapter<K extends DirItemKey = any> = {
  colorScheme: ColorScheme
  getActions(data: DirItemMap[K], state: MonsterBoxActionState): NavAction[]
  getText(data: DirItemMap[K]): string
  icon: any
  renderData?(data: DirItemMap[K]): ReactNode
  toTerms(data: DirItemMap[K]): string[]
  type: K
}

export type DirItem<K extends DirItemKey = any> = {
  adapter: DirAdapter<K>
  data: K extends DirItemKey ? DirItemMap[K] : Curriculum | School | Teacher
  termsA: string[]
  termsB: string[]
}

export function toDirItem<K extends DirItemKey>(
  adapter: DirAdapter<K>,
  data: DirItemMap[K],
  termsB: string[] = []
): DirItem<K> {
  return {
    adapter,
    data: data as any,
    termsA: adapter.toTerms(data),
    termsB,
  }
}

export const DirAdapters: { [key in DirItemKey]: DirAdapter<key> } = {
  curriculum: {
    colorScheme: 'red',
    getActions: (data, state) => {
      const { mode } = state
      const curriculumId = data.id
      const setCurriculumAction: MonsterBoxAction = {
        type: 'setCurriculum',
        curriculum: data,
      }

      const aSpecficPacket = {
        action: setCurriculumAction,
        icon: IcoPacket,
        label: 'A specific packet',
      }

      switch (mode) {
        case 'teacher-no-curriculm':
          const teacherId = state.teacher.id
          return [
            aSpecficPacket,
            {
              action: Routes.sw_time.navigateAction({
                curriculumId,
                teacherId,
              }),
              icon: IcoTimeGrid,
              label: 'Time',
            },
          ]
        case 'dir-base':
          return [
            {
              action: Routes.home_curriculum.navigateAction({ curriculumId }),
              icon: IcoList,
              label: 'Packet list',
            },
            aSpecficPacket,
          ]
      }
    },
    getText: (item) => item.name,
    icon: IcoMultiparter, // todo: need a curriculum icon
    toTerms: (data) => data.name.split(/\s+/),
    type: 'curriculum',
  },
  packet: {
    colorScheme: 'teal',
    getActions: (data, state) => {
      const contentId = data.contentId
      const curriculumId = state.curriculum.id
      const packetId = data.id
      const teacherId = state.teacher?.id
      const hasScans = !!data.scan?.count
      const hasStds = !!data.stds?.length

      let result: NavAction[] = []

      if (data.type === 'assessment') {
        if (hasScans) {
          result.push({
            action: Routes.sw_jumpToQ.navigateAction({
              curriculumId,
              packetId,
              teacherId,
            }),
            icon: IcoQ,
            iconOnly: true,
            label: 'Jump to Question',
          })

          if (hasStds) {
            result.push({
              action: Routes.sw_jumpToStd.navigateAction({
                curriculumId,
                packetId,
                teacherId,
              }),
              icon: IcoStd,
              iconOnly: true,
              label: 'Jump to Standard',
            })
          }
        }

        result.push({
          action: Routes.crossNetwork.navigateAction({ contentId }),
          icon: IcoPin,
          label: 'Cross-network',
        })
      } else {
        if (data._isTicketPilot) {
          result.push({
            action: Routes.sw_rubric.navigateAction({
              curriculumId,
              packetId,
              teacherId,
            }),
            icon: IcoRuGood,
            iconOnly: true,
            label: 'Rubric review',
          })
        }
        if (hasScans) {
          result.push({
            action: Routes.sw_time.navigateAction({
              curriculumId,
              packetId,
              teacherId,
            }),
            icon: IcoTimeGrid,
            iconOnly: true,
            label: 'Time grid',
          })
        }
      }

      result.push({
        action: Routes.publish.navigateAction({
          contentIdOrNew: data.contentId,
          curriculumId: data.curriculumId,
          packetId: data.id,
        }),
        icon: IcoEdit,
        label: 'Publish',
      })

      return result
    },
    getText: (item) => item.name,
    icon: null,
    renderData: (data) => (
      <HStack fontSize="xs" gap={2} py={1}>
        <Tag
          // todo: do i have ticket and packet colors?
          colorScheme={data.type === 'assessment' ? 'teal' : 'pink'}
          flexShrink={0}
          fontFamily="mono"
          size="sm"
          variant="solid"
          whiteSpace="nowrap"
        >
          {data.number}
        </Tag>
        <Txt fontSize="sm">{data.name}</Txt>
      </HStack>
    ),
    toTerms: (data) => [data.number, ...data.name.split(/\s+/)],
    type: 'packet',
  },
  school: {
    colorScheme: 'yellow',
    getActions: (data) => [
      {
        action: Routes.home_school.navigateAction({ schoolId: data.id }),
        icon: IcoList,
        label: 'Packet list',
      },
      // todo: would be nice to allow specific packet here
    ],
    getText: (item) => item.name,
    icon: IcoHomeSchool,
    toTerms: (data) => [data.id, ...data.name.split(/\s+/)],
    type: 'school',
  },
  teacher: {
    colorScheme: 'blue',
    getActions: (data, state) => {
      const { mode } = state
      const teacherId = data.id
      if (mode === 'dir-base') {
        return [
          {
            action: Routes.home_teacher.navigateAction({ teacherId }),
            icon: IcoList,
            label: 'Packet list',
          },
          {
            action: { type: 'setTeacher', teacher: data },
            icon: IcoPacket,
            label: 'A specific packet',
          },
        ]
      }
    },
    getText: getFullName,
    icon: IcoTeacher,
    renderData: (data) => (
      <VStack alignItems="stretch" py={2} overflow="hidden">
        <Txt fontSize="md">{getFullName(data)}</Txt>
        <TextStack.Bottom opacity={0.5} variant="compact">
          {data.email.split('@')[1]}
        </TextStack.Bottom>
        <TextStack.Bottom opacity={0.5} variant="loose">
          {data.families}
        </TextStack.Bottom>
      </VStack>
    ),
    toTerms: (data) => [data.firstName, data.lastName],
    type: 'teacher',
  },
}
