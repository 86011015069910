import {
  LoadJobType,
  LoadSchedule,
  LoadStatus,
  ScanBatchForStatus,
  SchoolYear,
} from '@paper/schema'
import { defineApi } from '../defineApi'

export const system_status_get = defineApi<{
  method: 'post'
  body: {}
  result: {
    fetchedAt: number
    load: {
      jobs: Record<LoadJobType, LoadStatus>
      loadSched: LoadSchedule[]
      loadYear: SchoolYear
      recentRows: { date: number; sched: boolean }[]
    }
    scanbatch: {
      lastN: ScanBatchForStatus[]
      N: number
    }
  }
}>({ method: 'post', urlFactory: `system/status` })
