import { Tooltip } from '@chakra-ui/react'
import type { DirPacket } from '@paper/schema'
import { getPartLengths, upToEven } from '@paper/utils'
import { Fragment, ReactNode } from 'react'
import { Txt } from '~src/components'
import { pluralS } from '~src/utils/messages'

type PageCountProps = { packet: DirPacket; variant: 'compact' | 'long' }

export function PageCounts(props: PageCountProps) {
  const { packet, variant } = props
  // part page counts
  const ppcs = getPartLengths(packet)

  return (
    <Txt as="span">
      {ppcs.map((length, idx) => {
        const evened = upToEven(length)
        const emptyAdded = evened !== length

        let label: ReactNode = evened
        // if (variant === 'long') {
        //   label += ` ${pluralS('page', length)}`
        // }
        if (emptyAdded) {
          label = (
            <Tooltip
              label={`This ${
                ppcs.length > 1 ? 'part' : 'packet'
              } has ${length} pages, but we add a trailing empty page to the multi-student PDF for printing double-sided.`}
              placement="right"
            >
              <Txt as="span">
                {label}
                <Txt as="span">*</Txt>
              </Txt>
            </Tooltip>
          )
        }

        return (
          <Fragment key={idx}>
            {idx !== 0 && (variant === 'compact' ? '/' : ' / ')}
            {label}
          </Fragment>
        )
      })}
      {variant === 'long' && ` ${pluralS('page', ppcs.at(-1))}`}
    </Txt>
  )
}
