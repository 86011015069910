import { ReactNode } from 'react'
import { HSep } from '~src/components'

type MenuModuleBaseProps = { children: ReactNode }

export function MenuModuleBase(props: MenuModuleBaseProps) {
  return (
    <>
      {props.children}
      <HSep />
    </>
  )
}
