import type { Teacher } from './school'
import type { TeacherSection } from './teacher'

/**
 * Status of the Paper and SIS sections
 */
export type SetupSectionStatus =
  | 'both-clean'
  | 'both-but-teacher-mismatch'
  | 'paper-only'
  | 'sis-only'

export type SetupSection = {
  id: string
  isEnabled: boolean
  teacherId: string
  /** info about the initial state */
  meta: {
    leadTeacher: Teacher
    section: TeacherSection
    status: SetupSectionStatus
  }
}

export type CourseFilterStatus = 'all' | 'none' | 'partial' | 'pending'

export type SetupCourse = {
  curriculumId: string
  hasPrinted: boolean
  id: string
  name: string
  sections: SetupSection[]
  stats: {
    deleted: number
    dirty: number
    exists: number
    paper: number
  }
  status: CourseFilterStatus
}
