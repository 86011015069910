import { Dispatch, SetStateAction, useCallback, useRef, useState } from 'react'

/**
 * Intended for passing a value to memoized callback without rerendering
 *
 * Not 100% this is correct
 * @example
 * const [state, setState, ref] = useStateAndRef(value)
 * // for example if we're rendering 100 items in a list
 * const onClickForExpensiveRender = useCallback(() => {
 *  doSomethingWith(ref.value)
 * }, [])
 */
export function useStateAndRef<S>(initialValue?: S | (() => S)) {
  const [state, _setState] = useState(initialValue)
  // Initialize ref to initial state, hopefully actually fix #61
  const ref = useRef<S>(state)

  if (state !== ref.current) {
    console.warn('[useStateAndRef] out-of-sync', state, ref.current)
    // if (process.env.NODE_ENV !== 'production') {
    //   throw Error('useStateAndRef issue')
    // }
  }

  // todo: are there any other ways that the state can change?
  // todo: i was previously doing this with useLayoutEffect, but that was creating race conditions
  const setState = useCallback<Dispatch<SetStateAction<S>>>(
    (nextOrFn) => {
      if (typeof nextOrFn === 'function') {
        _setState((old) => {
          // @ts-expect-error todo: typescript?
          let next = nextOrFn(old)
          ref.current = next
          return next
        })
      } else {
        ref.current = nextOrFn
        _setState(nextOrFn)
      }
    },
    [_setState]
  )

  return [state, setState, ref] as const
}
