import { List } from '@chakra-ui/react'
import { IcoAdd, IcoSecretDoor } from '@paper/icons'
import { useRouter } from '@paper/route'
import {
  NavigationMenu,
  NavigationMenuList,
} from '@radix-ui/react-navigation-menu'
import { HStack, Txt, VStack } from '~src/components'
import { usePublishContext } from '~src/pages/publish/publishProvider'
import { useGoToNew } from '~src/pages/publish/utils'
import { Routes } from '~src/routelist'
import { NavListSection } from '~src/routelist/navLinks'
import { NavLink, RouterNavLink } from '../menu/navLink'
import { useUser } from '../userProvider'
import { MenuModuleBase } from './mmBase'

type PublishMenuModuleProps = {}

const leftWidth = '300px'

export function PublishMenuModule(props: PublishMenuModuleProps) {
  const { currentRoute } = useRouter()

  if (currentRoute !== Routes.publish) {
    return null
  }
  return <PublishGuts />
}

function PublishGuts() {
  const { isInternal } = useUser()
  const { contentId, packetData } = usePublishContext()
  const goToNew = useGoToNew()

  const focusPacket = packetData?.focusPacket

  if (!focusPacket) {
    return null
  }

  const leftPane = (
    <NavigationMenu orientation="vertical">
      <NavigationMenuList asChild>
        <List p={1} width={leftWidth}>
          {goToNew && (
            <NavListSection>
              <NavLink icon={<IcoAdd />} onSelect={() => goToNew()}>
                Add a new packet
              </NavLink>
            </NavListSection>
          )}
          {isInternal && (
            <NavListSection noBorder={true} title="Internal">
              {isInternal && (
                // todo:  copy/paste with sw
                <RouterNavLink
                  icon={<IcoSecretDoor />}
                  route={Routes.internal_packetfix}
                  data={{ contentId }}
                >
                  Secret packet fixer
                </RouterNavLink>
              )}
            </NavListSection>
          )}
        </List>
      </NavigationMenuList>
    </NavigationMenu>
  )

  // todo: layout copy/paste from mmStudentWork
  const todoMaybeGrid = 4
  return (
    <MenuModuleBase>
      <VStack alignItems="stretch" gap={2} p={3}>
        <HStack alignItems="stretch" gap={todoMaybeGrid} minHeight="52px">
          <VStack
            alignItems="stretch"
            minWidth={`calc(${leftWidth} - ${todoMaybeGrid / 4}rem)`}
            ml={2} // todo: match NavListSection
          >
            <Txt fontSize="xs">Selected packet</Txt>
            <Txt fontSize="xl">{focusPacket.content.name}</Txt>
          </VStack>
        </HStack>
        <HStack alignItems="stretch">{leftPane}</HStack>
      </VStack>
    </MenuModuleBase>
  )
}
