import { Box, BoxProps } from '@chakra-ui/react'
import { forwardRef } from 'react'

// todo: maybe just use flexDirection (i think i was hoping for brevity)
export type StackProps = { type?: 'h' | 'v' } & BoxProps

const makeStack = (
  defaultType?: StackProps['type'],
  defaultAlignItems?: BoxProps['alignItems']
) => {
  return forwardRef<HTMLDivElement, StackProps>(function Stack(props, ref) {
    const { type = defaultType, ...rest } = props
    return (
      <Box
        alignItems={defaultAlignItems ?? 'center'}
        flexDirection={type === 'v' ? 'column' : 'row'}
        display="flex"
        ref={ref}
        {...rest}
      />
    )
  })
}

/**
 * @deprecated todo: probably not really...still deciding on HStack/VStack
 * note: chakra now has support for gap (good for flex-wrap), though gap doesn't support negative values like margin...
 * todo: it's possible the chakra's built-in stack is good enough now
 * Default: alignItems="center"
 */
export const Stack = makeStack()
/**
 * note: chakra now has support for gap (good for flex-wrap), though gap doesn't support negative values like margin...
 * todo: it's possible the chakra's built-in stack is good enough now
 * Default: alignItems="center"
 */
export const HStack = makeStack('h')
/**
 * note: chakra now has support for gap (good for flex-wrap), though gap doesn't support negative values like margin...
 * todo: it's possible the chakra's built-in stack is good enough now
 * Default: alignItems="center"
 */
export const VStack = makeStack('v')
/**
 * @deprecated This is dumb, not sure what to make defaults...
 * todo: It's a bit confusing that VStack and HStack change default to center...
 * todo: maybe change them to VCStack, and HCStack?
 * todo: or maybe don't change the default and just add alignItems="center" when needed?
 * note: chakra now has support for gap (good for flex-wrap), though gap doesn't support negative values like margin...
 * todo: it's possible the chakra's built-in stack is good enough now
 * Default: alignItems="stretch"
 */
export const VStackStretch = makeStack('v', 'stretch')
