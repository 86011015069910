import {
  Box,
  BoxProps,
  Heading,
  Link,
  List,
  ListItem,
  Tag,
} from '@chakra-ui/react'
import { IcoAdd } from '@paper/icons'
import { SetupCourse } from '@paper/schema'
import { chopOffLast, sortedLevenshteinDistance } from '@paper/utils'
import { useFormikContext } from 'formik'
import { orderBy } from 'lodash'
import { ReactNode, useMemo, useState } from 'react'
import { EqColGrid } from '~src/blocks/grid'
import { ButtonedListItem } from '~src/blocks/listChakra'
import {
  ComboBox,
  HStack,
  Italic,
  Txt,
  useComboBoxItems,
  VStack,
} from '~src/components'
import { getSupportMailTo } from '~src/utils/contactSupport'
import { useSchoolSetupContext } from '../schoolSetupContext'

export function CurriculaForm() {
  const { setFieldValue, values, errors } = useFormikContext<SetupCourse>()
  const [familyEd, setFamilyEd] = useState('')

  const { activeFamilyEdMap, curriculumMap } = useSchoolSetupContext()

  // Sorted list of available familyEds for curriculum picker
  const familyEds = useMemo(() => {
    let familyEds = Array.from(activeFamilyEdMap.keys())
    familyEds = orderBy(familyEds, [
      // sort by distance
      (famEd) =>
        sortedLevenshteinDistance(chopOffLast(famEd, ','), values.name),
      // then name
      (famEd) => famEd,
    ])
    return familyEds
  }, [activeFamilyEdMap, values.name])

  // List of available variants for selected familyEd
  let variants = useMemo(() => {
    let variants =
      activeFamilyEdMap
        .get(familyEd)
        ?.filter((c) => c.id !== values.curriculumId) ?? []
    variants = orderBy(variants, (c) => c.variant)
    return variants
  }, [activeFamilyEdMap, familyEd, values.curriculumId])

  const headingSpacing = 4
  const headingProps: BoxProps = {
    fontSize: 'md',
    fontWeight: 300,
    lineHeight: 1.2,
    userSelect: 'none',
  }

  type ListWithHeadingProps<T> = {
    headingProps?: BoxProps
    heading: ReactNode
    items: T[]
    empty?: ReactNode
    footer?: ReactNode
    opacity?: number
    renderItem(item: T, idx: number): ReactNode
  }

  function makeListWithHeading<T>(props: ListWithHeadingProps<T>) {
    const { heading, footer, empty, items, opacity, renderItem } = props
    return (
      <VStack
        alignItems="stretch"
        gap={headingSpacing}
        opacity={opacity}
        overflow="hidden"
      >
        <Heading as="h4" {...headingProps} {...props.headingProps}>
          {heading}
        </Heading>
        <List fontFamily="mono" overflowY="auto" spacing={3}>
          {items.length ? items.map(renderItem) : empty}
        </List>
        {footer}
      </VStack>
    )
  }

  const selectedCol = makeListWithHeading({
    heading: `Curriculum`,
    headingProps: { fontSize: 'xl' }, // make this heading bigger
    empty: (
      <Txt
        color={errors.curriculumId ? 'red.500' : null}
        fontFamily="body"
        fontSize="sm"
      >
        {errors.curriculumId ?? (
          <>Choose a curriculum for this course via the steps on the right.</>
        )}
      </Txt>
    ),
    footer: values.curriculumId && (
      <Txt fontSize="sm" opacity={0.8} mt={1}>
        {values.hasPrinted ? (
          <>
            This course has already generated packets for printing, so you can't
            change its curriculum here. If you need to change it, please{' '}
            <Link
              color="teal.500"
              fontWeight={400}
              href={getSupportMailTo({
                subject: `Change curriculum for "${values.name}" - ${values.sections?.[0].meta.section.schoolName}`,
                type: 'not-an-error',
              })}
            >
              contact support
            </Link>
            .
          </>
        ) : (
          <>
            This course hasn't generated packets for printing yet, so you can
            change its curriculum by selecting a different one on the right.
          </>
        )}
      </Txt>
    ),
    items: values.curriculumId ? [values.curriculumId] : [],
    renderItem: (id) => (
      <ListItem key={id}>
        {curriculumMap.get(id)?.name ?? (
          // todo: not expecting this, but should report this
          <Italic color="red.400">Error: {id}</Italic>
        )}
      </ListItem>
    ),
  })

  const comboProps = useComboBoxItems({
    items: familyEds,
    value: familyEd,
    filterer: (filters, item, inputValue) =>
      filters.startsWithByWord(item, inputValue),
  })

  const noSquishProps: BoxProps = {
    flexShrink: 0,
    whiteSpace: 'nowrap',
  }

  const familyCol = (
    <ComboBox.Root
      {...comboProps}
      isOpen={true}
      itemToString={(item) => item}
      onChange={(item) => setFamilyEd(item)}
      renderItem={(item) => {
        const { ed, family, levels } = activeFamilyEdMap.get(item)[0]
        return (
          <HStack gap={2}>
            <Tag {...noSquishProps} fontWeight={300} size="sm">
              {ed}
            </Tag>
            {family}
            {levels.map((l) => (
              <HStack gap={1} key={l} ml="auto">
                <Tag
                  {...noSquishProps}
                  fontFamily="mono"
                  fontWeight={300}
                  size="sm"
                >
                  {l}
                </Tag>
              </HStack>
            ))}
          </HStack>
        )
      }}
      size="sm"
      selectedItem={familyEd}
    >
      <ComboBox.Shell
        display="flex"
        flexDirection="column"
        height="100%"
        overflow="hidden"
        px="1px"
      >
        <ComboBox.Label mb={headingSpacing} {...headingProps}>
          (1) Select a family & edition
        </ComboBox.Label>
        <ComboBox.Input placeholder="Filter" />
        <ComboBox.List borderWidth="1px" borderTopWidth={0} type="permanent" />
      </ComboBox.Shell>
    </ComboBox.Root>
  )

  const variantCol = makeListWithHeading({
    empty: familyEd && (
      <Italic fontFamily="body" fontSize="sm">
        All available variants are already selected
      </Italic>
    ),
    heading: `(2) Select variant`,
    items: variants,
    opacity: familyEd ? 1 : 0.5,
    renderItem: (item) => (
      <ButtonedListItem
        aria-label="Add"
        key={item.id}
        icon={<IcoAdd />}
        onClick={() => setFieldValue('curriculumId', item.id)}
      >
        {item.variant}
      </ButtonedListItem>
    ),
  })

  const columns = values.hasPrinted
    ? [selectedCol, <Box />, <Box />]
    : [selectedCol, familyCol, variantCol]

  return (
    <EqColGrid
      columns={columns}
      flexShrink={0}
      gridColumnGap={8}
      height="180px"
    />
  )
}
