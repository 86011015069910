import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Input,
  List,
  ListItem,
  Radio,
} from '@chakra-ui/react'
import { IcoSecretDoor, IcoX } from '@paper/icons'
import { useRouter } from '@paper/route'
import {
  yupAdmin,
  yupAdminAll,
  yupDomain,
  yupSchoolYear,
} from '@paper/schema/validation'
import { yearCodeToId } from '@paper/utils'
import {
  Field as FormikField,
  FieldProps,
  Formik,
  useFormikContext,
} from 'formik'
import { produce } from 'immer'
import { ReactNode, useCallback } from 'react'
import { EqColGrid } from '~src/blocks/grid'
import { ButtonedListItem } from '~src/blocks/listChakra'
import {
  DropdownMenu,
  IconMenuButton,
  MenuBody,
  SimpleMenu,
} from '~src/blocks/menu'
import { MISupport } from '~src/blocks/miSupport'
import {
  BaseHeader,
  H3,
  HSep,
  HStack,
  Mono,
  Txt,
  VStack,
} from '~src/components'
import { FullPageLoading } from '~src/components/status'
import {
  FieldUI,
  SingleInputForm,
  useFormikArrayHelpers,
} from '~src/pages/publish/formHelpers'
import { Routes } from '~src/routelist'
import { useLimitToInternal } from '~src/utils/permissions'
import {
  AdminArrays,
  adminSorters,
  useAdminSetupData,
  useAdminSubmitter,
} from './data-adminSetup'

export function AdminSetupPage() {
  useLimitToInternal()
  const qResult = useAdminSetupData()
  const { mutateAsync } = useAdminSubmitter()

  return (
    <FullPageLoading qResult={qResult}>
      <Formik
        initialValues={qResult.data}
        onSubmit={async (values, form) => {
          let result = await mutateAsync(values)
          if (result) {
            // reload to refresh everything
            window.location.reload()
          }
          // todo: error handling
        }}
        validateOnMount={true}
        validationSchema={yupAdminAll}
      >
        <BaseHeader.Container>
          <BaseHeader stackGap="1rem">
            <AdminSetupMenu />
            Admin setup (internal/secret)
            <Box>
              <AdminSubmit />
            </Box>
          </BaseHeader>
          <BaseHeader.Body px={12}>
            <AdminForm />
          </BaseHeader.Body>
        </BaseHeader.Container>
      </Formik>
    </FullPageLoading>
  )
}

function AdminSetupMenu() {
  return (
    <SimpleMenu
      align="start"
      caret={true}
      shroud={true}
      trigger={(props) => <IconMenuButton {...props} as={IcoSecretDoor} />}
    >
      <MenuBody data-cy="menu-body" p={2}>
        <DropdownMenu.Group>
          <MISupport />
        </DropdownMenu.Group>
      </MenuBody>
    </SimpleMenu>
  )
}

function AdminSubmit() {
  const { dirty, isSubmitting, isValid, submitForm } =
    useFormikContext<AdminArrays>()
  const { dispatchRoute } = useRouter()
  const isSubmittable = dirty && isValid && !isSubmitting
  return (
    <ButtonGroup size="sm">
      <Button
        colorScheme="red"
        isDisabled={!isSubmittable}
        isLoading={isSubmitting}
        onClick={() => submitForm()}
        type="submit"
      >
        Submit
      </Button>
      <Button onClick={() => dispatchRoute(Routes.root.navigateAction({}))}>
        {dirty ? `Discard and exit` : `Exit`}
      </Button>
    </ButtonGroup>
  )
}

export function AdminForm() {
  const columns = [<DomainForm />, <AdminUserForm />, <SchoolYearForm />]
  return <EqColGrid columns={columns} />
}

function AdminUserForm() {
  const { addToArray, filterArray, sorted, usedSet } = useFormikArrayHelpers(
    adminSorters,
    'admins'
  )

  const validate = useCallback(
    (input: string) => {
      if (!input) {
        return
      } else if (usedSet.has(input)) {
        return 'Already in the set'
      } else {
        try {
          yupAdmin.validateSync({ email: input, roles: ['admin'] })
        } catch (err) {
          return `Doesn't look like an email`
        }
      }
    },
    [usedSet]
  )

  return (
    <AdminColumn
      title={<>Curriculum admins ({sorted.length})</>}
      desc={<>These users are allowed to configure packets</>}
      form={
        <>
          <SingleInputForm
            beforeChange={(value) => value?.trim()}
            clearOnSubmit={true}
            onSubmit={(email) => addToArray(email)}
            placeholder="e.g. admin@myschool.org"
            validate={validate}
          />
          <HSep />
          <List fontSize="sm" overflowY="auto" p={1}>
            {sorted.map((email) => (
              <ButtonedListItem
                aria-label="Remove"
                key={email}
                icon={<IcoX />}
                onClick={() => filterArray((p) => p !== email)}
                variant="ghost"
              >
                <HStack gap={1} fontSize=".825rem">
                  {email}
                </HStack>
              </ButtonedListItem>
            ))}
          </List>
          <HSep />
        </>
      }
    />
  )
}

function DomainForm() {
  const { addToArray, filterArray, sorted, usedSet } = useFormikArrayHelpers(
    adminSorters,
    'domains'
  )

  const validate = useCallback(
    (input: string) => {
      if (!input) {
        return
      } else if (usedSet.has(input)) {
        return 'Already in the set'
      } else {
        try {
          yupDomain.validateSync({ domain: input })
        } catch (err) {
          if (input?.startsWith('@')) {
            return `Omit the @`
          } else {
            return `Doesn't look like a domain`
          }
        }
      }
    },
    [usedSet]
  )

  return (
    <AdminColumn
      title={<>Domains ({sorted.length})</>}
      desc={
        <>All users with emails at these domains will be able to access Paper</>
      }
      form={
        <>
          <SingleInputForm
            beforeChange={(value) => value?.trim()}
            clearOnSubmit={true}
            onSubmit={(domain) => addToArray({ domain })}
            placeholder="e.g. myschool.org"
            validate={validate}
          />
          <HSep />
          <List fontSize="sm" overflowY="auto" p={1}>
            {sorted.map(({ domain }) => (
              <ButtonedListItem
                aria-label="Remove"
                key={domain}
                icon={<IcoX />}
                onClick={() => filterArray((p) => p.domain !== domain)}
                variant="ghost"
              >
                <HStack gap={1} fontSize=".825rem">
                  <Mono fontSize="lg" fontWeight={700} color="blueJeans.500">
                    @
                  </Mono>
                  {domain}
                </HStack>
              </ButtonedListItem>
            ))}
          </List>
          <HSep />
        </>
      }
    />
  )
}

function SchoolYearForm() {
  const { setFieldValue } = useFormikContext<AdminArrays>()
  const { addToArray, filterArray, sorted, usedSet } = useFormikArrayHelpers(
    adminSorters,
    'years'
  )

  const validate = (value: string) => {
    if (!value) {
      return
    } else if (usedSet.has(value)) {
      return 'Already in the set'
    } else {
      try {
        yupSchoolYear.fields.code.validateSync(value)
      } catch (err) {
        return 'Must be ##-##'
      }
    }
  }

  return (
    <AdminColumn
      title="School year"
      desc={
        <VStack>
          <Txt>
            Specify the current years for packet entry (PE) and student work
            (SW)
            <Radio isChecked={true} size="sm" mt="5px" ml={1} />, and when we
            should start and stop pulling data from PowerSchool/Illuminate
          </Txt>
        </VStack>
      }
      form={
        <>
          <SingleInputForm
            beforeChange={(value) => value?.trim()}
            clearOnSubmit={true}
            onSubmit={(code) =>
              addToArray({
                code,
                firstDay: '',
                isPEYear: false,
                isSWYear: false,
                lastDay: '',
                syId: yearCodeToId(code),
              })
            }
            placeholder="e.g. 23-24"
            validate={validate}
          />
          <HSep />
          <List fontSize="sm" overflowY="auto" p={1} spacing={2}>
            <ListItem
              alignItems="center"
              display="flex"
              fontSize="xs"
              gap={2}
              opacity={0.8}
              textAlign="center"
            >
              <Txt width="20px"></Txt>
              <Grid
                alignItems="center"
                gridColumnGap={6}
                gridTemplateColumns="20px 20px 40px 120px 120px"
                gridTemplateRows="auto"
                justifyItems="center"
              >
                <Txt>SW</Txt>
                <Txt>PE</Txt>
                <Txt>Code</Txt>
                <Txt>Load start</Txt>
                <Txt>Load end</Txt>
              </Grid>
            </ListItem>
            {sorted.map(({ code, syId }, idx) => {
              const fieldnamer = (name: keyof AdminArrays['years'][number]) =>
                `years.${idx}.${name}`
              return (
                <ButtonedListItem
                  aria-label="Remove"
                  key={syId}
                  icon={<IcoX />}
                  onClick={() => filterArray((p) => p.syId !== syId)}
                  variant="ghost"
                >
                  <Grid
                    alignItems="center"
                    gridColumnGap={6}
                    gridTemplateColumns="20px 20px 40px 120px 120px"
                    gridTemplateRows="auto"
                    justifyItems="center"
                  >
                    <FormikField name={fieldnamer('isSWYear')}>
                      {({ field, meta }: FieldProps) => (
                        <FieldUI
                          canBeRedUntouched={true}
                          display="flex"
                          hideErrorMsg={true}
                          label={null}
                          meta={meta}
                          input={
                            <Radio
                              isChecked={field.value}
                              size="md"
                              {...field}
                              onChange={() => {
                                setFieldValue(
                                  'years',
                                  produce(sorted, (draft) => {
                                    draft.forEach(
                                      (p) => (p.isSWYear = p.code === code)
                                    )
                                  })
                                )
                              }}
                            />
                          }
                        />
                      )}
                    </FormikField>
                    <FormikField name={fieldnamer('isPEYear')}>
                      {({ field, meta }: FieldProps) => (
                        <FieldUI
                          canBeRedUntouched={true}
                          display="flex"
                          hideErrorMsg={true}
                          label={null}
                          meta={meta}
                          input={
                            <Radio
                              isChecked={field.value}
                              size="md"
                              {...field}
                              onChange={() => {
                                setFieldValue(
                                  'years',
                                  produce(sorted, (draft) => {
                                    draft.forEach(
                                      (p) => (p.isPEYear = p.code === code)
                                    )
                                  })
                                )
                              }}
                            />
                          }
                        />
                      )}
                    </FormikField>
                    <Txt flexShrink={0} whiteSpace="nowrap">
                      {code}
                    </Txt>
                    {(['firstDay', 'lastDay'] as const).map((fieldName) => (
                      <FormikField key={fieldName} name={fieldnamer(fieldName)}>
                        {({ field, meta }: FieldProps) => (
                          <FieldUI
                            canBeRedUntouched={true}
                            display="flex"
                            hideErrorMsg={true}
                            label={null}
                            meta={meta}
                            input={
                              <Input
                                size="xs"
                                type="date"
                                {...field}
                                value={field.value ?? ''}
                              />
                            }
                          />
                        )}
                      </FormikField>
                    ))}
                  </Grid>
                </ButtonedListItem>
              )
            })}
          </List>
          <HSep />
        </>
      }
    />
  )
}

type AdminColumnProps = {
  desc: ReactNode
  form: ReactNode
  title: ReactNode
}

export function AdminColumn(props: AdminColumnProps) {
  const { desc, form, title } = props
  return (
    <VStack alignItems="stretch" gap={3}>
      <H3>{title}</H3>
      <Txt fontSize="sm" opacity={0.8}>
        {desc}
      </Txt>
      {form}
    </VStack>
  )
}
