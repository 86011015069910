import type { Question } from '@paper/schema'
import range from 'lodash/range'
import { pareUndefined } from './src'

export type OptionAlphabet = 'abc' | '012'

const ACharCode = 'A'.charCodeAt(0)
export const idxToLabel = (idx: number, alphabet: OptionAlphabet) =>
  alphabet === 'abc' ? String.fromCharCode(idx + ACharCode) : idx.toString()

/**
 * @example
 * let options = makeOptions(3, 'abc')
 * // [{ label: 'A'}, {label: 'B'}, { label: 'C'}]
 */
export const makeOptions = (length: number, alphabet: OptionAlphabet) =>
  range(length).map((idx) => ({ label: idxToLabel(idx, alphabet) }))

type LabelScheme = '1' | '1A' | 'A' | 'A1'

const schemes: { start: LabelScheme; rx: RegExp }[] = [
  // #
  {
    start: '1',
    rx: /^([0-9]+)$/i,
  },
  // Letter
  {
    start: 'A',
    rx: /^([A-Z])$/i,
  },
  // L#
  {
    start: 'A1',
    rx: /^([A-Z])([0-9]+)$/i,
  },
  // #L
  {
    start: '1A',
    rx: /^([0-9]+)([A-Z])$/i,
  },
]

export type ParsedLabel = Pick<Question, 'label' | 'labelMaj' | 'labelMin'>

export const parseLabel = (
  input: string,
  mode: 'parse' | 'increment' = 'parse'
): ParsedLabel => {
  if (!input) {
    throw Error(`Label is required`)
  }

  // Try each regex
  for (const { rx } of schemes) {
    const rxResult = rx.exec(input)
    if (rxResult) {
      let labelMaj = rxResult[1].toUpperCase()
      let labelMin = rxResult[2]?.toUpperCase()

      // Increment the minor-est digit in increment mode
      if (mode === 'increment') {
        labelMin != null
          ? (labelMin = increment(labelMin))
          : (labelMaj = increment(labelMaj))
      }

      return pareUndefined({
        label: concatLabel(labelMaj, labelMin),
        labelMaj,
        labelMin,
      }) as ParsedLabel
    }
  }
  // No regex match
  if (input.length > 3) {
    throw Error(`Label can be at most 3 characters`)
  }

  if (mode === 'parse') {
    // Allow any label if parse
    return {
      label: input,
      labelMaj: input,
    }
  } else {
    // Blank out if increment
    return {
      label: '',
      labelMaj: '',
    }
  }
}

const concatLabel = (a: string, b: string) => `${a}${b != null ? b : ''}`

const increment = (str: string) => {
  const asInt = parseInt(str)
  if (!Number.isNaN(asInt)) {
    return (asInt + 1).toString()
  } else if (str.length === 1) {
    return String.fromCharCode(str.charCodeAt(0) + 1)
  } else {
    throw Error(`Can't increment ${str}`)
  }
}
