import {
  Box,
  BoxProps,
  Button,
  ButtonGroup,
  Grid,
  Icon,
} from '@chakra-ui/react'
import { IcoNoScore, IcoRuBad, IcoRuGood, IcoRuNeutral } from '@paper/icons'
import { useRouter } from '@paper/route'
import { Student, TicketRubric, XpacketSW } from '@paper/schema'
import { pushDefault, XOR } from '@paper/utils'
import { chunk, orderBy, times } from 'lodash'
import { ReactNode, useMemo } from 'react'
import { HStack, ToggleGroup, Txt, VStack } from '~src/components'
import { RD_SW_Rubric } from '~src/routelist'

/**
 * Retrieves an element from an array using a circular indexing approach.
 *
 * This means that if the provided index is out of the bounds of the array,
 * it will wrap around using modulo arithmetic.
 *
 * @template T
 * @param {T[]} arr - The array from which to retrieve the element.
 * @param {number} index - The index to use for retrieving the element. Can be negative or larger than the array length.
 * @returns {T} The element at the adjusted index.
 */
function getArrCirc<T>(arr: T[], index: number): T {
  let adjIndex = ((index % arr.length) + arr.length) % arr.length
  return arr[adjIndex]
}

/** @deprecated need to uniqify */
export const getFirstL = (student: Student) =>
  `${student.firstName.split(/\s/)[0]} ${student.lastName.slice(0, 1)}.`

type RubricStudentGridProps = { xpackets: XpacketSW[] }

export function RubricStudentGrid(props: RubricStudentGridProps) {
  const { xpackets } = props
  const dim = 4 // todo:

  const { dispatchStay, routeData } = useRouter<RD_SW_Rubric>()

  const axisOffset = routeData.axes ?? 0

  const gridData = useMemo(() => {
    type GridCell = { firstL: string; rubric: TicketRubric; xpacketId: string }
    let cells: GridCell[][] = times(dim ** 3, () => [])

    xpackets.forEach((xp) => {
      if (xp.rubric && xp.rubric.values.some((p) => p != null)) {
        let xVal = getArrCirc(xp.rubric.values, 0 + axisOffset)
        let yVal = getArrCirc(xp.rubric.values, 1 + axisOffset)
        let zVal = getArrCirc(xp.rubric.values, 2 + axisOffset)

        const valToCoord = (val: number) => {
          switch (val) {
            case 2:
              return 0
            case 1:
              return 2
            case -1:
              return 3
            default:
              return 1
          }
        }
        const dim = xp.rubric.values.length + 1 // todo: empty spot
        let xCoord = valToCoord(xVal)
        let yCoord = valToCoord(yVal)
        let zCoord = valToCoord(zVal)
        const index = xCoord * dim ** 2 + yCoord * dim + zCoord
        pushDefault(cells, index, {
          firstL: getFirstL(xp.student),
          rubric: xp.rubric,
          xpacketId: xp.id,
        })
      }
    })

    // Sort each cell by name
    cells = cells.map((list) => orderBy(list, (p) => p.firstL))

    // Chunk the z-axis
    return chunk(cells, dim)
  }, [axisOffset, xpackets])

  const iconOrder = [IcoRuGood, IcoNoScore, IcoRuNeutral, IcoRuBad]
  const colors = ['green.500', 'gray.300', 'yellow.500', 'orange.500']
  const labels = ['What the question is asking', 'How to answer it', 'Habits']
  const iconStrokeWidth = '1.5'

  const containerProps: BoxProps = { flexShrink: 0 }

  return (
    <Grid
      {...containerProps}
      gridTemplateColumns={`repeat(${dim + 2}, auto)`}
      gridTemplateRows={`repeat(${dim + 3}, auto)`}
      userSelect="none"
    >
      <AxesLabels
        gridAddress={['3 / -1', 1]}
        x={getArrCirc(labels, axisOffset)}
        y={getArrCirc(labels, axisOffset + 1)}
      />
      {times(2, (j) =>
        times(dim, (i) => {
          const isColHeader = j === 0
          const key = `${isColHeader ? 'col' : 'row'}:${i}`
          return (
            <HStack
              gridColumn={!isColHeader ? 2 : i + 3}
              gridRow={isColHeader ? 2 : i + 3}
              justifyContent="center"
              key={key}
            >
              <Icon
                as={iconOrder[i]}
                color={colors[i]}
                fontSize="1.75rem"
                m={1}
                strokeWidth={iconStrokeWidth}
              />
            </HStack>
          )
        })
      )}
      {gridData.map((chunk, idx) => {
        const gridRow = idx % dim
        const gridColumn = Math.floor(idx / dim)
        // console.log(idx, gridRow, gridColumn)
        const border = '2px solid'

        return (
          <VStack
            alignItems="stretch"
            borderBottom={border}
            borderLeft={gridColumn === 0 ? border : undefined}
            borderRight={border}
            borderTop={gridRow === 0 ? border : undefined}
            gap={1}
            gridColumn={gridColumn + 3}
            gridRow={gridRow + 3}
            key={idx}
            p={1}
          >
            {chunk.map((items, idx) =>
              //  console.log(`${gridRow}:${gridColumn}`, p.rubric.values)
              !items.length ? null : (
                <Z
                  key={idx}
                  icon={
                    <Icon
                      as={iconOrder[idx]}
                      color={colors[idx]}
                      fontSize="md"
                      strokeWidth={iconStrokeWidth}
                    />
                  }
                >
                  {items.map((p) => {
                    const isSelected = routeData.xpacketId === p.xpacketId
                    return (
                      <Button
                        key={p.xpacketId}
                        colorScheme={isSelected ? 'yellow' : null}
                        onClick={() => dispatchStay({ xpacketId: p.xpacketId })}
                        size="xs"
                        variant={isSelected ? 'solid' : 'ghost'}
                      >
                        <Txt
                          as="span"
                          isTruncated={true}
                          textAlign="start"
                          width="80px"
                        >
                          {p.firstL}
                        </Txt>
                      </Button>
                    )
                  })}
                </Z>
              )
            )}
          </VStack>
        )
      })}
      {/* padding */}
      <Box gridColumn="3 / -1" gridRow="-1" height={6}></Box>
    </Grid>
  )
}

type ZProps = { children: ReactNode; icon?: ReactNode; variant?: 'filled' }

function Z(props: ZProps) {
  const { children, icon, variant } = props

  return (
    <HStack
      alignItems="start"
      borderColor={variant === 'filled' ? 'rgb(26, 32, 44)' : 'gray.400'}
      borderRadius="sm"
      borderWidth={'0.5px'}
      p={1}
    >
      {icon}
      <VStack>{children}</VStack>
    </HStack>
  )
}

type AxisLabelProps = {
  gridAddress: [BoxProps['gridRow'], BoxProps['gridRow']]
  label: ReactNode
  xOrY: 'x' | 'y'
}

function AxisLabel(props: AxisLabelProps) {
  const { gridAddress, label, xOrY } = props

  let area = [...gridAddress]

  const padding = 1

  let xOrYProps: BoxProps = {
    paddingBlockEnd: padding, // towards the grid
  }
  if (xOrY === 'y') {
    area.reverse()
    xOrYProps = {
      paddingBlockStart: padding, // towards the grid
      sx: { writingMode: 'vertical-lr' },
      transform: 'rotate(180deg)',
    }
  }

  return (
    <Txt
      {...xOrYProps}
      fontWeight={400}
      gridColumn={area[0]}
      gridRow={area[1]}
      placeSelf="center"
      userSelect="none"
    >
      ← {label} →
    </Txt>
  )
}

type AxesLabelProps = {
  /**
   * * [gridColumn, gridRow] of the x label
   * * And [gridRow, gridColumn] of the y label
   * (assumes the grid layout is symmetric)
   */
  gridAddress: AxisLabelProps['gridAddress']
  x?: ReactNode
  y?: ReactNode
}

function AxesLabels(props: AxesLabelProps) {
  const { gridAddress, x, y } = props
  return (
    <>
      <AxisLabel gridAddress={gridAddress} label={x} xOrY="x" />
      <AxisLabel gridAddress={gridAddress} label={y} xOrY="y" />
    </>
  )
}

type AxesSelectorProps = {}

export function AxesSelector(props: AxesSelectorProps) {
  const { dispatchStay, routeData } = useRouter<RD_SW_Rubric>()

  const axes = ['Habits', 'What', 'How'] // todo: unhardcode

  let value = routeData.axes

  if (!axes[value]) {
    value = 0
  }

  return (
    <ToggleGroup.Root
      colorScheme="blue"
      onChange={(next) => dispatchStay({ axes: parseInt(next) })}
      preventNone={true}
      shape="90deg"
      type="single"
      value={value.toString()}
    >
      <ButtonGroup
        display="flex"
        flexDirection="column"
        fontFamily="mono"
        gap={4}
        isAttached={true}
        justifyContent="center"
      >
        {axes.map((label, idx) => {
          const x = getArrCirc(axes, idx + 1)
          const y = getArrCirc(axes, idx + 2)
          const z = label

          const template = '16px 84px'

          return (
            <ToggleGroup.Button
              display="grid"
              fontSize="sm"
              gridTemplateColumns={template}
              gridTemplateRows={template}
              key={idx}
              height="auto"
              pb={1}
              pl={0}
              pt={0}
              pr={1}
              placeItems="center"
              value={idx.toString()}
              width="auto"
            >
              <AxisLabel2 x={x} />
              <AxisLabel2 y={y} />
              <Txt as="span" gridColumn="2" gridRow="2" p={2}>
                <Z variant="filled">↕ {z}</Z>
              </Txt>
            </ToggleGroup.Button>
          )
        })}
      </ButtonGroup>
    </ToggleGroup.Root>
  )
}

type AxisLabel2Props = XOR<{ x: string }, { y: string }>

function AxisLabel2(props: AxisLabel2Props) {
  const { x, y } = props

  const label = x ?? y

  const area = ['2 / -1', 1]

  const place = ['start', 'stretch']

  let xOrYProps: BoxProps = {}
  if (y) {
    area.reverse()
    place.reverse()

    xOrYProps = {
      ...xOrYProps,
      sx: { writingMode: 'vertical-lr' },
      transform: 'rotate(180deg)',
    }
  }

  return (
    <Grid
      {...xOrYProps}
      gridColumn={area[0]}
      gridRow={area[1]}
      gridTemplateColumns="auto 1fr auto"
      placeSelf={place.join(' ')}
      userSelect="none"
    >
      {`← ${label} →`.split(' ').map((txt) => (
        <Txt as="span" key={txt}>
          {txt}
        </Txt>
      ))}
    </Grid>
  )
}
