import { Button } from '@chakra-ui/react'
import { IcoAdd, IcoHome } from '@paper/icons'
import { useRouter } from '@paper/route'
import { Fragment } from 'react'
import { Error404Page, NotFoundError } from '~src/blocks/errors'
import { getEAProps } from '~src/blocks/errors/errorUtils'
import { RD_Publish, Routes } from '~src/routelist'
import { useGoToNew } from './utils'

export const pubErrorRender = ({ error }) => {
  if (error instanceof NotFoundError) {
    return <NotFoundErrorWrapper error={error} />
  }
  throw error
}

function NotFoundErrorWrapper(props: { error: NotFoundError }) {
  const { dispatchRoute } = useRouter<RD_Publish>()
  const goToNew = useGoToNew()

  let actions = (
    <Fragment>
      {goToNew && (
        <Button {...getEAProps(<IcoAdd />)} onClick={goToNew}>
          Add a new packet
        </Button>
      )}
      <Button
        {...getEAProps(<IcoHome />)}
        onClick={() => dispatchRoute(Routes.home_curriculum.mergeAction())}
      >
        Go to curriculum home
      </Button>
    </Fragment>
  )
  return <Error404Page actions={actions} error={props.error} />
}
