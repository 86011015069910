import { Box } from '@chakra-ui/react'
import { useStickyGridContext } from './stickGrid2'

/**
 * Shared by cell and axis label for some consistency
 */
export function AnswerSubGrid({ children, colCount }) {
  const gridCtx = useStickyGridContext()

  // todo: make this shareable
  const width = gridCtx ? `${gridCtx.cell.width - 24}px` : undefined

  return (
    <Box
      alignItems="center"
      display="grid"
      gridTemplateColumns={`repeat(${colCount}, 1fr)`}
      justifyItems="stretch"
      width={width}
    >
      {children}
    </Box>
  )
}
