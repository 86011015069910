import {
  Box,
  ButtonGroup,
  Icon,
  IconButton,
  IconButtonProps,
  Radio,
  Tag,
  TagLeftIcon,
  TagProps,
} from '@chakra-ui/react'
import {
  IcoAdd,
  IcoEdit,
  IcoFunnel,
  IconType,
  IcoTrash,
  IcoWarningCircle,
} from '@paper/icons'
import { useRouter } from '@paper/route'
import { CourseFilterStatus, SetupCourse } from '@paper/schema'
import { entries } from '@paper/utils'
import { partition, sumBy } from 'lodash'
import { MenuBody, SimpleMenu } from '~src/blocks/menu'
import { IconColumnProps } from '~src/blocks/packetTable/tableColUtils'
import { HStack, Txt, VStack } from '~src/components'
import { ColumnProps, UghColumn } from '~src/components/table'
import { RD_Setup_School } from '~src/routelist'
import { formatUnits } from '~src/utils/messages'
import { CourseColors } from './courseColors'
import { useSchoolSetupContext } from './schoolSetupContext'

const courseToProps = (item: SetupCourse): ColumnProps => ({
  opacity: item.stats.paper > 0 ? null : 0.5,
})

export const useCourseSetupColumns = (): UghColumn<SetupCourse>[] => {
  const { curriculumMap } = useSchoolSetupContext()
  const { dispatchStay } = useRouter<RD_Setup_School>()

  return [
    {
      props: IconColumnProps,
      label: () => (
        <Box aria-label="Add/edit column">
          <Icon as={IcoAdd} />
          <Icon as={IcoEdit} />
        </Box>
      ),
      cell: (item) => (
        <AddEditButton
          isAdd={!!courseToProps(item).opacity}
          onClick={() => dispatchStay({ courseId: item.id })}
        />
      ),
    },
    {
      props: { align: 'start' },
      getProps: courseToProps,
      label: () => 'Course',
      cell: (item) => item.name,
    },
    {
      props: { align: 'start' },
      getProps: courseToProps,
      label: () => 'Curricula',
      cell: (item) =>
        item.curriculumId && (
          <HStack fontFamily="mono" fontSize="sm" gap={2}>
            {/* <Tag size="sm">{item.curriculumIds.length}</Tag> */}
            <Txt as="span" isTruncated={true}>
              {curriculumMap.get(item.curriculumId)?.name}
            </Txt>
          </HStack>
        ),
    },
    {
      props: { width: 100 },
      getProps: courseToProps,
      label: () => (
        <HStack>
          Sections <StatusFilter />
        </HStack>
      ),
      cell: (item) => {
        const counts = item.stats
        const colorScheme =
          item.status === 'all'
            ? 'blue'
            : item.status === 'none'
            ? 'gray'
            : 'yellow'

        const tagProps: TagProps = {
          size: 'sm',
          fontSize: 'md',
        }

        return (
          <HStack fontFamily="mono" gap={1}>
            <Tag
              {...tagProps}
              colorScheme={colorScheme}
              variant={colorScheme !== 'gray' ? 'solid' : 'subtle'}
            >
              {counts.paper === counts.exists
                ? counts.paper
                : `${counts.paper}/${counts.exists}`}
            </Tag>
            <RedCountTag
              {...tagProps}
              count={counts.dirty}
              icon={IcoWarningCircle}
              units="dirty sections"
            />
            <RedCountTag
              {...tagProps}
              count={counts.deleted}
              icon={IcoTrash}
              units="deleted sections"
            />
          </HStack>
        )
      },
    },
    {
      props: { width: 100 },
      getProps: courseToProps,
      label: () => <HStack>Students</HStack>,
      cell: (item) => {
        const counts = partition(item.sections, (s) => s.isEnabled).map((p) =>
          sumBy(p, (s) => s.meta.section.students.length)
        )
        const colors = ['blue', 'blackAlpha']

        const tagProps: TagProps = {
          borderWidth: '1px',
          boxShadow: 'none',
          px: 1.5,
          size: 'sm',
        }

        // todo: copy/pasted from curriculum VariantStatus
        const attachedStyles = {
          // prettier-ignore
          horizontal: {
            '> *:first-child/* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */:not(:last-child)': { borderEndRadius: 0 }, 
            '> *:not(:first-child):not(:last-child)': { borderRadius: 0 }, 
            '> *:not(:first-child):last-child': { borderStartRadius: 0, borderLeftWidth: 0 }, 
          },
        }

        return (
          <ButtonGroup
            fontFamily="mono"
            isAttached={true}
            size="xs"
            userSelect="none"
            sx={attachedStyles.horizontal}
          >
            {counts.map((count, idx) => {
              const colorScheme = colors[idx]
              return (
                count > 0 && (
                  <Tag
                    {...tagProps}
                    borderColor={`${colorScheme}.500`}
                    colorScheme={colorScheme}
                    key={idx}
                    variant="outline"
                  >
                    {count}
                  </Tag>
                )
              )
            })}
          </ButtonGroup>
        )
      },
    },
  ]
}

type RedCountTagProps = TagProps & {
  icon?: IconType
  count: number
  units: string
}

function RedCountTag(props: RedCountTagProps) {
  const { count, icon, units, ...rest } = props
  return (
    count > 0 && (
      <Tag
        aria-label={formatUnits(count, units)}
        colorScheme={CourseColors.pending}
        variant="solid"
        {...rest}
      >
        {icon && <TagLeftIcon as={icon} mr={0} />}
        {count}
      </Tag>
    )
  )
}

type AddEditButtonProps = { isAdd: boolean } & Partial<IconButtonProps>

function AddEditButton(props: AddEditButtonProps) {
  const { isAdd, ...rest } = props

  return (
    <IconButton
      aria-label="Configure" // todo:
      data-cy="btn-add-edit"
      icon={isAdd ? <IcoAdd /> : <IcoEdit />}
      isRound={true}
      size="sm"
      variant="ghost" //{isAdd ? 'solid' : 'ghost'}
      {...rest}
      sx={{
        // so it stands out against the table background...
        _active: { bg: `blue.600`, color: 'white' },
        _hover: { bg: `blue.400`, color: 'white' },
      }}
    />
  )
}

const statuses: Record<
  CourseFilterStatus,
  { colorScheme: string; label: string }
> = {
  all: { colorScheme: CourseColors.registered, label: 'All registered' },
  partial: { colorScheme: CourseColors.partial, label: 'Partially registered' },
  pending: { colorScheme: CourseColors.pending, label: 'Pending changes' },
  none: { colorScheme: CourseColors.none, label: 'Unregistered' },
}

/** todo: copy/paste from curriculumSetupTableCOls */
function StatusFilter() {
  const { dispatchStay, routeData } = useRouter<RD_Setup_School>()
  const { f_status } = routeData

  return (
    <SimpleMenu
      caret={false}
      defaultOpen={false}
      sideOffset={4}
      trigger={(menuButtonProps) => (
        <IconButton
          {...menuButtonProps}
          aria-label={`Status filter`}
          colorScheme={statuses[f_status]?.colorScheme}
          data-cy="filter-status"
          icon={<IcoFunnel />}
          isRound={true}
          mx={0.5}
          size="xs"
          variant={f_status ? 'solid' : 'ghost'}
          minWidth="18px"
          height="18px"
        />
      )}
    >
      <MenuBody p={2}>
        <VStack alignItems="stretch" gap={2}>
          <Radio
            colorScheme={'gray'}
            isChecked={!f_status}
            onChange={() => dispatchStay({ f_status: null })}
          >
            <Txt fontSize="sm" px={1}>
              All
            </Txt>
          </Radio>
          {entries(statuses).map(([status, { colorScheme, label }]) => {
            const isSelected = status === f_status
            return (
              <Radio
                colorScheme={colorScheme}
                isChecked={isSelected}
                key={status}
                onChange={() => dispatchStay({ f_status: status })}
              >
                <Tag
                  colorScheme={colorScheme}
                  justifyContent="center"
                  textTransform="capitalize"
                  variant="solid"
                >
                  {label}
                </Tag>
              </Radio>
            )
          })}
        </VStack>
      </MenuBody>
    </SimpleMenu>
  )
}
