/**
 * Shortcut for `Math.max(0, value)`
 *
 * * Maybe pointless, but...
 * * (?) Increases readability
 * * Avoids me typing Math.min(0, ...) then fixing because I have min on the brain
 * @example
 * const xIdx = min0(Math.floor(el.scrollLeft / itemWidth))
 */
export const min0 = (value: number) => Math.max(0, value)
