import { BoxProps, Button, ButtonProps } from '@chakra-ui/react'
import type { SpaceProps } from '@chakra-ui/styled-system'
import {
  IconType,
  IcoPdf,
  IcoPrint,
  IcoPublish,
  IcoRadioactive,
  IcoRecall,
} from '@paper/icons'
import { Packetmeta, PublishStage } from '@paper/schema'
import { getPrintState } from '@paper/utils'
import { HStack, TooltippedIcon } from '~src/components'
import { formatPastVsNow } from '~src/utils/messages'

export const PublishColor = 'green'
export const RecallColor = 'red'
export const PrintColor = 'blue'
export const NuclearColor = 'yellow'

type IconSize = BoxProps['fontSize']
type PacketProp = { packet: Packetmeta }
type PacketHUDProps = PacketProp &
  SpaceProps & { size?: IconSize; variant?: 'full' | 'noupload' }

export const PacketHUD = (props: PacketHUDProps) => {
  const { packet, size = 'md', variant, ...passThrough } = props

  const hasPdf = packet._pub !== 'creating'
  const isPublished = packet._pub === 'published'
  const isRecalled = packet._pub === 'recalled'
  const { isPrinted, radioactive: isRadioactive } = getPrintState(packet)

  const publishTime = formatPastVsNow(packet._updates.publish?.time)

  const uploaded = (
    <Indicator
      icon={IcoPdf}
      isOn={hasPdf}
      onColor="gray.400"
      onLabel="Uploaded"
      offLabel="Not yet uploaded"
      size={size}
    />
  )

  // TODO: obviously on/off isn't quite the right paradigm

  const published = (
    <Indicator
      icon={isRecalled ? IcoRecall : IcoPublish}
      isOn={isPublished || isRecalled}
      onColor={isPublished ? `${PublishColor}.500` : `${RecallColor}.500`}
      // TODO: Yuck :)
      onLabel={`${isPublished ? 'Published' : 'Recalled'} ${
        publishTime ?? '(after submit)'
      }`}
      offLabel="Not yet published"
      size={size}
    />
  )

  const printed = (
    <Indicator
      icon={!isRadioactive ? IcoPrint : IcoRadioactive}
      isOn={isPrinted}
      onColor={!isRadioactive ? `${PrintColor}.500` : `${NuclearColor}.500`}
      onLabel={
        !isRadioactive
          ? `Printed ${formatPastVsNow(packet._printed?.first)}`
          : `Warning: This packet was edited after it was printed and may be in an inconsistent state.`
      }
      offLabel="Not yet printed"
      size={size}
    />
  )

  return (
    <HStack
      as="span"
      display="inline-flex"
      gap={1}
      justifyContent="center"
      userSelect="none"
      {...passThrough}
    >
      {variant !== 'noupload' && uploaded}
      {published}
      {printed}
    </HStack>
  )
}

type PublishButtonProps = { next?: PublishStage } & Omit<
  ButtonProps,
  'children'
>
const PublishButton = ({ next, ...rest }: PublishButtonProps) => {
  let colorScheme: string, text: string

  switch (next) {
    case 'published':
      colorScheme = PublishColor
      text = 'Publish'
      break

    case 'recalled':
      colorScheme = RecallColor
      text = 'Recall'
      break
  }

  return (
    <Button
      data-cy="pub-recall"
      isDisabled={!next}
      colorScheme={colorScheme}
      w="60px"
      {...rest}
    >
      {text}
    </Button>
  )
}

PacketHUD.PublishButton = PublishButton

type IndicatorProps = {
  isOn: boolean
  onColor: string
  onLabel: string
  offColor?: string
  offLabel: string
  icon: IconType
  size: IconSize
}

const Indicator = (props: IndicatorProps) => {
  const {
    icon,
    isOn,
    onColor,
    onLabel,
    offColor = 'gray.200',
    offLabel,
    size,
  } = props
  return (
    <TooltippedIcon
      aria-label={isOn ? onLabel : offLabel}
      as={icon}
      color={isOn ? onColor : offColor}
      fontSize={size}
      tooltipProps={{ placement: 'bottom' }}
    />
  )
}

// TODO: Don't want to expose this directly forever
PacketHUD.Indicator = Indicator
