import {
  fromAbsolute,
  getLocalTimeZone,
  now,
  parseAbsolute,
  parseZonedDateTime,
  toLocalTimeZone,
} from '@internationalized/date'

// todo: does date-fns/tz have a nicer api?
// todo: this is a big mess...

// maybe?
// export const tzUtil = {
//   gt: (a: ZonedDateTime, b: ZonedDateTime) => a.compare(b) > 0,
//   gte: (a: ZonedDateTime, b: ZonedDateTime) => a.compare(b) >= 0,
//   lt: (a: ZonedDateTime, b: ZonedDateTime) => a.compare(b) < 0,
//   lte: (a: ZonedDateTime, b: ZonedDateTime) => a.compare(b) <= 0,
// }

export const zdtInLocal = (ms: number) => {
  return fromAbsolute(ms, getLocalTimeZone())
}

/**
 * Returns `isoDate`s `ZonedDateTime` in `timezone`
 * @deprecated this doesn't do anything outside of parseAbsolute...need to clean this up
 */
export const isoInZone = (isoDate: string, timezone: string) => {
  const zdt = parseAbsolute(isoDate, timezone)
  return zdt
}

/**
 * @deprecated This probably doesn't make sense split out, but trying to centralize timezone stuff
 * because the @internationalize/date api isn't very friendly
 */
export const parseZdtToDate = (zdt: string) => {
  return parseZonedDateTime(zdt).toDate()
}

/**
 * Returns `time` in `timezone` in local timezone
 */
export const timeInLocal = (
  timezone: string,
  hour: number,
  minute = 0,
  second = 0,
  millisecond = 0
) => {
  // Get ZonedDateTime object of `time` in `timezone`
  // todo: is there a cleaner way?, the ZDT constructor seems to require knowing the offset of `timezone`
  let zdt = now(timezone)
  zdt = zdt.set({ hour, minute, second, millisecond })
  return toLocalTimeZone(zdt)
}
