import { useRouter } from '@paper/route'
import { ReactNode } from 'react'
import { HStack, VStack } from '~src/components'
import { Routes } from '~src/routelist'
import { MenuModuleBase } from './mmBase'
import { BarSection } from './mmStudentWork'

type MenuModulePinGridProps = { keyboard: ReactNode }

export function MenuModulePinGrid(props: MenuModulePinGridProps) {
  const { currentRoute } = useRouter()

  if (currentRoute !== Routes.crossNetwork) {
    return null
  }

  const todoMaybeGrid = 4 // todo: unify layout pieces

  // todo: need to figure out threading in the keyboard shortcuts
  return (
    <MenuModuleBase>
      <VStack alignItems="stretch" gap={2} p={3}>
        <HStack alignItems="stretch" gap={todoMaybeGrid} minHeight="52px">
          <BarSection title="Keyboard shortcuts">{props.keyboard}</BarSection>
        </HStack>
      </VStack>
    </MenuModuleBase>
  )
}
