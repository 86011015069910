import { Box, HStack } from '@chakra-ui/react'
import { PageItem, Passage } from '@paper/schema'
import { Yup, yupPassage } from '@paper/schema/validation'
import { Field as FormikField, FieldProps, useFormikContext } from 'formik'
import { chunk } from 'lodash'
import { useCallback } from 'react'
import { AnswerKey } from '~src/blocks/answerKey'
import { SimplePicker } from '~src/blocks/pickers/filterPicker'
import { FieldUI, SimpleTextField2 } from '../formHelpers'
import { SubFormButtons } from './formListValueHelpers'
import { PassageNoQError } from './validation'
import { useWizContext } from './wizProvider'

export type SinglePassageSchema = Passage & {
  _new?: boolean
  _questionIds: PageItem[]
}

export const yupSinglePassage = yupPassage
  .shape({
    _questionIds: Yup.array(Yup.object({ id: Yup.string().required() })),
  })
  .test('_questionIds', null, function (values) {
    // todo: having trouble getting this to fire via the normal mechanism
    if (!values._questionIds.length) {
      return this.createError({
        path: '_questionIds',
        message: PassageNoQError,
      })
    }
    return true
  })

type PassageFormProps = { onUnselect(): void }

export const SinglePassageForm = ({ onUnselect }: PassageFormProps) => {
  const { formik: wizFormik } = useWizContext()
  const thisFormik = useFormikContext<SinglePassageSchema>()

  const setSelectedQs = useCallback((selected: PageItem[]) => {
    thisFormik.setFieldValue('_questionIds', selected)
  }, [])

  return (
    <HStack alignItems="start" gap={6} minWidth={700} overflow="hidden" p={1}>
      <Box flexShrink={0} width={320}>
        <SimpleTextField2 label="name" name="name" />
        <GenreField />
        <SimpleTextField2 label="lexile (optional)" name="lexile" />
        <SubFormButtons onUnselect={onUnselect} />
      </Box>
      <FieldUI
        canBeRedUntouched={!!thisFormik.values.name}
        display="flex"
        flexDirection="column"
        flexGrow={1}
        height="100%"
        input={
          <AnswerKey.Container
            minWidth="280px"
            outerProps={{ pt: 2 }}
            sizingMode="dont-measure-width-flex-shrink"
          >
            <AnswerKey.CheckboxGuts
              // todo: chunking artificially because the Qs aren't wrapping
              chunks={chunk(wizFormik.values.questions, 1).map(
                (qChunk, idx) => ({
                  fingerprint: idx.toString(),
                  passages: [],
                  questions: qChunk,
                })
              )}
              chunkClearance="none"
              onChange={setSelectedQs}
              values={thisFormik.values._questionIds}
            />
          </AnswerKey.Container>
        }
        label="Attach questions"
        meta={thisFormik.getFieldMeta('_questionIds')}
        overflow="hidden"
        width="auto"
      />
    </HStack>
  )
}

const GenreField = () => {
  return (
    <FormikField name="genre">
      {({ field, form, meta }: FieldProps<string>) => (
        <FieldUI
          input={
            <SimplePicker
              filterType="contains"
              getId={(item) => item}
              getLabel={(item) => item}
              items={GENRES}
              itemPlural="genres"
              onChange={(value) => form.setFieldValue(field.name, value)}
              selectedId={field.value}
              variant="outline"
            />
          }
          label="genre (optional)"
          meta={meta}
          w="100%"
        />
      )}
    </FormikField>
  )
}

// TODO: Temporary and bad
const GENRES = [
  'Info: Audio/Video',
  'Info: Biography',
  'Info: Expository Non-Fiction',
  'Info: Historical Non-Fiction',
  'Info: Journalism',
  'Info: News Article',
  'Info: Other',
  'Info: Scientific Article',
  'Info: Video/Audio',
  'Narrative: Drama',
  'Narrative: Fantasy',
  'Narrative: Fiction',
  'Narrative: Folktale/Myth/Legend',
  'Narrative: Historical Fiction',
  'Narrative: Memoir/Non-Fiction',
  'Narrative: Poetry',
  'Narrative: Realistic Fiction',
  'Narrative: Video/Audio',
]
