import { Curriculum } from '@paper/schema'
import { getC2NameAndId, yearCodeToId } from '@paper/utils'
import { useFormikContext } from 'formik'
import { FormDialog, PublicFormDialogProps } from '~src/blocks/formDialog'
import { Txt } from '~src/components'
import { CurriculumForm, VariantList } from '../curriculumForm'
import { C2FamilyEd, useCurriculumSubmitter } from '../data-curriculumSetup'
import { VariantActionButtons } from '../variantStatus'
import { getManageButtons } from './dialogCreate'
import { useValidationSchema } from './validation'

/**
 * Dialog for managing existing curricula
 */
export function CurriculumManageDialog(
  props: PublicFormDialogProps<C2FamilyEd>
) {
  const closeDialog = props.onClose
  const validationSchema = useValidationSchema('manage')
  const submitter = useCurriculumSubmitter()

  return (
    <FormDialog
      {...props}
      onSubmit={async (values) => {
        let data = values.curricula
          .filter((p) => p._status || p.id)
          .map(({ _status, variant, id, name }): Curriculum => {
            const { ed, family, levels, subject } = values
            if (!id) {
              ;({ id, name } = getC2NameAndId(values, variant))
            }
            return {
              _status,
              ed,
              family,
              id,
              levels,
              name,
              subject,
              variant,
              syId: yearCodeToId(ed),
            }
          })
        await submitter.mutateAsync(data)
        closeDialog()
      }}
      validationSchema={validationSchema}
    >
      <CurriculumForm
        immutable={true}
        title="Manage curricula"
        variantList={<VariantListManage />}
      />
    </FormDialog>
  )
}

/** Variant list for the manage dialog */
function VariantListManage() {
  const { setFieldValue, values } = useFormikContext<C2FamilyEd>()

  return (
    <VariantList
      curricula={values.curricula}
      directions={{
        existing: <Txt>Manage existing variants</Txt>,
        new: <Txt>Add new variants</Txt>,
      }}
      renderItem={({ curriculum, idx, variant }) => (
        <>
          <VariantActionButtons
            buttons={getManageButtons({
              onAdd: () => {
                const c2 = { _status: 'active', variant: variant.name }
                // todo: someone has written this logic!
                setFieldValue(`curricula.${values.curricula.length}`, c2)
              },
              id: curriculum?.id,
              status: curriculum?._status,
            })}
            onChange={(action) => {
              setFieldValue(`curricula.${idx}._status`, action)
            }}
            preventNone={!!curriculum?.id}
            selectedValue={curriculum?._status}
            variant={variant.name}
          />
          {curriculum?._status && getC2NameAndId(values, variant.name).name}
        </>
      )}
    />
  )
}
