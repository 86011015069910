import {
  BoxProps,
  Button,
  ButtonProps,
  List,
  ListItem,
  StyleProps,
  useColorMode,
} from '@chakra-ui/react'
import { IcoAsterisk } from '@paper/icons'
import { useRouter, YearSlugs } from '@paper/route'
import { SchoolYear } from '@paper/schema'
import { DARK_MODE_FG } from '@paper/styles'
import { formatSchoolYear } from '@paper/utils'
import { orderBy } from 'lodash'
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
} from 'react'
import { ErrorActionSection, ErrorPage } from './blocks/errors/errorPageBase'
import { MenuBody, MenuItem, SimpleMenu } from './blocks/menu'
import { useUser } from './blocks/userProvider'
import { BaseHeader, Ex } from './components'
import { LightMode } from './utils/forceMode'

type SchoolYearAirlockProps = { children: ReactNode }

type SchoolYearContext = {
  /** years available to the route */
  availableYears: SchoolYear[]
  /** the year designated as currentYear for the route. (You can select past years so this may be different from the selected year) */
  currentYear?: SchoolYear
  /** `true` if year is 'current year' for the route */
  isCurrentYear: boolean
  /** `true` if the route is year switchable */
  isRouteYearSwitchable: boolean
  /** school year for the current/selected route year */
  routeYear?: SchoolYear
  setYear(id: number): void
  /** school year id for the current (selected) route year  */
  syId?: number
}

const SchoolYearContext = createContext<SchoolYearContext>(null)
export const useSchoolYearContext = () => useContext(SchoolYearContext)

export function SchoolYearAirlock(props: SchoolYearAirlockProps) {
  const { currentRoute, dispatchRoute, dispatchStay, routeData } = useRouter()

  const { year, years: allYears } = useUser()

  let needsUrlChange = false
  let routeYear: SchoolYear
  let availableYears: SchoolYear[] = []
  let currentYear: SchoolYear
  let isRouteYearSwitchable = false

  // parse route
  if (currentRoute.yearType === 'timeless') {
    availableYears = allYears
    // nothing to parse
  } else {
    const [switchable, loadVsSwVsPE, n] = currentRoute.yearType.split('-')

    currentYear = year[loadVsSwVsPE]
    isRouteYearSwitchable = !!(switchable === 'switchable' && currentYear)
    // there may not be a load year
    if (n === 'all' || n == null) {
      availableYears = allYears
    } else if (currentYear) {
      // work backwards pushing available years
      const allYearMap = new Map(allYears.map((sy) => [sy.syId, sy]))
      for (let i = 0; i < parseInt(n); i++) {
        let sy = allYearMap.get(currentYear.syId - i)
        if (sy) {
          availableYears.push(sy)
        }
      }
    }
  }

  availableYears = orderBy(availableYears, (p) => p.syId, 'desc')

  // parse routeData
  if (routeData.yearCode) {
    // current-year magic value redirect
    if (routeData.yearCode === YearSlugs.CurrentYear) {
      routeYear = currentYear
      needsUrlChange = true
    } else {
      try {
        routeYear = availableYears.find((p) => p.code === routeData.yearCode)
      } catch (err) {}
    }
  }

  let is404 =
    (!routeYear && currentRoute.yearType !== 'timeless') ||
    // todo: should this be !currentYear for any non-timeless route?
    (currentRoute.yearType.startsWith('specific') && !currentYear)

  if (is404) {
    needsUrlChange = false
  }

  // todo: useAirlock was not working here
  //useAirlock({ yearCode: routeYear?.code }, needsUrlChange)
  // todo: may have solved this by fixing useStateAndRef, but haven't tested fully yet
  // todo: want to overhaul airlock so any/all airlocks are combined
  // todo: if possible maybe also fingerprint with current url so each one runs once
  useLayoutEffect(() => {
    if (needsUrlChange) {
      //dispatchStay({ yearCode: routeYear?.code }, 'replace')
      // console.log(
      //   '[schoolYearAirlock]',
      //   needsUrlChange,
      //   '[routeData]',
      //   routeData.yearCode,
      //   '[to]',
      //   routeYear?.code
      // )
      dispatchRoute(
        currentRoute.mergeAction({ yearCode: routeYear?.code }, 'replace')
      )
    }
  }, [needsUrlChange])

  const setYear = useCallback(
    (syId: number) => {
      let yearCode = formatSchoolYear(syId)
      if (isRouteYearSwitchable) {
        // some routes make sense to switch years on
        // e.g. a teacher's home across different years
        dispatchStay({ yearCode }, 'push')
      } else {
        // other routes don't e.g. jump-to, where you'd just have to pick a different packet
        // todo: probably want to go up the parent chain until we hit a year-switchable or timeless route?
        dispatchRoute(currentRoute.base.mergeAction({ yearCode }))
      }
    },
    [currentRoute, dispatchStay]
  )

  const ctx: SchoolYearContext = {
    availableYears,
    currentYear,
    isRouteYearSwitchable,
    isCurrentYear: currentYear && currentYear.syId === routeYear?.syId,
    routeYear,
    setYear,
    syId: routeYear?.syId,
  }

  // console.log(
  //   '[schoolYearAirlock]',
  //   routeData.yearCode,
  //   { needsUrlChange },
  //   window.location.pathname
  // )

  let body = is404 ? (
    <SchoolYear404 routeYearCode={routeData.yearCode} />
  ) : needsUrlChange ? null : (
    props.children
  )

  return (
    <SchoolYearContext.Provider value={ctx}>{body}</SchoolYearContext.Provider>
  )
}

// which routes will be under a school-year?
// could potentially use <Router notFound=to add year?>
// hook into parseUrl/buildUrl?
// what behavior do we want when arriving? it potentially seems reasonable to always redirect?

type SchoolYearPickerProps = {
  variant?: 'default' | 'new-home' // todo:
  zIndex?: BoxProps['zIndex']
}

export function SchoolYearPicker(props: SchoolYearPickerProps) {
  const { variant } = props
  let {
    availableYears,
    currentYear,
    isRouteYearSwitchable,
    isCurrentYear,
    routeYear,
    setYear,
  } = useSchoolYearContext()

  // // this year and least year
  // const shownYearSet = new Set([thisYear.syId, thisYear.syId - 1])
  // availableYears = availableYears.filter((ay) => shownYearSet.has(ay.syId))

  const { colorMode } = useColorMode()

  const colorScheme = !isCurrentYear
    ? 'yellow'
    : colorMode === 'dark'
    ? 'blackAlpha'
    : 'gray'

  // todo: clean up this mess!
  const bg = !isCurrentYear
    ? null
    : variant === 'new-home'
    ? DARK_MODE_FG
    : colorMode === 'dark'
    ? '#404040'
    : null

  const variantProps: StyleProps = {
    bg,
    color: bg === '#404040' ? 'whiteAlpha.700' : null,
    [`border${variant === 'new-home' ? '' : 'BottomRight'}Radius`]: 'md',
    position: variant === 'new-home' ? 'relative' : 'fixed',
    top: variant === 'new-home' ? null : `${BaseHeader.Height}px`,
  }

  //console.log({ colorMode, colorScheme, variant, variantProps, props })

  let getButton = (isInert: boolean, props: ButtonProps) => {
    return (
      <Button
        data-cy="sy-picker"
        colorScheme={colorScheme}
        borderRadius={0}
        fontSize="xs"
        fontWeight="500!important"
        height="1.25rem"
        inert={isInert ? 'true' : undefined}
        minHeight="1.25rem"
        size="xs"
        userSelect="none"
        {...props}
        sx={{
          '> span': { marginInlineStart: 1 },
        }}
        variant="solid"
        {...variantProps}
      >
        {routeYear?.code ?? 'No year'}
      </Button>
    )
  }

  return !isRouteYearSwitchable || variant === 'new-home' ? (
    getButton(true, props)
  ) : (
    <LightMode>
      <SimpleMenu
        align="start"
        caret={isRouteYearSwitchable}
        shroud={false}
        sideOffset={0}
        trigger={(menuButtonProps) =>
          getButton(false, { ...menuButtonProps, ...props })
        }
      >
        <MenuBody>
          {availableYears.map((ay) => {
            const isCurrentYear = ay.syId === currentYear?.syId
            return (
              <MenuItem
                key={ay.syId}
                borderInlineStartColor={
                  routeYear?.syId === ay.syId
                    ? isCurrentYear
                      ? 'blue.500'
                      : 'yellow.400'
                    : null
                }
                fontSize="sm"
                onSelect={() => setYear(ay.syId)}
              >
                {ay.code} {isCurrentYear ? `(Current)` : ''}
              </MenuItem>
            )
          })}
        </MenuBody>
      </SimpleMenu>
    </LightMode>
  )
}

const BULLET = '🕰️'

type SchoolYear404Props = { routeYearCode: string }

function SchoolYear404(props: SchoolYear404Props) {
  const { availableYears } = useSchoolYearContext()

  const subject = '🗓️🤔 School Year 404'
  const error = null

  let actions =
    availableYears.length === 0 ? (
      <NoYear404Actions />
    ) : (
      <UnavailableYear404Actions {...props} />
    )

  return (
    <ErrorPage
      actions={actions}
      contactSectionMsg={null}
      data-cy="error-page-404"
      defaultFriendly={`There's nothing at this URL`}
      error={error}
      subject={subject}
    />
  )
}

function NoYear404Actions() {
  return (
    <List>
      <ListItem>
        {BULLET} This likely either means you're in between school years, or
        your admins haven't flipped the calendar yet.
      </ListItem>
    </List>
  )
}

function UnavailableYear404Actions(props: SchoolYear404Props) {
  const {
    availableYears,
    isRouteYearSwitchable,
    setYear,
    currentYear: thisYear,
  } = useSchoolYearContext()
  const { routeYearCode } = props

  let availableForMessageEnd: ReactNode
  let availableForMessageExtra: ReactNode

  let actions: ReactNode

  if (availableYears.length === 0) {
    actions = 'todo: message if no years are available...'
  } else {
    actions = availableYears.map((sy) => (
      <Button
        key={sy.code}
        colorScheme="blue"
        data-cy={`sy404-${sy.code}`}
        data-isswitchable={isRouteYearSwitchable}
        fontFamily="mono"
        fontSize="lg"
        leftIcon={sy.syId == thisYear?.syId ? <IcoAsterisk /> : null}
        onClick={() => setYear(sy.syId)}
        size="md"
        variant={sy.syId == thisYear?.syId ? 'solid' : 'outline'}
      >
        {sy.code}
      </Button>
    ))
    if (isRouteYearSwitchable) {
      availableForMessageEnd = <>but this page is currently available for:</>
    } else {
      availableForMessageEnd = (
        <>but that year is not currently available for this page.</>
      )
      availableForMessageExtra = (
        <ListItem>
          {BULLET} The available years are listed below, however the particular
          curriculum or packet referenced in the URL won't be available as those
          are tied to specific years.
        </ListItem>
      )
    }
  }

  return (
    <ErrorActionSection
      message={
        <List mb={1} spacing={1}>
          <ListItem>
            {BULLET} This is a <Ex>{routeYearCode}</Ex> URL,{' '}
            {availableForMessageEnd}
          </ListItem>
          {availableForMessageExtra}
        </List>
      }
    >
      {actions}
      <List mb={1} spacing={1}>
        <ListItem>
          {BULLET} If you don't see the correct year above, your admins may have
          either already flipped the calendar, or not flipped it yet.
        </ListItem>
      </List>
    </ErrorActionSection>
  )
}
