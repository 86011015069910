import {
  BoxProps,
  Button,
  FormLabel,
  Icon,
  List,
  ListIcon,
  ListItem,
  Stat,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
  UnorderedList,
  useControllableState,
} from '@chakra-ui/react'
import { BodyOf } from '@paper/api-specs'
import { IcoInfo } from '@paper/icons'
import { PacketImportMeta, Page, Question } from '@paper/schema'
import { DEFAULT_FG } from '@paper/styles'
import { ENDASH } from '@paper/utils'
import { ReactNode } from 'react'
import { TableContainer } from '~src/blocks/tableHelpers'
import { Ex, HSep, HStack, Txt, VStack } from '~src/components'
import { SingleInputForm } from '../formHelpers'
import {
  SatelliteAQQResult,
  useSatelliteAQLookup,
} from '../wizard/data-formQuestions'
import { ImportSrcsList } from '../wizard/formQuestionsDetails'
import { QuestionTable } from '../wizard/formQuestionsTable'
import { MatchIcon } from '../wizard/formQuestionStatusIcons'

type QLookupProps = {
  defaultTitle?: string
  onCancel?(): void
  onImport?(values: {
    _import: PacketImportMeta
    _importName: string
    pages: Page[]
    questions: Question[]
  }): void
  /** Specify for controlled version */
  onTitleSubmitted?(value: string): void
  paperData?: Omit<BodyOf<'pe.assessment.lookup'>, 'title'>
  /** Specify for controlled version */
  title?: string
}

/**
 * Messy attempt to share illuminate question lookup between QImport and util
 */
export function QLookup(props: QLookupProps) {
  const { onCancel, onImport, paperData } = props
  const [importQuery, setImportQuery] = useControllableState({
    defaultValue: props.defaultTitle || '',
    value: props.title,
    onChange: props.onTitleSubmitted,
  })
  const qResult = useSatelliteAQLookup({
    contentId: paperData?.contentId ?? '...',
    prevPages: paperData?.prevPages ?? [],
    prevQs: paperData?.prevQs ?? [],
    title: importQuery,
  })

  const da = qResult.data

  const lookupArea = (
    <HStack
      alignItems="stretch"
      flexGrow={1}
      flexShrink={0}
      gap={6}
      overflow="hidden"
      p={1}
    >
      <SingleInputForm
        autoFocus={true}
        formControlProps={{ maxWidth: '500px' }}
        initialValue={importQuery}
        label={
          <FormLabel fontWeight={300} mr={0}>
            Illuminate assessment title
          </FormLabel>
        }
        onSubmit={(value) => {
          let trimmed = value.trim()
          // Set the value
          setImportQuery(trimmed)
          // Illuminate may have changed since they last hit "look up"
          if (trimmed === importQuery) {
            qResult.refetch()
          }
        }}
        placeholder="Paste the assessment title here"
        size="md"
        submitLabel="Look up"
        validate={(value) =>
          value?.length > 255 ? 'Title can be at most 255 characters' : null
        }
      />
    </HStack>
  )

  // hide table completely while fetching
  const tableArea = !qResult.isFetching && (
    <>
      <HSep boxProps={{ mb: 2 }} />
      <TableContainer
        fontSize="sm"
        hideWhenFetching={true}
        qResult={qResult}
        width="100%"
      >
        <QuestionTable data={da?.diffedQs ?? []} mode="import" />
      </TableContainer>
    </>
  )

  // Import button
  const canImport = !qResult.isFetching && qResult.isSuccess && da?.isImportable

  const importButton = onImport && (
    <VStack alignItems="stretch" gap={2} width="250px">
      <Button
        data-cy="btn-q-import"
        isDisabled={!canImport}
        onClick={() => {
          const importChoice = da.candidates[0]
          onImport({
            _import: {
              srcs: importChoice.srcs,
              time: new Date().valueOf(),
            },
            _importName: importQuery,
            pages: importChoice.pages,
            questions: importChoice.questions,
          })
        }}
      >
        Import
      </Button>
      <Txt fontSize="xs" opacity={canImport ? null : 0.5} px={1}>
        <Icon as={IcoInfo} mb="-1px" mr={1} />
        This is a{' '}
        <Txt as="span" fontWeight="bold">
          one-time
        </Txt>{' '}
        import.
        <Txt as="span" display="block">
          If you make changes in Illuminate, you'll need to return here and{' '}
          <Txt as="span" fontWeight="bold">
            reimport
          </Txt>{' '}
          the questions.
        </Txt>
      </Txt>
    </VStack>
  )

  return (
    <VStack
      alignItems="stretch"
      flexGrow={1}
      gap={2}
      // todo: since this component is shared, this sizing could be brittle...
      maxHeight="100%"
      maxWidth="1400px"
      px={4}
      py={1}
    >
      <HStack>
        {lookupArea}
        {onCancel && (
          <Button onClick={onCancel} size="sm" variant="outline">
            Cancel
          </Button>
        )}
      </HStack>
      <HSep boxProps={{ mb: 2 }} />
      <HStack alignItems="stretch" gap={8}>
        <ImportSummary qResult={qResult} />
        {importButton}
      </HStack>
      {tableArea}
    </VStack>
  )
}

type ImportSummaryProps = {
  qResult: SatelliteAQQResult
}

type ImportStat = 'Found' | 'Questions' | 'Points' | 'Issues'

type StatSpec = {
  number: ReactNode
  alreadyWrapped?: boolean
  extra?: ReactNode
}

const errorProps: Pick<BoxProps, 'color' | 'fontWeight'> = {
  color: 'red.600',
  fontWeight: 400,
}

function ImportSummary(props: ImportSummaryProps) {
  const { qResult } = props
  const { data } = qResult
  const da = data

  const stats: Record<ImportStat, StatSpec> = {
    Found: {
      number: da?.foundCount ?? 0,
    },
    Questions: { number: da?.questionCount },
    Points: {
      number: da?.ptsSum,
    },
    Issues: { number: da?.issueCount },
  }
  // Add error summaries
  if (!qResult.isFetching && qResult.isSuccess) {
    // issues
    if (stats.Issues.number) {
      stats.Issues.extra = (
        <StatHelpText {...errorProps}>
          All issues must be resolved in Illuminate before import.
        </StatHelpText>
      )
    }
    if (!da?.foundCount) {
      stats.Found.extra = (
        <StatHelpText {...errorProps} pr={2}>
          No assessments found
          <UnorderedList color={DEFAULT_FG} fontSize="xs" mt={1}>
            <ListItem>
              Be sure to paste the title exactly as it appears in Illuminate
            </ListItem>
            <ListItem>
              The assessment must be shared with the <Ex>System Admin</Ex> role
            </ListItem>
            <ListItem>The assessment must be associated with a year</ListItem>
          </UnorderedList>
        </StatHelpText>
      )
    }
    // match status
    else if (da?.candidates?.length) {
      let text =
        da?.foundCount === 1 ? null : da?.candidates.length === 1 ? (
          <StatHelpText>Matching assessments</StatHelpText>
        ) : (
          <StatHelpText {...errorProps}>Assessments much match</StatHelpText>
        )

      stats.Found.extra = (
        <>
          {text}
          <List>
            {da.candidates.map((c, idx) => (
              <ListItem
                alignItems="center"
                display="flex"
                key={idx}
                borderTopWidth={idx === 0 ? null : 1}
                py={1}
              >
                <MatchIcon
                  Component={ListIcon}
                  status={idx === 0 ? 'match' : 'doesntMatch'}
                />
                <ImportSrcsList srcs={c.srcs} />
              </ListItem>
            ))}
          </List>
        </>
      )
    }
    // questions
    // todo: the sql query doesn't return assessments with 0 questions
    if (da?.foundCount > 0 && stats.Questions.number === 0) {
      stats.Questions.extra = (
        <StatHelpText {...errorProps}>No questions found.</StatHelpText>
      )
    }
  }

  // Stat loading state
  const isIdle = qResult.fetchStatus === 'idle' && qResult.isPending
  const statNumberColor = qResult.isFetching || isIdle ? 'gray.200' : null
  const doStatNumber = (statSpec: StatSpec) => {
    let content = qResult.isFetching
      ? `·  ·  ·`
      : isIdle
      ? ENDASH
      : statSpec.number
    return statSpec.alreadyWrapped && content === statSpec.number ? (
      statSpec.number
    ) : (
      <StatNumber color={statNumberColor}>{content}</StatNumber>
    )
  }

  return (
    <StatGroup flexGrow={1} gap={12}>
      {Object.entries(stats).map(([label, stat]) => (
        <Stat key={label}>
          <StatLabel>{label}</StatLabel>
          {doStatNumber(stat)}
          {stat.extra}
        </Stat>
      ))}
    </StatGroup>
  )
}
