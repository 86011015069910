import { PacketType, Pos } from '@paper/schema'
import { UnionStringArray } from '@paper/utils'
import { FieldMetaProps } from 'formik'
import { ContentFormSchema, SB } from './entryBaseData'
import { FormPacketName } from './formPacketName'
import { FormPacketTypeAndPages } from './formPacketType'
import { FormPages } from './formPages'
import { FormParts } from './formParts'
import { FormPassages } from './formPassages'
import { FormQRLabel } from './formQR'
import { FormQuestions } from './formQuestions'
import { FormUpload } from './formUploadPdf'
import { summarizeArray } from './stepSummary'
import { WizState, WizWhich } from './wizReducer'

// APPARENTLY BITWISE VALUES ARE ALL-CAPS
export let NON_PRINT_ASSESS = SB.PASSAGES | SB.QUESTIONS | SB.PAGES
let REQ_RAW_IMAGES = SB.PACKET_TYPE | SB.PACKET_NAME
let REQ_BLANK_IMAGES = SB.PARTS | NON_PRINT_ASSESS | SB.REVIEW | SB.PUBLISH
let REQ_PDF = REQ_RAW_IMAGES | REQ_BLANK_IMAGES | SB.QR | SB.REVIEW

// defining here for typing purposes :/
// i tried to infer them from the prereqQueue
// but then lost typing of the queue definitions
const prereqIds = [
  'fetchPacketData',
  'fetchPdf',
  'captureRaw',
  'generateBlanks',
  'captureBlanks',
] as const

export type PrereqId = UnionStringArray<typeof prereqIds>

type PrereqDef = {
  id: PrereqId
  errorIf?(): boolean
  queueIf(): boolean
}

export function getPrereqQueue(ctx: WizState) {
  let stepBit = ctx.curStep.bit
  let hasBlanks = !!(ctx._capturedBlanks || ctx._savedBlanks)
  let matchesStep = (stepOrSteps: number) => !!(stepBit & stepOrSteps)

  let defs: PrereqDef[] = [
    {
      id: 'fetchPdf',
      queueIf: () => !ctx.srcBuf && matchesStep(REQ_PDF),
      errorIf: () => !ctx._savedPdfUrl,
    },
    {
      id: 'captureRaw',
      queueIf: () => !ctx.rawImages && matchesStep(REQ_RAW_IMAGES),
      errorIf: () => !ctx.srcBuf,
    },
    {
      id: 'generateBlanks',
      queueIf: () =>
        !hasBlanks && !ctx._blanksPdfBlob && matchesStep(REQ_BLANK_IMAGES),
      errorIf: () => !ctx.srcDoc || !ctx.contentId,
    },
    {
      id: 'captureBlanks',
      queueIf: () => !hasBlanks && matchesStep(REQ_BLANK_IMAGES),
      errorIf: () => !ctx._blanksPdfBlob,
    },
  ]

  let filtered = defs.filter((p) => p.queueIf())
  let error: any
  if (filtered[0]?.errorIf()) {
    error = 'invalid state'
  }
  let queue = filtered.map((pr) => pr.id)

  return { curPrereq: queue[0], error, queue }
}

type StepContext<T, K extends keyof T> = {
  isCurrent: boolean
  isNew: boolean
  isFuture: boolean
  values: T
} & FieldMetaProps<T[K]>

type LockContext = { isNew: boolean; isPast: boolean }

export type StepSpec<
  T extends Record<string, any> = any,
  K extends keyof T = string,
> = {
  bit: SB
  Form: any
  getLocked?: (ctx: LockContext) => boolean
  getSummary?: (ctx: StepContext<T, K>) => string
  key: K
  noPrev?: boolean
  noWizardNav?: boolean
  title: string
  unit?: string
}

type Steps<T> = {
  [K in keyof T]?: StepSpec<T, K>
}

const QRPosLabelMap: Record<Pos, string> = {
  bl: 'Bottom-left',
  br: 'Bottom-right',
  tl: 'Top-left',
  tr: 'Top-right',
}

export const steps: Steps<ContentFormSchema> = {
  _idle: {
    bit: SB.PREINIT,
    Form: null,
    key: null,
    noWizardNav: true,
    title: null,
  },
  _pdf: {
    bit: SB.OPEN_PDF,
    Form: null,
    getLocked: ({ isNew, isPast }) => !isNew || isPast,
    key: '_pdf',
    noWizardNav: true,
    title: 'PDF',
  },
  _placeholder: {
    bit: null,
    Form: null,
    key: null,
    title: '·  ·  ·',
  },
  _uploadChecklist: {
    bit: SB.REVIEW,
    Form: FormUpload,
    key: null,
    title: 'Review',
  },
  name: {
    bit: SB.PACKET_NAME,
    Form: FormPacketName,
    getSummary: (ctx) => ctx.value,
    key: 'name',
    title: 'Packet Name',
  },
  pages: {
    bit: SB.PAGES,
    Form: FormPages,
    getSummary: (ctx) => summarizeArray(ctx.value, 'pages'),
    key: 'pages',
    title: 'Pages',
  },
  parts: {
    bit: SB.PARTS,
    Form: FormParts,
    getSummary: (ctx) => summarizeArray(ctx.value, 'parts'),
    key: 'parts',
    title: 'Parts',
  },
  passages: {
    bit: SB.PASSAGES,
    Form: FormPassages,
    getSummary: (ctx) => summarizeArray(ctx.value, 'passages', { zero: 'no' }),
    key: 'passages',
    title: 'Passages',
  },
  questions: {
    bit: SB.QUESTIONS,
    Form: FormQuestions,
    getSummary: (ctx) =>
      summarizeArray(ctx.value, 'questions', { zero: 'empty' }),
    key: 'questions',
    noPrev: true, // note: b/c of form split
    title: 'Questions',
  },
  style: {
    bit: SB.QR,
    Form: FormQRLabel,
    getSummary: (ctx) => {
      return [
        QRPosLabelMap[ctx.value.position],
        `${ctx.value.margin}" margin`,
        ctx.value.variant,
      ]
        .filter((p) => p)
        .join(', ')
    },
    key: 'style',
    title: 'QR Label',
  },
  type: {
    bit: SB.PACKET_TYPE,
    Form: FormPacketTypeAndPages,
    getLocked: ({ isNew, isPast }) => isPast && !isNew,
    getSummary: (ctx) => {
      const { parts, pages, type } = ctx.values
      switch (type) {
        case 'ticket':
          return `${pages.length}-sided ticket, page ${parts[0] + 1 || '?'}`
        case 'assessment':
          return `${pages.length} page assessment`
        default:
          return null
      }
    },
    key: 'type',
    title: 'Packet Type',
  },
}

/**
 * Returns the list of steps for a given packet type
 */
export function resolveSteps(type: PacketType, which: WizWhich): StepSpec[] {
  //console.log('resolveSteps', type)

  let commonStart: StepSpec[] = [
    steps._pdf,
    steps.type,
    steps.name,
    steps.style,
  ]
  switch (type) {
    case 'assessment':
      commonStart = [...commonStart, steps.parts, steps._uploadChecklist]
      break
    case 'ticket':
      commonStart = [...commonStart, steps._uploadChecklist]
      break
    default:
      commonStart = [...commonStart, steps._placeholder]
      break
  }

  switch (which) {
    case 'pdf':
      return commonStart
    case 'answerKey':
      return [...commonStart, steps.questions, steps.passages, steps.pages]
    default:
      return [steps._placeholder]
  }
}
