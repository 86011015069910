import { Box } from '@chakra-ui/react'
import { Fragment, memo, useCallback } from 'react'
import { AppTitle, VStack } from '~src/components'
import { Routes } from '~src/routelist'
import { useLimitToInternal } from '~src/utils/permissions'
import { ScanVizHeader } from './scanVizHeader'
import { ScanVizProvider, useScanVizContext } from './scanVizProvider'
import { useScanVizSettings } from './scanVizSettings'
import { ScanVizShape, useScanVizShapes } from './scanVizShapes'

export function SecretScanVizPage() {
  useLimitToInternal()
  return (
    <ScanVizProvider>
      <ScanViz />
    </ScanVizProvider>
  )
}

type OnClick = (batch: ScanVizShape) => void

function ScanViz() {
  const { data, emphasize, hideUninteresting } = useScanVizContext()

  // todo: move these into useScanVizContext()
  const { settings } = useScanVizSettings()
  // convert data to specs to draw shapes
  const shapes = useScanVizShapes({ data, settings })

  const onClick = useCallback<OnClick>(
    (batch) => {
      window.open(
        Routes.sw_scanlog.buildUrl({
          deviceName: batch.deviceName,
          sb_batchId: batch.id,
          scanDate: batch.scanDate,
          status: hideUninteresting ? emphasize : null,
          // maybe omit these to show in context?
          // packetId: batch.packetId,
          // teacherId: batch.teacherId,
        })
      )
    },
    [hideUninteresting, emphasize]
  )

  return (
    <VStack
      alignItems="stretch"
      bg={settings.bg}
      overflow="hidden"
      height="100vh"
      width="100vw"
    >
      <AppTitle title="Scan viz" />
      <ScanVizHeader />
      <Box overflow="auto">
        <ScanVizSvg onClick={onClick} shapes={shapes} />
      </Box>
    </VStack>
  )
}

type ScanVizRenderProps = {
  onClick: OnClick
  shapes: ScanVizShape[]
}

const ScanVizSvg = memo(function ScanVizSvg(props: ScanVizRenderProps) {
  console.log('[render] <ScanVizSvg />')
  const { onClick, shapes } = props
  const r = 0.5
  return (
    <svg
      viewBox={`0 0 ${shapes.length} 1000`}
      style={{
        maxHeight: '1000%',
        maxWidth: '100%',
        //transform: 'scale(16)',
        transformOrigin: 'top left',
      }}
    >
      {shapes.map((batch, x) => (
        <Fragment key={batch.id}>
          <rect
            x={x}
            y={0}
            fill={batch.fill}
            width={1}
            height={batch.length}
            style={{ cursor: 'pointer' }}
            onClick={() => onClick(batch)}
          />
          {batch.dots.length > 0 && (
            <g pointerEvents="none">
              {batch.dots.map((dot, dotIdx) => (
                <circle
                  key={dotIdx}
                  cx={x + r}
                  cy={dot.y + r}
                  fill={dot.fill}
                  r={r}
                />
              ))}
            </g>
          )}
          {batch.label && (
            <text
              fill="white"
              fontSize="1px"
              textAnchor="start"
              style={{
                fontFamily: 'monospace',
                pointerEvents: 'none',
                userSelect: 'none',
                writingMode: 'vertical-lr',
              }}
              x={x + 0.5}
              y={0.1}
            >
              {batch.label}
            </text>
          )}
        </Fragment>
      ))}
    </svg>
  )
})

// const ScanVizCanvas = memo(function ScanVizCanvas(props: ScanVizRenderProps) {
//   const { shapes: data } = props
//   const canvasRef = useRef<HTMLCanvasElement>()

//   const maxBatchSize = 1000
//   const PX = 1

//   const canvasHeight = maxBatchSize * PX
//   const canvasWidth = data.length * PX

//   useEffect(() => {
//     console.log('[memo-ish] canvas')
//     const canvas = canvasRef.current
//     // clear canvas
//     const ctx = canvas.getContext('2d')
//     ctx.clearRect(0, 0, canvasWidth, canvasHeight)
//     // todo:
//     // ctx.fillStyle = settings.bg
//     // ctx.fillRect(0, 0, canvasWidth, canvasHeight)

//     // loop through batches drawing bars
//     for (let batchIdx = 0; batchIdx < data.length; batchIdx++) {
//       const batch = data[batchIdx]

//       // draw the bar
//       const x = batchIdx * PX
//       ctx.fillStyle = batch.fill
//       ctx.fillRect(x, 0, PX, batch.length * PX)

//       // drawing dots
//       for (let chunk of batch.dots) {
//         ctx.fillStyle = chunk.fill
//         ctx.fillRect(x, chunk.y * PX, PX, PX)
//       }
//     }
//   }, [canvasRef.current, data])

//   return (
//     <canvas
//       style={{
//         // transform: `scale(${windowWidth / data.length})`,
//         // transformOrigin: 'top left',
//         maxHeight: '100%',
//         maxWidth: '100%',
//       }}
//       ref={canvasRef}
//       height={canvasHeight}
//       width={canvasWidth}
//     />
//   )
// })
