export type DiffResult = {
  allChanged: boolean
  modifiedKeys: string[]
  status: 'modified' | 'unchanged'
}

/**
 * todo: surely there's a library that does this better...
 * todo: adapted from the version i wrote for the staging diff
 */
export function diffObjDeep<T extends object>(
  old: T,
  next: T,
  prefix = ''
): DiffResult {
  const modifiedKeys: string[] = []
  const allKeys = new Set([old, next].flatMap(Object.keys))

  for (let key of allKeys) {
    const fullKey = `${prefix}${key}`

    if (old[key] !== next[key]) {
      if (
        old[key] == null ||
        next[key] == null ||
        typeof next[key] !== 'object'
      ) {
        modifiedKeys.push(fullKey)
      } else if (next[key] instanceof Date) {
        if (next[key].valueOf() !== old[key].valueOf()) {
          modifiedKeys.push(fullKey)
        }
      } else {
        const childResult = diffObjDeep(old[key], next[key], `${fullKey}.`)
        if (childResult.allChanged) {
          modifiedKeys.push(fullKey)
        } else {
          modifiedKeys.push(...childResult.modifiedKeys)
        }
      }
    }
  }

  const status = modifiedKeys.length > 0 ? 'modified' : 'unchanged'
  const allChanged =
    modifiedKeys.length > 0 && modifiedKeys.length === allKeys.size
  return { allChanged, modifiedKeys, status } as const
}
