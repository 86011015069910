import { useEffect, useLayoutEffect, useRef, useState } from 'react'

/**
 * `useState` but resets state to `initialValue` if `resetOnChange` changes
 */
export function useStateResetOnChange<T>(initialValue: T, resetOnChange: any) {
  const resetOnChangeRef = useRef(resetOnChange)
  const [value, setValue] = useState<T>(initialValue)
  useLayoutEffect(() => {
    if (resetOnChangeRef.current !== resetOnChange) {
      // todo: is there a nicer way to handle prop changes inside the component?
      // in this case, i actually want to remount if src changes
      // could accomplish this by (more or less) <ImagePage key={src} ... />
      // but that forces the caller to remember
      // this approach has an extra render...
      resetOnChangeRef.current = resetOnChange
      setValue(initialValue)
    }
  }, [resetOnChange])
  return [value, setValue] as const
}

/**
 * `useState` that has an intermediate local value
 * @example
 * // source of truth is in `routeData`
 * const { localValue, setLocalValue, value } = useStateAndPropForm(routeData.date)
 * // But we don't necessarily want to update on every change to the date picker
 * <>
 *  <Input onChange={event => setLocalValue(event.target.value)} value={localValue} />
 *  <Button type='submit' onClick={() => dispatchStay({ date: localValue })} />
 * </>
 */
export function useStateAndPropForm<T>(propValue: T) {
  const value = propValue?.toString()
  const [localValue, setLocalValue] = useState(value ?? '')
  useEffect(() => {
    let coerced = value ?? ''
    if (coerced !== localValue) {
      // todo: will this fire Input's onChange?
      setLocalValue(coerced)
    }
  }, [value])

  return { localValue, setLocalValue, value }
}
