import { createIcon } from '@chakra-ui/react'

export const IcoQ = createIcon({
  displayName: 'IcoQ',
  d: 'M 402 747.5 q -39 -31.5 -39 -110.5 q -52 -7 -97 -44.5 t -72.5 -106 t -27.5 -164.5 q 0 -116 34 -188 t 84.5 -103 t 103.5 -31 q 60 0 111 34 t 81 106 t 28 179 q 0 138 -46.5 218 t -137.5 100 q 2 37 18.5 56 t 55.5 19 l 81 -5 l -2 68 h -39 q -97 4 -136 -27.5 z M 463.5 550.5 q 33.5 -24.5 55.5 -78 t 22 -136.5 q 0 -104 -23.5 -165 t -58 -85.5 t -71.5 -24.5 q -74 0 -114 71.5 t -40 174.5 q 0 120 42.5 194 t 116.5 74 q 37 0 70.5 -24.5 z',
  viewBox: '0 0 775 775',
})

export const IcoStd = createIcon({
  displayName: 'IcoStd',
  d: 'M 490 363 q 34 39 34 98 q 0 46 -22.5 87 t -69.5 67 t -116 26 q -121 0 -195 -79 l 36 -63 v -1 v 0.5 q 27 36 69.5 57.5 t 98.5 21.5 q 55 0 92 -31 t 37 -76 q 0 -29 -11.5 -48.5 t -41.5 -38.5 t -90 -45 q -92 -36 -131.5 -82 t -39.5 -103 q 0 -70 51 -112 t 139 -42 q 51 0 95.5 20.5 t 77.5 57.5 l -39 52 l -1 1 q -30 -41 -62.5 -57 t -81.5 -16 q -48 0 -77.5 26.5 t -29.5 64.5 q 0 29 12.5 50.5 t 45.5 42 t 96 45.5 q 89 37 123 76 z',
  viewBox: '0 0 642 642',
})

export const IcoTeacher = createIcon({
  displayName: 'IcoTeacher',
  d: 'M 273 624 v -561 h -177 v -63 h 432 v 63 h -184 v 561 h -71 z',
  viewBox: '0 0 624 624',
})
