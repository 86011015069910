import { FileLocation } from '@paper/schema'
import { defineApi } from '../defineApi'

export const examplify_file = defineApi<{
  method: 'put'
  body: {
    dryRun?: boolean
    dest: FileLocation
    src: FileLocation
  }
  result: { status: 'copied' | 'error' | 'notfound' | 'unchanged' }
}>({
  app: 'examplify',
  method: 'put',
  urlFactory: `file`,
})
