import { Curriculum } from '../types/school'
import { Yup, yupMin0Int } from './v-utils'

export const yupCurriculum = Yup.object<Curriculum>({
  _status: Yup.string()
    .oneOf<Curriculum['_status']>(['active', 'recalled', 'retired'])
    .required(),
  ed: Yup.string().required(),
  family: Yup.string().required(),
  id: Yup.string().required(),
  levels: Yup.array(Yup.string().required()),
  name: Yup.string().required(),
  subject: Yup.string().required(),
  syId: yupMin0Int.required(),
  variant: Yup.string().required(),
})
