import { Box, HStack, Icon, Table, Tbody, Td, Tr } from '@chakra-ui/react'
import { IcoAsterisk, IcoImage, IcoList } from '@paper/icons'
import { ScanFixCandidatePage, ScanStatus } from '@paper/schema'
import { formatQRBackup, getFullName } from '@paper/utils'
import { useEffect, useRef } from 'react'
import { ImagePage } from '~src/blocks/imageViewer'
import {
  IconColumnProps,
  IconLabel,
} from '~src/blocks/packetTable/tableColUtils'
import { Ex, Italic, Mono, TextStack } from '~src/components'
import { UghColumn } from '~src/components/table'
import config from '~src/utils/config'
import { useScanFixContext, useScanlogContext } from '../scanlogAirlock'
import { Fixable } from './scanFixContext'

type Col = UghColumn<Fixable>

export const DirtyColorScheme = 'pink'
export const Dirty500 = `${DirtyColorScheme}.500`

const DirtyColumn: Col = {
  props: IconColumnProps,
  label: () => <IconLabel icon={IcoAsterisk} textValue="Unsaved changes" />,
  cell: (item) => {
    const isDirty = item.changeState !== 'none'
    return (
      <Icon
        aria-label={isDirty ? 'Unsaved changes' : 'Unchanged'}
        as={IcoAsterisk}
        color={isDirty ? `${DirtyColorScheme}.500` : 'gray.50'}
        fontSize="xl"
      />
    )
  },
}

const DataColumn: Col = {
  props: { width: 300 },
  label: () => <IconLabel icon={IcoList} textValue="Image data" />,
  cell: (item) => {
    return <ScanFixDataRouter fixable={item} />
  },
}

type ScanFixDataRouterProps = { fixable: Fixable }

function ScanFixDataRouter(props: ScanFixDataRouterProps) {
  const { fixable } = props
  const { scanFix, xpacketSetDigest } = useScanlogContext()
  const { candidateResult, changes, editState, isDirty } = scanFix

  // todo: choose between candidates
  const data = isDirty ? fixable.candidates?.[changes] : fixable.savedData

  // todo: move this...
  const isTableSet = new Set<ScanStatus>([
    'infer',
    'infer-gap',
    'manual',
    'success',
  ])

  // todo: these states are a mess!
  const isWaiting =
    candidateResult.isFetching ||
    xpacketSetDigest.qResult.isFetching ||
    (fixable.changeState === 'pending' && candidateResult.isPending)
  const isTable =
    (fixable.changeState === 'none' && isTableSet.has(fixable.status)) ||
    (!isNaN(fixable.changeState as any) && data?.status !== 'blank')

  if (
    isWaiting ||
    editState === 'waiting-for-choice' ||
    editState === 'no-results'
  ) {
    return null
  } else if (isTable && data) {
    return <ScanFixDataTable data={data} isDirty={isDirty} />
  } else if (data?.status === 'blank') {
    return <Ex color={Dirty500}>infer-blank</Ex>
  } else if (isTable) {
    console.log(fixable)
    return <Italic>Something went wrong</Italic>
  } else if (fixable.changeState === 'ignore') {
    return <Ex color={Dirty500}>ignore</Ex>
  } else {
    return <Ex>{fixable.status}</Ex>
  }
}

type ScanFixDataTableProps = { isDirty: boolean; data: ScanFixCandidatePage }

/**
 * The table of data about the image (that lives inside the table)
 */
function ScanFixDataTable(props: ScanFixDataTableProps) {
  const { data, isDirty } = props
  const { packet, packetIndex, qrb, status, student } = data

  const rows = [
    ['Status', status],
    ['Page number', packetIndex + 1],
    [
      'Printed code',
      <Mono>
        {config.meta.dbId}: {formatQRBackup(qrb)}
      </Mono>,
    ],
    ['Student', getFullName(student) ?? '<unnamed>'],
    [
      'Packet',
      <TextStack>
        <TextStack.Top fontFamily="mono" fontSize="xs">
          {packet.number}
        </TextStack.Top>
        <TextStack.Bottom whiteSpace="normal" variant="loose">
          {packet.name}
        </TextStack.Bottom>
      </TextStack>,
    ],
  ]
  return (
    <Table size="sm" sx={{ td: { fontSize: 'xs' } }} width="292px">
      <Tbody>
        {rows.map(([label, value], idx) => (
          <Tr key={idx}>
            <Td>{label}</Td>
            <Td color={isDirty ? Dirty500 : null}>{value}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

const imageHeight = 240
const imageWidth = imageHeight * 4

const ImageColumn: Col = {
  props: { align: 'start', width: imageWidth },
  label: () => <IconLabel icon={IcoImage} textValue="Scan" />,
  cell: (item) => {
    return (
      <HStack
        bg="gray.100"
        borderRadius="md"
        height={imageHeight - 7}
        p={1.5}
        width={imageWidth - 4}
      >
        <Box height="100%" overflow="hidden" width="100%">
          <ScanFixImage src={item.src} />
        </Box>
      </HStack>
    )
  },
}

type ScanFixImageProps = { src: string }

// need a separate component for hooks...
function ScanFixImage(props: ScanFixImageProps) {
  const { src } = props
  const { panEntangler, pos } = useScanFixContext()
  const ref = useRef<HTMLImageElement>()
  const { quadrant } = pos

  useEffect(() => {
    if (ref.current) {
      panEntangler.current.register(ref)
      return () => panEntangler.current.unregister(ref)
    }
  }, [])

  let scale = quadrant ? 10 : null

  return (
    <ImagePage
      src={src}
      transformProps={
        !!pos && {
          onPan: (delta) => panEntangler.current.onPan(delta),
          ref,
          scale,
        }
      }
    />
  )
}

export const scanFixTableCols = [DirtyColumn, DataColumn, ImageColumn]
