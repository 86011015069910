import { Table, Tbody, Td, Thead, Tr } from '@chakra-ui/react'
import { APIs } from '@paper/api-specs'
import { IcoExclamationTriangle } from '@paper/icons'
import { useRouter } from '@paper/route'
import { CM, Invalid, LoadMeta, School } from '@paper/schema'
import { objectToFlatEntries, setDefault } from '@paper/utils'
import { ReactNode, useMemo } from 'react'
import { MainMenuDrawer } from '~src/blocks/mainMenu/drawer'
import { FilterPicker } from '~src/blocks/pickers/filterPicker'
import {
  AppTitle,
  BaseHeader,
  Ex,
  H3,
  HStack,
  TextStack,
  ToggleGroup,
  VSep,
  VStack,
} from '~src/components'
import { FullPageLoading } from '~src/components/status'
import { useApiQuery } from '~src/data/useApiQuery'
import { RD_Setup_Issues } from '~src/routelist'

export function SetupIssues() {
  const qResult = useSetupIssuesData()

  return (
    <BaseHeader.Container>
      <AppTitle title="Setup issues" />
      <BaseHeader stackGap="1rem">
        <MainMenuDrawer icon={IcoExclamationTriangle} /> Setup issues
      </BaseHeader>
      <BaseHeader.Body px={12}>
        <FullPageLoading qResult={qResult}>
          <SetupIssuesBody data={qResult.data} />
        </FullPageLoading>
      </BaseHeader.Body>
    </BaseHeader.Container>
  )
}

type SetupIssuesData = { issues: LoadMeta['errors']; schools: School[] }

const useSetupIssuesData = () => {
  return useApiQuery({
    apiSpec: APIs['setup.issues'],
    queryVars: {},
    queryFn: async ({ plainFetch }): Promise<SetupIssuesData> => {
      let issues = await plainFetch()
      let schoolMap = new Map(
        issues.section
          ?.map<[string, string]>(
            (p) => p.fullItem && [p.fullItem.schoolId, p.fullItem.schoolName]
          )
          .filter((p) => p)
      )

      let schools = Array.from(schoolMap).map(([id, name]) => ({ id, name }))

      return { issues, schools }
    },
    useQueryProps: {},
  })
}

type SetupIssuesBodyProps = { data: SetupIssuesData }

function SetupIssuesBody(props: SetupIssuesBodyProps) {
  const { data } = props
  const { issues, schools } = data
  const { dispatchStay, routeData, useAirlock } = useRouter<RD_Setup_Issues>()

  const keys = Object.keys(issues).filter(
    (p) => issues[p]?.length
  ) as (keyof CM)[]

  const issueColl = routeData.issueColl as keyof CM
  const selectedIssueColl = useMemo(() => {
    let base = issues[issueColl] as Invalid<any>[]
    if (issueColl === 'section' && routeData.schoolId) {
      base = base.filter((p) => p.fullItem.schoolId === routeData.schoolId)
    }
    return base
  }, [issues[issueColl], routeData.schoolId, issueColl])

  useAirlock({ issueColl: keys[0] }, issues && keys[0] && !issues[issueColl])

  // todo: this would ideally be an id, but that's more work since need to be able to idify any type of object
  const issueItem = routeData.issueItem ?? '0'

  const issueData = issues[issueColl]?.[parseInt(issueItem)]

  const sharedRootProps = {
    colorScheme: 'red',
    preventNone: true,
    shape: '90deg',
    type: 'single',
  } as const

  if (!keys?.length) {
    return <>Yay! No issues right now.</>
  }

  return (
    <HStack alignItems="start" gap={8}>
      <SetupIssueColumn title="Issue source">
        <ToggleGroup.Root
          {...sharedRootProps}
          onChange={(nextValue) => dispatchStay({ issueColl: nextValue })}
          value={issueColl}
        >
          <VStack>
            {keys.map((key) => (
              <ToggleGroup.Button key={key} value={key}>
                {key}
              </ToggleGroup.Button>
            ))}
          </VStack>
        </ToggleGroup.Root>
      </SetupIssueColumn>
      <VSep />
      <SetupIssueColumn title="Issues as of the most recent load">
        <VStack gap={4}>
          {issueColl === 'section' && <SchoolPicker data={schools} />}
          <ToggleGroup.Root
            {...sharedRootProps}
            onChange={(nextValue) => dispatchStay({ issueItem: nextValue })}
            value={issueItem}
          >
            <VStack>
              {!selectedIssueColl?.length ? (
                <>No issues</>
              ) : (
                selectedIssueColl?.map((inv, idx) => {
                  const issue = inv.issues[0]
                  return (
                    <ToggleGroup.Button
                      fontSize="sm"
                      gap={4}
                      height="auto"
                      key={idx}
                      py={1}
                      value={idx.toString()}
                    >
                      {inv.fullItem.schoolId}
                      <TextStack alignItems="start">
                        <TextStack.Top fontFamily="mono">
                          {issue.path}
                        </TextStack.Top>
                        <TextStack.Bottom fontSize="sm" variant="loose">
                          {issue.message}
                        </TextStack.Bottom>
                      </TextStack>
                    </ToggleGroup.Button>
                  )
                })
              )}
            </VStack>
          </ToggleGroup.Root>
        </VStack>
      </SetupIssueColumn>
      <VSep />
      <SetupIssueColumn title="Selected issue details">
        <SetupIssueTable data={issueData} />
      </SetupIssueColumn>
    </HStack>
  )
}

type SetupIssueTableProps = { data: Invalid<any> }
type SetupIssueEntry = { key: string; value: any; issues?: string[] }

function SetupIssueTable(props: SetupIssueTableProps) {
  const { data } = props

  const fields = useMemo(() => {
    let fields = (objectToFlatEntries(data?.fullItem) ??
      []) as SetupIssueEntry[]
    // todo: do this elsewhere probably!
    data?.issues.forEach((iss) => {
      const field = fields.find((f) => f.key === iss.path)
      if (!field) {
        console.warn(`${iss.path} not found in`, field)
      } else {
        let arr = setDefault(field, 'issues', [])
        arr.push(iss.message)
      }
    })
    return fields
  }, [data])

  return (
    <Table size="sm" sx={{ td: { py: 2 } }}>
      <Thead>
        <Tr>
          <Td>Field</Td>
          <Td>Value</Td>
          <Td>Issue</Td>
        </Tr>
      </Thead>
      <Tbody>
        {fields.map(({ key, value, issues }) => (
          <Tr bg={issues?.length ? 'red.200' : null} key={key}>
            <Td fontFamily="mono">{key}</Td>
            <Td>
              <Ex my={1}>{JSON.stringify(value)}</Ex>
            </Td>
            <Td fontWeight="bolder">{issues?.join(', ')}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

type SchoolPickerProps = { data: School[] }

function SchoolPicker(props: SchoolPickerProps) {
  const { data } = props
  const { dispatchStay, routeData, useAirlock } = useRouter<RD_Setup_Issues>()
  useAirlock(
    { schoolId: null },
    data &&
      routeData.schoolId &&
      !data.find((item) => item.id === routeData.schoolId)
  )
  // todo: should probably make this sticky, but airlock for now

  return (
    // todo: unify with other school pickers...
    <FilterPicker
      filterType="startsWithByWord"
      getId={(item) => item.id}
      getLabel={(item) => item.name}
      items={data}
      itemPlural="Schools"
      onChange={(schoolId) => dispatchStay({ schoolId })}
      selectedId={routeData.schoolId}
      size="sm"
    />
  )
}

type SetupIssueColumnProps = { children: ReactNode; title: ReactNode }

function SetupIssueColumn(props: SetupIssueColumnProps) {
  const { children, title } = props
  return (
    <VStack gap={6}>
      <H3 fontSize="lg">{title}</H3>
      {children}
    </VStack>
  )
}
