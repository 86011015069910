import { Button, Text } from '@chakra-ui/react'

type StatStackProps = {
  onClick?(): void
  text: string | number
  selected?: boolean
  subtext: string | number
}

export const StatStack = (props: StatStackProps) => {
  const { onClick, text, selected, subtext } = props
  return (
    <Button
      alignItems="center"
      borderRadius=".25rem"
      boxShadow="none !important"
      display="flex"
      flexDir="column"
      fontWeight={300}
      onClick={onClick}
      p={1}
      variant={selected ? 'solid' : 'ghost'}
    >
      <Text as="span" fontSize="md">
        {text}
      </Text>
      <Text as="span" fontSize="xs">
        {subtext}
      </Text>
    </Button>
  )
}
