import { SetupCourse } from '@paper/schema'
import { FormDialog, PublicFormDialogProps } from '~src/blocks/formDialog'
import { useCourseSubmitter } from '../data-schoolSetup'
import { CourseForm } from './formCourse'
import { useValidationSchema } from './validation'

/**
 * Dialog for managing existing curricula
 */
export function CourseSetupDialog(props: PublicFormDialogProps<SetupCourse>) {
  const closeDialog = props.onClose
  const submitter = useCourseSubmitter()

  const validationSchema = useValidationSchema(props.data?.name)

  return (
    <FormDialog
      {...props}
      onSubmit={async (values) => {
        const data = values
        // @ts-expect-error todo: typescript is getting confused on this type...
        await submitter.mutateAsync(data)
        closeDialog()
      }}
      validationSchema={validationSchema}
    >
      <CourseForm />
    </FormDialog>
  )
}
