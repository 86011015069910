import { Box } from '@chakra-ui/react'
import { useRouter } from '@paper/route'
import { DirPacket } from '@paper/schema'
import { checkRadioactive, ImageIndexSets } from '@paper/utils'
import { ImageViewer, imageViewerColumnProps } from '~src/blocks/imageViewer'
import { KeyboardInstructions } from '~src/blocks/keyboardInstructions'
import {
  EnsureSelected,
  List,
  ListLoadShell,
  ListOuterStack,
  WSKeyboard,
} from '~src/blocks/list'
import { MainMenuDrawer } from '~src/blocks/mainMenu/drawer'
import { IcoQ, IcoStd } from '~src/blocks/swMenu/swMenuIcons'
import { BaseHeader, Column } from '~src/components'
import { usePacketBlanks, usePacketListData } from '~src/data/data-packets'
import { useXpacketDigest } from '~src/data/data-xpackets'
import { RD_SW_JumpToQ, Routes } from '~src/routelist'
import { SchoolYearPicker } from '~src/schoolYearAirlock'
import { Bargraph, BargraphHud } from './bargraph'
import { QStdProvider } from './bargraph/qStdProvider'
import { JumpToAirlock } from './jumpToAirlock'
import { PinDrawer } from './pinDrawer'
import { XpacketListFilters } from './xpacketFilters'

export function JumpTo() {
  const { selectedItem: packet } = usePacketListData()
  const digest = useXpacketDigest(packet)
  const { currentRoute, routeData } = useRouter<RD_SW_JumpToQ>()
  // todo: need 404 for no selected packet

  // handle blanks mode
  // todo: either need to digest or just inject here...
  const blanks = usePacketBlanks({
    enabled: !!(routeData.packetId && !routeData.teacherId),
    packetId: routeData.packetId,
  })

  const imageIndexSets = useImageIndexSets(packet)
  const xpacket = digest.success?.selectedItem
  // Check for radioactive
  let isRadioactive = checkRadioactive(packet, xpacket?._scanDate)

  return (
    <JumpToAirlock>
      <QStdProvider
        packet={packet ?? blanks.data?.packet}
        xpackets={
          digest.success?.otherData.preAnswerFilterXpackets ??
          blanks.data?.xpackets
        }
      >
        <BaseHeader.Container>
          <BaseHeader hideLogo={true} minWidth="unset" stackGap="1rem">
            <MainMenuDrawer
              icon={currentRoute === Routes.sw_jumpToQ ? IcoQ : IcoStd}
              keyboard={
                <KeyboardInstructions
                  adName={
                    currentRoute === Routes.sw_jumpToQ
                      ? 'questions'
                      : 'standards'
                  }
                  wsName="students"
                />
              }
            />
            {/* Overhang */}
            <Box
              alignSelf="flex-start"
              display="grid"
              flexGrow={1}
              gridTemplateColumns="auto 1fr"
              height="5.5rem"
              justifyItems="stretch"
            >
              <BargraphHud />
              <Bargraph />
            </Box>
          </BaseHeader>
          <SchoolYearPicker />
          <Column.Container
            colProps={{ flexGrow: 1, flexShrink: 0 }}
            flexGrow={1}
          >
            <ListLoadShell digest={digest} px={6} width="256px">
              {(status) => {
                if (status === 'success') {
                  return (
                    <ListOuterStack>
                      <List digest={digest}>
                        <XpacketListFilters />
                        <EnsureSelected />
                        <WSKeyboard />
                      </List>
                    </ListOuterStack>
                  )
                }
              }}
            </ListLoadShell>
            <Column {...imageViewerColumnProps}>
              <ImageViewer
                imageIndexSets={imageIndexSets}
                imageType={xpacket?.pages ? 'sw' : 'blank'}
                isRadioactive={isRadioactive}
                pages={xpacket?.pages ?? blanks.data?.xpackets[0].pages}
              />
            </Column>
            {routeData.teacherId && (
              // Only show for teacher version
              <PinDrawer xpacket={digest.success?.selectedItem} />
            )}
          </Column.Container>
        </BaseHeader.Container>
      </QStdProvider>
    </JumpToAirlock>
  )
}

/**
 * @deprecated todo: integrate with qImageIndexLookup
 */
function useImageIndexSets(packet: DirPacket): ImageIndexSets {
  const { routeData } = useRouter<RD_SW_JumpToQ>()

  // todo: bring this back...
  // if (modes.tag.isActive && packetmeta?.content.type === 'frontback') {
  //   // show side-by-side for front-back in tag mode since there's presumably extra room
  //   imageIndexSets = [new Set([0]), new Set([1])]
  // }

  const { qId } = routeData

  if (!packet) {
    return null
  }

  const selectedQ = packet.questions.find((q) => q.id === qId)

  if (!selectedQ) {
    return null // todo: airlock this away?
  } else {
    let idSets = [new Set([selectedQ.id])]
    if (selectedQ.passageIds.length > 0) {
      idSets.push(new Set(selectedQ.passageIds))
    }
    let imageIndexSets: ImageIndexSets = idSets.map((s) => new Set())
    packet.pages.forEach((page, pageIdx) => {
      page.items.forEach(({ id: itemId }) => {
        idSets.forEach((set, setIdx) => {
          if (set.has(itemId)) {
            imageIndexSets[setIdx].add(pageIdx)
          }
        })
      })
    })

    return imageIndexSets
  }
}
