import { RefObject, useEffect, useMemo, useRef, useState } from 'react'

export const bytesToBlob = (bytes: Uint8Array): Blob => new Blob([bytes])

export const revokeUrlRef = (ref: RefObject<string>) => {
  ref.current && URL.revokeObjectURL(ref.current)
}

export const revokeUrlRefArray = (ref: RefObject<string[]>) => {
  ref.current && console.log('revoking', ref.current)
  ref.current?.forEach((url) => URL.revokeObjectURL(url))
}

export const useBlobsToUrls = (blobs: Blob[]) => {
  const [urls, setUrls] = useState<string[]>()
  const ref = useRef<string[]>()

  useEffect(() => {
    // Clean up any old
    revokeUrlRefArray(ref)
    // Generate URLs
    ref.current = blobs?.map(URL.createObjectURL)
    setUrls(ref.current)
    // Also revoke on unmount
    return () => revokeUrlRefArray(ref)
  }, [blobs])

  return urls
}

/**
 * Returns URL.createObjectURL for `blob`
 * Revokes url on unmount and when `blob` changes
 */
export function useBlobUrl(blob: Blob) {
  const [url, setUrl] = useState<string>()
  const ref = useRef<string>()

  useEffect(() => {
    // Clean up any old
    revokeUrlRef(ref)
    // Generate url
    ref.current = blob ? URL.createObjectURL(blob) : null
    setUrl(ref.current)
    // Also revoke on unmount
    return () => revokeUrlRef(ref)
  }, [blob])

  return url
}

export function useBufferToUrl(ab: ArrayBuffer) {
  const blob = useMemo(() => (ab ? new Blob([ab]) : null), [ab])
  return useBlobUrl(blob)
}
