import { Box } from '@chakra-ui/react'
import { ImageViewer } from '~src/blocks/imageViewer'
import { BaseHeader } from '~src/components'
import { FullPageLoading } from '~src/components/status'
import { SchoolYearPicker } from '~src/schoolYearAirlock'
import { DarkMode } from '~src/utils/forceMode'
import { useTimeGridData } from './data-time'
import { StickyGrid } from './stickyGrid'
import { TimeAirlock, useTeacherTimeContext } from './timeAirlock'
import { TimeFilterMenu } from './timeFilterMenu'

export function TimeGridView() {
  const qResult = useTimeGridData()
  // todo:!!!!
  // todo: better will still be loading forever on direct and upstream query errors!
  // `qResult.isPending` is now true for disabled queries with no data!
  const xxx = { isPending: qResult.isPending }
  return (
    <FullPageLoading qResult={xxx}>
      <TimeAirlock baseData={qResult.data}>
        <TimeGrid />
      </TimeAirlock>
    </FullPageLoading>
  )
}

function TimeGrid() {
  // console.log('<TimeGraph />')
  const { digest } = useTeacherTimeContext()
  const { selectedItem } = digest

  return (
    <DarkMode>
      <SchoolYearPicker zIndex={1} />
      <Box
        alignItems="stretch"
        display="grid"
        // todo: this sets the Image column such that 1 page fits with horizontal a padding
        gridTemplateColumns={`1fr calc(((100vh - 64px) / 11 * 8.5) + 48px)`}
        gridTemplateRows="100vh"
        height="100vh"
        justifyItems="stretch"
        overflow="hidden"
        position="fixed"
        width="100vw"
      >
        <StickyGrid digest={digest} />

        <Box
          bg="#4c4c4c"
          display="grid"
          gridRowGap={1} // why isn't the boxShadow on top of ImageViewer?
          gridTemplateColumns="100%"
          gridTemplateRows={`${BaseHeader.Height}px 1fr`}
          justifyItems="center"
          zIndex={3} // todo: coordinate!
        >
          <Box
            alignItems="center"
            // todo: consolidate styles!
            boxShadow="rgb(0 0 0 / 8%) 0px 3px 7px 0px"
            display="grid"
            gridTemplateColumns={`min-content 1fr`}
            gridTemplateRows="100%"
            justifySelf="stretch"
            px={4}
          >
            <TimeFilterMenu digest={digest} />
          </Box>
          {selectedItem && (
            // todo: isRadioactive
            <ImageViewer imageType="sw" pages={selectedItem?.pages} />
          )}
        </Box>
      </Box>
    </DarkMode>
  )
}
