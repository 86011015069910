import { IconButton, IconButtonProps } from '@chakra-ui/react'
import { forwardRef } from 'react'

type WithText<T> = Omit<T, 'children' | 'icon'> & { icon: string | number }

export type TextIconButtonProps = WithText<IconButtonProps>

const upSizer = { xs: 'sm', sm: 'md', md: 'lg', lg: 'xl', xl: '2xl' }

/**
 * Workaround to use text in an IconButton
 */
export const TextIconButton = forwardRef<
  HTMLButtonElement,
  TextIconButtonProps
>(function TextIconButton(props, ref) {
  const { icon, ...rest } = props
  return (
    <IconButton
      fontFamily="mono"
      fontSize={upSizer[props.size ?? 'md']}
      {...rest}
      ref={ref}
      icon={<span>{icon}</span>}
    />
  )
})
