import { Box, Button, Icon } from '@chakra-ui/react'
import { IcoFunnel, IcoSortAmountAsc, IcoSortAmountDesc } from '@paper/icons'
import { PacketAxisItem, Student } from '@paper/schema'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { CSSProperties, memo } from 'react'
import {
  DarkModeMenuStyle,
  LighterDarkModeMenuStyle,
  MenuBody,
  MenuDetailPane,
  MenuDetailsSection,
  useMenu,
} from '~src/blocks/menu'
import { HStack, Txt, VStack } from '~src/components'
import { ColSort } from './colSort'
import { useTeacherTimeContext } from './timeAirlock'
import { Opacity } from './timeCell'
import { SortOrder } from './useColSortAirlock'

type AxisLabelProps<T> = {
  isFiltering?: boolean
  isSelected: boolean
  item: T
  sortOrder?: SortOrder
}

export const PacketAxisLabel = memo(function PacketAxisLabel(
  props: AxisLabelProps<PacketAxisItem>
) {
  const { isFiltering, isSelected, item, sortOrder } = props
  const { menuButtonProps, onOpenChange, open } = useMenu()

  const sortIndicator =
    sortOrder === 'asc' ? (
      <Icon as={IcoSortAmountAsc} />
    ) : sortOrder === 'desc' ? (
      <Icon as={IcoSortAmountDesc} />
    ) : null

  const style: CSSProperties = isSelected
    ? {
        background: '#333',
        opacity: Opacity.focused,
        transform: 'scale(1.15)',
        zIndex: 1,
      }
    : open
    ? { background: 'rgba(255, 255, 255, 0.08)' }
    : null

  return (
    <DropdownMenu.Root modal={false} onOpenChange={onOpenChange} open={open}>
      <DropdownMenu.Trigger asChild>
        <Button
          key={item.id} // todo: running into a strange issue where children aren't rendered if key is below {...menuButtonProps}
          {...menuButtonProps}
          alignItems="stretch"
          data-cy={isSelected ? `selected-packet` : undefined}
          display="grid"
          fontFamily="mono"
          gridRowGap={2}
          gridTemplateRows="1fr max-content"
          height="unset"
          justifyItems="center"
          opacity={Opacity.unfocused}
          p={2}
          size="xs"
          style={style}
          transition="none"
          variant="ghost"
          _hover={{
            background: 'rgba(255, 255, 255, 0.08) !important',
            transition: 'background .25s',
          }}
          _active={{
            background: 'rgba(255, 255, 255, 0.16) !important',
            transition: 'background .25s',
          }}
        >
          {/* note: react is complaining when these don't have keys */}
          <HStack key="ind" fontSize=".6rem">
            {isFiltering && <Icon as={IcoFunnel} />}
            {sortIndicator}
          </HStack>
          <VStack key="num">
            {item.number.split('.').map((segment, idx) => (
              <Box key={idx}>{segment}</Box>
            ))}
          </VStack>
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content asChild align="start" sideOffset={4}>
          <MenuBody
            {...(isSelected ? DarkModeMenuStyle : LighterDarkModeMenuStyle)}
            width="344px"
            variant="snug"
          >
            <MenuDetailPane>
              <MenuDetailsSection>
                <Txt fontSize="sm" ml={-1}>
                  {item.name}
                </Txt>
              </MenuDetailsSection>
              <MenuDetailsSection heading="Sort">
                <ColSort currentId={item.id} />
              </MenuDetailsSection>
            </MenuDetailPane>
          </MenuBody>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
})

export const StudentAxisLabel = memo(function StudentAxisLabel(
  props: AxisLabelProps<Student>
) {
  // console.log('<SAL/>')
  const { isSelected, item } = props
  const style: CSSProperties = !isSelected
    ? null
    : {
        background: '#333',
        opacity: Opacity.focused,
        transform: 'scale(1.15)',
        zIndex: 1,
      }

  return (
    // todo: this is not a button yet!
    <Txt
      // non-button things
      alignItems="center"
      display="flex"
      justifyContent="center"
      // button things
      fontSize="xs"
      height="unset"
      key={item.id}
      opacity={Opacity.unfocused}
      size="xs"
      style={style}
      transition="none"
      whiteSpace="nowrap"
      //variant={isSelected ? 'solid' : 'ghost'}
      //_hover={{ background: 'rgba(255, 255, 255, 0.08) !important' }}
    >
      {item.lastName}, {item.firstName}
    </Txt>
  )
})
