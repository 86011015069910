import { createIcon } from '@chakra-ui/react'

//https://github.com/mongodb/leafygreen-ui/tree/main/packages/icon/src/glyphs
export const IcoCaretDown = createIcon({
  displayName: 'IcoCaretDown',
  d: 'M8.67903 10.7962C8.45271 11.0679 8.04729 11.0679 7.82097 10.7962L4.63962 6.97649C4.3213 6.59428 4.5824 6 5.06866 6L11.4313 6C11.9176 6 12.1787 6.59428 11.8604 6.97649L8.67903 10.7962Z',
  viewBox: '0 0 16 16',
})

export const IcoCaretUp = createIcon({
  displayName: 'IcoCaretUp',
  d: 'M7.32097 5.20381C7.54729 4.93207 7.95271 4.93207 8.17903 5.20381L11.3604 9.02351C11.6787 9.40572 11.4176 10 10.9313 10L4.56866 10C4.0824 10 3.8213 9.40572 4.13962 9.02351L7.32097 5.20381Z',
  viewBox: '0 0 16 16',
})
