import { Box, Tag, TagLabel, TagLeftIcon, TagProps } from '@chakra-ui/react'
import { ReactNode } from 'react'

type GhostTagProps = TagProps & { label: ReactNode; leftIcon: any }
export function GhostTag(props: GhostTagProps) {
  const { color, fontSize, label, leftIcon, ...rest } = props
  return (
    <Tag bg="transparent" color="unset" px={0} size="lg" {...rest}>
      {leftIcon && <TagLeftIcon as={leftIcon} color={color} />}
      <TagLabel fontSize={fontSize ?? 'xs'} fontWeight={300}>
        {label}
      </TagLabel>
    </Tag>
  )
}

export type StatTagProps = { count: number } & Pick<
  TagProps,
  'bg' | 'color' | 'colorScheme' | 'size'
>

export function StatTag(props: StatTagProps) {
  const { bg, color, count, colorScheme, ...passThrough } = props

  // todo: workaround for extra customization...ideally would have everything colorSchemed/varianted
  let colorProps: TagProps = colorScheme
    ? { colorScheme }
    : { bg, color: color ?? 'unset' }

  const isZeroish = count === 0 || isNaN(count)

  if (isZeroish) {
    colorProps = {
      bg: 'transparent',
      borderColor: 'gray.100',
      borderStyle: 'dotted',
      borderWidth: 1,
    }
  }

  return (
    <Tag
      {...passThrough}
      {...colorProps}
      justifyContent="center"
      minWidth="unset"
      paddingInline="unset"
      width="28px"
      userSelect="none"
      variant="solid"
    >
      {!isZeroish && count}
    </Tag>
  )
}
