export const base64ToUrlSafe = (str: string) =>
  str
    .replace(/=/g, '') // discard `=`
    .replace(/\//g, '_') // `/` -> `_`
    .replace(/\+/g, '-') // `+` -> '-'

/**
 * Returns 11 character url-safe substring of passed in base64 hash
 * NOTE: This function does NOT do any hashing (b/c there is no unified web/node api)
 */
export const safe11 = (hash: string) => base64ToUrlSafe(hash.substring(0, 11))
/**
 * Returns 22 character url-safe substring of passed in base64 hash
 * NOTE: This function does NOT do any hashing (b/c there is no unified web/node api)
 */
export const safe22 = (hash: string) => base64ToUrlSafe(hash.substring(0, 22))
