import {
  BoxProps,
  Link,
  LinkProps,
  ListItem,
  StyleProps,
  useColorModeValue,
} from '@chakra-ui/react'
import { RouteMeta } from '@paper/route'
import {
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuLinkProps,
} from '@radix-ui/react-navigation-menu'
import { forwardRef, ReactNode } from 'react'
import {
  MILeftBorderRem,
  MIPXRem,
  useRouterMenuItemProps,
} from '~src/blocks/menu'

export type RouterNavLinkProps = NavLinkProps & {
  route: RouteMeta<any>
  data?: any
}

export const RouterNavLink = forwardRef<HTMLLIElement, RouterNavLinkProps>(
  function RouterNavLink(props, ref) {
    const { route, data, ...rest } = props
    const routerProps = useRouterMenuItemProps(route, data)
    return <NavLink ref={ref} {...routerProps} {...rest} />
  }
)

// todo: clean up this interface!!!
type NavLinkProps = {
  children: ReactNode
  disabled?: boolean
  icon?: ReactNode
} & StyleProps &
  Pick<LinkProps, 'as' | 'href' | 'isExternal'> &
  Pick<NavigationMenuLinkProps, 'onSelect'>

export const NavLink = forwardRef<HTMLLIElement, NavLinkProps>(
  function NavLink(props, ref) {
    const { children, disabled, icon, onSelect, ...rest } = props
    const styleProps = useMenuItemStyle(rest.as)

    // todo: there's no such thing as a disabled link?
    let href = disabled ? null : props.href
    let opacity = disabled ? 0.5 : null
    let pointerEvents: BoxProps['pointerEvents'] = disabled ? 'none' : null
    let tabIndex = disabled ? -1 : null

    let disabledProps: LinkProps = {}
    if (disabled) {
      disabledProps['data-disabled'] = true
    }

    let link = (
      <Link {...styleProps} {...rest} {...disabledProps} tabIndex={tabIndex}>
        {icon}
        {children}
      </Link>
    )

    // avoid being keyboard accessible
    if (!disabled) {
      link = (
        <NavigationMenuLink asChild href={href} onSelect={onSelect}>
          {link}
        </NavigationMenuLink>
      )
    }

    let menuItem = (
      <ListItem opacity={opacity} pointerEvents={pointerEvents} ref={ref}>
        {link}
      </ListItem>
    )

    if (!disabled) {
      menuItem = <NavigationMenuItem asChild>{menuItem}</NavigationMenuItem>
    }

    return menuItem
  }
)

// todo: MenuItem must be rendered within a menu, so needed to split out
// todo: so far have done this via copy/paste
// todo: combine with MenuItem (if necessary...possible this is shareable via asChild)
const useMenuItemStyle = (as: LinkProps['as']): LinkProps => {
  const hoverBg = useColorModeValue('gray.100', '#2a2a2a')
  let style: LinkProps = {
    alignItems: 'center',
    borderColor: 'transparent',
    borderInlineStartWidth: `${MILeftBorderRem}rem`,
    borderRadius: 1,
    cursor: 'pointer',
    fontWeight: 300,
    gap: '.75rem',
    display: 'flex',
    outline: 'none!important',
    paddingInlineStart: `${MIPXRem - MILeftBorderRem}rem`,
    paddingInlineEnd: `${MIPXRem}rem`,
    py: '.4rem',
    textDecoration: 'none!important',
    userSelect: 'none',
    sx: {
      '&[data-disabled]': {
        cursor: 'not-allowed',
        opacity: 0.4,
        pointerEvents: 'none',
      },
      '&:hover': {
        bg: hoverBg,
      },
    },
  }

  if (as === 'button') {
    style.fontWeight = 300
    style.textAlign = 'start'
    style.width = '100%'
  }

  return style
}
