import {
  Box,
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import { APIs } from '@paper/api-specs'
import { IcoRuGood } from '@paper/icons'
import { useRouter } from '@paper/route'
import { DirPacket, XpacketSW } from '@paper/schema'
import { setDefaultMap } from '@paper/utils'
import { useVirtualizer } from '@tanstack/react-virtual'
import { orderBy, partition } from 'lodash'
import { ReactNode, useEffect, useMemo, useRef } from 'react'
import { ImagePage, useNormalizeImages } from '~src/blocks/imageViewer'
import { MainMenuDrawer } from '~src/blocks/mainMenu/drawer'
import { useSWContext } from '~src/blocks/swContext'
import {
  AppTitle,
  BaseHeader,
  HSep,
  HStack,
  Mono,
  Txt,
  VStack,
} from '~src/components'
import { useXpacketDigest } from '~src/data/data-xpackets'
import { useApiQuery } from '~src/data/useApiQuery'
import { RD_SW_Rubric } from '~src/routelist'
import { DarkMode } from '~src/utils/forceMode'
import { pxx } from '~src/utils/layout'
import { RubricDotViz } from '../timeGrid/timeCell'
import { RubricMissing } from './rubricMissingSection'
import { AxesSelector, RubricStudentGrid } from './rubricStudentGrid'

type RubricPageProps = {}

export function RubricPage(props: RubricPageProps) {
  const { packet } = useSWContext()

  const digest = useXpacketDigest(packet)

  const sections = digest.success?.otherData.sections ?? []

  // todo: not memoized...
  const todo = sections.map((sec) => {
    const xpackets = digest.success?.items ?? []
    const thisXpackets = xpackets.filter((p) =>
      sec.students.find((stu) => stu.id === p.student?.id)
    )

    const rollupData = thisXpackets.map((p) => p.rubric)

    const uniqueMap = new Map<string, { count: 0; values: number[] }>()

    rollupData.forEach((rubric) => {
      if (rubric) {
        const { values } = rubric
        const key = values.toString()
        const item = setDefaultMap(uniqueMap, key, { count: 0, values })
        item.count += 1
      }
    })

    // todo: centralize
    const itemCount = rollupData[0]?.values?.length ?? 0

    let indyMap = new Map<
      string,
      { count: number; idx: number; value: number }
    >()

    rollupData.forEach((rubric) => {
      if (rubric) {
        const { values } = rubric
        values.forEach((value, idx) => {
          const indyKey = `${idx}:${value}`
          const item = setDefaultMap(indyMap, indyKey, { count: 0, idx, value })
          item.count += 1
        })
      }
    })

    const [hasRubric, noRubric] = partition(
      thisXpackets,
      (p) => !!(p.rubric?.values.length && p.rubric?.values?.some((p) => !!p))
    )

    // console.log('INDY', indyMap)

    return {
      id: sec.id,
      tab: (
        <HStack color="white" gap={2} p={1}>
          {sec.name}
        </HStack>
      ),
      panel: (
        <VStack gap={1} height="100%" overflowY="auto" pr={4} py={4}>
          <RubricStudentGrid xpackets={hasRubric} />
          <HSep />
          <RubricMissing rubricless={noRubric} />
        </VStack>
      ),
    }
  })

  const images = useNormalizeImages({
    keys: digest.success?.selectedItem?.pages.map((p) => p.key),
  })

  const bg = '#4c4c4c'

  return (
    <DarkMode>
      <BaseHeader.Container bg={bg} color="white">
        <AppTitle title="Ticket rubric" />
        <BaseHeader
          // bg="#404040"
          boxShadow="rgb(0 0 0 / 8%) 0px 3px 7px 0px"
          hideLogo={true}
          marginBottom={0}
          stackGap="1rem"
        >
          <MainMenuDrawer icon={IcoRuGood} />
          <Txt fontSize="lg" flexShrink={0}>
            Rubric review
          </Txt>
          {packet && (
            <>
              {BaseHeader.Divider}
              <HStack flexShrink={0} gap={2}>
                <Mono>{packet.number}</Mono>
                <Txt>{packet.name}</Txt>
              </HStack>
            </>
          )}
        </BaseHeader>
        <BaseHeader.Body gap={5} px={8} sx={{ '>*': { flexShrink: 0 } }}>
          <SectionTabs todo={todo} />
          <VStack gap={2} mt="120px">
            <Txt fontSize="xs" fontWeight="bold">
              Organize by
            </Txt>
            <AxesSelector />
          </VStack>
          <VStack alignItems="stretch" overflow="hidden" width="900px">
            <PacketAxis selectedXpacket={digest.success?.selectedItem} />
            <Box
              alignSelf="start"
              bg="#404040"
              borderRadius="md"
              gap={2}
              height="100%"
              overflowY="auto"
              p={2}
              width="100%"
            >
              {images?.map((img) => (
                <Box
                  borderTopRadius="md"
                  key={img.src}
                  overflow="hidden"
                  width="100%"
                >
                  <ImagePage mode="cover" src={img.src} />
                </Box>
              ))}
            </Box>
          </VStack>
        </BaseHeader.Body>
      </BaseHeader.Container>
    </DarkMode>
  )
}

type SectionTabsProps = {
  todo: { id: string; panel: ReactNode; tab: ReactNode }[]
}

function SectionTabs(props: SectionTabsProps) {
  const { todo } = props

  const { dispatchStay, routeData } = useRouter<RD_SW_Rubric>()
  const { f_sectionId } = routeData
  const tabIndex =
    (f_sectionId && todo.findIndex((p) => p.id === f_sectionId)) ?? 0

  const width = '600px'

  // todo: minimize screen jumping a bit
  if (todo.length === 0) {
    return <Box width={width} />
  }

  return (
    <Tabs
      align="center"
      colorScheme="blue"
      display="flex"
      flexDirection="column"
      index={tabIndex}
      //isFitted={true}
      isLazy={true}
      onChange={(index) => dispatchStay({ f_sectionId: todo[index]?.id })}
      width={width}
      size="md"
    >
      <TabList>
        {todo.map((p) => (
          <Tab key={p.id}>{p.tab}</Tab>
        ))}
      </TabList>
      <TabPanels overflow="hidden">
        {todo.map((p) => (
          <TabPanel key={p.id} height="100%" p={0}>
            {p.panel}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  )
}

type PacketAxisProps = {
  selectedXpacket: XpacketSW
}

function PacketAxis(props: PacketAxisProps) {
  const { selectedXpacket } = props
  const { dispatchStay, routeData } = useRouter<RD_SW_Rubric>()
  const { packetSwitchList } = useSWContext()

  const items = useMemo(() => {
    return orderBy(packetSwitchList, (p) => p.scan?.date)
  }, [packetSwitchList])

  // todo: messy!
  const packetIds = useMemo(() => items.map((p) => p.id), [items])

  const selectedIndex = useMemo(() => {
    return !routeData.packetId
      ? -1
      : items.findIndex((p) => p.id === routeData.packetId)
  }, [items])

  const parentRef = useRef()

  const rv = useVirtualizer({
    count: items.length,
    estimateSize: () => 60,
    getScrollElement: () => parentRef.current,
    horizontal: true,
  })

  const studentId = selectedXpacket?.student?.id

  // todo: hoist?
  const qResult = useApiQuery({
    apiSpec: APIs['dir.listXpacketsStudent'],
    queryFn: async ({ plainFetch }) => {
      let data = await plainFetch()
      return new Map(data.map((xp) => [xp.packetId, xp]))
    },
    queryVars: { body: { packetIds, studentId } },
    useQueryProps: {
      enabled: packetIds.length > 0 && !!studentId,
      staleTime: Infinity,
    },
  })

  const xpacketMap = qResult.data

  // todo: scrollIntoView
  // todo: i have implemented this is in like 20 different ways
  useEffect(() => {
    if (selectedIndex >= 0) {
      rv.scrollToIndex(selectedIndex)
    }
  }, [selectedIndex])

  return (
    <Box
      flexShrink={0}
      height="68px" // todo: is there a way to get the height from the children?
      // todo: though also want room from scrollbar
      overflowX="auto"
      ref={parentRef}
      role="presentation"
      width="100%"
    >
      <Box
        role="presentation"
        style={{ width: rv.getTotalSize(), position: 'relative' }}
      >
        {rv.getVirtualItems().map((vi) => {
          const pkt = items[vi.index]
          return (
            <Box
              data-index={vi.index}
              key={pkt.id + vi.index}
              role="presentation"
              px={0.5}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: `${vi.size}px`,
                height: '100%',
                transform: `translateX(${vi.start}px)`,
                transitionDuration: '.4s',
                transitionProperty: 'height top',
              }}
            >
              <PacketAxisItem
                isSelected={selectedIndex === vi.index}
                onSelect={(packetId, xpacketId) =>
                  dispatchStay({ packetId, xpacketId })
                }
                packet={pkt}
                xpacket={xpacketMap?.get(pkt.id)}
              />
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

type PacketAxisItemProps = {
  isSelected: boolean
  onSelect(packetId: string, xpacketId: string): void
  packet: DirPacket
  xpacket: XpacketSW
}

function PacketAxisItem(props: PacketAxisItemProps) {
  const { isSelected, onSelect, packet, xpacket } = props

  const rubric = xpacket?.rubric
  const size = 24
  const outsideSize = size + 1

  return (
    <Button
      key={packet.id}
      colorScheme={isSelected ? 'blue' : null}
      flexShrink={0}
      height="unset"
      onClick={() => onSelect(packet.id, xpacket?.id)}
      opacity={isSelected ? 1 : 0.75}
      px={1}
      py={1}
      variant={isSelected ? 'solid' : 'ghost'}
      width="100%"
    >
      <VStack fontSize="xs" overflow="hidden">
        <Mono>{packet.number}</Mono>
        {/* <Txt isTruncated={true}>{packet.name}</Txt> */}
        <Box
          borderColor={
            rubric
              ? isSelected
                ? '#4c4c4c' // same as background
                : 'whiteAlpha.300'
              : 'transparent'
          }
          borderWidth={'.25px'}
          height={pxx(outsideSize)}
          mt={0.5}
          opacity={rubric ? 1 : 0}
          transition="opacity .5s ease-in"
        >
          {rubric && <RubricDotViz data={rubric} size={pxx(size)} />}
        </Box>
      </VStack>
    </Button>
  )
}
