import { PartsSelector } from '~src/blocks/imageViewer'
import { Txt, VStack } from '~src/components'
import { Wiz } from '../publishLayout'
import { useWizContext } from './wizProvider'

export function FormParts() {
  const { formik, imageUrls } = useWizContext()
  const { setFieldValue } = formik
  const content = formik.values

  return (
    <Wiz.FormArea overflow="hidden">
      <VStack alignItems="start" gap={6} height="100%" width="100%">
        <VStack alignItems="stretch" gap={1}>
          <Txt fontSize="lg">Parts</Txt>
          <Txt fontSize="sm">
            If the assessment is printed in multiple parts, select the first
            page of each subsequent part.
          </Txt>
        </VStack>
        <PartsSelector
          onChange={(parts) => setFieldValue('parts', parts)}
          pkt={content}
          urls={imageUrls.blank}
        />
      </VStack>
    </Wiz.FormArea>
  )
}
