import { Box, BoxProps, Grid, useColorModeValue } from '@chakra-ui/react'
import * as SeparatorPrimitive from '@radix-ui/react-separator'
import { forwardRef, ReactNode } from 'react'

type SeparatorProps = SeparatorPrimitive.SeparatorProps & {
  boxProps?: BoxProps
  size?: BoxProps['height']
}

const makeSeparator = (orientation?: SeparatorProps['orientation']) => {
  return forwardRef<HTMLDivElement, SeparatorProps>(
    function Separator(props, ref) {
      let { boxProps, size, ...rest } = props
      boxProps = boxProps ?? {}
      const bg = useColorModeValue('gray.100', 'rgba(255,255,255,.2)')
      return (
        <SeparatorPrimitive.Root
          asChild
          orientation={orientation}
          ref={ref}
          {...rest}
        >
          <Box
            bg={bg}
            flexShrink={0}
            opacity={0.75}
            sx={{
              '&[data-orientation=horizontal]': {
                alignSelf: 'stretch',
                height: size ?? '1px',
              },
              '&[data-orientation=vertical]': {
                alignSelf: 'stretch',
                width: size ?? '1px',
              },
            }}
            {...boxProps}
          />
        </SeparatorPrimitive.Root>
      )
    }
  )
}

export const Separator = makeSeparator()
export const VSep = makeSeparator('vertical')
export const HSep = makeSeparator('horizontal')

type LabeledVSepProps = {
  label: ReactNode
  placement: 'center' | string
} & Omit<BoxProps, 'children'>

export function LabeledVSep(props: LabeledVSepProps) {
  const { label, placement, ...boxProps } = props

  let gridTemplateRows = `${
    placement === 'center' ? '1fr' : placement
  } auto 1fr`

  return (
    <Grid
      gridRowGap={2}
      gridTemplateRows={gridTemplateRows}
      placeItems="center"
      userSelect="none"
      {...boxProps}
    >
      <VSep />
      {label}
      <VSep />
    </Grid>
  )
}
