import {
  Button,
  ButtonGroup,
  FormLabel,
  PinInput,
  PinInputField,
} from '@chakra-ui/react'
import { IcoHelp } from '@paper/icons'
import { ReactNode } from 'react'
import { ExclaimRed } from '~src/blocks/exclaimRed'
import {
  Ex,
  HStack,
  Mono,
  TooltippedIconButton,
  Txt,
  VStack,
} from '~src/components'
import config from '~src/utils/config'
import { useScanFixContext, useScanlogContext } from '../scanlogAirlock'

type ScanlogPinputProps = {}

export function ScanlogPinput(props: ScanlogPinputProps) {
  const {
    scanFix: { candidateResult, tableData, qrbPrefix, setChanges },
    selectedXpacketSet,
  } = useScanlogContext()

  return (
    // todo: why is this a ButtonGroup?
    <ButtonGroup alignItems="center" fontSize="xs" spacing={2} size="xs">
      <FormLabel
        display="flex"
        flexDirection="column"
        fontSize="unset"
        fontWeight="unset"
        gap={1.5}
        mb={0}
      >
        <HStack>
          <Txt>
            Enter the code printed next to the QR code (underneath{' '}
            <Txt as="span" fontFamily="mono" fontSize="sm">
              {config.meta.dbId}
            </Txt>
            )
          </Txt>
          <TooltippedIconButton
            aria-label="Help"
            icon={<IcoHelp fontSize="md" />}
            colorScheme="blue"
            cursor="pointer"
            isRound={true}
            tooltipProps={{
              label: (
                <Txt lineHeight={1.8} p={1.5} textAlign="center" width="240px">
                  Click for more help if you don't see <Ex>{qrbPrefix}-</Ex> or{' '}
                  <Ex>{config.meta.dbId}</Ex>
                </Txt>
              ),
              placement: 'bottom-start',
            }}
            size="xs"
            variant="ghost"
          />
        </HStack>
        <HStack gap={2}>
          <Pinput />
          {candidateResult.isSuccess && !tableData.length && (
            <ExclaimRed>No pages found with this code</ExclaimRed>
          )}
        </HStack>

        {selectedXpacketSet && (
          <Txt
            height={selectedXpacketSet ? '18px' : '0px'}
            overflow="hidden"
            // todo: this is pointless because the entire thing gets re-rendered...
            transition="height .5s ease"
          >
            Or, select a missing page from the packets in the left column.
          </Txt>
        )}
        <HStack gap={1}>
          If the sheet doesn't have a Paper QR, you can
          <Button onClick={() => setChanges('ignore')} size="xs">
            Mark sheet as <Mono ml={1}>ignored</Mono>
          </Button>
        </HStack>
      </FormLabel>
    </ButtonGroup>
  )
}

type ScanlogFixAreaProps = {
  actions: ReactNode
  children: ReactNode
  title: ReactNode
}

export function ScanlogFixArea(props: ScanlogFixAreaProps) {
  return (
    <VStack alignItems="stretch" fontSize="xs" gap={6}>
      <HStack fontSize="md">{props.title}</HStack>
      {props.children}
      <ButtonGroup alignItems="center" spacing={2} size="xs">
        {props.actions}
      </ButtonGroup>
    </VStack>
  )
}

function Pinput() {
  const chunkSpec = [5]
  const chunkCount = chunkSpec.length
  const { candidateInputs, qrbPrefix, selectPage } = useScanFixContext()

  const children: ReactNode[] = []
  for (let i = 0; i < chunkCount; i++) {
    const chunkLength = chunkSpec[i]

    if (i !== 0) {
      children.push(
        <Txt as="span" fontSize="2xl" key={`${i}.-`}>
          -
        </Txt>
      )
    }
    for (let j = 0; j < chunkLength; j++) {
      children.push(<PinInputField key={`${i}.${j}`} />)
    }
  }

  return (
    <HStack fontFamily="mono" gap={1} whiteSpace="nowrap">
      <PinInput
        autoFocus={true}
        onChange={(value) => {
          selectPage({ qrb: value.toUpperCase() })
        }}
        onComplete={(value) => {
          selectPage({ qrb: value.toUpperCase(), complete: true })
        }}
        size="sm"
        type="alphanumeric"
        value={candidateInputs.qrbValue ?? ''}
      >
        <Txt as="span" fontSize="xl">
          {qrbPrefix}-
        </Txt>
        {children}
      </PinInput>
    </HStack>
  )
}
