import { APIs } from '@paper/api-specs'
import { useRouter } from '@paper/route'
import {
  CrunchBatch,
  FixitActionSet,
  FixitIDStatus,
  FixitRosterItem,
  FixitSheet,
  FixitSheetBase,
  FixitSheetRow,
  FixitSlot,
  FixitSlotType,
  Student,
} from '@paper/schema'
import { produce } from 'immer'
import { isEqual } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { useApiQuery } from '~src/data/useApiQuery'
import { useListenQuery } from '~src/pages/publish/data-publish'
import type { RD_SW_Fixit } from '~src/routelist'

import { useFixitSectioned } from './data-fixit-sectioned'
import type { OnSelectSlot } from './fixitProvider'
import { crunchFixitSheet } from './fixitSheetStatus'

export type OnSlotAction = (action: FixitActionSet) => void

export const useFixitData = () => {
  const { dispatchStay, routeData } = useRouter<RD_SW_Fixit>()
  const { curriculumId, packetId, teacherId } = routeData

  // Get base data
  const qResult = useApiQuery({
    apiSpec: APIs['fixit.get'],
    queryVars: {
      body: { curriculumId, packetId, teacherId },
    },
    useQueryProps: {
      enabled: !!(packetId && teacherId),
    },
  })

  // Holder for edits
  const [data, setData] = useState<EditableFixitData>({
    _dirty: null,
    _original: null,
    base: null,
    crunched: { rows: [], slots: [], sheets: [] },
  })

  // Populate that on query success
  useListenQuery(qResult, true, ({ data }) => {
    const { batches, roster } = data

    const studentMap = new Map(
      roster.map(({ student }) => [student.id, student])
    )

    const base: BaseFixitData = {
      batches,
      roster,
      sheets: sheetify(batches),
      studentMap,
    }

    // Merge in `not-this` since it doesn't do anything on the server
    setData((prev) => {
      prev?.base?.sheets?.forEach((prevSheet) => {
        let sheetIndex: number
        const setSheet = (setter: (sheet: FixitSheetBase) => void) => {
          sheetIndex ??= base.sheets.findIndex((p) => p.id === prevSheet.id)
          const sheet = base.sheets[sheetIndex]
          if (sheet) {
            setter(sheet)
          }
        }

        if (prevSheet._actionSet.packet === 'not-this-packet') {
          setSheet((p) => (p._actionSet.packet = 'not-this-packet'))
        }
        if (prevSheet._actionSet.student === 'not-this-roster') {
          setSheet((p) => (p._actionSet.student = 'not-this-roster'))
        }
      })

      const { rows, sheets, slots } = slotify({
        baseSheets: base.sheets,
        originalSheets: base.sheets,
        packetId,
        studentMap,
      })

      const editable: EditableFixitData = {
        _dirty: false,
        _original: base,
        base,
        crunched: { rows, sheets, slots },
      }

      return editable
    })
  })

  // Digest
  const digested = useDigestFixit(data)

  const onSelectSlot = useCallback<OnSelectSlot>((item) => {
    dispatchStay({
      si_imageId: item?.sheetId ?? null,
      slot: item?.type ?? null,
    })
  }, [])

  const { slots } = data.crunched

  const selectedSlot = useMemo(() => {
    return slots.find(
      (p) => p.type === routeData.slot && p.sheetId === routeData.si_imageId
    )
  }, [slots, routeData.si_imageId, routeData.slot])

  const onSlotAction = useMemo<OnSlotAction>(() => {
    // todo: using useMemo instead of useCallback to try to diagnose bug
    // console.log(`[useMemo] onSlotAction`)
    return (action) => {
      const goNext = true
      const slotId = selectedSlot?.id

      let slotIndex: number
      let nextItem: FixitSlot

      // todo: i was previously using setData(callback), but for reasons that aren't clear to me
      // todo: the callback was getting somehow getting executed AFTER onSelectSlot below when setting the same packet 3 times in a row
      let nextData = produce(data, (draft) => {
        // todo: this is static for a given dataset, so could make a lookup map on initial crunch
        slotIndex = draft.crunched.slots.findIndex((p) => p.id === slotId)
        const slot = draft.crunched.slots[slotIndex]

        if (!slot?._editable) {
          // note: not foolproof since you could theoretically pass any data along with the slot
          console.warn(`Slot is not editable`, slot)
          return
        }

        const item = draft.base.sheets[slot.sheetIndex]
        item._dirty = true // for the moment won't worry about a user change that reverts
        draft._dirty = true // mark us as dirty on the whole

        // Handle special actions
        // todo: another thing that needs cleanup!
        // Ignore actions blow everything else away
        if (action.packet === 'ignore' || action.student === 'no-student') {
          item._actionSet = action
        }
        // Everything else merges
        else {
          item._actionSet ??= {}
          for (let [key, value] of Object.entries(action)) {
            item._actionSet[key] = value
          }
        }

        // crunch the changes
        draft.crunched = slotify({
          baseSheets: draft.base.sheets,
          originalSheets: data._original.sheets, // these don't change
          packetId,
          studentMap: data.base.studentMap, // these don't change
        })
      })

      // todo: this is a mess
      // todo: the challenge is that i want to choose the next slot dynamically based on the changes
      // todo: so need to be able to calculate that with the most recent data
      if (goNext) {
        const { slots } = nextData.crunched
        // looks for next eligible slot
        if (slotIndex >= 0) {
          for (let j = slotIndex + 1; j < slots.length; j++) {
            let slot = slots[j]
            if (slot._editable && slot._review) {
              nextItem = slot
              break
            }
          }
        }
      }

      // console.log('[[setData]]')
      setData(nextData)

      if (goNext) {
        onSelectSlot(nextItem)
      }
    }
  }, [data, onSelectSlot, packetId, selectedSlot])

  const sheets = data?.crunched.sheets
  const selectedSheet = useMemo(() => {
    return sheets?.find((p) => p.id === routeData.si_imageId)
  }, [sheets, routeData.si_imageId])

  return {
    baseData: data,
    digested,
    onSelectSlot,
    onSlotAction,
    qResult,
    selectedSheet,
    selectedSlot,
  }
}

/**
 * Flattens batches into sheet base
 */
const sheetify = (batches: CrunchBatch[]) => {
  return batches.flatMap((batch, indexOfItsBatch) => {
    return batch.chunks.flatMap((chunk) => {
      return chunk.items.map((sheet) => {
        let result: FixitSheetBase = {
          _dirty: false,
          _actionSet: {},
          _review: null, // fill this in when crunching...
          batchId: batch.id,
          id: sheet[0].id,
          indexOfItsBatch,
          scans: sheet,
        }
        return result
      })
    })
  })
}

export type BaseFixitData = {
  batches: CrunchBatch[]
  roster: FixitRosterItem[]
  sheets: FixitSheetBase[]
  studentMap: Map<string, Student>
}

type Editable<T> = { _original: T; base: T }
export type EditableFixitData = Editable<BaseFixitData> & {
  _dirty: boolean
  crunched: {
    rows: FixitSheetRow[]
    sheets: FixitSheet[]
    slots: FixitSlot[]
  }
}

const useDigestFixit = (data: EditableFixitData) => {
  // todo: how expensive is this update?
  // console.time('useDigestify')

  const baseRoster = data?.base?.roster

  // digest sheets into review/problem axis
  const sheets = data?.crunched.sheets
  // { [studentId]: { sheetId, sheetIndex } }
  // todo: this assumes 1 sheet per student...
  const stuToScanMap = useMemo(() => {
    const result = new Map<
      string,
      { sheetId: string; sheetIndex: number; notThis: boolean }
    >()
    sheets?.forEach((sheet, sheetIndex) => {
      if (sheet.student) {
        // notThis allows us to reset if that's the action
        // todo: need a new plan as this is still broken when changing a student
        result.set(sheet.student.id, {
          sheetId: sheet.id,
          sheetIndex,
          notThis: sheet.status.packet !== 'success',
        })
      }
    })
    return result
  }, [sheets])

  // apply changes to the roster as needed
  const roster = useMemo(() => {
    if (!baseRoster) {
      return []
    }

    // todo: i suspect there's a better way to store this data that would simplify this...
    // todo: one possiblity is to have `roster` just return the list of students and do this lookup every time
    // todo: issues include guaranteeing that every relevant batch is included
    return baseRoster.map((item) => {
      // lookup sheet
      let sheetIndex = stuToScanMap.get(item.student.id)?.sheetIndex
      let notThis = stuToScanMap.get(item.student.id)?.notThis
      let sheet = sheetIndex != null && sheets[sheetIndex]

      if (!sheet?._dirty) {
        return item
      } else if (notThis) {
        return {
          ...item,
          _dirty: true,
          rubric: null,
          score: null,
          sheets: sheet.scans.map((p) => null),
        }
      } else {
        const { _actionSet, rubric, scans, score } = sheet
        // todo: assumes single-sheet/ handle n/a
        return {
          ...item,
          _dirty: true,
          rubric,
          score,
          sheets: [
            scans
              .filter((p, idx) => {
                // todo: this is a disaster! but need to filter out to-be-inferred-blanks
                if (p.status === 'infer-blank') {
                  return false
                } else if (
                  _actionSet.packet &&
                  typeof _actionSet.packet !== 'string' &&
                  _actionSet.packet.pageIndices[idx] === null
                ) {
                  return false
                }
                return true
              })
              .map((p) => p.key),
          ],
        } as FixitRosterItem
      }
    })
  }, [baseRoster, sheets, stuToScanMap])

  // get unassigned students
  const unassignedStudents = useMemo(() => {
    let result = roster
      .filter(({ sheets }) => {
        // todo: process this on earlier
        let scans = sheets.flat()
        return scans.length === 0 || scans.some((p) => !p)
      })
      .map((p) => p.student)
    return result
  }, [roster])

  const unassignedStudentMap = useMemo(() => {
    return new Map(unassignedStudents.map((p) => [p.id, p]))
  }, [unassignedStudents])

  // sectionify roster
  const sectioned = useFixitSectioned(roster)

  // console.timeEnd('useDigestify')
  return {
    dirty: data?._dirty,
    batchCount: data?.base?.batches.length,
    sectioned,
    stuToScanMap,
    unassignedStudentMap,
    unassignedStudents,
  }
}

type SlotifyProps = {
  baseSheets: FixitSheetBase[]
  originalSheets: FixitSheetBase[]
  packetId: string
  studentMap: Map<string, Student>
}

function slotify(props: SlotifyProps) {
  const { baseSheets, originalSheets, packetId, studentMap } = props

  let sheets: FixitSheet[] = []
  let rows: FixitSheetRow[] = []
  let slots: FixitSlot[] = []

  sheets = baseSheets.map((sheet) => {
    const { data, fixedKeySet, status, student } = crunchFixitSheet({
      packetId,
      sheet,
      studentMap,
    })
    const result: FixitSheet = {
      ...sheet,
      _fixedKeySet: fixedKeySet,
      rubric: data.rubric,
      score: data.pts,
      status,
      student,
    }
    return result
  })

  const slotTypes: FixitSlotType[] = ['packet', 'student', 'score', 'rubric']

  for (let [sheetIndex, current] of sheets.entries()) {
    const original = crunchFixitSheet({
      packetId,
      sheet: originalSheets[sheetIndex],
      studentMap,
    })
    const sheetId = current.id

    const ourSlots: FixitSheetRow['slots'] = {}

    // Add to rows
    rows.push({ id: current.id, slots: ourSlots })

    for (let type of slotTypes) {
      const ignoredSet = new Set<FixitIDStatus>(['ignore', 'not-this'])
      const isIgnored =
        ignoredSet.has(ourSlots.packet?.status as any) ||
        ignoredSet.has(ourSlots.student?.status as any)

      // todo: messy! we don't want to create a slot for the packet if the student is not in the roster
      const isPacketIgnored =
        current.status.packet === 'no-qr' &&
        (current.status.student === 'not-this' ||
          current.status.student === 'ignore')

      // todo: it probably(?) doesn't make sense to edit most things out of order...
      const isPacketIded = ourSlots.packet?.status === 'success'
      const isFullIded = isPacketIded && ourSlots.student?.status === 'success'

      // skip on ignored
      if (isIgnored) {
        continue
      } else if (type === 'packet' && isPacketIgnored) {
        continue
      }

      // otherwise include slot and fill out the _fields
      let slot: FixitSlot = {
        _dirty: null,
        _editable: null,
        _review: null,
        _savedChanges: null,
        id: `${sheetId}.${type}`,
        rubric: current.rubric,
        score: current.score,
        sheetIndex,
        sheetId,
        status: current.status[type],
        student: current.student,
        type,
      }
      ourSlots[type] = slot

      slots.push(slot)

      // todo: this is a disaster!
      if (type === 'packet') {
        slot._dirty = current.status.packet !== original.status.packet
        // can fix mistakes, but can't override QR
        slot._editable =
          current._actionSet.packet === 'not-this-packet' || // marked not-this-packet locally
          original.fixedKeySet.has('packetId') || // fixed manually on the server
          slot._dirty || // fixed manually on the client
          current.status.packet === 'no-qr' || // no-qr
          current.status.packet === 'ignore' // ignored
        slot._review = original.status.packet === 'no-qr'
        slot._savedChanges =
          current._fixedKeySet.has('packetId') || isPacketIgnored
      } else if (type === 'student') {
        slot._dirty = current.student?.id !== original.student?.id
        slot._editable =
          current._actionSet.student === 'not-this-roster' || // marked not-this-roster locally
          current._actionSet.student === 'no-student' ||
          current.status.student === 'ignore' ||
          original.fixedKeySet.has('studentId') || // fixed manually on the server
          (isPacketIded && (slot._dirty || current.status.student === 'no-qr')) // dirty or not-id'd on the client
        slot._review = slot._editable && original.status.student === 'no-qr'
        slot._savedChanges =
          current._fixedKeySet.has('studentId') ||
          current.status.student === 'ignore'
      } else if (type === 'score') {
        slot._dirty = current.score !== original.data.pts
        slot._editable = !isIgnored && isFullIded
        slot._review = slot._editable && original.status.score !== 'success'
        slot._savedChanges = current._fixedKeySet.has('pts')
      } else if (type === 'rubric') {
        slot._dirty = !isEqual(current.rubric, original.data.rubric)
        slot._editable = !isIgnored && isFullIded
        slot._review = !current.rubric
        slot._savedChanges = current._fixedKeySet.has('rubric')
      }

      // todo: this is a mess, and also don't want this to change when fixing...
      current._review = Object.values(ourSlots).some((p) => p._review)
    }
  }

  return { rows, sheets, slots }
}
