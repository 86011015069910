import { APIs, ResultOf } from '@paper/api-specs'
import { orderBy } from 'lodash'
import { useApiMutation, useApiQuery } from '~src/data/useApiQuery'

export type AdminArrays = ResultOf<'setup.admin.get'>

type Sorters<T extends Record<string, any>> = {
  [K in keyof T]: {
    sorter(item: T[K][number]): any
    keyer(item: T[K][number]): string | number
  }
}

export const adminSorters: Sorters<AdminArrays> = {
  admins: {
    sorter: (item) => {
      let [handle, domain] = item.split('@')
      return `${domain}@${handle}`
    },
    keyer: (item) => item,
  },
  domains: { sorter: (item) => item.domain, keyer: (item) => item.domain },
  years: { sorter: (item) => -item.syId, keyer: (item) => item.code },
}

export const useAdminSetupData = () => {
  return useApiQuery({
    apiSpec: APIs['setup.admin.get'],
    queryVars: {},
    queryFn: async ({ plainFetch }) => {
      const data = await plainFetch()
      // sort
      for (let key in data) {
        data[key] = orderBy(data[key], adminSorters[key].sorter)
      }

      return data
    },
    useQueryProps: {},
  })
}

export const useAdminSubmitter = () => {
  return useApiMutation({
    apiSpec: APIs['setup.admin.set'],
    useMutationProps: {},
  })
}
