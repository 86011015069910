import { AuthDomain, AuthUser } from '../types/auth'
import { IllXpacket } from '../types/illuminate'
import { Packetmeta } from '../types/packetmeta'
import { PrintManifest } from '../types/print'
import { Curriculum, CurriculumVariant, SchoolYear } from '../types/school'
import { TeacherSection } from '../types/teacher'
import { CrunchBatch, ScanBatch } from '../types/types.imgp'
import { LoadMeta, LoadSchedule } from '../types/types.load'
import { StrId } from '../types/types.packet'
import { Pin, PinQCellDb } from '../types/types.pin'
import { FullStd } from '../types/types.std'
import { Xpacket } from '../types/types.xpacket'

/** [C]ollection[M]ap - maps collection names to doc type */
export type CM = {
  _load_meta: LoadMeta
  _load_schedule: LoadSchedule
  auth_domain: AuthDomain
  auth_user: AuthUser
  curriculum: Curriculum
  curriculum_variant: CurriculumVariant
  ill_qcell: PinQCellDb
  ill_xpacket: IllXpacket
  packetmeta: Packetmeta
  pin: Pin
  printmanifest: PrintManifest
  scanbatch: ScanBatch
  scanbatch_crunched: CrunchBatch
  schoolyear: SchoolYear
  section: TeacherSection
  standard: FullStd
  xpacket: Xpacket
}

/** Map of collection names as strings */
export const Collections: Record<keyof CM, string> = {
  _load_meta: '_load_meta',
  _load_schedule: '_load_schedule',
  auth_domain: 'auth_domain',
  auth_user: 'auth_user',
  curriculum: 'curriculum',
  curriculum_variant: 'curriculum_variant',
  ill_qcell: 'ill_qcell',
  ill_xpacket: 'ill_xpacket',
  packetmeta: 'packetmeta',
  pin: 'pin',
  printmanifest: 'printmanifest',
  scanbatch: 'scanbatch',
  scanbatch_crunched: 'scanbatch_crunched',
  schoolyear: 'schoolyear',
  section: 'section',
  standard: 'standard',
  xpacket: 'xpacket',
}

export const C = Collections

type Get_id<T> = (doc: T) => any
const idAs_id = (doc: StrId) => doc.id

export const get_ids: { [key in keyof CM]: Get_id<CM[key]> } = {
  _load_meta: undefined,
  _load_schedule: undefined,
  auth_domain: (doc) => doc.domain,
  auth_user: undefined,
  curriculum: idAs_id,
  curriculum_variant: (doc) => doc.name,
  ill_qcell: (doc) => doc.cellId,
  ill_xpacket: undefined,
  packetmeta: idAs_id,
  pin: (doc) => `${doc.cellId}.${doc.aStr}`,
  printmanifest: idAs_id,
  scanbatch: idAs_id,
  scanbatch_crunched: idAs_id,
  schoolyear: (doc) => doc.code,
  section: idAs_id,
  standard: undefined,
  xpacket: idAs_id,
}
