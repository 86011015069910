import { Button, Checkbox, Icon } from '@chakra-ui/react'
import { IcoFunnel, IcoUnscored } from '@paper/icons'
import { useRouter } from '@paper/route'
import { BigIconFontSize, GRAY_TIME } from '@paper/styles'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { Children, cloneElement, ReactNode } from 'react'
import { KeyboardInstructions } from '~src/blocks/keyboardInstructions'
import {
  DarkModeMenuStyle,
  MenuBody,
  MenuDetailPane,
  MenuDetailsSection,
  useMenu,
} from '~src/blocks/menu'
import { SWSectionPicker } from '~src/blocks/pickers'
import { FilterPicker } from '~src/blocks/pickers/filterPicker'
import { HStack, Txt, VStack } from '~src/components'
import { RD_SW_Time } from '~src/routelist'
import { TeaGridDigest } from './timeAirlock'
import { MiniPie } from './timeCell'

const AllStandardsValue = 'All standards'

export const timeKeyboardInstructions = (
  <KeyboardInstructions adName="packets" wsName="students" />
)

export function TimeFilterMenu(props: { digest: TeaGridDigest }) {
  const { routeData } = useRouter<RD_SW_Time>()
  const { menuButtonProps, onOpenChange, open } = useMenu({ caret: true })

  const std = routeData.std ?? AllStandardsValue
  const filterCount = [
    routeData.f_packet,
    routeData.f_sectionId,
    routeData.std,
  ].filter((p) => p).length

  return (
    <DropdownMenu.Root modal={false} onOpenChange={onOpenChange} open={open}>
      <DropdownMenu.Trigger asChild>
        <Button
          data-cy="menu-filter"
          height="48px"
          variant="ghost"
          {...menuButtonProps}
        >
          <Icon as={IcoFunnel} fontSize={BigIconFontSize} mr={2} />
          <Txt as="span" fontFamily="mono">
            ({filterCount})
          </Txt>
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content asChild align="start" sideOffset={4}>
          <MenuBody {...DarkModeMenuStyle} fontSize="xs" pt={0} width="500px">
            <MenuDetailPane separator={true}>
              <MenuDetailsSection fontSize="xs" heading="Filters">
                <Filters digest={props.digest} std={std} />
              </MenuDetailsSection>
              <MenuDetailsSection heading="Keyboard navigation">
                {timeKeyboardInstructions}
              </MenuDetailsSection>
              <MenuDetailsSection heading="Legend">
                <Legend />
              </MenuDetailsSection>
            </MenuDetailPane>
          </MenuBody>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

// todo: fix props...
function Filters(props: { digest: TeaGridDigest; std: string }) {
  const { packetNumberPrefixes, sections, stds } = props.digest
  const { dispatchStay, routeData } = useRouter<RD_SW_Time>()

  const sharedProps = { size: 'xs', width: '200px' }

  return (
    <VStack alignItems="stretch" gap={3}>
      <FilterRow label="Section">
        <SWSectionPicker {...sharedProps} items={sections} />
      </FilterRow>
      <FilterRow label="Standard">
        <FilterPicker
          {...sharedProps}
          filterType="contains"
          itemPlural="standards"
          items={stds}
          onChange={(std) => dispatchStay({ std })}
          selectedId={routeData.std}
        />
      </FilterRow>
      <FilterRow label="Packet">
        <FilterPicker
          {...sharedProps}
          filterType="startsWith"
          fontFamily="mono"
          getLabel={(prefix) => `${prefix}*`}
          itemPlural="packets"
          items={packetNumberPrefixes}
          onChange={(next) => dispatchStay({ f_packet: next })}
          selectedId={routeData.f_packet}
        />
      </FilterRow>
      <FilterRow label="Hide empty columns">
        <Checkbox
          isChecked={routeData.tgf_col === 'hide-empty'}
          onChange={(event) =>
            dispatchStay({
              tgf_col: event.target.checked ? 'hide-empty' : null,
            })
          }
          size="sm"
        />
      </FilterRow>
    </VStack>
  )
}

type LegendSectionProps = {
  itemGap?: string
  items: ReactNode
  label: ReactNode
}

function LegendSection(props: LegendSectionProps) {
  const { itemGap, items, label } = props
  return (
    <VStack alignItems="stretch" gap={1}>
      {label}
      <HStack gap={itemGap ?? '.375rem'}>{items}</HStack>
    </VStack>
  )
}

function Legend() {
  return (
    <VStack alignItems="stretch" fontSize="xs" gap={4}>
      <LegendSection
        label={<Txt as="span">If Paper has Illuminate data</Txt>}
        items={[true, false].map((hasScans) => (
          <HStack key={hasScans.toString()} gap={2}>
            <MiniPie hasScans={hasScans} pct={70} />
            <Txt>{hasScans ? 'With scans' : 'No scans'}</Txt>
          </HStack>
        ))}
      />
      <LegendSection
        label="Everything else"
        items={[true, false].map((hasScans) => (
          <HStack key={hasScans.toString()} gap={2}>
            <Icon
              as={IcoUnscored}
              color={hasScans ? GRAY_TIME : 'transparent'}
              width="14px"
            />
            <Txt>{hasScans ? 'With scans' : 'No scans'}</Txt>
          </HStack>
        ))}
      />
    </VStack>
  )
}

type FilterRowProps = {
  children: Parameters<typeof cloneElement>[0]
  label: string
}
function FilterRow(props: FilterRowProps) {
  const { label } = props
  const id = `filterRow${label}`

  const filter = cloneElement<any>(Children.only(props.children), {
    'aria-labelledby': id,
  })

  return (
    <HStack gap={2} minHeight="24px">
      <Txt id={id} minWidth="50px">
        {label}
      </Txt>
      {filter}
    </HStack>
  )
}
