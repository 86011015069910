import type { InvalidateQueryFilters, QueryKey } from '@tanstack/react-query' // todo: figure out how to deal with this import!
import type { Except } from 'type-fest'
import type { ApiApp, InvalidateKey } from './apiConstants'
import { ApiFetcher } from './fetcher'

export type BodyMethod = 'post' | 'put' | 'delete' | 'patch'
export type Method = 'get' | BodyMethod

export type ApiDefBase<R = any, B = any, PP = any, SP = any> = {
  pathParams?: PP
  result: R
} & (
  | { body?: never; method: 'get'; searchParams?: SP }
  | { body: B; method: BodyMethod; searchParams?: never }
)

export type InvalidateOnMutationProps = {
  /** todo: only runs on useMutation,
   * todo: also not sure if this should be a reference to the api itself?
   * (apis: APIs) => [APIs.setup_school_get, APIs.dir_list]
   */
  invalidateOnMutationSuccess?: InvalidateKey[]
  invalidateQueryFilters?: Except<InvalidateQueryFilters, 'queryKey'>
}

type ApiProps<A extends ApiDefBase> = {
  /** Top-level app prepended to URL todo: move this definition */
  app?: ApiApp
  /** A key to group apis for invalidation */
  invalidateKey?: InvalidateKey
  method: A['method']
  urlFactory: A['pathParams'] extends object
    ? (pathParams: A['pathParams']) => [url: string, urlKey: string]
    : string
} & InvalidateOnMutationProps

export type ApiInput<A extends ApiDefBase> = Except<A, 'method' | 'result'>
export type ApiReqData<A extends ApiDefBase> = A['body'] &
  A['pathParams'] &
  A['searchParams']

export type ApiDef<A extends ApiDefBase = any> = Except<
  ApiProps<ApiDefBase>,
  'app' | 'invalidateKey'
> & {
  app: ApiProps<A>['app']
  factory(
    vars: ApiInput<A>,
    suffix?: QueryKey
  ): { queryKey: QueryKey; url: string }
  fetch(fetchProps: NonHookFetchProps<A>): Promise<A['result']>
  pathMatches(path: string): boolean
  invalidateKey: QueryKey
}

type NonHookFetchProps<A extends ApiDefBase> = {
  fetchAs: ApiFetcher<A>
  headers?: HeadersInit
  queryVars: ApiInput<A>
}

export function defineApi<A extends ApiDefBase>(props: ApiProps<A>): ApiDef<A> {
  const { invalidateKey, ...rest } = props
  // Default to api since most of our apis live there
  const { app = 'api', urlFactory } = props

  const factory: ApiDef<A>['factory'] = (vars, suffix) => {
    const { invalidateKey, urlFactory } = props
    // produce full url, and url key
    let [url, urlKey] = (
      typeof urlFactory === 'string'
        ? [urlFactory, urlFactory]
        : urlFactory(vars.pathParams)
    )
      // prepend `app` to url
      // e.g. `api/pin` vs. `imgp_generate`
      .map((url: string) => (url ? `${app}/${url}` : app))

    // query key is [urlKey, vars]
    let queryKey: QueryKey = [urlKey, vars]
    // or [invalidateKey, urlKey, vars]
    if (invalidateKey) {
      queryKey = [invalidateKey, ...queryKey]
    }
    // escapte valve suffix
    if (suffix) {
      queryKey = [...queryKey, ...suffix]
    }

    return { queryKey, url }
  }

  const fetch: ApiDef<A>['fetch'] = (fetchProps) => {
    const { fetchAs, headers, queryVars } = fetchProps
    const { method } = props
    const { body: json, searchParams } = queryVars
    const { url } = factory(queryVars)
    // @ts-expect-error
    return fetchAs(url, { method, headers, json, searchParams }).json()
  }

  const pathMatches: ApiDef<A>['pathMatches'] = (path) => {
    // todo: need to clean up url story
    // todo: just gonna copy/paste in the above for now
    // produce full url, and url key
    let [, urlKey] =
      typeof urlFactory === 'string' ? [urlFactory, urlFactory] : urlFactory({})
    // todo: should this be endsWith
    return path.includes(urlKey)
  }

  return {
    ...rest,
    app,
    factory,
    fetch,
    invalidateKey: [invalidateKey],
    pathMatches,
  }
}
