import { Button } from '@chakra-ui/react'
import { useStateAndRef } from '@paper/route/src/utils'
import { PageItemType, PorQ } from '@paper/schema'
import { FormikErrors, useFormikContext } from 'formik'
import { Dispatch, Reducer, SetStateAction, useCallback, useMemo } from 'react'
import { ActionFactory } from '~src/blocks/answerKey'
import { HStack } from '~src/components'
import { useAlwaysUpdateRef } from '~src/utils/useRefs'
import { useWizContext } from './wizProvider'

export type ListAction<T = any> =
  | { type: 'add'; item: T }
  | { type: 'delete'; item: T }
  | { type: 'move'; item?: undefined; mover(items: T[]): T[] }
  | { type: 'save'; item: T }
  | { type: 'selectToEdit'; item: T }
  | { type: 'unselect'; item?: undefined }

export type FormListState<S, V, A = S> = {
  lastAdded: A
  selected: S
  values: V
}

export function useFormListValue<S, V, A>(
  reducer: Reducer<FormListState<S, V, A>, ListAction<A>>,
  getInitialSelection: (values: V) => S
) {
  const { formik } = useWizContext() // todo: ugh

  // todo: i think the types got messed up due to not using useFormikContext
  // todo: messily changing the types for now
  const setValues = formik.setValues as any as (
    values: SetStateAction<V>,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<V>> | Promise<void>
  const values = formik.values as V
  const errors = formik.errors as FormikErrors<V>

  const [selected, setSelected, selectedRef] = useStateAndRef(() =>
    getInitialSelection(values)
  )
  const [lastAdded, setlastAdded, lastAddedRef] = useStateAndRef<A>()

  const valuesRef = useAlwaysUpdateRef(values)

  const dispatch = useMemo(() => {
    return (action: ListAction<A>) => {
      // Run reducer to get next state
      const nextState = reducer(
        {
          lastAdded: lastAddedRef.current,
          selected: selectedRef.current,
          values: valuesRef.current,
        },
        action
      )

      // update if there are changes
      if (selectedRef.current !== nextState.selected) {
        setSelected(nextState.selected)
      }
      if (valuesRef.current !== nextState.values) {
        setValues(nextState.values)
      }
      if (lastAddedRef.current !== nextState.lastAdded) {
        setlastAdded(nextState.lastAdded)
      }
    }
  }, [])

  return { dispatch, errors, lastAdded, selected, values }
}

export function useFormActionFactory(
  dispatch: Dispatch<ListAction<PorQ>>,
  itemTypeFilter?: PageItemType
) {
  return useCallback<ActionFactory>(
    ({ itemType, item }) => {
      if (itemTypeFilter && itemType !== itemTypeFilter) {
        return []
      } else {
        return [
          {
            children: 'Edit',
            onClick: () => dispatch({ type: 'selectToEdit', item }),
          },
          {
            children: 'Delete',
            onClick: () => dispatch({ type: 'delete', item }),
          },
        ]
      }
    },
    [itemTypeFilter, dispatch]
  )
}

export const SubFormButtons = ({ onUnselect }) => {
  const { dirty, handleSubmit, isValid, isSubmitting, resetForm, values } =
    useFormikContext<{ _new: boolean }>()

  // NOTE: The Button types are purposely not submit/reset
  // because we don't want them to submit the super-form
  // I can imagine we'd eventually need another workaround for accessibility...
  return (
    <HStack gap={6}>
      <Button
        // NOTE: Since we autoincrement for Qs, the QForm doesn't need to be dirty to be submittable
        isDisabled={!isValid || isSubmitting}
        // @ts-expect-error todo:
        onClick={handleSubmit}
      >
        {values._new ? 'Add' : 'Save'}
      </Button>
      <Button
        // Only disabled when new (not editing existing) AND dirty
        isDisabled={values._new && !dirty}
        onClick={() => {
          values._new ? resetForm() : onUnselect()
        }}
        variant="outline"
      >
        {values._new ? 'Clear' : 'No changes'}
      </Button>
    </HStack>
  )
}
