import {
  Box,
  BoxProps,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import { IcoX } from '@paper/icons'
import type { PacketContent, XpageSW } from '@paper/schema'
import { DEFAULT_FG } from '@paper/styles'
import type { ImageIndexSets, OneOfType } from '@paper/utils'
import { produce } from 'immer'
import { orderBy, range } from 'lodash'
import { ReactNode, useEffect, useRef } from 'react'
import {
  StackProps,
  TextStack,
  TooltippedIconButton,
  VStack,
} from '~src/components'
import config from '~src/utils/config'
import { LightMode } from '~src/utils/forceMode'
import { formatUnits } from '~src/utils/messages'
import { useSignedWildcardMapper } from '~src/utils/useSignedUrls'
import { OverrideCallout, RadioactiveCallout } from './imageCallouts'
import { ImagePage, ImagePageButton, MovedPage, NoScan } from './imagePage'

type ImageSrcAll = {
  key_: string
  keys: string[]
  pages: XpageSW[]
  unsigneds: string[]
  url: string
  urls: string[]
}

/**
 * One of
 * @example
 * {
 *  key_: 's3-key-goes-here',
 *  // or
 *  keys: ['s3-key-goes-here', ...],
 *  // or
 *  pages: [XpageSW, ...],
 *  // or
 *  unsigneds: ['https://cdn.ponderpaper.co/...', ...]
 *  // or
 *  urls: ['https://some-url-that-doesnt-require-signing']
 *  // or
 *  url: 'https://some-url-that-doesnt-require-signing'
 * }
 */
export type ImageSrc = OneOfType<ImageSrcAll>

export type ImageType = 'blank' | 'raw' | 'scanlog' | 'sw'

export type ImageViewerProps = {
  imageIndexSets?: ImageIndexSets
  imageType: ImageType
  isRadioactive?: boolean
} & ImageSrc

type ImageViewPkt = Pick<PacketContent, 'pages' | 'parts' | 'type'>

type TabImageViewerProps = ImageSrc & {
  /** todo: not implemented yet */
  hideNumbers?: boolean
  imageType: ImageType
  isRadioactive?: boolean
  pkt: ImageViewPkt
}

type PageImageSelectorProps = {
  onChange(parts: number[]): void
  pkt: ImageViewPkt
  urls: string[]
}

type GetImageColumnsOptions = {
  crunched: ReturnType<typeof useNormalizeImages>
  imageIndexSets?: ImageIndexSets
  imageType: ImageType
  isRadioactive?: boolean
  showNumbers?: boolean
}

const getImageColumns = ({
  crunched,
  imageIndexSets,
  imageType,
  isRadioactive,
  showNumbers,
}: GetImageColumnsOptions) => {
  let radioactiveCallout = isRadioactive ? <RadioactiveCallout /> : null
  let noImageBehavior = imageType === 'sw' ? 'noscan' : 'empty' // todo:
  imageIndexSets ??= [null]

  return imageIndexSets.map(
    (iis) =>
      crunched
        ?.filter((_, idx) => (iis ? iis.has(idx) : true))
        .map((p, pageIdx) => {
          return (
            <ImagePage
              callouts={
                <>
                  {radioactiveCallout}
                  {p.overrideCallout}
                </>
              }
              key={pageIdx}
              pageNumber={showNumbers && pageIdx + 1}
              src={p.src}
              noSrc={
                p.isMovedOut ? (
                  <MovedPage />
                ) : noImageBehavior === 'noscan' ? (
                  <NoScan />
                ) : null
              }
            />
          )
        })
  )
}

export const imageViewerColumnProps: StackProps = {
  alignItems: 'center',
  px: 6,
}

export function ImageViewer(props: ImageViewerProps) {
  const { imageIndexSets, imageType, isRadioactive } = props
  let crunched = useNormalizeImages(props)

  let cols = getImageColumns({
    crunched,
    imageIndexSets,
    imageType,
    isRadioactive,
    showNumbers: false,
  })

  // console.log({ crunched, cols, isArrNullOrEmpty, hasImages })

  let body: ReactNode

  if (cols?.length > 1) {
    body = (
      <Box
        display="grid"
        gridAutoColumns="1fr"
        gridAutoFlow="column"
        height="100%"
        justifyItems="center"
        maxHeight="100%" // https://github.com/ponderco/paper/issues/220 todo: may need to revisit
        overflow="hidden"
        position="relative"
        sx={{ aspectRatio: `${imageIndexSets.length * 8.5} / 11` }}
      >
        {cols.map((pages, colIdx) => (
          <ScrollViewer
            bg="white"
            color={DEFAULT_FG}
            key={colIdx}
            pages={pages}
            sx={{ aspectRatio: '8.5 / 11' }}
          />
        ))}
      </Box>
    )
  } else {
    body = (
      <ScrollViewer
        bg="white"
        color={DEFAULT_FG}
        pages={cols[0]}
        sx={{ aspectRatio: '8.5 / 11' }}
      />
    )
  }

  return <LightMode>{body}</LightMode>
}

export function TabImageViewer(props: TabImageViewerProps) {
  const { hideNumbers, imageType, isRadioactive, pkt } = props
  let crunched = useNormalizeImages(props)

  // slice up images if raw ticket
  if (imageType === 'raw' && pkt.type === 'ticket') {
    const { selectedPages } = parseImagePkt(pkt)
    crunched = crunched?.filter((_, idx) => selectedPages.has(idx))
  }

  const imageIndexSets = partitionImages(crunched, pkt)

  const tabLabeler = (colIdx: number) =>
    pkt.type === 'ticket'
      ? 'Ticket'
      : pkt.parts.length === 1
      ? 'Assessment'
      : `Part ${colIdx + 1}`

  const partCols = getImageColumns({
    crunched,
    imageIndexSets,
    imageType,
    isRadioactive,
    showNumbers: !hideNumbers,
  })

  return (
    <LightMode>
      <Tabs
        colorScheme="gray"
        display="flex"
        flexDirection="column"
        overflow="hidden"
        p={1} // pad here so we don't occlude the focus indicator
        variant="enclosed"
      >
        <TabList>
          {partCols.map((pageBtns, colIdx) => (
            <Tab key={colIdx}>
              <TextStack px={1}>
                <TextStack.Top>{tabLabeler(colIdx)}</TextStack.Top>
                <TextStack.Bottom variant="compact">
                  {formatUnits(pageBtns.length, 'page')}
                </TextStack.Bottom>
              </TextStack>
            </Tab>
          ))}
        </TabList>
        <TabPanels
          alignSelf="stretch"
          display="flex"
          justifyContent="center"
          overflow="hidden"
        >
          {partCols.map((pageBtns, colIdx) => {
            return (
              <TabPanel display="flex" key={colIdx}>
                <PageScroller>{pageBtns}</PageScroller>
              </TabPanel>
            )
          })}
        </TabPanels>
      </Tabs>
    </LightMode>
  )
}

const parseImagePkt = (pkt: ImageViewPkt) => {
  // get selected pages
  let startIndex = pkt.type === 'ticket' ? pkt.parts[0] : null
  let packetLength = pkt.pages?.length ?? 0

  const selectedPages = new Set<number>()

  // add each ticket page
  if (startIndex != null) {
    for (let i = 0; i < packetLength; i++) {
      selectedPages.add(i + startIndex)
    }
  }
  return { packetLength, selectedPages, startIndex }
}

/**
 * Widget to select ticket pages
 */
export function TicketPageSelector(props: PageImageSelectorProps) {
  const { onChange, pkt, urls } = props

  // for scrolling
  const packetStartRef = useRef<HTMLButtonElement>()

  let { packetLength, startIndex, selectedPages } = parseImagePkt(pkt)

  // make buttons
  let pageBtns = urls?.map((url, idx) => {
    let disabled = !packetLength
    let selected: boolean
    let pageNumber: number
    let partsIfSelected: number[]

    if (pkt.type === 'ticket') {
      // ticket limits which pages are included
      selected = selectedPages.has(idx)
      // disable sleecting pages that would fall off the end of the packet
      disabled ||= idx > urls.length - packetLength
      // start numbering from the selected page
      pageNumber = idx - startIndex + 1
      // set parts to this page if selected
      partsIfSelected = [idx]
    } else if (pkt.type === 'assessment') {
      // assessments include all pages
      selected = true
      // thus clicks are disabled
      disabled = true
      // one column, so simple numbering
      pageNumber = idx + 1
    }

    return (
      <ImagePageButton
        disabled={disabled}
        idx={idx}
        key={idx}
        onClick={() => onChange(partsIfSelected)}
        pageNumber={pageNumber}
        ref={idx === startIndex ? packetStartRef : undefined}
        selected={selected}
        src={url}
      />
    )
  })

  // hack to scroll newly selected ticket page into view
  // todo: this doesn't work on initial load because the images don't have height
  useEffect(() => {
    if (startIndex != null) {
      packetStartRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }, [startIndex, selectedPages.size])

  return (
    <HStack alignItems="stretch" gap={4} maxWidth="100%" overflowX="auto">
      <ImageColumn
        canUnsplit={false}
        label={null}
        maxWidth="500px"
        onUnsplit={null}
      >
        {pageBtns}
      </ImageColumn>
    </HStack>
  )
}

export function PartsSelector(props: PageImageSelectorProps) {
  const { onChange, pkt } = props
  const iis = partitionImages(props.urls, pkt)
  const partCols = iis.map((ii, colIdx) =>
    props.urls
      .filter((_, idx) => ii.has(idx))
      .map((src, idx) => {
        // absolute index
        const absIdx = idx + pkt.parts[colIdx]
        // can't double select first item
        let disabled = idx === 0
        // all pages are included
        let selected = true
        // number pages across columns
        let pageNumber = absIdx + 1
        // add this part if selected
        let partsIfSelected = orderBy([...pkt.parts, absIdx])

        return (
          <ImagePageButton
            disabled={disabled}
            idx={idx}
            key={idx}
            onClick={() => onChange(partsIfSelected)}
            pageNumber={pageNumber}
            selected={selected}
            src={src}
          />
        )
      })
  )

  return (
    <HStack alignItems="stretch" gap={4} maxWidth="100%" overflowX="auto">
      {partCols.map((pageBtns, colIdx) => {
        return (
          <ImageColumn
            canUnsplit={colIdx > 0}
            key={colIdx}
            label={
              partCols.length > 1 && (
                <TextStack>
                  <TextStack.Top>Part {colIdx + 1}</TextStack.Top>
                  <TextStack.Bottom variant="sardine">
                    {formatUnits(pageBtns.length, 'page')}
                  </TextStack.Bottom>
                </TextStack>
              )
            }
            maxWidth="320px"
            onUnsplit={() =>
              onChange(
                produce(pkt.parts, (draft) => {
                  draft.splice(colIdx, 1)
                })
              )
            }
          >
            {pageBtns}
          </ImageColumn>
        )
      })}
    </HStack>
  )
}

type ImageColumnProps = {
  canUnsplit: boolean
  children: ReactNode
  label: ReactNode
  maxWidth: string
  onUnsplit(): void
}

function ImageColumn(props: ImageColumnProps) {
  const { canUnsplit, children, label, maxWidth, onUnsplit } = props

  // scrollIntoView self on mount
  const domRef = useRef<HTMLDivElement>()
  useEffect(() => {
    domRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [])

  return (
    <VStack bg="gray.50" maxWidth={maxWidth} minWidth="200px" ref={domRef}>
      {label && (
        <Box
          bg="inherit"
          p={4}
          pb={2}
          position="relative"
          textAlign="center"
          width="100%"
        >
          {label}
          {canUnsplit && (
            <TooltippedIconButton
              aria-label="Unsplit"
              icon={<IcoX />}
              onClick={onUnsplit}
              position="absolute"
              right={0}
              top={0}
              variant="ghost"
            />
          )}
        </Box>
      )}
      <VStack
        gap={PageSpacingGap}
        overflowY="auto"
        p={4}
        sx={{ scrollPadding: 6 }}
      >
        {children}
      </VStack>
    </VStack>
  )
}

type ScrollProps = { pages: ReactNode } & BoxProps

function ScrollViewer(props: ScrollProps) {
  const { pages, ...boxProps } = props
  //console.log('<ScrollViewer/>', srcs, noSrc)
  return (
    <Box className="scroll-viewer" height="100%" overflowY="auto" {...boxProps}>
      {pages}
    </Box>
  )
}

const PageSpacingPx = 16
const PageSpacingGap = `${PageSpacingPx}px`

function PageScroller({ children }) {
  return (
    <VStack
      gap={PageSpacingGap}
      overflowY="auto"
      p={4}
      sx={{ scrollPadding: 4 }}
    >
      {children}
    </VStack>
  )
}

export const useNormalizeImages = (input: ImageSrc) => {
  let { key_, keys, pages, unsigneds, url, urls } = input

  // extract keys from key or pages (if defined)
  if (key_) {
    keys = [key_]
  } else if (pages) {
    keys = pages.map((p) => p.key)
  }

  // get unsigned urls from keys (if defined)
  if (keys) {
    unsigneds = keys.map((key) => (key ? `${config.cdnOrigin}/${key}` : null))
  }

  // get signer (if there are unsigned urls)
  const wildcardUrl = unsigneds?.find((p) => p)
  const signerResult = useSignedWildcardMapper(wildcardUrl)

  if (unsigneds) {
    if (!wildcardUrl) {
      // nothing to sign
      urls = unsigneds
    } else if (signerResult.isSuccess) {
      // add the signature
      urls = unsigneds.map(signerResult.data)
    } else {
      // empty while waiting
      urls = []
    }
  }

  if (url) {
    urls = [url]
  }

  return urls?.map((url, idx) => {
    let xpage = pages?.[idx]
    let isMovedOut = xpage?._overrideType === 'move-out'
    // console.log(xpage)
    let overrideCallout = !xpage?._overrideType ? null : (
      <OverrideCallout {...xpage} />
    )

    return { isMovedOut, overrideCallout, src: url }
  })
}

const partitionImages = (arr: any[], pkt: ImageViewPkt) => {
  if (!arr?.length || !pkt?.pages?.length || !pkt.parts?.length) {
    return []
  } else if (pkt.type === 'assessment' && pkt.parts?.length > 1) {
    return pkt.parts.map((pageIdx, partIdx) => {
      // console.log({
      //   pageIdx,
      //   end: pkt.parts[partIdx + 1],
      //   range: range(pageIdx, pkt.parts[partIdx + 1]),
      // })
      return new Set(range(pageIdx, pkt.parts[partIdx + 1] ?? pkt.pages.length))
    })
  } else {
    return [new Set(range(0, pkt.pages.length))]
  }
}
