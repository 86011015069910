import { filterObject } from '@paper/utils'
import Bowser from 'bowser'
import { serializeError } from 'serialize-error'
import type { ErrorResponse } from '~src/blocks/errors/errorUtils'
import { PAPER_BUILD_NUMBER } from '~src/buildNumber'
import rollbar from './rollbar'

const getPlatform = () => {
  const { browser, os, platform } = Bowser.parse(window.navigator.userAgent)
  return { browser, os, platform }
}

const formatDimensions = (obj: any, prefix = '') => {
  return ['Width', 'Height']
    .map(
      (key) => obj[prefix ? `${prefix}${key}` : key.toLowerCase()] ?? 'notfound'
    )
    .join('x')
}

const getPlatformString = () => {
  const { browser, os, platform } = getPlatform()
  return Object.entries({
    'Paper Build': [PAPER_BUILD_NUMBER ?? '[not available]'],
    Browser: [browser.name, browser.version],
    OS: [os.name, os.version, os.versionName],
    Platform: [platform.type, platform.vendor, platform.model],
    Window: [formatDimensions(window, 'inner')],
    Screen: [
      formatDimensions(window.screen, 'avail'),
      formatDimensions(window.screen),
    ],
  })
    .map(
      ([label, values]) => `* ${label}: ${values.filter((p) => p).join(' | ')}`
    )
    .join('\n')
}

const DefaultSupportEmail = 'support@ponder.co'

export type SupportLinkProps = {
  email?: string
  error?: Error
  response?: ErrorResponse
  subject?: string
  type?: 'error' | 'not-an-error'
  uuid?: string
}

export function getSupportMailTo(props: SupportLinkProps) {
  let email = props.email ?? DefaultSupportEmail
  let subject = `[Ponder Paper] ` + (props.subject ?? '')
  let data = filterObject(
    {
      error: props.error && serializeError(props.error),
      href: window.location.href,
      response: props.response,
      uuid: props.uuid,
    },
    (p) => !!p
  )
  let stringified = JSON.stringify(data, undefined, 2)
  let encoded = encodeURIComponent(stringified)

  let sections = [
    '[Let us know what went wrong. Screenshots appreciated!]',
    '',
    `---- Below is some info we've collected to help fix the problem ----`,
    encodeURIComponent(getPlatformString()),
    `${'```'}%0A${encoded}%0A${'```'}`,
  ]

  // add spacing between sections
  let body = sections.join('%0A%0A')

  let link = `mailto:${email}?body=${body}`
  if (subject) {
    link += `&subject=${subject}`
  }

  return link
}

type MailToProps = Omit<SupportLinkProps, 'uuid'>

/**
 * Returns link props for contacting support
 */
export function useContactSupportLink(props: MailToProps) {
  const { error, response, subject } = props
  const email = props.email ?? DefaultSupportEmail

  return {
    href: `mailto:${email}`,
    onClick: (event) => {
      event.preventDefault()
      // send to rollbar
      const { uuid } = rollbar.error('ContactSupport', error, {
        response,
        subject,
      })

      const mailto = getSupportMailTo({ email, error, response, subject, uuid })
      // open mailto link
      window.location.href = mailto
    },
  }
}

// Add to window for internal folks
// @ts-expect-error
window.copyPlatformData = () => {
  // @ts-expect-error
  copy(getPlatformString())
}
