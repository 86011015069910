import { Icon } from '@chakra-ui/react'
import { IcoExclamationTriangle } from '@paper/icons'
import { ReactNode } from 'react'
import { Txt } from '~src/components'
import { usePdfBuilderContext } from './pdfBuilderProvider'

export const usePdfPrintError = () => {
  const { selected: item } = usePdfBuilderContext()

  let body: ReactNode

  if (!item) {
    // no error
  } else if (item.error) {
    body = (
      <>
        An error occurred. It may be something transient, in which case it may
        succeed if you try again in a bit. If it fails again, please contact
        support.
      </>
    )
  } else if (item.status === 'timed-out') {
    body = (
      <>
        The last attempt to generate these packets timed out. Please contact
        support.
      </>
    )
  } else if (item.status === 'in-progress' && !item.inflight) {
    body = (
      <>
        These packets are being generated by another person (or tab). Please
        close this dialog and reopen it in a few minutes to download the result.
      </>
    )
  }

  return {
    hasError: !!body,
    node: !body ? null : (
      <Txt
        borderColor="red.500"
        borderLeftWidth="3px"
        data-cy="error"
        fontSize="xs"
        px={2}
      >
        <Icon as={IcoExclamationTriangle} color="red.500" mr={1.5} mb="-1px" />
        {body}
      </Txt>
    ),
  }
}

export function PdfPrintError() {
  return usePdfPrintError().node
}
