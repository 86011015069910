import { AuthRole } from '@paper/schema'
import { NotFoundError } from '~src/blocks/errors'
import { useUser } from '~src/blocks/userProvider'

const getNotFoundError = () => {
  return new NotFoundError({
    friendly: `There's nothing at this URL for you!`,
    thing: 'page',
    value: location.pathname,
  })
}

/**
 * Throws 404 if not internal
 */
export function useLimitToInternal() {
  const { isInternal } = useUser()

  // Limit access to ponder.co admins
  const canAccess = isInternal

  if (!canAccess) {
    throw getNotFoundError()
  }
}

export function useLimitToRole(role: AuthRole) {
  const { user } = useUser()

  // Limit access...
  const canAccess = user.roles.includes(role)

  if (!canAccess) {
    throw getNotFoundError()
  }
}
