import { Box, BoxProps } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'

type FileInputProps = Omit<BoxProps, 'children'> & {
  children: ReactNode | ((isDragActive: boolean) => ReactNode)
  dropzone: DropzoneOptions
}

export function FileInput(props: FileInputProps) {
  const { children, dropzone, ...dropTargetProps } = props
  const { getRootProps, getInputProps, isDragActive } = useDropzone(dropzone)

  const color = 'gray'
  const highlightedColor = `${color}.400`

  return (
    <Box
      as="button"
      alignItems="center"
      backgroundColor={isDragActive ? `${color}.200` : `${color}.100`}
      borderRadius={1}
      borderColor={isDragActive ? highlightedColor : `${color}.300`}
      borderStyle="dashed"
      borderWidth="3px"
      color={`${color}.500`}
      d="flex"
      justifyContent="center"
      p={5}
      transition="all .4s"
      _hover={{
        borderColor: highlightedColor,
        textDecoration: 'underline',
      }}
      {...dropTargetProps}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {typeof children === 'function' ? children(isDragActive) : children}
    </Box>
  )
}

export function isEvtWithFiles(event) {
  if (!event.dataTransfer) {
    return !!event.target && !!event.target.files
  }
  // https://developer.mozilla.org/en-US/docs/Web/API/DataTransfer/types
  // https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/Recommended_drag_types#file
  return Array.prototype.some.call(
    event.dataTransfer.types,
    (type) => type === 'Files' || type === 'application/x-moz-file'
  )
}
