type ValueKeyIterateeTypeGuard<T, S extends T> = (
  value: T,
  key: string
) => value is S

// todo: types and tests

export function pick<T extends object, K extends keyof T>(
  object: T,
  keys: readonly K[]
): Pick<T, K> {
  const result: Partial<T> = {}
  for (let k of keys) {
    result[k] = object?.[k]
  }
  return result as Pick<T, K>
}

export function pickBy<T extends object>(
  object: T,
  predicate: (value: any, key: string) => boolean
): Partial<T> {
  if (object == null) {
    return {}
  }
  const result: Partial<T> = {}
  for (const [key, value] of Object.entries(object)) {
    if (predicate(value, key)) {
      result[key] = value
    }
  }
  return result
}
