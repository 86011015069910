import { FixitRosterItem } from '@paper/schema'
import { groupBy } from '@paper/utils'
import { values } from 'lodash'
import { useMemo } from 'react'

type RosterStatSet = {
  rubric: number
  scans: number
  score: number
  students: number
}

export type FixitRoster = {
  section: { id: string; name: string }
  students: FixitRosterItem[]
  stats: RosterStatSet
}

// was tempted by symbol, but react doesn't accept those as keys, though could `toString()` it...
export const FIXIT_ALL_SECTION_ID = '__fixit_section_all__'

export const useFixitSectioned = (roster: FixitRosterItem[]) => {
  return useMemo(() => {
    let grouped = values(groupBy(roster, (item) => item.section.id))

    let sectioned = grouped.map((sectionList) => {
      const { section } = sectionList[0]
      return { section, students: sectionList }
    })

    // prepend an 'all' section
    sectioned = [
      { section: { id: FIXIT_ALL_SECTION_ID, name: 'All' }, students: roster },
      ...sectioned,
    ]

    // calculate stats
    return sectioned.map((p) => {
      const result: FixitRoster = {
        ...p,
        stats: calculateSectionStats(p.students),
      }
      return result
    })
  }, [roster])
}

const calculateSectionStats = (students: FixitRosterItem[]) => {
  const stats = initRosterStatSet()

  for (let stu of students) {
    stats.students += 1
    if (stu.score != null) {
      stats.score += 1
    }
    if (stu?.rubric?.values.some((p) => p != null)) {
      stats.rubric += 1
    }
    if (stu.sheets.flat().every((p) => p)) {
      stats.scans += 1
    }
  }
  return stats
}

const initRosterStatSet = () => {
  let result: RosterStatSet = {
    rubric: 0,
    scans: 0,
    score: 0,
    students: 0,
  }
  return result
}
