import type { Curriculum } from '@paper/schema'

/**
 * Returns predicate for a given `syId` as to whether the passed in curriculum is considered active
 */
export const getActiveCurriculumPred =
  (syId: number) =>
  (c: Curriculum): boolean => {
    return c._status !== 'recalled' && c.syId === syId
  }
