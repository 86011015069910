import { Box, ButtonProps, TextProps } from '@chakra-ui/react'
import { Curriculum } from '@paper/schema'
import {
  formatSchoolYear,
  getC2NameAndId,
  makeC2,
  yearCodeToId,
} from '@paper/utils'
import { useFormikContext } from 'formik'
import { produce } from 'immer'
import { FormDialog, PublicFormDialogProps } from '~src/blocks/formDialog'
import { HStack, ToggleGroup, Txt } from '~src/components'
import {
  CurriculumForm,
  VariantList,
  VariantListStatus,
} from '../curriculumForm'
import {
  C2FamilyEd,
  C2NewEdAction,
  useCurriculumSubmitter,
} from '../data-curriculumSetup'
import { VariantActionButtons } from '../variantStatus'
import { useValidationSchema } from './validation'

/**
 * New curriculum edition dialog
 */
export function CurriculumEditionDialog(
  props: PublicFormDialogProps<C2FamilyEd>
) {
  const closeDialog = props.onClose

  // make a copy with next edition
  let data =
    props.data &&
    produce(props.data, (draft) => {
      let nextEd = formatSchoolYear(yearCodeToId(draft.ed) + 1) // todo: cleaner way
      draft.ed = nextEd
    })

  const submitter = useCurriculumSubmitter()

  const validationSchema = useValidationSchema('newedition')

  return (
    <FormDialog
      {...props}
      data={data}
      onSubmit={async (values) => {
        let changed: Curriculum[] = []
        let { curricula, statuses, ...common } = values
        curricula.forEach(({ _action, ...c }) => {
          // update variants on old curricula if requested
          if (_action === 'retire' || _action === 'createAndRetire') {
            changed.push(
              produce(c, (draft) => {
                draft._status = 'retired'
              })
            )
          }
          // create new edition curricula as requested
          if (_action === 'create' || _action === 'createAndRetire') {
            const { variant } = c
            changed.push(makeC2({ ...common, variant, _status: 'active' }))
          }
        })
        console.log('changed', changed)
        await submitter.mutateAsync(changed)
        closeDialog()
      }}
      validationSchema={validationSchema}
    >
      <CurriculumForm
        title="New edition"
        variantList={<VariantListEdition />}
      />
    </FormDialog>
  )
}

/** Variant list for new edition dialog */
function VariantListEdition() {
  const { getFieldMeta, setFieldValue, values } = useFormikContext<C2FamilyEd>()

  const fieldMeta = getFieldMeta('curricula')
  const vlError = fieldMeta.error ? `(Required)` : null

  return (
    <VariantList
      curricula={values.curricula}
      directions={{
        existing: (
          <Txt>
            Choose one or more variants for the new edition
            {/* Specify how to handle each variant from the previous edition */}
            {<VariantListStatus error={vlError} />}
          </Txt>
        ),
        new: <Txt>Add new variants</Txt>,
      }}
      renderItem={({ curriculum, idx, variant }) => {
        const _action = curriculum?._action
        const isCreating = _action === 'create' || _action === 'createAndRetire'
        const isRetiring = _action === 'retire' || _action === 'createAndRetire'

        return (
          <VariantActionButtons
            buttons={getNewEdButtons({
              isCreating,
              isRetiring,
              nextName: getC2NameAndId(values, variant.name).name,
              prevName: curriculum?.name,
            })}
            onChange={(action) => {
              if (curriculum) {
                setFieldValue(`curricula.${idx}._action`, action)
              } else {
                // todo: someone has written this logic!
                setFieldValue(`curricula.${values.curricula.length}`, {
                  _action: action,
                  variant: variant.name,
                })
              }
            }}
            preventNone={false} // todo: always allowing toggling {!!curriculum?.id} // allow toggling for create
            selectedValue={_action}
            variant={variant.name}
          />
        )
      }}
    />
  )
}

type GetNewEdButtonProps = {
  isCreating: boolean
  isRetiring: boolean
  nextName: string
  prevName: string
}

/**
 * Returns buttons for a new-edition variant row
 * todo: should probably rip more of this out...create and retire probably won't happen at the same time
 */
function getNewEdButtons(props: GetNewEdButtonProps) {
  const { isCreating, isRetiring, nextName, prevName } = props
  const rowCount = prevName ? 2 : 1

  type BtnSpec = {
    key: C2NewEdAction
    props: Partial<ButtonProps>
  }

  const create: BtnSpec = {
    key: 'create',
    props: { children: 'Create' },
  }

  // const retire: BtnSpec = {
  //   key: 'retire',
  //   props: { children: 'Retire' },
  // }

  // const createAndRetire: BtnSpec = {
  //   key: `createAndRetire`,
  //   props: {
  //     height: 'auto',
  //     gridRow: '1 / -1',
  //     children: (
  //       <Txt lineHeight="11px">
  //         Retire
  //         <br />
  //         <Txt as="span" fontSize=".5rem" lineHeight="auto">
  //           &
  //         </Txt>
  //         <br />
  //         Create
  //       </Txt>
  //     ),
  //   },
  // }

  const buttonFromOp = (opt: BtnSpec) => (
    <ToggleGroup.Button
      colorScheme="blueJeans"
      borderEndRadius={0}
      borderStartRadius={0}
      {...opt.props}
      key={opt.key}
      value={opt.key}
    />
  )

  const labelProps: TextProps = {
    as: 'span',
    alignItems: 'center',
    bg: 'gray.100',
    borderEndRadius: 'var(--chakra-radii-md)',
    display: 'flex',
    fontFamily: 'mono',
    px: 3,
  }

  // 1 vs. 2 rows
  const createLabelProps: TextProps =
    rowCount === 1
      ? {}
      : {
          borderTopEndRadius: '0 !important',
          borderTopColor: 'gray.200',
          borderTopWidth: '1px',
        }

  return (
    <Box
      data-cy="c2-newed-btns"
      display="grid"
      fontSize="xs"
      gridAutoFlow="column"
      gridTemplateColumns="auto auto auto"
      gridTemplateRows={'1fr '.repeat(rowCount)}
    >
      {/* {prevName && buttonFromOp(createAndRetire)} */}
      {prevName && (
        // placeholder since it doesn't make sense to retire here in the year-ed world (and possibly ever)
        <HStack bg="gray.100" fontStyle="italic" px={2}>
          Existing
        </HStack>
      )}
      {/* {prevName && buttonFromOp(retire)} */}
      {buttonFromOp(create)}
      {prevName && (
        <Txt
          {...labelProps}
          borderBottomEndRadius={0}
          opacity={isRetiring ? 0.35 : 1}
        >
          {prevName}
        </Txt>
      )}
      <Txt
        {...labelProps}
        {...createLabelProps}
        opacity={isCreating ? 1 : 0.35}
      >
        {isCreating && nextName}
      </Txt>
    </Box>
  )
}
