import { useRouter } from '@paper/route'
import { TableContainer } from '~src/blocks/tableHelpers'
import { UghTable } from '~src/components/table'
import type { RD_Setup_Curriculum } from '~src/routelist'
import { useCurriculumSetupColumns } from './curriculumSetupTableCols'
import { C2FamilyEd, useCurriculumSetupData } from './data-curriculumSetup'

type CurriculumSetupTableProps = {}
export function CurriculumSetupTable(props: CurriculumSetupTableProps) {
  const { dispatchStay, routeData } = useRouter<RD_Setup_Curriculum>()

  const columns = useCurriculumSetupColumns()
  const { qResult } = useCurriculumSetupData()

  return (
    <TableContainer fontSize="sm" qResult={qResult}>
      <UghTable
        aria-label="Curriculum list"
        columns={columns}
        data={qResult.data}
        onSelect={(item: C2FamilyEd) => dispatchStay({ familyEd: item.id })}
        selectedId={routeData.familyEd}
        // spacingY="snug"
      />
    </TableContainer>
  )
}
