import * as Base from './school'

export type ByTeacher = Base.Teacher & {
  sections: TeacherSection[]
}

export type TeacherSection = Base.Section & {
  students: Base.Student[]
}

export type TeacherCurriculum = Base.Curriculum & {
  sections: TeacherSection[]
}

export type SectionAxisItem = Pick<TeacherSection, 'name' | 'id' | 'students'>
