import { ImagesThat, ScanStatus } from '@paper/schema'

export const STD_BUCKETS = ['CCSS', 'APC', 'Custom'] as const
export const STD_LEVEL = ['K', '1', '2', '3', '4', '5', '6', '7', '8', 'HS']

export const JUMP_TO_KEY_ISSUE_SET = new Set<ScanStatus>([
  'error',
  'infer',
  'infer-gap',
  'manual',
  'no-qr',
])

/** @deprecated Maybe use UnionStringArray instead?  */
export const IMAGES_THAT = [...JUMP_TO_KEY_ISSUE_SET, 'unfinished'] as any as [
  ImagesThat,
  ...ImagesThat[],
]
