import { Grid, Tooltip } from '@chakra-ui/react'
import { ENDASH } from '@paper/utils'
import { formatDistance } from '@paper/utils/date'
import { capitalize } from 'lodash'
import { ReactNode } from 'react'
import { HSep, HStack, Italic, Mono, Txt, VStack } from '~src/components'
import { formatUnits } from '~src/utils/messages'
import { SystemStatusSubArea } from './loaderSchedArea'
import { SystemStatusIndicator } from './systemStatusIndicator'
import { useSystemStatus } from './systemStatusProvider'

type ScanStatusAreaProps = {}

export function ScanStatusArea(props: ScanStatusAreaProps) {
  const { qResult } = useSystemStatus()
  if (!qResult.isSuccess) {
    return null
  }
  const { fetchedAt, scanbatchStats } = qResult.data
  const { hasBatches, latest, recent } = scanbatchStats

  const fmtMin = (min: number) =>
    min == null ? ENDASH : `${Math.ceil(min)} min`

  const batchInfo = !hasBatches ? (
    <Italic data-cy="no-batches">No batches</Italic>
  ) : (
    <>
      <VStack alignItems="start" gap={0.5}>
        <Txt>
          {capitalize(
            formatDistance(latest.settled, fetchedAt, {
              addSuffix: true,
              includeSeconds: true,
            })
          )}
        </Txt>
      </VStack>
      <HSep />
      <Txt>
        <Mono fontSize="sm">{recent.countLabel}</Mono>{' '}
        {recent.countLabel === '1' ? 'batch' : 'batches'} in the last{' '}
        {recent.intervalMinutes} min.
      </Txt>
      <Grid
        alignItems="center"
        alignSelf="start"
        columnGap={4}
        gridTemplateColumns="auto auto"
        rowGap={1}
      >
        <ScanDelayTooltip>
          <HStack gap={1}>
            <Txt>Scanner delay</Txt>
            <SystemStatusIndicator ifNormal="info" type="scans" />
          </HStack>
        </ScanDelayTooltip>
        <Mono>{fmtMin(recent.delayMedian)}</Mono>
        <Txt>Paper processing</Txt>
        <Mono>{fmtMin(recent.procMedian)}</Mono>
      </Grid>
    </>
  )

  return (
    <SystemStatusSubArea title={<>Scans last processed</>}>
      {batchInfo}
    </SystemStatusSubArea>
  )
}

type ScanDelayTooltipProps = { children: ReactNode }

function ScanDelayTooltip(props: ScanDelayTooltipProps) {
  const { children } = props
  // todo: should we define high volume? ~25,000 scans per hour
  const tooltip = (
    <VStack alignItems="start" gap={3} p={2}>
      <Txt>
        The scanners have a delay between when you scan the batch, and when the
        batch reaches Paper.
      </Txt>
      <VStack alignItems="start" gap={0.5}>
        <Txt>During normal volume, this time is roughly:</Txt>
        <Mono>1 minute per 100 scans in the batch</Mono>
      </VStack>
      <Txt>
        During high volume, the scanner backend can get backed up, and the delay
        can be significantly longer.
      </Txt>
      <Txt>
        The value to the right is the average delay for recent batches. If it's
        high, that's likely why you're not seeing your batch yet - Paper hasn't
        seen it yet either!
      </Txt>
      <Txt>
        Once received, Paper's processing to read the QR codes typically takes a
        few minutes per batch.
      </Txt>
    </VStack>
  )

  return (
    <Tooltip label={tooltip} maxWidth="385px" placement="bottom-start">
      {children}
    </Tooltip>
  )
}
