import { IconButton } from '@chakra-ui/react'
import { IcoAdd } from '@paper/icons'
import { useRouter } from '@paper/route'
import { C2Status, Curriculum } from '@paper/schema'
import { getC2NameAndId, makeC2 } from '@paper/utils'
import { useFormikContext } from 'formik'
import { ReactNode } from 'react'
import { FormDialog, PublicFormDialogProps } from '~src/blocks/formDialog'
import { ToggleGroup, Txt } from '~src/components'
import { RD_Setup_Curriculum } from '~src/routelist'
import {
  CurriculumForm,
  VariantList,
  VariantListStatus,
} from '../curriculumForm'
import { C2FamilyEd, useCurriculumSubmitter } from '../data-curriculumSetup'
import { statusToColor, VariantActionButtons } from '../variantStatus'
import { useValidationSchema } from './validation'

/**
 * Dialog for managing existing curricula
 */
export function CurriculumCreateDialog(
  props: PublicFormDialogProps<C2FamilyEd>
) {
  const closeDialog = props.onClose
  const submitter = useCurriculumSubmitter()
  const validationSchema = useValidationSchema('create')
  const { routeData } = useRouter<RD_Setup_Curriculum>()

  const data: Omit<C2FamilyEd, 'id' | 'statuses' | 'syId'> = {
    ed: routeData.ed ?? '',
    family: '',
    curricula: [],
    levels: [],
    subject: '',
  }

  return (
    <FormDialog
      {...props}
      data={data}
      onSubmit={async (values) => {
        let { curricula, ...common } = values
        let data = curricula
          .filter((p) => p._status)
          .map(({ _status, variant }): Curriculum => {
            return makeC2({ ...common, variant, _status })
          })
        await submitter.mutateAsync(data)
        closeDialog()
      }}
      validationSchema={validationSchema}
    >
      <CurriculumForm
        title="Create curricula"
        variantList={<VariantListCreate />}
      />
    </FormDialog>
  )
}

/** Variant list for the manage dialog */
function VariantListCreate() {
  const { getFieldMeta, isValid, setFieldValue, values } =
    useFormikContext<C2FamilyEd>()

  const fieldMeta = getFieldMeta('curricula')
  const vlError = fieldMeta.error ? `Required (at least one)` : null

  return (
    <VariantList
      curricula={values.curricula}
      directions={{
        new: <Txt>Select variants{<VariantListStatus error={vlError} />}</Txt>,
      }}
      renderItem={({ curriculum, idx, variant }) => (
        <>
          <VariantActionButtons
            buttons={getManageButtons({
              onAdd: () => {
                const c2 = { _status: 'active', variant: variant.name }
                // todo: someone has written this logic!
                setFieldValue(`curricula.${values.curricula.length}`, c2)
              },
              id: curriculum?.id,
              status: curriculum?._status,
            })}
            onChange={(action) => {
              setFieldValue(`curricula.${idx}._status`, action)
            }}
            preventNone={!!curriculum?.id}
            selectedValue={curriculum?._status}
            variant={variant.name}
          />
          {isValid &&
            curriculum?._status &&
            getC2NameAndId(values, variant.name).name}
        </>
      )}
    />
  )
}

type GetManageButtonProps = { id: string; onAdd(): void; status: C2Status }
/**
 * Returns buttons for for a variant row
 */
export function getManageButtons(props: GetManageButtonProps) {
  const { id, onAdd, status } = props

  let buttons: ReactNode
  // options to toggle status
  if (status) {
    const options: C2Status[] = id
      ? ['active', 'retired', 'recalled']
      : ['active']
    buttons = options.map((opt) => (
      <ToggleGroup.Button
        colorScheme={statusToColor(opt)}
        key={opt}
        textTransform="capitalize"
        value={opt}
      >
        {opt}
      </ToggleGroup.Button>
    ))
  }
  // action to add variant
  else {
    buttons = (
      <IconButton
        aria-label="Add variant"
        colorScheme="blueJeans"
        cursor="pointer"
        data-cy="c2-add-variant"
        icon={<IcoAdd />}
        onClick={() => onAdd()}
      />
    )
  }

  return buttons
}
