import {
  Button,
  ButtonGroup,
  ButtonProps,
  Grid,
  IconButton,
} from '@chakra-ui/react'
import {
  IcoChevronDown,
  IcoChevronLeft,
  IcoChevronRight,
  IcoChevronUp,
  IcoSkipUp,
} from '@paper/icons'
import { useRouter } from '@paper/route'
import { Ex, Txt, VStack } from '~src/components'
import type { RD_SW_Scanlog } from '~src/routelist'
import { getScanlogNow, useScanlogContext } from './scanlogAirlock'

export function ScanPrevNext() {
  const { dispatchStay, routeData } = useRouter<RD_SW_Scanlog>()
  const { sbDigest, jumpStatus, seekEarlier, seekLater } = useScanlogContext()
  const { problems } = sbDigest.success.otherData

  const btnProps: Pick<ButtonProps, 'colorScheme' | 'size' | 'variant'> = {
    size: 'xs',
  }

  const sameWidthBtn = '72px'

  // paging doesn't make sense in packet/teacher mode
  // todo: probably formalize the modes in <ScanlogAirlock />
  const paging = !routeData.packetId && !routeData.teacherId && (
    <VStack gap={1}>
      <Txt fontSize="xs">More batches</Txt>
      <VStack alignItems="end" gap={1}>
        <ButtonGroup {...btnProps} isAttached={true}>
          <IconButton
            aria-label="Latest"
            icon={<IcoSkipUp style={{ marginTop: '-3.55px' }} />}
            isDisabled={!routeData.scanDate}
            onClick={() => dispatchStay({ scanDate: getScanlogNow() })}
          >
            Latest
          </IconButton>
          <Button
            leftIcon={<IcoChevronUp />}
            onClick={seekLater}
            width={sameWidthBtn}
          >
            <Txt as="span" flexGrow={1}>
              Later
            </Txt>
          </Button>
        </ButtonGroup>
        <Button
          {...btnProps}
          leftIcon={<IcoChevronDown />}
          onClick={seekEarlier}
          width={sameWidthBtn}
        >
          <Txt as="span" flexGrow={1}>
            Earlier
          </Txt>
        </Button>
      </VStack>
    </VStack>
  )

  const jumping = (
    <VStack gap={1}>
      <Txt fontSize="xs">
        Jump to <Ex py="1px">{jumpStatus}</Ex> scans
      </Txt>
      <ButtonGroup {...btnProps}>
        <Button
          isDisabled={!problems.hasPrev}
          leftIcon={<IcoChevronLeft />}
          onClick={problems.onPrev}
        >
          Prev
        </Button>
        <Button
          isDisabled={!problems.hasNext}
          leftIcon={<IcoChevronRight />}
          onClick={problems.onNext}
        >
          Next
        </Button>
      </ButtonGroup>
    </VStack>
  )

  return (
    <Grid
      gridAutoColumns="1fr"
      gridAutoFlow="column"
      gridTemplateRows="auto"
      mb={2}
    >
      {jumping}
      {paging}
    </Grid>
  )
}
