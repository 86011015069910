import * as Yup from 'yup'
import type { PacketmetaC2 } from '../types/packetmeta'

const yupIlluminate = Yup.string().trim().nullable()
const yupUrl = Yup.string().url('Must be a valid URL').nullable()

// NOTE: Formik/Yup validation doesn't seem to work with an array at the root
export type CrossPacketFormData = { packets: PacketmetaC2[] }

const yupCrossPacketArray = Yup.array(
  Yup.object({
    id: Yup.string().required(),
    _linkExternal: yupUrl,
    _illuminate: yupIlluminate,
  }).required()
)

export const yupCrossPacketSchema = Yup.object({
  packets: yupCrossPacketArray,
})
