import { Icon } from '@chakra-ui/react'
import { IcoExclamationTriangle } from '@paper/icons'
import { Txt } from '~src/components'

export function ListError({ children = undefined }) {
  return (
    <Txt
      as="span"
      alignItems="center"
      display="flex"
      flexDir="column"
      flexGrow={1}
      fontSize="md"
      px={2}
      py={4}
    >
      <Icon as={IcoExclamationTriangle} color="red.500" mb={2} />
      {children ?? <Txt as="span">An error occurred</Txt>}
    </Txt>
  )
}
