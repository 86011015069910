import { LoginData } from '@paper/schema'
import { defineApi } from '../defineApi'

export const auth_checktoken = defineApi<{
  body: undefined
  method: 'post'
  result: LoginData
}>({
  invalidateKey: 'login',
  method: 'post',
  urlFactory: `checktoken`,
})

export const auth_googletoken = defineApi<{
  method: 'post'
  body: { code: string }
  result: LoginData
}>({
  method: 'post',
  urlFactory: `googletoken`,
})

export const auth_inttoken = defineApi<{
  method: 'get'
  searchParams: { as?: 'admin' | 'internal' | 'teacher' }
  result: LoginData
}>({
  method: 'get',
  urlFactory: `inttoken`,
})
