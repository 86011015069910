import { PureComponent, ReactNode } from 'react'
import { Error404Page, NotFoundError } from '~src/blocks/errors'
import { UncaughtErrorPage } from '~src/blocks/errors/errorPageUncaught'
import rollbar from '~src/utils/rollbar'

// https://reactjs.org/docs/error-boundaries.html
export class ErrorBoundaryUncaught extends PureComponent<{
  children: ReactNode
}> {
  state = {
    hasError: false,
    error: undefined,
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    return { hasError: true, error }
  }

  componentDidUpdate(previousProps, previousState) {
    if (previousProps.children !== this.props.children)
      this.setState({ hasError: false })
  }

  componentDidCatch(error, info) {
    if (error instanceof NotFoundError) {
      // no-op
    } else if (process.env.NODE_ENV === 'production') {
      console.error(error)
      rollbar.error(error)
    } else {
      // note: In dev, HMR spits me out here a lot...
      // Various React contexts lose their value, so I get e.g.
      const hmrErrorPrefix = `TypeError: Cannot destructure property`
      const offenders = [
        // 'useColSortAirlock',
        'useCurriculumContext',
        // 'useListContext',
        // 'usePacketContext',
        // 'usePublishContext',
        // 'usePdf',
        // 'useQStdContext',
        'useScanlogContext',
        'useSchoolYearContext',
        // 'useStepContext',
        // 'useSWContext',
        'useTeacherContext',
        // 'useTeacherTimeContext',
        'useUser',
        // 'useWizContext',
      ]
      const errorStr: string = error.toString()
      // Not sure how to solve the root problem, so I'll just reload :)
      // The obvious problem is that if it's not HMR, it will reload forever. (This is only dev)
      if (
        errorStr.startsWith(hmrErrorPrefix) &&
        offenders.some((off) => errorStr.includes(off))
      ) {
        window.location.reload()
      }
    }
  }

  render() {
    const { error, hasError } = this.state

    if (hasError) {
      // todo: messy...maybe separate catchers?
      return error instanceof NotFoundError ? (
        <Error404Page error={error} />
      ) : (
        <UncaughtErrorPage error={error} />
      )
    }

    return this.props.children
  }
}
