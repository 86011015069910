import { StackProps, VStack } from '~src/components'
import { MenuBody } from './menuComponents'
import { SimpleMenu, SimpleMenuProps } from './useMenu'

type SimplePopupProps = SimpleMenuProps & { stackProps?: StackProps }

const menuZIndex = 1799 // todo: needs to render on top of dialog, but below tooltip

/**
 * todo: not exactly sure how much of this will be reuseable
 */
export function SimplePopup(props: SimplePopupProps) {
  let { children, stackProps = {}, ...simpleMenuProps } = props
  return (
    <SimpleMenu caret={false} collisionPadding={50} {...simpleMenuProps}>
      <MenuBody overflow="hidden" p={4} zIndex={menuZIndex}>
        <VStack
          alignItems="start"
          display="flex"
          flexDirection="column"
          flexWrap="wrap"
          gap={1}
          height="100%"
          overflow="auto"
          {...stackProps}
        >
          {children}
        </VStack>
      </MenuBody>
    </SimpleMenu>
  )
}
