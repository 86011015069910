import { extendTheme, Theme, theme } from '@chakra-ui/react'

export const DEFAULT_FG = 'rgba(0,0,0,.64)'
export const mainFont = "'Helvetica', Calibri, sans-serif"
export const monoFont = 'Inconsolata, monospace'

// https://v1.chakra-ui.com/docs/styled-system/theming/advanced#theme-typings
interface StyleOptions {
  theme: Theme
  colorMode: 'light' | 'dark'
  colorScheme: string
}

const ifIsTooLight = (style: any) => (props: StyleOptions) =>
  props.colorMode === 'light' && props.colorScheme === 'manualYellow'
    ? style
    : undefined

const colors = {
  pureGray: {
    '50': '#fafafa',
    '100': '#ededed',
    '600': '#4c4c4c',
    '700': '#404040',
    '800': '#333333',
  },
  grayBtn: theme.colors.gray, // circumvent chakra's special handling around gray buttons
  // purple from the react charts library
  purple: {
    '50': '#f1f0fa',
    '100': '#dbdaf3',
    '200': '#c4c2ec',
    '300': '#aca9e4',
    '400': '#9a96de',
    '500': '#8884d8',
    '600': '#807cd4',
    '700': '#7571ce',
    '800': '#6b67c8',
    '900': '#5854bf',
  },
  blueJeans: {
    50: '#f7fbfe',
    100: '#d9ecfa',
    200: '#bbdef7',
    300: '#9ecff3',
    400: '#80c1f0',
    500: '#63B3ED',
    600: '#4f8fbd',
    700: '#3b6b8e',
    800: '#27475e',
    900: '#13232f',
  },
  manualYellow: {
    50: '#fffdf7',
    100: '#fff8dd',
    200: '#fff2c2',
    300: '#ffeda7',
    400: '#ffe78c',
    500: '#ffe272',
    600: '#ccb45b',
    700: '#998744',
    800: '#665a2d',
    900: '#322d16',
  },
  passageBlue: {
    50: '#e7f5f9',
    100: '#c2e6f1',
    200: '#9ad5e7',
    300: '#71c4dd',
    400: '#52b8d6',
    500: '#34abcf',
    600: '#2fa4ca',
    700: '#279ac3',
    800: '#2191bd',
    900: '#1580b2',
  },
  // https://github.com/chakra-ui/chakra-ui/blob/v0/packages/chakra-ui/src/theme/colors.js
  scanMissingRed: {
    50: '#fff5f5',
    100: '#fff5f5',
    200: '#fed7d7',
    300: '#feb2b2',
    400: '#fc8181',
    500: '#f56565',
    600: '#e53e3e',
    700: '#c53030',
    800: '#9b2c2c',
    900: '#822727',
  },
  scanPresentGray: {
    50: '#F7FAFC',
    200: '#F7FAFC',
    300: '#EDF2F7',
    400: '#E2E8F0',
    500: '#CBD5E0',
    600: '#A0AEC0',
    700: '#718096',
    800: '#4A5568',
    900: '#2D3748',
  },
  scanFixedGray: {
    50: '#f8f8f7',
    100: '#dfdddc',
    200: '#c6c2c1',
    300: '#ada8a5',
    400: '#948d8a',
    500: '#7C736F',
    600: '#635c58',
    700: '#4a4542',
    800: '#312e2c',
    900: '#181616',
  },
}

export const chakraTheme = extendTheme({
  components: {
    Button: {
      baseStyle: { fontWeight: 300 },
      variants: {
        outline: ifIsTooLight({
          borderColor: colors.manualYellow[500],
          color: colors.manualYellow[800],
          _hover: {
            bg: colors.manualYellow[100],
          },
        }),
        solid: ifIsTooLight({ color: DEFAULT_FG }),
      },
    },
    Table: {
      sizes: {
        sm: {
          th: { py: '.5rem' },
          td: { py: '.1875rem' },
        },
      },
    },
    // todo: this isn't working?
    // Tag: {
    //   variants: {
    //     solid: ifIsTooLight({
    //       bg: 'red.500',
    //       color: `${DEFAULT_FG}!important`,
    //       '&nonsense': 'abc',
    //     }),
    //   },
    // },
    Modal: {
      sizes: {
        xl: {
          dialog: { maxWidth: '80vw' },
        },
      },
    },
    Drawer: {
      sizes: {
        xl: {
          dialog: { maxWidth: '960px' },
        },
      },
    },
    Menu: {
      baseStyle: {
        item: {
          borderLeftColor: 'transparent',
          borderLeftStyle: 'solid',
          borderLeftWidth: '5px',
          '>.chakra-menu__icon-wrapper': {
            display: 'flex',
          },
        },
      },
    },
  },
  fonts: {
    heading: mainFont,
    body: mainFont,
    mono: monoFont,
  },
  colors,
})
