import { BoxProps, Icon } from '@chakra-ui/react'
import { IcoCalendar } from '@paper/icons'
import { formatDate } from '@paper/utils/date'
import { ReactNode } from 'react'
import { Hl, HStack, Txt, VSep, VStack } from '~src/components'
import { LoaderSchedGrid } from './loaderSchedGrid'
import { useSystemStatus } from './systemStatusProvider'

type LoaderSchedAreaProps = {}

export function LoaderSchedArea(props: LoaderSchedAreaProps) {
  const { qResult } = useSystemStatus()

  const doDate = (d: string) => d && formatDate(new Date(d), 'PP')

  // schedule stuff
  // todo: may need to populate with defaults to prevent jumping...
  const data = qResult.data?.load
  const loadYear = data.loadYear
  const yearCode = loadYear?.code
  const loadFrom = doDate(loadYear?.firstDay)
  const loadTo = doDate(loadYear?.lastDay)

  const title = (
    <HStack gap={2}>
      <HStack as="span" fontSize="sm" gap={2}>
        {yearCode && <Hl>{yearCode}</Hl>} External Data
      </HStack>
      {yearCode && (
        <HStack fontSize="xs" gap={1.5}>
          <VSep boxProps={{ my: 1 }} />
          <Txt>Loaded daily</Txt>
          <Icon aria-hidden="true" as={IcoCalendar} />
          <Hl>{loadFrom}</Hl> to <Hl>{loadTo}</Hl>
        </HStack>
      )}
    </HStack>
  )

  return (
    <SystemStatusSubArea title={title}>
      <LoaderSchedGrid />
    </SystemStatusSubArea>
  )
}

type SystemStatusSubAreaProps = {
  children: ReactNode
  containerProps?: BoxProps
  title: ReactNode
}

export function SystemStatusSubArea(props: SystemStatusSubAreaProps) {
  const { children, containerProps = {}, title } = props
  return (
    <VStack
      alignItems="stretch"
      as="section"
      flexShrink={0}
      fontSize="xs"
      gap={2}
      position="relative"
      {...containerProps}
    >
      <HStack fontSize="sm" height="27px">
        {title}
      </HStack>
      {children}
    </VStack>
  )
}
