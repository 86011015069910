import { yupPassage } from '@paper/schema/validation'
import { Formik } from 'formik'
import { produce } from 'immer'
import { nanoid } from 'nanoid/non-secure'
import { Reducer, useMemo } from 'react'
import { AnswerKey } from '~src/blocks/answerKey'
import { VSep } from '~src/components'
import { Wiz } from '../publishLayout'
import type { ContentFormSchema } from './entryBaseData'
import {
  FormListState,
  ListAction,
  useFormActionFactory,
  useFormListValue,
} from './formListValueHelpers'
import {
  SinglePassageForm,
  SinglePassageSchema,
  yupSinglePassage,
} from './formSinglePassage'

const getEmptyPassage = (): SinglePassageSchema => {
  return {
    _new: true,
    _questionIds: [],
    id: nanoid(11),
    name: '',
    genre: '',
    lexile: '',
  }
}

type State = FormListState<SinglePassageSchema, ContentFormSchema>

const reducer: Reducer<State, ListAction<SinglePassageSchema>> = (
  state,
  action
) => {
  const { item } = action

  return produce(state, (draft) => {
    const { selected, values } = draft

    // Helper to update `passages` and their `questions` references
    const save = (item: SinglePassageSchema) => {
      const { _questionIds } = item
      // Strip to passage
      const passage = yupPassage.validateSync(item, { stripUnknown: true })
      // Update `passages`
      if (action.type === 'add') {
        values.passages.push(passage)
      } else if (action.type === 'save') {
        // Save edited passage
        const editedIndex = values.passages.findIndex((p) => p.id === item.id)
        values.passages.splice(editedIndex, 1, passage)
      }

      // Update Qs
      const qIdToBeSelectedSet = new Set(_questionIds.map((q) => q.id))
      values.questions.forEach((q) => {
        let passageIdIdx = q.passageIds.indexOf(passage.id)
        if (qIdToBeSelectedSet.has(q.id) && passageIdIdx === -1) {
          // add
          q.passageIds.push(passage.id)
        } else if (!qIdToBeSelectedSet.has(q.id) && passageIdIdx >= 0) {
          // remove
          q.passageIds.splice(passageIdIdx, 1)
        }
      })
    }

    switch (action.type) {
      case 'add':
      case 'save':
        save(item) // save
        draft.selected = getEmptyPassage() // reset form
        break
      case 'delete':
        values.passages = values.passages.filter((p) => p.id !== item.id)
        // If deleting selected, then reset it
        if (selected?.id === action.item.id) {
          draft.selected = getEmptyPassage()
        }
        // Remove passage from any questions
        values.questions.forEach((q) => {
          q.passageIds = q.passageIds.filter((p) => p !== action.item.id)
        })
        // Remove passage from any pages
        values.pages.forEach(
          (page) =>
            (page.items = page.items.filter((p) => p.id !== action.item.id))
        )
        break
      case 'selectToEdit':
        draft.selected = item
        break
      case 'unselect':
        draft.selected = getEmptyPassage()
        break
    }
  })
}

type FormPassagesProps = {}

export function FormPassages(props: FormPassagesProps) {
  const { dispatch, errors, lastAdded, selected, values } = useFormListValue(
    reducer,
    getEmptyPassage
  )
  const actionFactory = useFormActionFactory(dispatch, 'passage')
  const { passages, questions } = values

  // "chunks" without questions - can't use useChunked b/c all passages would be "unused"
  const chunks = useMemo(() => {
    return (
      passages?.map((passage, idx) => ({
        fingerprint: passage.id,
        passages: [{ ...passage, _isInvalid: !!errors.passages?.[idx] }],
        questions: [],
      })) ?? []
    )
  }, [passages, errors.passages])

  const initialValues: SinglePassageSchema = {
    ...selected,
    _questionIds:
      questions // todo: maybe memoize...
        .filter((q) => q.passageIds.includes(selected.id))
        .map((q) => ({ id: q.id, type: 'question' })) ?? [],
  }

  return (
    <Wiz.FormArea
      alignItems="stretch"
      display="grid"
      gridColumnGap={12}
      gridTemplateColumns="minmax(280px, max-content) auto 1fr"
      justifyContent="stretch"
      overflowX="auto"
    >
      <AnswerKey.Container minWidth="280px">
        <AnswerKey.Guts
          actionFactory={actionFactory}
          chunks={chunks}
          highlightedId={selected.id}
          scrolledToId={lastAdded?.id}
        />
      </AnswerKey.Container>
      <VSep />
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={({ _new, ...values }) => {
          dispatch({ type: _new ? 'add' : 'save', item: values })
        }}
        validateOnMount={true}
        validationSchema={yupSinglePassage}
      >
        <SinglePassageForm onUnselect={() => dispatch({ type: 'unselect' })} />
      </Formik>
    </Wiz.FormArea>
  )
}
