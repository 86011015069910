import { SectionAxisItem, XpacketSW } from '@paper/schema'
import { defineApi } from '../defineApi'

export const xpackets_list = defineApi<{
  method: 'post'
  body: {
    curriculumId: string
    excludeMissing?: boolean
    packetIds: string[]
    teacherId: string
  }
  // this may need to change if we add non-teacher-centric views
  result: { sections: SectionAxisItem[]; xpackets: XpacketSW[] }
}>({
  method: 'post',
  invalidateKey: 'xpacket',
  urlFactory: `dir/xpackets`,
})

export const xpackets_list_student = defineApi<{
  method: 'post'
  body: { packetIds: string[]; studentId: string }
  result: XpacketSW[]
}>({
  method: 'post',
  invalidateKey: 'xpacket',
  urlFactory: `dir/xpackets/student`,
})
