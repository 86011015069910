import type {
  Curriculum,
  DirPacket,
  School,
  Teacher,
  Transit,
  XpacketSW,
} from '@paper/schema'
import { defineApi } from '../defineApi'

export const dir_list = defineApi<{
  method: 'get'
  searchParams: { syId: number }
  result: {
    canEdit: boolean
    csts: Transit.CST[]
    curricula: Curriculum[]
    schools: School[]
    teachers: Teacher[]
  }
}>({
  invalidateKey: 'dir_cst',
  method: 'get',
  urlFactory: `dir`,
})

export const dir_list_packets = defineApi<{
  method: 'post'
  body: { curriculumId: string; teacherId?: string }
  result: DirPacket[]
}>({
  invalidateKey: 'xpacket',
  method: 'post',
  urlFactory: `dir/packets`,
})

export const dir_list_packets_blanks = defineApi<{
  method: 'get'
  searchParams: { packetId: string }
  result: { packet: DirPacket; xpackets: XpacketSW[] }
}>({
  method: 'get',
  urlFactory: `blanks`,
})
