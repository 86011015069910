import { UseQueryResult } from '@tanstack/react-query'
import { ListDigest } from '~src/blocks/list'

/**
 * Airlocks and such shouldn't run until we have data
 *
 * @deprecated I think this may be wrong,
 * * Or at least `isPending` is complicated because that's also the state for disabled.
 * * If the query is disabled, then it likely means we're good to airlock?
 * * Meaning maybe this should just be `!qResult.isFetching`?
 */
export const isRQReady = (qResult: UseQueryResult) =>
  !qResult.isPending && !qResult.isFetching

/**
 * Checks if digest needs url change ready+selectedId,but no selected item
 */
export const checkDigestAirlock = (digest: ListDigest) => {
  return (
    digest.success &&
    !digest.qResult.isFetching &&
    digest.success.selectedId &&
    !digest.success.selectedItem
  )
}
