import { BoxProps, Button, List, ListIcon, ListItem } from '@chakra-ui/react'
import { IcoWarningCircle } from '@paper/icons'
import { ReactNode, useLayoutEffect, useState } from 'react'
import { HSep, VStack } from '~src/components'
import { LoadingDots } from '~src/components/status'
import config from '~src/utils/config'
import { usePdfBuilderContext } from './pdfBuilderProvider'
import {
  PdfPrintGenerate,
  useRegenerateCheck,
} from './printDialogGenerateButton'
import { PrintPdfHistory } from './printDialogHistory'
import { PdfPrintStats } from './printDialogPdfStats'

// avoid hammering?
const useAvoidHammering = (key: string, cooldown: number) => {
  const [isWaiting, setIsWaiting] = useState(false)
  // reset on key change
  useLayoutEffect(() => {
    let unmounted = false
    let timeout: number
    if (key) {
      setIsWaiting(true)
      clearTimeout(timeout)
      timeout = window.setTimeout(
        () => !unmounted && setIsWaiting(false),
        cooldown
      )
    }
    return () => {
      clearTimeout(timeout)
      unmounted = true
    }
  }, [key])

  return isWaiting
}

export function PdfBuilderSidePanel() {
  const { isLoading, nextPacket, selected } = usePdfBuilderContext()
  let isWaiting = useAvoidHammering(
    !isLoading && selected?.grainId, // wait for loaded before initializing hammer avoidance
    config.meta.where === 'int' || config.meta.where === 'local' ? 10 : 2700
  )

  const isWaitingProps: BoxProps = isWaiting
    ? {
        bg: 'rgba(226,202,81,.20)',
        // @ts-expect-error
        'data-cy': 'is-waiting',
      }
    : {}

  const regen = useRegenerateCheck()

  //console.log('[PdfBuilderSidePanel]', { isLoading, selected, stats })
  let body: ReactNode
  if (isLoading) {
    body = <LoadingDots />
  } else if (selected && !isLoading) {
    const inProgress = selected.status === 'in-progress'

    if (regen.needsConfirm) {
      isWaiting = false // no need for cooldown not submittable (todo: maybe refactor so these can all be at the same level)
    } else if (inProgress) {
      isWaiting = true // already in-progress
    }

    // can't submit if
    const isDisabled =
      isWaiting || // waiting for cooldown
      regen.shouldBeDisabled // unconfirmed

    body = (
      <>
        <PdfPrintStats />
        <HSep />
        <PrintPdfHistory listProps={{ spacing: 2 }} variant="school" />
        <HSep />
        <List spacing={2}>
          <ListItem>Before continuing</ListItem>
          <ListItem alignItems="center" display="flex" fontSize="sm">
            <ListIcon as={IcoWarningCircle} color="yellow.500" />
            Verify the page and packet counts match what you're expecting
          </ListItem>
          {regen.element}
        </List>
        <HSep />
        <PdfPrintGenerate
          isDisabled={isDisabled}
          isWaiting={inProgress}
          onClick={() => regen.reset()}
        />
        <Button
          alignSelf="center"
          onClick={nextPacket}
          px={4}
          py={2}
          size="sm"
          variant="ghost"
        >
          Skip
        </Button>
      </>
    )
  }

  return (
    <VStack
      alignItems="stretch"
      borderRadius="lg"
      flexShrink={0}
      gap={4}
      overflowY="auto"
      px={4}
      py={2}
      transition="background 700ms ease-out"
      width="440px"
      {...isWaitingProps}
    >
      {body}
    </VStack>
  )
}
