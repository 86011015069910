import { Button, ButtonGroup, ButtonProps } from '@chakra-ui/react'
import { IcoDownload } from '@paper/icons'
import { produce } from 'immer'
import { useState } from 'react'
import { CopyButton } from '~src/components'
import type { DownloadTableVariant } from './downloadTable'

const touchedProps = {
  colorScheme: 'pink',
  variant: 'solid',
} satisfies ButtonProps
const untouchedProps = { variant: 'outline' } satisfies ButtonProps

type DownloadButtonGroupProps = {
  partLayout: 'col' | 'row'
  partNumber?: number
  url: string
  variant: DownloadTableVariant
}

export function DownloadButtonGroup(props: DownloadButtonGroupProps) {
  const { partLayout, partNumber, url, variant } = props

  // local only
  const [touched, setTouched] = useState<boolean[]>([false, false])

  // todo: maybe validate new URL(url).searchParams.get('Expires') vs. current time
  // const onBeforeClick = () => {}

  const propSets = touched.map((isTouched, idx) => {
    return {
      onClick: () => {
        setTouched(
          produce((draft) => {
            draft[idx] = true
          })
        )
      },
      props: touched[idx] ? touchedProps : untouchedProps,
    }
  })

  const singlePartLabel = partLayout === 'col' ? 'Single part' : 'Download'

  return (
    <ButtonGroup isAttached={true} size="xs">
      <Button
        data-cy="btn-dl-pdf"
        leftIcon={<IcoDownload />}
        justifyContent="start"
        {...propSets[0].props}
        onClick={() => {
          window.open(url, '_blank')
          propSets[0].onClick()
        }}
      >
        {partNumber ? `Part ${partNumber}` : singlePartLabel}
      </Button>
      {variant === 'loggedin' && (
        <CopyButton
          aria-label="Copy url to clipboard"
          data-cy="btn-copy"
          isRound={false}
          onCopyDone={() => {
            propSets[1].onClick()
          }}
          value={url}
          {...propSets[1].props}
        />
      )}
    </ButtonGroup>
  )
}

DownloadButtonGroup.touchedProps = touchedProps
