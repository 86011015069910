import { Box } from '@chakra-ui/react'
import { PacketContent, Passage, Question } from '@paper/schema'
import { ReactNode, useMemo } from 'react'

/** With import, its now (corner-case) possible for a passage to become invalid, so hacking in a way to show this */
export type PassageWithStatus = Passage & { _isInvalid?: boolean }

export type PassageChunk = {
  /* How we chunk Qs in the world of multiple passages  */
  fingerprint: string
  passages: PassageWithStatus[]
  questions?: Question[]
}
export type ChunkClearance = 'none' | 'some' | 'lots'

type ChunkProps = { children: ReactNode; clearance?: ChunkClearance }
export function Chunk(props: ChunkProps) {
  const { children, clearance } = props
  return (
    <Box
      alignItems="flex-start"
      className="chunk"
      display="flex"
      flexDir="column"
      marginX="5px"
      marginY={clearance === 'lots' ? 3 : clearance === 'some' ? 2 : undefined}
      maxH="100%"
      minW="125px"
      overflowY="auto"
    >
      {children}
    </Box>
  )
}

export const useUnpagedIds = (
  props: Pick<PacketContent, 'pages' | 'passages' | 'questions'>
) => {
  const { pages, passages, questions } = props
  return useMemo(() => {
    // Start will all ids
    const unpagedIds = new Set(
      [...passages, ...questions].map((item) => item.id)
    )
    // Go through pages, removing ids
    pages.forEach(({ items }) =>
      items.forEach((item) => unpagedIds.delete(item.id))
    )

    return unpagedIds
  }, [pages, passages, questions])
}

type ChunkQProps = {
  maxChunkSize?: number
  passages: Passage[]
  questions: Question[]
}

export const useChunked = (props: ChunkQProps) => {
  const { passages, questions, maxChunkSize } = props
  return useMemo(() => {
    return chunkQs({ passages, questions, maxChunkSize })
  }, [passages, questions, maxChunkSize])
}

/**
 *
 * @param param0 questions should be sorted!
 */
const chunkQs = ({ maxChunkSize = 25, passages, questions }: ChunkQProps) => {
  // Dangle passages for convenient lookup
  const passageMap = new Map(passages.map((p) => [p.id, p]))
  const unusedPassageSet = new Set(passages)

  // result
  const chunks: PassageChunk[] = []
  let chunk: PassageChunk

  for (let q of questions) {
    const passages = q.passageIds.map((id) => passageMap.get(id))
    const fingerprint = q.passageIds.join('') || undefined

    // new chunk if
    if (
      !chunk || // first chunk
      fingerprint !== chunk.fingerprint || // passage changed
      chunk.questions.length >= maxChunkSize // max chunk size
    ) {
      // Remove passages from unused
      passages.forEach((psg) => unusedPassageSet.delete(psg))
      // Create chunk
      chunk = { fingerprint, passages, questions: [] }
      // Push chunk
      chunks.push(chunk)
    }
    // Add question to chunk
    chunk.questions.push(q)
  }

  const unusedPassages = Array.from(unusedPassageSet)
  return { chunks, unusedPassages }
}
