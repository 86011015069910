import { BodyOf } from '@paper/api-specs'
import { useRouter } from '@paper/route'
import {
  DirPacket,
  FixitChange,
  FixitSheet,
  FixitSheetRow,
  FixitSlot,
  Student,
} from '@paper/schema'
import { Vector2 } from '@use-gesture/react'
import { produce } from 'immer'
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { useSWContext } from '~src/blocks/swContext'
import { FullPageLoading } from '~src/components/status'
import { RD_SW_Fixit } from '~src/routelist'
import { PanEntangler, usePanEntangler } from '~src/utils/usePanEntangler'
import { OnSlotAction, useFixitData } from './data-fixit'
import { FixitRoster } from './data-fixit-sectioned'

export type OnSelectSlot = (item: FixitSlot) => void
type OnSelectStudent = (studentId: string) => void
type GetPayload = () => BodyOf<'fixit.update'>

type FixitContext = {
  batchCount: number
  dirty: boolean
  getPayload: GetPayload
  imagePosition: {
    origin: Vector2
    panEntangler: PanEntangler
  }
  onSlotAction: OnSlotAction
  onSelectSlot: OnSelectSlot
  onSelectStudent: OnSelectStudent
  /** packetId lookup result */
  packet: DirPacket
  /** optimistic packetId */
  packetId: string
  reviewStats: { num: number; denom: number }
  rows: FixitSheetRow[]
  sectioned: FixitRoster[]
  selectedSheet: FixitSheet
  selectedSlot: FixitSlot
  sheets: FixitSheet[]
  slots: FixitSlot[]
  unassignedStudentMap: Map<string, Student>
  unassignedStudents: Student[]
}

const FixitContext = createContext<FixitContext>(null)
export const useFixitContext = () => useContext(FixitContext)

type FixitProviderProps = { children: ReactNode }

export function FixitProvider(props: FixitProviderProps) {
  const { children } = props

  const { dispatchStay, routeData } = useRouter<RD_SW_Fixit>()
  const { packetId } = routeData // optimistic packetId
  const { packet } = useSWContext() // looked-up packet

  const {
    baseData,
    digested,
    onSlotAction,
    onSelectSlot,
    qResult,
    selectedSheet,
    selectedSlot,
  } = useFixitData()

  const { rows, sheets, slots } = baseData.crunched

  const {
    batchCount,
    dirty,
    sectioned,
    stuToScanMap,
    unassignedStudentMap,
    unassignedStudents,
  } = digested

  const onSelectStudent = useCallback<OnSelectStudent>(
    (studentId) => {
      const sheet = studentId && stuToScanMap.get(studentId)
      // select an editable slot
      // todo: is useCallbacking on every slots change expensive?
      // todo: should maybe precalculate whether there's an editable slot?
      const si_imageId = sheet?.sheetId || null
      const slot = si_imageId
        ? slots.find((p) => p._editable && p.sheetId === sheet.sheetId)?.type
        : null
      dispatchStay({ si_imageId, slot })
    },
    [slots, stuToScanMap]
  )

  const panEntangler = usePanEntangler('cover')

  // todo:
  // select first item on new load
  useEffect(() => {
    if (baseData._original && !selectedSlot) {
      let selectee = baseData.crunched.slots.find(
        (p) => p._editable && p._review
      )
      if (selectee) {
        onSelectSlot(selectee)
      }
    }
  }, [baseData?._original])

  const reviewStats = useMemo(() => {
    let reviewSubset = sheets.filter((p) => p._review)
    let num =
      selectedSheet?.id &&
      reviewSubset.findIndex((p) => p.id === selectedSheet.id) + 1
    return {
      num,
      denom: reviewSubset.length,
    }
  }, [sheets, selectedSheet?.id])

  const getPayload = useCallback<GetPayload>(() => {
    return sheets
      .filter((p) => p._dirty)
      .flatMap((sheet) => {
        return sheet.scans.map((si, siIdx) => {
          // tweak for reverse
          let actionSet = sheet._actionSet

          // todo: need to distinguish between dirty/not dirty infer blank
          if (si.status === 'infer-blank') {
            return null
          }

          actionSet = produce(actionSet, (draft) => {
            if (draft.packet && typeof draft.packet !== 'string') {
              const pageIndex = draft.packet.pageIndices[siIdx]
              if (pageIndex === null) {
                draft.packet = 'infer-blank'
                delete draft.student
              } else {
                draft.packet.pageIndex = pageIndex
              }

              if (pageIndex !== 0) {
                delete draft.score
                delete draft.rubric
              }
            }
          })

          let result: FixitChange = {
            actionSet,
            batchId: sheet.batchId,
            scanImageId: si.id,
          }
          return result
        })
      })
      .filter((p) => p)
  }, [sheets])

  const ctx: FixitContext = {
    batchCount,
    dirty,
    getPayload,
    imagePosition: { origin: [0, 0], panEntangler },
    onSlotAction,
    onSelectSlot,
    onSelectStudent,
    packet,
    packetId,
    reviewStats,
    rows,
    sectioned,
    selectedSheet,
    selectedSlot,
    sheets,
    slots,
    unassignedStudentMap,
    unassignedStudents,
  }

  return (
    <FixitContext.Provider value={ctx}>
      <FullPageLoading qResult={qResult}>{children}</FullPageLoading>
    </FixitContext.Provider>
  )
}
