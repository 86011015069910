import {
  Icon,
  IconButton,
  IconButtonProps,
  IconProps,
  Tooltip,
  TooltipProps,
} from '@chakra-ui/react'
import type { IconType } from '@paper/icons'

/** todo: not sure what happened here, but IconProps seems to be missing `as`? */
type IconPropsFix = IconProps & { as?: IconType }
type WithTooltip<T> = T & { tooltipProps?: Omit<TooltipProps, 'children'> }
export type TooltippedIconProps = WithTooltip<IconPropsFix>

/**
 * IconButton that gets its tooltip from `aria-label`
 * note: Need to verify this is the right way to do it!
 */
export function TooltippedIconButton(props: WithTooltip<IconButtonProps>) {
  const { tooltipProps = {}, ...iconBtnProps } = props
  return (
    // default to the IconButton label
    <Tooltip label={iconBtnProps['aria-label']} {...tooltipProps}>
      <IconButton {...iconBtnProps} />
    </Tooltip>
  )
}

/**
 * Icon that gets its tooltip from `aria-label`
 */
export function TooltippedIcon(props: TooltippedIconProps) {
  const { tooltipProps = {}, ...iconProps } = props
  return (
    <Tooltip label={props['aria-label']} {...tooltipProps}>
      <Icon {...iconProps} />
    </Tooltip>
  )
}
