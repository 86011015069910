import { StdDesc, FullStd } from '@paper/schema'
import { XOR } from '@paper/utils'
import { defineApi } from '../defineApi'

export const std_list = defineApi<{
  method: 'post'
  body: XOR<{ ids: string[] }, { bucket: FullStd['bucket']; level: string }>
  result: StdDesc[]
}>({
  method: 'post',
  urlFactory: `std`,
})
