import { SVGProps } from 'react'

type SVGGridUtilsCtx = {
  cellHeight: number
  cellWidth: number
  colCount: number
  paddingBottom: number
  paddingLeft: number
  paddingRight: number
  paddingTop: number
  rowCount: number
}

export class SVGGridUtils {
  ctx: SVGGridUtilsCtx
  height: number
  width: number

  constructor(ctx: SVGGridUtilsCtx) {
    this.ctx = ctx
    this.height =
      ctx.rowCount * ctx.cellHeight + ctx.paddingTop + ctx.paddingBottom
    this.width =
      ctx.colCount * ctx.cellWidth + ctx.paddingLeft + ctx.paddingRight
  }

  cell(xIdx: number, yIdx: number, pad = 0): SVGProps<SVGRectElement> {
    const { ctx } = this
    return {
      x: ctx.paddingLeft + xIdx * ctx.cellWidth - pad,
      width: ctx.cellWidth + 2 * pad,
      y: ctx.paddingTop + yIdx * ctx.cellHeight - pad,
      height: ctx.cellHeight + 2 * pad,
    }
  }

  hStripe(yIdx: number) {
    const { width } = this
    return { ...this.cell(0, yIdx), x: 0, width }
  }

  vStripe(xIdx: number) {
    const { height } = this
    return { ...this.cell(xIdx, 0), y: 0, height }
  }
}

// const M = (idx: number) => idx * magic || 0

// const rects: SVGProps<SVGRectElement>[] = [
//   // // top left
//   // { x: 0, y: 0, width: M(xIdx), height: M(yIdx) },
//   // // bottom left
//   // {
//   //   x: 0,
//   //   y: M(yIdx + 1),
//   //   width: M(xIdx),
//   //   height: M(rowCount) - M(yIdx),
//   // },
//   // // top right
//   // {
//   //   x: M(xIdx + 1),
//   //   y: 0,
//   //   width: M(colCount) - M(xIdx),
//   //   height: M(yIdx),
//   // },
//   // // bottom right
//   // {
//   //   x: M(xIdx + 1),
//   //   y: M(yIdx + 1),
//   //   width: M(colCount) - M(xIdx),
//   //   height: M(rowCount) - M(yIdx),
//   // },
// ]
// // top
// rects.push({
//   x: M(xIdx),
//   y: 0,
//   width: magic,
//   height: M(yIdx) - padAdj,
//   fill,
// })
// // bottom
// rects.push({
//   x: M(xIdx),
//   y: M(yIdx + 1) + padAdj,
//   width: magic,
//   height: height - M(yIdx + 1) - padAdj,
//   fill,
// })
// // left
// rects.push({
//   x: 0,
//   y: M(yIdx),
//   width: M(xIdx) - padAdj,
//   height: magic,
//   fill,
// })
// // right
// rects.push({
//   x: M(xIdx + 1) + padAdj,
//   y: M(yIdx),
//   width: width - M(xIdx + 1) - padAdj,
//   height: magic,
//   fill,
// })
