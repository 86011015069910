import { BoxProps, useColorModeValue, type TextProps } from '@chakra-ui/react'
import { DARK_MODE_FG, DEFAULT_FG } from '@paper/styles'
import { forwardRef } from 'react'
import { Txt } from './txt'

/** Highlighted mono example */
export const Ex = forwardRef<HTMLParagraphElement, TextProps>(
  function Ex(props, ref) {
    const bg = useColorModeValue('gray.100', DARK_MODE_FG)

    return (
      <Txt
        as="span"
        bg={bg}
        borderRadius={2}
        color={DEFAULT_FG}
        fontFamily="mono"
        fontWeight={400}
        p={1}
        ref={ref}
        {...props}
      />
    )
  }
)

export const Mono = forwardRef<HTMLParagraphElement, TextProps>(
  function Mono(props, ref) {
    return <Txt as="span" fontFamily="mono" ref={ref} {...props} />
  }
)

/** Highlight values */
export const Hl = forwardRef<HTMLDivElement, BoxProps>(function Hl(props, ref) {
  return (
    <Txt
      as="span"
      borderRadius="md"
      borderColor="whiteAlpha.300"
      borderWidth="1px"
      px={1}
      py={0.5}
      ref={ref}
      {...props}
    />
  )
})
