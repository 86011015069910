import { Grid, Icon, ThemingProps, Tooltip } from '@chakra-ui/react'
import { APIs } from '@paper/api-specs'
import { IcoClock, IcoInfo, IcoRefresh } from '@paper/icons'
import { CrunchBatch } from '@paper/schema'
import { getBatchStuckState } from '@paper/utils'
import { getScanlogStatProps, ScanlogStatsLegend } from '~src/blocks/scanStatus'
import { useUser } from '~src/blocks/userProvider'
import {
  HStack,
  StatTag,
  TextStack,
  TooltippedIcon,
  TooltippedIconButton,
  TooltippedIconProps,
  Txt,
  VStack,
} from '~src/components'
import { FullPageLoading } from '~src/components/status'
import { UghColumn } from '~src/components/table'
import { useApiMutation } from '~src/data/useApiQuery'
import { intlDateTimeShortShort } from '~src/utils/messages'

type Col = UghColumn<CrunchBatch>

const batchNumber: Col = {
  props: { width: 64 },
  label: () => '#',
  cell: (item) => (
    <Txt data-cy="batch-number" fontFamily="mono" fontWeight={600}>
      {item.batchNum}
    </Txt>
  ),
}

const dateEtc: Col = {
  props: { align: 'start', width: 200 },
  label: () => 'Batch',
  cell: (item) => (
    <TextStack>
      <TextStack.Top
        alignItems="center"
        display="flex"
        gap={2}
        height="24px" // prevent jumping...
      >
        {intlDateTimeShortShort(item.scanDate)}
        <RerunButton item={item} />
      </TextStack.Top>
      <TextStack.Bottom
        alignItems="center"
        display="flex"
        fontFamily="mono"
        variant="loose"
      >
        {item.device.name}
      </TextStack.Bottom>
    </TextStack>
  ),
}

const stats: Col = {
  props: { width: 120 },
  label: () => (
    <Tooltip label={<ScanlogStatsLegend />} placement="top">
      <HStack gap={1}>
        Scan stats <Icon as={IcoInfo} />
      </HStack>
    </Tooltip>
  ),
  cell: (item) => {
    const stats = getScanlogStatProps(item)
    return (
      <Grid
        gap={1}
        gridTemplateColumns="repeat(3, 1fr)"
        gridTemplateRows="repeat(2, auto)"
      >
        {stats.map((props, idx) => (
          <StatTag key={idx} size="sm" {...props} />
        ))}
      </Grid>
    )
  },
}

export const scanBatchColumns = [batchNumber, dateEtc, stats]

type RerunButtonProps = { item: CrunchBatch }

function RerunButton(props: RerunButtonProps) {
  const { item } = props
  const { isInternal, isSuperDuperUser } = useUser()

  let settled = item.ts.settled

  const isRerunCandidate =
    item.stats.error > 0 || // errors
    getBatchStuckState(item) === 'maybe-stuck' // or 10 minutes have elapsed

  // Can rerun if super user, or internal + rerun candidate
  // todo: having code paths that are reachable/unreachable just by me doesn't seem great
  // todo: probably better if this is behind a break-in-case-of-emergency-setting
  const canRerun = isSuperDuperUser || (isInternal && isRerunCandidate)
  const mutation = useApiMutation({
    apiSpec: APIs['imgp.rerun'],
    useMutationProps: {},
  })

  let settledDate = settled ? intlDateTimeShortShort(settled) : '<Not yet>'
  let message = `Processing finished ${settledDate}.`
  if (canRerun) {
    message += ` Click to rerun.`
  }

  const size = 'xs'

  let sharedProps: Pick<TooltippedIconProps, 'aria-label' | 'tooltipProps'> = {
    'aria-label': message,
    tooltipProps: {
      label: (
        <VStack fontSize="sm" gap={2} p={1} textAlign="center">
          <TextStack>
            <TextStack.Top fontSize="xs">Processing finished:</TextStack.Top>
            <TextStack.Bottom fontSize="sm" variant="loose">
              {settledDate}
            </TextStack.Bottom>
          </TextStack>
          {canRerun && (
            <TextStack>
              <TextStack.Top>Click to rerun</TextStack.Top>
              <TextStack.Bottom fontStyle="italic" variant="loose">
                Experimental, be cautious
              </TextStack.Bottom>
            </TextStack>
          )}
        </VStack>
      ),
      placement: 'end-start',
    },
  }

  // Redder if re-run candidate (vs. normal, re-run won't do anything in normal circumstances)
  const themeProps: ThemingProps = isRerunCandidate
    ? { colorScheme: 'red', variant: 'outline' }
    : { variant: 'outline' }

  return canRerun ? (
    <FullPageLoading qResult={mutation} type="transparent">
      <TooltippedIconButton
        {...sharedProps}
        {...themeProps}
        aria-label={message}
        icon={<IcoRefresh />}
        onClick={(event) => {
          event.stopPropagation()
          mutation.mutate({ batchId: item.id })
        }}
        size={size}
      />
    </FullPageLoading>
  ) : (
    <TooltippedIcon {...sharedProps} as={IcoClock} fontSize={size} />
  )
}
