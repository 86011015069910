import { Packetmeta } from '@paper/schema'
import { defineApi } from '../defineApi'

export const pl_illuminate = defineApi<{
  method: 'get'
  pathParams: { _illuminate: string }
  result: Packetmeta[]
}>({
  method: 'get',
  urlFactory: ({ _illuminate }) => {
    const urlKey = `packets/illuminate/`
    return [`${urlKey}${_illuminate}`, urlKey]
  },
})
