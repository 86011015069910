import { Icon, List, ListIcon, ListItem, ListProps } from '@chakra-ui/react'
import { IcoChevronRight } from '@paper/icons'
import { Fragment, ReactNode } from 'react'
import { HStack, VSep, VStack } from '~src/components'

type DialogSubsectionProps = {
  children: ReactNode
  icon: any
  title: ReactNode
  unhighlighed?: boolean
}

export function DialogSubsection(props: DialogSubsectionProps) {
  const { children, icon, title, unhighlighed } = props
  return (
    <VStack
      alignItems="stretch"
      gap={4}
      opacity={unhighlighed ? 0.6 : null}
      px={5}
      py={4}
    >
      <HStack gap={2}>
        <Icon as={icon} />
        {title}
      </HStack>
      {children}
    </VStack>
  )
}

type DialogListProps = {
  children: ReactNode
  fontSize?: ListProps['fontSize']
  spacingY?: 'airy' | 'default'
}

function DialogList(props: DialogListProps) {
  const { spacingY, ...rest } = props
  const spacing = spacingY === 'airy' ? 3 : 2
  const listProps = { fontSize: 'xs', spacing, ...rest }
  return <List {...listProps} />
}

DialogSubsection.List = DialogList

/**
 * @deprecated probably parameterize/componentize...
 */
export const lifact = (fact: ReactNode) => (
  <ListItem alignItems="center" display="flex">
    <ListIcon as={IcoChevronRight} />
    {fact}
  </ListItem>
)

/**
 * @deprecated probably parameterize/componentize...
 */
export const lifactPieces = (pieces: ReactNode[]) => {
  return lifact(
    <HStack
      display="inline-flex"
      gap={2}
      isTruncated={true}
      whiteSpace="nowrap"
    >
      {renderPiecesSS(pieces)}
    </HStack>
  )
}

export function SummarySeparator() {
  return <VSep boxProps={{ bg: 'gray.200', marginInline: 1 }} />
}

// todo: pretty sure i've written this multiple times...
export const renderPieces = (pieces: ReactNode[], separator?: ReactNode) => {
  return pieces
    ?.filter((p) => p)
    .map((item, idx) => (
      <Fragment key={idx}>
        {idx !== 0 && separator}
        {item}
      </Fragment>
    ))
}

/**
 * Returns pieces with `<SummarySeparator />` injected in between
 */
export const renderPiecesSS = (pieces: ReactNode[]) => {
  return renderPieces(pieces, <SummarySeparator />)
}
