// can't upgrade to chakra 2 yet
// chakra 1.x automatically loads its toast module which we aren't using and is using the old api
if (process.env.NODE_ENV !== 'production') {
  const consoleError = console.error
  const annoyingError = `Warning: ReactDOM.render is no longer supported in React 18`
  console.error = (...args: string[]) => {
    if (!args[0]?.startsWith?.(annoyingError)) {
      consoleError(...args)
    }
  }

  const consoleInfo = console.info
  const annoyingLogs = ['%cDownload the React DevTools']
  console.info = (...args: string[]) => {
    if (annoyingLogs.every((a) => !args[0]?.startsWith?.(a))) {
      consoleInfo(...args)
    }
  }
}
