import { z } from 'zod'
import type { QType } from '../../types/packetmeta'
import { Student, Teacher, Term } from '../../types/school'
import {
  SatelliteAQ,
  SatelliteAssessment,
  SatelliteSection,
} from '../../types/types.satellite'
import { RawStd } from '../../types/types.std'
import { zenum, zw } from '../v-utils'

const z_nonempty = z.string().trim().min(1)
const z_int = z.number().int()
const z_email = z.string().trim().toLowerCase().email()
const z_syId = z_int

export const emailOrNull = (email: string) => {
  let result = z_email.safeParse(email)
  return result.success ? result.data : null
}

export const z_student = zw<Student>({
  firstName: z_nonempty,
  grade: z_nonempty,
  id: z_nonempty,
  lastfirst: z_nonempty,
  lastName: z_nonempty,
  number: z_nonempty,
})

const z_teacher = zw<Teacher>({
  email: z_email,
  firstName: z_nonempty,
  id: z_nonempty,
  lastName: z_nonempty,
})

const z_term = zw<Term>({
  code: z.string().regex(/^\d{2}-\d{2}$/),
  firstDay: z.coerce.date(),
  lastDay: z.coerce.date(),
  syId: z_syId,
})

export const z_satelliteSection = zw<SatelliteSection>({
  course: z_nonempty,
  coteacherEmails: z.array(z_email).default([]),
  id: z_nonempty,
  name: z_nonempty,
  schoolId: z_nonempty,
  schoolName: z_nonempty,
  students: z.array(z.any()).nonempty() as any,
  teacher: z_teacher.omit({ id: true }),
  term: z_term,
})

export const z_rawStd = zw<RawStd>({
  sub_id: z_int,
  sub_title: z.string(),
  sub_set: z.string(),
  cat_id: z_int,
  cat_title: z.string(),
  cat_hi: z.string(),
  cat_lo: z.string(),
  local_id: z_int,
  parent_id: z_int.nullable(),
  id: z.string(),
  code: z.string(),
  desc: z.string().trim(),
  seq: z_int,
})

const z_satelliteAQ = zw<SatelliteAQ>({
  _ill_field_id: z_int,
  bubbles: z.array(z.string()),
  gridChoices: z.string().optional(),
  gridColumns: z_int.optional(),
  isAdvanced: z.boolean(),
  isEC: z.boolean(),
  isRubric: z.boolean(),
  label: z.string(),
  maxPts: z.number(),
  order: z_int,
  responses: z.array(z.object({ filledStr: z.string(), pts: z.number() })),
  stds: z.array(z.object({ code: z.string(), id: z.string() })),
  type: zenum<QType>(['GRID', 'MC', 'OER']),
})

export const z_satelliteAssessment = zw<SatelliteAssessment>({
  _ill_assessment_id: z_int,
  instId: z.string(),
  questions: z.array(z_satelliteAQ),
  syId: z_syId,
  title: z.string(),
})
