import { Button, ButtonGroup, IconButton, Radio, Tag } from '@chakra-ui/react'
import { IcoEdit, IcoFunnel, IcoNewEdition } from '@paper/icons'
import { useRouter } from '@paper/route'
import { C2Status } from '@paper/schema'
import { MenuBody, SimpleMenu } from '~src/blocks/menu'
import { SIBP } from '~src/blocks/packetTable/tableColUtils'
import {
  HStack,
  TooltippedIcon,
  TooltippedIconButton,
  Txt,
  VStack,
} from '~src/components'
import { UghColumn } from '~src/components/table'
import type { RD_Setup_Curriculum } from '~src/routelist'
import { C2FamilyEd, C2FamilyStatus } from './data-curriculumSetup'
import { useDialogContext } from './dialogs'
import { statusToColor, VariantStatus } from './variantStatus'

type Col = UghColumn<C2FamilyEd>

const Family: Col = {
  props: { align: 'start' },
  label: () => 'Family',
  cell: (item) => <Txt>{item.family}</Txt>,
}

const Levels: Col = {
  props: { align: 'start', width: 80 },
  label: () => 'Level',
  cell: (item) => item.levels.join(' | '),
}

const Subject: Col = {
  props: { align: 'start', width: 80 },
  label: () => 'Subject',
  cell: (item) => item.subject,
}

type VariantStatusesProps = {
  statuses: C2FamilyStatus[]
}

function VariantStatuses(props: VariantStatusesProps) {
  const { statuses } = props
  return (
    <ButtonGroup>
      {statuses.map((data) => (
        <VariantStatus key={data.status} {...data} />
      ))}
    </ButtonGroup>
  )
}

function StatusFilter() {
  const statuses: C2Status[] = ['active', 'retired', 'recalled']

  const { dispatchStay, routeData } = useRouter<RD_Setup_Curriculum>()
  const { f_status } = routeData

  return (
    <SimpleMenu
      caret={false}
      defaultOpen={false}
      sideOffset={4}
      trigger={(menuButtonProps) => (
        <IconButton
          {...menuButtonProps}
          aria-label={`Status filter`}
          colorScheme={f_status ? statusToColor(f_status) : null}
          data-cy="filter-status"
          icon={<IcoFunnel />}
          isRound={true}
          mx={0.5}
          size="xs"
          variant={f_status ? 'solid' : 'ghost'}
          minWidth="18px"
          height="18px"
        />
      )}
    >
      <MenuBody p={2}>
        <VStack alignItems="stretch" gap={2}>
          <Radio
            colorScheme={'gray'}
            isChecked={!f_status}
            onChange={() => dispatchStay({ f_status: null })}
          >
            <Txt fontSize="sm" px={1}>
              All
            </Txt>
          </Radio>
          {statuses.map((status) => {
            const colorScheme = statusToColor(status)
            const isSelected = status === f_status
            return (
              <Radio
                colorScheme={colorScheme}
                isChecked={isSelected}
                key={status}
                onChange={() => dispatchStay({ f_status: status })}
              >
                <Tag
                  colorScheme={colorScheme}
                  justifyContent="center"
                  textTransform="capitalize"
                  variant="solid"
                >
                  {status}
                </Tag>
              </Radio>
            )
          })}
        </VStack>
      </MenuBody>
    </SimpleMenu>
  )
}

export const useCurriculumSetupColumns = () => {
  const { openDialog } = useDialogContext()

  const Edition: Col = {
    props: { align: 'start', width: 80 },
    label: () => 'Edition',
    cell: (item) => {
      return (
        <HStack fontFamily="mono">
          {item.ed}
          <TooltippedIconButton
            aria-label="New edition"
            data-cy="c2-new-ed"
            {...SIBP}
            icon={<IcoNewEdition />}
            onClick={() => openDialog('newedition', item)}
            tooltipProps={{ placement: 'end', offset: [0, 4] }}
          />
        </HStack>
      )
    },
  }

  const VariantStatusCol: Col = {
    props: { align: 'start' },
    label: () => (
      <HStack>
        Status
        <StatusFilter />| Variants{' '}
        <TooltippedIcon
          as={IcoEdit}
          aria-label="Click an entry in this column to manage its variants"
          mx={1}
        />
      </HStack>
    ),
    cell: (item) => (
      <Button
        aria-label="Manage variants"
        data-cy="c2-edit"
        onClick={() => openDialog('manage', item)}
        size="sm"
        variant="ghost"
      >
        <VariantStatuses statuses={item.statuses} />
      </Button>
    ),
  }

  return [Edition, Subject, Levels, Family, VariantStatusCol]
}
