import { APIs } from '@paper/api-specs'
import { LoginData, SchoolYear, WebappUser } from '@paper/schema'
import { createFetch, Fetcher, fetcher, getInternalUrl } from '@paper/utils'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { createContext, useContext, useMemo, useState } from 'react'
import Rollbar from 'rollbar'
import { Txt } from '~src/components'
import { FullPageLoading } from '~src/components/status'
import { Public } from '~src/pages/public'
import config from '~src/utils/config'
import rollbar from '~src/utils/rollbar'
import { useStaticFn } from './list/listCallbacks'

const TOKEN_KEY = 'token'
const TOKEN_STORED_AT_INIT = localStorage.getItem(TOKEN_KEY)

// these are currently static
const { queryKey: USER_QUERY_KEY, url: urlPath } =
  APIs['auth.checktoken'].factory(undefined)

type TransitionState = 'none' | 'signing-out'
export type SignOutReason = 'none' | 'session_closed'
type SignOut = (manual: boolean) => void

type UserContextData = {
  isInternal: boolean
  isSuperDuperUser: boolean
  user: WebappUser
  year: LoginData['year']
  years: SchoolYear[]
}

type UserContext = UserContextData & {
  fetchAs: Fetcher
  signOut: SignOut
}
const UserContext = createContext<UserContext>(undefined)
export const useUser = () => useContext(UserContext)

export function UserProvider({ children }) {
  // Get a callback for successful login that set the user
  const publicProps = useSetUserCallback()
  // Get user data based on the a saved token (if exists/valid)
  const sessionResult = useUserQuery()
  // Show app vs. public/login page based on whether we have a user
  const contextData = sessionResult.data
  const isPublic = !contextData

  // Keep track of whether we're signing out so we can short-circuit
  const [transitionState, setTransitionState] =
    useState<TransitionState>('none')

  // Build context functions
  const signOut = useStaticFn<SignOut>((manual) => {
    // Put us in signing out mode to short-circuit the app #284
    setTransitionState('signing-out')
    // Prep for sign out by loading null user
    loadUserAndPrepContext(null)

    // Reload the page to be extra safe
    if (manual) {
      // if signout is manual, send home
      window.location.href = '/'
    } else {
      // set reason
      SignOutReason.set('session_closed')
      // otherwise (e.g. expired token) reload the page
      window.location.reload()
    }
  })

  const fetchAs = useMemo(() => {
    return createFetch({
      origin: config.api.prefix,
      bearer: contextData?.user.token,
      on401: () => signOut(false),
    })
  }, [contextData?.user, signOut])

  const context: UserContext = {
    ...contextData,
    fetchAs,
    signOut,
  }

  // Short-circuit while signing out
  if (transitionState === 'signing-out') {
    return (
      <FullPageLoading
        loadMsg={
          <Txt>
            Session closed - Please reload the page if you get stuck here.
          </Txt>
        }
        qResult={{ fetchStatus: 'fetching', isPending: true }}
      />
    )
  }

  // Otherwise grab public vs. private page
  const page = isPublic ? (
    <Public {...publicProps} />
  ) : (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  )

  // Stick it in the loading shell
  return <FullPageLoading qResult={sessionResult}>{page}</FullPageLoading>
}

const useUserQuery = () => {
  const url = getInternalUrl(config.api.prefix, urlPath)
  const headers = { authorization: `Bearer ${TOKEN_STORED_AT_INIT}` }

  return useQuery({
    queryKey: USER_QUERY_KEY,
    enabled: !!TOKEN_STORED_AT_INIT, // don't run if no token
    staleTime: Infinity, // run once
    queryFn: async () => {
      // Check token if stored at init
      // If there's no token user gets set via the login process
      if (TOKEN_STORED_AT_INIT) {
        //console.log('**', url)
        try {
          const result = await fetcher.post(url, { headers }).json<LoginData>()
          return loadUserAndPrepContext(result)
        } catch (err) {
          // if the token fail, remove it
          localStorage.removeItem(TOKEN_KEY)
          // 401 is expected when the token is bad
          if (err.response?.status === 401) {
            return null
          }
          // Throw unexpected errors
          throw err
        }
      } else {
        // if no token, continue to login screen
        localStorage.removeItem(TOKEN_KEY)
        return null
      }
    },
  })
}

const useSetUserCallback = () => {
  // Keep track of sign-out reason so we can display a nicer message
  const [signOutReason, setSignOutReason] = useState(SignOutReason.getter)

  const queryClient = useQueryClient()
  const onLogin = (result: LoginData) => {
    setSignOutReason('none') // reset signOutReason if we're setting here
    // todo: Not sure if I'm misusing react-query (also, this was written prior to v3)
    queryClient.setQueryData(USER_QUERY_KEY, loadUserAndPrepContext(result))
  }

  return { onLogin, reason: signOutReason }
}

/**
 * Unpacks LoginData to UserContextData and updates non-react things
 */
const loadUserAndPrepContext = (
  loginData: LoginData | null
): UserContextData => {
  // Unpack login data, null if signing out
  const user = loginData?.user
  // Update token
  localStorageSetOrRemove(TOKEN_KEY, user?.token)
  // Update rollbar
  rollbar.configure({ payload: { person: userToPerson(user) } })

  if (!loginData) {
    return null
  } else {
    const { year, years } = loginData
    const isInternal =
      user.domain === 'ponder.co' && user.roles.includes('admin')
    const isSuperDuperUser = user.email === 'tony@ponder.co' // workaround so i can re-run batches

    return {
      isInternal,
      isSuperDuperUser,
      user,
      year,
      years,
    }
  }
}

///////////////////
// Little utils
///////////////////

const localStorageSetOrRemove = (key: string, value: string) => {
  value ? localStorage.setItem(key, value) : localStorage.removeItem(key)
}

function localStorageGetAndRemove<T extends string>(key: string) {
  const value = localStorage.getItem(key)
  if (value) {
    localStorage.removeItem(key)
  }
  return value as T
}

const userToPerson = (user: WebappUser): Rollbar.Payload['person'] => {
  if (!user) {
    return null
  } else {
    return { id: user.email, email: user.email }
  }
}

const SignOutReason = {
  _key: 'signout_reason',
  set: (value: SignOutReason) =>
    localStorage.setItem(SignOutReason._key, value),
  // This is consumed by useState, so need a function so we don't consume multiple times
  getter: () => localStorageGetAndRemove<SignOutReason>(SignOutReason._key),
}
