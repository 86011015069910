import { Button } from '@chakra-ui/react'
import { IcoArrowBack } from '@paper/icons'
import { ReactNode } from 'react'
import { NotFoundError } from '.'
import { ErrorActionSection, ErrorPage } from './errorPageBase'
import { getEAProps } from './errorUtils'

type Error404Props = { actions?: ReactNode; error?: NotFoundError }

export function Error404Page(props: Error404Props) {
  const { actions, error } = props
  const subject = `🤔 Paper 404`

  return (
    <ErrorPage
      actions={
        <ErrorActionSection
          message={`❓ If arriving from outside Paper, you may have been given a bad link.`}
        >
          {window.history.length && (
            <Button
              {...getEAProps(<IcoArrowBack />)}
              // todo: not sure this updates the url properly...
              onClick={() => window.history.back()}
            >
              Go back
            </Button>
          )}
          {actions}
        </ErrorActionSection>
      }
      contactSectionMsg={`🐛 If Paper sent you here, you probably found a bug!`}
      data-cy="error-page-404"
      defaultFriendly={`There's nothing at this URL`}
      error={error}
      subject={subject}
    />
  )
}
