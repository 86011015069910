import { useMemo } from 'react'
import { ComboBox, ComboBoxProps, Txt, useComboBoxItems } from '~src/components'
import { Filters } from '~src/utils/useFilters'

export type FilterPickerProps<T> = {
  allText?: string
  busy?: ComboBoxProps['busy']
  clearable?: ComboBoxProps['clearable']
  filterType: keyof Filters
  getId?(item: T): string
  getLabel?(item: T): string
  inputTextAlign?: ComboBoxProps['inputTextAlign']
  itemPlural: string
  items: T[]
  label?: ComboBoxProps['label']
  listMaxHeight?: ComboBoxProps['listMaxHeight']
  onChange(id: string): void
  renderItem?: ComboBoxProps['renderItem']
  round?: ComboBoxProps['round']
  selectedId: string
  // todo: more prop problems...maybe change to hook...
  fontFamily?: ComboBoxProps['size']
  size?: ComboBoxProps['size']
  width?: ComboBoxProps['width']
  variant?: ComboBoxProps['variant']
}

/**
 * Simple text filter picker with an "all" option
 */
export function FilterPicker<T>(props: FilterPickerProps<T>) {
  let {
    allText,
    clearable,
    filterType,
    getId,
    getLabel,
    itemPlural,
    items,
    onChange,
    selectedId,
    ...passThrough
  } = props

  // default all text
  allText = allText ?? `All ${itemPlural}`

  // coalesce blank to null
  selectedId = selectedId ?? null

  const itemsWithAll = useMemo(() => {
    getId =
      getId ?? ((item: any) => (typeof item === 'string' ? item : item.id))
    getLabel = getLabel ?? ((item) => item.toString())

    let result = items.map((item) => ({
      id: getId(item),
      label: getLabel(item),
      full: item,
    }))

    if (!clearable) {
      result.unshift({ id: null, label: allText, full: null })
    }
    return result
  }, [clearable, items])

  const comboProps = useComboBoxItems({
    items: itemsWithAll,
    value: selectedId,
    selector: (item, value) => item.id === value,
    filterer: (filters, item, inputValue) =>
      filters[filterType](item.label, inputValue),
  })

  return items.length === 0 ? (
    <Txt fontSize="xs">There are no {itemPlural.toLowerCase()}</Txt>
  ) : (
    <ComboBox
      variant="filled" // default to filled
      {...comboProps}
      {...passThrough}
      blurOnSelect={true}
      caret={true}
      clearable={clearable}
      itemToString={(item) => item.label}
      // Filter picker is designed to propagate to the router
      // so returning the id should be sufficient
      // need `item.?` in case of clearable
      onChange={(item) => onChange(item?.id)}
      placeholder={clearable ? allText : undefined}
    />
  )
}

/**
 * @deprecated
 * todo: copy/pasted from filter picker
 */
export function SimplePicker<T>(
  props: FilterPickerProps<T> & { placeholder?: string }
) {
  let {
    allText,
    filterType,
    getId,
    getLabel,
    itemPlural,
    items,
    onChange,
    selectedId,
    ...passThrough
  } = props

  // coalesce blank to null
  selectedId = selectedId ?? null

  const itemsWithAll = useMemo(() => {
    getId =
      getId ?? ((item: any) => (typeof item === 'string' ? item : item.id))
    getLabel = getLabel ?? ((item) => item.toString())

    // no-all
    return [
      ...items.map((item) => ({ id: getId(item), label: getLabel(item) })),
    ]
  }, [items])

  const comboProps = useComboBoxItems({
    items: itemsWithAll,
    value: selectedId,
    selector: (item, value) => item.id === value,
    filterer: (filters, item, inputValue) =>
      filters[filterType](item.label, inputValue),
  })

  return items.length === 0 ? (
    <Txt>There are no {itemPlural.toLowerCase()}</Txt>
  ) : (
    <ComboBox
      variant="filled" // default to filled
      {...comboProps}
      {...passThrough}
      blurOnSelect={true}
      caret={true}
      itemToString={(item) => item.label}
      // Filter picker is designed to propagate to the router
      // so returning the id should be sufficient
      onChange={(item) => onChange(item.id)}
    />
  )
}
