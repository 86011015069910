import { Tag, TagCloseButton, TagLabel } from '@chakra-ui/react'
import { StdEmbed } from '@paper/schema'
import { HStack, Txt } from '~src/components'

type StdTagListProps<T> = { stds: T[] } & (
  | { onRemove(item: T): void; readonly: false }
  | { onRemove?: undefined; readonly: true }
)

export function StdTagList(props: StdTagListProps<StdEmbed>) {
  const { onRemove, readonly, stds } = props
  return (
    <HStack flexWrap="wrap" gap={1.5}>
      {!stds?.length ? (
        <Txt fontSize="sm" fontStyle="italic" opacity=".8">
          No standards selected for this ticket.
        </Txt>
      ) : (
        stds.map((std) => (
          <Tag
            key={std.id}
            borderRadius="full"
            colorScheme="blackAlpha"
            size="md"
            variant="solid"
          >
            <TagLabel>{std.code}</TagLabel>
            {!readonly && <TagCloseButton onClick={() => onRemove(std)} />}
          </Tag>
        ))
      )}
    </HStack>
  )
}
