import { Box, BoxProps } from '@chakra-ui/react'
import { RouteMeta, RouterAction, useLink, useRouter } from '@paper/route'
import { pathToDataCy } from '@paper/utils'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { forwardRef, ReactNode } from 'react'

export const MIPX = 4
export const MIPXRem = MIPX / 4
export const MILeftBorderRem = 0.35

/**
 * Returns props for MenuItem selected state
 */
export function useSelectedMenuItemProps(isSelected: boolean) {
  let props: MenuItemProps = isSelected
    ? { borderInlineStartColor: 'blue.500' }
    : {}
  return props
}

/**
 * Returns props for MenuItem that is an external link (e.g. to zendesk)
 */
export function getMenuItemExternalLinkProps(href: string) {
  // having trouble with chakra/focus-visible formatting so do it this way
  return {
    as: 'a',
    href,
    rel: 'noopener noreferrer',
    target: '_blank',
  } as const
}

type MenuItemProps = {
  icon?: ReactNode
} & BoxProps &
  Pick<DropdownMenu.MenuItemProps, 'disabled' | 'onSelect'>
/**
 * Base MenuItem with inline-start border for selected state
 * todo: the way this is styled, can't use sx
 */
export const MenuItem = forwardRef<HTMLDivElement, MenuItemProps>(
  function MenuItem(props, ref) {
    const { children, disabled, icon, onSelect, ...boxProps } = props

    let handleSelect = (event) => {
      event.preventDefault() // don't close (should parameterize)
      onSelect?.(event)
    }

    return (
      <DropdownMenu.Item asChild disabled={disabled} onSelect={handleSelect}>
        <Box
          borderColor="transparent"
          sx={{
            alignItems: 'center',
            borderInlineStartWidth: `${MILeftBorderRem}rem`,
            cursor: 'pointer',
            gap: '.75rem',
            display: 'flex',
            outline: 'none',
            paddingInlineStart: `${MIPXRem - MILeftBorderRem}rem`,
            paddingInlineEnd: `${MIPXRem}rem`,
            py: '.4rem',
            userSelect: 'none',
            '&:focus': { bg: 'gray.100' },
            '&[data-disabled]': {
              cursor: 'not-allowed',
              opacity: 0.4,
              pointerEvents: 'none',
            },
          }}
          {...boxProps}
          ref={ref}
        >
          {icon}
          {children}
        </Box>
      </DropdownMenu.Item>
    )
  }
)

/**
 * Returns props for RouterMenuItem
 */
export function useRouterMenuItemProps(route: RouteMeta, data?: object) {
  const { currentRoute } = useRouter()
  const selectedProps = useSelectedMenuItemProps(currentRoute === route)
  const linkProps = useLink(route.mergeAction(data))
  return { as: 'a', ...linkProps, ...selectedProps } as const
}

type RouterMenuItemProps = Omit<MenuItemProps, 'as'> & {
  route: RouteMeta<any>
  data?: any
}
/**
 * MenuItem that links to another route via `mergeAction`
 */
export const RouterMenuItem = forwardRef<HTMLDivElement, RouterMenuItemProps>(
  function RouterMenuItem(props, ref) {
    const { data, route, ...rest } = props
    const routerProps = useRouterMenuItemProps(route, data)
    return (
      <MenuItem
        data-cy={pathToDataCy('mi', route.path)}
        ref={ref}
        {...rest}
        {...routerProps}
      />
    )
  }
)

type RouteActionMenuItemProps = Omit<MenuItemProps, 'as'> & {
  action: RouterAction
}

/**
 * MenuItem that links to another route via provided `props.action`
 */
export const RouteActionMenuItem = forwardRef<
  HTMLDivElement,
  RouteActionMenuItemProps
>(function RouteActionMenuItem(props, ref) {
  const { action, ...rest } = props
  const linkProps = useLink(action)
  return <MenuItem as="a" ref={ref} {...rest} {...linkProps} />
})
