import { BodyOf } from '@paper/api-specs'
import { useRouter } from '@paper/route'
import {
  Pos,
  ScanFixCandidatePage,
  ScanImageAbbr,
  ScanStatus,
  XpacketSet,
} from '@paper/schema'
import { ScanStatusSets, uniqOrNull, XOR } from '@paper/utils'
import { Vector2 } from '@use-gesture/react'
import { orderBy, times } from 'lodash'
import { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useImmer } from 'use-immer'
import { useNormalizeImages } from '~src/blocks/imageViewer'
import { useStaticFn } from '~src/blocks/list/listCallbacks'
import { useListenQuery } from '~src/pages/publish/data-publish'
import { RD_SW_Scanlog } from '~src/routelist'
import { useSchoolYearContext } from '~src/schoolYearAirlock'
import { PanEntangler, usePanEntangler } from '~src/utils/usePanEntangler'
import { useScanFixCandidateDetails } from '../data-scanlog'

export type Fixable = {
  /** candidates for editing */
  candidates: ScanFixCandidatePage[]
  /** State of the page */
  changeState: ScanChange
  /** scan image id */
  id: string
  /** saved data */
  savedData: ScanFixCandidatePage
  /** image source */
  src: string
  /** scanimage status */
  status: ScanStatus
}

export type CandidateInputs = {
  qrbComplete?: string
  qrbValue?: string
  xpageId?: string
}

type ScanFixPos = {
  /** @deprecated maybe */
  originPct: Vector2
  quadrant: ScanFixQuadrant
}
type ScanFixQuadrant = Pos | ''

export type ScanChange = 'ignore' | 'none' | 'pending' | number

/**
 * Base state values
 */
type ScanFixPrivateState = {
  /** qrb or xpageId  */
  candidateInputs: CandidateInputs
  /** changes */
  changes: ScanChange
}

// Actions
type SelectPage = (
  props: XOR<{ qrb: string; complete?: boolean }, { xpageId: string }>
) => void

type SetChanges = (changes: ScanChange) => void

type EditState =
  | 'idle'
  | 'ineligible'
  | 'invalid'
  | 'not-eligible'
  | 'no-results'
  | 'waiting-for-choice'
  | 'waiting-for-input'
  | 'waiting-for-results'
  | 'wating-for-submit'

export type ScanFixContext = {
  candidateResult: ReturnType<typeof useScanFixCandidateDetails>
  editState: EditState
  isDirty: boolean
  isValid: boolean
  fixPayload: BodyOf<'scanlog.xpageFix'>
  panEntangler: PanEntangler
  pos: ScanFixPos
  qrbPrefix: string
  selectedFixable: Fixable
  selectPage: SelectPage
  setChanges: SetChanges
  setQuadrant(quadrant: ScanFixQuadrant): void
  tableData: Fixable[]
  targetXpageIdSet: Set<string>
} & ScanFixPrivateState

const getInitialState = (): ScanFixPrivateState => ({
  candidateInputs: {},
  changes: 'none',
})

type UseMakeScanFixContextProps = {
  selectedScanImage: ScanImageAbbr
  selectedSheet: ScanImageAbbr[]
  selectedXpacketSet: XpacketSet
  xpacketSets: XpacketSet[]
}

export const useMakeScanFixContext = (
  props: UseMakeScanFixContextProps
): ScanFixContext => {
  const { selectedScanImage, selectedSheet, selectedXpacketSet, xpacketSets } =
    props
  const { routeData } = useRouter<RD_SW_Scanlog>()

  ////////////////////////
  // position and pan
  ////////////////////////
  // rerendering this context on every pan is kind of expensive
  // but want the images to pan together
  const panEntangler = usePanEntangler('contain')
  const [pos, setPos] = useState<ScanFixPos>({
    originPct: [0, 0],
    quadrant: '',
  })

  const setQuadrant = useStaticFn((quadrant: ScanFixQuadrant) => {
    const pctLookup = { t: 0, l: 0, b: 100, r: 50 }
    // tl is [y,x], origin is [x,y]
    const xy = quadrant?.split('').reverse()
    const pct = (xy?.map((p) => pctLookup[p]) ?? [0, 0]) as Vector2
    setPos({ originPct: pct, quadrant })
    panEntangler.current.setOrigin(pct)
  })

  // Reset quadrant/pos when packet changes
  const consensusQuadrant = useMemo(() => {
    if (selectedXpacketSet) {
      return selectedXpacketSet.packet.style?.position ?? ''
    } else {
      return (
        uniqOrNull(
          new Set(xpacketSets?.map((p) => p.packet.style?.position))
        ) ?? ''
      )
    }
  }, [selectedXpacketSet, xpacketSets])

  useEffect(() => {
    setQuadrant(consensusQuadrant)
  }, [consensusQuadrant])

  ////////////////////////
  // actions/data
  ////////////////////////
  const [scanFixState, setScanFixState] = useImmer(getInitialState)

  const selectPage = useStaticFn<SelectPage>((input) => {
    setScanFixState((draft) => {
      const { complete, qrb, xpageId } = input
      if (xpageId) {
        draft.candidateInputs = { xpageId }
      } else if (qrb != null) {
        // working value in the input
        draft.candidateInputs = { qrbValue: qrb }
        // value that gets sent to the server
        if (complete) {
          draft.candidateInputs.qrbComplete = qrb
        }
      }
    })
  })

  const setChanges = useStaticFn<SetChanges>((changes) => {
    setScanFixState((draft) => {
      draft.changes = changes
      // reset inputs on some state changes
      if (isNaN(draft.changes as any)) {
        draft.candidateInputs = {}
      }
    })
  })

  // todo: add this to the db? it need to change next year
  // todo: need to handle in between years
  const qrbPrefix = useSchoolYearContext().currentYear?.code ?? ''

  // Get result from server for candidate inputs
  const candidateResult = useScanFixCandidateDetails(
    scanFixState.candidateInputs,
    qrbPrefix
  )

  // todo: messily select first item if there is only 1 item
  useListenQuery(candidateResult, true, (qResult) => {
    if (qResult.data?.length === 1) {
      setChanges(0)
    }
  })

  // Also clear page selection on si_imageId or packetId change
  useLayoutEffect(() => {
    setChanges('none')
  }, [routeData.si_imageId, routeData.packetId])

  // calculate data from saved value
  const origSheet = useMemo<ScanFixCandidatePage[]>(() => {
    return selectedSheet?.map((scanImage) => {
      const xpacketId = scanImage.data?.xpacketId
      const xpageId = scanImage.data?.xpageId

      for (let xps of xpacketSets ?? []) {
        for (let xpacket of xps.xpackets) {
          if (xpacket.id === xpacketId) {
            const xpageMap = new Map(
              xpacket.pages.map((xpage, idx) => [xpage.id, { idx, xpage }])
            )
            const entry = xpageMap.get(xpageId)

            if (!entry) {
              return null
            } else {
              const page: ScanFixCandidatePage = {
                by: entry.xpage.fix?.by,
                course: xpacket.section.course,
                curriculumId: xpacket.section.curriculumId,
                packet: xps.packet,
                packetIndex: entry.idx,
                qrb: entry.xpage.qrb,
                status: entry.xpage.fix ? 'fixed' : 'success',
                student: xpacket.student,
                xpacketId,
                xpageId,
              }
              return page
            }
          }
        }
      }
    })
  }, [selectedScanImage, selectedSheet, xpacketSets])

  // zip together selectedSheet and reconcile origSheet vs. candidates
  const images = useNormalizeImages({
    keys: selectedSheet?.map((si) => si.key),
  })

  const { selectedFixable, tableData } = useMemo(() => {
    if (!images?.length) {
      return { selectedChangeState: null, tableData: [] }
    }

    let selectedFixable: Fixable

    const changes: ScanChange[] = Array.isArray(scanFixState.changes)
      ? scanFixState.changes
      : times(images.length, () => scanFixState.changes)

    // todo: this is such a mess!
    // the qrb lookup doesn't know which page is which in the sheet
    const sheetifyCandidates = (unordered: ScanFixCandidatePage[][]) => {
      if (!unordered?.length) {
        return unordered
      }

      // candidates are returned as [match, flip]
      // so flip that if the sheet is in reverse
      const selectedIndexInSheet = selectedSheet.findIndex(
        (p) => p.id === selectedScanImage.id
      )
      let indexOrder = selectedIndexInSheet === 0 ? [0, 1] : [1, 0]

      // sort candidates by whether the packetIds match
      let ordered = orderBy(unordered, (c) =>
        c[0].packet.id === routeData.packetId ? 0 : 1
      )

      // the result is grouped by sheet, we need to group by page
      // candidates are returned [match, flip]
      return indexOrder.map((idx) => ordered.map((p) => p[idx]))
    }

    const sheetifiedCandidates = sheetifyCandidates(candidateResult.data)

    const tableData = images.map(({ src }, idx) => {
      const si = selectedSheet[idx]
      const changeState = changes[idx]

      let fixable: Fixable = {
        candidates:
          changeState === 'ignore' ? null : sheetifiedCandidates?.[idx],
        changeState,
        id: si.id,
        src,
        savedData: origSheet?.[idx],
        status: si.status,
      }

      if (si.id === selectedScanImage.id) {
        selectedFixable = fixable
      }
      return fixable
    })

    //console.log('[tableData]', tableData)
    return { selectedFixable, tableData }
  }, [
    candidateResult.data,
    images,
    origSheet,
    routeData.packetId,
    scanFixState.candidateInputs,
    scanFixState.changes,
    selectedScanImage,
    selectedSheet,
  ])

  const isDirty = oneOrMore(tableData, (c) => c.changeState !== 'none')

  // calculate payload and whether we can submit, in context probably...
  const { fixPayload, isValid } = useMemo(() => {
    const fixPayload: BodyOf<'scanlog.xpageFix'> = (tableData as Fixable[])
      ?.filter(
        (item) => item.changeState !== 'none' && item.changeState !== 'pending'
      )
      .map((item) => {
        let candidateIndex = item.changeState as number
        if (item.changeState === 'ignore') {
          return { action: 'ignore', scanImageId: item.id }
        } else if (!isNaN(candidateIndex)) {
          let candidate = item.candidates?.[candidateIndex]
          if (item.savedData?.status === 'success') {
            // todo: need to notify the user this isn't allowed, but i am tired
            return {
              action: 'manual',
              scanImageId: null,
              xpageId: null,
            }
          } else {
            return {
              action: candidate?.status === 'blank' ? 'infer-blank' : 'manual',
              scanImageId: item.id,
              xpageId: candidate?.xpageId,
            }
          }
        } else {
          // todo: do something more useful...
          console.log('[fixPayload] Unexpected state', item)
        }
      })

    const isValid = fixPayload.every(
      (p) =>
        !!p.scanImageId &&
        (p.action === 'ignore' || p.action === 'infer-blank' || !!p.xpageId)
    )
    return { fixPayload, isValid }
  }, [isDirty, tableData])

  //selectedFixable?.candidates && console.log(selectedFixable.candidates)

  // Calculate edit state
  const editState: EditState =
    !selectedFixable || !ScanStatusSets.editable.has(selectedFixable.status)
      ? 'ineligible'
      : selectedFixable.changeState === 'none' &&
        selectedFixable.status !== 'no-qr'
      ? 'idle'
      : !scanFixState.candidateInputs?.xpageId &&
        !scanFixState.candidateInputs?.qrbComplete
      ? 'waiting-for-input'
      : candidateResult.isFetching
      ? 'waiting-for-results'
      : !selectedFixable.candidates?.length
      ? 'no-results'
      : isNaN(selectedFixable.changeState as any)
      ? 'waiting-for-choice'
      : !isValid
      ? 'invalid'
      : 'wating-for-submit'

  // Set of xpageIds that are targetted by this change
  const targetXpageIdSet = useMemo(() => {
    // workaround so the button feels responsive
    if (
      editState === 'waiting-for-results' &&
      scanFixState.candidateInputs?.xpageId
    ) {
      return new Set([scanFixState.candidateInputs?.xpageId])
    } else {
      return new Set(fixPayload?.map((p) => p.xpageId))
    }
  }, [editState, fixPayload, scanFixState.candidateInputs?.xpageId])

  return {
    ...scanFixState,
    candidateResult,
    editState,
    isDirty,
    isValid,
    fixPayload,
    panEntangler: panEntangler,
    pos,
    qrbPrefix,
    selectedFixable,
    selectPage,
    setChanges,
    setQuadrant,
    tableData,
    targetXpageIdSet,
  }
}

function maybeArray<T>(maybe: T | T[]): T[] {
  return Array.isArray(maybe) ? maybe : [maybe]
}

export function oneOrMore<T>(
  maybe: T | T[],
  predicate: (item: T) => boolean
): boolean {
  return Array.isArray(maybe) ? maybe.some(predicate) : predicate(maybe)
}
