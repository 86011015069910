import {
  Box,
  Button,
  Grid,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import { IcoAsterisk } from '@paper/icons'
import { FixitRosterItem } from '@paper/schema'
import { MISSING_COLOR } from '@paper/styles'
import { ENDASH, toXpageId } from '@paper/utils'
import { useVirtualizer } from '@tanstack/react-virtual'
import { memo, useRef } from 'react'
import { ScanlogPageGraph } from '~src/blocks/xpacketPageGraph/xpacketPageGraph'
import { Txt, VStack } from '~src/components'
import { useFixitContext } from './fixitProvider'
import { FixitRosterStatsAndExport } from './fixitRosterStats'
import { FixitSym } from './fixitSymbols'
import { RubricXViz } from './rubricXs'

type FixitRosterProps = {}

export function FixitRoster(props: FixitRosterProps) {
  const { onSelectStudent, sectioned } = useFixitContext()

  return (
    <Tabs
      colorScheme="gray"
      display="flex"
      flexDirection="column"
      isFitted={true}
      isLazy={true}
      size="sm"
    >
      <TabList>
        {sectioned.map(({ section }) => (
          <Tab fontSize="xs" key={section.id}>
            {section.name}
          </Tab>
        ))}
      </TabList>
      <TabPanels overflow="hidden">
        {sectioned.map((p) => (
          <TabPanel key={p.section.id} height="100%" p={0}>
            <VStack height="100%">
              <FixitRosterStatsAndExport
                roster={p}
                totalStudents={sectioned[0].stats.students}
              />
              <StudentList items={p.students} onSelect={onSelectStudent} />
            </VStack>
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  )
}

type StudentListProps = {
  items: FixitRosterItem[]
  onSelect: (id: string) => void
}

function StudentList(props: StudentListProps) {
  const { items, onSelect } = props
  const parentRef = useRef()
  const rv = useVirtualizer({
    count: items.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 24,
  })

  const scrollAdjPx = 6 // todo: is it miscalculating the size or is the scroll-iness caused by overflow

  return (
    <Box ref={parentRef} overflow="auto" width="100%">
      <Box height={`${rv.getTotalSize() + scrollAdjPx}px`} position="relative">
        {rv.getVirtualItems().map((vr) => {
          const item = items[vr.index]
          return (
            <Box
              key={vr.key}
              data-index={vr.index}
              display="flex"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: `${vr.size}px`,
                transform: `translateY(${vr.start}px)`,
              }}
            >
              <StudentItem data={item} onClick={onSelect} />
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

type StudentItemProps = {
  data: FixitRosterItem
  onClick(studentId: string): void
}

const StudentItem = memo(function StudentItem(props: StudentItemProps) {
  const { data, onClick } = props
  const hasFirstPage = !!data.sheets[0]?.[0]
  const hasScore = data.score != null
  const hasRubric = !!data.rubric

  let score = !hasFirstPage ? null : (
    <FixitSym.Score
      placement="bottom-start"
      score={data.score}
      status={hasScore ? 'success' : hasRubric ? 'empty' : 'unreadable'}
    />
  )

  let asterisk = data._dirty && (
    <Icon
      as={IcoAsterisk}
      aria-label="Unsaved changes"
      color="yellow.500"
      position="absolute"
      pointerEvents="none"
      top={0}
      right={0}
    />
  )

  const rubricSize = 16
  const endashBox = (color?: string) => (
    <Box color={color} opacity={0.4}>
      {ENDASH}
    </Box>
  )
  const scoreEndashBox = endashBox(MISSING_COLOR)
  const rubricEndashBox = endashBox()

  return (
    <Grid
      borderBottomWidth="1px"
      fontSize="xs"
      gap={2}
      placeItems="center"
      position="relative"
      templateColumns={`auto 24px ${rubricSize}px 1fr`}
      userSelect="none"
      width="100%"
    >
      <PlaceholderXpacketGraph data={data.sheets} />
      {score ?? scoreEndashBox}
      {hasRubric ? (
        <Box position="relative">
          <RubricXViz data={data.rubric} size={rubricSize} />
        </Box>
      ) : (
        rubricEndashBox
      )}
      <Button
        justifyContent="start"
        justifySelf="start"
        // todo: should get this value from the lookup map...
        isDisabled={!hasFirstPage}
        maxHeight="20px"
        onClick={() => onClick(data.student.id)}
        py={0}
        size="xs"
        variant="ghost"
      >
        <Txt as="span" isTruncated={true} textAlign="start" maxWidth="100px">
          {data.student.lastName}, {data.student.firstName}
        </Txt>
      </Button>
      {asterisk}
    </Grid>
  )
})

const equalsOrIsInSet = (value: string, strOrSet: string | Set<string>) => {
  return !strOrSet
    ? false
    : typeof strOrSet === 'string'
    ? value === strOrSet
    : strOrSet.has(value)
}

type PlaceholderXpacketGraphProps = {
  data: FixitRosterItem['sheets']
}

function PlaceholderXpacketGraph(props: PlaceholderXpacketGraphProps) {
  const sheets = props.data
  if (!sheets) {
    return <Box /> // todo: filling the grid slot...
  }
  const pages = sheets.flat()

  return (
    <ScanlogPageGraph
      columnCount={pages.length}
      data={pages.map((page, idx) => {
        const xpageId = toXpageId('todo:', idx)

        const props = {
          canSelectPage: null,
          highlightedXpageIdSet: null,
          onSelectPage: null,
          selectedXpageId: null,
          targetXpageId: null,
        }

        const isAssignTarget = equalsOrIsInSet(xpageId, props.targetXpageId)
        const isScanImageSelection = xpageId === props.selectedXpageId

        const onSelect = !props.canSelectPage?.(page)
          ? null
          : () => props.onSelectPage?.(xpageId)

        const arrow = null

        return {
          arrow,
          colorScheme: isAssignTarget
            ? 'blue'
            : !page
            ? 'scanMissingRed'
            : props.highlightedXpageIdSet?.has(xpageId)
            ? 'grayBtn'
            : 'scanPresentGray',
          onSelect,
          selected: isScanImageSelection,
          symbol:
            // page.fix?.by?.user === PAPER_BOT_USER
            //   ? 'A'
            //   : page.fix?.key
            //   ? 'M'
            //   :
            !arrow && !page ? 'X' : null,
          targeted: isAssignTarget,
        }
      })}
    />
  )
}
