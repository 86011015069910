import { BodyOf, ResultOf } from '@paper/api-specs'
import { UnionStringArray } from '@paper/utils'
import { useFormikContext } from 'formik'
import { orderBy, sumBy } from 'lodash'
import { useMemo } from 'react'

export const StickerFormUnits = ['sheet', 'sticker'] as const
const stickersPerSheet = 44 // todo:

type StickerLists = ResultOf<'sticker.validate'>

export type StickerFormShape = {
  _allLists: StickerLists
  /** calculate this here so it's available for the validator... */
  _errorCount: number
  _selectedListKeys: string[]
  _studentNumberText: string
  _studentNumberTextTrimmed: string
  _validationLoaded: boolean
  _validationPayload: BodyOf<'sticker.validate'>
  perStudent: number
  studentLists: StickerLists
  unit: UnionStringArray<typeof StickerFormUnits>
}

export const useStickerForm = () => {
  const formik = useFormikContext<StickerFormShape>()
  const { setValues, values } = formik

  /** Shallow merges `mergee` into form */
  const mergeValues = (mergee: Partial<StickerFormShape>) => {
    return setValues({ ...values, ...mergee })
  }
  const initStudentLists = (allLists: StickerFormShape['studentLists']) => {
    // select all by default
    const _allListKeys = allLists.map((p) => p.name)

    mergeValues({
      _allLists: allLists,
      _errorCount: sumBy(
        allLists,
        (p) => p.items.filter((p) => !p.student).length
      ),
      _selectedListKeys: _allListKeys,
      _validationLoaded: true,
      studentLists: allLists,
    })
  }

  const resetStudentStage = () => {
    mergeValues({
      _allLists: null,
      _selectedListKeys: null,
      _validationLoaded: null,
      _validationPayload: null,
      studentLists: null,
    })
  }

  const setSelectedLists = (keys: string[]) => {
    mergeValues({
      _selectedListKeys: keys,
      studentLists: values._allLists.filter((p) => keys.includes(p.name)),
    })
  }

  const setValidationPayload = (payload: BodyOf<'sticker.validate'>) => {
    mergeValues({ _validationPayload: payload })
  }
  const setStudentNumberText = (text: string) =>
    mergeValues({
      _studentNumberText: text,
      _studentNumberTextTrimmed: text?.trim(),
    })

  // provide an estimate of the page count as a sanity check for the user
  const pageCount = useMemo(() => {
    if (!values.studentLists?.length) {
      return NaN
    } else {
      const divisor = values.unit === 'sheet' ? 1 : stickersPerSheet
      // we'll assume that sheets split at the list-level
      return sumBy(values.studentLists, (p) =>
        Math.ceil(
          (p.items.filter((p) => !!p.student).length * values.perStudent) /
            divisor
        )
      )
    }
  }, [values.perStudent, values.studentLists, values.unit])

  const allStudents = useMemo(() => {
    let flat = values.studentLists?.flatMap((p) => p.items)
    // sort errors to the top
    let sorted = orderBy(flat, (p) => !!p.student)
    return sorted
  }, [values.studentLists])

  return {
    ...formik,
    allStudents,
    mergeValues,
    pageCount,
    resetStudentStage,
    initStudentLists,
    setSelectedLists,
    setStudentNumberText,
    setValidationPayload,
  }
}
