import { entries } from '@paper/utils'
import { createContext, ReactNode, useContext, useState } from 'react'
import { C2FamilyEd } from '../data-curriculumSetup'
import { CurriculumCreateDialog } from './dialogCreate'
import { CurriculumEditionDialog } from './dialogEdition'
import { CurriculumManageDialog } from './dialogManage'

const CurriculumSetupDialogs = {
  create: CurriculumCreateDialog,
  manage: CurriculumManageDialog,
  newedition: CurriculumEditionDialog,
}

type DialogKey = keyof typeof CurriculumSetupDialogs
type DialogState = { data: C2FamilyEd; dialog: DialogKey }
type DialogContext = {
  closeDialog(): void
  openDialog(dialogKey: DialogKey, data: C2FamilyEd): void
}
const DialogContext = createContext<DialogContext>(undefined)
export const useDialogContext = () => useContext(DialogContext)

type CurriculumSetupDialogProviderProps = { children: ReactNode }
export function CurriculumSetupDialogProvider(
  props: CurriculumSetupDialogProviderProps
) {
  const [dialogState, setDialogState] = useState<DialogState>()

  let dialogs = entries(CurriculumSetupDialogs).map(([key, Component]) => {
    return (
      <Component
        key={key}
        data={dialogState?.data}
        isOpen={dialogState?.dialog === key}
        onClose={() => setDialogState(null)}
      />
    )
  })

  const ctx: DialogContext = {
    closeDialog: () => setDialogState(null),
    openDialog: (dialogKey, data) =>
      setDialogState({ data, dialog: dialogKey }),
  }

  return (
    <DialogContext.Provider value={ctx}>
      {dialogs}
      {props.children}
    </DialogContext.Provider>
  )
}
