import {
  AuthDomain,
  Curriculum,
  CurriculumVariant,
  LoadMeta,
  School,
  SchoolYear,
  SetupCourse,
  TeacherSection,
} from '@paper/schema'
import { defineApi } from '../defineApi'

type AdminData = {
  admins: string[]
  domains: AuthDomain[]
  years: SchoolYear[]
}

export const setup_admin_get = defineApi<{
  method: 'get'
  result: AdminData
}>({
  method: 'get',
  urlFactory: `setup/admin`,
})

export const setup_admin_set = defineApi<{
  method: 'post'
  body: AdminData
  result: {}
}>({
  method: 'post',
  urlFactory: `setup/admin`,
})

export const setup_curriculm_get = defineApi<{
  method: 'get'
  result: Curriculum[]
}>({
  invalidateKey: 'setup_curriculum',
  method: 'get',
  urlFactory: `setup/curriculum`,
})

export const setup_curriculum_submit = defineApi<{
  method: 'post'
  body: Curriculum[]
  result: {}
}>({
  invalidateOnMutationSuccess: ['dir_cst', 'setup_curriculum'],
  method: 'post',
  urlFactory: `setup/curriculum`,
})

export const setup_issues = defineApi<{
  method: 'get'
  result: LoadMeta['errors']
}>({
  method: 'get',
  urlFactory: `setup/issues`,
})

export const setup_school_get = defineApi<{
  method: 'get'
  searchParams: { schoolId: string }
  result: SetupCourse[]
}>({
  invalidateKey: 'setup_school',
  method: 'get',
  urlFactory: `setup/school`,
})

export const setup_school_set = defineApi<{
  method: 'post'
  body: {
    id: string
    name: string
    // todo: i'm currently only using sections
    sections: { isEnabled: boolean; section: TeacherSection }[]
  }
  result: boolean
}>({
  invalidateOnMutationSuccess: ['dir_cst', 'setup_school'],
  method: 'post',
  urlFactory: `setup/school`,
})

export const setup_school_base = defineApi<{
  method: 'post'
  body: undefined
  result: {
    allCurricula: Curriculum[]
    schools: School[]
    syId: number
  }
}>({
  method: 'post',
  urlFactory: `setup/schoolbase`,
})

export const setup_variants_get = defineApi<{
  method: 'get'
  result: CurriculumVariant[]
}>({
  invalidateKey: `setup_curriculum_variants`,
  method: `get`,
  urlFactory: `setup/curriculum/variants`,
})

export const setup_variants_set = defineApi<{
  method: 'post'
  body: CurriculumVariant[]
  result: boolean
}>({
  invalidateOnMutationSuccess: [`setup_curriculum_variants`],
  method: `post`,
  urlFactory: `setup/curriculum/variants`,
})
