import { Button, ButtonGroup, ButtonProps } from '@chakra-ui/react'
import { IcoDownload, IcoLeftRight, IcoPrint, IcoQRCode } from '@paper/icons'
import { DirPacket, SchoolPacketGroup } from '@paper/schema'
import { itemFromSet } from '@paper/utils'
import * as Popover from '@radix-ui/react-popover'
import { ReactNode, useState } from 'react'
import { HSep, VStack } from '~src/components'
import { FullPageLoading, LoadingDots } from '~src/components/status'
import { FullPageMessage } from '~src/components/status/fullPage'
import { useSchoolYearContext } from '~src/schoolYearAirlock'
import { pluralS } from '~src/utils/messages'
import { MenuBody, useMenu } from '../menu'
import { SupportButtons } from '../miSupport'
import { expandeeBtnProps } from '../packetTable/expandeeUtils'
import { useTeacherContext } from '../teacherAirlock'
import { PdfBuilderInputItem } from './data-pdfBuilder'
import { PdfPrintError } from './pdfBuilderError'
import { PdfBuilderProvider, usePdfBuilderContext } from './pdfBuilderProvider'
import {
  PdfPrintGenerate,
  useRegenerateCheck,
} from './printDialogGenerateButton'
import { PrintPdfTeacherDownload } from './printDialogHistory'
import { DialogSubsection } from './printDialogLayout'
import { PdfPrintStats } from './printDialogPdfStats'

type TeacherPrintDialogProps = { packet: DirPacket }

export function TeacherPrintDialog(props: TeacherPrintDialogProps) {
  const { packet } = props
  const { curriculum, teacher, teacherRecord } = useTeacherContext()
  // todo: paper currently assumes teacher is associated with one school
  const schoolId = itemFromSet(teacherRecord.schoolIds)
  // const { curriculum, teacher } = useLookupInDirData(item) <- from when this was school + teacher

  ////////////////////
  // convert to packet group
  const packetGroup: SchoolPacketGroup = {
    id: packet.number,
    number: packet.number,
    packets: [packet],
  }

  ////////////////////
  // menu props
  const { menuButtonProps, onOpenChange, open, shroud } = useMenu({
    caret: false,
    defaultOpen: false,
    shroud: true,
  })

  ////////////////////
  // spec for the packet
  const spec = { curriculum, packet, teacher } satisfies PdfBuilderInputItem
  const pdfCount = packet.parts.length

  const { isCurrentYear } = useSchoolYearContext()

  return (
    <Popover.Root modal={false} onOpenChange={onOpenChange} open={open}>
      <Popover.Trigger asChild>
        <Button
          data-cy="pdf-dialog-trigger"
          {...menuButtonProps}
          {...expandeeBtnProps}
          isDisabled={!isCurrentYear}
          leftIcon={<IcoPrint />}
        >
          Generate {pluralS('PDF', pdfCount)} for printing
        </Button>
      </Popover.Trigger>
      {shroud}
      <PdfBuilderProvider
        data={spec}
        grain="teacher"
        isActive={open}
        packetGroup={packetGroup}
        parentView="teacher"
        schoolId={schoolId}
      >
        <PrintDialog />
      </PdfBuilderProvider>
    </Popover.Root>
  )
}

function PrintDialog() {
  const ctx = usePdfBuilderContext()
  const { hasInflight, isLoading, selected } = ctx
  const isntReady = isLoading || !selected // todo: unify
  const [_activePane, setActivePane] = useState<'download' | 'generate'>()
  const regenerateCheck = useRegenerateCheck()

  let body: ReactNode
  if (isntReady) {
    body = <LoadingDots display="block" />
  } else {
    const activePane =
      _activePane ?? (selected.currentManifest ? 'download' : 'generate')

    let pane: ReactNode
    let switchProps: ButtonProps

    if (activePane === 'download') {
      pane = (
        <DialogSubsection icon={IcoDownload} title="Download">
          <PrintPdfTeacherDownload />
        </DialogSubsection>
      )
      switchProps = {
        children: 'Switch to Generate',
        isDisabled: selected.status === 'in-progress',
        onClick: () => setActivePane('generate'),
      }
    } else {
      pane = (
        <DialogSubsection icon={IcoQRCode} title="Generate">
          <PdfPrintStats />
          {regenerateCheck.element}
          <PdfPrintGenerate
            isDisabled={isntReady || regenerateCheck.shouldBeDisabled}
            onClick={() => {
              // reset
              regenerateCheck.reset()
              setActivePane(null)
            }}
          />
          <PdfPrintError />
        </DialogSubsection>
      )
      switchProps = {
        children: 'Switch to Download',
        isDisabled: !selected.currentManifest, // disable if nothing to download
        onClick: () => setActivePane('download'),
      }
    }

    body = (
      <VStack alignItems="stretch">
        {pane}
        <HSep boxProps={{ marginTop: 'auto' }} />
        <ButtonGroup p={2} size="xs" variant="outline">
          <SupportButtons />
          <Button
            data-cy="btn-switch-pane"
            leftIcon={<IcoLeftRight />}
            ml="auto!important"
            {...switchProps}
          />
        </ButtonGroup>
      </VStack>
    )
  }

  // console.log('[printDialog]', ctx)

  return (
    <FullPageLoading
      loadMsg={
        <FullPageMessage.Text textAlign="center" width="400px">
          Generating PDFs
        </FullPageMessage.Text>
      }
      qResult={{ isPending: hasInflight }}
      type="transparent"
    >
      <Popover.Portal>
        <Popover.Content asChild sideOffset={8} collisionPadding={16}>
          <MenuBody width="400px" py={0}>
            {body}
          </MenuBody>
        </Popover.Content>
      </Popover.Portal>
    </FullPageLoading>
  )
}
