import { useStateAndRef } from '@paper/route/src/utils'
import { useCallback, useRef, useState } from 'react'
import { OnJpeg, PdfViewer } from '~src/blocks/pdfViewer'

export type CaptureProps = {
  initialPageNum?: number
  interval?: number
  maxPage: number
  onCaptured: (images: Blob[]) => void
  pdfUrl: string
}

export function PdfImageCapture(props: CaptureProps) {
  const { onJpeg, pageNumCursor } = useCaptureImagesOnce(props)

  return (
    <PdfViewer
      height="100%"
      id="wizard"
      onJpeg={onJpeg}
      pageIndexPlus1={pageNumCursor}
      url={props.pdfUrl}
      width="100%"
    />
  )
}

function useCaptureImagesOnce(props: CaptureProps) {
  const { initialPageNum = 1, interval, maxPage, onCaptured } = props

  const imagesRef = useRef<Blob[]>(Array(maxPage).fill(undefined))
  const [captureDone, setCaptureDone, captureDoneRef] = useStateAndRef(false)
  const [pageNumCursor, setPageNumCursor] = useState(initialPageNum)

  const packetLength = maxPage - initialPageNum + 1

  // Callback for PDF jpegs
  const onJpeg = useCallback<OnJpeg>(({ blob, pageNumber }) => {
    // Add image if within page interval
    // PDFViewer renders an extra page which was previously erroneously included (#69)
    if (pageNumber <= maxPage) {
      imagesRef.current[pageNumber - initialPageNum] = blob
    }

    // Check if we have all the images
    let haveAllImages = imagesRef.current.every((p) => p)
    if (haveAllImages) {
      // pdfjs laods multiple pages so this can fire more than once
      if (!captureDoneRef.current) {
        setCaptureDone(true)
        // Be extra careful due to race condition bug (#69)
        // where extra images were getting included
        onCaptured(imagesRef.current.slice(0, packetLength))
      }
    } else if (pageNumber < maxPage) {
      // Cycle through pages (with a delay) as we get the jpegs
      if (pageNumber < maxPage) {
        setTimeout(() => {
          setPageNumCursor(pageNumber + 1)
        }, interval)
      }
    }
  }, [])
  return { captureDone, onJpeg, pageNumCursor }
}
