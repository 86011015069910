import { Button, Tooltip } from '@chakra-ui/react'
import { DiffedQ, Question } from '@paper/schema'
import { SimplePopup } from '~src/blocks/menu/simplePopup'
import { IconColumnProps } from '~src/blocks/packetTable/tableColUtils'
import { Txt } from '~src/components'
import { UghColumn, UghTable } from '~src/components/table'
import {
  IQLabel,
  IQOutOf,
  IQResponses,
  IQStdsSummary,
  IQType,
  makeQTableCell,
  QuestionImportDetails,
} from './formQuestionsDetails'
import { MatchIcon, ValidationIcon } from './formQuestionStatusIcons'

type QuestionTableProps = {
  data: (DiffedQ | Question)[]
  mode: 'display' | 'import'
}

export const QuestionTable = (props: QuestionTableProps) => {
  const { data, mode } = props
  const columns = useFormQuestionsTableCols(mode)
  return (
    <UghTable
      aria-label="Questions"
      columns={columns}
      data={data ?? []}
      spacingY="snug"
    />
  )
}

type Col = UghColumn<DiffedQ>

const useFormQuestionsTableCols = (mode: 'display' | 'import') => {
  const labelCol: Col = {
    props: { dontElipsize: true, width: 60 },
    label: () => 'Label',
    cell: makeQTableCell(IQLabel),
  }

  const typeCol: Col = {
    props: IconColumnProps,
    label: () => 'Type',
    cell: makeQTableCell(IQType),
  }

  const responsesCol: Col = {
    props: { align: 'start', width: 240 },
    label: () => 'Responses',
    cell: makeQTableCell(IQResponses),
  }
  const outOfCol: Col = {
    props: { dontElipsize: true, width: 60 },
    label: () => (
      // todo: probably stick this in a component for consistent placement
      <Tooltip label="Points the question is out of" placement="top">
        <Txt userSelect="none">/ Pts</Txt>
      </Tooltip>
    ),
    cell: makeQTableCell(IQOutOf),
  }
  const stdCol: Col = {
    props: { align: 'start' },
    label: () => 'Standards',
    cell: makeQTableCell(IQStdsSummary),
  }

  let cols = [labelCol, typeCol, outOfCol, responsesCol, stdCol]

  // my current guess is that we won't allow errors post-import, so this would always be empty
  if (mode === 'import') {
    const errorCol: Col = {
      props: { ...IconColumnProps, width: 48 },
      label: () => 'Status',
      cell: (item) => (
        <SimplePopup
          side="right"
          trigger={(menuButtonProps) => (
            <Button
              {...menuButtonProps}
              aria-label="Diff and validation details"
              fontSize="md"
              gap={0.5}
              px={1.5}
              size="sm"
              variant="ghost"
            >
              <MatchIcon status={item.status} />
              <ValidationIcon isValid={item.isValid} />
            </Button>
          )}
        >
          <QuestionImportDetails data={item} />
        </SimplePopup>
      ),
    }
    cols.unshift(errorCol)
  }

  return cols
}
