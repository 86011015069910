import type { PacketType, PublishStage } from '@paper/schema'
import { z, zenum } from '@paper/schema/validation'

/**
 * Shared by all home routes
 */
const z_home_base = z
  .object({
    curriculumId: z.string().nullable(), // todo: temporary-ish for crossNetwork link
    packetId: z.string(),
    pgId: z.string(),
  })
  .partial()

/**
 * Shared by curriculum and teacher home routes
 */
const z_home_base_tea_cur = z_home_base
  .extend({
    pl_filterText: z.string(),
    pl_packetType: zenum<PacketType>(['assessment', 'ticket']),
    pl_printStatus: z.enum(['not', 'printed', 'radioactive']),
  })
  .partial()

/**
 * Teacher home
 */
export const z_home_teacher = z_home_base_tea_cur.extend({
  pl_scanStatus: z.enum(['scanned', 'partial', 'unnamed']).optional(),
  schoolId: z.string().optional(),
  teacherId: z.string().optional(),
})

/**
 * Curriculum home
 */
export const z_home_curriculum = z_home_base_tea_cur.extend({
  pl_pub: zenum<PublishStage>([
    'creating',
    'uploaded',
    'published',
    'recalled',
  ]).optional(),
})

/**
 * School home
 */
export const z_home_school = z_home_base.extend({
  schoolId: z.string().optional(),
  teacherId: z.string().optional(),
})

export type RD_Home_Base = z.infer<typeof z_home_base>
export type RD_Home_Teacher = z.infer<typeof z_home_teacher>
export type RD_Home_Curriculum = z.infer<typeof z_home_curriculum>
export type RD_Home_School = z.infer<typeof z_home_school>
export const PacketFilterKeys = Object.keys(z_home_teacher.shape).filter(
  (key) => key.startsWith('pl_') // todo: maybe better to specify explicitly?
)
