import { Button } from '@chakra-ui/react'
import { IcoArrowBack, IcoRefresh } from '@paper/icons'
import { ErrorActionSection, ErrorPage } from './errorPageBase'
import { getEAProps } from './errorUtils'

type UncaughtErrorProps = {
  error?: Error & { friendly?: string }
}

// todo: very copy/paste-y with the other error pages
export function UncaughtErrorPage(props: UncaughtErrorProps) {
  return (
    <ErrorPage
      actions={
        <ErrorActionSection message={`❓ You can also try:`}>
          <Button
            {...getEAProps(<IcoArrowBack />)}
            // todo: not sure this updates the url properly...
            onClick={() => window.history.back()}
          >
            Go back
          </Button>
          <Button
            {...getEAProps(<IcoRefresh />)}
            // todo: not sure this updates the url properly...
            onClick={() => window.location.reload()}
          >
            Reload the page
          </Button>
        </ErrorActionSection>
      }
      actionsGoAfter={true}
      contactSectionMsg={`🐛 Looks like you've encountered a bug!`}
      defaultFriendly={`Something unexpected happened`}
      error={props.error}
      subject={`👹 🕹️ 🖥️ 💥 🤷‍♀️ 😳`}
    />
  )
}
