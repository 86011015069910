import {
  Box,
  BoxProps,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  IconButtonProps,
  useDisclosure,
} from '@chakra-ui/react'
import { APIs, BodyOf } from '@paper/api-specs'
import { IcoDrawer, IcoLeftRight, IcoLink, IcoPin } from '@paper/icons'
import { useLink, useRouter } from '@paper/route'
import { PinQCell, XpacketSW } from '@paper/schema'
import { CORRECT_COLOR } from '@paper/styles'
import { useVirtualizer } from '@tanstack/react-virtual'
import { maxBy } from 'lodash'
import { useEffect, useMemo, useRef } from 'react'
import { useSWContext } from '~src/blocks/swContext'
import { BLink, TooltippedIconButton, Txt } from '~src/components'
import { useApiMutation, useApiQuery } from '~src/data/useApiQuery'
import { RD_SW_JumpToQ, Routes } from '~src/routelist'
import { useSchoolYearContext } from '~src/schoolYearAirlock'
import { getTeaAxisId } from '../../pinGrid/pinGridAirlock'
import { useQStdContext } from './bargraph/qStdProvider'

function usePinData() {
  const { routeData } = useRouter<RD_SW_JumpToQ>()
  const { syId } = useSchoolYearContext()
  const { packetId, teacherId } = routeData

  const qResult = useApiQuery({
    apiSpec: APIs['pin.list'],
    queryVars: { body: { packetId, syId, teacherId } },
    useQueryProps: { enabled: !!teacherId },
  })

  return qResult
}

function usePinStatus(xpacket: XpacketSW) {
  const swCtx = useSWContext()
  const qStdCtx = useQStdContext()
  const { syId } = useSchoolYearContext()

  const curQ = qStdCtx?.qDigest.success?.selectedItem
  const xQ = xpacket?.qs?.[curQ?.qIndex]

  // todo: also need to handle error properly #201 (no pin data because of an error is different than it being empty)
  const pinQResult = usePinData()
  const pinData = pinQResult.data // todo: probably hoist, but i'm lazy
  const pinQIdSet = new Set(pinData?.map((p) => p.qId) ?? [])

  const aStr = curQ?.type === 'GRID' ? xQ?.pts?.toString() : xQ?.filledStr // todo: need to centralize this logic

  const pinnedId =
    !!xQ &&
    !!xpacket &&
    // todo: maybe turn into a lookup
    pinData?.find((p) => p.qId === curQ.id)?.answers[aStr]?.pinnedId

  const isPinned = xpacket && pinnedId === xpacket?.id
  const isSlotFilledByOther = pinnedId && !isPinned

  const pinPayload: BodyOf<'pin.set'> = {
    aStr,
    // todo: do i actually need contentId?
    contentId: swCtx?.packet?.contentId,
    curriculumId: swCtx?.packet?.curriculumId,
    packetId: swCtx.packet?.id,
    pinnedId: xpacket?.id,
    // todo: exclude qId if not in pinData set as a workaround to prevent pinning questions without data
    qId: pinQIdSet.has(curQ?.id) && curQ?.id,
    syId: syId,
    teacherId: swCtx.teacher?.id,
  }

  const isPinEligible = !!pinData?.length

  const canPin = swCtx.can.pin && isPinEligible

  return {
    aStr: xQ?.filledStr,
    canPin,
    isError: pinQResult.isError,
    isPinned,
    isPinEligible, // todo: this could be confusing since some Q types aren't pin eligible
    isSlotFilledByOther,
    pinData,
    pinPayload,
    showPinButton: canPin || isPinned,
  }
}

type PinStatus = ReturnType<typeof usePinStatus>

// todo: probably better to contextify this so i don't have to pass it...
type PinDrawerProps = { xpacket: XpacketSW }

export function PinDrawer(props: PinDrawerProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef()

  const pinStatus = usePinStatus(props.xpacket)
  const drawerIcon = !pinStatus.showPinButton ? (
    <>
      <IcoPin />
      <IcoDrawer />
    </>
  ) : (
    <IcoDrawer />
  )

  const cantPinMsg = (pinStatus.isError || !pinStatus.isPinEligible) && (
    <Txt fontStyle="italic" opacity={0.8}>
      {pinStatus.isError
        ? `Something went wrong grabbing pin data` // todo: contact support/rollbar probably
        : `Pinning is only available for packets with Illuminate score data`}
    </Txt>
  )

  return (
    <>
      <Box position="absolute" top="5.5rem" right="1.5rem">
        <PinButton isRound={true} size="lg" pinStatus={pinStatus} />
        <IconButton
          aria-label="open pin drawer"
          icon={drawerIcon}
          isRound={true}
          ml="-16px"
          onClick={onOpen}
          ref={btnRef}
          size="sm"
          variant="outline"
          position="absolute"
          top="2.5rem"
        />
      </Box>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader fontWeight={300}>
            Pin slots{' '}
            {pinStatus.isPinEligible && (
              <EveryonesPinsLink pinPayload={pinStatus.pinPayload} />
            )}
          </DrawerHeader>
          <DrawerBody position="relative">
            {cantPinMsg || <TheOtherPinGrid pinData={pinStatus.pinData} />}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

function EveryonesPinsLink({ pinPayload }: Pick<PinStatus, 'pinPayload'>) {
  const packet = useSWContext().packet
  const teaId = getTeaAxisId(pinPayload)
  const linkProps = useLink(
    Routes.crossNetwork.mergeAction({
      aStr: pinPayload.aStr,
      contentId: packet?.contentId,
      teaId,
    })
  )

  return !packet ? null : (
    <BLink
      leftIcon={<IcoLink />}
      {...linkProps}
      ml={1}
      size="xs"
      variant="ghost"
    >
      See everyone's pins
    </BLink>
  )
}

const estimateSize = () => 40

function TheOtherPinGrid(props: { pinData: PinQCell[] }) {
  const parentRef = useRef()
  const { pinData } = props

  const { dispatchStay, routeData } = useRouter<RD_SW_JumpToQ>()
  const { f_ans, qId } = routeData

  const rv = useVirtualizer({
    count: pinData?.length ?? 0,
    estimateSize,
    getScrollElement: () => parentRef.current,
  })

  const { answerArrays, widths } = useMemo(() => {
    const answerArrays = pinData?.map((p) => Object.values(p.answers))
    const qCol = 48
    const aColMax = 48
    const aColMin = 30 // todo: could be dynamic? e.g. ABCD requires more space than ABC
    const maxCols = maxBy(answerArrays, (aa) => aa.length)?.length
    const row = qCol + maxCols * (aColMax + 4)
    return { answerArrays, widths: { aColMax, aColMin, qCol, row } }
  }, [pinData])

  // scroll into view
  const selectedIndex = useMemo(() => {
    return !qId ? -1 : pinData?.findIndex((p) => p.qId === qId)
  }, [pinData, qId])

  // scroll to selected
  useEffect(() => {
    if (selectedIndex >= 0) {
      rv.scrollToIndex(selectedIndex)
    }
  }, [selectedIndex])

  return (
    <Box
      display="flex"
      fontSize="xs"
      height="100%"
      justifyContent="center"
      overflowY="auto"
      px={2}
      ref={parentRef}
    >
      <Box
        role="presentation"
        style={{ height: rv.getTotalSize(), position: 'relative' }}
        width={widths.row}
      >
        {rv.getVirtualItems()?.map((vi) => {
          const q = pinData[vi.index]
          const answerArr = answerArrays[vi.index]
          const isSelected = vi.index === selectedIndex
          const isBotBorderSelected =
            isSelected || vi.index === selectedIndex - 1

          const borderColor = 'gray.20'
          const cellProps: BoxProps = {
            alignItems: 'center',
            borderBottomColor: isBotBorderSelected ? 'blue.200' : borderColor,
            borderBottomWidth: isBotBorderSelected ? '2px' : '1px',
            borderColor,
            borderEndWidth: '1px',
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
          }

          return (
            <Box
              key={vi.key}
              role="presentation"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: `${vi.size}px`,
                transform: `translateY(${vi.start}px)`,
                transitionDuration: '.4s',
                transitionProperty: 'height top',
              }}
            >
              <Box
                alignItems="stretch"
                display="grid"
                gridTemplateColumns={`${widths.qCol}px repeat(${answerArr.length}, minmax(${widths.aColMin}px, ${widths.aColMax}px))`}
                height="100%"
                justifyItems="stretch"
              >
                <Box {...cellProps}>{q.qLabel}</Box>
                {answerArr.map(({ aStr, correct, count, pinnedId }) => {
                  return (
                    <Box
                      {...cellProps}
                      color="gray.500"
                      key={aStr}
                      textAlign="center"
                    >
                      {pinnedId && (
                        <IconButton
                          aria-label="Go to pin"
                          icon={<IcoPin />}
                          isRound={true}
                          key="pin"
                          onClick={() =>
                            dispatchStay({
                              f_ans: f_ans === aStr ? aStr : null,
                              qId: q.qId,
                              xpacketId: pinnedId,
                            })
                          }
                          size="sm"
                          variant="ghost"
                        />
                      )}
                      {count && (
                        <Txt
                          color={correct ? CORRECT_COLOR : 'gray.500'}
                          fontSize=".6rem"
                          fontWeight="400"
                          key="aStr"
                          opacity={0.8}
                          pointerEvents="none"
                          position="absolute"
                          top={0}
                          right="2px"
                        >
                          {correct ? '✱' : ''}
                          {aStr}
                        </Txt>
                      )}
                    </Box>
                  )
                })}
              </Box>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

function PinButton(
  props: Omit<IconButtonProps, 'aria-label' | 'icon' | 'onClick'> & {
    pinStatus: PinStatus
  }
) {
  const { pinStatus, ...boxProps } = props
  const { isPinned, isSlotFilledByOther, pinPayload, showPinButton } = pinStatus

  const mutation = useApiMutation({
    apiSpec: APIs['pin.set'],
    useMutationProps: {
      onSuccess() {
        mutation.reset()
      },
    },
  })

  const hasAllValues = Object.values(pinPayload).every((p) => p)

  const tooltip = isPinned
    ? 'Pinned'
    : isSlotFilledByOther
    ? 'Pin this answer, replacing the previous pin'
    : 'Pin this answer'

  const icon = isSlotFilledByOther ? (
    <>
      <IcoPin />
      <IcoLeftRight />
    </>
  ) : (
    <IcoPin />
  )

  return !showPinButton ? null : (
    <TooltippedIconButton
      {...boxProps}
      aria-label={tooltip}
      bg={isPinned ? 'blue.200' : null}
      colorScheme={mutation.error ? 'red' : null}
      icon={icon}
      isDisabled={!hasAllValues}
      isLoading={mutation.isPending}
      onClick={() => mutation.mutate(pinPayload)}
      pointerEvents={isPinned ? 'none' : null} // todo: better solution for can't pin twice
      variant={isPinned ? 'solid' : 'outline'}
    />
  )
}
