import { useRouter } from '@paper/route'
import { PacketAxisItem } from '@paper/schema'
import { useCallback, useEffect, useMemo } from 'react'
import { RD_SW_Time } from '~src/routelist'

export type SortOrder = 'asc' | 'desc'
export type ColSortBase = { id: string; order: SortOrder }
type ColSortFull = ColSortBase & { label: string }
export type ColSortContext = {
  colSort: ColSortFull[]
  colSortMap: Map<string, ColSortFull>
  setColSort: (list: ColSortBase[]) => void
}

// todo: rename - this is sorting students...
export const useColSortAirlock = (items: PacketAxisItem[]): ColSortContext => {
  const { dispatchStay, routeData } = useRouter<RD_SW_Time>()
  const { tgs_rowsBy } = routeData

  // Parse tgs_rowsBy for sorting columsn
  // todo: not sure how stable the `items` reference is such that memo may be pointless...
  const { colSort, colSortMap, invalid } = useMemo(() => {
    const rawList = parseQPArray(tgs_rowsBy)
    const validatedList = rawList
      .map((raw): ColSortFull => {
        const [id, order] = raw.split(':') as [string, ColSortBase['order']]
        const label = items.find((p) => p.id === id)?.number
        return { id, label, order }
      })
      .filter(
        (p) => p.id && p.label && (p.order === 'asc' || p.order === 'desc')
      )

    const result = {
      colSort: validatedList,
      colSortMap: new Map(validatedList.map((p) => [p.id, p])),
      invalid: rawList.length !== validatedList.length,
    }
    //console.log('(4.x) colSort', result)
    return result
  }, [items, tgs_rowsBy])

  const setColSort = useCallback((list: ColSortBase[]) => {
    dispatchStay({
      tgs_rowsBy: list.length ? list.map((p) => `${p.id}:${p.order}`) : null,
    })
  }, [])

  // Airlock
  useEffect(() => {
    if (invalid) {
      setColSort(colSort)
    }
  }, [colSort, invalid])

  return { colSort, colSortMap, setColSort }
}

/** The querystring library (I think) doesn't know that a value is intended to be an array */
function parseQPArray(qp: string | string[]): string[] {
  if (!qp) {
    return []
  } else if (typeof qp === 'string') {
    return [qp]
  } else {
    return qp
  }
}
