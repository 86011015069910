import { ReactNode } from 'react'
import { TableLayout } from '~src/blocks/tableHelpers'

type HomePanelProps = {
  filters?: ReactNode
  name: string
  pickers: ReactNode
  table: ReactNode
}

// may eventually need more slot composition flexibility
export function HomePanel(props: HomePanelProps) {
  const { name, ...rest } = props
  return <TableLayout gridProps={{ pt: 2 }} title={[name, 'Home']} {...rest} />
}
