import {
  Box,
  Button,
  ButtonGroup,
  ButtonGroupProps,
  ButtonProps,
} from '@chakra-ui/react'
import { KeyCap } from '~src/blocks/keyboardInstructions'
import { useKeyboard } from '~src/utils/useKeyboard'

type KeyNButtonGroupProps = Omit<ButtonGroupProps, 'children'> & {
  actions: KeyNButtonProps[]
  buttonProps?: Omit<ButtonProps, 'onClick'>
}

export function KeyNButtonGroup(props: KeyNButtonGroupProps) {
  const { actions, buttonProps = {}, isDisabled, orientation, ...rest } = props

  let orientProps: ButtonGroupProps =
    orientation === 'vertical' || !rest.isAttached
      ? {
          alignItems: 'stretch',
          flexDirection: 'column',
          sx: { '> *': { marginInlineStart: '0!important' } },
        }
      : {}

  return (
    <ButtonGroup
      isAttached={orientation !== 'vertical'}
      isDisabled={isDisabled}
      gap={0.5}
      {...orientProps}
      {...rest}
    >
      {actions.map((a) => {
        let { children, ...rest } = a
        // flex-grow child so keys align
        if (orientation === 'vertical') {
          children = <Box flexGrow={1}>{children}</Box>
        }
        return (
          <KeyNButton
            key={a.keyChar}
            isDisabled={isDisabled}
            {...rest}
            {...buttonProps}
          >
            {children}
          </KeyNButton>
        )
      })}
    </ButtonGroup>
  )
}

export type KeyNButtonProps = Omit<ButtonProps, 'onClick'> & {
  keyChar: string
  onClick(): void
}

function KeyNButton(props: KeyNButtonProps) {
  const { keyChar, isDisabled, onClick, ...rest } = props
  useKeyboard(
    keyChar,
    (event) => {
      // todo: maybe memoize
      // without this, the keypress ends up in the subsequently rendered <input/>
      event.preventDefault()
      onClick()
    },
    isDisabled ? 'none' : 'keypress'
  )

  const icon = getKeyNButtonIcon(keyChar)

  return (
    <Button
      {...rest}
      isDisabled={isDisabled}
      onClick={onClick}
      rightIcon={icon}
    />
  )
}

export const getKeyNButtonIcon = (keyChar: string) => {
  let iconLabel = keyChar === 'Enter' ? '⏎' : keyChar.slice(0, 3)
  return <KeyCap keyChar={iconLabel} />
}
