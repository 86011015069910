import { shallowEqual } from '@paper/utils'
import isEqual from 'lodash/isEqual'
import { useRef } from 'react'

type ObjOrArr = object | []

export function useShallowMemo<T extends ObjOrArr>(objOrArr: T) {
  const ref = useRef<T>()
  if (!shallowEqual(objOrArr, ref.current)) {
    ref.current = objOrArr
  }
  return ref.current
}

export function useDeepMemo<T extends ObjOrArr>(objOrArr: T) {
  const ref = useRef<T>()
  if (!isEqual(objOrArr, ref.current)) {
    ref.current = objOrArr
  }
  return ref.current
}
