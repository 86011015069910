import { Box } from '@chakra-ui/react'
import { IcoRuGood, IcoTimeGrid } from '@paper/icons'
import { RouteMeta, useLink } from '@paper/route'
import { DirPacket, PacketType } from '@paper/schema'
import { pathToDataCy } from '@paper/utils'
import { IBLink, IBLinkProps } from '~src/components'
import { Routes } from '~src/routelist'
import { useTeacherContext } from '../teacherAirlock'
import { SIBP } from './tableColUtils'

type ViewLinkProps = {
  ctx: DirPacket
  mayNotHaveScans?: boolean
  route: RouteMeta<any>
} & Omit<IBLinkProps, 'href' | 'onClick' | 'type'>

export function ViewLink(props: ViewLinkProps) {
  const { ctx, mayNotHaveScans, route, ...rest } = props
  // todo: injecting contentId is a bad api
  const linkProps = useLink(
    route.mergeAction({
      contentIdOrNew: ctx.contentId,
      curriculumId: ctx.curriculumId,
      packetId: ctx.id,
      teacherId: ctx.teacherId,
    })
  )
  return (
    <IBLink
      data-cy={pathToDataCy('vl', route.path)}
      {...SIBP}
      {...rest}
      {...linkProps}
      opacity={mayNotHaveScans ? 0.65 : null}
    />
  )
}

type ViewBtnProps = Omit<ViewLinkProps, 'action' | 'ctx'>

type ViewKey = 'time' | 'q' | 'std' | 'rubric'

const slots: Record<PacketType, readonly ViewKey[]> = {
  assessment: ['q', 'std', 'time'],
  ticket: ['time', 'rubric'],
} as const

export function ViewNavCell(props: { item: DirPacket }) {
  const { item } = props

  const hasScansForSure = !!item.scan.count

  // todo: (pre-#289) need some other plan for curriculum view where this won't be known
  // todo: (#289) show Q and S even if no official scans as workaround for #289
  // todo: (#289) tickets will have the same problem, but this is a stopgap
  if (!item.scan.count && item.type === 'ticket') {
    return null
  }

  const views: Record<ViewKey, ViewBtnProps> = {
    q: {
      'aria-label': 'Jump to question',
      icon: 'Q',
      route: Routes.sw_jumpToQ,
    },
    std: {
      'aria-label': 'Jump to standard',
      icon: 'S',
      route: Routes.sw_jumpToStd,
    },
    time: {
      'aria-label': 'Time grid',
      icon: <IcoTimeGrid />,
      route: Routes.sw_time,
    },
    rubric: {
      'aria-label': 'Rubric review',
      icon: <IcoRuGood />,
      route: Routes.sw_rubric,
    },
  } as const

  // todo: hoist more of this!
  let enabledMap: Record<ViewKey, boolean> = {
    q: item.questions?.length > 0,
    std: item.stds?.length > 0,
    time: !!hasScansForSure, // todo: b/c the time view currently filters on this
    rubric: item._isTicketPilot,
  }
  let links = slots[item.type]
    .map((key) => (enabledMap[key] ? views[key] : null))
    .filter((p) => p) // slots with empty spaces vs. fit what's there?

  return (
    <Box
      alignItems="center"
      display="grid"
      gridTemplateColumns={`repeat(${links.length}, 1fr)`}
      justifyContent="center"
    >
      {links.map((linkProps, idx) => (
        <ViewLink
          gridColumn={idx + 1}
          mayNotHaveScans={!hasScansForSure}
          {...linkProps}
          key={idx}
          ctx={item}
        />
      ))}
    </Box>
  )
}
