import { Box, BoxProps, IconButton, Tooltip } from '@chakra-ui/react'
import { IcoCheckmark, IcoCsv, IcoFiThisUser, IcoScanner } from '@paper/icons'
import { DirPacket } from '@paper/schema'
import { GRAY_150, GRAY_HAS_SCANS } from '@paper/styles'
import { getFullName, slugify } from '@paper/utils'
import { saveAs } from 'file-saver'
import { HStack } from '~src/components'
import { formatScore } from '~src/utils/messages'
import { BadBars } from './badBars'
import { FixitRoster, FIXIT_ALL_SECTION_ID } from './data-fixit-sectioned'
import { useFixitContext } from './fixitProvider'

type FixitRosterStatsAndExportProps = {
  roster: FixitRoster
  totalStudents: number
}

export function FixitRosterStatsAndExport(
  props: FixitRosterStatsAndExportProps
) {
  const { roster, totalStudents } = props

  const chart = (
    <BadBars
      bars={[
        {
          color: GRAY_150,
          name: 'Students',
          Icon: IcoFiThisUser,
          value: roster.stats.students,
        },
        {
          color: GRAY_HAS_SCANS,
          name: 'Students with a full set of scans',
          Icon: IcoScanner,
          value: roster.stats.scans,
        },
        {
          color: 'blue.500',
          name: 'Students with scores',
          Icon: IcoCheckmark,
          value: roster.stats.score,
        },
      ]}
      denom={totalStudents}
      fontSize=".75rem"
      height={54}
      maxBarWidth={160}
      width={200}
    />
  )

  return (
    <HStack gap={1} py={4}>
      {chart}
      <ExportCsvButton roster={roster} />
    </HStack>
  )
}

type ExportCsvButtonProps = { roster: FixitRoster }

function ExportCsvButton(props: ExportCsvButtonProps) {
  const { dirty, packet } = useFixitContext()
  const { roster } = props
  // todo: tooltip explanation on dirty
  const label = dirty
    ? 'Please save your changes before exporting'
    : !roster.stats.score
    ? 'No scores to export'
    : 'Export scores to CSV'

  const positionProps: BoxProps = {
    alignSelf: 'end',
  }

  return (
    <Tooltip label={label} textAlign="center" maxWidth="190px">
      <Box {...positionProps}>
        <IconButton
          aria-label={label} // todo: i suspect this is wrong from an accessibility perspective...
          colorScheme="blue"
          icon={<IcoCsv />}
          isRound={true}
          isDisabled={dirty || !roster.stats.score}
          onClick={() => toCsv(roster, packet)}
          size="md"
          variant="outline"
        />
      </Box>
    </Tooltip>
  )
}

const toCsv = (roster: FixitRoster, packet: DirPacket) => {
  const withScore = roster.students.filter((p) => p.score != null)

  if (withScore.length !== roster.stats.score) {
    console.warn(
      `${withScore.length} !== ${roster.stats.score}`,
      roster.stats,
      withScore
    )
  }

  const filenameParts = [
    'scores',
    `${withScore.length}of${roster.students.length}`,
    roster.section.id === FIXIT_ALL_SECTION_ID
      ? 'all-sections'
      : roster.section.name,
    packet.number,
    slugify(packet.name),
  ]

  const filename = `${filenameParts.join('_')}.csv`

  const csv = withScore
    .map((p) =>
      [
        p.student.number,
        getFullName(p.student).replaceAll(',', ''),
        formatScore(p.score),
      ].join(',')
    )
    .join('\n')

  const blob = new Blob([csv], { type: 'text/plain;charset=utf-8' })

  saveAs(blob, filename)
}
