import { BoxProps, Button, ButtonProps } from '@chakra-ui/react'
import { IcoRefresh } from '@paper/icons'
import { useRouter } from '@paper/route'
import { ImagesThat } from '@paper/schema'
import { orderBy } from 'lodash'
import { ReactNode, useMemo } from 'react'
import { usePickerBase } from '~src/blocks/pickers'
import { FilterPicker } from '~src/blocks/pickers/filterPicker'
import { ComboBox, HStack, Mono, TextStack, Txt, VStack } from '~src/components'
import { BadDateInput } from '~src/components/dateInput'
import { useDirectoryData } from '~src/data/data-directory'
import type { RD_SW_Scanlog } from '~src/routelist'
import { getScanlogNow } from './scanlogAirlock'

const sharedPickerProps = {
  caret: true,
  clearable: true,
  size: 'sm',
  width: '180px',
  variant: 'filled',
} as const

type ScanDevicePickerProps = { data: string[] }

export function ScanDevicePicker(props: ScanDevicePickerProps) {
  const { dispatchStay, routeData, useAirlock } = useRouter<RD_SW_Scanlog>()
  const dirData = useDirectoryData()
  const schoolLookup = dirData.data?.school.map

  // Add rollups for devices based on naming convention
  const scanDevices = useMemo(() => {
    let data = props.data?.filter((p) => p)
    if (!data) {
      return []
    }

    return orderBy(
      data.map((label) => ({ id: label, label })),
      (p) => p.label
    )
  }, [props.data, schoolLookup])

  useAirlock(
    { deviceName: null },
    routeData.deviceName &&
      !scanDevices.some((p) => p.id === routeData.deviceName)
  )

  return (
    <ScanPickerShell>
      <FilterPicker
        {...sharedPickerProps}
        allText="Any scanner"
        filterType="startsWith"
        getLabel={(item) => item.label}
        itemPlural="devices"
        items={scanDevices}
        label={pickerLabel('Scan device')}
        onChange={(deviceName) => dispatchStay({ deviceName })}
        renderItem={(item) =>
          item.full?.schoolName ? (
            <TextStack>
              <TextStack.Top fontSize="sm">{item.label}</TextStack.Top>
              <TextStack.Bottom fontSize="xs">
                {item.full.schoolName}
              </TextStack.Bottom>
            </TextStack>
          ) : (
            <Mono>{item.label}</Mono>
          )
        }
        selectedId={routeData.deviceName}
      />
    </ScanPickerShell>
  )
}

type ScanDatePickerProps = {}

export function ScanDatePicker(props: ScanDatePickerProps) {
  const { dispatchStay, routeData } = useRouter<RD_SW_Scanlog>()
  const date = (
    <BadDateInput
      max="now"
      iconButtonProps={{ colorScheme: 'gray', icon: <IcoRefresh /> }}
      onChange={(value) => {
        // we want to convert the chosen date to the user's time zone
        // ...i think
        dispatchStay({
          dir: null,
          // setting to '' goes to `now` #313
          scanDate: value?.userTz ?? getScanlogNow(),
        })
      }}
      size="sm"
      type="datetime-local"
      value={routeData.scanDate}
    />
  )

  return (
    // todo: label properly
    <ScanPickerShell>
      <HStack gap={1}>
        {pickerLabel('Scan date')}
        {routeData.scanDate && (
          <ClearFilterButton
            fontSize={pickerLabelFontSize}
            onClick={() =>
              dispatchStay({ dir: null, scanDate: getScanlogNow() })
            }
          >
            (now)
          </ClearFilterButton>
        )}
      </HStack>
      {date}
    </ScanPickerShell>
  )
}
/**
 * Scan batches with images that...e.g. haven't finished processing
 */
export function ScanWithImagesThatPicker() {
  const { dispatchStay, routeData } = useRouter<RD_SW_Scanlog>()

  const data: { id: ImagesThat }[] = [
    { id: 'unfinished' },
    // todo: match these with scanviz
    { id: 'error' },
    { id: 'infer' },
    { id: 'infer-gap' },
    { id: 'manual' },
    { id: 'no-qr' },
  ]

  // todo: is this already implemented for the packet list filter?
  const pickerProps = usePickerBase(
    { data, isLoading: false },
    {
      filterer: (filters, item, inputValue) => {
        return filters.startsWith(item.id, inputValue)
      },
      selector: (item, value) => item.id === value,
      value: routeData?.status,
    }
  )

  return (
    <ScanPickerShell>
      <ComboBox
        {...sharedPickerProps}
        {...pickerProps}
        itemToString={(item) => item.id}
        label={pickerLabel('Scan status')}
        onChange={(item) => dispatchStay({ status: item?.id })}
        placeholder="All"
        renderItem={(item) => (
          // todo: add the xpacket graph icons
          <Txt fontSize="sm">{item.id}</Txt>
        )}
      />
    </ScanPickerShell>
  )
}

const pickerLabelFontSize = '.7rem'

const pickerLabel = (text: ReactNode) => (
  <Txt fontSize={pickerLabelFontSize} ml={0.5}>
    {text}
  </Txt>
)

function ScanPickerShell(props: Pick<BoxProps, 'alignItems' | 'children'>) {
  const { alignItems, children } = props
  return (
    <VStack alignItems={alignItems ?? 'stretch'} gap={0.25}>
      {children}
    </VStack>
  )
}

type ClearFilterButtonProps = ButtonProps

export function ClearFilterButton(props: ClearFilterButtonProps) {
  return (
    <Button colorScheme="teal" size="xs" variant="link" {...props}>
      {props.children ?? <>(clear)</>}
    </Button>
  )
}
