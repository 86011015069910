import { Box, HTMLChakraProps } from '@chakra-ui/react'
import { TheBoxShadow } from '@paper/styles'
import { RefObject, useCallback, useEffect, useRef, useState } from 'react'

export type OnJpeg = (imageData: { blob: Blob; pageNumber: number }) => void

type PdfViewerProps = {
  id: string
  omitBoxShadow?: boolean // todo: messy
  onJpeg?: OnJpeg
  onPdfReady?(): void
  /** a.k.a. page number => pageIndex = 0 -> pageNumber = pageIndexPlus1 = 1 */
  pageIndexPlus1?: number
  url: string
} & HTMLChakraProps<'iframe'>

export function PdfViewer(props: PdfViewerProps) {
  const {
    id,
    omitBoxShadow,
    onJpeg,
    onPdfReady,
    pageIndexPlus1,
    url,
    ...iframeProps
  } = props
  const iframeRef = useRef<HTMLIFrameElement>()
  const pdfjsApp = useIframePdfjsApp(iframeRef)
  const [pdfReady, setPdfReady] = useState(false)

  // Set pdf url
  useEffect(
    function setPdfUrl() {
      // Open if url and app is ready
      pdfjsApp && (url ? pdfjsApp.open(url) : pdfjsApp.close())
      // Reset pdfReady
      setPdfReady(false)
    },
    [pdfjsApp, url]
  )

  // Pdf events
  const onDocumentLoaded = useCallback(
    (event) => {
      setPdfReady(true)
      onPdfReady && onPdfReady()
    },
    [onPdfReady]
  )
  usePdfjsAppEvent(pdfjsApp, 'documentloaded', onDocumentLoaded)
  const onPageRendered = useCallback<Handler<PageRenderedEvent>>(
    async (event) => {
      const { pageNumber, source } = event
      //const dataUrl = source.canvas.toDataURL('image/jpeg')
      const blob: Blob = await new Promise((resolve) =>
        source.canvas.toBlob(resolve, 'image/jpeg', 0.7)
      )
      onJpeg({ blob, pageNumber })
    },
    [onJpeg]
  )
  usePdfjsAppEvent(pdfjsApp, 'pagerendered', onJpeg && onPageRendered)

  // Set pdf page
  useEffect(
    function setPdfPage() {
      if (!pdfjsApp || !pdfReady || pageIndexPlus1 < 1) {
        return
      }
      pdfjsApp.page = pageIndexPlus1
    },
    [pageIndexPlus1, pdfjsApp, pdfReady]
  )

  const src = `/viewer/index.html` //?url=${encodeURIComponent(url)}`
  return (
    <Box
      as="iframe"
      boxShadow={omitBoxShadow ? null : TheBoxShadow}
      ref={iframeRef}
      {...iframeProps}
      // @ts-ignore
      src={src}
    />
  )
}

const useIframeLoaded = (iframeRef: RefObject<HTMLIFrameElement>) => {
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    let unmounted = false
    setLoaded(false)
    if (!iframeRef.current) {
      return
    }

    // Listen for onload
    const iframe = iframeRef.current
    const listener = () => {
      !unmounted && setLoaded(true)
    }
    iframe.addEventListener('load', listener)
    return () => {
      unmounted = true
      iframe.removeEventListener('load', listener)
    }
  }, [iframeRef])

  return loaded
}

const useIframePdfjsApp = (iframeRef: RefObject<HTMLIFrameElement>) => {
  const iframeLoaded = useIframeLoaded(iframeRef)
  // @ts-expect-error
  // prettier-ignore
  return iframeLoaded ? (iframeRef.current.contentWindow.PdfjsApp as PdfjsApp) : undefined
}

const usePdfjsAppEvent = (
  pdfjsApp: PdfjsApp,
  eventName: PdfjsEventParam[0],
  listener: PdfjsEventParam[1]
) => {
  useEffect(() => {
    if (!pdfjsApp || !listener) {
      return
    }
    let unmounted = false

    pdfjsApp.eventBus.on(eventName, (event) => {
      !unmounted && listener(event)
    })

    return () => {
      unmounted = true
      pdfjsApp.eventBus.off(eventName, listener)
    }
  }, [pdfjsApp, eventName, listener])
}

/** (external) PDF.js types */
type Handler<Ev> = (event: Ev) => void
type PdfjsEventParam =
  | ['pagerendered', Handler<PageRenderedEvent>]
  | ['documentloaded', Handler<{ source: PdfjsApp }>]

type PdfjsApp = {
  close(): Promise<unknown>
  eventBus: {
    on(eventName: PdfjsEventParam[0], listener: PdfjsEventParam[1]): void
    off(eventName: PdfjsEventParam[0], listener: PdfjsEventParam[1]): void
  }
  open(url: string): Promise<unknown>
  page: number
  pdfViewer: {
    firstPagePromise: Promise<unknown>
  }
}

type PageRenderedEvent = {
  pageNumber: number
  source: { canvas: HTMLCanvasElement }
}
/** */
