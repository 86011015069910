import {
  Box,
  ButtonProps,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { ReactNode } from 'react'
import { IcoCaretDown } from '../menu/menuIcons'
import { SystemStatusIndicator } from '../systemStatus'
import { BigIconButton } from './bigIconButton'
import { MainMenu } from './mainMenu'

type Menu2DrawerProps = {
  icon?: any
  keyboard?: ReactNode
  trigger?(buttonProps: ButtonProps): ReactNode
}

export function MainMenuDrawer(props: Menu2DrawerProps) {
  const { icon, keyboard, trigger } = props
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false })
  const buttonProps = {
    'aria-label': 'Open menu',
    // @ts-expect-error
    'data-cy': 'open-menu',
    onClick: onOpen,
    rightIcon: <IcoCaretDown />,
    variant: 'ghost',
  } satisfies ButtonProps

  return (
    <>
      <Box position="relative">
        {trigger?.(buttonProps) ?? <BigIconButton as={icon} {...buttonProps} />}
        <SystemStatusIndicator
          isAdminOnly={true}
          ifNormal="nothing"
          type="system"
          position="absolute"
          bottom={0.5}
          left={0.5}
        />
      </Box>
      <Drawer isOpen={isOpen} onClose={onClose} placement="left" size="xl">
        <DrawerOverlay />
        <DrawerContent bg="whiteAlpha.800">
          <MainMenu keyboard={keyboard} onClose={onClose} pageIcon={icon} />
        </DrawerContent>
      </Drawer>
    </>
  )
}
