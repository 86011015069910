import { BoxProps, CircularProgress, Icon } from '@chakra-ui/react'
import {
  IcoClock,
  IcoExclamationTriangle,
  IconType,
  IcoPdf,
} from '@paper/icons'
import { PdfGenerateStatus } from '@paper/schema'
import { HStack } from '~src/components'

const statusMap: Record<
  PdfGenerateStatus,
  { color: BoxProps['bg']; icon?: IconType; whirly?: boolean }
> = {
  error: { color: 'red.500', icon: IcoExclamationTriangle },
  'in-progress': { color: 'yellow.500', whirly: true },
  none: { color: 'gray.100', icon: null },
  success: { color: 'blue.500', icon: IcoPdf },
  'timed-out': { color: 'orange.500', icon: IcoClock },
}

type GenerateStatusProps = { status: PdfGenerateStatus }

export function GenerateStatus(props: GenerateStatusProps) {
  const { status } = props
  const statusProps = statusMap[status] ?? statusMap.none

  const size = 24

  const body = statusProps.whirly ? (
    <CircularProgress
      color="white"
      isIndeterminate={true}
      size={`${(size * 2) / 3}px`}
    />
  ) : statusProps.icon ? (
    <Icon aria-label={status} as={statusProps.icon} />
  ) : null

  // todo: this isn't reusable...
  return (
    <HStack
      bg={statusProps.color}
      color="white"
      flexShrink={0}
      fontSize="md"
      justifyContent="center"
      p={1}
      width={`${size}px`}
    >
      {body}
    </HStack>
  )
}
