import { Button, ButtonGroup } from '@chakra-ui/react'
import { ReactNode, useEffect } from 'react'
import { Column, Ex, HStack, Txt } from '~src/components'
import { useScanFixMutation } from '../data-scanlog'
import { useScanlogContext } from '../scanlogAirlock'
import { oneOrMore } from './scanFixContext'
import { ScanFixTable } from './scanFixTable'

type ScanlogFixProps = {}

export function ScanlogFix(props: ScanlogFixProps) {
  const { layout, scanFix, selectedSheet } = useScanlogContext()
  const { isDirty, isValid, fixPayload, setChanges, tableData } = scanFix

  const fixMutation = useScanFixMutation()

  useEffect(() => {
    fixPayload?.length && console.log('[fixPayload]', fixPayload)
  }, [fixPayload])

  if (!selectedSheet?.length) {
    return null
  }

  const canSubmit =
    isValid &&
    oneOrMore(
      tableData,
      (c) => c.changeState !== 'none' && c.changeState !== 'pending'
    )

  let message: ReactNode
  if (fixPayload && !isValid) {
    if (fixPayload.some((p) => p.action === 'manual' && !p.xpageId)) {
      message = (
        <>
          Can't overwrite a <Ex>success</Ex> scan
        </>
      )
    } else {
      message = <>Invalid</>
    }
  } else if (fixMutation.isError) {
    message = <>Something went wrong</>
  }

  return (
    <Column
      alignItems="stretch"
      gap={4}
      px={layout.colPadX}
      // todo: to match batch list column...need to unify these styles
      pt="1px"
    >
      <HStack gap={2}>
        <ButtonGroup size="sm" variant="outline">
          <Button
            colorScheme="blue"
            isDisabled={!canSubmit}
            isLoading={fixMutation.isPending}
            onClick={() => fixMutation.mutate(fixPayload)}
          >
            {fixPayload?.length === 2 ? 'Submit both pages' : 'Submit'}
          </Button>
          <Button isDisabled={!isDirty} onClick={() => setChanges('none')}>
            Discard
          </Button>
        </ButtonGroup>

        <Txt color="red.600" fontSize="xs">
          {message}
        </Txt>
      </HStack>
      <ScanFixTable />
    </Column>
  )
}
