import type {
  Curriculum,
  FileLocation,
  PacketContent,
  Packetmeta,
  Section,
  Student,
  Teacher,
  XpacketStatus,
  Xpage,
} from '..'

export type StudentPacketStatus = Student & { status?: XpacketStatus }
export type StudentNSection = {
  section: Section
  student: Student
}

export type PdfGrain = 'generic' | 'packet' | 'teacher'
export type PdfSorter = 'section' | 'student' | 'teacher'
export type PdfGenerateStatus =
  | 'success'
  | 'error'
  | 'in-progress'
  | 'timed-out'
  | 'none'

export type PrintedStatus = { date: number; is: boolean }

type PdfPage = Pick<Xpage, 'id' | 'qrb'>
export type PdfChecksum = {
  /** 1st page of 1st packet */
  '1_1': PdfPage
  /** 1st page of 2nd packet */
  '2_1': PdfPage
  /** last page of last packet */
  m_n: PdfPage
  /** number of packets */
  m: number
  /** number of sections */
  sec: number
  /** number of students */
  stu: number
  /** number of unnamed */
  u: number
}

export type TktManifest = {
  // todo: should we store grid values here also/instead?
  // grid: { x: number, y: number, w: number, h: number, ppu: number, r: number}
  // todo: should bubble values be stored here? referenced via gridId? or referenced via its own id?
  // bubbles: { id: string, options: number[]; x: number; y: number; }[]
  // todo: this will be easier to decide later, so i'll continue hardcoding, and plan to migrate
  gridId: string
  outOf: number
  /** qrbackup prefix */
  qrbp: string
  rubricId: string
  termCode: string
}

/**
 * Manifest of what's printed (when combined with Xpage and Xpacket)
 */
export type PrintManifest = {
  id: string
  /** ids for important pages */
  checksums: PdfChecksum[]
  /** Start of printing when we take snapshots
   * todo: (Separate from _printed.date since printing may fail), i suppose i could store { date, is: false }... */
  date: number
  /** One PDF per  */
  grain: PdfGrain
  /** id of thing we're printing for */
  grainId: string
  /** `true` if trailing blank pages are not added  */
  noTrailingBlanks: boolean
  /** packetId (key into packetmeta collection) */
  packetId: string
  /** Snapshot of packetmeta at time of printing */
  packetSnap: PacketPrintSnapshot
  /** Location of the pdfs (1 per part) */
  pdfs: FileLocation[]
  /** Email of the user who did the printing */
  printUser: string
  /** Whether this grainId is recalled (e.g. accidentally generated per-teacher instead of per-packet) */
  recalled: boolean
  /** schoolId for the school page feature */
  schoolId: string
  /** How the PDF is sorted */
  sortedBy: PdfSorter[]
  /** schoolYearId */
  syId: number
  /** ticket experiment */
  tkt: TktManifest
  /** number of unnamed packets created per section */
  unnamedPerSection: number
  /** snapshot of names of who printed */
  whoSnap: WhoPrintSnapshot
  /** Duration of generating */
  _duration?: number
  /** Error when generating */
  _error?: string
  /** Some indication of memory used */
  _heapUsedMB?: number
  /** Printing is multi-step; `_printed.is === true` if printing has completed */
  _printed: PrintedStatus
}

/** User choices when printing */
export type PrintOptions = Pick<
  PrintManifest,
  | 'grain'
  | 'grainId'
  | 'noTrailingBlanks'
  | 'packetId'
  | 'sortedBy'
  | 'unnamedPerSection'
>

/**
 * Packetmeta subset snapshotted when printing
 */
export type PacketPrintSnapshot = {
  curriculum: Curriculum
  groupId: string
  name: PacketContent['name']
  number: Packetmeta['number']
  segments: [start: number, length: number][]
  style: PacketContent['style']
  type: PacketContent['type']
}

/**
 * Storing school and teacher name on the manifest so they are more convenient to display
 */
export type WhoPrintSnapshot = {
  schoolName: string
  teacher?: Teacher
}
