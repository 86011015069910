import { ImgpOneResult, ScanBatch, ScanPageTransfer } from '@paper/schema'
import { defineApi } from '../defineApi'

type CreateBody = Pick<
  ScanBatch,
  'batchNum' | 'device' | 'jobName' | 'scanDate' | 'sourceId'
> & {
  pages: ScanPageTransfer[]
}

export const imgp_batch_create = defineApi<{
  method: 'post'
  body: CreateBody
  result: {
    already: {
      created: boolean
      uploaded: boolean
      settled: boolean
    }
    batchId: string
    imageUrls: string[]
    manifestUrl: string
  }
}>({
  method: 'post',
  urlFactory: `imgp/batch/create`,
})

export const imgp_batch_intook = defineApi<{
  method: 'post'
  body: { batchId: string }
  result: boolean
}>({
  method: 'post',
  urlFactory: `imgp/batch/intook`,
})

export const imgp_rerun = defineApi<{
  method: 'post'
  body: { batchId: string }
  result: boolean
}>({
  app: 'imgp_run',
  invalidateOnMutationSuccess: ['scanlog'],
  method: 'post',
  urlFactory: `rerun`,
})

export const imgp_run = defineApi<{
  method: 'post'
  body: { batchId: string }
  result: ImgpOneResult[]
}>({
  app: 'imgp_run',
  method: 'post',
  urlFactory: ``,
})
