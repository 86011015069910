import { Box, chakra } from '@chakra-ui/react'
import { IcoPin } from '@paper/icons'
import { PinQCell } from '@paper/schema'
import { CORRECT_COLOR, GRAY_HAS_SCANS } from '@paper/styles'
import { memo } from 'react'
import { AnswerSubGrid } from './answerSubGrid'

// todo: rename/move
export const Opacity = {
  axisFocused: 0.85,
  focused: 1,
  unfocused: 0.65,
} as const
export const Transition = 'all .35s ease'

type CellProps<T> = {
  isCellSelected: boolean
  item: T
  onSelect(x: number, y: number, z: string): void
  x: number
  y: number
  z: string
}

// copy/paste!
export const Cell = memo(function Cell(props: CellProps<PinQCell>) {
  // console.log('<Cell />')
  const { item, isCellSelected, onSelect, x, y, z } = props

  let selectedAStr = z

  const data = Object.values(item.answers).map(
    ({ aStr, correct, count, pinnedId }) => {
      return {
        aStr,
        correct,
        pct: (100 * count) / item.count,
        pinned: !!pinnedId,
        selected: isCellSelected && aStr === selectedAStr,
      }
    }
  )

  const transform = isCellSelected ? `scale(1.15)` : undefined

  return (
    <Box
      justifyItems="center"
      style={{
        gridColumn: x + 1,
        gridRow: y + 1,
        transform,
      }}
      transition={Transition}
    >
      <MiniBar data={data} onSelect={(z) => onSelect(x, y, z)} />
    </Box>
  )
})

type MiniBarItem = {
  aStr: string
  correct: boolean
  pct: number
  pinned: boolean
  selected: boolean
}
type MiniBarProps = { data: MiniBarItem[]; onSelect(z: string): void }

function MiniBar(props: MiniBarProps) {
  const { data, onSelect } = props

  const aspectRatio = 2 // not ideal that this hardcoded...
  const vbHeight = Math.min(48, 12 * data.length)
  const vbWidth = Math.floor((aspectRatio * vbHeight) / data.length)
  const barWidth = vbWidth // one svg per bar now so it's easier to transform
  const pinSize = 16 // this is hardcoded in my react-icons hack

  let bars = []

  for (let barIdx = 0; barIdx < data.length; barIdx++) {
    const { aStr, correct, pct, pinned, selected } = data[barIdx]

    // no bar for 0
    if (pct === 0) {
      continue
    }

    let pctH = Math.round((pct * vbHeight) / 100)
    let color = correct ? CORRECT_COLOR : GRAY_HAS_SCANS
    let pinX = barWidth / 2 - pinSize / 2
    let pinY = Math.round((2 / 3) * vbHeight - pinSize / 2)

    bars.push(
      <chakra.svg
        gridColumn={barIdx + 1}
        key={aStr}
        _hover={{
          filter: 'brightness(1.15)',
          transform: 'scale(1.15)',
        }}
        _active={{
          filter: 'brightness(1.35)',
        }}
        overflow="visible"
        transition={Transition}
        viewBox={`0 0 ${vbWidth} ${vbHeight}`}
      >
        <path
          key={aStr}
          d={`M0 ${vbHeight} v${-pctH} h${barWidth} v${pctH}`}
          strokeWidth={selected ? 2 : null}
          stroke={selected ? color : null}
          strokeDasharray="5 2 5 2 4 2"
          strokeDashoffset="4"
          fill={color}
          fillOpacity={0.2}
        />
        {pinned && (
          <g
            opacity={!selected ? 0.4 : 1}
            transform={`translate(${pinX}, ${pinY})`}
          >
            <IcoPin color={GRAY_HAS_SCANS} />
          </g>
        )}
        <chakra.rect
          cursor="pointer"
          fill="transparent"
          onClick={() => onSelect(aStr)}
          x={0}
          y={0}
          width={`${barWidth}px`} // ugh chakra
          height={`${vbHeight}px`}
        />
      </chakra.svg>
    )
  }

  return <AnswerSubGrid colCount={data.length}>{bars}</AnswerSubGrid>
}
