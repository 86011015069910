import { Table, Tbody, Td, Thead, Tr } from '@chakra-ui/react'
import { DownloadRow, PdfChecksum } from '@paper/schema'
import { formatQRBackup, getLastFirst } from '@paper/utils'
import { merge } from 'lodash'
import { CSSProperties, useRef } from 'react'
import { CopyButton2 } from '~src/components'
import { formatUnits, intlFormatDateTime } from '~src/utils/messages'

type Val = string | number

type ExCol<T> = {
  fontFamily?: string
  textAlign?: CSSProperties['textAlign']
  label: string
  value: (row: T) => Val | { style: CSSProperties; value: Val }
}

type Col = ExCol<DownloadRow>

const IDCol = (partial: Col): Col => ({
  ...partial,
  fontFamily: MONO,
})

const ShortCol = (partial: Col): Col => ({
  ...partial,
  textAlign: 'center',
})

const ShortIDCol = (partial: Col) => IDCol(ShortCol(partial))

const checksumKeys = ['1_1', '2_1', 'm_n'] satisfies (keyof PdfChecksum)[]

const MONO = 'Consolas'

const columns: Col[] = [
  {
    label: 'School',
    value: (row) => row.whoSnap.schoolName,
  },
  ShortIDCol({
    fontFamily: MONO,
    label: 'Packet Group Id',
    value: (row) => row.packetSnap.groupId,
  }),
  ShortIDCol({
    label: 'Grain',
    value: (row) => {
      const color =
        row.rowStatus !== 'downloadable'
          ? undefined
          : row.grain === 'packet'
          ? 'darkgoldenrod'
          : 'darkcyan'
      return {
        style: { color },
        value: row.grain,
      }
    },
  }),
  {
    label: 'Curriculum',
    value: (row) => row.packetSnap.curriculum.name,
  },
  {
    label: 'Teacher',
    value: (row) => {
      const NONE = '-'
      const value = getLastFirst(row.whoSnap.teacher) ?? NONE
      return {
        style: { textAlign: value === NONE ? 'center' : undefined },
        value,
      }
    },
  },
  { label: 'Packet-Name', value: (row) => row.packetSnap.name },
  ShortCol({ label: 'Part', value: (row) => row.partNumber }),
  {
    label: 'Generated At',
    value: (row) => intlFormatDateTime(row.generatedAt),
  },
  { label: 'Expires', value: (row) => intlFormatDateTime(row.expires) },
  {
    label: 'Download URL',
    value: (row) => {
      if (row.rowStatus !== 'downloadable') {
        return {
          style: { color: 'firebrick', fontFamily: MONO, textAlign: 'center' },
          value: row.rowStatus,
        }
      } else {
        return row.downloadUrl
      }
    },
  },
  ShortCol({ label: 'Staple-Every', value: (row) => row.stapleEvery }),
  ShortCol({ label: 'Packet Count', value: (row) => row.checksum.m }),
  ShortCol({ label: 'Sheet Count', value: (row) => row.sheetCount }),
  ...checksumKeys.map((key): Col => {
    let [pkt, page] = key.split('_')
    pkt = pkt === 'm' ? 'last' : pkt
    page = page === 'n' ? 'last' : page
    return {
      fontFamily: MONO,
      label: `[${pkt}-${page}] text code`,
      value: (row) => formatQRBackup(row.checksum[key].qrb),
    }
  }),
  { label: 'Generated By', value: (row) => row.printUser },
  IDCol({ label: 'grainId', value: (row) => row.grainId }),
  IDCol({ label: 'rowId', value: (row) => row.id }),
]

type DownloadTableExportProps = { data: DownloadRow[] }

export function DownloadTableExport(props: DownloadTableExportProps) {
  let { data } = props
  const ref = useRef<HTMLTableElement>()

  if (!data) {
    return null
  }

  return (
    <>
      <CopyButton2
        data-cy="btn-copy-log"
        isDisabled={!data.length}
        size="xs"
        value={() => {
          const type = 'text/html'
          const blob = new Blob([ref.current.outerHTML], { type })
          const item = new ClipboardItem({ [type]: blob })
          return [item]
        }}
        variant="outline"
      >
        {formatUnits(data.length, 'row')}
      </CopyButton2>
      <Table display="none" ref={ref}>
        <Thead>
          <Tr>
            {columns.map((col, idx) => (
              <Td
                key={idx}
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                {col.label}
              </Td>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {data.map((row, rowIdx) => {
            let rowStyle: CSSProperties = {
              color: row.rowStatus !== 'downloadable' ? '#AAAAAA' : undefined,
            }
            return (
              <Tr key={rowIdx}>
                {columns.map((col, colIdx) => {
                  const { label, value: _value, ...rest } = col
                  let value = col.value(row)
                  let colStyle: CSSProperties
                  if (typeof value === 'object' && value != null) {
                    colStyle = value.style
                    value = value.value
                  }
                  let tdStyle = merge({}, rowStyle, rest, colStyle)
                  return (
                    <Td key={colIdx} style={tdStyle}>
                      {value as Val}
                    </Td>
                  )
                })}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </>
  )
}
