import { APIs } from '@paper/api-specs'
import { useUser } from '~src/blocks/userProvider'

/**
 * Returns handler that downloads `unsignedUrl` (by opening signed url in new window)
 */
export function useDownload(unsignedUrl: string) {
  // todo: make use of new signing mojo!!!
  // todo: should also have a loading state!
  const { fetchAs } = useUser()
  return async () => {
    const urlMap = await APIs.signurls.fetch({
      fetchAs,
      queryVars: { body: [unsignedUrl] },
    })
    const url = urlMap[unsignedUrl]
    window.open(url, '_blank')
  }
}
