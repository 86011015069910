import { Button } from '@chakra-ui/react'
import { useRouter } from '@paper/route'
import { CrunchBatch } from '@paper/schema'
import { sumBy } from 'lodash'
import { ReactNode } from 'react'
import { ListLoadShell } from '~src/blocks/list'
import { HStack, TextStack, Txt, VStack } from '~src/components'
import { LoadingDots } from '~src/components/status'
import { ExpandeeProps, UghTable } from '~src/components/table'
import { RD_SW_Scanlog } from '~src/routelist'
import { formatPastVsNow, getScanlogPacketLabel } from '~src/utils/messages'
import { useScanlogContext } from './scanlogAirlock'
import { scanBatchColumns } from './scanlogBatchListCols'
import { ScanlogChunk } from './scanlogChunk'
import { ScanPrevNext } from './scanlogPrevNext'

type ScanlogBatchListProps = {}

export function ScanlogBatchList(props: ScanlogBatchListProps) {
  const { layout, sbDigest } = useScanlogContext()
  const { dispatchStay, routeData } = useRouter<RD_SW_Scanlog>()
  const expandeeHeight = 110

  // todo: this is going to be brittle...
  const sectionWidth =
    sumBy(scanBatchColumns, (p) => p.props.width) +
    (scanBatchColumns.length - 1) * 4 +
    8 * layout.colPadX

  return (
    <ListLoadShell
      digest={sbDigest}
      fontSize="sm"
      px={layout.colPadX}
      width={sectionWidth}
    >
      {(status) => {
        if (status === 'empty') {
          // base data is empty
          return <Txt textAlign="center">No matching batches</Txt>
        } else if (status === 'success') {
          return (
            <>
              <ScanPrevNext />
              <UghTable
                aria-label="Scan batch list"
                colorScheme="gray"
                columns={scanBatchColumns}
                data={sbDigest.success.items}
                Expandee={ScanBatchExpandee}
                expandeeHeight={expandeeHeight}
                height="100%"
                onSelect={(item: CrunchBatch) =>
                  dispatchStay({ sb_batchId: item.id, si_imageId: null })
                }
                selectedId={routeData.sb_batchId}
                spacingX="snug"
                spacingY="airy+"
              />
            </>
          )
        }
      }}
    </ListLoadShell>
  )
}

function ScanBatchExpandee(props: ExpandeeProps<CrunchBatch>) {
  // packet and teacher buttons
  const { item } = props
  const { chunks } = item

  const { xpacketSetDigest } = useScanlogContext()
  const { qResult } = xpacketSetDigest

  const { dispatchStay, routeData } = useRouter<RD_SW_Scanlog>()

  const xpacketSets = xpacketSetDigest.success?.otherData.xpacketSets ?? []
  let body: ReactNode

  if (!item.ts.process) {
    // todo: would be nice to have an indication of queue position/detect if it's stuck/contact support button
    // todo: probably ideally separate queued from other, and recheck those
    body = (
      <NotVeryHelpfulQueueMessage
        info={`We've received this batch, but processing has not begun yet.`}
      />
    )
  } else if (!item.ts.settled) {
    body = (
      <NotVeryHelpfulQueueMessage
        info={<>Processing began {formatPastVsNow(item.ts.process)}</>}
      />
    )
  } // todo: need to figure out isPending situation!!!
  else if (qResult.isPending && qResult.fetchStatus !== 'idle') {
    body = <LoadingDots />
  } else if (qResult.isSuccess || !item.packets?.length) {
    body = (
      <>
        <VStack alignItems="stretch" gap={1}>
          <HScrollSection title="Packets" gap={2}>
            <ButtonSection
              items={xpacketSets}
              itemToLabel={getScanlogPacketLabel}
              itemToOn={(item) =>
                item.packet.id === routeData.packetId &&
                item.teacher.id === routeData.teacherId
              }
              name="Packets"
              onClick={(item) => {
                dispatchStay({
                  curriculumId: item.curriculumId,
                  deviceName: null,
                  packetId: item.packet.id,
                  scanDate: null,
                  teacherId: item.teacher.id,
                  status: null,
                })
              }}
            />
          </HScrollSection>
          <HScrollSection title="Sheets">
            {chunks.map((item, idx) => {
              return <ScanlogChunk key={idx} {...item} />
            })}
          </HScrollSection>
        </VStack>
      </>
    )
  }

  return (
    <VStack alignItems="stretch" gap={3} px={4} py={3}>
      {body}
    </VStack>
  )
}

type ButtonSectionProps<T> = {
  disabled?: boolean
  items: T[]
  itemToLabel(item: T): ReactNode
  itemToOn(item: T): boolean
  name: string
  onClick(item: T): void
}

function ButtonSection<T>(props: ButtonSectionProps<T>) {
  // todo: this used to be a subsection on the same row
  const { disabled, itemToLabel, itemToOn, items, name, onClick } = props
  return (
    <>
      {items.length > 0 ? (
        items.map((item, idx) => (
          <Button
            key={idx}
            flexShrink={0}
            isDisabled={disabled}
            onClick={() => onClick(item)}
            overflow="hidden"
            size="xs"
            variant={itemToOn(item) ? 'solid' : 'ghost'}
          >
            {itemToLabel(item)}
          </Button>
        ))
      ) : (
        <Txt fontSize="xs">None found</Txt>
      )}
    </>
  )
}

type HScrollSectionProps = {
  children: ReactNode
  gap?: number
  title: string
}

function HScrollSection(props: HScrollSectionProps) {
  const { children, gap, title } = props
  const scrollbarPad = 3
  return (
    <HStack gap={2}>
      <Txt fontSize="xs" pb={scrollbarPad}>
        {title}:
      </Txt>
      <HStack gap={gap ?? 1} overflowX="auto" pb={scrollbarPad}>
        {children}
      </HStack>
    </HStack>
  )
}

function NotVeryHelpfulQueueMessage({ info }) {
  return (
    <TextStack>
      <TextStack.Top>{info}</TextStack.Top>
      <TextStack.Bottom fontStyle="italic" opacity={0.8} variant="loose">
        If that was a long time ago, something probably went wrong!
      </TextStack.Bottom>
    </TextStack>
  )
}
