import { Box, Grid, List, ListItem, Tooltip } from '@chakra-ui/react'
import { useLink } from '@paper/route'
import { XpacketSW } from '@paper/schema'
import { partition } from 'lodash'
import { ReactNode, useMemo } from 'react'
import { IcoScanIssue } from '~src/blocks/icoScanIssue'
import {
  HSep,
  HStack,
  IBLink,
  Italic,
  Txt,
  VSep,
  VStack,
} from '~src/components'
import { Routes } from '~src/routelist'
import { getFirstL } from './rubricStudentGrid'

type RubricMissingProps = { rubricless: XpacketSW[] }

export function RubricMissing(props: RubricMissingProps) {
  const { rubricless } = props
  const [noScans, noRubric] = useMemo(() => {
    return partition(rubricless, (p) => p.status === 'missing')
  }, [rubricless])

  const blinkProps = useLink(Routes.sw_fixit.mergeAction())
  const blinkLabel = 'Go to fix-it/export'

  return (
    <VStack>
      <Grid gap={2} gridTemplateColumns="1fr auto 1fr" placeItems="center">
        <Box />
        Missing
        <Tooltip label={blinkLabel} placement="end">
          <IBLink
            aria-label={blinkLabel}
            icon={<IcoScanIssue />}
            size="sm"
            {...blinkProps}
          />
        </Tooltip>
      </Grid>
      <HStack alignItems="start" p={2}>
        <StudentList title="Rubric" xpackets={noRubric} />
        <VSep />
        <StudentList title="Scans" xpackets={noScans} />
      </HStack>
    </VStack>
  )
}

type StudentListProps = { title: ReactNode; xpackets: XpacketSW[] }

function StudentList(props: StudentListProps) {
  const { title, xpackets } = props
  return (
    <VStack gap={1} minWidth="100px">
      <Txt fontSize="sm">{title}</Txt>
      <HSep />
      {xpackets.length ? (
        <List fontSize="xs" px={2} spacing={1} textAlign="start">
          {xpackets.map((xp) => (
            <ListItem key={xp.id} isTruncated={true}>
              {getFirstL(xp.student)}
            </ListItem>
          ))}
        </List>
      ) : (
        <Italic fontSize="xs">None</Italic>
      )}
    </VStack>
  )
}
