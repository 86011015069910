import { List, ListItem, ListProps } from '@chakra-ui/react'
import { IcoStaple } from '@paper/icons'
import { upToEven } from '@paper/utils'
import { capitalize } from 'lodash'
import { ReactNode } from 'react'
import { GhostTag, HStack, Txt } from '~src/components'
import { formatPastVsNow, formatUnits } from '~src/utils/messages'
import { DownloadButtonGroup } from './downloadButtonGroup'
import { usePdfPrintError } from './pdfBuilderError'
import { usePdfBuilderContext } from './pdfBuilderProvider'
import {
  DialogSubsection,
  lifact,
  lifactPieces,
  renderPiecesSS,
} from './printDialogLayout'
import { liStuSecCounts } from './printDialogPdfStats'

type PrintPdfHistoryProps = {
  listProps?: ListProps
  variant: 'school' | 'teacher'
}

export function PrintPdfHistory(props: PrintPdfHistoryProps) {
  const { listProps = {}, variant } = props
  const fontSize = variant === 'teacher' ? 'xs' : 'sm'
  const { selected: item } = usePdfBuilderContext()
  const pdfError = usePdfPrintError()

  let body: ReactNode
  if (pdfError.hasError) {
    body = pdfError.node
  } else if (item.currentManifest) {
    body = lifact(
      <HStack alignItems="center" display="flex" gap={2} minHeight="32px">
        {renderPiecesSS([
          capitalize(formatPastVsNow(item.currentManifest.date)),
          item.currentManifest.printUser,
        ])}
      </HStack>
    )
  } else {
    body = (
      <ListItem fontStyle="italic" opacity={0.8}>
        Never
      </ListItem>
    )
  }

  return (
    <List fontSize={fontSize} {...listProps}>
      <ListItem>Most recently generated</ListItem>
      {body}
    </List>
  )
}

type PrintPdfTeacherDownloadProps = {}

export function PrintPdfTeacherDownload(props: PrintPdfTeacherDownloadProps) {
  const { selected: item } = usePdfBuilderContext()
  const { currentManifest, downloadUrls } = item
  const pdfError = usePdfPrintError()

  if (pdfError.hasError) {
    return pdfError.node
  } else if (!currentManifest) {
    return <Txt fontSize="xs">PDFs haven't been generated yet</Txt>
  }

  const checksum = currentManifest.checksums[0]
  const isMultipart = downloadUrls.length > 1
  return (
    <>
      <DialogSubsection.List spacingY="airy">
        {lifactPieces([
          `Created ${formatPastVsNow(currentManifest.date)}`,
          currentManifest.printUser,
        ])}
        {liStuSecCounts({
          sec: checksum.sec,
          stu: checksum.stu,
          unnamed: checksum.u,
        })}
      </DialogSubsection.List>
      <DialogSubsection.List>
        {downloadUrls?.map((url, idx) => {
          const partLength = upToEven(
            currentManifest.packetSnap.segments[idx][1]
          )
          return (
            <ListItem key={idx} alignItems="center" display="flex" gap={2}>
              <DownloadButtonGroup
                partNumber={isMultipart ? idx + 1 : null}
                partLayout="col"
                url={url}
                variant="loggedin"
              />
              <GhostTag
                color="yellow.500"
                leftIcon={partLength > 2 ? IcoStaple : undefined}
                minHeight="unset"
                label={formatUnits(partLength, 'page')}
              />
            </ListItem>
          )
        })}
      </DialogSubsection.List>
    </>
  )
}
