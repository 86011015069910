import { ButtonGroup, Tag } from '@chakra-ui/react'
import { C2Status } from '@paper/schema'
import { ReactNode } from 'react'
import { ToggleGroup } from '~src/components'

export type WithAll<T> = T | 'all'

export const statusToColor = (status: WithAll<C2Status>) =>
  status === 'active'
    ? 'blue'
    : status === 'recalled'
    ? 'red'
    : // : status === 'draft'
      // ? 'grayBtn'
      'blackAlpha'

type VariantTagProps = { min?: boolean; variant: string }

function VariantTag(props: VariantTagProps) {
  const { min, variant } = props
  return (
    <Tag
      fontFamily="mono"
      fontSize="sm"
      justifyContent={min ? 'center' : undefined}
      minWidth={min ? 10 : undefined}
      size="md"
    >
      {variant}
    </Tag>
  )
}

function StatusTag(props: { status: C2Status }) {
  const { status } = props
  return (
    <Tag
      colorScheme={statusToColor(status)}
      fontSize="xs"
      size="md"
      textTransform="capitalize"
      variant="solid"
    >
      {status}
    </Tag>
  )
}

type VariantStatusProps = { curricula: { variant: string }[]; status: C2Status }

export function VariantStatus(props: VariantStatusProps) {
  const { curricula, status } = props
  // note: curricula are currently pre-sorted by variant, though should maybe just sort here?
  return (
    <ButtonGroup isAttached={true} userSelect="none">
      <StatusTag status={status} />
      {curricula.map(({ variant }) => (
        <VariantTag key={variant} variant={variant} />
      ))}
    </ButtonGroup>
  )
}

type VariantStatusPickerProps = {
  buttons: ReactNode
  onChange(action: string): void
  preventNone?: boolean
  selectedValue: string
  variant: string
}

/**
 * Displays a variant and its action buttons
 * Assumes size and single toggle group, so will need to tweak if we need more flexibility
 */
export function VariantActionButtons(props: VariantStatusPickerProps) {
  let { buttons, onChange, preventNone, selectedValue, variant } = props

  // Taken from here, but want to apply it irrespective of type
  // https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/button/src/button-group.tsx
  // emotion complains that first-child might not be safe server-side, but we're not rendering server-side
  const attachedStyles = {
    // prettier-ignore
    horizontal: {
      '> *:first-child/* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */:not(:last-child)': { borderEndRadius: 0 }, 
      '> *:not(:first-child):not(:last-child)': { borderRadius: 0 }, 
      '> *:not(:first-child):last-child': { borderStartRadius: 0 }, 
    },
  }

  return (
    <ToggleGroup.Root
      onChange={onChange}
      preventNone={preventNone ?? true}
      size="xs"
      type="single"
      value={selectedValue}
    >
      <ButtonGroup
        data-variant={variant}
        isAttached={true}
        size="xs"
        userSelect="none"
        sx={attachedStyles.horizontal}
      >
        <VariantTag min={true} variant={variant} />
        {buttons}
      </ButtonGroup>
    </ToggleGroup.Root>
  )
}
