interface ClusterResult {
  centroids: number[]
  assignments: number[]
}

function kMeansCluster(
  data: number[],
  k: number,
  iterations: number = 5,
  tolerance: number = 1e-4
): ClusterResult {
  let centroids = initializeCentroids(data, k)
  let assignments: number[] = new Array(data.length)
  let prevCentroids = [...centroids]

  for (let iter = 0; iter < iterations; iter++) {
    // Step 1: Assignment step
    for (let i = 0; i < data.length; i++) {
      let minDistance = Infinity
      let closestCentroid = 0
      for (let j = 0; j < k; j++) {
        let distance = Math.abs(data[i] - centroids[j])
        if (distance < minDistance) {
          minDistance = distance
          closestCentroid = j
        }
      }
      assignments[i] = closestCentroid
    }

    // Step 2: Update centroids
    let newCentroids = new Array(k).fill(0)
    let counts = new Array(k).fill(0)
    for (let i = 0; i < assignments.length; i++) {
      newCentroids[assignments[i]] += data[i]
      counts[assignments[i]]++
    }

    for (let j = 0; j < k; j++) {
      if (counts[j] !== 0) {
        centroids[j] = newCentroids[j] / counts[j]
      }
    }

    // Step 3: Check for convergence
    let isConverged = true
    for (let j = 0; j < k; j++) {
      if (Math.abs(centroids[j] - prevCentroids[j]) > tolerance) {
        isConverged = false
        break
      }
    }

    if (isConverged) {
      // console.log('Converged at iteration:', iter)
      break
    }

    prevCentroids = [...centroids]
  }

  return { centroids, assignments }
}

function initializeCentroids(data: number[], k: number): number[] {
  const min = Math.min(...data)
  const max = Math.max(...data)
  let centroids = []
  const step = (max - min) / (k - 1)
  for (let i = 0; i < k; i++) {
    centroids.push(min + i * step)
  }
  return centroids
}

interface ClusterResult {
  centroids: number[]
  assignments: number[]
}

/**
 * Returns indices of the largest centroid if it meets a minimum distance criteria
 */
export function kMeansLargestCentroidCluster(
  data: number[],
  k: number,
  minDistance: number
): number[] {
  const { centroids, assignments } = kMeansCluster(data, k)

  // Find the index of the largest centroid
  let largestCentroidIndex = 0
  let largestCentroidValue = centroids[0]
  let smallestCentroidValue = centroids[0]

  for (let i = 1; i < centroids.length; i++) {
    if (centroids[i] > largestCentroidValue) {
      largestCentroidValue = centroids[i]
      largestCentroidIndex = i
    }
    if (centroids[i] < smallestCentroidValue) {
      smallestCentroidValue = centroids[i]
    }
  }

  // Check if the difference between largest and smallest centroids is less than minDistance
  if (largestCentroidValue - smallestCentroidValue < minDistance) {
    return [] // Return an empty array if the condition is not met
  }

  // Collect indices of data points assigned to the largest centroid
  const indices = []
  for (let i = 0; i < assignments.length; i++) {
    if (assignments[i] === largestCentroidIndex) {
      indices.push(i)
    }
  }

  return indices
}

/**
 * Returns index of the largest value if it meets a minimum distance criteria
 *
 * For single-select bubbles, we (probably) just want the largest value.
 * Possible we'll want to have different handling for situations where multiple are similarly filled
 * (which would take us back to clustering)
 */
export function indexOfLargest(
  values: number[],
  minFilledness: number,
  minDistance: number
): number[] {
  if (values.length === 0) {
    return [] // Return an empty array if the array is empty
  }

  let largestValue = values[0]
  let smallestValue = values[0]
  let largestIndex = 0 // Store the index of the largest value

  // Iterate through the array to find the smallest and largest values and track the index of the largest
  for (let i = 1; i < values.length; i++) {
    if (values[i] == null) {
      continue
    }
    if (values[i] > largestValue) {
      largestValue = values[i]
      largestIndex = i
    }
    if (values[i] < smallestValue) {
      smallestValue = values[i]
    }
  }

  // Check if the difference between largest and smallest meets minDistance
  if (
    largestValue >= minFilledness &&
    largestValue - smallestValue >= minDistance
  ) {
    return [largestIndex] // Return the index of the largest value in an array
  }

  return [] // Return an empty array if the condition is not met
}
