import {
  Box,
  BoxProps,
  Button,
  Grid,
  Icon,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Tag,
  useBoolean,
} from '@chakra-ui/react'
import { APIs } from '@paper/api-specs'
import { IcoAsterisk, IcoSecretDoor } from '@paper/icons'
import { FixitSheetRow } from '@paper/schema'
import { ENDASH, XOR } from '@paper/utils'
import { capitalize } from 'lodash'
import { useEffect } from 'react'
import { useNormalizeImages } from '~src/blocks/imageViewer'
import { ListDigest } from '~src/blocks/list'
import { useUser } from '~src/blocks/userProvider'
import { HSep, HStack, ToggleGroup, Txt, VStack } from '~src/components'
import { FullPageLoading } from '~src/components/status'
import { useApiMutation } from '~src/data/useApiQuery'
import { DarkMode } from '~src/utils/forceMode'
import { SinglePlural } from '~src/utils/messages'
import { useKeyboard } from '~src/utils/useKeyboard'
import { fixitForms } from './fixitForms'
import { FixitImage } from './fixitImage'
import { useFixitContext } from './fixitProvider'
import { FixitScanList } from './fixitScanList'
import { RubricXViz } from './rubricXs'

type FixitScansProps = {}

export function FixitScans(props: FixitScansProps) {
  const { selectedSheet } = useFixitContext()

  const images = useNormalizeImages({
    keys: selectedSheet?.scans.map((p) => p.key),
  })

  return (
    <HStack alignItems="start">
      <VStack gap={1} height="100%" overflow="hidden">
        <HStack alignSelf="start" gap={1}>
          <Txt fontSize="sm">Scans</Txt>
          <InternalSheetNav />
        </HStack>
        <FixitScanList />
      </VStack>

      <FixitSummary />

      <VStack
        alignSelf="start"
        bg="#4c4c4c"
        borderRadius="md"
        gap={2}
        height="100%"
        ml={5}
        p={2}
        width="900px"
      >
        {images?.map((img, idx) => {
          const isInferBlank = // todo: gahhhh
            selectedSheet.scans[idx].status === 'infer-blank' ||
            (selectedSheet._actionSet.packet &&
              typeof selectedSheet._actionSet.packet !== 'string' &&
              selectedSheet._actionSet.packet.pageIndices[idx] === null)
          // todo: temporary for 1-page ticket, need to handle sheet and range...
          return (
            <Box
              key={img.src}
              overflow="hidden"
              position="relative"
              width="100%"
            >
              <FixitImage src={img.src} />
              {isInferBlank && (
                <Box
                  bg="blackAlpha.600"
                  p={2}
                  position="absolute"
                  left={0}
                  top={0}
                  width="100%"
                  height="100%"
                >
                  <Tag fontFamily="mono" size="lg" userSelect="none">
                    non-Ponder-Paper-flipside
                  </Tag>
                </Box>
              )}
            </Box>
          )
        })}
      </VStack>
    </HStack>
  )
}

type FixitSummaryProps = {}

function FixitSummary(props: FixitSummaryProps) {
  const {
    batchCount,
    dirty,
    getPayload,
    reviewStats,
    selectedSheet,
    selectedSlot,
    sheets,
  } = useFixitContext()

  const mutation = useApiMutation({
    apiSpec: APIs['fixit.update'],
    useMutationProps: {},
  })

  const size = 'sm'

  const formContainerProps: BoxProps = {
    bg: '#4c4c4c',
    borderRadius: '2xl',
    color: 'white',
    fontSize: size,
    p: 4,
    width: '200px',
  }

  const currentAction = fixitForms[selectedSlot?.type]

  return (
    <VStack gap={2} width="208px">
      <HStack gap={4}>
        <FullPageLoading qResult={mutation}>
          <Button
            colorScheme={dirty ? 'red' : null}
            isDisabled={!dirty}
            onClick={async () => {
              const payload = getPayload()
              //console.log(payload)
              await mutation.mutateAsync(payload)
            }}
          >
            Submit
          </Button>
        </FullPageLoading>
        <Txt flexGrow={1} fontSize="xs" whiteSpace="pre-wrap">
          {dirty && <Icon as={IcoAsterisk} color="yellow.500" />}
          {dirty ? 'Unsaved changes' : 'No changes'}
        </Txt>
      </HStack>
      <HSep />
      <VStack fontSize="sm" gap={0.5}>
        <OfStat
          numIndex={selectedSlot?.sheetIndex}
          denom={sheets.length}
          unit={'sheet'}
        />
        <OfStat {...reviewStats} unit="issue" />
        <OfStat
          numIndex={selectedSheet?.indexOfItsBatch}
          denom={batchCount}
          unit="batch"
        />
      </VStack>
      {currentAction?.Form && (
        <DarkMode>
          <Grid {...formContainerProps} gap={3} placeItems="start center">
            <currentAction.Form />
          </Grid>
        </DarkMode>
      )}
    </VStack>
  )
}

type OfStatProps = XOR<
  XOR<{ num: number }, { numIndex: number }> & { denom: number },
  { axis: ListDigest['success'] }
> & { unit: SinglePlural }

function OfStat(props: OfStatProps) {
  let { axis, num, numIndex, denom, unit } = props

  if (axis) {
    num = axis.selectedIndex + 1
    denom = axis.items.length
  } else if (numIndex != null) {
    num = numIndex + 1
  }

  if (Array.isArray(unit)) {
    unit = unit[0]
  }

  return isNaN(num) ? null : (
    <Txt>
      {capitalize(unit)} {num <= 0 ? ENDASH : num} of {denom}
    </Txt>
  )
}

type InternalSheetNavProps = {}

function InternalSheetNav(props: InternalSheetNavProps) {
  const { isInternal } = useUser()
  const [tookoverKeyboard, setTookoverKeyboard] = useBoolean(false)

  return !isInternal ? null : (
    <>
      {tookoverKeyboard && (
        <KeyboardTakeover onExit={setTookoverKeyboard.off} />
      )}
      <ToggleGroup.Root
        colorScheme="red"
        onChange={(value) =>
          // could probably use toggle, but just in case...
          value ? setTookoverKeyboard.on() : setTookoverKeyboard.off()
        }
        size="xs"
        type="single"
        value={tookoverKeyboard ? 'on' : 'off'}
      >
        <ToggleGroup.Icon
          aria-label="Up and down arrows navigate scans, Esc to exit"
          icon={<IcoSecretDoor />}
          value="on"
        />
      </ToggleGroup.Root>
    </>
  )
}

type KeyboardTakeoverProps = {
  onExit(): void
}

/**
 * todo: this is an internal stopgap
 * The fixit navigation is optimized for jumping to the next issue
 * But in reviewing bubble accuracy, we need to be able to easily go to the next item
 * Would need to think some more, but there are challenges with how that would work with fix-it
 * Thus taking over the screen in this mode for now
 */
function KeyboardTakeover(props: KeyboardTakeoverProps) {
  const { onExit } = props
  const { onSelectSlot, rows, selectedSlot } = useFixitContext()

  const topRow = rows?.[0]
  const topRowId = topRow?.id

  const selectEligibleSlot = (row: FixitSheetRow) => {
    if (!row) {
      return
    }
    // Stay in same column if possible
    const sameColSlot = row.slots[selectedSlot?.type]
    if (sameColSlot?._editable) {
      onSelectSlot(sameColSlot)
    } else {
      // Otherwise pick first available
      let candidates = Object.values(row.slots)
      let next = candidates.find((p) => p._editable)
      onSelectSlot(next)
    }
  }

  // select top editable slot if none selected
  useEffect(() => {
    if (!selectedSlot && topRowId) {
      selectEligibleSlot(topRow)
    }
  }, [topRowId])

  const onKey = (event: KeyboardEvent) => {
    const getRowIndex = () =>
      rows.findIndex((p) => p.id === selectedSlot.sheetId)
    event.stopPropagation()
    switch (event.key) {
      case 'ArrowUp':
        selectEligibleSlot(rows[getRowIndex() - 1])
        break
      case 'ArrowDown':
        selectEligibleSlot(rows[getRowIndex() + 1])
        break
    }
  }

  useKeyboard('up', onKey)
  useKeyboard('down', onKey)

  // todo: make sure something is selected to start
  // todo: ability to navigate in these directions
  // todo: probably only show for internal folks to start
  // todo: probably start with a dialog to capture key events?
  return (
    <Modal isOpen={true} onEsc={onExit} onClose={onExit} size="full">
      <ModalOverlay />
      <ModalContent bg="transparent">
        {selectedSlot && (
          <HStack
            bg="white"
            color="purple.700"
            fontSize="3xl"
            fontWeight="bold"
            gap={4}
            p={3}
            position="absolute"
            right="30%"
          >
            {selectedSlot?.score}
            <RubricXViz data={selectedSlot.rubric} size={40} />
          </HStack>
        )}
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  )
}
