import {
  parseAbsolute,
  toTimeZone,
  ZonedDateTime,
} from '@internationalized/date'
import { LoadRun } from '@paper/schema'

type ScheduleItem = { hour: number; tz: string }

type GetNearbySchedSlotsProps = {
  schedule: ScheduleItem[]
  target: ZonedDateTime
  durationHrs: number
  firstDay: string
  lastDay: string
}

/**
 * Returns `{ next: ZonedDateTime, recent: ZonedDateTime[] }`
 * where `recent` is `schedule` items that fall between `target` and `target - durationHrs`
 * and `next` is the next item on the schedule
 */
export const getNearbySchedSlots = (props: GetNearbySchedSlotsProps) => {
  const { schedule, target, durationHrs, firstDay, lastDay } = props

  // Convert firstDay to midnight of the timezone
  const zonedMin = parseAbsolute(`${firstDay}T00:00:00Z`, target.timeZone)
  // Convert lastDay to the end of the day in that timezone
  const zonedMax = parseAbsolute(`${lastDay}T23:59:59Z`, target.timeZone)

  let next = findNearestScheduleRun(schedule, target, 1)
  // next must be before the cutoff
  if (next?.compare(zonedMax) > 0) {
    next = null
  }
  const recent: ZonedDateTime[] = []
  const end = target.subtract({ hours: durationHrs })

  // Cap backwards target at max
  const cappedTarget = target.compare(zonedMax) > 0 ? zonedMax : target
  let candidate = findNearestScheduleRun(schedule, cappedTarget, -1)
  // todo: support both directions?

  while (
    candidate &&
    candidate.compare(end) > 0 &&
    candidate.compare(zonedMin) > 0
  ) {
    // push first since valid
    recent.push(candidate)
    // calculate next candidate
    candidate = findNearestScheduleRun(
      schedule,
      candidate.add({ hours: -1 }),
      -1
    )
  }

  return { next, recent }
}

/**
 * Returns a ZonedDateTime of items on the `schedule` to `target` in `direction`
 * @param schedule
 * @param target
 * @param direction
 * @returns
 */
export const findNearestScheduleRun = (
  schedule: ScheduleItem[],
  target: ZonedDateTime,
  direction: 1 | -1 = -1
) => {
  let nearest: ZonedDateTime = null
  for (let item of schedule) {
    const { hour, tz } = item

    const todaysRun = toTimeZone(target, tz).set({
      hour,
      minute: 0,
      second: 0,
      millisecond: 0,
    })

    const candidate =
      direction === -1
        ? todaysRun.compare(target) <= 0
          ? todaysRun
          : todaysRun.subtract({ days: 1 })
        : todaysRun.compare(target) > 0
        ? todaysRun
        : todaysRun.add({ days: 1 })

    if (!nearest) {
      nearest = candidate
    } else if (direction === -1 && candidate.compare(nearest) > 0) {
      nearest = candidate
    } else if (direction === 1 && candidate.compare(nearest) < 0) {
      nearest = candidate
    }
  }
  return nearest
}

type GetSchedStatusProps = {
  /** Base time in unix ms */
  fetchedAt: number
  /** last `LoadRun` as calculated for `LoadStatus.recentSlots` */
  lastRun: LoadRun
  /** amount of time after the job starts to finish */
  leewayMinutesRun: number
  /** amount of time after the scheduled run for the job to show up */
  leewayMinutesStart: number
}

/**
 * Returns top-level status for job
 */
export const getSchedJobStatus = (props: GetSchedStatusProps) => {
  const { fetchedAt, lastRun, leewayMinutesRun, leewayMinutesStart } = props

  let isNormal = false

  if (!lastRun) {
    isNormal = true // no run is normal
  } else if (lastRun.status === 'success') {
    isNormal = true // success
  } else if (lastRun.status === 'locked') {
    // locked/in-progress is normal...as long as not too long
    isNormal = fetchedAt <= lastRun.runStart + leewayMinutesRun * 60_000
  } else if (!lastRun.status) {
    // missing is normal, also if it hasn't been too long
    isNormal = fetchedAt <= lastRun.jobSet.date + leewayMinutesStart * 60_000
  }

  return isNormal
}
