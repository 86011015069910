import type { PDFDocument } from '@paper/pdf'
import { createSlice, current, PayloadAction } from '@reduxjs/toolkit'
import type { WizState } from './wizard/wizReducer'

export type PublishPage =
  | 'entryAnswerKey'
  | 'entryPdf'
  | 'home'
  | 'new'
  | 'preinit'

export type PubState = {
  __wizStub__?: WizState
  _contentIdOrNew: string
  contentId: string
  error?: Error
  openedPdf?: {
    srcBuf: ArrayBuffer
    srcDoc: PDFDocument
    waitingForPackets: boolean
  }
  page: PublishPage
}

type PA<K extends keyof PubState> = PayloadAction<Pick<PubState, K>>
type OpenPdfAction = PayloadAction<
  { contentId: string } & Pick<PubState['openedPdf'], 'srcBuf' | 'srcDoc'>
>

const getInitialState = (): PubState => ({
  /** store previous route value so we can detect changes */
  _contentIdOrNew: null,
  contentId: null,
  error: null,
  page: 'preinit',
})

export const pubSlice = createSlice({
  name: 'publish',
  initialState: getInitialState,
  reducers: {
    error(draft, action: PA<'error'>) {
      draft.error = action.payload.error
    },
    /**
     * @example
     * useEffect(() => {
     *  dispatch(handleRouter({ contentId: routeData.contentId }))
     * }, [routeData.contentId])
     */
    handleRouter(draft, action: PayloadAction<{ contentIdOrNew: string }>) {
      const { contentIdOrNew } = action.payload

      if (contentIdOrNew !== draft._contentIdOrNew) {
        // console.log('handleRouter', contentIdOrNew, draft.page)
        const isNew = contentIdOrNew === 'new'
        const contentId = isNew ? null : contentIdOrNew
        const page = isNew ? 'new' : 'home'

        // overwrite state if contentId changed
        if (isNew || contentId !== draft.contentId) {
          return {
            _contentIdOrNew: contentIdOrNew,
            contentId,
            page,
          }
        } else {
          // otherwise just do updates
          // todo: this is complicated (and possibly broken!)
          // without this, there's a race condition between the above reset and the packetData coming in...
          // todo: what i actually need is a better way to keep "good" query data in sync :((((
          draft._contentIdOrNew = contentIdOrNew
          draft.page = page
        }
      }
    },
    /**
     * Reset things on opened pdf
     */
    openPdf(draft, action: OpenPdfAction) {
      let { contentId, srcBuf, srcDoc } = action.payload
      // merge
      draft.contentId = contentId
      draft.openedPdf = { srcBuf, srcDoc, waitingForPackets: true }
    },
    /**
     * @example
     * const setRawImages = (images: Blob[]) => {
     *  dispatch(_shallowMerge({ state: { rawImages: images }}))
     * }
     */
    _shallowMerge(draft, action: PayloadAction<{ state: Partial<PubState> }>) {
      return { ...draft, ...action.payload.state }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      () => true,
      (state, action) => {
        // logging for dev
        console.log(`[${action.type}]`, action.payload, current(state))
      }
    )
  },
})
