import { Box, Grid, Icon, IconButton } from '@chakra-ui/react'
import { IcoFunnel, IcoInfo } from '@paper/icons'
import { SMALLDOT } from '@paper/utils'
import { Fragment, ReactNode } from 'react'
import { AnswerKey } from '~src/blocks/answerKey'
import { BaseHeader, HStack, Txt, VSep, VStack } from '~src/components'
import { LoadingDots } from '~src/components/status'
import { StdDigest } from '~src/data/data-questions'
import { useHackyFade } from '~src/utils/useAnimation'
import { AnswerFilter } from './answerFilter'
import { useQStdContext } from './qStdProvider'
import { StdPopover } from './stdPopover'
import { CharLabel, StdQNav } from './stdQNav'

// width animation props
const widthMs = {
  delay: 100,
  duration: 500,
}

// ugh, layout problems
const magicNos = { py: 2, contentHeight: '72px' }

const useHudFade = () => {
  const { fadeTransition, opacity } = useHackyFade({
    delay: widthMs.delay + widthMs.duration,
    duration: 300,
    enabled: true,
    isActive: true,
  })
  return { opacity, transition: fadeTransition }
}

export function BargraphHud() {
  const { digest, mode } = useQStdContext()
  // todo: as with <BarGraph /> still figuring how to handle the different modes
  const isStdMode = mode === 'std'
  const selectedItem = digest.success?.selectedItem

  let children: ReactNode = null

  if (!selectedItem) {
    children = <LoadingDots height={magicNos.contentHeight} mt={-2} />
  } else if (isStdMode) {
    children = <StdModeHud />
  } else {
    children = <QModeHud />
  }

  return (
    <VStack
      alignItems="stretch"
      backgroundColor="RGBA(255, 255, 255, .8)"
      boxShadow={BaseHeader.BoxShadow}
      data-cy="bargraph-hud"
      height="100%"
      // overflow='hidden' todo: overflow hidden breaks layout with absolute positioned info icon
      position="relative"
      py={magicNos.py}
      roundedBottom="12px"
      transition={`width ${widthMs.duration}ms ${widthMs.delay}ms ease`}
      width={isStdMode ? '400px' : '250px'}
    >
      {children}
    </VStack>
  )
}

/**
 * Assumes we're already in jump-to-q with a selected item
 */
function QModeHud() {
  const { packet, qDigest } = useQStdContext()
  const fade = useHudFade()
  // Check if we have answer data
  // todo: there's probably a better way, and should probably be part of context
  const hasAnswers = !qDigest.success?.items.every((item) => isNaN(item.outOf))

  if (hasAnswers) {
    const qAgg = qDigest.success.selectedItem
    return (
      <HStack {...fade} flexGrow={1} gap={4} justifyContent="center" p={4}>
        {/* Answer label */}
        <CharLabel
          char="A"
          icon={<Icon as={IcoFunnel} fontSize="xs" />}
          subtext={qAgg.type === 'GRID' ? '(pts)' : null}
        />
        {/* Answer filter */}
        <HStack flexGrow={1} justifySelf="center">
          <AnswerFilter {...qAgg} />
        </HStack>
      </HStack>
    )
  } else {
    // No answer data so show AnswerKey snippet
    const qAgg = qDigest.success.selectedItem
    const pmQ = packet.questions[qAgg.qIndex]
    const pmPs = [] // could include passages, but need to fix layout
    //packetmeta.content.passages.filter((p) =>
    //   pmQ.passageIds.includes(p.id)
    // )
    const chunks = [{ fingerprint: qAgg.id, passages: pmPs, questions: [pmQ] }]
    return (
      <VStack {...fade} justifyContent="center">
        <Box fontSize="xs" pl={2} textAlign="center">
          No answer data for this packet
        </Box>
        <Box>
          <AnswerKey.Guts chunks={chunks} />
        </Box>
      </VStack>
    )
  }
}

/**
 * Assumes we're already in jump-to-std with a selected item
 */
function StdModeHud() {
  const { digest } = useQStdContext()
  const fade = useHudFade()
  const stdDigest = digest as StdDigest
  const stdAgg = stdDigest.success?.selectedItem

  const segments = stdAgg.label.split(/[.]/)

  return (
    <Grid
      alignItems="center"
      gridTemplateColumns="150px auto 1fr"
      gridTemplateRows="auto"
      height="100%"
      justifyItems="center"
      position="relative"
      {...fade}
    >
      <StdPopover
        placement="bottom-start"
        trigger={
          <IconButton
            aria-label="Standard details"
            icon={<IcoInfo />}
            bottom={`-${magicNos.py * 4}px`} // account for parent's padding
            isRound={true}
            left="0"
            position="absolute"
            size="xs"
            variant="ghost"
          />
        }
      />
      <Txt
        fontFamily="mono"
        fontSize="md"
        lineHeight={1.35}
        // todo: hardcoding because i've messed up the layout in other places...
        maxHeight="100%"
        overflow="hidden"
        position="relative"
        px={2}
        // as of January 2024, this seems to work on Chrome/Firefox/Safari
        style={{
          display: '-webkit-box',
          // @ts-expect-error
          textWrap: 'balance',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 3,
        }}
        textAlign="center"
        width="100%"
      >
        {segments.map((seg, idx) => (
          <Fragment key={idx}>
            {seg}
            {idx !== segments.length - 1 && (
              <>
                {SMALLDOT}
                <wbr />
              </>
            )}
          </Fragment>
        ))}
      </Txt>
      <VSep />
      <StdQNav qs={stdAgg.qs} />
    </Grid>
  )
}
