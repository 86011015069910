import { Alert, AlertIcon, BoxProps, Text } from '@chakra-ui/react'
import type { PDFDocument } from 'pdf-lib'
import { Fragment, useCallback, useState } from 'react'
import { FileInput } from '~src/components'
import { readFile } from '~src/utils/file'

type PdfInputProps = {
  disabled?: boolean
  onError?(error: Error): any
  onFile?(file: File): any
  onPdf(pdfDoc: PDFDocument, arrBuf: ArrayBuffer): any
} & BoxProps

export function PdfInput(props: PdfInputProps) {
  const { disabled, onError, onFile, onPdf, ...passThrough } = props

  const [error, setError] = useState<string>()

  const onDrop = useCallback(
    async (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        if (
          acceptedFiles.length === 0 &&
          fileRejections[0].errors.some(({ code }) => {
            return code === 'file-invalid-type'
          })
        ) {
          // #50 Specific message if 1 file and not 1 pdf
          setError(`Whoops, wrong file type! Your file must be a PDF.`)
          return
        }
        // Generic message for mismatch errors
        setError(`Whoops! You can only upload one file at a time.`)
        return
      }

      // Clear error
      setError(null)
      const file = acceptedFiles[0]
      // Emit onFile
      onFile && onFile(file)
      // Read pdf
      try {
        const { PDFWrap } = await import('@paper/pdf')
        const arrBuf = await readFile(file)
        const pdfDoc = await PDFWrap.load(arrBuf)
        onPdf(pdfDoc, arrBuf)
      } catch (err) {
        setError('Unable to read PDF :(')
        onError && onError(err)
      }
    },
    [onPdf]
  )

  return (
    <FileInput
      borderWidth="5px"
      {...passThrough}
      dropzone={{
        accept: { 'application/pdf': ['.pdf'] },
        disabled,
        multiple: false,
        onDrop,
      }}
      pos="relative"
    >
      {(isDragActive) => (
        <Fragment>
          <Text>
            {isDragActive
              ? 'Drop to open'
              : 'Drop a PDF here or click to browse'}
          </Text>
          {error && !isDragActive && (
            <Alert top={8} maxW="80%" pos="absolute" status="error">
              <AlertIcon />
              {error}
            </Alert>
          )}
        </Fragment>
      )}
    </FileInput>
  )
}
