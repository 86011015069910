import { Tag, TagCloseButton, TagLabel } from '@chakra-ui/react'
import { RouterAction, useRouter } from '@paper/route'
import { Curriculum, Teacher } from '@paper/schema'
import { orderBy } from 'lodash'
import {
  ReactNode,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { ComboBoxProps, HStack } from '~src/components'
import { useDirectoryData } from '~src/data/data-directory'
import { usePacketListData } from '~src/data/data-packets'
import { getSampleString } from '~src/utils/messages'
import { useFilters } from '~src/utils/useFilters'
import { MBoxActionList } from './monsterActionList'
import { DirAdapters, DirItem, toDirItem } from './monsterAdapters'
import { DirListItem } from './monsterListItem'

export type NavAction = { action: RouterAction | MonsterBoxAction } & (
  | { icon: any; iconOnly: true; label: string }
  | { icon?: any; iconOnly?: false; label: ReactNode }
)

export type MonsterBoxMode =
  | 'dir-base'
  | 'curriculum'
  | 'teacher-no-curriculm'
  | 'teacher-curriculum'

export type MonsterBoxActionState = {
  curriculum?: Curriculum
  mode: MonsterBoxMode
  teacher?: Teacher
}

export type MonsterBoxAction = {
  type: 'setCurriculum' | 'setTeacher'
} & Partial<MonsterBoxActionState> // todo: should probably use the redux action creator mojo

type useMonsterBoxItemProps = {
  curriculumId: string
  mode: MonsterBoxMode
  teacherId: string
}

function useMonsterBoxItemPool(props: useMonsterBoxItemProps) {
  const { curriculumId, mode, teacherId } = props

  const qResultDir = useDirectoryData()
  // get packets if relevant
  const { qResult: qResultPkt } = usePacketListData({
    override: { curriculumId, teacherId },
  })

  const busy = qResultDir.isFetching || qResultPkt.isFetching
  // todo: this is dangerous and confusing as usePacketList.data !== usePacketList.qResult.data
  const packets = curriculumId ? qResultPkt.data : null

  // todo: have written this somewhere...
  const getCurriculaFromTeacherId = (teacherId: string) => {
    const dirData = qResultDir.data
    const curriculumIdSet = dirData.teacher.map.get(teacherId).curriculumIds
    return Array.from(curriculumIdSet).map(
      (curriculumId) => dirData.curriculum.map.get(curriculumId)?.item
    )
  }

  // Common item interface
  const allItems = useMemo(() => {
    if (busy) {
      return []
    } else if (packets) {
      // if there are packets, we're showing that
      return (
        packets
          // todo: workaround for #265
          .filter((p) => p.pub.stage === 'published')
          .map((data) => toDirItem(DirAdapters.packet, data))
      )
    } else if (teacherId) {
      // if there's a teacher, but no packets, we need a curriculum
      return getCurriculaFromTeacherId(teacherId).map((data) => {
        return toDirItem(DirAdapters.curriculum, data)
      })
    } else if (!qResultDir.data) {
      // no directory data, nothing to return (though probably caught in the 'busy' clause)
      return []
    }

    // otherwise, combine CSTs
    const activeCurricula = qResultDir.data.activeCurricula
    const curriculumMap = qResultDir.data.curriculum.map
    const schoolMap = qResultDir.data.school.map
    const teacherMap = qResultDir.data.teacher.map

    let items: DirItem[] = []

    teacherMap.forEach((value) => {
      const curricula = Array.from(value.curriculumIds).map(
        (id) => curriculumMap.get(id).item
      )
      const schools = Array.from(value.schoolIds).map(
        (id) => schoolMap.get(id).item
      )
      const termsB = [
        ...curricula.flatMap((data) => DirAdapters.curriculum.toTerms(data)),
        ...schools.flatMap((data) => DirAdapters.school.toTerms(data)),
      ]

      const data = {
        ...value.item,
        // show what curricula they use
        families: getSampleString({
          items: curricula,
          toString: (c) => c.family,
        }),
      }

      items.push(toDirItem(DirAdapters.teacher, data, termsB))
    })

    schoolMap.forEach((value) => {
      items.push(toDirItem(DirAdapters.school, value.item))
    })

    activeCurricula.forEach((value) => {
      items.push(toDirItem(DirAdapters.curriculum, value))
    })

    return items
  }, [busy, mode, qResultDir.data, packets])

  return { busy, allItems }
}

export function useMonsterBoxState() {
  const limit = 6 // todo: combobox doesn't implement virtual
  // plausibly worth using redux mojo, but so far doesn't seem to save much
  // the other benefit i forgot is passing nav actions...
  const [curriculum, setCurriculum] = useState<Curriculum>()
  const [teacher, setTeacher] = useState<Teacher>()
  const [highlightedIndex, setHighlightedIndex] = useState(-1)
  const [actionIndex, setActionIndex] = useState(0)
  const [inputValue, setInputValue] = useState('')
  const inputRef = useRef<HTMLInputElement>()

  // i guess clear is the most annoying/likely to forgot to update
  const clear = useCallback(() => {
    setCurriculum(null)
    setTeacher(null)
    setActionIndex(0)
  }, [])

  // summarize
  const mode = useMemo<MonsterBoxMode>(() => {
    return curriculum && teacher
      ? 'teacher-curriculum'
      : curriculum
      ? 'curriculum'
      : teacher
      ? 'teacher-no-curriculm'
      : 'dir-base'
  }, [curriculum, teacher])

  // get all items
  const { allItems, busy } = useMonsterBoxItemPool({
    curriculumId: curriculum?.id,
    mode,
    teacherId: teacher?.id,
  })

  // get filtered items
  const trimmed = inputValue.trim()
  const filters = useFilters()
  const comboPropsSubset = useMemo(() => {
    // score each item for sorting/filtering
    type Scored<T> = T & { _score?: number }
    let scored = allItems as Scored<DirItem>[]
    let queryWords = trimmed.split(/\s+/)

    if (trimmed) {
      scored = scored.map((item) => {
        const scoring = [
          [item.termsA, 1.5],
          [item.termsB, 1],
        ] as const

        let _score = 0
        for (let inputWord of queryWords) {
          nextword: for (let [terms, pts] of scoring) {
            for (let term of terms) {
              if (filters.startsWith(term, inputWord)) {
                _score += pts
                break nextword // each query word only counts for points once
              }
            }
          }
        }

        return { _score, ...item }
      })

      // filter
      scored = scored.filter((p) => p._score >= queryWords.length)

      // sort
      scored = orderBy(scored, (p) => p._score, 'desc')
    }

    // slice
    let items = scored.slice(0, limit)

    return { items, selectedItem: null }
  }, [allItems, trimmed])

  const highlighted = comboPropsSubset.items[highlightedIndex]
  useLayoutEffect(() => {
    if (highlighted) {
      setActionIndex(0) // reset on highlight change
    }
  }, [highlighted])

  // todo: too many things named action
  const navActions = useMemo(() => {
    const navActions =
      highlighted?.adapter.getActions(highlighted.data, {
        curriculum,
        mode,
        teacher,
      }) ?? []
    return navActions
  }, [highlighted, mode, curriculum, teacher])

  // console.log(`[actionIndex]`, actionIndex)
  const cycleActions = (direction: 1 | -1) => {
    //console.log('[cycleActions]', direction)
    setActionIndex((last) => (last + direction) % navActions.length)
  }

  const { dispatchRoute } = useRouter()
  const dispatchAction = (action: MonsterBoxAction | RouterAction) => {
    if (action.type === 'navigate') {
      dispatchRoute(action)
    } else if (action.type === 'setCurriculum') {
      setCurriculum(action.curriculum)
    } else if (action.type === 'setTeacher') {
      setTeacher(action.teacher)
    } else {
      console.warn(`Unrecognized action`, action)
    }
    setInputValue('') // clear input on action
  }

  const breadcrumb = useMemo(() => {
    if (!curriculum && !teacher) {
      return null
    }
    // todo: we have limited space...
    const tags = [
      [teacher?.lastName, setTeacher],
      [curriculum?.name, setCurriculum],
    ] as const
    return (
      <HStack gap={2} userSelect="none">
        {tags.map(
          ([label, action]) =>
            label && (
              <Tag key={label} size="sm">
                <TagCloseButton
                  data-cy="close-tag"
                  // move to other side
                  ml="-2px"
                  mr="2px"
                  onClick={() => action(null)}
                />
                <TagLabel>{label}</TagLabel>
              </Tag>
            )
        )}
      </HStack>
    )
  }, [clear, curriculum, teacher])

  const handleSelect = useCallback(
    (thisIndex?: number) => {
      if (thisIndex != null) {
        setActionIndex(thisIndex)
      }
      const action = navActions[thisIndex ?? actionIndex]?.action
      // todo: proper handling of {enter} when there's no item selected
      if (action) {
        dispatchAction(action)
      }
    },
    [navActions, actionIndex]
  )

  const renderItem = useCallback<ComboBoxProps['renderItem']>(
    (item: DirItem, details) => {
      const { colorScheme, icon, getText, renderData } = item.adapter
      const { isHighlighted } = details

      return (
        <MBoxActionList
          isOpen={isHighlighted}
          items={navActions}
          onSelect={handleSelect}
          selectedIndex={actionIndex}
        >
          <DirListItem colorScheme={colorScheme} icon={icon}>
            {(renderData ?? getText)(item.data)}
          </DirListItem>
        </MBoxActionList>
      )
    },
    [navActions, actionIndex, handleSelect, highlightedIndex]
  )

  const comboProps: ComboBoxProps<DirItem> = {
    ...comboPropsSubset,
    autoFocus: true,
    busy,
    inputRef,
    inputValue,
    // open if text or on subsequent steps
    isOpen: inputValue.length > 0 || mode !== 'dir-base',
    itemToString: (item) => item.adapter.getText(item.data),
    onHighlightChange: setHighlightedIndex,
    onInputValueChange: (changes) => setInputValue(changes.inputValue),
    onKeyDown: (event, preventDefaults) => {
      if (event.key === 'Enter') {
        handleSelect()
        preventDefaults()
      } else if (event.key === 'Escape') {
        clear()
      }
    },
    onSelect: (item) => {
      //console.log(navActions[actionIndex].action)
    },
    onTab: (highlightedItem, preventDefaults, event) => {
      if (mode === 'dir-base' && !inputValue) {
        return
      }
      preventDefaults()
      cycleActions(event.shiftKey ? -1 : 1)
    },
    placeholder:
      mode === 'teacher-no-curriculm'
        ? 'Then a curriculum'
        : mode === 'dir-base'
        ? 'Curriculum, School, or Teacher'
        : 'Packet name or number',
    renderItem,
  }

  return { breadcrumb, comboProps }
}
