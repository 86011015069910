import { Button, Checkbox, CheckboxGroup, HStack } from '@chakra-ui/react'
import { ResultOf } from '@paper/api-specs'
import { IcoArrowBack } from '@paper/icons'
import { TableContainer } from '~src/blocks/tableHelpers'
import { Txt, VStack } from '~src/components'
import { UghColumn, UghTable } from '~src/components/table'
import { formatUnits } from '~src/utils/messages'
import { FieldUI } from '../publish/formHelpers'
import { useStickerForm } from './stickerFormBase'

type StudentItem = ResultOf<'sticker.validate'>[number]['items'][number]
type Col = UghColumn<StudentItem>

const StudentNumber: Col = {
  props: { align: 'start', width: 150 },
  label: () => 'Student number',
  cell: (item) => item.student?.number ?? item.input,
}

const LastFirst: Col = {
  props: { align: 'start' },
  label: () => 'Last, First',
  cell: (item) =>
    item.student?.lastfirst ?? (
      <Txt color="red.500" fontWeight={400}>
        Not found
      </Txt>
    ),
}

const stickerStudentColumns = [StudentNumber, LastFirst]

type StickerStudentPreviewProps = {}

export function StickerStudentPreview(props: StickerStudentPreviewProps) {
  const { allStudents, resetStudentStage, setSelectedLists, values } =
    useStickerForm()

  return (
    <TableContainer fontSize="xs" gap={4}>
      <HStack gap={4}>
        <Button
          leftIcon={<IcoArrowBack />}
          onClick={() => resetStudentStage()}
          size="sm"
        >
          Back
        </Button>
        <Txt fontSize="md">
          {formatUnits(allStudents.length, 'student')},{' '}
          {formatUnits(values._errorCount, 'error')}
          {values._errorCount > 0 && (
            <Txt as="span" display="block" fontSize="xs">
              Items with errors will be skipped with no stickers generated
            </Txt>
          )}
        </Txt>
      </HStack>
      <FieldUI
        label="Lists"
        input={
          <VStack alignItems="start" gap={2} p={1}>
            <CheckboxGroup
              onChange={setSelectedLists}
              size="md"
              value={values._selectedListKeys}
            >
              {values._allLists.map((p, idx) => (
                <Checkbox key={idx} value={p.name}>
                  <Txt fontSize="sm">{p.name}</Txt>
                </Checkbox>
              ))}
            </CheckboxGroup>
          </VStack>
        }
      />
      <UghTable
        aria-label={'Students'}
        columns={stickerStudentColumns}
        data={allStudents}
        spacingX="snug"
        spacingY="snug"
      />
    </TableContainer>
  )
}
