import { ResultOf } from '@paper/api-specs'
import { useRouter } from '@paper/route'
import { DirPacket, School } from '@paper/schema'
import { useCallback, useEffect, useMemo } from 'react'
import { useStaticFn } from '~src/blocks/list/listCallbacks'
import { PacketExpandee } from '~src/blocks/packetTable'
import { schoolPacketColumns } from '~src/blocks/packetTable/packetTableCols'
import { PdfBuilder } from '~src/blocks/pdfBuilder'
import { PdfBuilderProvider } from '~src/blocks/pdfBuilder/pdfBuilderProvider'
import { usePickerBase, useSchoolPicker } from '~src/blocks/pickers'
import { TableContainer } from '~src/blocks/tableHelpers'
import { ComboBox, TextStack, Txt } from '~src/components'
import { UghTable } from '~src/components/table'
import { useDirectoryData } from '~src/data/data-directory'
import { useSchoolPanelData } from '~src/data/data-schoolPanel'
import { UseApiQueryResult } from '~src/data/useApiQuery'
import type { RD_Home_School } from '~src/routelist'
import { HomePanel } from './homePanel'

export const useSchoolFromRouter = () => {
  const { dispatchStay, routeData, useAirlock } = useRouter<RD_Home_School>()
  const { data: dirData, isSuccess: isDirDataSuccess } = useDirectoryData()

  const school = dirData?.school.map.get(routeData.schoolId)?.item
  const schComboProps = useSchoolPicker({ value: school?.id })

  // select a school if there's a teacherId passed in
  const schoolFromTeacherId =
    !routeData.schoolId &&
    routeData.teacherId &&
    dirData?.teacher.map.get(routeData.teacherId)?.schoolIds.keys().next().value

  useAirlock(
    { schoolId: schoolFromTeacherId },
    schoolFromTeacherId && schoolFromTeacherId !== routeData.schoolId
  )

  const dispatchSchool = useCallback((item: School) => {
    dispatchStay({ schoolId: item.id })
  }, [])

  return { dispatchSchool, isDirDataSuccess, schComboProps, school }
}

export function SchoolPanel() {
  const { dispatchStay, routeData } = useRouter<RD_Home_School>()

  const { isDirDataSuccess, schComboProps, school } = useSchoolFromRouter()

  const { data, pg, qResultPg, qResultSch } = useSchoolPanelData(routeData)

  // select a packet group if there's packetId passed in
  const pgFromPacketId =
    !routeData.pgId &&
    routeData.packetId &&
    qResultSch.isSuccess &&
    qResultSch.data.packetGroups.find((pg) =>
      // todo: probably create a lookup!
      pg.packets.some((p) => p.id === routeData.packetId)
    )?.id

  // todo: for reasons that aren't 100% clear, these are interleaving updates when using useLayoutEffect
  // todo: the router uses useLayoutEffect in its useStateAndRef, so maybe there's a race condition :(
  // todo:todo: the router no longer uses useLayoutEffect
  // todo:todo: useAirlock doesn't support changing if value has changed?
  useEffect(() => {
    if (pgFromPacketId) {
      dispatchStay({ pgId: pgFromPacketId })
    }
  }, [pgFromPacketId])

  const pgComboProps = usePickerBase(
    { isLoading: qResultSch.isFetching, data: qResultSch.data?.packetGroups },
    {
      filterer: (filters, item, inputValue) =>
        filters.contains(item.number, inputValue),
      selector: (item, value) => item.id === value,
      value: routeData.pgId,
    }
  )

  return (
    <HomePanel
      name={school?.name ?? 'School'}
      table={<SchoolPacketTable qResult={qResultPg} />}
      pickers={
        <>
          <ComboBox
            {...schComboProps}
            caret={true}
            inputTextAlign="center"
            onChange={(item) => dispatchStay({ schoolId: item.id })}
            openIfNoSelection={true}
            placeholder="Select a school"
            renderItem={(item) => (
              <Txt as="span" fontSize="sm">
                {item.name}
              </Txt>
            )}
            round={true}
            size="md"
            variant="filled"
            width="300px"
          />
          <ComboBox
            {...pgComboProps}
            caret={true}
            fontFamily="mono"
            inputTextAlign="center"
            itemToString={(pg) => pg.number}
            onChange={(item) => dispatchStay({ pgId: item.id })}
            openIfNoSelection={isDirDataSuccess && !!schComboProps.selectedItem}
            placeholder={
              !!schComboProps.selectedItem && 'Select a packet group'
            }
            renderItem={renderPacketGroup}
            round={true}
            size="md"
            variant="filled"
            width="300px"
          />
          {data && (
            <PdfBuilderProvider
              data={data}
              isActive={true}
              packetGroup={pg}
              parentView="school"
              schoolId={school?.id}
            >
              <PdfBuilder />
            </PdfBuilderProvider>
          )}
        </>
      }
    />
  )
}

function renderPacketGroup(
  pg: ResultOf<'school.listpackets'>['packetGroups'][number]
) {
  return (
    <TextStack>
      <TextStack.Top>{pg.number}</TextStack.Top>
      <TextStack.Bottom fontFamily="body">
        {pg.packets.length === 1
          ? pg.packets[0].name
          : `${pg.packets.length} packets`}
      </TextStack.Bottom>
    </TextStack>
  )
}

type SchoolPacketTableProps = {
  qResult: UseApiQueryResult<'school.packetgroup'>
}
function SchoolPacketTable(props: SchoolPacketTableProps) {
  const { dispatchStay, routeData } = useRouter<RD_Home_School>()

  // todo:
  const data = useMemo(() => {
    return props.qResult.data?.map((item) => item.packet)
  }, [props.qResult.data])

  // todo: copy/pasted from <PacketTable />

  const getId = useStaticFn((item: any) => {
    return item.teacherId + (item.id ?? item.packetId)
  })

  if (props.qResult.isPending && props.qResult.fetchStatus === 'idle') {
    return null // waiting for outside inputs
  }

  return (
    <TableContainer qResult={props.qResult}>
      <UghTable
        aria-label="Packet list"
        columns={schoolPacketColumns}
        data={data}
        data-cy="home-packet-table"
        Expandee={PacketExpandee}
        expandeeHeight={56}
        getId={getId}
        height="100%"
        onSelect={(item: DirPacket) =>
          dispatchStay({
            curriculumId: item.curriculumId,
            packetId: item.id,
            teacherId: item.teacherId,
          })
        }
        selectedId={getId(routeData)}
      />
    </TableContainer>
  )
}
