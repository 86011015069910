import { PlacementWithLogical } from '@chakra-ui/react'
import { IcoNoScore } from '@paper/icons'
import { FixitBubbleStatus } from '@paper/schema'
import { MISSING_COLOR } from '@paper/styles'
import { IcoScanIssue } from '~src/blocks/icoScanIssue'
import { Mono, TooltippedIcon } from '~src/components'
import { formatScore } from '~src/utils/messages'

type FixitScoreProps = {
  placement?: PlacementWithLogical
  score: number
  status: FixitBubbleStatus
}

function FixitScore(props: FixitScoreProps) {
  const { placement, status, score } = props
  return status === 'success' ? (
    <Mono>{formatScore(score)}</Mono>
  ) : (
    <TooltippedIcon
      aria-label={status === 'empty' ? 'No score' : 'Unreadable score'}
      as={status === 'empty' ? IcoNoScore : (IcoScanIssue as any)}
      color={MISSING_COLOR}
      tooltipProps={{ placement }}
    />
  )
}

export const FixitSym = {
  Score: FixitScore,
}
