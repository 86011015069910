import { IconButton } from '@chakra-ui/react'
import { IcoArrowDown, IcoArrowUp } from '@paper/icons'
import { useStateAndRef } from '@paper/route/src/utils'
import { PageItem } from '@paper/schema'
import { useCallback } from 'react'
import { AnswerKey, useChunked, useUnpagedIds } from '~src/blocks/answerKey'
import { ImageViewer } from '~src/blocks/imageViewer'
import { Txt, VStack } from '~src/components'
import { Wiz } from '../publishLayout'
import { useSetWizCurStepError, useWizContext } from './wizProvider'

export function FormPages() {
  const { formik, imageUrls } = useWizContext()
  const { setFieldValue, values } = formik

  const { pages, passages, questions } = values

  const answerKeyProps = useChunked({ passages, questions })
  const unusedIds = useUnpagedIds({ pages, passages, questions })

  const [curPageIndex, setCurPageIndex, curPageIndexRef] = useStateAndRef(0)
  const isLastPage = curPageIndex === pages.length - 1
  const isFirstPage = curPageIndex === 0

  // hoist validation error to formik
  useSetWizCurStepError(isLastPage ? null : `You must review all pages`)

  // Change handler for the CheckboxAnswerKey
  const setSelected = useCallback((selected: PageItem[]) => {
    setFieldValue(`pages.${curPageIndexRef.current}`, { items: selected })
  }, [])

  const pageChanger = (amt: number) => () => setCurPageIndex(curPageIndex + amt)

  return (
    <Wiz.FormArea
      alignItems="stretch"
      display="flex"
      gap={8}
      overflowX="auto"
      overflowY="hidden"
      sx={{ '>*': { flexShrink: 0 } }}
    >
      <AnswerKey.Container
        minWidth="280px"
        sizingMode="measure-width-flex-shrink"
      >
        <AnswerKey.CheckboxGuts
          {...answerKeyProps}
          onChange={setSelected}
          unusedIds={unusedIds}
          values={pages[curPageIndex].items}
        />
      </AnswerKey.Container>
      {pages.length > 1 && (
        <VStack gap={4}>
          <IconButton
            aria-label="Previous page"
            data-cy="prev-page"
            icon={<IcoArrowUp />}
            isDisabled={isFirstPage}
            onClick={pageChanger(-1)}
          />
          <Txt fontSize="sm" textAlign="center">
            {curPageIndex + 1}/{pages.length}
          </Txt>
          <IconButton
            aria-label="Next page"
            data-cy="next-page"
            icon={<IcoArrowDown />}
            isDisabled={isLastPage}
            onClick={pageChanger(1)}
          />
        </VStack>
      )}
      <ImageViewer imageType="blank" url={imageUrls.blank?.[curPageIndex]} />
    </Wiz.FormArea>
  )
}
