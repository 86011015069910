import { Box, BoxProps, chakra, Icon, Spinner } from '@chakra-ui/react'
import { IcoExclamationTriangle, IcoUnscored } from '@paper/icons'
import { TicketRubric, XpacketSW } from '@paper/schema'
import {
  CORRECT_COLOR,
  GRAY_TIME,
  MISSING_COLOR,
  MISSING_PIE_COLOR,
} from '@paper/styles'
import { sumBy } from 'lodash'
import { memo, ReactNode } from 'react'
import { pxx } from '~src/utils/layout'

// todo: rename/move
export const Opacity = {
  axisFocused: 0.85,
  focused: 1,
  unfocused: 0.65,
} as const
export const Transition = 'all .35s ease'

export type CellProps = {
  isCellSelected: boolean
  isDimmed?: boolean
  item: XpacketSW
  onSelect(x: number, y: number): void
  status?: 'error' | 'loading' | 'success'
  x: number
  y: number
}

export const Cell = memo(function Cell(props: CellProps) {
  // console.log('<Cell />')
  const { item, isCellSelected, isDimmed, onSelect, status, x, y } = props

  const pctQs = item.qs?.filter((q) => q) // in case a q is missing from illuminate

  const pct = !item.qs
    ? NaN
    : (100 * sumBy(pctQs, (q) => q.pts)) / sumBy(pctQs, (q) => q._outOf_)

  let glyph =
    status === 'error' ? (
      <Icon as={IcoExclamationTriangle} data-cy="tag-error" color="red.500" />
    ) : status === 'loading' ? (
      <Spinner data-cy="tag-loading" color={'white'} size="xs" speed="1s" />
    ) : !isNaN(pct) ? (
      <MiniPie hasScans={item.status !== 'missing'} pct={pct} />
    ) : item.rubric ? (
      <RubricDotViz data={item.rubric} />
    ) : (
      <Icon
        as={IcoUnscored}
        color={item.status === 'missing' ? MISSING_COLOR : GRAY_TIME}
      />
    )

  const transform = isCellSelected ? `scale(1.6)` : undefined
  const opacity = isDimmed ? 0.2 : 1

  // todo: this is a button, but rending 10000 buttons will be slower..
  // update - we now have virtual rendering so it's more like 500 buttons
  return (
    <Box
      cursor="pointer"
      // todo: is there any problematic cost of including data- here?
      data-cy="cell"
      data-x={x}
      data-y={y}
      display="flex"
      justifyItems="center"
      key={item.id}
      onClick={() => onSelect(x, y)}
      opacity={opacity}
      transform={transform}
      style={{ gridColumn: x + 1, gridRow: y + 1, transform }}
      transition={Transition}
      _hover={{
        filter: 'brightness(1.15)',
        transform: 'scale(1.25)',
      }}
      _active={{
        filter: 'brightness(1.35)',
      }}
    >
      {glyph}
    </Box>
  )
})

type MiniPieProps = { hasScans: boolean; pct: number }

export function MiniPie(props: MiniPieProps) {
  const pct = Math.round(props.pct)
  // todo: presumably should be controllable from the outside like an icon...
  const svgSize = '14px'
  const arcLength = 314
  const arcOffset = arcLength * ((100 - pct) / 100)
  //const d = 'M 100 23 a 77 77 0 1 1 -.1 0'
  const d = 'M 100 50 a 50 50 0 1 1 -.1 0'

  // todo: not showing pie for no scans for now
  if (!props.hasScans) {
    return <Box width="14px" />
  }

  return (
    // https://daverupert.com/2018/03/animated-svg-radial-progress-bars/
    // transition: stroke-dashoffset 850ms ease-in-out;
    <svg
      height={svgSize}
      width={svgSize}
      viewBox="0 0 200 200"
      overflow="visible"
    >
      <g fill="none" strokeWidth={100}>
        <path stroke="rgba(0,0,0,.2)" d={d} />
        <path
          d={d}
          stroke={props.hasScans ? CORRECT_COLOR : MISSING_PIE_COLOR}
          strokeDasharray={arcLength}
          strokeDashoffset={arcOffset}
          style={{ transition: 'stroke-dashoffset .2s .1s ease-in-out' }}
        />
        {/* {!props.hasScans && (
          <path
            fill="none"
            stroke={MISSING_X}
            strokeWidth="20"
            d={xd(185, 235, 165, 215)}
          />
        )} */}
      </g>
    </svg>
  )
}

function xd(x1: number, x2: number, y1 = x1, y2 = x2) {
  return `M${x1},${y1} L${x2},${y2} M${x1},${y2} L${x2},${y1}`
}

type RubricDotVizProps = { data: TicketRubric; size?: string }

export function RubricDotViz(props: RubricDotVizProps) {
  // console.log('<RubricDotViz/>')
  const { data, size = '15px' } = props

  // todo: this has overlap with `RubricXViz`
  const dim = 1
  const r = dim / 2
  // todo: assume 3x3
  const viewBox = '0 0 3 3'

  const height = size
  const width = size

  // todo: hardcoded...
  type RubricValue = 2 | 1 | -1
  const lookup: Record<RubricValue, { color: BoxProps['color']; y: number }> = {
    2: { color: 'green.500', y: 0 },
    1: { color: 'yellow.500', y: 1 },
    '-1': { color: 'orange.500', y: 2 },
  }

  const squares: ReactNode[] = []

  for (let [idx, value] of data.values.entries()) {
    if (value != null) {
      const attrs = lookup[value as RubricValue]
      if (attrs) {
        // squares.push(
        //   <chakra.rect
        //     key={idx}
        //     fill={attrs.color}
        //     height={pxx(dim)}
        //     x={idx}
        //     y={attrs.y}
        //     width={pxx(dim)}
        //   />
        // )
        squares.push(
          <chakra.circle
            key={idx}
            fill={attrs.color}
            r={pxx(r)}
            cx={idx + r}
            cy={attrs.y + r}
          />
        )
      }
    }
  }

  const isEmpty = squares.length === 0
  return (
    <svg viewBox={viewBox} height={height} width={width} pointerEvents="none">
      {isEmpty && (
        <chakra.rect
          fill="transparent"
          height="100%"
          stroke="gray.50"
          strokeWidth={0.05}
          x={0}
          y={0}
          width="100%"
        />
      )}
      {squares}
    </svg>
  )
}
