import { Student } from '@paper/schema'
import { defineApi } from '../defineApi'

export const sticker_generate = defineApi<{
  method: 'post'
  body: {
    perStudent: number
    studentLists: Student[][]
    unit: 'sheet' | 'sticker' // todo: clean up/centralize
  }
  result: Buffer
}>({
  app: 'print_generate',
  method: 'post',
  urlFactory: `sticker/generate`,
})

export const sticker_validate = defineApi<{
  method: 'post'
  body: { input: string; schoolId: string; type: 'studentNumber' | 'teacherId' }
  result: { name: string; items: { input: string; student: Student }[] }[]
}>({
  method: 'post',
  urlFactory: `sticker/validate`,
})
