import { Text as Txt, TextProps } from '@chakra-ui/react'
// `window.Text` is a thing, so this is more typescript autocomplete friendly
export { Text as Txt } from '@chakra-ui/react'

export function Italic(props: TextProps) {
  return <Txt as="span" fontStyle="italic" {...props} />
}

/**
 * todo: is it bad perf-wise to have defaults via components?
 * todo: also obviously a downside is that it's not composable...e.g. we'd need SpItalicMono
 * todo: i think it's more readable at least in some cases, especially if very nested
 * @example
    <Txt fontSize="xs" isTruncated={true}>
      <SpMono>({students.length})</SpMono>
      {sample}
    </Txt>
    // vs.
    <Txt fontSize="xs" isTruncated={true}>
      <Txt // if nested, prettier will break across lines
        as="span" 
        fontFamily="mono"
      >
        ({students.length})
      </Txt>
      {sample}
    </Txt>
    // another option that i've used elsewhere is to define common prop sets
    <Txt fontSize="xs" isTruncated={true}>
      <Txt {...spMono}>({students.length})</Txt>
      {sample}
    </Txt>
 */
export function SpMono(props: TextProps) {
  return <Txt as="span" fontFamily="mono" {...props} />
}
