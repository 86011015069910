import { Link, List, ListItem } from '@chakra-ui/react'
import { useLink, useRouter } from '@paper/route'
import { School, Teacher } from '@paper/schema'
import { getFullName } from '@paper/utils'
import { groupBy, orderBy } from 'lodash'
import { H3, HSep, Txt, VStack } from '~src/components'
import { useDirectoryData } from '~src/data/data-directory'
import { RD_Home_Curriculum, Routes } from '~src/routelist'
import { MenuModuleBase } from './mmBase'

type CurriculumMenuModuleProps = {}

export function CurriculumMenuModule(props: CurriculumMenuModuleProps) {
  const { currentRoute } = useRouter()
  if (currentRoute !== Routes.home_curriculum) {
    return null
  }
  // todo: need to combine layout mojo with other modules
  return (
    <MenuModuleBase>
      <TeacherDrawer />
    </MenuModuleBase>
  )
}

function TeaLink(props: Teacher) {
  const item = props
  const linkProps = useLink(
    Routes.home_teacher.mergeAction({ teacherId: props.id })
  )
  return <Link {...linkProps}>{getFullName(item)}</Link>
}

function SchLink(props: School) {
  const item = props
  const linkProps = useLink(
    Routes.home_school.mergeAction({ schoolId: props.id })
  )
  return (
    <Link fontSize="sm" {...linkProps}>
      {item.name}
    </Link>
  )
}

/**
 * @deprecated
 * todo: curriculumContext is defined below here, so need to re-extract
 * todo: this is a bit less efficient than doing it once...
 */
const useCurriculumRecord = () => {
  const { routeData } = useRouter<RD_Home_Curriculum>()
  const dirData = useDirectoryData().data
  const curriculumRecord = dirData?.curriculum.map.get(routeData.curriculumId)
  return { curriculumRecord, dirData }
}

function TeacherDrawer() {
  const { curriculumRecord } = useCurriculumRecord()
  if (!curriculumRecord) {
    return null
  }

  return (
    <VStack alignItems="stretch" gap={2} overflow="hidden" p={4}>
      <H3 display="flex" flexDirection="column" gap={2}>
        <Txt fontFamily="mono" fontSize="lg">
          {curriculumRecord.item.name}
        </Txt>
        <Txt fontSize="sm">Links to its schools and teachers</Txt>
      </H3>
      <HSep boxProps={{ my: 2 }} />
      <TeacherLinkList />
    </VStack>
  )
}

function TeacherLinkList() {
  const { curriculumRecord, dirData } = useCurriculumRecord()
  const teachers = Array.from(curriculumRecord.teacherIds).map((teacherId) => {
    const teacherRecord = dirData.teacher.map.get(teacherId)
    const schools = Array.from(teacherRecord.schoolIds).map(
      (schoolId) => dirData.school.map.get(schoolId).item
    )

    return { schools, teacher: teacherRecord.item }
  })

  const flattened = teachers.flatMap(({ schools, teacher }) =>
    schools.map((school) => ({ school, teacher }))
  )

  const schools = orderBy(
    Object.values(groupBy(flattened, ({ school }) => school.id)),
    (items) => items[0].school.name
  )

  return (
    <List overflowY="auto">
      {schools.map((teachers, schIdx) => {
        const school = teachers[0].school // already grouped by school...
        teachers = orderBy(
          teachers,
          ({ teacher }) => teacher.firstName + teacher.lastName
        )
        return (
          <ListItem key={school.id}>
            <SchLink {...school} />
            <List mt={1} mx={2}>
              {teachers.map(({ teacher }) => (
                <ListItem key={teacher.id} py={2}>
                  <TeaLink {...teacher} />
                </ListItem>
              ))}
            </List>
            {schIdx !== schools.length - 1 && <HSep boxProps={{ my: 2 }} />}
          </ListItem>
        )
      })}
    </List>
  )
}
