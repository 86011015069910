import { ButtonProps, chakra } from '@chakra-ui/react'
import {
  IcoArrowBack,
  IcoArrowForward,
  IcoLeftRight,
  IconType,
} from '@paper/icons'
import { min0, PAPER_BOT_USER } from '@paper/utils'
import { ReactNode } from 'react'
import { SvgX } from '../svg'

/**
 * Get size of page graph for virtual rows
 * @param count Number of pages
 * @param cols Number of columns, defaults to 15
 * @returns
 */
export const measurePageGraph = (count: number, cols?: number) => {
  count = count || 0
  // default value
  cols ??= 15

  const pageSize = 16

  const colGap = 1
  const tallyGap = 8
  const rowGap = 4

  const rows = Math.ceil(count / cols)
  const width = cols * (pageSize + colGap) + min0(cols / 5 - 1) * tallyGap
  const height = rows * (pageSize + rowGap) - rowGap

  return { colGap, cols, height, pageSize, rowGap, tallyGap, width }
}

type PageGraphProps = {
  columnCount?: number
  data: {
    arrow?: 'in' | 'out' | 'inout'
    colorScheme: ButtonProps['colorScheme']
    onSelect?(): void
    selected: boolean
    // todo: need to de-dupe this with arrow/targeted
    symbol?: 'A' | 'M' | 'X'
    targeted: boolean
  }[]
}

export function ScanlogPageGraph(props: PageGraphProps) {
  const { data } = props
  if (!data) {
    return null
  }

  const { colGap, cols, height, pageSize, rowGap, tallyGap, width } =
    measurePageGraph(props.data.length, props.columnCount)

  const viewBox = `0 0 ${width} ${height}`

  let boxes: ReactNode[] = []
  let x = 0
  let y = 0
  for (let i = 0; i < data.length; i++) {
    let { arrow, colorScheme, onSelect, selected, symbol, targeted } = data[i]

    let color500 = `${colorScheme}.500`
    let dashed = arrow === 'out'
    let strokeWidth = arrow ? 1 : 1.5
    let adjSize = pageSize - 2 * strokeWidth
    let adjX = x + strokeWidth
    let adjY = y + strokeWidth

    // todo:...better message, maybe should include a message for all cells
    // todo: though seems impractical for someone to use this page fully with a screen reader
    let tooltip =
      symbol === 'A'
        ? `An image was assigned to this page by ${PAPER_BOT_USER}`
        : symbol === 'M'
        ? `An image was assigned to this page by a human`
        : symbol === 'X'
        ? `There is no image assigned to this page`
        : null

    boxes.push(
      <chakra.rect
        cursor={onSelect ? 'pointer' : null}
        key={i}
        x={adjX}
        y={adjY}
        // title="does this work?"
        width={`${adjSize}px`}
        height={`${adjSize}px`}
        fill={selected ? color500 : 'transparent'}
        strokeWidth={strokeWidth}
        stroke={color500}
        strokeDasharray={dashed ? '4 2' : null}
        rx={2}
        onClick={onSelect}
        // pointerEvents={onSelect ? null : 'none'}
        sx={
          onSelect && {
            _hover: {
              fill: `${colorScheme}.${selected ? 600 : 100}`,
              stroke: `${colorScheme}.600`,
            },
            _active: {
              fill: `${colorScheme}.${selected ? 700 : 200}`,
              stroke: `${colorScheme}.700`,
            },
          }
        }
      >
        {tooltip && <title>{tooltip}</title>}
      </chakra.rect>
    )

    if (symbol === 'X') {
      const offset = 2
      // chakra.path is broken due to `d` prop
      const x1 = adjX + adjSize / 2
      const x2 = adjX + adjSize - offset
      const y1 = adjY + adjSize / 2
      const y2 = adjY + offset

      boxes.push(
        <SvgX
          key={`${i}.sym.${symbol}`}
          pointerEvents="none"
          stroke={color500}
          strokeWidth={1}
          xyxy={[x1, y1, x2, y2]}
        />
      )
    } else if (symbol) {
      const x1 = adjX + adjSize / 2
      const y1 = adjY + adjSize / 2

      boxes.push(
        <chakra.text
          key={`${i}.sym.${symbol}`}
          fill={selected ? 'white' : color500}
          fontSize="7px"
          fontWeight={600}
          pointerEvents="none"
          userSelect="none"
          x={x1}
          y={y1}
        >
          {symbol}
        </chakra.text>
      )
    }

    if (targeted) {
      ;[2, 4.25].forEach((r) =>
        boxes.push(
          <chakra.circle
            key={i + '.' + r}
            cx={adjX + adjSize / 2}
            cy={adjY + adjSize / 2}
            pointerEvents="none"
            r={r}
            stroke={color500}
            strokeWidth={1.25}
            fill="none"
          />
        )
      )
    }

    if (arrow) {
      let ArrowIcon: IconType
      switch (arrow) {
        case 'out':
          ArrowIcon = IcoArrowBack
          break
        case 'in':
          ArrowIcon = IcoArrowForward
          break
        case 'inout':
          ArrowIcon = IcoLeftRight
          break
      }
      const arrowSize = pageSize * (2 / 3)

      boxes.push(
        <ArrowIcon
          color={`var(--chakra-colors-${colorScheme}-500)`}
          height={arrowSize}
          key={i + 'arrow'}
          width={arrowSize}
          x={x}
          y={y + (pageSize - arrowSize) / 2}
        />
      )
    }

    x += pageSize + colGap
    if ((i + 1) % 5 === 0) {
      x += tallyGap
    }
    if ((i + 1) % cols === 0) {
      x = 0
      y += pageSize + rowGap
    }
  }

  return (
    <svg viewBox={viewBox} height={height} width={width}>
      {boxes}
    </svg>
  )
}
