import { memo, useCallback, useLayoutEffect } from 'react'
import { useRouter } from './Router'
import type { Action } from './types'

interface RedirectProps {
  action: Action
}

// todo: probably no point to memo
export const Redirect = memo(function Redirect(props: RedirectProps) {
  const { action } = props
  const { dispatchRoute } = useRouter()
  useLayoutEffect(() => {
    dispatchRoute(action)
  }, [dispatchRoute, action])
  return null
})

export function useLink(action: Action) {
  const { dispatchRoute, routeData } = useRouter()

  const href = action.routeMeta.buildUrl({
    ...(action.mergeType === 'merge' ? routeData : {}),
    ...action.data,
  })
  const onClick = useCallback(
    (event) => {
      event.preventDefault()
      dispatchRoute(action)
    },
    [href]
  )
  return { href, onClick }
}
