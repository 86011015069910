import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react'
import { PacketmetaC2, PublishStage } from '@paper/schema'
import { yupPublishSet } from '@paper/schema/validation'
import { PktColors } from '@paper/styles'
import { Field, Form, Formik, useFormikContext } from 'formik'
import { produce } from 'immer'
import { Txt } from '~src/components'
import { useSubmitPublish } from '../data-publish'
import { ButtonStack, FieldUI } from '../formHelpers'
import { PacketHUD } from './packetHUD'

type PublishDialogProps = {
  onClose(): void
  packet: PacketmetaC2
}
type PublishFormProps = { initialStage: PublishStage; neverPubd: boolean }

type PublishSchema = PacketmetaC2

export const PublishDialog = (props: PublishDialogProps) => {
  const { onClose, packet } = props
  // TODO: error handling
  const mutation = useSubmitPublish({ onSuccess: props.onClose })

  if (!packet) {
    return null
  }

  const initialStage = packet._pub
  const neverPubd = initialStage === 'creating' || initialStage === 'uploaded'
  // initialize form to published if the packet has never been published before
  const initialValues = produce(packet, (draft) => {
    if (neverPubd) {
      draft._pub = 'published'
    }
  })

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onReset={() => onClose()}
      onSubmit={async (values, actions) => {
        const { _pub, contentId, curriculumId, number } = values
        await mutation.mutateAsync({ _pub, contentId, curriculumId, number })
      }}
      validateOnMount={true}
      validationSchema={yupPublishSet}
    >
      <Modal
        closeOnEsc={false}
        closeOnOverlayClick={false}
        isCentered={true}
        isOpen={!!packet}
        onClose={onClose}
      >
        <ModalOverlay />
        <PublishForm initialStage={initialStage} neverPubd={neverPubd} />
      </Modal>
    </Formik>
  )
}

const getNextStage = (stage: PublishStage): PublishStage => {
  return stage === 'published' ? 'recalled' : 'published'
}

const PublishForm = (props: PublishFormProps) => {
  const { initialStage, neverPubd } = props
  const {
    dirty,
    isSubmitting,
    isValid,
    setFieldValue,
    setFieldTouched,
    values,
  } = useFormikContext<PublishSchema>()

  const nextStage: PublishStage =
    // Only allow going to the next stage once
    values._pub !== initialStage ? undefined : getNextStage(values._pub)

  // Locally clear out publish time so it doesn't say "Published [Old time]"
  const doPublish = (next: PublishStage) => {
    setFieldValue('_pub', next)
    setFieldValue('_updates.publish.time', undefined)
  }

  return (
    <ModalContent as={Form} data-cy="pub-dialog" borderRadius={4}>
      <ModalBody overflow="hidden" pt={6}>
        <Box alignItems="center" display="flex" flexWrap="wrap" mb={6}>
          <Txt fontSize="md" fontWeight="bolder">
            {values.curriculum.name}
          </Txt>
          <Box alignItems="center" display="inline-flex">
            <PacketHUD mx={3} packet={values} />
            <PacketHUD.PublishButton
              next={nextStage}
              onClick={() => doPublish(nextStage)}
              size="xs"
            />
          </Box>
        </Box>
        <Field name="number">
          {({ field, meta }) => {
            return (
              <FieldUI
                input={
                  <Input
                    data-cy="pkt-num"
                    type="text"
                    {...field}
                    onChange={(event) => {
                      // Prevent whitespace
                      const noSpaces = event.target.value.trim()
                      setFieldValue(field.name, noSpaces)
                      // get error message to display after we've typed some TODO: Better way?
                      if (!field.touched && noSpaces.length >= 3) {
                        // without setTimeout, the field gets a stale error that never updates...
                        setTimeout(() => setFieldTouched(field.name, true), 0)
                      }
                    }}
                    textAlign="center"
                    value={field.value ?? ''}
                    w="92px"
                  />
                }
                label="packet number"
                meta={meta}
                w="100%"
              />
            )
          }}
        </Field>
      </ModalBody>
      <ModalFooter>
        <ButtonStack>
          <Button size="sm" type="reset" variant="outline">
            Cancel
          </Button>
          <Button
            data-cy="pub-submit"
            isDisabled={!dirty || isSubmitting || !isValid}
            size="sm"
            type="submit"
            colorScheme={neverPubd ? PktColors.Publish : undefined}
          >
            {neverPubd ? 'Publish' : 'Submit'}
          </Button>
        </ButtonStack>
      </ModalFooter>
    </ModalContent>
  )
}
