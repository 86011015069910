import { CrunchBatch, ScanStatus } from '@paper/schema'
import { defineApi } from '../defineApi'

export type ScanVizBatch = Pick<
  CrunchBatch,
  'id' | 'batchNum' | 'device' | 'packets' | 'scanDate' | 'stats'
> & {
  chunks: { length: number; status: ScanStatus }[]
  length: number
  statuses: ScanStatus[]
}

export const scan_viz = defineApi<{
  method: 'post'
  body: {
    endDate: number
  }
  result: ScanVizBatch[]
}>({
  method: 'post',
  urlFactory: `unstable-scan-viz`,
})
