import {
  chakra,
  omitThemingProps,
  Portal,
  TooltipProps,
  useStyleConfig,
  useTheme,
  useTooltip,
  VisuallyHidden,
} from '@chakra-ui/react'
import { getCSSVar, isString, omit, pick } from '@chakra-ui/utils'
import React, { forwardRef } from 'react'

/**
 * Ripped out the animation + some defaults
 * @see https://github.com/chakra-ui/chakra-ui/blob/%40chakra-ui/react%401.8.6/packages/tooltip/src/tooltip.tsx
 */
export function TT(props: TooltipProps) {
  return <Tooltip p={0} {...props} />
}

/**
 * Tooltips display informative text when users hover, focus on, or tap an element.
 *
 * @see Docs     https://chakra-ui.com/docs/overlay/tooltip
 * @see WAI-ARIA https://www.w3.org/TR/wai-aria-practices/#tooltip
 */
const Tooltip = forwardRef<'div', TooltipProps>(function Tooltip(props, ref) {
  const styles = useStyleConfig('Tooltip', props)
  const ownProps = omitThemingProps(props)
  const theme = useTheme()

  const {
    children,
    label,
    shouldWrapChildren,
    'aria-label': ariaLabel,
    hasArrow,
    bg,
    portalProps,
    background,
    backgroundColor,
    bgColor,
    ...rest
  } = ownProps

  const userDefinedBg = background ?? backgroundColor ?? bg ?? bgColor

  if (userDefinedBg) {
    styles.bg = userDefinedBg
    // todo: hardcoding this since prod parcel apparently compiles this away
    styles['--popper-arrow-bg'] = getCSSVar(theme, 'colors', userDefinedBg)
  }
  const tooltip = useTooltip({ ...rest, direction: theme.direction })

  const shouldWrap = isString(children) || shouldWrapChildren

  let trigger: React.ReactElement

  if (shouldWrap) {
    trigger = (
      <chakra.span tabIndex={0} {...tooltip.getTriggerProps()}>
        {children}
      </chakra.span>
    )
  } else {
    /**
     * Ensure tooltip has only one child node
     */
    const child = React.Children.only(children) as React.ReactElement & {
      ref?: React.Ref<any>
    }
    trigger = React.cloneElement(
      child,
      tooltip.getTriggerProps(child.props, child.ref)
    )
  }

  const hasAriaLabel = !!ariaLabel

  const _tooltipProps = tooltip.getTooltipProps({}, ref)

  const tooltipProps = hasAriaLabel
    ? omit(_tooltipProps, ['role', 'id'])
    : _tooltipProps

  const hiddenProps = pick(_tooltipProps, ['role', 'id'])

  /**
   * If the `label` is empty, there's no
   * point showing the tooltip. Let's simply return back the children
   */
  if (!label) {
    return <>{children}</>
  }

  return (
    <>
      {trigger}
      {tooltip.isOpen && (
        <Portal {...portalProps}>
          <chakra.div
            {...tooltip.getTooltipPositionerProps()}
            __css={{
              zIndex: styles.zIndex,
              pointerEvents: 'none',
            }}
          >
            <chakra.div
              {...(tooltipProps as any)}
              initial="exit"
              animate="enter"
              exit="exit"
              __css={styles}
            >
              {label}
              {hasAriaLabel && (
                <VisuallyHidden {...hiddenProps}>{ariaLabel}</VisuallyHidden>
              )}
              {hasArrow && (
                <chakra.div
                  data-popper-arrow
                  className="chakra-tooltip__arrow-wrapper"
                >
                  <chakra.div
                    data-popper-arrow-inner
                    className="chakra-tooltip__arrow"
                    __css={{ bg: styles.bg }}
                  />
                </chakra.div>
              )}
            </chakra.div>
          </chakra.div>
        </Portal>
      )}
    </>
  )
})
