import { useLayoutEffect, useRef, useState } from 'react'

/**
 * Intended for passing a value to memoized callback without rerendering
 * Not 100% this is correct
 * @example
 * const { value } = props
 * const ref = useAlwaysUpdateRef(value)
 * // for example if we're rendering 100 items in a list
 * const onClickForExpensiveRender = useCallback(() => {
 *  doSomethingWith(ref.value)
 * }, [])
 */
export function useAlwaysUpdateRef<S>(value: S) {
  // Initialize ref to value, hopefully actually fix #61
  const ref = useRef<S>(value)
  useLayoutEffect(() => {
    ref.current = value
  })
  return ref
}
