import { AuthDomain } from '../types/auth'
import { SchoolYear } from '../types/school'
import { Yup } from './v-utils'

export const yupAdmin = Yup.object({
  email: Yup.string().email().required(),
  roles: Yup.array(Yup.string().oneOf(['admin']).required()),
})

export const yupDomain = Yup.object<AuthDomain>({
  domain: Yup.string()
    .matches(/^([\w-]+\.)+\w{2,}$/)
    .required(),
})

export const yupSchoolYear = Yup.object<SchoolYear>({
  code: Yup.string()
    .matches(/^\d{2}-\d{2}$/)
    .required(),
  firstDay: Yup.string().required(),
  isPEYear: Yup.bool().required(),
  isSWYear: Yup.bool().required(),
  lastDay: Yup.string().required(),
  syId: Yup.number().required(),
})

export const yupAdminAll = Yup.object({
  admins: Yup.array(yupAdmin.fields.email),
  domains: Yup.array(yupDomain.required()),
  years: Yup.array(yupSchoolYear.required()).test({
    name: 'years',
    test: function testIsSWYear(value: SchoolYear[]) {
      let fields = ['isPEYear', 'isSWYear']

      for (let key of fields) {
        if (value.filter((p) => p[key]).length !== 1) {
          return this.createError({
            path: `years.0.${key}`,
            message: `One year must be designated as "${key}"`,
          })
        }
      }

      return true
    },
  }),
})
