import { useEffect, useRef } from 'react'
import { ImagePage } from '~src/blocks/imageViewer'
import { useFixitContext } from './fixitProvider'

// todo: copy/paste with scanlog
type FixitImageProps = { src: string }

export function FixitImage(props: FixitImageProps) {
  const { src } = props
  const { imagePosition } = useFixitContext()
  const { panEntangler } = imagePosition
  const ref = useRef<HTMLImageElement>()

  useEffect(() => {
    if (ref.current) {
      panEntangler.current.register(ref)
      return () => panEntangler.current.unregister(ref)
    }
  }, [])

  return (
    <ImagePage
      mode="cover"
      src={src}
      transformProps={{
        onPan: (delta) => {
          panEntangler.current.onPan(delta)
        },
        ref,
        scale: 1, // scale isn't needed in cover mode...
      }}
    />
  )
}
