import { DeepPartial } from '@chakra-ui/react'
import { SetupCourse, SetupSection } from '@paper/schema'
import { Yup } from '@paper/schema/validation'
import { useMemo } from 'react'

export const useValidationSchema = (course: string) => {
  return useMemo(() => {
    const yupSetupSection = Yup.object<Omit<SetupSection, 'meta'>>({
      id: Yup.string().required(),
      isEnabled: Yup.bool().required(),
      teacherId: Yup.string()
        .nullable()
        .test(null, null, function (value) {
          if (this.parent.isEnabled && !value) {
            throw new Yup.ValidationError(
              `Invalid email address`,
              value,
              this.path
            )
          }
          return true
        }),
    })

    const validationSchema = Yup.object<DeepPartial<SetupCourse>>({
      curriculumId: Yup.string(),
      id: Yup.string().required(),
      sections: Yup.array(yupSetupSection),
    }).test(null, null, function (value) {
      let hasCurricula = !!value.curriculumId
      let hasSections = value.sections.some((s) => s.isEnabled)

      // todo: for some reason curriculmIds validation doesn't seemt to be working with `.min` or `.test`
      if (hasCurricula && hasSections) {
        // good
      } else if (hasSections) {
        throw new Yup.ValidationError(
          `A course must have a curriculum`,
          value.curriculumId,
          'curriculumIds'
        )
      } else if (hasCurricula) {
        throw new Yup.ValidationError(
          `You must either activate one or more sections or clear the curriculum.`,
          value.sections,
          'sections'
        )
      }

      return true
    })

    return validationSchema
  }, [course])
}
