import {
  Button,
  ButtonProps,
  IconButton,
  Link,
  LinkProps,
} from '@chakra-ui/react'
import { forwardRef, ReactElement } from 'react'
import { TextIconButton, TextIconButtonProps } from '.'

type BaseProps = Omit<LinkProps, 'onClick' | 'variant'>
export type BLinkProps = ButtonProps & BaseProps
export type IBLinkProps = Omit<TextIconButtonProps, 'icon'> &
  BaseProps & { icon: string | number | ReactElement }

function handleIsDisabled<T extends BLinkProps | IBLinkProps>(props: T): T {
  if (props.isDisabled) {
    return { ...props, href: null, onClick: null }
  } else {
    return props
  }
}

/**
 * A link with button styling
 */
export const BLink = forwardRef<HTMLButtonElement, BLinkProps>(
  function BLink(props, ref) {
    // only underline if link variant
    const textDecor = props.variant === 'link' ? undefined : 'none !important'
    return (
      <Button
        as={Link}
        textDecor={textDecor}
        ref={ref}
        {...handleIsDisabled(props)}
      />
    )
  }
)

/**
 * A link with icon button styling
 */
export const IBLink = forwardRef<HTMLButtonElement, IBLinkProps>(
  function IBLink(props, ref) {
    const Component = isStrOrNum(props.icon) ? TextIconButton : IconButton
    return (
      // @ts-expect-error typescript doesn't understand above check
      <Component
        as={Link}
        textDecor="none !important"
        ref={ref}
        {...handleIsDisabled(props)}
      />
    )
  }
)

const isStrOrNum = (val: any) =>
  typeof val === 'string' || typeof val === 'number'
