import { Pin, PinQCell } from '@paper/schema'
import { XOR } from '@paper/utils'
import { defineApi } from '../defineApi'

export const unstable_pin_list = defineApi<{
  method: 'post'
  body: { syId: number } & XOR<
    { contentId: string }, // list for grid
    { packetId: string; teacherId: string } // list for pinning view
  >
  result: PinQCell[]
}>({
  invalidateKey: 'pin',
  method: 'post',
  urlFactory: `unstable-pin/list`,
})

export const unstable_pin_set = defineApi<{
  method: 'put'
  body: Omit<Pin, '_id' | 'cellId' | '_update'>
  result: Pin
}>({
  invalidateOnMutationSuccess: ['pin'],
  method: 'put',
  urlFactory: `unstable-pin/set`,
})
