import { ButtonProps } from '@chakra-ui/react'
import type { HTTPError } from '@paper/utils'
import { useEffect, useState } from 'react'

/** Returns standardized error action props */
export const getEAProps = (icon: ButtonProps['leftIcon']) => {
  return { leftIcon: icon, size: 'sm' }
}

export type ErrorResponse = {
  body: string | object
  status: number
}
/**
 * Amends response text if http error #65
 * @deprecated (?) This should probably be part of react-query error handling?
 */
export function useHttpErrorResponse(error: Error) {
  const [errorResponse, setErrorResponse] = useState<ErrorResponse>()

  // Extract response text if http error #65
  let httpError = error as HTTPError
  useEffect(() => {
    let unmounted = false
    if (httpError?.response) {
      const { status } = httpError.response
      httpError.response
        .text()
        .then((respTxt) => {
          try {
            const asJson = JSON.parse(respTxt)
            !unmounted && setErrorResponse({ body: asJson, status })
          } catch (err) {
            !unmounted && setErrorResponse({ body: respTxt, status })
          }
        })
        .catch((err) => console.error(err))
    }
    return () => {
      unmounted = true
    }
  }, [httpError])

  return errorResponse
}

/////////////////////////////////////////////////////////////
