import type { SetupCourse } from '@paper/schema'

export const getCourseStatus = (
  stats: SetupCourse['stats']
): SetupCourse['status'] => {
  return stats.paper === 0
    ? 'none'
    : stats.deleted + stats.dirty > 0
    ? 'pending'
    : stats.paper !== stats.exists
    ? 'partial'
    : 'all'
}
