import { FixitSlotType, ImagesThat } from '@paper/schema'
import { z, zenum } from '@paper/schema/validation'
import { IMAGES_THAT } from '@paper/utils'

const z_sw_base = z.object({
  curriculumId: z.string().optional().nullable(), // todo: temporary for JumpToLink since crossNetwork doesn't have curriculumId?
  f_sectionId: z.string().optional(),
  packetId: z.string().optional(),
  qId: z.string().optional(),
  studentId: z.string().optional(),
  teacherId: z.string().optional(), // optional for link api
  xpacketId: z.string().optional(),
})

export type ScanNoscan = 'scans' | 'noscans'

export const z_sw_fixit = z_sw_base.partial().extend({
  si_imageId: z.string().optional(),
  slot: zenum<FixitSlotType>([
    'packet',
    'student',
    'score',
    'rubric',
  ]).optional(),
})

export const z_sw_jumpToQ = z_sw_base.extend({
  f_ans: z.string().optional(),
  f_scans: zenum<ScanNoscan>(['scans', 'noscans']).optional(),
})

export const z_sw_jumpToStd = z_sw_jumpToQ.extend({
  std: z.string().optional(),
})

export const z_sw_rubric = z_sw_base.partial().extend({
  axes: z.coerce.number().int().nonnegative().optional(),
})

export const z_sw_scanlog = z_sw_base.partial().extend({
  deviceName: z.string().optional(),
  dir: zenum(['later']).optional(),
  sb_batchId: z.string().optional(),
  scanDate: z.coerce.number().int().nonnegative().optional(),
  si_imageId: z.string().optional(),
  status: zenum<ImagesThat>(IMAGES_THAT).optional(), // todo: need to catch all enums!
})

export const z_sw_setStudent = z_sw_base

export const z_sw_time = z_sw_base.extend({
  f_packet: z.string().optional(),
  std: z.string().optional(),
  tgf_col: zenum(['hide-empty']).optional(),
  tgf_tag: z.string().optional(),
  tgs_rowsBy: z.array(z.string()).optional(),
})

type Yearify<T extends z.ZodType<any, any, any>> = z.infer<T> & {
  yearCode?: string
}

export type RD_SW_Base = Yearify<typeof z_sw_base>
export type RD_SW_Fixit = Yearify<typeof z_sw_fixit>
export type RD_SW_JumpToQ = Yearify<typeof z_sw_jumpToQ>
export type RD_SW_JumpToStd = Yearify<typeof z_sw_jumpToStd>
export type RD_SW_Rubric = Yearify<typeof z_sw_rubric>
export type RD_SW_Scanlog = Yearify<typeof z_sw_scanlog>
export type RD_SW_SetStudent = Yearify<typeof z_sw_setStudent>
export type RD_SW_Time = Yearify<typeof z_sw_time>
