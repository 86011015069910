import {
  Button,
  Grid,
  Icon,
  IconButton,
  Input,
  useBoolean,
} from '@chakra-ui/react'
import { IcoArrowForward, IcoX } from '@paper/icons'
import { FixitPageIndices, Student, TicketRubric } from '@paper/schema'
import { ENDASH, getFullName } from '@paper/utils'
import { produce } from 'immer'
import { orderBy, times } from 'lodash'
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import {
  ComboBox,
  HStack,
  Txt,
  useComboBoxItems,
  VStack,
} from '~src/components'
import { LoadingDots } from '~src/components/status'
import { pxx } from '~src/utils/layout'
import {
  getKeyNButtonIcon,
  KeyNButtonGroup,
  KeyNButtonProps,
} from './fixitKeyboard'
import { useFixitContext } from './fixitProvider'

type MiniFormProps = {}

function PacketField(props: MiniFormProps) {
  const { onSlotAction, packet } = useFixitContext()

  const thisPacketButtons = useSingleDoubleSidedButtons()

  if (packet?.pages.length > 2) {
    console.warn('Multi-page assessment not implemented yet')
  }

  if (!packet) {
    return <LoadingDots />
  }

  return (
    <HStack alignSelf="stretch">
      <KeyNButtonGroup
        actions={[
          ...thisPacketButtons,
          {
            children: 'Other (skip)',
            keyChar: 'l',
            onClick: () => onSlotAction({ packet: 'not-this-packet' }),
          },
          {
            children: 'Not Ponder Paper',
            keyChar: ';',
            onClick: () => onSlotAction({ packet: 'ignore' }),
          },
        ]}
        buttonProps={{
          height: 'auto',
          py: 1,
        }}
        gap={2}
        orientation="vertical"
        size="sm"
      />
    </HStack>
  )
}

const useSingleDoubleSidedButtons = () => {
  const {
    onSlotAction,
    packet,
    packetId,
    selectedSlot,
    selectedSheet,
    unassignedStudentMap,
  } = useFixitContext()

  const hasFrontBackScans = selectedSheet.scans.length === 2
  // todo: this obviously needs to be extended for multi-page
  const isFrontBackPacket = packet?.pages.length > 1

  if (packet?.pages.length > 2) {
    console.warn('Multi-page assessment not implemented yet')
  }

  const isThisPacketDisabled =
    selectedSlot?.student && !unassignedStudentMap.has(selectedSlot.student.id) // todo: maybe stamp this on item?

  type ProtoKeyNButton = {
    children: ReactNode
    keyChar: string
  }

  let seed: ProtoKeyNButton[] = [
    {
      children: (
        <Txt as="span" overflow="hidden" whiteSpace="pre-wrap">
          {packet?.name ?? 'This packet'}
        </Txt>
      ),
      keyChar: 'j',
    },
    {
      children: '[Flipped]',
      keyChar: 'k',
    },
  ]

  if (!hasFrontBackScans) {
    seed = seed.slice(0, 1)
  }

  return seed.map(({ children, keyChar }, idx) => {
    const indices: FixitPageIndices = [0, isFrontBackPacket ? 1 : null]
    if (idx === 1) {
      indices.reverse()
    }

    const leftIcon = hasFrontBackScans ? (
      <FrontBackIcon hasFrontBackScans={hasFrontBackScans} indices={indices} />
    ) : null

    let result: KeyNButtonProps = {
      children,
      isDisabled: isThisPacketDisabled,
      keyChar,
      leftIcon,
      onClick: () =>
        onSlotAction({ packet: { packetId, pageIndices: indices } }),
    }
    return result
  })
}

const formatFixitIndex = (index: number) =>
  index == null ? (
    <Icon as={IcoX} aria-label="non-Ponder Paper flipside" width="100%" />
  ) : (
    `p.${index + 1}`
  )

type FrontBackIconProps = {
  hasFrontBackScans: boolean
  indices: FixitPageIndices
}

function FrontBackIcon(props: FrontBackIconProps) {
  const { hasFrontBackScans, indices } = props

  const borderWidth = 1.5
  const border = `${borderWidth}px solid`
  const cellHeight = 18
  // hacky/convoluted way to account for the single border
  const gridTemplateRows = times(hasFrontBackScans ? 2 : 1, (i) =>
    pxx(cellHeight + (i === 0 ? borderWidth : 0))
  ).join(' ')

  return (
    <Grid
      border={border}
      fontFamily="mono"
      fontSize="xs"
      gridTemplateRows={gridTemplateRows}
      placeItems="center stretch"
    >
      {indices.map((value, idxIdx) => {
        if (idxIdx === 1 && !hasFrontBackScans) {
          return null
        }
        return (
          <VStack
            alignItems="stretch"
            borderBottom={idxIdx === 0 ? border : null}
            key={idxIdx}
            justifyContent="center"
            height="100%"
            px={1}
          >
            {formatFixitIndex(value)}
          </VStack>
        )
      })}
    </Grid>
  )
}

function StudentField(props: MiniFormProps) {
  const { onSlotAction, selectedSlot, unassignedStudents } = useFixitContext()

  const items = useMemo(() => {
    // todo: typescript and/or lodash is mistyping this...it shouldn't be inferring the type from the 2nd function
    return orderBy(unassignedStudents, getFullName) as Student[]
  }, [unassignedStudents])

  const comboProps = useComboBoxItems({
    items,
    value: null,
    filterer: (filters, item, inputValue) =>
      filters.startsWith(item.firstName, inputValue) ||
      filters.startsWith(item.lastName, inputValue),
  })

  const onNoStudent = () => onSlotAction({ student: 'no-student' })
  const onNotRoster = () => onSlotAction({ student: 'not-this-roster' })

  const actions: KeyNButtonProps[] = [
    {
      children: 'Not my student',
      keyChar: ';',
      onClick: onNotRoster,
    },
    {
      children: 'No student',
      keyChar: '/',
      onClick: onNoStudent,
    },
  ]

  return (
    <VStack alignItems="stretch" fontSize="xs" gap={3}>
      <VStack alignItems="start" gap={0.5}>
        <ComboBox.Root
          {...comboProps}
          autoFocus={true}
          isOpen={true}
          itemToString={(student) => getFullName(student)}
          // reset on slot change
          key={`combo:${selectedSlot.id}`}
          onChange={(item) => {
            onSlotAction({ student: { studentId: item?.id } })
          }}
          onKeyDown={(event) => {
            for (let action of actions) {
              if (event.key === action.keyChar) {
                action.onClick()
                event.preventDefault()
              }
            }
          }}
          placeholder="Type to filter"
          selectedItem={selectedSlot.student}
        >
          <ComboBox.Label key="not-sure-why...">
            Select a student
          </ComboBox.Label>
          <ComboBox.Shell key="react-is-suddenly-complaining-without-these...">
            <ComboBox.Input />
            <ComboBox.List height="92px" type="permanent" />
          </ComboBox.Shell>
        </ComboBox.Root>
      </VStack>
      <KeyNButtonGroup
        actions={actions}
        mb={1}
        orientation="vertical"
        size="xs"
      />
    </VStack>
  )
}

/**
 * Resets input to '' and focus when `item` changes
 */
const useFocusedAndResetInput = (item: any) => {
  const [localValue, setLocalValue] = useState('')
  const inputRef = useRef<HTMLInputElement>()
  useEffect(() => {
    // reset
    setLocalValue('')
    // focus
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [item])

  return [inputRef, localValue, setLocalValue] as const
}

const isValidScore = (score: number) => score != null && !isNaN(score)

function ScoreField(props: MiniFormProps) {
  const { onSlotAction, selectedSlot } = useFixitContext()

  const [inputRef, localValue, setLocalValue] =
    useFocusedAndResetInput(selectedSlot)

  // todo: float handling!!!
  const candidate = Number.parseFloat(localValue)
  const isCandidateValid = isValidScore(candidate)

  const submit = () => {
    if (isValidScore(candidate)) {
      onSlotAction({ score: { pts: candidate } })
    }
  }
  // todo: label properly

  return (
    <VStack gap={2}>
      Enter a score
      <HStack gap={1} mb={2}>
        <Input
          autoFocus={true}
          onChange={(event) => {
            let value = event.target.value
            let cleaned = value
              // 0-9 and .
              .replace(/[^0-9\.]/g, '')
              // single decimal point
              .replace(/(\..*)\./g, '$1')
            setLocalValue(cleaned)
          }}
          onKeyPress={(event) => {
            event.stopPropagation()
            if (event.key === 'Enter') {
              submit()
            } else if (event.key === 'Escape') {
              inputRef.current.blur()
            }
          }}
          ref={inputRef}
          value={localValue}
          width="64px"
        />
        <IconButton
          aria-label="Set score"
          icon={<IcoArrowForward />}
          isDisabled={!isCandidateValid}
          onClick={submit}
        />
      </HStack>
    </VStack>
  )
}

function RubricField(props: MiniFormProps) {
  const { onSlotAction, selectedSlot } = useFixitContext()

  const [local, setLocal] = useState<TicketRubric>()
  // todo: this isn't quite right, but using the 'Done' button for focus
  const [isFocused, setFocused] = useBoolean(false)
  const focusRef = useRef<HTMLButtonElement>()

  useEffect(() => {
    if (focusRef.current) {
      focusRef.current.focus()
    }
    setLocal(
      selectedSlot?.rubric ?? {
        id: 'math.0', // todo: unhardcode!!!
        values: [],
      }
    )
  }, [selectedSlot])

  // todo: centralize
  const lookup = {
    [-1]: 2,
    1: 1,
    2: 0,
  }

  const reverseLookup = {
    2: -1,
    1: 1,
    0: 2,
  }

  // todo: unhardcode
  let cols = 3
  let rows = 3

  let chars = [
    ['q', 'w', 'e'],
    ['a', 's', 'd'],
    ['z', 'x', 'c'],
  ]

  let actions: KeyNButtonProps[] = []

  for (let y = 0; y < rows; y++) {
    for (let x = 0; x < cols; x++) {
      const isSelected = lookup[local?.values[y]] === x

      actions.push({
        children: '',
        colorScheme: isSelected ? 'blue' : null,
        keyChar: chars[y][x],
        onClick: () => {
          setLocal((prev) =>
            produce(prev, (draft) => {
              // toggle
              if (draft.values[y] === reverseLookup[x]) {
                draft.values[y] = null
              } else {
                draft.values[y] = reverseLookup[x]
              }
            })
          )
        },
      })
    }
  }

  const submit = () => {
    onSlotAction({ rubric: local })
  }

  // todo: unhardcode!
  return (
    <VStack gap={4}>
      <KeyNButtonGroup
        actions={actions}
        display="grid"
        gridTemplateColumns={`repeat(${cols}, 1fr)`}
        gridTemplateRows={`repeat(${rows}, 1fr)`}
        isAttached={false}
        placeItems="stretch"
      />
      <Button
        onBlur={setFocused.off}
        onFocus={setFocused.on}
        onClick={submit}
        ref={focusRef}
        rightIcon={getKeyNButtonIcon('Enter')}
        sx={{
          '> span': { display: isFocused ? null : 'none' },
        }}
        width="104px"
      >
        Done
      </Button>
    </VStack>
  )
}

export const fixitForms = {
  packet: { Form: PacketField },
  student: { Form: StudentField },
  score: { Form: ScoreField },
  rubric: { Form: RubricField },
}
