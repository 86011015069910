import { Box, BoxProps, Icon, Tooltip } from '@chakra-ui/react'
import { IconType } from '@paper/icons'
import { IcoScanIssue } from '~src/blocks/icoScanIssue'
import { HStack, Txt, VStack } from '~src/components'
import { pxx } from '~src/utils/layout'

type SingleOrArray<T> = T | T[]

type BadBarSpec = {
  color: BoxProps['bg']
  name: string
  Icon: IconType | typeof IcoScanIssue
  value: number
}

type BadBarsProps = {
  bars: SingleOrArray<BadBarSpec>[]
  denom: number
  fontSize: BoxProps['fontSize']
  height: number
  maxBarWidth: number
  width: number
}

/**
 *  todo: accessibility, probably better as an svg?
 */
export function BadBars(props: BadBarsProps) {
  const { bars, denom, fontSize, height, maxBarWidth, width } = props

  return (
    <VStack
      alignItems="start"
      fontSize={fontSize}
      height={pxx(height)}
      overflow="hidden"
      userSelect="none"
      width={pxx(width)}
    >
      {bars.map((barOrBars) => {
        // todo: implement stacked
        const p = Array.isArray(barOrBars) ? barOrBars[0] : barOrBars

        return (
          <Tooltip key={p.name} label={p.name} placement="end">
            <HStack flexGrow={1} gap={1.5}>
              <Icon aria-label={p.name} as={p.Icon} />
              <Box
                alignSelf="stretch"
                bg={p.color}
                width={pxx((maxBarWidth * p.value) / denom)}
                // transition="width 500ms ease-in" react replaces the elements, so can't animate...
              />
              <Txt as="span">{p.value}</Txt>
            </HStack>
          </Tooltip>
        )
      })}
    </VStack>
  )
}
