import { useRouter } from '@paper/route'
import {
  CrunchBatch,
  ScanImageAbbr,
  ScanStatus,
  XpacketSet,
} from '@paper/schema'
import { JUMP_TO_KEY_ISSUE_SET } from '@paper/utils'
import { createContext, useContext, useMemo } from 'react'
import type { RD_SW_Scanlog } from '~src/routelist'
import { checkDigestAirlock, isRQReady } from '~src/utils/airlock'
import { ScanBatchDigest, useScanBatchList } from './data-scanlog'
import {
  ScanXpacketSetDigest,
  useScanXpacketSetsDigest,
} from './data-scanXpacketSets'
import { ScanFixContext, useMakeScanFixContext } from './fix/scanFixContext'

type ScanlogContext = {
  jumpStatus: ScanStatus
  layout: {
    colPadX: number
  }
  sbDigest: ScanBatchDigest
  scanFix: ScanFixContext
  seekEarlier: () => void
  seekLater: () => void
  selectedBatch: CrunchBatch
  selectedScanImage: ScanImageAbbr
  selectedSheet: ScanImageAbbr[]
  selectedXpacketSet: XpacketSet
  xpacketSetDigest: ScanXpacketSetDigest
}

const ScanlogContext = createContext<ScanlogContext>(null)
export const useScanlogContext = () => useContext(ScanlogContext)
export const useScanFixContext = () => useContext(ScanlogContext).scanFix

/** returns "now" for scanlog  */
export const getScanlogNow = () => new Date().valueOf()

export function ScanlogAirlock({ children }) {
  const { dispatchStay, routeData, useAirlock } = useRouter<RD_SW_Scanlog>()

  const jumpStatus = JUMP_TO_KEY_ISSUE_SET.has(routeData.status as any)
    ? (routeData.status as ScanStatus)
    : 'no-qr'

  // todo: this used to be more complicated...
  const jumpKeys = useMemo(() => [jumpStatus], [jumpStatus])

  const sbDigest = useScanBatchList(jumpKeys)
  const selectedBatch = sbDigest.success?.selectedItem
  const xpacketSetDigest = useScanXpacketSetsDigest(
    selectedBatch?.packets,
    routeData
  )
  const selectedXpacketSet =
    xpacketSetDigest.success?.otherData.selectedXpacketSet

  const imageSheetMap = useMemo(() => {
    const chunks = selectedBatch?.chunks ?? []
    return new Map(
      chunks
        .flatMap((chunk) => chunk.items)
        .flatMap((sheet) =>
          sheet.map((si) => [
            si.id,
            { selectedScanImage: si, selectedSheet: sheet },
          ])
        )
    )
  }, [selectedBatch])

  const { selectedScanImage, selectedSheet } =
    imageSheetMap.get(routeData.si_imageId) ?? {}

  //////////////////
  // airlocks
  //////////////////
  // scan date #313
  useAirlock(
    { scanDate: getScanlogNow() },
    // todo: centralize mode check - this logic is repeated in data-scanlog `useScanBatchList`
    !routeData.scanDate && !routeData.packetId
  )

  // batch
  useAirlock({ sb_batchId: null }, checkDigestAirlock(sbDigest))

  // image
  useAirlock(
    { si_imageId: null },
    routeData.si_imageId &&
      isRQReady(xpacketSetDigest.qResult) &&
      checkDigestAirlock(sbDigest) && // selectedScanImage is only 'ready' if sbDigest is ready
      !selectedScanImage
  )

  // Select first batch if xpacketId or packetId and there are matches
  const firstBatchId = sbDigest.success?.items[0]?.id
  useAirlock(
    { sb_batchId: firstBatchId },
    (routeData.xpacketId || routeData.packetId) &&
      firstBatchId &&
      !selectedBatch &&
      !sbDigest.success?.otherData.noMatches
  )

  // Unselect xpacketId if no packetId
  // todo: is having this many useAirlock calls a problem?
  useAirlock({ xpacketId: null }, routeData.xpacketId && !routeData.packetId)

  const seekEarlier = () => {
    const earliest = sbDigest.success?.items?.at(-1)
    if (earliest) {
      dispatchStay({ dir: null, scanDate: earliest.scanDate - 1 })
    }
  }

  const seekLater = () => {
    const latest = sbDigest.success?.items?.at(0)
    dispatchStay({ dir: 'later', scanDate: latest?.scanDate ?? null })
  }

  let ctx: ScanlogContext = {
    jumpStatus,
    layout: {
      colPadX: 6,
    },
    sbDigest,
    scanFix: useMakeScanFixContext({
      selectedScanImage,
      selectedSheet,
      selectedXpacketSet,
      xpacketSets: xpacketSetDigest.success?.otherData.xpacketSets,
    }),
    seekEarlier,
    seekLater,
    selectedBatch,
    selectedScanImage,
    selectedSheet,
    selectedXpacketSet,
    xpacketSetDigest,
  }

  return (
    <ScanlogContext.Provider value={ctx}>{children}</ScanlogContext.Provider>
  )
}
