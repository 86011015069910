import { ScanBatch, ScanStatus } from '@paper/schema'

export const PAPER_BOT_USER = '*PaperBot*'

const infer = new Set<ScanStatus>(['infer', 'infer-gap', 'infer-blank'])
const fixed = new Set<ScanStatus>([...infer, 'ignore', 'manual'])

const unfixable = new Set<ScanStatus>([
  'error',
  'not-paper-qr',
  'wrong-location',
])

export const ScanStatusSets = {
  auto: new Set<ScanStatus>([...infer, 'success']),
  editable: new Set<ScanStatus>([...fixed, 'no-qr']),
  fixed,
  infer,
  unfixable,
}

function nMinElapsed(since: number, nMin: number) {
  return since && new Date().valueOf() > since + nMin * 60_000
}
/**
 * Returns whether batch is settled vs. stuck vs. maybe processing
 * This is by no means foolproof, but we generally don't want to rerun batches that may be processing
 */
export const getBatchStuckState = (batch: Pick<ScanBatch, 'ts'>) => {
  if (batch.ts.settled) {
    return 'settled'
  } else if (!nMinElapsed(batch.ts.process, 10)) {
    return 'maybe-processing'
  } else {
    return 'maybe-stuck'
  }
}
