import { Box, Button, Switch, Tooltip } from '@chakra-ui/react'
import { IcoTechnical, IcoToggleOnOff } from '@paper/icons'
import { SetupCourse, SetupSection, Student } from '@paper/schema'
import { useFormikContext } from 'formik'
import { SimplePopup } from '~src/blocks/menu/simplePopup'
import {
  IconColumnProps,
  IconLabel,
} from '~src/blocks/packetTable/tableColUtils'
import { TableContainer } from '~src/blocks/tableHelpers'
import {
  CopyButton,
  HStack,
  Italic,
  SpMono,
  Txt,
  VStack,
} from '~src/components'
import { UghColumn, UghTable } from '~src/components/table'
import { getSampleString } from '~src/utils/messages'

type Col = UghColumn<SetupSection>

export function SectionSetupTable() {
  const columns = useSectionSetupColumns()
  const { values } = useFormikContext<SetupCourse>()

  return (
    <TableContainer flexGrow={1} flexShrink={0} fontSize="sm">
      <UghTable
        aria-label={'Sections'}
        columns={columns}
        data={values.sections}
        data-cy="table-setup-section"
        height="100%"
        spacingY="airy"
      />
    </TableContainer>
  )
}

const getProps = (item: SetupSection) => {
  return { opacity: item.isEnabled ? null : 0.5 }
}

const useSectionSetupColumns = () => {
  const { errors, setFieldValue } = useFormikContext<any>()

  const toggleCol: Col = {
    props: IconColumnProps,
    label: () => (
      <IconLabel icon={IcoToggleOnOff} textValue="Add/remove from Paper" />
    ),
    cell: (item, idx) => (
      <Switch
        colorScheme={item.meta.status === 'paper-only' ? 'red' : 'blue'}
        data-cy="toggle-section"
        isChecked={item.isEnabled}
        onChange={(event) => {
          setFieldValue(`sections.${idx}.isEnabled`, !item.isEnabled)
        }}
      />
    ),
  }

  const teacherCol: Col = {
    getProps,
    props: { align: 'start', width: 150 },
    label: () => 'Teacher',
    cell: (item, idx) => {
      const paperTeacher = item.meta.section.teacher
      const sisTeacher = item.meta.leadTeacher
      const error = errors?.sections?.[idx]?.teacherId
      return error ? (
        <Italic color="red.500">{error}</Italic>
      ) : (
        paperTeacher.email
      )
    },
  }

  const nameCol: Col = {
    getProps,
    props: { align: 'start', width: 120 },
    label: () => 'Name',
    cell: (item, idx) => item.meta.section.name,
  }

  const studentsCol: Col = {
    getProps,
    props: { align: 'start', width: 360 },
    label: () => 'Students',
    cell: (item) => {
      return <StudentSamplePopup students={item.meta.section.students} />
    },
  }

  const debugCol: Col = {
    props: IconColumnProps,
    label: () => <IconLabel icon={IcoTechnical} textValue="Debug info" />,
    cell: (item) => {
      const label = 'Copy debug info to clipboard'
      return (
        <Tooltip label={label}>
          <Box>
            <CopyButton
              aria-label={label}
              colorScheme="blackAlpha"
              icon={IcoTechnical}
              size="md"
              toConsole={item}
              value={JSON.stringify(item)}
              variant="ghost"
            />
          </Box>
        </Tooltip>
      )
    },
  }

  const columns: UghColumn<SetupSection>[] = [
    toggleCol,
    teacherCol,
    nameCol,
    studentsCol,
    debugCol,
  ]
  return columns
}

type StudentSamplePopupProps = { students: Student[] }

function StudentSamplePopup(props: StudentSamplePopupProps) {
  const { students } = props
  const sample = getSampleString({
    items: students,
    toString: (item) => item.lastfirst,
  })
  const COL_WIDTH = 300
  return sample ? (
    // todo: copy/pasted from courseSetupTable
    <SimplePopup
      align="end"
      caret={false}
      collisionPadding={50}
      defaultOpen={false}
      side="left"
      sideOffset={4}
      trigger={(menuButtonProps) => (
        <Button
          {...menuButtonProps}
          fontSize="xs"
          gap={1}
          height="auto"
          p={2}
          variant="ghost"
          width="100%"
          sx={{
            // so it stands out against the table background...
            // todo: copy/paste from courseSetupTableCols, should make this a variant
            _active: { bg: `blue.600`, color: 'white' },
            _hover: { bg: `blue.400`, color: 'white' },
            '&[data-state=open]': { bg: `blue.600`, color: 'white' },
          }}
        >
          <SpMono>({students.length})</SpMono>
          <Txt as="span" isTruncated={true}>
            {sample}
          </Txt>
        </Button>
      )}
    >
      <VStack
        alignItems="start"
        display="flex"
        fontSize="xs"
        flexDirection="column"
        flexWrap="wrap"
        gap={1}
        height="100%"
        maxHeight="50vh"
        overflow="auto"
      >
        {students.map((student) => (
          <HStack key={student.id} gap={2} maxWidth={`${COL_WIDTH}px`}>
            <SpMono>{student.number}</SpMono>
            <Txt as="span" isTruncated={true}>
              {student.lastfirst}
            </Txt>
          </HStack>
        ))}
      </VStack>
    </SimplePopup>
  ) : (
    <Italic fontSize="xs">None</Italic>
  )
}
