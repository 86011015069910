export type UnionStringArray<T extends Readonly<string[]>> = T[number]

type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: undefined }

export type XOR<T, U> = T | U extends Object
  ? (Without<T, U> & U) | (Without<U, T> & T)
  : T | U

// Tweaked from https://dev.to/maxime1992/implement-a-generic-oneof-type-with-typescript-22em
type ValueOf<Obj> = Obj[keyof Obj]
// prettier-ignore
type OneOnly<Obj, Key extends keyof Obj> = { [key in Exclude<keyof Obj, Key>]?: never } & Pick<Obj, Key>;
type OneOfByKey<Obj> = { [key in keyof Obj]: OneOnly<Obj, key> }
export type OneOfType<Obj> = ValueOf<OneOfByKey<Obj>>
