import { Button, ButtonGroup } from '@chakra-ui/react'
import { APIs } from '@paper/api-specs'
import { saveAs } from 'file-saver'
import { Formik, FormikErrors } from 'formik'
import { range, sumBy } from 'lodash'
import { Except } from 'type-fest'
import {
  HSep,
  HStack,
  ToggleGroup,
  ToggleRootProps,
  VSep,
  VStack,
} from '~src/components'
import { FullPageLoading } from '~src/components/status'
import { useApiMutation } from '~src/data/useApiQuery'
import { formatUnits } from '~src/utils/messages'
import { FieldUI } from '../publish/formHelpers'
import {
  StickerFormShape,
  StickerFormUnits,
  useStickerForm,
} from './stickerFormBase'
import { StickerFormStudentStage } from './stickerFormStudentStage'
import { StickerStudentPreview } from './stickerStudentTable'

const sharedToggleRootProps: Except<
  ToggleRootProps,
  'children' | 'onChange' | 'type' | 'value'
> = {
  preventNone: true,
  size: 'xs',
}

const validate = (values: StickerFormShape) => {
  let errors: FormikErrors<StickerFormShape> = {}
  // todo: this is sort of repeating pageCount logic
  let studentCount = sumBy(values.studentLists, (p) =>
    sumBy(p.items, (p) => (p.student ? 1 : 0))
  )
  if (studentCount > 0) {
    // yay!
  } else {
    errors['studentLists'] = 'There must be at least 1 valid student selected.'
  }
  return errors
}

export function StickerForm() {
  const initialValues: StickerFormShape = {
    _allLists: null,
    _errorCount: null,
    _selectedListKeys: null,
    _studentNumberText: '',
    _studentNumberTextTrimmed: '',
    _validationPayload: null,
    _validationLoaded: false,
    perStudent: 1,
    studentLists: null,
    unit: StickerFormUnits[0],
  }

  const mutation = useApiMutation({
    apiSpec: APIs['sticker.generate'],
    mutationFn: async ({ fetchAs, method, json, url }) => {
      return (await fetchAs(url, { method, json })).blob()
    },
    useMutationProps: {
      onSuccess(data) {
        saveAs(data, 'stickers.pdf')
      },
    },
  })

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, formikHelpers) => {
        await mutation.mutateAsync({
          perStudent: values.perStudent,
          // filter out errors todo: maybe do this when setting?
          studentLists: values.studentLists
            .map((p) => p.items.map((p) => p.student).filter((p) => p))
            .filter((p) => p),
          unit: values.unit,
        })
      }}
      validate={validate}
    >
      <FullPageLoading qResult={mutation}>
        <VStack
          alignItems="start"
          gap={6}
          overflow="hidden"
          px={8}
          py="2.5rem"
          sx={{ '>*': { flexShrink: 0 } }}
        >
          <StageContainer />
        </VStack>
      </FullPageLoading>
    </Formik>
  )
}

function StageContainer() {
  const { values } = useStickerForm()
  if (values._validationLoaded) {
    return (
      <HStack alignItems="stretch" alignSelf="stretch" gap={8} height="100%">
        <StickerStudentPreview />
        <VSep />
        <VStack alignItems="start" gap={6}>
          <UnitField />
          <PerStudentField />
          <HSep />
          <SubmitButton />
        </VStack>
      </HStack>
    )
  } else {
    return <StickerFormStudentStage />
  }
}

function PerStudentField() {
  const { setFieldValue, values } = useStickerForm()

  return (
    <FieldUI
      label="Per student"
      input={
        <ToggleGroup.Root
          {...sharedToggleRootProps}
          onChange={(value) => setFieldValue('perStudent', parseInt(value))}
          type="single"
          value={values.perStudent.toString()}
        >
          <ButtonGroup isAttached={true}>
            {range(0, 10).map((i) => (
              <ToggleGroup.Button key={i} value={(i + 1).toString()}>
                {i + 1}
              </ToggleGroup.Button>
            ))}
          </ButtonGroup>
        </ToggleGroup.Root>
      }
    />
  )
}

function UnitField() {
  const { setFieldValue, values } = useStickerForm()
  return (
    <FieldUI
      label="Unit"
      input={
        <ToggleGroup.Root
          {...sharedToggleRootProps}
          onChange={(value) => setFieldValue('unit', value)}
          size="sm"
          type="single"
          value={values.unit}
        >
          <ButtonGroup isAttached={true}>
            {StickerFormUnits.map((u) => (
              <ToggleGroup.Button
                key={u}
                value={u}
                textTransform="capitalize"
                width="72px"
              >
                {u}
                {values.perStudent === 1 ? '' : 's'}
              </ToggleGroup.Button>
            ))}
          </ButtonGroup>
        </ToggleGroup.Root>
      }
    />
  )
}

function SubmitButton() {
  const { isValid, isSubmitting, pageCount, submitForm, values } =
    useStickerForm()
  return (
    <Button isDisabled={!isValid || isSubmitting} onClick={submitForm}>
      Generate {formatUnits(pageCount, 'page')}
    </Button>
  )
}
