import { PacketContent, PacketType } from '@paper/schema'
import { fetcher, padTo00 } from '@paper/utils'
import times from 'lodash/times'
import type { RD_Publish } from '~src/routelist'
import { GetPacketEntryData } from '../data-publish'
import { loadPdf } from '../loadPdf'
import type { PubState } from '../publishReducer'
import { getPrereqQueue, NON_PRINT_ASSESS, steps } from '../wizard/steps'
import type { WizState } from '../wizard/wizReducer'
import { getPacketsStub } from './packetStubs'

type EntryStubProps = RD_Publish
export type EntryStub = {
  state: PubState
  formValues: PacketContent
  packetData: GetPacketEntryData
}

/**
 * Stubs packet entry for dev/test
 * This is fairly messy and the overall design could be improved, but makes dev/test a bunch faster
 */
export async function getEntryStub(props: EntryStubProps): Promise<EntryStub> {
  let { contentIdOrNew, curriculumId, dev_step, dev_type } = props
  const curStep = steps[dev_step]
  // Stop if no step
  if (!curStep) {
    return
  }
  const page = curStep.bit & NON_PRINT_ASSESS ? 'entryAnswerKey' : 'entryPdf'
  // Parse other params
  const isNew = contentIdOrNew === 'new'
  // Stick with passed-in contentId if provided, otherwise use dummy value
  const contentId = isNew || !contentIdOrNew ? 'stub' : contentIdOrNew
  // default to assessment (including on nonsense value)
  const type: PacketType = dev_type === 'ticket' ? 'ticket' : 'assessment'

  // We're always going to need a PDF, so load that fixture
  // Load blank images while we're at it for less waiting
  const pdfLength = 5
  const pdfUrl = `/viewer/sample.pdf`
  const imageUrls = times(pdfLength, (idx) => {
    const imageNum = padTo00(idx + 1)
    return `/sample/sample${imageNum}.jpg`
  })
  const [{ srcBuf, srcDoc }, ...rawImages] = await Promise.all([
    loadPdf(pdfUrl),
    ...imageUrls.map((url) => fetcher.get(url).blob()),
  ])

  // Get packet stub data
  const { curriculum, formValues, packets } = getPacketsStub({
    contentId,
    curriculumId,
    curStepBit: curStep.bit,
    isNew,
    omitAnswerKey: type !== 'assessment',
    pdfLength,
    type,
  })

  // Make wiz state
  let wizState: WizState = {
    // reusing rawImages for blanks when stubbing
    // but exclude for editing pdf to match how reset works
    // this is necessary because we need to generate blanks to submit no matter what
    _savedBlanks: isNew || page === 'entryPdf' ? undefined : imageUrls,
    _savedPdfUrl: isNew ? undefined : pdfUrl,
    contentId,
    curriculum,
    curStep,
    isNew,
    rawImages,
    srcBuf,
    srcDoc,
    type: page === 'entryAnswerKey' ? 'answerKey' : 'pdf',
  }

  // Stub images depending on the step
  const { queue } = getPrereqQueue(wizState)

  queue.forEach((pr) => {
    switch (pr) {
      case 'generateBlanks':
      case 'captureBlanks':
        // i'm not worried about actually generating blanks for stubbing...
        // just use rawImages (though adjust for ticket)
        wizState._blanksPdfBlob = new Blob([srcBuf])
        wizState._capturedBlanks =
          type === 'assessment'
            ? rawImages
            : rawImages.slice(formValues.parts[0], formValues.pages.length)
        break
      case 'captureRaw':
      case 'fetchPacketData':
      case 'fetchPdf':
        // todo: why did i only stub raw for isNew above? this contradicts the warning...
        console.warn(`(stub error) ${pr} should have already been stubbed`)
        break
    }
  })

  const packetData = {
    focusPacket: packets[0],
    packets,
    signedImageUrls: imageUrls,
    signedPdfUrl: pdfUrl,
  }

  // Get base state
  let state: PubState = {
    __wizStub__: wizState,
    _contentIdOrNew: contentIdOrNew,
    contentId,
    error: null,
    openedPdf: isNew
      ? {
          srcBuf,
          srcDoc,
          waitingForPackets: false,
        }
      : null,
    page,
  }

  return { state, formValues, packetData }
}
