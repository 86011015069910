import './suppress'

// above needs to be first

import { renderPaper } from './renderPaper'
import { Root } from './root'
import config from './utils/config'
import { PAPER_BUILD_NUMBER } from './buildNumber'

// prevent react-error-overlay for local int
// https://stackoverflow.com/a/62857214
if (process.env.NODE_ENV !== 'production' && config.meta.where === 'int') {
  const reo = require('react-error-overlay')
  reo.stopReportingRuntimeErrors()
}

// as a first step for a versioning story, log the git sha so it's possible to trace to a commit
if (PAPER_BUILD_NUMBER) {
  console.log('Build number:', PAPER_BUILD_NUMBER)
}

renderPaper(<Root />)
