import { Button, ButtonGroup, List } from '@chakra-ui/react'
import { IcoSetup, IcoX } from '@paper/icons'
import { CurriculumVariant } from '@paper/schema'
import { Formik, useFormikContext } from 'formik'
import { ButtonedListItem } from '~src/blocks/listChakra'
import { SimplePopup } from '~src/blocks/menu/simplePopup'
import { HSep, HStack, Mono, Txt, VStack } from '~src/components'
import {
  SingleInputForm,
  Sorter,
  useFormikArrayHelpers,
} from '~src/pages/publish/formHelpers'
import { AdminColumn } from '../admin/adminSetup'
import { useVariantData, useVariantSubmitter } from './data-curriculumSetup'

// todo: copy/paste from AdminSetup

export function VariantSetup() {
  const qResult = useVariantData()
  const submitter = useVariantSubmitter()

  return (
    qResult.isSuccess && (
      <Formik
        enableReinitialize={true}
        // todo: i think this initialization may be a problem
        initialValues={{ variants: qResult.data ?? [] }}
        onSubmit={async (values, formikHelpers) => {
          await submitter.mutateAsync(values.variants)
          // todo: should probably close self, or at least reset dirtyness...
          // todo: want to wait for qResult to update...
          setTimeout(() => formikHelpers.resetForm(), 500)
        }}
      >
        <VariantForm />
      </Formik>
    )
  )
}

const variantSorters = {
  variants: {
    sorter: (item) => item.name,
    keyer: (item) => item.name,
  } satisfies Sorter<CurriculumVariant>,
}

function VariantForm() {
  const { dirty, isSubmitting, isValid, resetForm, submitForm } =
    useFormikContext()

  let { addToArray, filterArray, sorted } = useFormikArrayHelpers(
    variantSorters,
    'variants'
  )

  return (
    // todo: this probably needs to be a proper dialog
    <SimplePopup
      align="end"
      collisionPadding={0}
      side="bottom"
      trigger={(menuButtonProps) => (
        <Button
          data-cy="trigger-variant-setup"
          {...menuButtonProps}
          leftIcon={<IcoSetup />}
          size="sm"
          variant="solid"
        >
          Variant setup
        </Button>
      )}
    >
      <AdminColumn
        title={<>Variants</>}
        desc={<>A curriculum family can have one or more variants.</>}
        form={
          <VStack alignItems="stretch" p={1}>
            <SingleInputForm
              beforeChange={(value) => value?.trim().toUpperCase()}
              clearOnSubmit={true}
              onSubmit={(value) => addToArray({ name: value, desc: null })}
              //placeholder="e.g. admin@myschool.org"
              //validate={validate}
            />
            <HSep />
            <List fontSize="sm" overflowY="auto" p={1}>
              {sorted.map((value) => (
                <ButtonedListItem
                  aria-label="Remove"
                  key={value.name}
                  icon={<IcoX />}
                  onClick={() => filterArray((p) => p.name !== value.name)}
                  variant="ghost"
                >
                  <HStack gap={2} fontSize=".825rem">
                    <Mono whiteSpace="pre">{value.name.padEnd(5, ' ')}</Mono>
                    <Txt as="span" fontSize="xs">
                      {value.desc}
                    </Txt>
                  </HStack>
                </ButtonedListItem>
              ))}
            </List>
            <HSep />
          </VStack>
        }
      />
      <ButtonGroup size="sm">
        <Button
          colorScheme="red"
          isDisabled={!dirty || !isValid}
          isLoading={isSubmitting}
          onClick={() => submitForm()}
        >
          Submit
        </Button>
        <Button onClick={() => resetForm()}>Reset</Button>
      </ButtonGroup>
    </SimplePopup>
  )
}
