import { ButtonProps, VStack } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { BLink, BLinkProps, Hl, HStack, IBLink, Txt } from '~src/components'
import { useScrollIntoView } from '~src/utils/useScroll'
import { NavAction } from './monsterState'
import { TT } from './tooltipNoAnimation'

type MBoxActionButtonProps = {
  isHighlighted: boolean
  item: NavAction
  onClick: ButtonProps['onClick']
}

function MBoxActionButton(props: MBoxActionButtonProps) {
  const { item, isHighlighted, onClick } = props
  const scrollRef = useScrollIntoView(isHighlighted)

  const sharedProps = {
    boxShadow: isHighlighted ? 'outline' : 'none',
    flexShrink: 0,
    onClick,
    overflow: 'hidden',
    size: 'sm',
    variant: 'outline',
  } satisfies BLinkProps

  // todo: not passing href right now, so maybe this is just a button?
  return item.iconOnly ? (
    <IBLink aria-label={item.label} icon={<item.icon />} {...sharedProps} />
  ) : (
    <BLink
      {...sharedProps}
      leftIcon={item.icon && <item.icon />}
      ref={scrollRef}
    >
      {item.label}
    </BLink>
  )
}

type MBoxActionListProps = {
  children: ReactNode
  isOpen: boolean
  items: NavAction[]
  onSelect(thisIndex: number): void
  selectedIndex: number
}

export function MBoxActionList(props: MBoxActionListProps) {
  const { children, isOpen, items, onSelect, selectedIndex } = props

  //console.log('[selectedIndex]', selectedIndex)
  const contents = (
    <VStack alignItems="center" gap={1} p={4} overflow="hidden">
      <HStack gap={4} p={2} overflowX="auto" width="100%">
        {items.map((item, idx) => {
          const isHighlighted = idx === selectedIndex
          return (
            <MBoxActionButton
              key={idx}
              isHighlighted={isHighlighted}
              item={item}
              onClick={(event) => {
                event.stopPropagation()
                onSelect(idx)
              }}
            />
          )
        })}
      </HStack>
      <HStack fontSize="xs" gap={3}>
        {items.length > 1 && (
          <Txt>
            <Hl>Tab</Hl> to toggle
          </Txt>
        )}
        <Txt>
          <Hl>Enter</Hl> to select
        </Txt>
        <Txt>
          <Hl>Esc</Hl> to clear
        </Txt>
      </HStack>
    </VStack>
  )

  return (
    <TT
      bg="#404040"
      borderRadius="md"
      color="whiteAlpha.700"
      hasArrow={true}
      isOpen={isOpen}
      label={isOpen && contents}
      maxWidth="400px"
      placement="right"
      pointerEvents="all"
    >
      {children}
    </TT>
  )
}
