import { Box, Button, ButtonProps, Icon, IconButton } from '@chakra-ui/react'
import { IcoChevronLeft, IcoChevronRight, IcoFunnel } from '@paper/icons'
import { ReactNode, useEffect, useRef } from 'react'
import { HStack, Txt } from '~src/components'
import { AnswerFilter } from './answerFilter'
import { QAgg } from './bargraphTypes'
import { useQStdContext } from './qStdProvider'

type StdQNavProps = { qs: QAgg[] }

export function StdQNav(props: StdQNavProps) {
  let { qs } = props

  // todo: weird combination of props and context
  const { qDigest } = useQStdContext()
  const selectedQ = qDigest.success?.selectedItem

  return (
    <Box
      alignItems="center"
      display="grid"
      height="100%"
      justifyItems="stretch"
      gridColumnGap={3}
      gridTemplateColumns="auto 1fr"
      gridTemplateRows="1fr 1fr"
      overflow="hidden"
      px={4}
      width="100%"
    >
      {/* Question label */}
      <CharLabel char="Q" icon="⇄" />
      {/* Question toggle */}
      <QNav qs={qs} selectedQ={selectedQ} />
      {/* Answer label */}
      <CharLabel
        char="A"
        icon={<Icon as={IcoFunnel} fontSize="xs" />}
        subtext={selectedQ?.type === 'GRID' ? '(pts)' : null}
      />
      {/* Answer filter */}
      <HStack justifyContent="center">
        {selectedQ && <AnswerFilter {...selectedQ} />}
      </HStack>
    </Box>
  )
}

type QNavProps = StdQNavProps & { selectedQ: QAgg }
function QNav(props: QNavProps) {
  const { qs, selectedQ } = props
  const { qDigest, stdDigest } = useQStdContext()

  const height = '20px'
  const selectedRef = useRef<HTMLButtonElement>()

  useEffect(() => {
    selectedRef.current?.scrollIntoView()
  }, [selectedQ?.id])

  // Shared props for prev/next buttons
  const prevNextProps: ButtonProps = {
    height,
    size: 'xs',
    variant: 'ghost',
  }

  return (
    <HStack gap={1} justifyContent="space-between" overflow="hidden" px={2}>
      <IconButton
        {...prevNextProps}
        aria-label="previous question"
        icon={<IcoChevronLeft />}
        onClick={stdDigest.success?.onPrev}
      />
      <HStack gap={1} overflowX="auto">
        {qs.map((q) => {
          // todo: onclick/selected
          // todo: handle button overflow!
          const isSelected = q === selectedQ
          return (
            <Button
              bg={isSelected ? 'gray.200' : undefined}
              height={height}
              key={q.label}
              onClick={() => qDigest.success?.onSelect(q)}
              p="unset"
              ref={isSelected ? selectedRef : null}
              rounded="10px"
              size="xs"
              variant={isSelected ? 'solid' : 'outline'}
              width="24px"
            >
              {q.label}
            </Button>
          )
        })}
      </HStack>
      <IconButton
        {...prevNextProps}
        aria-label="next question"
        icon={<IcoChevronRight />}
        onClick={stdDigest.success?.onNext}
      />
    </HStack>
  )
}

type CharLabelProps = { char: string; icon: ReactNode; subtext?: string }
export function CharLabel(props: CharLabelProps) {
  let { char, icon, subtext } = props
  if (typeof icon === 'string') {
    icon = (
      <Txt as="span" fontSize="sm">
        {icon}
      </Txt>
    )
  }
  // todo: fix accessibility
  return (
    <Box
      alignItems="center"
      display="grid"
      fontFamily="mono"
      gridTemplateColumns="auto 12px"
      justifyItems="center"
      lineHeight={1.2} // match button so alignItems=center works
      position="relative"
    >
      <Txt as="span" fontSize="sm">
        {char}
      </Txt>
      {icon}
      {subtext && (
        <Txt bottom="-9px" fontSize=".55rem" opacity=".8" position="absolute">
          {subtext}
        </Txt>
      )}
    </Box>
  )
}
