import { Button } from '@chakra-ui/react'
import { IcoAdd } from '@paper/icons'
import { useRouter } from '@paper/route'
import { useCurriculumContext } from '~src/blocks/curriculumAirlock'
import { PacketTable } from '~src/blocks/packetTable'
import { curriculumPacketColumns } from '~src/blocks/packetTable/packetTableCols'
import { useActiveCurriculaPicker } from '~src/blocks/pickers'
import { ComboBox } from '~src/components'
import { RD_Home_Curriculum } from '~src/routelist'
import { useSchoolYearContext } from '~src/schoolYearAirlock'
import { useGoToNew } from '../publish/utils'
import { HomePanel } from './homePanel'
import { PacketListFilters } from './packetListFilters'

export function CurriculumPanel() {
  const { curriculum } = useCurriculumContext()
  const { dispatchStay } = useRouter<RD_Home_Curriculum>()
  const curComboProps = useActiveCurriculaPicker({ value: curriculum?.id })
  const goToNew = useGoToNew()
  const { isCurrentYear } = useSchoolYearContext()

  return (
    <HomePanel
      pickers={
        <>
          <ComboBox
            {...curComboProps}
            caret={curComboProps.items.length > 1}
            inputTextAlign="center"
            onChange={(item) => dispatchStay({ curriculumId: item.id })}
            openIfNoSelection={true}
            placeholder="Select a curriculum"
            round={true}
            size="md"
            variant="filled"
            width="240px"
          />
        </>
      }
      filters={
        <>
          <PacketListFilters />
          {goToNew && (
            <Button
              data-cy="btn-add-packet"
              borderRadius="5rem"
              isDisabled={!isCurrentYear}
              leftIcon={<IcoAdd />}
              onClick={goToNew}
              pr={6}
            >
              Add
            </Button>
          )}
        </>
      }
      name={curriculum?.name ?? 'Curriculum'}
      table={<PacketTable columns={curriculumPacketColumns} />}
    />
  )
}
