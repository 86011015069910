import type { Page, Question } from '@paper/schema'

export type ImageIndexSets = Set<number>[]
export type IISLookup = Map<string, ImageIndexSets>

type IIProps = { pages: Page[]; questions: Question[] }

/**
 * Returns imageIndexSets lookup by `qId` for `questions`
 * @example
 * const lookup = qImageIndexLookup(packet, '2-col')
 * const imageIndexSets = lookup.get(qId)
 */
export function getQImageIndexLookup(
  iiProps: IIProps,
  cols: '1-col' | '2-col'
): IISLookup {
  const { pages, questions } = iiProps

  // console.log('gQIIL', questions)

  return new Map(
    questions.map((q) => {
      // add pq ids to columns
      let idSets = [new Set([q.id])]
      if (q.passageIds?.length > 0) {
        // add a second column
        if (cols === '2-col') {
          idSets.push(new Set())
        }
        // add passage ids
        q.passageIds.forEach((id) => {
          idSets[idSets.length - 1].add(id)
        })
      }

      // then convert ids to pageIndexes
      let indexSets: ImageIndexSets = idSets.map(() => new Set())
      pages.forEach((page, pageIdx) => {
        page.items.forEach(({ id: itemId }) => {
          idSets.forEach((set, setIdx) => {
            if (set.has(itemId)) {
              indexSets[setIdx].add(pageIdx)
            }
          })
        })
      })

      return [q.id, indexSets]
    })
  )
}
