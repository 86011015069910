import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { VStack } from '~src/components'
import { QLookup } from '../illuminateLookup/qLookupShared'
import { useWizContext } from './wizProvider'

type QImportDialogProps = {
  isOpen: boolean
  close: () => void
}

export function QImportDialog(props: QImportDialogProps) {
  const { isOpen, close } = props
  const { contentId, formik } = useWizContext()

  let body = (
    <QLookup
      defaultTitle={formik.values._importName}
      onCancel={close}
      onImport={(importValues) => {
        formik.setValues({ ...formik.values, ...importValues })
        close()
      }}
      paperData={{
        contentId,
        prevPages: formik.values.pages,
        prevQs: formik.values.questions,
      }}
    />
  )

  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isCentered={true}
      isOpen={isOpen}
      onClose={close}
    >
      <ModalOverlay
        data-cy="qimport-dialog"
        // todo: better way to do this? otherwise the dialog scrolls when large, which isn't what we want
        sx={{ '.chakra-modal__content-container': { overflow: 'hidden' } }}
      >
        <ModalContent borderRadius={4} maxWidth="95vw">
          <ModalBody overflow="hidden" pt={6}>
            <VStack alignItems="stretch" height="90vh">
              {body}
            </VStack>
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
