import { Box, Button } from '@chakra-ui/react'
import type { Packetino, Xpacket, Xpage } from '@paper/schema'
import { gray75 } from '@paper/styles'
import { PAPER_BOT_USER, toXpageId } from '@paper/utils'
import { useVirtualizer } from '@tanstack/react-virtual'
import { memo, ReactNode, useEffect, useMemo, useRef } from 'react'
import { ListLoadShell, ListRenderer } from '~src/blocks/list'
import { Txt, VStack } from '~src/components'
import { ScanXpacketSetDigest } from '~src/pages/sw/scanlog/data-scanXpacketSets'
import { measurePageGraph, ScanlogPageGraph } from './xpacketPageGraph'

// todo: initial very messy parameterization
type LIPassThroughProps = {
  canSelectPage?(page: Xpage): boolean
  highlightedXpageIdSet?: Set<string>
  onSelectPage?(xpageId: string): void
  selectedXpageId?: string
  targetXpageId?: string | Set<string>
}

type ScanXpacketColumnProps = {
  digest: ScanXpacketSetDigest
  empty?(): ReactNode
  header?: ListRenderer
  idle?(): ReactNode
  packet: Packetino
} & LIPassThroughProps

/**
 * todo: copy/paste with JumpTo and/or UghTable
 */
export function ScanXpacketColumn(props: ScanXpacketColumnProps) {
  const { digest, empty, header, idle, packet, ...liProps } = props

  // Virtualization since there could be a lot of pages...
  const data = digest.success?.items

  const parentRef = useRef()

  const estimateSizeHeightRef = useRef<number>()

  const measured = useMemo(() => {
    let { height, width } = measurePageGraph(packet?.pages.length)
    // add padding
    height += 48
    width += 16

    // todo: @tanstack/react-virtual@3.5 appears to assume `estimateSize` is constant (though their docs are iffy)
    estimateSizeHeightRef.current = height
    return { height, width }
  }, [packet])

  const rv = useVirtualizer({
    count: data?.length,
    estimateSize: () => estimateSizeHeightRef.current,
    getScrollElement: () => parentRef.current,
  })

  const selectedIndex = digest.success?.selectedIndex

  useEffect(() => {
    if (selectedIndex >= 0) {
      // todo: i wish this supported offset, though can maybe accomplish with css?
      rv.scrollToIndex(selectedIndex)
    }
  }, [selectedIndex])

  const onSelect = digest.success?.onSelect

  return (
    <ListLoadShell
      digest={digest}
      px={6}
      width={measured.width + 52} // account for padding
    >
      {(status) => {
        if (status === 'empty') {
          return (
            <VStack gap={2} overflow="hidden">
              {header?.(status)}
              {empty?.()}
            </VStack>
          )
        } else if (status === 'idle') {
          return idle?.()
        } else if (status === 'success') {
          return (
            <VStack gap={2} overflow="hidden">
              {header?.(status)}
              <Box
                ref={parentRef}
                role="presentation"
                overflowY="auto"
                width="100%"
              >
                <Box
                  role="presentation"
                  style={{ height: rv.getTotalSize(), position: 'relative' }}
                >
                  {rv.getVirtualItems().map((vi) => {
                    const xpacket = data[vi.index]
                    return (
                      <Box
                        data-index={vi.index}
                        key={data[vi.index].id}
                        role="presentation"
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: `${vi.size}px`,
                          transform: `translateY(${vi.start}px)`,
                          transitionDuration: '.4s',
                          transitionProperty: 'height top',
                        }}
                      >
                        <XpacketListItem
                          data={xpacket}
                          isSelected={digest.success?.selectedItem === xpacket}
                          onSelect={onSelect}
                          {...liProps}
                        />
                      </Box>
                    )
                  })}
                </Box>
              </Box>
            </VStack>
          )
        }
      }}
    </ListLoadShell>
  )
}

type LIProps<T> = {
  data: T
  isSelected: boolean
  onSelect?(item: T): void
} & LIPassThroughProps

export const XpacketListItem = memo(function XpacketListItem(
  props: LIProps<Xpacket>
) {
  const { data, isSelected, onSelect } = props
  const xp = data

  // todo: copy/paste with ListItem
  let bg = isSelected ? gray75 : undefined
  return (
    <Box
      bg={bg}
      borderBottomWidth="1px"
      display="flex"
      flexDirection="column"
      gap={1}
      overflow="hidden"
      position="relative"
      pt={1}
      pb={3}
      transition={`background-color .3s ease`}
    >
      <Button
        alignSelf="stretch"
        data-cy="btn-scan-xpacket"
        fontSize="sm"
        height="unset"
        justifyContent="flex-start"
        onClick={() => onSelect(isSelected ? null : xp)}
        px={2}
        py={1.5}
        variant="ghost"
        width="100%"
      >
        <Txt
          as="span"
          display="inline"
          fontFamily="mono"
          mr={1.5}
          opacity={0.8}
        >
          {xp.student?.number}
        </Txt>
        <Txt as="span" fontWeight={400} isTruncated={true}>
          {xp.student?.lastfirst ?? '<unnamed>'}
        </Txt>
      </Button>
      <Box px={2}>
        <ScanlogPageGraph
          data={xp.pages.map((page, idx) => {
            const xpageId = toXpageId(xp.id, idx)

            const isAssignTarget = equalsOrIsInSet(xpageId, props.targetXpageId)
            const isScanImageSelection = xpageId === props.selectedXpageId

            const onSelect = !props.canSelectPage?.(page)
              ? null
              : () => props.onSelectPage?.(xpageId)

            const arrow =
              page.movedIn && page.movedOut
                ? 'inout'
                : page.movedIn
                ? 'in'
                : page.movedOut
                ? 'out'
                : null

            return {
              arrow,
              colorScheme: isAssignTarget
                ? 'blue'
                : page.movedIn
                ? 'cyan'
                : page.fix?.key
                ? 'scanFixedGray'
                : !page.imgp
                ? 'scanMissingRed'
                : props.highlightedXpageIdSet?.has(xpageId)
                ? 'grayBtn'
                : 'scanPresentGray',
              onSelect,
              selected: isScanImageSelection,
              symbol:
                page.fix?.by?.user === PAPER_BOT_USER
                  ? 'A'
                  : page.fix?.key
                  ? 'M'
                  : !arrow && !page.imgp
                  ? 'X'
                  : null,
              targeted: isAssignTarget,
            }
          })}
        />
      </Box>
    </Box>
  )
})

const equalsOrIsInSet = (value: string, strOrSet: string | Set<string>) => {
  return !strOrSet
    ? false
    : typeof strOrSet === 'string'
    ? value === strOrSet
    : strOrSet.has(value)
}
