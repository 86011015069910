import {
  AssessmentSrc,
  PacketContent,
  QResp,
  Question,
  StdEmbed,
} from './packetmeta'
import type { Student, Teacher, Term } from './school'

export type SatelliteSection = {
  course: string
  coteacherEmails: string[]
  id: string
  name: string
  schoolId: string
  schoolName: string
  students: Student[]
  teacher: Omit<Teacher, 'id'>
  term: Term
}

export type SatelliteScore = {
  _illuminate: string
  student_number: string
  qs: [label: string, filledStr: string, pts: number][]
}

export type SatelliteAQ = {
  _ill_field_id: number
  bubbles: string[]
  gridChoices?: string
  gridColumns?: number
  isAdvanced: boolean
  isEC: boolean
  isRubric: boolean
  label: string
  maxPts: number
  order: number
  responses: QResp[]
  stds: StdEmbed[]
  type: 'GRID' | 'MC' | 'OER'
}

export type SatelliteAssessment = {
  _ill_assessment_id: number
  instId: string
  questions: SatelliteAQ[]
  syId: number
  title: string
}

export type CandidateA = {
  /** passed validation */
  isValid: boolean
  /** pages may be modified by import */
  pages: PacketContent['pages']
  /** questions with merged passageIds */
  questions: Question[]
  /** assessment srcs */
  srcs: AssessmentSrc[]
  /** school year id */
  syId: number
  /** assessment title */
  title: string
  /** merged and validated questions */
  validatedQs: ValidatedQ[]
}

export type DiffedAssessmentGroup = {
  /** unique import candidates */
  candidates: CandidateA[]
  /** list of qs with diff info */
  diffedQs: DiffedQ[]
  /** whether the group is importable */
  isImportable: boolean
  /** school year id */
  syId: number
  /** illuminate title */
  title: string
}

type Validated<O extends object> = {
  errors: { [key in keyof O]?: string[] }
  hash: string
  isValid: boolean
  srcs: AssessmentSrc[]
  value: O
}

export type ValidatedQ = Validated<Question>
export type DiffStatus = 'ref' | 'match' | 'doesntMatch'
export type DiffedQ = {
  dvResult: ValidatedQ[]
  id: string
  fieldMap: { [key in keyof Question]?: boolean[] }
  isValid: boolean
  q: Question
  status: DiffStatus
}
