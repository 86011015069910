import {
  Button,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  useBoolean,
} from '@chakra-ui/react'
import { ENDASH, sumByPrec } from '@paper/utils'
import { ReactNode, useMemo } from 'react'
import { TableContainer } from '~src/blocks/tableHelpers'
import { HSep, HStack, Txt, VStack } from '~src/components'
import { formatPastVsNow } from '~src/utils/messages'
import { Wiz } from '../publishLayout'
import { QImportDialog } from './formQuestionsImportDialog'
import { QuestionTable } from './formQuestionsTable'
import { useWizContext } from './wizProvider'

const doStatNumber = (value: ReactNode, falseyValue: ReactNode = ENDASH) => {
  let color = !value ? 'gray.200' : null
  return <StatNumber color={color}>{value || falseyValue}</StatNumber>
}

export function FormQuestions() {
  const { formik } = useWizContext()
  const [isDialogOpen, setDialog] = useBoolean(false)

  // Put questions in import format so we can reuse the table
  const { tableQs, totalPts } = useMemo(() => {
    const tableQs = formik.values.questions
    const totalPts = sumByPrec(formik.values.questions, (q) => q.outOf)
    return { tableQs, totalPts }
  }, [formik.values.questions])

  const summaryArea = (
    <VStack alignItems="stretch" flexGrow={1} gap={4}>
      <StatGroup gap={12}>
        <Stat>
          <StatLabel>Illuminate assessment title</StatLabel>
          <HStack
            as="dd"
            data-cy="label-_importName"
            fontSize="lg"
            gap={2}
            lineHeight="36px"
            height="36px"
            whiteSpace="nowrap"
          >
            {formik.values._importName}
            <Button
              data-cy="trigger-dialog-import"
              onClick={setDialog.on}
              size="sm"
            >
              {formik.values._importName ? 'Reimport' : 'Import'}
            </Button>
          </HStack>
        </Stat>
        <Stat>
          <StatLabel>Questions</StatLabel>
          {doStatNumber(tableQs.length)}
        </Stat>
        <Stat>
          <StatLabel>Points</StatLabel>
          {doStatNumber(totalPts)}
        </Stat>
      </StatGroup>
      <VStack alignItems="stretch" gap={1}>
        <Txt fontSize="sm" fontStyle="italic">
          {formik.values._import?.time
            ? `Imported ${formatPastVsNow(formik.values._import.time)}`
            : `Questions are entered via Illuminate import`}
        </Txt>
      </VStack>
    </VStack>
  )

  return (
    <Wiz.FormArea
      display="flex"
      flexDirection="column"
      minWidth={900}
      overflowY="hidden"
      overflowX="auto"
    >
      <QImportDialog close={setDialog.off} isOpen={isDialogOpen} />
      {summaryArea}
      <HSep boxProps={{ my: 4 }} />
      <TableContainer fontSize="sm" hideWhenFetching={true} width="100%">
        <QuestionTable data={tableQs} mode="display" />
      </TableContainer>
    </Wiz.FormArea>
  )
}
