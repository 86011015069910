import type { Action, BaseData, State } from './types'
import { pickBy } from './utils'

export function reducer<D extends BaseData>(
  state: State<D>,
  action: Action
): State<D> {
  const { mergeType = 'overwrite' } = action

  let { data, routeMeta } = action

  // Merge if requested
  if (mergeType === 'merge') {
    data = { ...state.data, ...data }
  }
  // Always include defaults
  else {
    data = { ...data }
  }

  // Update based on routeMeta
  data = routeMeta.mapData(data)

  // Clear out null-ish values
  data = pickBy(data, (value) => value != null) as D

  return { data, routeMeta }
}
