import { Box, BoxProps } from '@chakra-ui/react'
import { Student, XpacketSW } from '@paper/schema'
import {
  CORRECT_COLOR,
  GRAY_HAS_SCANS,
  GRAY_NO_SCORE,
  SCORE_COLOR,
} from '@paper/styles'
import sumBy from 'lodash/sumBy'
import { memo, useEffect, useRef } from 'react'
import { HStack, Txt, VStack } from '~src/components'
import { ItemProps, ListItem } from '../../../blocks/list'
import { useQStdContext } from './bargraph/qStdProvider'

export const XpacketListItem = memo(function XpacketListItem(
  props: ItemProps<XpacketSW>
) {
  const { data, isSelected, onSelect } = props
  const xp = data

  // console.log('render xpli')
  // todo: the layout is a mess...

  return (
    <ListItem
      data-cy="li-xpacket"
      data-sel={isSelected ? true : undefined} // todo: cleaner way to indicate this to cypress?
      data={data}
      heightMs={100}
      isSelected={isSelected}
      onSelect={onSelect}
      unexpandedHeight="52px"
      alignItems="stretch"
      flexDirection="row"
      px={0} // Override padding for status and score
      py={0}
      position="relative"
    >
      <ListItemTag xpacket={data} />
      <HStack flexGrow={1} overflow="hidden" position="relative">
        <StudentLabel student={xp.student} />
      </HStack>
      <XpacketScore isSelected={isSelected} xpacket={xp} />
      <QLabel
        isSelected={isSelected}
        xpacket={xp}
        fontSize=".65rem"
        position="absolute"
        bottom="1px"
        right="3px"
        userSelect="none"
      />
    </ListItem>
  )
})

type ListItemTagProps = {
  xpacket: XpacketSW
}

/** @deprecated Tag mode no longer lives here, so this needs to be cleaned up */
function ListItemTag(props: ListItemTagProps) {
  const { xpacket } = props

  const bg = xpacket.status === 'missing' ? 'red.400' : GRAY_HAS_SCANS

  return (
    <Box
      display="grid"
      alignItems="center"
      gridTemplateColumns="20px 20px"
      justifyItems="center"
    >
      <HStack
        alignSelf="stretch"
        justifySelf="stretch"
        bg={bg}
        p={1}
        transition="background-color .3s ease"
      ></HStack>
    </Box>
  )
}

type StudentLabelProps = { student: Student }
export const StudentLabel = ({ student }: StudentLabelProps) => {
  return (
    <VStack alignItems="flex-start" whiteSpace="nowrap" overflow="hidden">
      <Txt
        as="span"
        fontSize="sm"
        fontWeight={400}
        isTruncated={true}
        width="100%"
      >
        {student?.lastfirst ?? '<unnamed>'}
      </Txt>
      <Txt as="span" fontFamily="mono" fontSize=".825rem" mt={-1} opacity={0.8}>
        {student?.number}
      </Txt>
    </VStack>
  )
}

type XpacketScoreProps = { isSelected: boolean; xpacket: XpacketSW }
function XpacketScore(props: XpacketScoreProps) {
  const { isSelected, xpacket } = props
  const canvasRef = useRef<HTMLCanvasElement>()

  const isActive = true

  const qs = isActive ? xpacket.qs : null
  const pctQs = qs?.filter((p) => p) // in case a q is missing from illuminate
  const pct = Math.round(
    !qs
      ? 0
      : 100 * (sumBy(pctQs, (q) => q.pts) / sumBy(pctQs, (q) => q._outOf_))
  )

  // todo: should probably be svg!
  const height = 5
  const width = 320

  useEffect(() => {
    //console.log('drawing XpacketScore')
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')
    // Clear canvas
    ctx.clearRect(0, 0, width, height)
    ctx.fillStyle = isSelected ? SCORE_COLOR : GRAY_HAS_SCANS

    const correctWidth = Math.round((pct / 100) * width)
    ctx.fillRect(0, 0, correctWidth, height)
    ctx.fillStyle = GRAY_NO_SCORE
    ctx.fillRect(correctWidth, 0, width - correctWidth, height)

    for (let i = 1; i < 10; i++) {
      ctx.fillStyle = 'white'
      ctx.fillRect((i * width) / 10 - 1, 0, 3, height)
    }
  }, [isSelected, pct])

  // todo: messy layout...
  const left = '20px'

  return (
    <canvas
      height={height}
      ref={canvasRef}
      width={width}
      style={{
        position: 'absolute',
        left,
        bottom: 0,
        width: `calc(100% - ${left})`,
      }}
    />
  )
}

type QLabelProps = XpacketScoreProps & BoxProps
function QLabel(props: QLabelProps) {
  const { isSelected, xpacket, ...boxProps } = props
  const qStdCtx = useQStdContext()
  const isActive = true

  // bail if no qStdCtx for now so I can reuse XpacketListItem even if that isn't defined
  if (!isActive || !isSelected || !qStdCtx) {
    return null
  }

  const { qDigest } = qStdCtx
  const curQ = xpacket.qs?.[qDigest.success?.selectedItem?.qIndex]

  if (!curQ) {
    return null
  }

  const isFullCredit = curQ.pts >= curQ._outOf_

  const text = `${isFullCredit ? '✱' : ''}${curQ?.filledStr}`
  const color = !isSelected
    ? 'gray.300'
    : isFullCredit
    ? CORRECT_COLOR
    : undefined

  return (
    <Box {...boxProps} color={color} fontWeight="500">
      {text}
    </Box>
  )
}
