import { useRouter } from '@paper/route'
import { TableContainer } from '~src/blocks/tableHelpers'
import { UghTable } from '~src/components/table'
import { RD_Setup_School } from '~src/routelist'
import { useCourseSetupColumns } from './courseSetupTableCols'
import { useSingleSchoolSetupData } from './data-schoolSetup'
import { CourseSetupDialog } from './dialogs/dialogCourse'

type CourseSetupTableProps = {}

export function CourseSetupTable(props: CourseSetupTableProps) {
  // todo: figure out where all this stuff lives
  const { dispatchStay, routeData } = useRouter<RD_Setup_School>()

  const schoolQResult = useSingleSchoolSetupData(routeData.schoolId)
  const columns = useCourseSetupColumns()

  const selected = schoolQResult.data?.find((p) => p.id === routeData.courseId)
  return (
    <TableContainer
      flexGrow={1}
      fontSize="sm"
      hideWhenFetching={true} // PS api is slow
      margin="auto"
      maxWidth="1200px"
      qResult={schoolQResult}
    >
      <CourseSetupDialog
        data={selected}
        isOpen={!!selected}
        onClose={() => dispatchStay({ courseId: null })}
      />
      {schoolQResult.data && (
        // at time of writing, we airlock in a school
        // todo: since the api is fairly slow, may want to remove that and add an empty message
        <UghTable
          aria-label="Courses"
          columns={columns}
          data={schoolQResult.data}
          data-cy="table-setup-course"
          height="100%"
          // onSelect={(item: SetupCourse) => dispatchStay({ courseId: item.id })}
          // selectedId={routeData.courseId}
          spacingY="snug"
        />
      )}
    </TableContainer>
  )
}
