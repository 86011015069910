/// <reference lib="dom" />

import type { Input, Options } from 'ky'
import { Except } from 'type-fest'
import { ApiDefBase } from './defineApi'

export type ApiOptionsData<A extends ApiDefBase> = A['method'] extends 'get'
  ? { method: 'get'; searchParams: A['searchParams'] }
  : {
      method: 'post' | 'put' | 'delete' | 'patch'
      json: A['body']
    }

export type ApiOptions<A extends ApiDefBase> = Except<
  Options,
  'method' | 'json' | 'searchParams'
> &
  ApiOptionsData<A>

/**
 * Pre-typed version of `Fetcher`
 */
export type ApiFetcher<A extends ApiDefBase> = (
  url: Input,
  options: ApiOptions<A>
) => TypedResponsePromise<A['result']>

/**
Returns a `Response` object with `Body` methods added for convenience. So you can, for example, call `ky.get(input).json()` directly without having to await the `Response` first. When called like that, an appropriate `Accept` header will be set depending on the body method used. Unlike the `Body` methods of `window.Fetch`; these will throw an `HTTPError` if the response status is not in the range of `200...299`. Also, `.json()` will return an empty string if the response status is `204` instead of throwing a parse error due to an empty body.
*/
interface TypedResponsePromise<T> extends Promise<Response> {
  // arrayBuffer: () => Promise<ArrayBuffer>;
  // blob: () => Promise<Blob>;
  json: () => Promise<T>
  // text: () => Promise<string>;
}
