import { APIs } from '@paper/api-specs'
import { StrId } from '@paper/schema'
import { getActiveCurriculumPred } from '@paper/utils'
import { orderBy } from 'lodash'
import { useSchoolYearContext } from '~src/schoolYearAirlock'
import { Dict, ExtractQuery } from '~src/utils/types'
import { useApiQuery } from './useApiQuery'

export type DirData = ExtractQuery<ReturnType<typeof useDirectoryData>>
export type DirRecord<K extends keyof DirData> = DirData[K] extends {
  map: Map<any, any>
}
  ? ReturnType<DirData[K]['map']['get']>
  : never

/**
 * Fetches curriculum/schools/teachers for the base 'directory'
 * It also returns a stopgap global `canEdit`
 */
export function useDirectoryData() {
  const { syId } = useSchoolYearContext()

  const qResult = useApiQuery({
    apiSpec: APIs['dir.list'],
    useQueryProps: {
      enabled: !!syId,
      staleTime: Infinity,
    },
    queryVars: {
      searchParams: { syId },
    },
    queryFn: async ({ plainFetch }) => {
      let result = await plainFetch()
      const { canEdit } = result

      console.time('crunch(dir)')

      // this is messy, was previously inferring keys from csts, but that could be empty
      const makeAxis = getAxisMaker(result.csts, [
        'curriculumId',
        'schoolId',
        'teacherId',
      ])

      const curriculum = makeAxis(
        result.curricula,
        'curriculumId',
        (p) => p.name,
        canEdit
      )

      const school = makeAxis(
        result.schools,
        'schoolId',
        (p) => p.name,
        canEdit
      )

      const teacher = makeAxis(
        result.teachers,
        'teacherId',
        (p) => p.email,
        canEdit
      )

      const activeCurricula = curriculum.items.filter(
        getActiveCurriculumPred(syId)
      )
      console.timeEnd('crunch(dir)')

      return { activeCurricula, curriculum, school, teacher }
    },
  })

  // todo: throw error, not 100% sure why, but otherwise page goes nuts retrying indefinitely
  if (qResult.isError) {
    throw qResult.error
  }

  return qResult
}

// todo: writing this a lot, but not sure if/how to factor out yet
export const useLookupInDirData = (props: {
  curriculumId?: string
  schoolId?: string
  teacherId?: string
}) => {
  const { curriculumId, schoolId, teacherId } = props
  const dirData = useDirectoryData().data
  const curriculumRecord =
    curriculumId && dirData?.curriculum.map.get(curriculumId)
  const teacherRecord = teacherId && dirData?.teacher.map.get(teacherId)
  const schoolRecord = schoolId && dirData?.school.map.get(schoolId)
  return {
    curriculum: curriculumRecord?.item,
    curriculumRecord,
    dirData,
    school: schoolRecord?.item,
    schoolRecord,
    teacher: teacherRecord?.item,
    teacherRecord,
  }
}

type Axis<T, L extends Dict, K extends keyof L> = {
  items: T[]
  map: Map<
    string,
    // @ts-expect-error somehow doesn't know that Exclude<keyof L, K> is a string
    Record<`${Exclude<keyof L, K>}s`, Set<string>> & {
      canEdit: boolean
      item: T
    }
  >
}

const getAxisMaker = <L extends Dict>(links: L[], allKeys: (keyof L)[]) => {
  /**
   * Quite possibly messier than writing each one out...
   */
  return <T extends StrId, K extends keyof L>(
    items: T[],
    key: K,
    sorter: (item: T) => any,
    canEdit: boolean
  ): Axis<T, L, K> => {
    // Sort
    items = orderBy(items, sorter)

    // Get lookup keys
    let lookupKeys = allKeys.filter((p) => p !== key)

    // Lookup
    const map = new Map(
      items.map((item) => {
        const sets = Object.fromEntries(
          lookupKeys.map((key) => [`${String(key)}s`, new Set()])
        )
        return [item.id, { canEdit, item, ...sets }]
      })
    ) as Axis<T, L, K>['map']

    // Add links to lookup
    links.forEach((link) => {
      // Get entry for this id: e.g. `map.get(curriculumId)`
      const entry = map.get(link[key])
      // Add the other ids to the lookup
      // e.g. entry.schoolIds.add(schoolId)
      if (!entry) {
        throw Error(`No entry for ${link[key]}, (key: ${key as string})`)
      }
      for (let linkKey of lookupKeys) {
        entry[`${String(linkKey)}s`].add(link[linkKey])
      }
    })

    return { items, map }
  }
}
