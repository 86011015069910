import { BoxProps, Icon } from '@chakra-ui/react'
import { IcoUpdatedAt } from '@paper/icons'
import type { LastUpdate } from '@paper/schema'
import { Txt } from '~src/components'
import { formatPastVsNow } from '~src/utils/messages'
import { useUser } from './userProvider'

type LastUpdateProps = {
  fontSize?: BoxProps['fontSize']
  lastUpdate: LastUpdate
}

export function LastUpdateDisplay(props: LastUpdateProps) {
  const { fontSize, lastUpdate } = props
  const hookResult = useUser() // splitting out so i can use in anyone-can-view
  const isYou = hookResult?.user.email === lastUpdate?.user
  return !lastUpdate ? null : (
    <Txt as="span" fontSize={fontSize ?? 'xs'} opacity={0.8}>
      <Icon as={IcoUpdatedAt} aria-label="Last updated" mb="-2px" />{' '}
      {formatPastVsNow(lastUpdate.time)} | {isYou ? 'you' : lastUpdate.user}{' '}
    </Txt>
  )
}
