import { Button, Textarea } from '@chakra-ui/react'
import { APIs } from '@paper/api-specs'
import { itemFromSet } from '@paper/utils'
import { useTeacherContext } from '~src/blocks/teacherAirlock'
import {
  ComboBox,
  HSep,
  HStack,
  LabeledVSep,
  Mono,
  Txt,
  VStack,
} from '~src/components'
import { LoadingDots } from '~src/components/status'
import { useApiQuery } from '~src/data/useApiQuery'
import { useSchoolFromRouter } from '../home/schoolPanel'
import { useTeacherCurriculaContextPickers } from '../home/teacherPanel'
import { useListenQuery } from '../publish/data-publish'
import { FieldUI } from '../publish/formHelpers'
import { useStickerForm } from './stickerFormBase'

type StickerFormStudentStageProps = {}

export function StickerFormStudentStage(props: StickerFormStudentStageProps) {
  const { initStudentLists, values } = useStickerForm()

  // todo: handle errors
  const qResultStudentLists = useApiQuery({
    apiSpec: APIs['sticker.validate'],
    queryVars: { body: values._validationPayload },
    useQueryProps: { enabled: !!values._validationPayload },
  })

  useListenQuery(qResultStudentLists, true, (qResult) => {
    console.log('useListenQuery', qResult)
    initStudentLists(qResult.data)
  })

  // Loading
  if (qResultStudentLists.isFetching) {
    return (
      <VStack justifyContent="center" width="100vw">
        <LoadingDots />
      </VStack>
    )
  } else if (qResultStudentLists.isError) {
    console.error(qResultStudentLists.error)
    return <>todo: Error</>
  }

  return (
    <VStack alignItems="start" gap={6}>
      <Txt fontSize="lg">Choose students</Txt>
      <HSep />
      <HStack alignItems="start" gap={8}>
        <TeacherField />
        <LabeledVSep
          alignSelf="stretch"
          placement="5%"
          label={<Mono fontSize="xl" opacity={0.7}>{`{or}`}</Mono>}
        />
        <PasteField />
      </HStack>
    </VStack>
  )
}

function PasteField() {
  // todo: copy/paste from schoolPanel and teacherPanel
  const { dispatchSchool, schComboProps, school } = useSchoolFromRouter()
  const { setStudentNumberText, setValidationPayload, values } =
    useStickerForm()

  const schoolPicker = (
    <FieldUI
      label="Select a school"
      input={
        <ComboBox
          {...schComboProps}
          caret={true}
          inputTextAlign="center"
          onChange={dispatchSchool}
          renderItem={(item) => (
            <Txt as="span" fontSize="sm">
              {item.name}
            </Txt>
          )}
          round={true}
          size="md"
          variant="filled"
          width="300px"
        />
      }
    />
  )

  const textarea = (
    <FieldUI
      label={`Student numbers`}
      input={
        <Textarea
          fontSize="sm"
          height="300px"
          onChange={(event) => setStudentNumberText(event.target.value)}
          placeholder="Paste student numbers here, one per line."
          value={values._studentNumberText}
          width="300px"
        />
      }
    />
  )

  return (
    <VStack gap={4}>
      {schoolPicker}
      {textarea}
      <Button
        isDisabled={!school || !values._studentNumberTextTrimmed}
        onClick={() =>
          setValidationPayload({
            input: values._studentNumberTextTrimmed,
            schoolId: school.id,
            type: 'studentNumber',
          })
        }
      >
        Continue
      </Button>
    </VStack>
  )
}

function TeacherField() {
  // todo: copy/paste with pdfBuilderProvider/printDialog
  const { teacher, teacherRecord } = useTeacherContext()

  const schoolId = teacherRecord && itemFromSet(teacherRecord?.schoolIds)

  const { setValidationPayload, values } = useStickerForm()

  const { teacherPicker } = useTeacherCurriculaContextPickers({
    openIfNoSelection: false,
  })
  return (
    <FieldUI
      label="By teacher"
      input={
        <VStack alignSelf="start" gap={4}>
          {teacherPicker}
          {/* todo: maybe lookup automatically */}
          <Button
            isDisabled={!teacher}
            onClick={() => {
              setValidationPayload({
                input: teacher.id,
                schoolId,
                type: 'teacherId',
              })
            }}
          >
            Continue
          </Button>
        </VStack>
      }
    />
  )
}
