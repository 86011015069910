import { ListItem } from '@chakra-ui/react'
import { IcoMultiparter, IcoStaple } from '@paper/icons'
import { getLastFirst } from '@paper/utils'
import { sumBy } from 'lodash'
import { GhostTag } from '~src/components'
import { formatUnits } from '~src/utils/messages'
import { PageCounts } from '../pageCounts'
import { usePdfBuilderContext } from './pdfBuilderProvider'
import {
  DialogSubsection,
  lifactPieces,
  renderPiecesSS,
} from './printDialogLayout'

type PrintStatsProps = {}

export function PdfPrintStats(props: PrintStatsProps) {
  const {
    parentView,
    selected: item,
    stats,
    unnamedPerSection,
  } = usePdfBuilderContext()
  const includeNames = parentView === 'school'
  const size = parentView === 'school' ? 'sm' : 'xs'
  const { sections } = item

  const teacherLabel =
    item.grain === 'packet'
      ? // todo: precalculate teacher count
        formatUnits(new Set(sections.map((p) => p.teacher.id)).size, 'teacher')
      : getLastFirst(item.teacher)

  ////////////////////////////
  // Curriculum and teacher
  const curriculumAndTeaher = includeNames && (
    <ListItem alignItems="center" display="flex" gap={2} minHeight="32px">
      {renderPiecesSS([item.curriculum.name, teacherLabel])}
    </ListItem>
  )

  ////////////////////////////
  // Student section counts
  const stuSecCounts = liStuSecCounts({
    sec: sections.length,
    stu: sumBy(sections, (sec) => sec.students.length),
    unnamed: unnamedPerSection * sections.length,
  })

  ////////////////////////////
  // Packet name
  const packetName = includeNames && (
    <ListItem alignItems="center" display="flex" gap={2} minHeight="32px">
      {item.packet.name}
    </ListItem>
  )

  ////////////////////////////
  // Page stats
  const pageStats = lifactPieces([
    <GhostTag
      color="yellow.500"
      fontSize={size}
      label={formatUnits(item.packet.parts.length, 'part')}
      leftIcon={IcoMultiparter}
      minHeight="unset"
    />,
    <PageCounts packet={item.packet} variant="long" />,
    stats.isStapled && (
      <GhostTag
        aria-label="Stapling"
        color="yellow.500"
        label=""
        leftIcon={IcoStaple}
        minHeight="unset"
      />
    ),
  ])

  return (
    <DialogSubsection.List fontSize={size} spacingY="airy">
      {packetName}
      {pageStats}
      {curriculumAndTeaher}
      {stuSecCounts}
    </DialogSubsection.List>
  )
}

type StuSecCountProps = { sec: number; stu: number; unnamed: number }
export const liStuSecCounts = (props: StuSecCountProps) => {
  const { sec, stu, unnamed } = props
  return lifactPieces([
    // section count
    formatUnits(sec, 'section'),
    // student count
    formatUnits(stu, 'student'),
    // unnamed
    !!unnamed && <>{unnamed} unnamed</>,
  ])
}
