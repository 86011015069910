import { Box, BoxProps } from '@chakra-ui/react'
import { QResp, Question } from '@paper/schema'
import { CORRECT_COLOR, DEFAULT_FG } from '@paper/styles'
import { orderBy } from 'lodash'
import { memo, ReactNode, useMemo, useRef } from 'react'
import { Mono, VStack } from '~src/components'
import { useScrollToSelected } from '~src/utils/useScroll'

type QuestionRowProps = {
  data: Question
  isScrolledTo?: boolean
  noBorder?: boolean
  noLabel?: boolean
  qResp?: QResp
  showPts?: boolean
}
export const QuestionRow = (props: QuestionRowProps) => {
  const { data, isScrolledTo, noBorder, noLabel, showPts } = props
  const { label, maxPts, options, responses, type } = data
  const domRef = useRef()
  useScrollToSelected(domRef, isScrolledTo)

  // todo: at some point should indicate qs with multiple answers
  const topResponse = props.qResp ?? responses.find((p) => p.pts === maxPts)
  // todo: handle no topResponse (should probably be a validation error)
  const topResponseSet = new Set(topResponse?.filledStr.split(''))

  let bubbles: ReactNode

  switch (type) {
    case 'GRID':
      bubbles = topResponse?.filledStr
        .split('')
        .map((char, idx) => (
          <Bubble color="teal.600" key={idx} label={char} filled={false} />
        ))
      break
    case 'MC':
    case 'OER':
      bubbles = options.map(({ label: optionLabel }, idx) => {
        const filled = type === 'OER' ? false : topResponseSet.has(optionLabel)
        return (
          <Bubble
            color={filled ? CORRECT_COLOR : undefined}
            key={idx}
            label={optionLabel}
            filled={filled}
          />
        )
      })
      break
    default:
      bubbles = (
        <Mono color="red.500" fontSize="xs">
          Unrecognized question type
        </Mono>
      )
      break
  }

  const labelEl = !noLabel && (
    <Mono fontSize="xs" whiteSpace="pre" width="20px">
      {label.padStart(2, ' ')}
    </Mono>
  )

  const ptsEl = showPts && (
    <Mono fontSize="xs" mr={2}>
      ({topResponse.pts} pts)
    </Mono>
  )

  return (
    <Box
      alignItems="center"
      borderBottom={noBorder ? null : '1px solid #e8e8e8'}
      display="flex"
      flexShrink={0}
      fontSize="xs"
      overflow="hidden"
      px="5px"
      py="2px"
      ref={domRef}
      textOverflow="ellipsis"
      userSelect="none"
      whiteSpace="nowrap"
      sx={{
        '> *': {
          flexShrink: 0,
          marginRight: '2px',
        },
      }}
    >
      {labelEl}
      {ptsEl}
      {bubbles}
    </Box>
  )
}

export const QuestionRowAllResponses = (
  props: Omit<QuestionRowProps, 'noBorder' | 'noLabel' | 'qResp'>
) => {
  let { data } = props
  let sorted = useMemo(() => {
    return orderBy(data.responses, ['pts', 'filledStr'], ['desc', 'asc'])
  }, [data.responses])
  return (
    <VStack alignItems="stretch">
      {sorted.map((qResp, idx) => (
        <QuestionRow
          key={idx}
          noBorder={true}
          noLabel={true}
          showPts={true}
          {...props}
          qResp={qResp}
        />
      ))}
    </VStack>
  )
}

type BubbleProps = {
  color?: BoxProps['color']
  filled: boolean
  label: string
  size?: number
}

const Bubble = memo(function Bubble(props: BubbleProps) {
  const { color = DEFAULT_FG, filled, label, size = 15 } = props
  return (
    <Box
      as="span"
      alignItems="center"
      bg={filled ? color : undefined}
      borderColor={color}
      borderWidth="1px"
      borderRadius={`${size}px`}
      color={filled ? 'white' : color}
      display="inline-flex"
      fontFamily="mono"
      fontSize="10.5px"
      fontStyle="italic"
      justifyContent="center"
      height={`${size}px`}
      paddingRight="1px"
      userSelect="none"
      width={`${size}px`}
    >
      {label}
    </Box>
  )
})
