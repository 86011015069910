import {
  Box,
  BoxProps,
  CSSObject,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemProps,
  MenuList,
} from '@chakra-ui/react'
import { PageItemType, Passage, Question } from '@paper/schema'
import { SELECTION_COLOR } from '@paper/styles'
import { Fragment, ReactNode } from 'react'

type MenuAction = {
  children: ReactNode
  onClick: MenuItemProps['onClick']
}

export type RenderItem<T> = (
  item: T,
  actions: MenuAction[],
  isHighlighted?: boolean,
  key?: string
) => ReactNode

export type PActionTuple = { itemType: 'passage'; item: Passage }
export type QActionTuple = { itemType: 'question'; item: Question }
export type PQTuple = PActionTuple | QActionTuple

export type ActionFactory<T = PQTuple> = (props: T) => MenuAction[]

type PQMenuShellProps = {
  actions?: MenuAction[]
  color: string
  children: ReactNode
  displayMode?: 'compact' | 'loose'
  isHighlighted: boolean
}

export function PQMenuShell(props: PQMenuShellProps) {
  const { actions, children, ...rest } = props
  const hasActions = actions?.length > 0

  // Render just a label with no actions
  if (!hasActions) {
    return <Box sx={getShellStyles({ hasActions, ...rest })}>{children}</Box>
  }

  return (
    <Menu placement="right-start">
      {({ isOpen }) => {
        const menuButtonStyles = getShellStyles({ hasActions, isOpen, ...rest })
        return (
          <Fragment>
            <MenuButton
              flexShrink={0}
              fontSize="xs"
              overflow="hidden"
              sx={menuButtonStyles}
              type="button" // note: this prevents the MenuButton from submitting the form
            >
              {children}
            </MenuButton>
            {isOpen && (
              // note: not sure what we lose out on, but seems to render faster with a lot of buttons
              <MenuList fontFamily="mono" fontSize=".75em" minW="11rem" py={1}>
                {actions.map((props, idx) => (
                  <MenuItem
                    {...props}
                    key={idx}
                    fontWeight="bold"
                    minH="1.75rem"
                    px={3}
                    _focus={{
                      backgroundColor: 'blue.400',
                      color: 'white',
                    }}
                  />
                ))}
              </MenuList>
            )}
          </Fragment>
        )
      }}
    </Menu>
  )
}

PQMenuShell.displayName = 'PQMenuShell'

export type PQCheckboxShellProps = {
  children: ReactNode
  color: string
  displayMode?: 'compact' | 'loose'
  isChecked: boolean
  isLastAction?: boolean
  pendingAction?: 'check' | 'uncheck'
  type: PageItemType
  // todo: need to check if these are the right events for all input devices
  // todo: plus need to accessiblitize this...
} & Pick<BoxProps, 'onMouseEnter' | 'onMouseLeave' | 'onClick'>

export const PQCheckboxShell = (props: PQCheckboxShellProps) => {
  const { children, isChecked, type, onClick, onMouseEnter, onMouseLeave } =
    props
  const styles = getShellStyles({
    ...props,
    hasActions: true,
    isHighlighted: isChecked,
  })
  const onOff = isChecked ? 'on' : 'off'

  return (
    <Box
      data-cy={`ak-${type}`}
      data-state={onOff}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      sx={styles}
    >
      {children}
    </Box>
  )
}

PQCheckboxShell.displayName = 'PQCheckboxShell'

/////////////////////////
// Style
/////////////////////////

type ShellStyleProps = Pick<PQMenuShellProps, 'displayMode' | 'isHighlighted'> &
  Pick<PQCheckboxShellProps, 'isLastAction' | 'pendingAction'> & {
    color: string
    hasActions: boolean
    isOpen?: boolean
  }

export const getShellStyles = (props: ShellStyleProps): CSSObject => {
  const {
    color,
    displayMode = 'compact',
    hasActions,
    isHighlighted,
    isLastAction,
    isOpen,
    pendingAction,
  } = props

  const borderStyle = pendingAction === 'check' ? 'solid' : 'dashed'
  const borderLefter = (color: string, style?: string) =>
    `3px ${style ?? borderStyle} ${color}`

  // todo: this could probably be converted to a normal component?
  const base: CSSObject = {
    borderLeft: borderLefter('transparent', 'solid'),
    outline: 'none',
    position: 'relative',
    textAlign: 'start',
    transitionDuration: '.25s',
    transitionProperty: 'border-left, background-color, border-radius',
    transitionTimingFunction: 'ease-in',
    userSelect: 'none',
  }

  const compact: CSSObject = {
    padding: '0 0.25rem',
  }

  const loose: CSSObject = {
    borderBottom: 'none',
    borderRight: borderLefter(color),
    marginBottom: '2px',
    padding: '5px 7px',
  }

  const hover: CSSObject = {
    cursor: 'pointer',
    ':hover': {
      bg: isHighlighted ? '#eee5b9' : '#faf7ea', // selection.400 / selection.100
      borderLeft: borderLefter(color),
      borderRadius: `${displayMode === 'loose' ? 15 : 10}px`,
    },
  }

  const open: CSSObject = {
    borderLeft: `3px dotted #a0aec0`,
  }

  const highlighted: CSSObject = {
    bg: SELECTION_COLOR, // #ebe0a9
    borderRadius: `${displayMode === 'loose' ? 15 : 10}px`,
  }

  // todo: messy cursor for last action, probably need to make room in row
  const lastAction: CSSObject = {
    '::after': {
      color,
      content: '"❮"',
      fontSize: 'xs',
      position: 'absolute',
      top: 'calc(50% - 9px)',
      right: '-3px',

      // content: '"‸"',
      // fontSize: 'lg',
      // fontWeight: 'bold',
      // position: 'absolute',
      // bottom: '0',
      // right: '0',
    },
  }

  return {
    ...base,
    ...(displayMode === 'compact' ? compact : loose),
    ...(isHighlighted ? highlighted : {}),
    ...(hasActions ? hover : {}),
    ...(isOpen ? open : {}),
    ...(pendingAction ? (hover[':hover'] as CSSObject) : {}),
    ...(isLastAction ? lastAction : {}),
  }
}
