import { Box } from '@chakra-ui/react'
import { useRouter } from '@paper/route'
import { useListContext } from '~src/blocks/list'
import { SWSectionPicker } from '~src/blocks/pickers'
import { StatStack, VStack } from '~src/components'
import { XpacketDigest } from '~src/data/data-xpackets'
import type { RD_SW_JumpToStd } from '~src/routelist'
import { useAlwaysUpdateRef } from '~src/utils/useRefs'

type RD_Base = RD_SW_JumpToStd

export function XpacketListFilters() {
  const { scanCounts, sections } = useListContext<XpacketDigest>().otherData
  const { dispatchStay, routeData } = useRouter<RD_Base>()
  const { f_scans } = routeData

  const routeDataRef = useAlwaysUpdateRef(routeData)
  function makeToggleHandler<K extends keyof RD_Base>(
    routerKey: K,
    value: RD_Base[K]
  ) {
    return () => {
      // Get old value
      const old = routeDataRef.current[routerKey]
      const nextValue =
        old === value
          ? null // clear if already this value
          : value // otherwise switch to value
      console.log({ routerKey, old, nextValue })
      // Navigate
      dispatchStay({ [routerKey]: nextValue })
    }
  }

  return (
    <VStack gap={2}>
      <SWSectionPicker items={sections} size="xs" />
      <Box
        alignItems="center"
        display="grid"
        gridAutoColumns="1fr"
        gridAutoFlow="column"
        gridColumnGap={1}
        justifyItems="stretch"
      >
        <StatStack
          onClick={makeToggleHandler('f_scans', 'scans')}
          selected={f_scans === 'scans'}
          subtext="scans"
          text={`${scanCounts.scans}`}
        />
        <StatStack
          onClick={makeToggleHandler('f_scans', 'noscans')}
          selected={f_scans === 'noscans'}
          subtext="no scans"
          text={`${scanCounts.noscans}`}
        />
      </Box>
    </VStack>
  )
}
