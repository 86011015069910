import { APIs } from '@paper/api-specs'
import { useRouter } from '@paper/route'
import { sortNumeric } from '@paper/utils'
import { useMemo } from 'react'
import { RD_Home_Curriculum, RD_Home_Teacher } from '~src/routelist'
import { useFilters } from '~src/utils/useFilters'
import { useApiQuery } from './useApiQuery'

type UsePacketListDataProps = {
  override?: { curriculumId: string; teacherId: string }
}

/**
 * @deprecated Not really, but need to check for leaky `teacherId` param
 * e.g. Anywhere that isn't teacher-specific needs to lose the `teacherId` param otherwise it'll grab that teacher's data
 * @warning `usePacketListData().data !== usePacketList.qResult.data` - The qResult is unfiltered.
 */
export function usePacketListData(props?: UsePacketListDataProps) {
  // todo: may need to split this further for PacketList vs. JumpTo
  // todo: may need explicit params as part of that! (e.g. we may not be on RD_HomeTeacher)
  const { routeData } = useRouter<RD_Home_Teacher & RD_Home_Curriculum>()
  const { contains: existsAndContains } = useFilters()

  const { curriculumId, teacherId } = props?.override ?? routeData

  const qResult = useApiQuery({
    apiSpec: APIs['dir.listPackets'],
    useQueryProps: {
      enabled: !!curriculumId,
      // Attempt to prevent 2 packet queries in quick succession
      // Not sure the exact right value or if more invalidation is needed
      staleTime: 10000,
      throwOnError: true,
    },
    queryVars: { body: { curriculumId, teacherId } },
    queryFn: async ({ plainFetch }) => {
      const packets = await plainFetch()
      sortNumeric(packets, (item) => item.number)
      // todo: debugging
      // console.log('usePacketListData', teacherContext)
      return packets
    },
  })

  // Apply client-side filtering
  let data = useMemo(() => {
    let packets = qResult.data ?? []

    if (packets.length === 0) {
      return packets
    }

    if (routeData.pl_filterText) {
      packets = packets.filter(
        (p) =>
          existsAndContains(p.name, routeData.pl_filterText) ||
          existsAndContains(p.number, routeData.pl_filterText)
      )
    }
    if (routeData.pl_packetType) {
      packets = packets.filter((p) => p.type === routeData.pl_packetType)
    }
    // scanStatus
    if (routeData.pl_scanStatus === 'scanned') {
      packets = packets.filter((p) => p.scan.count > 0)
    } else if (routeData.pl_scanStatus === 'partial') {
      packets = packets.filter((p) => p.scan.partial > 0)
    } else if (routeData.pl_scanStatus === 'unnamed') {
      packets = packets.filter((p) => p.scan.unnamedOpen > 0)
    }
    // printStatus
    switch (routeData.pl_printStatus) {
      case 'not':
        packets = packets.filter((p) => !p.print.date)
        break
      case 'printed':
        packets = packets.filter((p) => p.print.date)
        break
      case 'radioactive':
        packets = packets.filter((p) => p.pub.radioactive)
        break
    }
    // publish status
    if (routeData.pl_pub) {
      packets = packets.filter((p) => p.pub.stage === routeData.pl_pub)
    }

    return packets
  }, [
    qResult.data,
    routeData.pl_filterText,
    routeData.pl_packetType,
    routeData.pl_printStatus,
    routeData.pl_pub,
    routeData.pl_scanStatus,
  ])

  // todo: make this a "digest" probably
  let selectedItem = data.find((p) => p.id === routeData.packetId)
  return { data, qResult, selectedItem }
}

export function usePacketBlanks(props: { enabled: boolean; packetId: string }) {
  const { enabled, packetId } = props

  return useApiQuery({
    apiSpec: APIs.blanks,
    useQueryProps: { enabled },
    queryVars: { searchParams: { packetId } },
  })
}
