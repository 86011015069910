export type StdDesc = {
  code: string
  desc: string
  id: string
}

export type FullStd = StdDesc & {
  bucket: 'APC' | 'CCSS' | 'Custom'
  cat: string
  catLevel: string
  subSet: string
  subTitle: string
}

/**
 * Raw from the db
 */
export type RawStd = {
  sub_id: number
  sub_title: string
  sub_set: string
  cat_id: number
  cat_title: string
  cat_hi: string
  cat_lo: string
  local_id: number
  parent_id: number | null
  id: string
  code: string
  desc: string
  seq: number
}
