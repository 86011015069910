import { Box, BoxProps, Text } from '@chakra-ui/react'

type TextStackProps = BoxProps

export const TextStack = (props: TextStackProps) => {
  return (
    <Box
      display="flex"
      flexDir="column"
      overflow="hidden"
      whiteSpace="nowrap"
      {...props}
    />
  )
}

const Top = (props: BoxProps) => {
  return (
    <Text
      as="span"
      isTruncated={true}
      maxW="100%"
      whiteSpace="nowrap"
      {...props}
    />
  )
}

TextStack.Top = Top

const Bottom = (
  props: BoxProps & {
    variant?: 'loose' | 'compact' | 'sardine'
  }
) => {
  const { variant = 'sardine', ...boxProps } = props
  let mt
  switch (variant) {
    case 'sardine':
      mt = '-4px'
      break
    case 'compact':
      mt = '-2px'
      break
    case 'loose':
      mt = 0
      break
  }

  return (
    <Text
      as="span"
      fontSize="xs"
      isTruncated={true}
      maxW="100%"
      mt={mt}
      whiteSpace="nowrap"
      {...boxProps}
    />
  )
}

TextStack.Bottom = Bottom
