import type { QResp, SatelliteAQ, SatelliteAssessment } from '@paper/schema'
import { UnionStringArray } from '@paper/utils'
import { produce } from 'immer'
import { times } from 'lodash'

const SAStubs = ['ok', 'doesntMatch', 'invalid', 'extraQ', 'empty'] as const
export type SAStub = UnionStringArray<typeof SAStubs>

export const stub_satelliteAssessment = (
  which: SAStub | number
): SatelliteAssessment[] => {
  // convert index to string value value
  if (typeof which === 'number') {
    which = SAStubs[which]
  }

  const title = `Dev.${which}`

  if (which === 'empty') {
    return []
  }

  let fakeIllId = 10000000

  type StubAQSpec = Partial<SatelliteAQ> & Pick<SatelliteAQ, 'type'>

  const stubAQs = (specs: StubAQSpec[]): SatelliteAQ[] => {
    return specs.map((partial, idx): SatelliteAQ => {
      const { type } = partial
      const labelNum = idx + 1
      let maxPts = partial.maxPts
      let bubbles = partial.bubbles
      let responses: QResp[]
      switch (type) {
        case 'MC':
          maxPts ??= 1
          bubbles ??= ['A', 'B', 'C', 'D']

          // choose from all combinations of bubbles, including multi-select
          let mask = ((idx % (2 ** bubbles.length - 1)) + 1)
            .toString(2)
            .padStart(bubbles.length, '0')
            .split('')
            .reverse()
          let filledStr = mask
            .map((b, idx) => (b === '1' ? bubbles[idx] : ''))
            .join('')

          responses ??= [{ filledStr, pts: maxPts }]
          break
        case 'OER':
          maxPts ??= 3
          responses ??= times(maxPts + 1, (pts) => ({
            filledStr: pts.toString(),
            pts,
          }))
          bubbles ??= responses
            .map(({ filledStr }) => filledStr)
            // illuminate limits bubbles, though probably not like this?
            .slice(-9)
          break
        case 'GRID':
          bubbles ??= ['A', 'B', 'C', 'D'] // illuminate returns nonsense bubbles for grid
          maxPts ??= 2
          const decStr = (dec: number) => {
            let str = dec.toString()
            // slice off leading 0 if exists
            let start = str.startsWith('0.') ? 1 : 0
            // limit to 4 characters
            return str.slice(start, start + 4)
          }
          responses ??= [`1/${labelNum}`, decStr(1 / labelNum)].map(
            (filledStr) => ({ filledStr, pts: maxPts })
          )
          break
      }

      return {
        _ill_field_id: 0,
        gridChoices: null, // todo: gridX fields aren't currently used by paper
        gridColumns: null,
        isAdvanced: false,
        isEC: false,
        isRubric: type === 'OER', // should match type
        label: `${labelNum}`,
        order: idx,
        stds: ['1', '2', '3'].map((suffix) => ({
          code: `fairly.long.standard.name.${suffix}`,
          id: suffix,
        })),
        ...partial,
        bubbles,
        maxPts,
        responses,
      }
    })
  }

  // valid for 2022
  let ny2022: SatelliteAssessment = {
    _ill_assessment_id: fakeIllId,
    instId: 'ny',
    questions: stubAQs([
      ...times(6, (i): StubAQSpec => ({ type: 'MC' })),
      ...times(2, (i): StubAQSpec => ({ type: 'GRID' })),
      ...times(4, (i): StubAQSpec => ({ type: 'OER' })),
    ]),
    syId: 2022,
    title,
  }

  let ny2023 = produce(ny2022, (draft) => {
    draft._ill_assessment_id += 1
    draft.syId = 2023

    if (which === 'invalid') {
      draft.questions.at(0).type = null
      draft.questions.at(1).label = '1'
    }
  })

  // match
  let nj2023 = produce(ny2023, (draft) => {
    draft.instId = 'nj'
  })

  let ma2023 = produce(ny2023, (draft) => {
    draft.instId = 'ma'
    if (which === 'doesntMatch') {
      // change response
      draft.questions[1].responses[0].filledStr = 'A'
      // change stds
      draft.questions[2].stds.pop()
      // change maxPts
      draft.questions[3].maxPts = 2
      draft.questions[3].responses[0].pts = 2
      // renumber
      let oerCounter = 0
      draft.questions.forEach((q) => {
        if (q.type === 'OER') {
          q.label = `A${1 + oerCounter++}`
        }
      })
      // retype
      let gridEx = draft.questions.find((p) => p.type === 'GRID')
      if (gridEx) {
        gridEx.type = 'MC'
      }
      // remove question
      draft.questions.pop()
    }
  })

  if (which === 'extraQ') {
    // remove question from first assessment
    ny2023 = produce(ny2023, (draft) => {
      draft.questions.pop()
    })
  }

  return [ny2023, nj2023, ma2023]
}
