import { Button, Icon } from '@chakra-ui/react'
import { IcoHome, IcoPacketEntry } from '@paper/icons'
import { useRouter } from '@paper/route'
import { BigIconFontSize } from '@paper/styles'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { Fragment, ReactNode } from 'react'
import { MenuBody, MenuItem, MenuSeparator, useMenu } from '~src/blocks/menu'
import { MISupport } from '~src/blocks/miSupport'
import { RD_Publish, Routes } from '~src/routelist'
import { LightMode } from '~src/utils/forceMode'
import { usePublishContext } from './publishProvider'

export function PublishMenu() {
  const { dispatchRoute } = useRouter<RD_Publish>()
  const { page } = usePublishContext()

  let { menuButtonProps, onOpenChange, open, shroud } = useMenu({
    caret: true,
    //defaultOpen: true,
    shroud: true,
  })

  let icon = IcoPacketEntry

  let destinations: ReactNode

  if (page === 'new') {
    destinations = (
      <Fragment>
        <MenuItem
          icon={<IcoHome />}
          onSelect={() => dispatchRoute(Routes.home_curriculum.mergeAction())}
        >
          Exit to curriculum home
        </MenuItem>
      </Fragment>
    )
  }

  return (
    <DropdownMenu.Root
      modal={false} // some sort of perf issue with modal...
      onOpenChange={onOpenChange}
      open={open}
    >
      <DropdownMenu.Trigger asChild>
        <Button
          data-cy="open-menu"
          {...menuButtonProps}
          flexShrink={0}
          px={1}
          size="lg"
        >
          <Icon as={icon} fontSize={BigIconFontSize} />
        </Button>
      </DropdownMenu.Trigger>
      {shroud}
      <DropdownMenu.Portal>
        <DropdownMenu.Content asChild align="start">
          <MenuBody width="272px">
            <LightMode>
              {destinations}
              {destinations && <MenuSeparator />}
              <MISupport />
            </LightMode>
          </MenuBody>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}
