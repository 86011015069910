import {
  DownloadRow,
  PrintManifest,
  PrintOptions,
  TeacherSection,
} from '@paper/schema'
import { defineApi } from '../defineApi'

export type GetPrintDownloadsProps = {
  expires: number
  packetGroupId: string
  schoolId: string
  syId: number
}

export const print_download = defineApi<{
  method: 'post'
  body: GetPrintDownloadsProps
  result: {
    downloads: DownloadRow[]
    expires: number
  }
}>({
  method: 'post',
  urlFactory: `download/pdflist`,
})

// todo: probably want to add this to DirPacket
// todo: maybe make manifest an array so we can display multiple
export type Downloadable = { downloadUrls: string[]; manifest: PrintManifest }

/**
 * note: This is a standalone api!
 */
export const print_generate = defineApi<{
  method: 'post'
  body: PrintOptions & { sections: TeacherSection[] }
  result: Downloadable
}>({
  app: 'print_generate',
  method: 'post',
  urlFactory: ``,
})
