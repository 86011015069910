import { DARK_MODE_FG } from '@paper/styles'
import { ReactNode } from 'react'
import { HStack, VSep, VStack } from '~src/components'
import { DarkMode } from '~src/utils/forceMode'
import { MenuDetailsSection } from '../menu/menuComponents'
import { SystemStatusMenuArea } from '../systemStatus'
import { CurriculumMenuModule } from './mmCurriculum'
import { NavMenu } from './mmLeftNav'
import { MenuModulePinGrid } from './mmPinGrid'
import { PublishMenuModule } from './mmPublish'
import { StudentWorkModule } from './mmStudentWork'

export type MainMenuProps = {
  keyboard?: ReactNode
  onClose?(): void
  pageIcon?: any
}

export function MainMenu(props: MainMenuProps) {
  return (
    <DarkMode>
      <VStack
        alignItems="stretch"
        bg="blackAlpha.800"
        color={DARK_MODE_FG}
        data-cy="menu2-container"
        overflow="auto"
        height="100vh"
      >
        <HStack
          alignItems="start"
          flexGrow={1}
          // so they all get the same height...
        >
          <NavMenu {...props} />
          <VSep />
          <VStack
            alignItems="stretch"
            flexGrow={1}
            overflow="hidden"
            pl={2}
            pb={4}
          >
            <StudentWorkModule keyboard={props.keyboard} />
            <CurriculumMenuModule />
            <PublishMenuModule />
            <MenuModulePinGrid keyboard={props.keyboard} />
            <MenuDetailsSection flexShrink={0} key="load" minHeight="110px">
              <SystemStatusMenuArea />
            </MenuDetailsSection>
          </VStack>
        </HStack>
      </VStack>
    </DarkMode>
  )
}
