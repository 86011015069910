import {
  Button,
  ButtonGroup,
  ButtonProps,
  Grid,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { IcoDownload, IcoPrint, IcoQRCode } from '@paper/icons'
import { getLastFirst } from '@paper/utils'
import { ReactNode, useState } from 'react'
import {
  Ex,
  HSep,
  HStack,
  ToggleGroup,
  Txt,
  VSep,
  VStack,
} from '~src/components'
import { useScrollIntoView } from '~src/utils/useScroll'
import { SupportButtons } from '../miSupport'
import { PdfBuilderPreprintable } from './data-pdfBuilder'
import { DownloadTableWrapper } from './pdfBuilderDownload'
import { PdfBuilderGrainPicker } from './pdfBuilderGrainPicker'
import { usePdfBuilderContext } from './pdfBuilderProvider'
import { PdfBuilderSidePanel } from './pdfBuilderSidePanel'
import { GenerateStatus } from './pdfBuilderStatus'

type PdfBuilderProps = {}

export function PdfBuilder(props: PdfBuilderProps) {
  const { isOpen, onClose, onOpen } = useDisclosure({ defaultIsOpen: false })
  const {
    grain,
    grainId,
    hasInflight,
    isDisabled,
    packetGroup,
    pdfList,
    setGrain,
    setGrainId,
  } = usePdfBuilderContext()

  const dontNavigateAway = hasInflight

  const [mode, setMode] = useState<'build' | 'download'>('build') // todo:
  // todo: this is a mess!!!
  const title = mode === 'build' ? 'Generate PDFs for printing' : 'Downloads'
  const togglerProps: ButtonProps =
    mode === 'build'
      ? {
          children: 'Download',
          leftIcon: <IcoDownload />,
          onClick: () => setMode('download'),
        }
      : {
          children: 'Back to PDF generation',
          leftIcon: <IcoQRCode />,
          onClick: () => setMode('build'),
        }

  const modeToggler = (
    <Button
      data-cy="btn-switch-pane"
      isDisabled={dontNavigateAway}
      ml="auto"
      variant="outline"
      {...togglerProps}
    />
  )

  let body: ReactNode

  if (mode === 'build') {
    body = (
      <VStack alignItems="stretch" gap={4} height="100%" overflow="hidden">
        <PdfBuilderGrainPicker
          isDisabled={dontNavigateAway}
          onChange={(value) => setGrain(value)}
          value={grain}
        />
        <HSep />
        <HStack alignItems="stretch" gap={8} overflow="hidden" pt={1}>
          <ToggleGroup.Root
            colorScheme="gray"
            onChange={(value) => setGrainId(value)}
            preventNone={true}
            type="single"
            value={grainId}
          >
            <Grid
              id="grid-assessment"
              flexGrow={1}
              gap={4}
              gridTemplateColumns="repeat(auto-fill, minmax(200px, 1fr))"
              gridAutoRows="min-content"
              overflowX="hidden"
              overflowY="auto"
              p={1} // for accessiblity outline...
              scrollPadding={4}
            >
              {pdfList.map((p) => (
                <AssessmentItem
                  key={p.grainId}
                  isSelected={p.grainId === grainId}
                  data={p}
                />
              ))}
            </Grid>
          </ToggleGroup.Root>
          <VSep />
          <PdfBuilderSidePanel />
        </HStack>
      </VStack>
    )
  } else {
    body = <DownloadTableWrapper />
  }

  const closeArea = (
    <HStack fontSize="xs" gap={4} ml="auto!important">
      {dontNavigateAway && (
        <Txt fontStyle="italic">
          Please remain on the page while PDFs are generating
        </Txt>
      )}
      <Button
        data-cy="modal-close"
        isDisabled={dontNavigateAway}
        onClick={onClose}
      >
        Close
      </Button>
    </HStack>
  )

  return (
    <>
      <Button
        data-cy="open-pdf-builder"
        leftIcon={<IcoPrint />}
        isDisabled={isDisabled}
        onClick={onOpen}
        size="sm"
        variant="outline"
      >
        Generate PDFs for printing
      </Button>
      <Modal
        closeOnEsc={false}
        closeOnOverlayClick={false}
        isCentered={true}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay
          // todo: copy/paste with form-dialog
          // todo: better way to do this? otherwise the dialog scrolls when large, which isn't what we want
          data-cy="modal-overlay"
          sx={{
            '.chakra-modal__content-container': { overflow: 'hidden' },
          }}
        >
          <ModalContent my={0} height="95vh" width="95vw" maxWidth="unset">
            <ModalHeader
              alignItems="center"
              display="flex"
              fontWeight={300}
              gap={2}
            >
              <Ex borderRadius="md" px={2}>
                {packetGroup.number}
              </Ex>{' '}
              {title}
              {modeToggler}
            </ModalHeader>
            <HSep boxProps={{ mx: 6, mb: 2 }} />
            <ModalBody overflow="hidden">{body}</ModalBody>
            <HSep boxProps={{ mt: 2 }} />
            <ModalFooter>
              <ButtonGroup flexGrow={1} spacing={2}>
                <SupportButtons />
                {closeArea}
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  )
}

type AssessmentItemProps = {
  data: PdfBuilderPreprintable
  isSelected: boolean
}

function AssessmentItem(props: AssessmentItemProps) {
  const { data, isSelected } = props

  const domRef = useScrollIntoView(isSelected, 'todo:figureouttheseoptions')

  return (
    <ToggleGroup.Button
      alignItems="stretch"
      data-cy="assessment-btn"
      data-status={data.status}
      flexShrink={0}
      height="auto"
      minHeight="56px"
      overflow="hidden"
      p={0}
      ref={domRef}
      value={data.grainId}
      whiteSpace="unset"
    >
      <GenerateStatus status={data.status} />
      <VStack
        flexGrow={1.5}
        fontSize="xs"
        justifyContent="space-evenly"
        px={2}
        textAlign="center"
      >
        <HStack py={1.5}>{data.curriculum.name}</HStack>
        <HSep boxProps={{ bg: 'gray.300' }} />
        <HStack fontFamily="mono" py={1.5}>
          {data.packet.name}
        </HStack>
        {data.grain === 'teacher' && (
          <>
            <HSep boxProps={{ bg: 'gray.300' }} />
            <HStack py={1.5}>{getLastFirst(data.teacher)}</HStack>
          </>
        )}
      </VStack>
    </ToggleGroup.Button>
  )
}
