import { Passage, Question } from '@paper/schema'
import { pareUndefined } from '@paper/utils'
import { makeOptions, parseLabel } from '@paper/utils/questionUtils'
import { times } from 'lodash'

type QSpec = Partial<Question> & { resps?: string[] }

export function stubPQs() {
  let { passages, passageIds } = stubPassages([
    {
      name: 'Passage Awesome: A study of how longer passage names render',
      genre: 'Narrative: Memoir/Non-Fiction',
      lexile: 'abc',
    },
    {
      name: `Surely you're joking`,
      genre: 'Narrative: Memoir/Non-Fiction',
      lexile: 'xyz',
    },
  ])

  let questions = stubQuestions(
    times(1, (i): QSpec[] => [
      {
        label: '1',
        labelMaj: '1',
        options: makeOptions(4, 'abc'),
        passageIds: passageIds.slice(0, 1),
        resps: ['AB'],
        type: 'MC',
      },
      {
        label: '2A',
        labelMaj: '2',
        labelMin: 'A',
        options: makeOptions(3, '012'),
        passageIds: passageIds,
        type: 'OER',
      },
      {
        label: '2B',
        labelMaj: '2',
        labelMin: 'B',
        options: makeOptions(3, '012'),
        passageIds: passageIds,
        type: 'OER',
      },
      {
        label: '3',
        labelMaj: '3',
        options: [],
        outOf: 2,
        passageIds: passageIds.slice(1),
        resps: ['2.5', '5/2'],
        type: 'GRID',
      },
    ]).flat(),
    passageIds
  )

  return { passages, questions }
}

function stubPassages(template: Partial<Passage>[]) {
  let passages = template.map((partial, idx) => {
    return {
      id: `stub-p-${idx}`,
      name: `Passage ${idx}`,
      ...partial,
    }
  })
  return { passages, passageIds: passages.map((p) => p.id) }
}

function stubQuestions(template: QSpec[], passageIds: string[]) {
  let lastLabel = '0'
  let questions = template.map((partial, idx): Question => {
    let type = partial.type ?? (idx >= 5 ? 'OER' : 'MC')
    let optionsLength = type === 'OER' ? 3 : 4
    let stubLabel = !partial.label && parseLabel(lastLabel, 'increment')
    let outOf = partial.outOf ?? 1
    let maxPts = partial.maxPts ?? partial.outOf ?? 1
    let responses =
      partial.responses ??
      partial.resps?.map((filledStr) => ({ filledStr, pts: maxPts })) ??
      (type === 'OER'
        ? times(maxPts - 1, (i) => ({ filledStr: `${i + 1}`, pts: i + 1 }))
        : [])

    let q: Question = pareUndefined({
      id: `stub-q-${idx}`,
      label: partial.label ?? stubLabel?.label,
      labelMaj: partial.labelMaj ?? stubLabel?.labelMaj,
      labelMin: partial.labelMin ?? stubLabel?.labelMin,
      maxPts,
      outOf,
      options:
        partial.options ??
        makeOptions(optionsLength, type === 'OER' ? '012' : 'abc'),
      passageIds,
      responses,
      stds: partial.stds ?? [],
      type,
    })
    lastLabel = q.label
    return q
  })
  return questions
}
