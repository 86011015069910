import { AnswerKey as Guts } from './answerKey'
import { CheckboxAnswerKey } from './checkboxKey'
import { AnswerKeyContainer } from './container'
export * from './chunk'
export * from './questionRow'
export * from './shell'

export const AnswerKey = {
  CheckboxGuts: CheckboxAnswerKey,
  Container: AnswerKeyContainer,
  Guts,
}
