import { useMemo } from 'react'
import { useStaticFn } from '~src/blocks/list/listCallbacks'
import { ScanXpacketColumn } from '~src/blocks/xpacketPageGraph'
import { Txt } from '~src/components'
import { useScanlogContext } from './scanlogAirlock'

type ScanlogXpacketListProps = {}

export function ScanlogXpacketList(props: ScanlogXpacketListProps) {
  const {
    scanFix: {
      candidateInputs,
      editState,
      selectedFixable,
      selectPage,
      targetXpageIdSet,
    },
    selectedBatch,
    selectedXpacketSet,
    xpacketSetDigest: digest,
  } = useScanlogContext()

  const onSelectPage = useStaticFn((xpageId: string) => {
    selectPage({ xpageId })
  })

  const xpageIdSet = useMemo(() => {
    return new Set(
      selectedBatch?.chunks
        .flatMap((c) =>
          c.items.flatMap((sh) => sh.flatMap((p) => p.data?.xpageId))
        )
        .filter((p) => p)
    )
  }, [selectedBatch])

  return (
    <ScanXpacketColumn
      canSelectPage={(
        page // don't allow assigning to a slot that already has an image
      ) => {
        let canSelect =
          // allow reselecting after selected from here
          (editState === 'waiting-for-input' ||
            (editState === 'wating-for-submit' && candidateInputs?.xpageId)) &&
          !page.imgp &&
          !page.fix?.key &&
          !page.movedIn?.key
        return canSelect
      }}
      digest={digest}
      empty={() => (
        <Txt fontSize="sm" mt={4} opacity={0.7} textAlign="center">
          Select a packet to see page-by-page status
        </Txt>
      )}
      highlightedXpageIdSet={xpageIdSet}
      onSelectPage={onSelectPage}
      packet={selectedXpacketSet?.packet}
      // todo: messy, but we want to reset when editing
      selectedXpageId={
        selectedFixable?.changeState === 'none' &&
        selectedFixable?.savedData?.xpageId
      }
      targetXpageId={targetXpageIdSet}
    />
  )
}
