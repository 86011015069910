import { APIs, BodyOf } from '@paper/api-specs'
import { useRouter } from '@paper/route'
import { CrunchBatch, ScanStatus, SheetChunk } from '@paper/schema'
import { useMemo } from 'react'
import { ListDigest, useListCallbacks } from '~src/blocks/list'
import { useUser } from '~src/blocks/userProvider'
import { laScanBatch } from '~src/data/listAdapters'
import { useApiMutation, useApiQuery } from '~src/data/useApiQuery'
import { SparseAxisLookup } from '~src/pages/pinGrid/pinGridAirlock'
import type { RD_SW_Scanlog } from '~src/routelist'
import { CandidateInputs } from './fix/scanFixContext'
import { useScanFixContext } from './scanlogAirlock'

export type ProblemChunk = SheetChunk & { batchId: string }

export type ScanBatchDigest = ListDigest<
  CrunchBatch,
  {
    isMore: boolean
    noMatches: boolean
    problems: {
      hasAny: boolean
      hasNext: boolean
      hasPrev: boolean
      onNext(): void
      onPrev(): void
    }
    scanDevices: string[]
  }
>

/**
 * Fetch scan batch list
 * @param jumpKeys Keys to determine what issues are jumped between via prev/next buttons
 */
export function useScanBatchList(jumpKeys: ScanStatus[]): ScanBatchDigest {
  const { year } = useUser()
  const { routeData } = useRouter<RD_SW_Scanlog>()

  // scanlog has separate packet and date modes that ignore some params
  const modeParams: Partial<BodyOf<'scanlog.batchList'>> = routeData.packetId
    ? { packetId: routeData.packetId, teacherId: routeData.teacherId }
    : {
        deviceName: routeData.deviceName,
        dir: routeData.dir,
        scanDate: routeData.scanDate,
        status: routeData.status,
      }

  const qResult = useApiQuery({
    apiSpec: APIs['scanlog.batchList'],
    queryVars: { body: { ...modeParams, syId: year.sw?.syId } },
    useQueryProps: {
      enabled: year.sw && !!(routeData.packetId || routeData.scanDate),
    },
  })

  // todo: allItems === items because no longer filtering batches by xpacket
  let allItems = qResult.data?.items
  let items = allItems
  let noMatches = allItems?.length && routeData.xpacketId && items.length === 0

  const listCallbacks = useListCallbacks(items, laScanBatch)

  const problems = useProblems(items, jumpKeys)

  return {
    adapter: laScanBatch,
    qResult,
    success: !qResult.isSuccess
      ? null
      : {
          ...listCallbacks,
          empty: allItems?.length === 0,
          items,
          otherData: {
            isMore: qResult.data.isMore,
            noMatches,
            problems,
            scanDevices: qResult.data.scanDevices,
          },
        },
  }
}

/**
 * Produces 'problem axis' to allow jumping between issues
 */
const useProblems = (items: CrunchBatch[], jumpKeys: ScanStatus[]) => {
  const { dispatchStay, routeData } = useRouter<RD_SW_Scanlog>()

  const toAddressKey = (rd: RD_SW_Scanlog) =>
    `${rd.sb_batchId}_${rd.si_imageId ?? null}`

  // todo: not sure this is the right approach! :)))
  const problemAxisStuff = useMemo(() => {
    const problemStatusSet = new Set(jumpKeys)
    // Put together a "problem axis" so it's easy to jump to pins
    // note: this is copy/pasted from pinGridAirlock!
    let problemAxis: RD_SW_Scanlog[] = []
    let problemAxisLookup: SparseAxisLookup = new Map()
    for (let y = 0; y < items?.length; y++) {
      const batch = items[y]

      // create an entry for each chunk
      for (let x = 0; x < batch.chunks.length; x++) {
        let candidate = batch.chunks[x]

        // extract first and last images from chunk
        const firstLast = [
          candidate.items.at(0)[0],
          candidate.items.at(-1).at(-1),
        ]
        if (firstLast[0] === firstLast[1]) {
          firstLast.pop()
        }

        // Add both sides of the chunk to the axis
        let problemAdded = false
        for (let si of firstLast) {
          let isProblem = problemStatusSet.has(si.status)

          const address = {
            sb_batchId: batch.id,
            si_imageId: si.id,
          }
          const addressKey = toAddressKey(address)
          let cursorAdj = isProblem ? -1 : 0
          // Only add one page from the sheet as a "stop" on the axis
          if (isProblem && !problemAdded) {
            problemAxis.push(address)
            problemAdded = true
          }
          // But need to add lookup entry for all items including skipped
          // note: not sure why i did it this way, but the index is where to look BEFORE going -1 or 1
          // add lookup entry for skipped items too
          problemAxisLookup.set(addressKey, {
            [-1]: problemAxis.length + cursorAdj, // next one if we're going backwards
            1: problemAxis.length - 1, // prev one if we're going forwards
          })
        }
      }
    }

    // console.log({ problemAxis, problemAxisLookup })

    return { problemAxis, problemAxisLookup }
  }, [items, jumpKeys])

  // todo: not sure this is the right approach! :))) (continued)
  const problems = useMemo(() => {
    let addressKey = toAddressKey(routeData)
    let axisIndices = problemAxisStuff.problemAxisLookup.get(addressKey) ?? {
      [-1]: null,
      [1]: -1,
    }

    let [prevItem, nextItem] = [-1, 1].map(
      (amt) => problemAxisStuff.problemAxis[axisIndices[amt] + amt]
    )

    return {
      hasAny: problemAxisStuff.problemAxis.length > 0,
      hasNext: !!nextItem,
      hasPrev: !!prevItem,
      onNext: () => dispatchStay(nextItem),
      onPrev: () => dispatchStay(prevItem),
    }
  }, [problemAxisStuff, routeData.sb_batchId, routeData.si_imageId])

  return problems
}

/**
 * Grabs details about a scanfix candidate
 */
export function useScanFixCandidateDetails(
  props: CandidateInputs,
  qrbPrefix: string
) {
  // todo: clean this up...
  const qrb = props?.qrbComplete
    ? qrbPrefix.replaceAll('-', '') + props.qrbComplete
    : undefined
  const xpageId = props?.xpageId

  return useApiQuery({
    apiSpec: APIs['scanlog.qrbLookup'],
    queryVars: { body: { qrb, xpageId } },
    useQueryProps: { enabled: !!(qrb || xpageId) },
  })
}

/**
 * Scanlog fix mutation...
 */
export function useScanFixMutation() {
  const { setChanges } = useScanFixContext()

  return useApiMutation({
    apiSpec: APIs['scanlog.xpageFix'],
    useMutationProps: {
      onSuccess() {
        // clear inputs
        setChanges('none')
      },
    },
  })
}
