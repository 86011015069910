import { APIs, ResultOf } from '@paper/api-specs'
import { useRouter } from '@paper/route'
import {
  PacketAxisItem,
  SectionAxisItem,
  Student,
  XpacketSW,
} from '@paper/schema'
import { addToMapSetDefault } from '@paper/utils'
import { orderBy, uniq } from 'lodash'
import { useMemo } from 'react'
import { useTeacherContext } from '~src/blocks/teacherAirlock'
import { usePacketListData } from '~src/data/data-packets'
import { useApiQuery } from '~src/data/useApiQuery'
import { RD_SW_Time } from '~src/routelist'

type PacketMaps = Map<
  string,
  {
    axis: PacketAxisItem
    stdPage: Map<string, Set<number>>
    stdQ: Map<string, Set<string>>
  }
>

export type TeaTimeBaseData = {
  empty: boolean
  packetAxis: PacketAxisItem[]
  packetMaps: PacketMaps
  packetNumberPrefixes: string[]
  sections: SectionAxisItem[]
  stds: string[]
  studentAxis: Student[]
  xpackets: XpacketSW[]
}

export const useTimeGridData = () => {
  const { curriculum, teacher } = useTeacherContext()
  const { routeData } = useRouter<RD_SW_Time>()
  const pld = usePacketListData()

  // Get packet ids with scans
  const { packetIds, packets } = useMemo(() => {
    // note: scan count isn't reliable here because xpackets can switch teachers
    const packets = pld.data //?.filter((p) => p.scan?.count)
    const packetIds = packets?.map((p) => p.id)
    return { packets, packetIds }
  }, [pld.data, routeData.f_packet])

  return useApiQuery({
    apiSpec: APIs['dir.listXpackets'],
    queryVars: {
      body: {
        curriculumId: curriculum.id,
        excludeMissing: true,
        teacherId: teacher.id,
        packetIds,
      },
    },
    queryFn: async ({ json, plainFetch }) => {
      // #353/#354 - Having issues with empty state
      // todo: I think maybe the easiest way to handle it is distinguishing between null packetIds (pending)
      // todo: and empty packetIds...no data
      // todo: it might be simpler to send the empty packetIds to the server, but i'll try handling it here

      let fetched: ResultOf<'dir.listXpackets'> = { sections: [], xpackets: [] }

      if (json.packetIds?.length) {
        fetched = await plainFetch()
      }

      let { sections, xpackets } = fetched

      // Make student axis
      let studentAxis = sections.flatMap((section) => section.students)
      studentAxis = orderBy(studentAxis, (sa) => sa.lastfirst.toLowerCase())

      // note: the list of packets with scans is not reliable due to packets switching teachers
      const packetSetWithScans = new Set(xpackets.map((p) => p.packetId))

      // And packet axis
      let packetAxis = orderBy(
        packets
          .filter((p) => packetSetWithScans.has(p.id))
          .map((p): PacketAxisItem => {
            return {
              date: p.scan?.date,
              id: p.id,
              name: p.name,
              number: p.number,
              pages: p.pages,
              questions: p.questions,
              stds: p.stds ?? [],
              type: p.type,
            }
          }),
        (pa) => pa.date
      )

      // list of all stds
      let stds = orderBy(
        uniq(packetAxis.flatMap((pa) => pa.stds.map((std) => std.code)))
      )
      // list of all packet number prefixes
      let packetNumberPrefixes = orderBy(
        uniq(packetAxis.map((pa) => pa.number.split('.')[0] + '.'))
      )

      // produce lookups
      const packetMaps: PacketMaps = new Map()
      packetAxis.forEach((pa) => {
        const stdQ = new Map<string, Set<string>>()
        const stdPage = new Map<string, Set<number>>()
        packetMaps.set(pa.id, { axis: pa, stdPage, stdQ })

        // question ids by std
        pa.questions.forEach(
          (q) =>
            q.stds?.forEach((std) => addToMapSetDefault(stdQ, std.code, q.id))
        )
        // pages by std
        pa.stds.forEach((std) => {
          const qSet = stdQ.get(std.code)
          if (qSet) {
            pa.pages.forEach((page, idx) => {
              if (page.items.find((item) => qSet.has(item.id))) {
                // todo: include passage pages here too?
                addToMapSetDefault(stdPage, std.code, idx)
              }
            })
          }
        })
      })

      // todo: paste on _outOf_
      xpackets.forEach((xp) => {
        packetMaps.get(xp.packetId)?.axis.questions.forEach((pmQ, qIdx) => {
          const xpQ = xp.qs?.[qIdx]
          if (xpQ) {
            xpQ._outOf_ = pmQ.outOf
          }
        })
      })

      return {
        empty: xpackets.length === 0,
        packetAxis,
        packetMaps,
        packetNumberPrefixes,
        sections,
        stds,
        studentAxis,
        xpackets,
      }
    },
    useQueryProps: {
      enabled: pld.qResult.isSuccess,
      // garbage collect immediately upon unsubscribing
      // note: was i worried about a bunch of large instances in the cache
      // or stale data?...if stale date, we should use the value for that
      gcTime: 0,
    },
  })
}
