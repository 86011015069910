import type { ReactElement, SVGAttributes } from 'react'
import { createElement, forwardRef, memo } from 'react'

export type IconType = (props: IconBaseProps) => JSX.Element

export interface IconTree {
  tag: string
  attr: { [key: string]: string }
  child?: IconTree[]
}

// todo: require tree or children
export interface IconBaseProps extends SVGAttributes<SVGElement> {
  tree?: IconTree
}

function Tree2Element(tree: IconTree[]): ReactElement<{}>[] {
  return tree?.map((node, i) =>
    createElement(node.tag, { key: i, ...node.attr }, Tree2Element(node.child))
  )
}

export const IconBase = forwardRef<SVGSVGElement, IconBaseProps>(
  function IconBase(props, ref) {
    const { children, tree, style, ...svgProps } = props
    // todo: support context?
    // todo: also why do we ignore svgProps.width and height?
    const computedSize = '1em'
    // The root of the tree contains viewBox info
    const { attr, child } = tree

    return (
      <svg
        fill="currentColor"
        ref={ref}
        stroke="currentColor"
        strokeWidth="0"
        height={computedSize}
        width={computedSize}
        // {...conf.attr}
        {...attr}
        {...svgProps}
        // className={className}
        // style={{
        //   color: props.color || conf.color,
        //   ...conf.style,
        //   ...props.style,
        // }}
        style={{ flexShrink: 0, ...style }} // todo:
        xmlns="http://www.w3.org/2000/svg"
      >
        {tree ? Tree2Element(child) : children}
      </svg>
    )
  }
)

/** memo(forwardRef) for icons */
export const mfr = (name: string, tree: IconTree): IconType => {
  const component = memo(
    forwardRef<SVGSVGElement, IconBaseProps>((props, ref) => {
      return <IconBase ref={ref} tree={tree} {...props} />
    })
  )
  component.displayName = name
  return component
}
