import type { CrunchBatch, FixitChange, FixitRosterItem } from '@paper/schema'
import { defineApi } from '../defineApi'

export const fixit_get = defineApi<{
  method: 'post'
  body: {
    curriculumId: string
    packetId: string
    teacherId: string
  }
  result: {
    batches: CrunchBatch[]
    roster: FixitRosterItem[]
  }
}>({
  invalidateKey: 'fixit',
  method: 'post',
  urlFactory: `fixit`,
})

export const fixit_update = defineApi<{
  method: 'post'
  body: FixitChange[]
  result: boolean
}>({
  invalidateOnMutationSuccess: ['fixit'],
  method: 'post',
  urlFactory: `fixit/submit`,
})
