import type { UseQueryResult } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useDeepMemo } from '~src/utils/useMemos'

export type Crunched<T, O = undefined> = {
  empty: boolean
  items: T[]
  otherData?: O
}

export type Cruncher<T, TKey extends object, O = undefined, TRaw = T[]> = (
  upstreamData: TRaw,
  crunchKey: TKey
) => Crunched<T, O>

export function useClientCrunch<
  T,
  TKey extends object,
  O = undefined,
  TRaw = T[],
>(
  qResult: UseQueryResult<TRaw>,
  crunchKey: TKey,
  cruncher: Cruncher<T, TKey, O, TRaw>
): Crunched<T, O> {
  // Extract upstreamData if success
  // Awkwardness is partially due to hooks needing to be unconditional
  const upstreamData = qResult.isSuccess && qResult.data

  // Memoize key
  // todo: Might be nice to reuse react-query method
  crunchKey = useDeepMemo(crunchKey)

  // Call `cruncher` with memo mojo
  const crunched = useMemo(() => {
    return !upstreamData ? undefined : cruncher(upstreamData, crunchKey)
  }, [upstreamData, crunchKey])

  return crunched
}
