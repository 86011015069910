import type { Curriculum } from '@paper/schema'
import { padToN0 } from './format'
import { slugify } from './slugify'
import { sortKeys } from './sortKeys'

/**
 * Gets `xpageId` from `xpacketId` and `index`
 */
export const toXpageId = (xpacketId: string, index: number) => {
  return `${xpacketId}.${padToN0(index, 2)}`
}

/**
 * Parses `xpageId` into `{ xpacketId, pageIndex }`
 */
export const parseXpageId = (xpageId: string) => {
  let [xpacketId, idxStr] = xpageId.split('.')
  return { xpacketId, pageIndex: parseInt(idxStr) }
}

/**
 * Gets scanImage id from `batchId` and `index`
 */
export const toScanImageId = (batchId: string, index: number) => {
  return `${batchId}.${padToN0(index, 3)}`
}

/**
 * Parses scanImage id into `{ batchId, index }`
 */
export const parseScanImageId = (xpageId: string) => {
  let [batchId, idxStr] = xpageId.split('.')
  return { batchId, batchIndex: parseInt(idxStr) }
}

/**
 * Formats curriculum name and deterministic id
 */
export const getC2NameAndId = (
  family: { ed: string; family: string },
  variant: string
) => {
  const name = [family.family, variant, family.ed].join(' ')
  const id = slugify([family.ed, family.family, variant].join(' '))

  return { id, name }
}

/**
 * Helper to construct `Curriculum` v2
 */
export const makeC2 = (
  proto: Omit<Curriculum, 'id' | 'name' | 'syId'>
): Curriculum => {
  // todo: maybe validate...at least strip unknown
  const syId = yearCodeToId(proto.ed)
  return sortKeys({
    ...proto,
    ...getC2NameAndId(proto, proto.variant),
    syId,
  })
}

export const YearCodeRegex = /^\d{2}-(\d{2})$/

/**
 * @example
 * yearCodeToId('22-23') // 2023
 * yearCodeToId('garbage') // throws
 */
export const yearCodeToId = (code: string) => {
  let match = YearCodeRegex.exec(code)
  if (!match) {
    throw Error(`code must be ##-##`)
  }
  return 2000 + parseInt(match[1])
}
