import { useRouter } from '@paper/route'
import { useCurriculumContext } from '~src/blocks/curriculumAirlock'
import { Routes } from '~src/routelist'
import { usePublishContext } from './publishProvider'

export function useGoToNew() {
  const { curriculum, curriculumRecord } = useCurriculumContext()
  const pubContext = usePublishContext()
  const canEdit = curriculumRecord?.canEdit
  const { dispatchRoute } = useRouter()

  const canNew = pubContext?.page !== 'new' && canEdit

  if (!canNew) {
    return null
  }

  const goToNew = () =>
    dispatchRoute(
      Routes.publish.navigateAction({
        contentIdOrNew: 'new',
        curriculumId: curriculum.id,
      })
    )

  return goToNew
}
