import type { Xpacket } from '@paper/schema'
import { defineApi } from '../defineApi'

/**
 * List students for movepages
 */
export const movepages_list_students = defineApi<{
  method: 'post'
  body: { xpacketId: string }
  result: Xpacket[]
}>({
  invalidateKey: 'move',
  method: 'post',
  urlFactory: `move-pages/students`,
})

export const movepages_move = defineApi<{
  method: 'post'
  body: {
    destXpacket: Xpacket
    pageIndexes: number[]
    srcXpacketId: string
  }
  result: {
    ok: boolean
    error?: string
    teacherId?: string
    studentId?: string
    xpacketId?: string
  }
}>({
  invalidateOnMutationSuccess: ['move', 'scanlog'],
  method: 'post',
  urlFactory: `move-pages/move`,
})
