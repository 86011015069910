import { APIs, BodyOf } from '@paper/api-specs'
import { useRouter } from '@paper/route'
import { BaseData } from '@paper/route/src/types'
import { Xpacket, XpacketSet } from '@paper/schema'
import { orderBy } from 'lodash'
import { useMemo, useState } from 'react'
import { ListAdapter, ListDigest, useListCallbacks } from '~src/blocks/list'
import {
  ListCallbacks,
  usePrevNext,
  useStaticFn,
} from '~src/blocks/list/listCallbacks'
import { useApiQuery } from '~src/data/useApiQuery'
import type { RD_SW_Base } from '~src/routelist'
import { checkDigestAirlock } from '~src/utils/airlock'
import { useFilters } from '~src/utils/useFilters'

export type ScanXpacketSetDigest = ListDigest<
  Xpacket,
  { selectedXpacketSet: XpacketSet; xpacketSets: XpacketSet[] }
>

const laScanXpacket: ListAdapter<Xpacket, RD_SW_Base> = {
  id: 'xpacketlist',
  idFromItem: (item) => item.id,
  idFromRouter: (routeData) => routeData.xpacketId,
  ItemComponent: null,
  itemName: 'scans',
  select: (xp) => ({ xpacketId: xp?.id, studentId: xp?.student?.id }),
}

type DigestConfig<T> = {
  process?(items: T[]): { empty: boolean; items: T[] }
  selectSrc?: 'state' | 'url'
}

/**
 * Shared by scanlog and move
 * todo: but this got super messy, so probably needs to be split!
 */
export const useScanXpacketSetsDigest = (
  packets: BodyOf<'scanlog.xpacketSets'>,
  current: { packetId?: string; teacherId?: string },
  config?: DigestConfig<Xpacket>
): ScanXpacketSetDigest => {
  const adapter = laScanXpacket

  const qResult = useApiQuery({
    apiSpec: APIs['scanlog.xpacketSets'],
    queryFn: async ({ plainFetch }) => {
      let result = await plainFetch()
      return result
    },
    queryVars: {
      body: packets,
    },
    useQueryProps: {
      enabled: !!(
        packets?.length > 0 &&
        packets[0]?.packetId &&
        packets[0].teacherId
      ),
      gcTime: 0, // remove from cache immediately when unmounted
    },
  })

  // sort
  let { empty, items, selectedXpacketSet } = useMemo(() => {
    let selectedXpacketSet = qResult.data?.find(
      (xpset) =>
        xpset.packet.id === current.packetId &&
        xpset.teacher.id === current.teacherId
    )
    let items = selectedXpacketSet?.xpackets ?? []
    let empty = !items?.length

    // process if requested
    if (config?.process && items) {
      ;({ empty, items } = config.process?.(items))
    }

    // sort
    items = sortXpacketsForScanXpacketList(items)

    return { empty, items, selectedXpacketSet }
  }, [qResult.data, current.packetId, current.teacherId])

  ////////////////////////////
  // Selected state and callbacks
  ////////////////////////////
  const listCallbacksHook =
    config?.selectSrc === 'state' ? useLocalListCallbacks : useListCallbacks
  const listCallbacks = listCallbacksHook(items, adapter)

  let digest: ScanXpacketSetDigest = {
    adapter,
    qResult,
    success: !qResult.isSuccess
      ? null
      : {
          empty,
          items,
          ...listCallbacks,
          otherData: { selectedXpacketSet, xpacketSets: qResult.data },
        },
  }

  // airlock todo: probably move this...
  if (config?.selectSrc !== 'state') {
    const { useAirlock } = useRouter<RD_SW_Base>()
    useAirlock({ studentId: null, xpacketId: null }, checkDigestAirlock(digest))
  }

  return digest
}

interface UseListCallbacks {
  <T, RD extends BaseData = BaseData>(
    items: T[],
    adapter: ListAdapter<T, RD>
  ): ListCallbacks<T>
}
/**
 * todo: copy/pasted from useListCallbacks...
 */
export const useLocalListCallbacks: UseListCallbacks = <T, RD>(
  items: T[],
  adapter: ListAdapter<T, RD>
) => {
  const [localData, setLocalData] = useState<RD>({} as RD)

  const selectedId = adapter.idFromRouter(localData)
  const selectedIndex = items?.findIndex(
    (itm) => adapter.idFromItem(itm) === selectedId
  )
  const selectedItem = items?.[selectedIndex]
  const onSelect = useStaticFn((item: T) => {
    setLocalData((old) => ({ ...old, ...adapter.select(item) }))
  })
  const [onPrev, onNext] = usePrevNext(items, selectedIndex, onSelect)

  return { onNext, onPrev, onSelect, selectedId, selectedIndex, selectedItem }
}

const sortXpacketsForScanXpacketList = (items: Xpacket[]) =>
  orderBy(items, [
    (p) =>
      p.status === 'partial'
        ? 0
        : p.status === 'missing'
        ? 1
        : p._overriden
        ? 2
        : Infinity,
    (p) => p.student?.lastfirst,
  ])

// todo: copy/paste with useScanXpacketDigest
export const useMoveDestDigest = (xpacketId: string, nameFilter: string) => {
  const adapter = laScanXpacket

  const qResult = useApiQuery({
    apiSpec: APIs['movepages.studentList'],
    queryVars: { body: { xpacketId } },
    queryFn: async ({ plainFetch }) => {
      let xpackets = await plainFetch()
      xpackets = sortXpacketsForScanXpacketList(xpackets)
      return xpackets
    },
    useQueryProps: { enabled: !!xpacketId },
  })

  let items = qResult.data

  // filter by name
  const filters = useFilters()
  items = useMemo(() => {
    return !items || !nameFilter
      ? []
      : items.filter((p) =>
          filters.startsWithByWord(p.student.lastfirst, nameFilter)
        )
  }, [items, nameFilter])

  const empty = !items?.length

  const listCallbacks = useLocalListCallbacks(items, adapter)

  let digest: ScanXpacketSetDigest = {
    adapter,
    qResult,
    success: !qResult.isSuccess ? null : { empty, items, ...listCallbacks },
  }

  return digest
}
