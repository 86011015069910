// NOTE: shallow-equal library has a bug around objects with the same number of keys
// Taking this one from facebook
// https://github.com/facebook/fbjs/blob/master/packages/fbjs/src/core/shallowEqual.js

const hasOwnProperty = Object.prototype.hasOwnProperty

export function shallowEqual<A extends {}, B extends {}>(
  objA: A,
  objB: B
): boolean {
  if (Object.is(objA, objB)) {
    return true
  }

  if (
    typeof objA !== 'object' ||
    objA === null ||
    typeof objB !== 'object' ||
    objB === null
  ) {
    return false
  }

  const keysA = Object.keys(objA)
  const keysB = Object.keys(objB)

  if (keysA.length !== keysB.length) {
    return false
  }

  // Test for A's keys different from B.
  for (let i = 0; i < keysA.length; i++) {
    if (
      !hasOwnProperty.call(objB, keysA[i]) ||
      !Object.is(objA[keysA[i]], objB[keysA[i]])
    ) {
      return false
    }
  }

  return true
}
