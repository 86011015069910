import { APIs } from '@paper/api-specs'
import { useApiQuery } from '~src/data/useApiQuery'
import config from './config'

const OneHourInMs = 1000 * 60 * 60

export type LocationKey = { key: string }

/**
 * @deprecated Use `useSignedWildcardMapper` instead
 */
export const useSignedUrls = (locations: LocationKey[]) => {
  // note: domain added to content-security-policy in index.html
  // loc.key is null when no scan is present
  const slots = locations.map(
    (loc) => loc.key && `${config.cdnOrigin}/${loc.key}`
  )
  // Filter out nulls since we don't need to sign them
  const unsigneds = slots.filter((key) => !!key)
  const hasImages = unsigneds.length > 0

  // todo: Similar code in data-packetEntry for the pdf

  return useApiQuery({
    apiSpec: APIs.signurls,
    queryVars: { body: unsigneds },
    queryFn: async ({ plainFetch }) => {
      const signeds = await plainFetch()
      // include all slots
      return slots.map((url) => signeds[url])
    },
    useQueryProps: {
      enabled: hasImages,
      staleTime: OneHourInMs,
    },
  })
}

/**
 * Returns a QueryResult with a function that appends the signature to a url
 * todo: It currently signs any url under /pages/ or /img-entry/
 */
export const useSignedWildcardMapper = (example: string) => {
  const payload = example && [getWildcard(example)]

  const result = useApiQuery({
    apiSpec: APIs.signurls,
    queryVars: { body: payload },
    queryFn: async ({ json, plainFetch }) => {
      const signeds = await plainFetch()
      // return signature
      return signeds[json[0]].split('?')[1]
    },
    useQueryProps: {
      enabled: !!example,
      staleTime: OneHourInMs,
    },
  })

  const sig = result.isSuccess && result.data
  const data = !sig ? undefined : (url: string) => (url ? `${url}?${sig}` : url)

  return { ...result, data }
}

const getWildcard =
  // #142 - complicating things is that the blank images are under a different path
  (url: string) => {
    const paths = ['/pages/', '/img-entry/']
    for (let path of paths) {
      if (url.includes(path)) {
        return url.split(path)[0] + `${path}*`
      }
    }
    // if we made it here, there was no match
    // todo: rollbar?
    console.warn(`Unhandled wildcard url: ${url}`)
  }
