import { BoxProps } from '@chakra-ui/react'
import { Children, createContext, ReactNode, useContext } from 'react'
import { useShallowMemo } from '~src/utils/useMemos'
import { Separator } from '.'
import { HStack, StackProps, VStack } from './stack'

type ColumnContext = BoxProps
const ColumnContext = createContext(undefined)
export const useColumnContext = () => useContext(ColumnContext)

export function Column(props: StackProps) {
  const contextProps = useColumnContext()
  return <VStack as="section" {...contextProps} {...props} />
}

type ContainerProps = { colProps: ColumnContext } & StackProps
const Container = (props: ContainerProps) => {
  let { children, colProps, ...boxProps } = props
  children = spliceChildren(children, (index) => (
    <Separator
      key={index}
      style={{ flexShrink: 0, marginBlock: '1.5rem', zIndex: -1 }}
      orientation="vertical"
    />
  ))

  const context: ColumnContext = useShallowMemo(colProps)

  return (
    <HStack
      alignItems="stretch"
      maxWidth="100%"
      overflowX="auto"
      overflowY="hidden"
      {...boxProps}
    >
      <ColumnContext.Provider value={context}>
        {children}
      </ColumnContext.Provider>
    </HStack>
  )
}

Column.Container = Container

/**
 * Splices in result `spliceFactory` in between each child
 * todo: doesn't handle <Fragment /> correctly!
 */
export function spliceChildren(
  children: ReactNode,
  spliceFactory: (index: number) => ReactNode
) {
  const childArr = Children.toArray(children) as ReactNode[]
  const childCount = childArr.length
  // Insert spacers
  for (let i = 0; i < childCount - 1; i++) {
    const index = 2 * i + 1
    childArr.splice(index, 0, spliceFactory(index))
  }

  return childArr
}
