export type C2Status = 'active' | 'retired' | 'recalled'

/**
 * Curriculum
 */
export type Curriculum = {
  /**
   * * `draft` pre-enabled
   * * `active` if subscribable
   * * `retired` if not subscribable
   * * `recalled` curriculum analog of recalling a packet
   */
  _status: C2Status
  /** edition: e.g. `v1`, `23-24`, `2023` (immutable) */
  ed: string
  /** course or module name */
  family: string
  /** `ed-family-variant` (immutable) **/
  id: string
  /** e.g. 6 */
  levels: string[]
  /** `[family] [variant] [ed]` (immutable) */
  name: string
  /** e.g. `Math` */
  subject: string
  /** school year */
  syId: number
  /** e.g. `MA`, `NJ`, `NY` */
  variant: string
}

export type CurriculumVariant = {
  desc: string
  name: string
}

export type School = {
  id: string
  name: string
}

/** minimal section, often all we need is name+sectionId */
export type Sect = Pick<Section, 'name' | 'id'>

export type Section = {
  course: string
  curriculumId: string
  name: string
  schoolId: string
  schoolName: string
  id: string
  teacher: Teacher
  teacherIds: string[]
  term: Term
}

export type Student = {
  grade: string
  id: string
  firstName: string
  lastfirst: string
  lastName: string
  number: string
}

export type Teacher = {
  email: string
  id: string
  firstName: string
  lastName: string
}

export type Term = {
  code: string
  firstDay: Date
  lastDay: Date
  /** School Year id; 23-24 => `syId === 2024` */
  syId: number
}

export type SchoolYear = {
  code: string
  firstDay: string
  /** Current year for 'packet entry' */
  isPEYear: boolean
  /** Current year for 'student work' */
  isSWYear: boolean
  lastDay: string
  syId: number
}
