import { Box, BoxProps } from '@chakra-ui/react'
import { gray75 } from '@paper/styles'
import { useDumbHeightForCSSAnimation } from '~src/utils/useAnimation'
import { useScrollToSelected } from '~src/utils/useScroll'

export type ItemProps<T> = {
  data: T
  isSelected: boolean
  onSelect(item: T): void
}

// TODO: Not quite compose-y
type ListItemProps<T> = {
  data: T
  heightMs?: number
  isSelected: boolean
  onSelect(item: T): void
  unexpandedHeight: string
} & Omit<BoxProps, 'onSelect'>

export function ListItem<T>(props: ListItemProps<T>) {
  const {
    children,
    data,
    heightMs = 0,
    isSelected,
    onSelect,
    unexpandedHeight,
    ...rest
  } = props

  const { height, rootRef } = useDumbHeightForCSSAnimation(
    unexpandedHeight,
    isSelected
  )

  // TODO: Gucky workaround so we wait to scroll until element has its height
  useScrollToSelected(rootRef, isSelected, heightMs * 1.25)

  let bg = isSelected ? gray75 : null
  let hoverBg: BoxProps['bg'] = bg ?? 'gray.50'

  return (
    <Box
      bg={bg}
      cursor="pointer"
      display="flex"
      flexDir="column"
      flexShrink={0}
      height={height}
      onClick={() => onSelect(data)}
      overflow="hidden"
      pos="relative"
      px={2}
      py={2}
      // @ts-expect-error
      ref={rootRef}
      transition={`all .25s, height ${heightMs}ms`}
      _hover={{ bg: hoverBg }}
      {...rest}
    >
      {children}
    </Box>
  )
}
