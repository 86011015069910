export { default as slugify } from '@sindresorhus/slugify'

/**
 * Formats QR backup string
 */
export const formatQRBackup = (qrb: string) => {
  return qrb && [qrb.slice(0, 2), qrb.slice(2, 4), qrb.slice(4)].join('-')
}

/**
 * Formats QR backup string
 */
export const formatQRBackupExperimental = (qrb: string) => {
  return qrb && [qrb.slice(0, 2), qrb.slice(2, 7), qrb.slice(7)].join('-')
}
