import { Box, BoxProps, keyframes } from '@chakra-ui/react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { createContext, forwardRef, ReactNode, useContext } from 'react'
import {
  Separator,
  spliceChildren,
  StackProps,
  Txt,
  VStack,
} from '~src/components'
import { MIPX } from '.'

const scaleAndFade = keyframes({
  '0%': { opacity: 0, transform: 'scale(.8)' },
  '100%': { opacity: 1, transform: 'scale(1)' },
})

const MenuBodyContext = createContext<StackProps>({ py: 0 })
export const MenuAnimationLength = '150ms'

type MenuBodyProps = BoxProps & { variant?: 'default' | 'snug' }

export const MenuBody = forwardRef<HTMLDivElement, MenuBodyProps>(
  function MenuBody(props, ref) {
    const { children, sx = {}, transformOrigin, variant, ...boxProps } = props
    return (
      <Box
        bg="white"
        borderRadius={4}
        // disappearing boxShadow may be related to focusVisible library?
        boxShadow="rgb(6 22 33 / 20%) 0px 2px 6px !important"
        overflow="hidden"
        py={2}
        ref={ref}
        sx={{
          ...sx, // todo: merge properly...
          '@media (prefers-reduced-motion: no-preference)': {
            willChange: 'transform, opacity',
            '&[data-state="open"]': {
              // chakra/emotion apparently don't parse animationName
              animation: `${scaleAndFade} ${MenuAnimationLength} ease-in-out`,
            },
            transformOrigin: transformOrigin ?? 'top left', // todo: should be based on align/side/rtl
          },
        }}
        {...boxProps}
      >
        <MenuBodyContext.Provider value={{ py: variant === 'snug' ? 2 : 4 }}>
          {children}
        </MenuBodyContext.Provider>
      </Box>
    )
  }
)

type MenuSectionProps = { children: ReactNode; heading?: string } & StackProps

export function MenuDetailsSection(props: MenuSectionProps) {
  const { children, heading, ...stackProps } = props
  const ctxProps = useContext(MenuBodyContext)

  return (
    <VStack
      {...ctxProps}
      alignItems="flex-start"
      gap={2}
      px={MIPX}
      {...stackProps}
    >
      {heading && <MenuHeading>{heading}</MenuHeading>}
      {children}
    </VStack>
  )
}

function MenuHeading(props: BoxProps) {
  return (
    <Txt
      as="div"
      fontSize="xs"
      // ml={-1}
      // textTransform="uppercase"
      userSelect="none"
      {...props}
    />
  )
}

type MenuDetailPaneProps = {
  children: ReactNode
  separator?: boolean
} & StackProps

export function MenuDetailPane(props: MenuDetailPaneProps) {
  let { children, separator, ...rest } = props

  if (separator) {
    children = spliceChildren(children, (index) => (
      <Separator key={index} style={{ marginInline: '.5rem' }} />
    ))
  }

  return (
    <VStack alignItems="stretch" overflow="hidden" {...rest}>
      {children}
    </VStack>
  )
}

export function MenuSeparator() {
  return (
    <DropdownMenu.Separator asChild>
      <Box
        alignSelf="stretch"
        as="hr" // the base stylesheet magically adds border-top to "hr"
        mx={1}
        my={2}
        opacity={0.75}
      />
    </DropdownMenu.Separator>
  )
}

/**
 * Absolute position workaround so scroll content doesn't affect menu grid height
 */
export function MenuScrollContainer({ children }) {
  return (
    <Box display="flex" flexGrow={1} position="relative">
      <Box
        display="flex"
        height="100%"
        overflow="hidden"
        position="absolute"
        width="100%"
      >
        {children}
      </Box>
    </Box>
  )
}

// workarounds for a menu that looks decent against dark backdrop
export const DarkModeMenuStyle = {
  bg: '#333',
  color: 'rgba(255,255,255,.9)',
}

export const LighterDarkModeMenuStyle = {
  bg: '#5a5a5a',
  color: 'rgba(255,255,255,.9)',
}
