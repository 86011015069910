import { HStack, Icon, Tag } from '@chakra-ui/react'
import { forwardRef, ReactNode } from 'react'
import { ColorScheme } from './monsterAdapters'

type DirListItemProps = {
  children: ReactNode
  colorScheme: ColorScheme
  icon: any
}

export const DirListItem = forwardRef<HTMLDivElement, DirListItemProps>(
  function DirListItem(props, ref) {
    let { children, colorScheme, icon } = props

    // todo: not sure why, but strings are getting eaten
    if (typeof children === 'string') {
      children = <>{children}</>
    }

    return (
      <HStack
        data-cy="monster-li"
        gap={2}
        ref={ref}
        // todo: this doesn't quite solve the whole problem because this doesn't cover the whole item
        // todo: also, we need to keep track of this click when there's only one action
        onClick={(event) => event.stopPropagation()}
        width="100%"
      >
        {icon && (
          <Tag
            colorScheme={colorScheme}
            justifyContent="center"
            px={0}
            size="sm"
            variant="solid"
          >
            <Icon as={icon} />
          </Tag>
        )}
        {children}
      </HStack>
    )
  }
)
