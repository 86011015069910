import { IconButton, IconButtonProps, ListItem } from '@chakra-ui/react'
import { ReactNode } from 'react'

type ButtonedListItemProps = { children: ReactNode } & IconButtonProps
export function ButtonedListItem(props: ButtonedListItemProps) {
  const { children, ...iconButtonProps } = props
  return (
    <ListItem alignItems="center" display="flex" gap={2}>
      <IconButton size="xs" {...iconButtonProps} />
      {children}
    </ListItem>
  )
}
