import { TeacherSection } from '@paper/schema'
import { defineApi } from '../defineApi'

export const roster_get = defineApi<{
  method: 'post'
  body: {
    curriculumId: string
    schoolId: string
    teacherId: string
  }
  // this may need to change if we add non-teacher-centric views
  result: TeacherSection[]
}>({
  method: 'post',
  urlFactory: `roster/get`,
})
