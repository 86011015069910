/**
 * todo: Need to reconcile this with my (yet-to-exist) api error handling
 * e.g. an api can return 404 too.
 */
export class NotFoundError extends Error {
  friendly?: string
  thing: string
  value: string

  constructor(props: { friendly?: string; thing: string; value: string }) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(`Not found: ${props.thing} -> ${props.value}`)

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NotFoundError)
    }

    this.name = '404Error'
    this.friendly = props.friendly
    this.thing = props.thing
    this.value = props.value
  }
}
