type Unlisten = () => void

export function listenWindow<K extends keyof WindowEventMap>(
  type: K,
  listener: (this: Window, ev: WindowEventMap[K]) => any,
  options?: boolean | AddEventListenerOptions
): Unlisten {
  window.addEventListener(type, listener, options)
  return () => window.removeEventListener(type, listener)
}
