import { PQPacketmeta } from '@paper/schema'
import { defineApi } from '../defineApi'

export const external_pq = defineApi<{
  method: 'get'
  searchParams: { limit?: number; time: number }
  result: {
    hasMore: boolean
    packetmetas: PQPacketmeta[]
  }
}>({
  method: 'get',
  urlFactory: `unstable-expect-this-to-break/packetmeta/v1`,
})
