import { Fragment, ReactNode } from 'react'
import { Helmet } from 'react-helmet-async'
import { makeTitle } from '~src/utils/messages'

type AppTitleProps = { children?: ReactNode; title: string | string[] }

export function AppTitle(props: AppTitleProps) {
  let { children, title } = props
  let segments = (typeof title === 'string' ? [title] : title ?? []).filter(
    (p) => p
  )
  return (
    <Fragment>
      <Helmet>
        <title>{makeTitle(...segments)}</title>
      </Helmet>
      {children}
    </Fragment>
  )
}
