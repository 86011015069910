import { BoxProps } from '@chakra-ui/layout'
import { UseQueryResult } from '@tanstack/react-query'
import { Fragment, ReactNode } from 'react'
import {
  Error404Page,
  ErrorPrintRecalledPage,
  NotFoundError,
  PrintRecalledError,
} from '~src/blocks/errors'
import { FetchErrorPage } from '~src/blocks/errors/errorPageFetch'
import { Txt, VStack } from '..'
import { FullPageMessage } from './fullPage'

type FullPageLoadingProps = {
  children?: ReactNode | (() => ReactNode)
  loadMsg?: ReactNode
  qResult: {
    error?: unknown
    fetchStatus?: UseQueryResult['fetchStatus']
    isError?: boolean
    isPending: boolean
    reset?: () => void
  }
  type?: 'opaque' | 'transparent'
}

/**
 * @deprecated
 * todo: Need to overhaul to support disabled queries properly (status vs. fetchStatus)
 * Also should specify explicit behavior for disable queries
 * The grids are currently taking advantage of the fact that `isLoading` is true for disabled queries and not passing the `fetchStatus`
 */
export function FullPageLoading(props: FullPageLoadingProps) {
  const { error, fetchStatus, isError, isPending } = props.qResult
  const { loadMsg, type = 'opaque' } = props

  // todo: need to survey other FullPageLoading usages on whether to always show the error on fetch
  // todo: or i guess maybe make this opt-in for everyone, in case there's a submit that shouldn't be blindly retried?
  const showError = error && !!props.qResult.reset

  // todo: still not quite right...see deprecation notice
  const isActuallyLoading =
    isPending && (!fetchStatus || fetchStatus !== 'idle')

  const isActive = showError || isActuallyLoading

  const renderChildren =
    !isError && (!isActuallyLoading || type === 'transparent')

  let msg: ReactNode

  if (error instanceof NotFoundError) {
    msg = <Error404Page error={error} />
  } else if (error instanceof PrintRecalledError) {
    msg = <ErrorPrintRecalledPage error={error} />
  } else if (showError) {
    msg = <FetchErrorPage {...props.qResult} />
  } else if (isActuallyLoading) {
    msg = (
      <FullPageMessage isActive={isActive} type={type}>
        <VStack justifyContent="flex-end" height="50%">
          {loadMsg}
          <LoadingDots data-cy="full-page-loading" />
        </VStack>
      </FullPageMessage>
    )
  }

  return (
    <Fragment>
      {msg}
      {renderChildren
        ? typeof props.children === 'function'
          ? props.children()
          : props.children
        : null}
    </Fragment>
  )
}

export const LOADING_DOTS = `·  ·  ·`

export const LoadingDots = (props: BoxProps) => {
  return (
    <Txt
      as="span"
      color="gray.200"
      data-cy="loading-dots"
      fontSize="6xl"
      fontWeight="bold"
      role="presentation"
      textAlign="center"
      userSelect="none"
      {...props}
    >
      {LOADING_DOTS}
    </Txt>
  )
}
