import {
  Box,
  Button,
  ButtonGroup,
  FormHelperText,
  Heading,
  Icon,
  List,
  ListItem,
  ModalBody,
  ModalContent,
  ModalFooter,
} from '@chakra-ui/react'
import { IcoCheckmark } from '@paper/icons'
import { useRouter } from '@paper/route'
import { CurriculumVariant } from '@paper/schema'
import { Form, useFormikContext } from 'formik'
import { orderBy } from 'lodash'
import { ReactNode } from 'react'
import { Ex, Separator, Txt } from '~src/components'
import { RD_Setup_Curriculum } from '~src/routelist'
import { FieldUI, SimpleTextField2 } from '../../publish/formHelpers'
import { C2FamilyEd, C2NewEd, useVariantData } from './data-curriculumSetup'

type CurriculumFormProps = {
  immutable?: boolean
  title: ReactNode
  variantList: ReactNode
}

export function CurriculumForm(props: CurriculumFormProps) {
  const { immutable, title, variantList } = props
  const { routeData } = useRouter<RD_Setup_Curriculum>()

  const { dirty, isSubmitting, isValid, setFieldValue, values } =
    useFormikContext<C2FamilyEd>()

  const edHelp = (
    <>
      School year, e.g. <Ex mr={2}>{routeData.ed}</Ex>
    </>
    // <>
    //   Single-use: <Ex mr={2}>{formatSchoolYear(year)}</Ex>
    //   Open-ended: <Ex>{year}</Ex> or <Ex>v1</Ex>
    // </>
  )

  return (
    <ModalContent as={Form} borderRadius={4} maxW="1000px">
      <ModalBody overflow="hidden" pt={6}>
        <Heading
          as="h3"
          fontSize="xl"
          fontWeight={300}
          mb={8}
          userSelect="none"
        >
          {title}
        </Heading>
        <Box
          display="grid"
          gridColumnGap={6}
          gridTemplateColumns="2fr auto 3fr"
        >
          <Box>
            <SimpleTextField2
              helperText={edHelp}
              label="edition"
              name="ed"
              inputProps={{ 'data-cy': 'c2-ed', isDisabled: immutable }}
            />
            <SimpleTextField2
              label="family"
              name="family"
              inputProps={{ 'data-cy': 'c2-family', isDisabled: immutable }}
            />
            <SimpleTextField2
              label="subject"
              name="subject"
              inputProps={{ 'data-cy': 'c2-subject' }}
            />
            <SimpleTextField2
              helperText={
                <>
                  Use commas to specify multiple levels, e.g. <Ex>5,6</Ex>
                </>
              }
              label="levels"
              name="levels"
              inputProps={{
                'data-cy': 'c2-levels',
                onChange: (event) => {
                  let str = event.target.value.trim()
                  setFieldValue(
                    'levels',
                    // todo: formik was resetting errors if i set this to []
                    // todo: this somehow works around that
                    str ? str.split(',').map((str) => str.trim()) : null
                  )
                },
                value: values.levels?.join(',') ?? '',
              }}
            />
          </Box>
          <Separator orientation="vertical" />
          {variantList}
        </Box>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup gap={1} size="sm">
          <Button type="reset" variant="outline">
            Cancel
          </Button>
          <Button
            data-cy="c2-submit"
            colorScheme="red"
            isDisabled={!dirty || isSubmitting || !isValid}
            type="submit"
          >
            Submit
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </ModalContent>
  )
}

export type VariantItem = {
  curriculum: C2NewEd
  idx: number
  variant: CurriculumVariant
}
type VariantListProps = {
  curricula: C2NewEd[]
  directions: { existing?: ReactNode; new?: ReactNode }
  renderItem: (item: VariantItem) => ReactNode
}

export function VariantList(props: VariantListProps) {
  const { curricula, directions, renderItem } = props

  const qResult = useVariantData()

  // todo: temporary!
  if (!qResult.data) {
    return null
  }

  const usedMap = new Map(
    curricula.map((c, idx) => [c.variant, { curriculum: c, idx }])
  )

  // List all variants
  let items = qResult.data?.map((variant) => ({
    ...usedMap.get(variant.name),
    variant,
  }))
  // With those actually selected at the top
  items = orderBy(items, (p) => p.curriculum?.id)

  // Render items
  let renderedItems: ReactNode[] = items.map((item) => (
    <ListItem
      key={item.variant.name}
      alignItems="center"
      display="flex"
      fontSize="sm"
      gap={3}
    >
      {renderItem(item)}
    </ListItem>
  ))

  // Inject directions in between
  let dirIdx = items.findIndex((p) => !p.curriculum?.id)
  renderedItems.splice(
    dirIdx,
    0,
    <VariantListDirections
      key="**directions**"
      newSlot={directions.new}
      existingSlot={directions.existing}
    />
  )

  return (
    <Box>
      {qResult.data && (
        <FieldUI
          label="Variants"
          input={
            <List mb={4} spacing={4}>
              {renderedItems}
            </List>
          }
        />
      )}
    </Box>
  )
}

type VariantListDirectionsProps = {
  existingSlot?: ReactNode
  newSlot: ReactNode
}

export function VariantListDirections(props: VariantListDirectionsProps) {
  const { existingSlot, newSlot } = props
  return (
    <FormHelperText mt={0}>
      {existingSlot}
      {existingSlot && <Separator style={{ marginBlock: '1rem' }} />}
      {newSlot}
    </FormHelperText>
  )
}

type VariantListStatusProps = { error?: ReactNode }

/**
 * @deprecated todo: Need to wire this up to form meta and aria properly
 */
export function VariantListStatus(props: VariantListStatusProps) {
  return (
    <Txt as="span" color="red.500" fontWeight={400} ml={1.5}>
      {props.error || (
        <Icon
          aria-label="Completed"
          as={IcoCheckmark}
          color="green.500"
          fontSize="lg"
          mb={-1}
        />
      )}
    </Txt>
  )
}
