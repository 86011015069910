import { getLocalTimeZone, today } from '@internationalized/date'
import { APIs } from '@paper/api-specs'
import { useApiQuery } from '~src/data/useApiQuery'
import { useSchoolYearContext } from '~src/schoolYearAirlock'
import { DownloadTable } from './downloadTable'
import { usePdfBuilderContext } from './pdfBuilderProvider'

type DownloadThingProps = {}

// todo: this has simplified a bunch now that there's no anyone-can-view link...
export function DownloadTableWrapper(props: DownloadThingProps) {
  const { packetGroup, schoolId } = usePdfBuilderContext()
  const { syId } = useSchoolYearContext()

  const tz = getLocalTimeZone()
  const expires = today(tz).add({ days: 30 }).toDate(tz).getTime() - 60_000 // 11:59pm

  const qResultDownloadTable = useApiQuery({
    apiSpec: APIs['print.download'],
    queryVars: {
      body: {
        expires,
        packetGroupId: packetGroup.id,
        schoolId,
        syId,
      },
    },
    useQueryProps: {},
  })

  return <DownloadTable qResult={qResultDownloadTable} variant="loggedin" />
}
