import { Box, TextProps } from '@chakra-ui/react'
import { IcoLeftRight, IconType, IcoUpDown } from '@paper/icons'
import { Txt, VStack } from '~src/components'

type KeyboardInstr = {
  /** Two character string for the keys e.g. `zx` */
  keys: string
  icon: IconType | 'updown' | 'leftright'
  name: string
}

export type KeyboardInstructionProps = {
  adName: string
  wsName: string
  more?: KeyboardInstr[]
}
export function KeyboardInstructions(props: KeyboardInstructionProps) {
  let items: KeyboardInstr[] = [
    { icon: 'leftright', keys: 'ad', name: props.adName },
    { icon: 'updown', keys: 'ws', name: props.wsName },
    ...(props.more ?? []),
  ]

  return (
    <VStack alignItems="stretch" gap={2}>
      {items.map((args) => {
        const [key1, key2] = args.keys

        const icon =
          args.icon === 'leftright'
            ? IcoLeftRight
            : args.icon === 'updown'
            ? IcoUpDown
            : args.icon

        return (
          <Txt
            alignItems="center"
            display="inline-flex"
            fontSize="xs"
            key={args.keys}
            opacity={0.7}
            whiteSpace="pre"
          >
            <KeyCap keyChar={key1} /> and <KeyCap keyChar={key2} />
            <Box aria-label="navigate" as={icon} mx={1} />
            {args.name}
          </Txt>
        )
      })}
    </VStack>
  )
}

type KeyCapProps = {
  keyChar: string
} & TextProps

export function KeyCap({ keyChar, ...textProps }: KeyCapProps) {
  return (
    <Txt
      as="kbd"
      alignItems="center"
      borderWidth="1px"
      boxShadow="rgba(67, 117, 151, 0.08) 0px 3px 7px 0px;"
      display="inline-flex"
      fontFamily="mono"
      justifyContent="center"
      position="relative"
      rounded="10%"
      userSelect="none"
      width="26px" // todo: Should be proportional to height/text size
      {...textProps}
    >
      {keyChar}
    </Txt>
  )
}
