import { useRouter } from '@paper/route'
import { Routes } from '~src/routelist'

/**
 * Returns help link based on route
 * @see https://docs.google.com/document/d/1B4DBc0wADnuQSBJ8z3MvOvCuSvT-38DnZecsMKL4ZdU/edit
 */
export function useHelpLink() {
  const { currentRoute, routeData } = useRouter()
  // default to landing page
  let href = 'https://support.ponder.co/hc/en-us/categories/360003933271-Paper'

  if (currentRoute === Routes.sw_jumpToQ) {
    href = 'https://support.ponder.co/hc/en-us/articles/4404179949460'
  } else if (currentRoute === Routes.sw_jumpToStd) {
    href = 'https://support.ponder.co/hc/en-us/articles/4404208481172'
  } else if (currentRoute === Routes.sw_time) {
    href = 'https://support.ponder.co/hc/en-us/articles/4404179320852'
  }

  // tagging https://support.ponder.co/hc/en-us/articles/360058720732
  // scan log https://support.ponder.co/hc/en-us/articles/360047553812

  return href
}

export const zdNamingGuide =
  'https://support.ponder.co/hc/en-us/articles/360047539431-Packet-Naming-Guidelines'

export const defaultHelpLink =
  'https://support.ponder.co/hc/en-us/categories/360003933271-Paper'
