import { chakra } from '@chakra-ui/react'
import type { TicketRubric } from '@paper/schema'
import { ReactNode } from 'react'
import { SvgX } from '~src/blocks/svg'

type RubricXVizProps = {
  ifNull?: ReactNode
  data: TicketRubric
  size: number
}

export function RubricXViz(props: RubricXVizProps) {
  const { data, ifNull, size } = props
  if (!data) {
    return (ifNull || null) as JSX.Element
  }

  const dim = 1
  const viewBox = '0 0 3 3'
  const height = size
  const width = size

  const ys = {
    2: 0,
    1: 1,
    [-1]: 2,
  }

  return (
    <svg viewBox={viewBox} height={height} width={width} pointerEvents="none">
      <chakra.rect
        fill="transparent"
        height="100%"
        stroke="gray.100"
        strokeWidth={0.5}
        x={0}
        y={0}
        width="100%"
      />
      {data.values.map(
        (p, idx) =>
          p && (
            <SvgX
              key={idx}
              pad={0.15}
              stroke="gray.500"
              //stroke={colors[p]}
              strokeWidth={0.2}
              xywh={[ys[p], idx, dim, dim]}
            />
          )
      )}
    </svg>
  )
}
