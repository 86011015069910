import { useRouter } from '@paper/route'
import { BaseData } from '@paper/route/src/types'
import { createContext, ReactNode, useLayoutEffect } from 'react'
import { AppTitle } from '~src/components'
import { usePacketListData } from '~src/data/data-packets'
import { RD_SW_JumpToStd, Routes } from '~src/routelist'
import { NO_STD } from '~src/utils/messages'

type JumpToContext = {}
const JumpToContext = createContext(null)

type JumpToAirlockProps = { children: ReactNode }

/**
 * Ensures valid q or std is selected
 * And centralizes q vs. std context for bargraph etc.
 */
export function JumpToAirlock(props: JumpToAirlockProps) {
  const { children } = props
  const { currentRoute, routeData, useAirlock } = useRouter<RD_SW_JumpToStd>()
  const { qId, std } = routeData

  const packetDigest = usePacketListData()
  const packet = packetDigest.selectedItem

  let paramChange: Partial<RD_SW_JumpToStd>

  // todo: want to be mindful of where this sits to avoid extra renders
  if (!packet) {
    // but packet is loaded (so need to figure this out)
    // todo: 404
  } else if (!packet.questions.length) {
    // can't jumpTo if no questions
    // todo: leaning that we just want an empty message...
    // hey, this packet doesn't have questions (use the menu to go somewhere...)
  } else {
    const q = packet.questions.find((q) => q.id === qId)
    // jump-to-q
    if (!q && currentRoute === Routes.sw_jumpToQ) {
      paramChange = { qId: packet.questions[0].id }
    }
    // jump-to-std
    const stdX =
      std === NO_STD ? NO_STD : packet.stds?.find((p) => p.code === std)
    if ((!stdX || !q) && currentRoute === Routes.sw_jumpToStd) {
      // todo: should probably combine with QStdDigest
      // prefer current q/std
      let nextQ = stdX
        ? // prefer current std
          packet.questions.find((q) => q.stds.some((std) => std.code === stdX))
        : q?.stds?.length
        ? // prefer current q
          q
        : // fallback to q with std
          packet.questions.find((q) => q.stds.length)
      // todo: need to 404 if no nextQ
      if (nextQ) {
        paramChange = { qId: nextQ.id, std: nextQ.stds[0]?.code }
      }
    }
  }

  // execute airlock
  // console.log('[[[jumpTo]]]', routeData.yearCode)
  useAirlock(paramChange, !!paramChange)

  const ctx: JumpToContext = {}
  return (
    <JumpToContext.Provider value={ctx}>
      <AppTitle title={['Jump-to', packet?.name]} />
      {children}
    </JumpToContext.Provider>
  )
}
