import { C2Status, CourseFilterStatus } from '@paper/schema'
import { z, zenum } from '@paper/schema/validation'

export const z_setup_curriculum = z
  .object({
    ed: z.string(),
    familyEd: z.string(),
    f_level: z.string(),
    f_status: zenum<C2Status>(['active', 'recalled', 'retired']),
    f_text: z.string(),
  })
  .partial()

export type RD_Setup_Curriculum = z.infer<typeof z_setup_curriculum>

export const z_setup_school = z.object({
  courseId: z.string().optional(),
  f_status: zenum<CourseFilterStatus>([
    'all',
    'none',
    'partial',
    'pending',
  ]).optional(),
  f_text: z.string().optional(),
  schoolId: z.string().optional(),
})

export type RD_Setup_School = z.infer<typeof z_setup_school>

export const z_setup_issues = z.object({
  issueColl: z.string().optional(),
  issueItem: z.string().optional(),
  schoolId: z.string().optional(),
})

export type RD_Setup_Issues = z.infer<typeof z_setup_issues>
