const checkIfCypress = () => {
  // https://docs.cypress.io/faq/questions/using-cypress-faq#Is-there-any-way-to-detect-if-my-app-is-running-under-Cypress
  // @ts-expect-error
  return !!(typeof window !== 'undefined' && window.Cypress)
}

/**
 * Sets `window[key] = value` if cypress (for edge cases that require stubbing/spying)
 * Obviously hate having to modify app code for E2E tests,
 * but there are a few cases where i don't have an obvious better option
 */
export const setWindowIfCypress = (key: string, value: any) => {
  if (checkIfCypress()) {
    window[key] = value
  }
}

/**
 * Sets `window[key]` for cypress stub (cypress won't stub something that doesn't exist)
 * Returns a function that returns stub-or-not depending on cypress
 * Obviously hate having to modify app code for E2E tests,
 * but there are a few cases where i don't have an obvious better option
 */
export function __useGetCypressStub__<T>(fn: T, key: string): () => T {
  let isCypress = checkIfCypress()
  if (isCypress && !window[key]) {
    window[key] = fn
  }
  return () => (isCypress ? window[key] : fn)
}
