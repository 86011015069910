import { BoxProps } from '@chakra-ui/react'
import { Txt } from '~src/components'

/** Wrapper for form instruction with some default formatting */
export function Instruction(props: BoxProps) {
  return <Txt fontSize="sm" opacity={0.8} {...props} />
}

const packetType = {
  assessment: `Assessments include all pages so you're all set.`,
  ticket: `Select the first page of the ticket from the images on the right.`,
  none: `You must select a packet type first.`,
} as const

const uploadChecklist = [
  `The correct pages are present`,
  `The QR labels are not obstructing packet content`,
] as const

export const instruct = {
  packetType,
  uploadChecklist,
}
