import { useDrag, Vector2 } from '@use-gesture/react'
import { useState } from 'react'

export type OnPan = (xyPctDelta: Vector2) => void
type UseDragTransformProps = {
  enabled: boolean
  onPan?: OnPan
}

/**
 * Drag transform for ImageViewer
 */
export const useDragTransform = (props: UseDragTransformProps) => {
  const { enabled, onPan } = props
  const [isDragging, setIsDragging] = useState(false)

  // console.log('<ImagePage />')

  // old.originPct.map((p, idx) => p - deltaPct[idx])

  const bind = useDrag((state) => {
    if (!enabled) {
      return
    }

    // @ts-expect-error
    let rect = state.event.target.getBoundingClientRect()

    state.event.preventDefault()

    // calculate percent change
    const rectDims = [rect.width, rect.height]
    onPan?.(state.delta.map((p, idx) => (p * 100) / rectDims[idx]) as Vector2)

    if (state.first) {
      setIsDragging(true)
    } else if (state.last) {
      setIsDragging(false)
    }
  }) as any

  return { bind, isDragging }
}

export const pctVectorToOrigin = (originPct: Vector2) =>
  originPct?.map((p) => `${p}%`).join(' ') ?? null
