import { sumBy } from 'lodash'

/**
 * @deprecated Proper way to handle this? I haven't thought too hard about floats..
 * @example
 * // the problem
 * let values = times(60, { a: .57 })
 * sumBy(values, item => item.a)
 * // 34.20000000000001
 * sumByPrec(values, item => item.a)
 * // 34.2
 */
export function sumByPrec<T>(
  array: T[],
  iterator: (item: T) => number,
  prec = 10000
) {
  // sum terms * prec so we're summing integers
  const sum = sumBy(array, (item) => prec * (iterator(item) ?? 0))
  // todo: is this necessary, or is it sufficient to redivide by prec?
  const sumStr = Math.round(sum).toString()
  const decimalPlace = Math.log10(prec)
  console.log(sum, sumStr, decimalPlace)
  return parseFloat(
    `${sumStr.slice(0, -decimalPlace)}.${sumStr.slice(-decimalPlace)}`
  )
}

/**
 * sumBy, but coerces falsey to 0
 *
 * I got tired of forgetting to write sumBy(data, p => p.stats.success || 0)
 * @example
 * sumBy([1,0,null], p => p) // NaN
 * sumByCoerce([1,0,null], p => p) // 1
 */
export function sumByCoerce<T>(array: T[], iterator: (item: T) => number) {
  return sumBy(array, (...args) => iterator(...args) || 0)
}
