import { orderBy } from 'lodash'

/**
 * Sorts the words before calculating the levenshtein distance
 * This is a workaround so `5th Grade Math` and `Grade 5 Math` have a shorter distance
 */
export const sortedLevenshteinDistance = (s: string, t: string) => {
  let sorted = [s, t].map((str) => orderBy(str.split(/\s+/)).join(' ')) as [
    s: string,
    t: string,
  ]
  return levenshteinDistance(...sorted)
}

/**
 * Taken from: https://www.30secondsofcode.org/js/s/levenshtein-distance/
 */
export const levenshteinDistance = (s: string, t: string) => {
  if (!s.length) return t.length
  if (!t.length) return s.length
  const arr: number[][] = []
  for (let i = 0; i <= t.length; i++) {
    arr[i] = [i]
    for (let j = 1; j <= s.length; j++) {
      arr[i][j] =
        i === 0
          ? j
          : Math.min(
              arr[i - 1][j] + 1,
              arr[i][j - 1] + 1,
              arr[i - 1][j - 1] + (s[j - 1] === t[i - 1] ? 0 : 1)
            )
    }
  }
  return arr[t.length][s.length]
}
