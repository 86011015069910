import { PdfGenerateStatus, PrintManifest } from '@paper/schema'

/**
 *
 * @param manifests Pre-sorted descending list of print manifests
 */
export const getPdfGenerateStatus = (
  manifests: PrintManifest[]
): PdfGenerateStatus => {
  const latest = manifests[0]
  if (!latest) {
    return 'none'
  } else if (latest._printed.is) {
    return 'success'
  } else if (latest._error) {
    return 'error'
  } else if (new Date().valueOf() - latest.date > 120_000) {
    return 'timed-out'
  } else {
    return 'in-progress'
  }
}
