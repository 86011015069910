import { Button } from '@chakra-ui/react'
import { IcoHome } from '@paper/icons'
import { useRouter } from '@paper/route'
import { Curriculum } from '@paper/schema'
import { createContext, ReactNode, useContext, useMemo } from 'react'
import { FullPageLoading } from '~src/components/status'
import { DirRecord, useDirectoryData } from '~src/data/data-directory'
import { Routes } from '~src/routelist'
import { Error404Page, NotFoundError } from './errors'
import { getEAProps } from './errors/errorUtils'

type CurriculumContext = {
  curriculum: Curriculum
  curriculumRecord: DirRecord<'curriculum'>
}

const CurriculumContext = createContext<CurriculumContext>(null)
export const useCurriculumContext = () => useContext(CurriculumContext)

type CurriculumAirlockProps = {
  behavior?: 'locked' | 'unlocked'
  children: ReactNode
}

export function CurriculumAirlock(props: CurriculumAirlockProps) {
  // note: copy-pasty with TeacherAirlock...
  const { behavior, children } = props
  const { dispatchRoute, routeData, useAirlock } = useRouter<{
    curriculumId: string
  }>()

  let { curriculumId } = routeData
  const qResult = useDirectoryData()

  ////////////////////////////////
  // Lookup items from router
  ////////////////////////////////
  let curriculumRecord =
    curriculumId && qResult.data?.curriculum.map.get(curriculumId)
  let curriculum = curriculumRecord?.item

  ////////////////////////////////
  // Apply airlock logic
  ////////////////////////////////
  let needsUrlChange = false
  let is404 = false
  if (qResult.isSuccess) {
    if (!curriculumRecord) {
      if (behavior === 'locked') {
        is404 = true
      } else {
        needsUrlChange = curriculumId != null
        curriculumId = null
      }
    }
  }

  useAirlock({ curriculumId }, needsUrlChange)

  let error = useMemo(
    () =>
      is404
        ? new NotFoundError({ thing: 'curriculum', value: curriculumId })
        : null,
    [curriculumId, is404]
  )

  ////////////////////////////////
  // Render
  ////////////////////////////////
  let ctx = { curriculum, curriculumRecord }
  let body: ReactNode
  if (qResult.isPending || needsUrlChange) {
    body = <FullPageLoading qResult={{ isPending: true }} />
  } else if (is404) {
    body = (
      <Error404Page
        actions={
          // c/p from EntryProvider 404...maybe make a bunch of these to choose from?
          <Button
            {...getEAProps(<IcoHome />)}
            onClick={() => dispatchRoute(Routes.home_curriculum.mergeAction())}
          >
            Go to curriculum home
          </Button>
        }
        error={error}
      />
    )
  } else {
    body = children
  }

  return (
    <CurriculumContext.Provider value={ctx}>{body}</CurriculumContext.Provider>
  )
}
