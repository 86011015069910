import type {
  CrunchBatch,
  ImagesThat,
  ScanFixCandidatePage,
  ScanStatus,
  XpacketSet,
} from '@paper/schema'
import type { XOR } from '@paper/utils'
import { defineApi } from '../defineApi'

/**
 * List scan batches
 */
export const sb_list = defineApi<{
  method: 'post'
  body: {
    deviceName?: string
    dir?: 'later'
    packetId?: string
    scanDate?: number
    status?: ImagesThat
    syId: number
    teacherId?: string
  }
  result: {
    items: CrunchBatch[]
    isMore: boolean
    scanDevices: string[]
  }
}>({
  invalidateKey: 'scanlog',
  method: 'post',
  urlFactory: `scanbatch`,
})

/**
 * Xpacketsets for xpacket level scan status
 * It's possible this should be folded into two separate apis scanbatch/details, move/details
 * But it's simpler to keep it separate for now
 */
export const xpacketSets = defineApi<{
  method: 'post'
  body: CrunchBatch['packets']
  result: XpacketSet[]
}>({
  invalidateKey: 'scanlog',
  method: 'post',
  urlFactory: 'xpacketsets/list',
})

/**
 * qrbackup lookup for scan fix candidates
 */
export const qrbackup_lookup = defineApi<{
  method: 'post'
  body: { qrb: string; xpageId: string }
  result: ScanFixCandidatePage[][]
}>({
  invalidateKey: 'scanlog',
  method: 'post',
  urlFactory: `scanfix/lookup`,
})

type XpacketFixItem = {
  action: 'ignore' | 'infer-blank' | 'manual'
  scanImageId: string
  xpageId?: string
}

/**
 * Edit the scan/xpacket
 */
export const xpage_fix = defineApi<{
  method: 'post'
  body: XpacketFixItem[]
  result: boolean[]
}>({
  invalidateOnMutationSuccess: ['scanlog'],
  method: 'post',
  urlFactory: `scanfix/set`,
})
