import {
  DirPacket,
  PdfGenerateStatus,
  PdfGrain,
  PrintManifest,
  SchoolPacketGroup,
  TeacherSection,
} from '@paper/schema'
import { defineApi } from '../defineApi'

// todo: move
export type $SchoolManifestsProps = {
  grain: PdfGrain
  grainId?: string
  packetIds: string[]
  schoolId: string
}

export const sch_manifests_get = defineApi<{
  method: 'post'
  body: $SchoolManifestsProps
  result: [
    string,
    {
      downloadUrls: string[]
      manifests: PrintManifest[]
      status: PdfGenerateStatus
    },
  ][]
}>({
  method: 'post',
  urlFactory: `school/generatestatus`,
})

export type SectionsByCurriculum = {
  curriculumId: string
  sections: TeacherSection[]
}

export const sch_print_list = defineApi<{
  method: 'post'
  body: { schoolId: string; syId: number }
  result: { sbc: SectionsByCurriculum[]; packetGroups: SchoolPacketGroup[] }
}>({
  method: 'post',
  urlFactory: `school/packets`,
})

export type SchoolGridIn = {
  curriculumId: string
  packetId: string
  teacherId: string
}
export type SchoolGridOut = {
  curriculumId: string
  packet: DirPacket
  teacherId: string
}

export const sch_pg_get = defineApi<{
  method: 'post'
  body: { spec: SchoolGridIn[] }
  result: SchoolGridOut[]
}>({
  method: 'post',
  urlFactory: `school/packetgroup`,
})
