import { Box, BoxProps } from '@chakra-ui/react'
import { elipsize } from '@paper/utils'
import { useScrollIntoView } from '~src/utils/useScroll'
import { PassageWithStatus } from './chunk'

export const PagedStatus = ({ isPaged, variant = 'inline' }) => {
  return (
    <Box
      as="span"
      color={isPaged ? undefined : 'red.500'}
      fontFamily="mono"
      fontSize="xs"
      mr={variant === 'inline' ? 1 : undefined}
      pos={variant === 'inline' ? undefined : 'absolute'}
      top={variant === 'inline' ? undefined : '50%'}
      transform={
        variant === 'inline'
          ? undefined
          : `translate(-50%, -${isPaged ? 50 : 57}%)`
      }
    >
      {isPaged ? '✓' : '○'}
    </Box>
  )
}

type PassageLabelProps = {
  data: PassageWithStatus
  isScrolledTo?: boolean
  maxLength?: number
}
export const PassageLabel = (props: PassageLabelProps) => {
  const { data, isScrolledTo, maxLength } = props
  const domRef = useScrollIntoView(isScrolledTo)
  const invalidProps: BoxProps = data._isInvalid
    ? {
        borderBottomColor: 'red.500',
        borderBottomStyle: 'dashed',
        borderBottomWidth: 2,
      }
    : {}
  return (
    <Box fontSize="xs" py={1} ref={domRef} maxW="125px" {...invalidProps}>
      {elipsize(data?.name, maxLength)}
    </Box>
  )
}
