import {
  FixitActionSet,
  FixitSheet,
  FixitSheetBase,
  ScanImageAbbr,
  Student,
} from '@paper/schema'

/**
 * Returns merged `si.fix` onto `si.data`
 * todo: move this somewhere shared
 * todo: handle pageIndex vs. pageIndices!!!
 */
const resolveScanData = (si: ScanImageAbbr, actionSet: FixitActionSet) => {
  let merged = si.data ? { ...si.data } : {}
  let status = si.status
  const fixedKeySet = new Set<keyof ScanImageAbbr['data']>()
  if (si.fix) {
    for (let [key, value] of Object.entries(si.fix)) {
      if (value != null) {
        fixedKeySet.add(key as any)
        merged[key] = value
      }
    }
  }

  if (si.status === 'infer-blank') {
    //
  } else {
    if (actionSet.packet) {
      if (actionSet.packet === 'ignore') {
        status = 'ignore'
        merged = {}
      } else if (actionSet.packet === 'not-this-packet') {
        // todo: do we need a check here whether this action is valid?
        merged.packetId = null
        merged.pageIndex = null
        status = 'no-qr'
      } else if (actionSet.packet === 'infer-blank') {
        merged.packetId = null
        merged.pageIndex = null
        status = 'infer-blank'
      } else {
        merged.packetId = actionSet.packet.packetId
        merged.pageIndex = actionSet.packet.pageIndex
        status = 'manual'
        fixedKeySet.add('packetId')
      }
    }
    if (actionSet.student) {
      if (actionSet.student === 'no-student') {
        merged = {}
        status = 'ignore'
      } else if (actionSet.student === 'not-this-roster') {
        merged.studentId = null
      } else {
        merged.studentId = actionSet.student.studentId
        status = 'manual'
        fixedKeySet.add('studentId')
      }
    }
    if (actionSet.score != null) {
      merged.pts = actionSet.score.pts
      fixedKeySet.add('pts')
    }
    if (actionSet.rubric != null) {
      merged.rubric = actionSet.rubric
      fixedKeySet.add('rubric')
    }
  }

  return { data: merged, fixedKeySet, status }
}

type CrunchProps<T> = T & {
  packetId: string
  studentMap: Map<string, Student>
}

type CrunchFixitSheetProps = CrunchProps<{
  sheet: FixitSheetBase
}>

export const crunchFixitSheet = (props: CrunchFixitSheetProps) => {
  const { packetId, sheet, studentMap } = props

  // extract the 'best' data
  // todo: eventually support multi-page and detect anomalies...
  // todo: this is a mess!
  // todo: need a better way to keep track of imgp data vs. changed on the server vs. changed on the client
  // todo: or maybe decide to not worry about the distinction
  let { data, fixedKeySet } = resolveScanData(sheet.scans[0], sheet._actionSet)
  for (let i = 1; i < sheet.scans.length; i++) {
    let { data: pageData, fixedKeySet: pageFixedSet } = resolveScanData(
      sheet.scans[i],
      sheet._actionSet
    )
    for (let [key, value] of Object.entries(pageData)) {
      if (value && !data[key]) {
        data[key] = value
        if (pageFixedSet.has(key as any)) {
          fixedKeySet.add(key as any)
        }
      }
    }
  }

  // student (if in current roster)
  const student = studentMap.get(data.studentId)

  const hasReadableBubbles = !!data.rubric

  const isSavedIgnore = sheet.scans[0].status === 'ignore'
  const hasQRdPacket = !!sheet.scans[0]?.data?.packetId

  const isIgnorePacket =
    sheet._actionSet?.packet === 'ignore' ||
    (!sheet._actionSet.packet && isSavedIgnore && !hasQRdPacket) // saved, but not edited locally

  const isIgnoreStudent =
    sheet._actionSet?.student === 'no-student' || // local
    (!sheet._actionSet.student && isSavedIgnore && hasQRdPacket) // saved, but not edited locally

  const status: FixitSheet['status'] = {
    packet:
      sheet._actionSet?.packet === 'not-this-packet'
        ? 'not-this'
        : isIgnorePacket
        ? 'ignore'
        : !data?.packetId
        ? 'no-qr'
        : data.packetId === packetId
        ? 'success'
        : 'not-this',
    rubric: hasReadableBubbles ? 'success' : 'unreadable',
    score:
      data.pts != null
        ? 'success'
        : !hasReadableBubbles
        ? 'unreadable'
        : 'empty',
    student:
      sheet._actionSet?.student === 'not-this-roster'
        ? 'not-this'
        : isIgnoreStudent
        ? 'ignore'
        : !data?.studentId
        ? 'no-qr'
        : !!student
        ? 'success'
        : 'not-this',
  }

  return { data, fixedKeySet, status, student }
}
