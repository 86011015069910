import { PacketContent, PacketType } from '@paper/schema'
import times from 'lodash/times'
import { TicketPageSelector } from '~src/blocks/imageViewer'
import { HStack, ToggleGroup, Txt, VStack } from '~src/components'
import { Wiz } from '../publishLayout'
import { instruct, Instruction } from './entryInstructions'
import { useWizContext } from './wizProvider'

type TypeOption = 'assessment' | 'ticket-1' | 'ticket-2'

const splitType = (
  values: Pick<PacketContent, 'pages' | 'type'>
): TypeOption => {
  switch (values.type) {
    case 'ticket':
      return values.pages.length === 1 ? 'ticket-1' : 'ticket-2'
    default:
      return values.type
  }
}

export function FormPacketTypeAndPages() {
  const { formik, imageUrls } = useWizContext()
  const { setFieldValue } = formik
  const content = formik.values

  return (
    <Wiz.FormArea overflow="hidden">
      <HStack alignItems="stretch" gap={12} height="100%">
        {/* COLUMN: Instructions+PacketType */}
        <VStack alignItems="stretch" flexShrink={0} gap={8} width="320px">
          {/* SECTION: PacketType instructions */}
          <VStack alignItems="stretch" gap={4}>
            <VStack alignItems="stretch" gap={1}>
              <Txt fontSize="lg">Packet Type</Txt>
            </VStack>
            {/* SECTION: PacketType instructions */}
            <PacketTypeButtons />
          </VStack>
          {/* SECTION: Page instruction */}
          <VStack
            alignItems="stretch"
            gap={1}
            opacity={content.type == null ? 0.3 : 1}
            transition="opacity .5s ease"
          >
            <Txt fontSize="lg">Pages</Txt>
            <Instruction>
              {content.type === 'assessment'
                ? // todo: should probably just turn this into a component?
                  // more ideally, probably populated by something markdown-y/CMS-y
                  instruct.packetType.assessment
                : content.type === 'ticket'
                ? instruct.packetType.ticket
                : instruct.packetType.none}
            </Instruction>
          </VStack>
        </VStack>
        {/* COLUMN: Image buttons */}
        <TicketPageSelector
          onChange={(parts) => setFieldValue('parts', parts)}
          pkt={content}
          urls={imageUrls.raw}
        />
      </HStack>
    </Wiz.FormArea>
  )
}

function PacketTypeButtons() {
  const { formik, rawImages } = useWizContext()
  const content = formik.values
  const buttonHeight = '100px'

  const onChange = (value: TypeOption) => {
    let type: PacketType
    let length: number
    let parts = content.parts
    switch (value) {
      case 'ticket-1':
      case 'ticket-2':
        // reset page/parts if switching from assessment
        if (content.type === 'assessment') {
          parts = []
        }
        type = 'ticket'
        length = value === 'ticket-1' ? 1 : 2
        break
      case 'assessment':
        type = 'assessment'
        length = rawImages.length
        parts = [0]
        break
    }
    const pages = times(length, () => ({ items: [] }))
    formik.setValues({ ...formik.values, pages, parts, type })
  }
  return (
    <ToggleGroup.Root
      colorScheme="blue"
      onChange={onChange}
      preventNone={true}
      shape="rounded"
      type="single"
      value={splitType(content)}
    >
      <HStack gap={2}>
        <ToggleGroup.Button
          flexGrow={1}
          height={buttonHeight}
          value="assessment"
        >
          Assessment
        </ToggleGroup.Button>
        <VStack flexGrow={1} gap={1} gridArea="ticket" height={buttonHeight}>
          <ToggleGroup.Button flexGrow={1} value="ticket-1">
            1-sided Ticket
          </ToggleGroup.Button>
          <ToggleGroup.Button
            isDisabled={rawImages?.length === 1}
            flexGrow={1}
            value="ticket-2"
          >
            2-sided Ticket
          </ToggleGroup.Button>
        </VStack>
      </HStack>
    </ToggleGroup.Root>
  )
}
