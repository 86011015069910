export type IllXQ = {
  filled: number[]
  filledStr: string
  /** @deprecated not currently stored, tacked on in post...todo: should probably create a separate type */
  _outOf_?: number
  pts: number
}

export type IllXpacket = {
  _illuminate: string
  packetId: string
  qs: IllXQ[]
  studentId: string
  syId: number
  xpacketId: string
}
