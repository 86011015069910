import { useFilter } from '@react-aria/i18n'
import { mapValues } from 'lodash'

type StockFilters = ReturnType<typeof useFilter>
export type Filters = StockFilters & {
  startsWithByWord: Filters['startsWith']
}

/**
 * Each word in `query` should have a startsWith match in `src`
 * Created this for curricula where `6 math` and `math 6` should both return the same thing
 * Current implementation is bad for `2` since it will match `grade 2` and `21-22`
 * It's also inefficient, splitting multiple times!
 */
const makeStartsWithByWord =
  (filters: StockFilters) =>
  (src: string, query: string): boolean => {
    const srcWords = src.split(/\s+/)
    // todo: maybe add to filters...
    return query
      .split(/\s+/)
      .every((queryWord) =>
        srcWords.some((srcWord) => filters.startsWith(srcWord, queryWord))
      )
  }

/**
 * Returns filters via @react-aria with sensitivity base
 */
export function useFilters() {
  // Make filters
  // note: useFilter recreates on each render
  let stockFilters = useFilter({ sensitivity: 'base' })
  // checking that src != null so we don't crash
  stockFilters = mapValues(
    stockFilters,
    (f) => (src, query) => src != null && f(src, query)
  )
  const filters: Filters = {
    ...stockFilters,
    startsWithByWord: makeStartsWithByWord(stockFilters),
  }
  return filters
}
