import {
  Box,
  Button,
  ButtonGroup,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react'
import { IcoAdd, IcoSearch, IcoSetup } from '@paper/icons'
import { useRouter } from '@paper/route'
import { orderBy } from 'lodash'
import { useMemo } from 'react'
import { MainMenuDrawer } from '~src/blocks/mainMenu/drawer'
import { usePickerBase } from '~src/blocks/pickers'
import { TableLayout } from '~src/blocks/tableHelpers'
import {
  BaseHeader,
  ComboBox,
  HStack,
  ToggleGroup,
  VSep,
} from '~src/components'
import type { RD_Setup_Curriculum } from '~src/routelist'
import { useSchoolYearContext } from '~src/schoolYearAirlock'
import { useLimitToInternal } from '~src/utils/permissions'
import { CurriculumSetupTable } from './curriculumSetupTable'
import { useCurriculumSetupData } from './data-curriculumSetup'
import { useDialogContext } from './dialogs'
import { VariantSetup } from './variantSetup'

export function CurriculumSetup() {
  useLimitToInternal()
  const { routeData } = useRouter<RD_Setup_Curriculum>()
  const { openDialog } = useDialogContext()
  return (
    <BaseHeader.Container>
      <BaseHeader stackGap="1rem">
        <MainMenuDrawer icon={IcoSetup} />
        Curriculum Setup <EditionFilter />
        <Box ml="auto">
          <VariantSetup />
        </Box>
      </BaseHeader>
      <BaseHeader.Body px={12}>
        {routeData.ed && (
          <TableLayout
            filters={<Filters />}
            pickers={
              <Button
                borderRadius="5rem"
                data-cy="c2-create"
                leftIcon={<IcoAdd />}
                onClick={() => openDialog('create', null)}
              >
                New
              </Button>
            }
            table={<CurriculumSetupTable />}
            title={'Curriculum Setup'}
          />
        )}
      </BaseHeader.Body>
    </BaseHeader.Container>
  )
}

function Filters() {
  // todo: c/p with PacketListFilters
  return (
    <HStack flexWrap="wrap" gap={6} justifyContent="center">
      <TextFilter />
      <LevelFilter />
    </HStack>
  )
}

function EditionFilter() {
  const { availableYears } = useSchoolYearContext()
  const { dispatchStay, routeData } = useRouter<RD_Setup_Curriculum>()
  // todo: should populate based on available editions...
  const edData = useMemo(() => {
    let sorted = orderBy(availableYears, (p) => p.syId, 'desc')
    return sorted?.map((p) => ({ ed: p.code, id: p.code })) ?? []
  }, [availableYears])

  const edComboProps = usePickerBase(
    { isLoading: false, data: edData },
    {
      filterer: (filters, value, inputValue) =>
        filters.startsWith(value.ed, inputValue),
      selector: (item, value) => item.id === value,
      value: routeData.ed,
    }
  )
  return (
    <>
      <VSep boxProps={{ my: 4 }} />
      <ComboBox
        {...edComboProps}
        caret={true}
        data-cy="c2-ed"
        label="Edition"
        itemToString={(item) => item.ed}
        onChange={(item) => {
          dispatchStay({ ed: item.id })
        }}
        size="md"
        variant="filled"
        width="160px"
      />
    </>
  )
}

/**
 * todo: c/p with PacketTextFilter
 */
function TextFilter() {
  const size = 'sm'
  const { dispatchStay, routeData } = useRouter<RD_Setup_Curriculum>()
  const label = 'Filter by name, family, or variant'
  return (
    <InputGroup size={size} width="300px">
      <InputLeftElement color="gray.400" children={<IcoSearch />} zIndex={0} />
      <Input
        aria-label={label}
        borderRadius="5rem"
        data-cy="textFilter"
        onChange={(event) =>
          dispatchStay({ f_text: event.target.value || null })
        }
        placeholder={label}
        value={routeData.f_text ?? ''}
      />
    </InputGroup>
  )
}

function LevelFilter() {
  const { dispatchStay, routeData } = useRouter<RD_Setup_Curriculum>()
  const { levels } = useCurriculumSetupData()

  return (
    <ToggleGroup.Root
      onChange={(value) =>
        dispatchStay({ f_level: value === 'all' ? null : value })
      }
      preventNone={true}
      size="xs"
      type="single"
      value={routeData.f_level ?? 'all'}
    >
      <ButtonGroup isAttached={true}>
        <ToggleGroup.Button value="all">All</ToggleGroup.Button>
        {levels.map((l) => (
          <ToggleGroup.Button key={l} value={l}>
            {l}
          </ToggleGroup.Button>
        ))}
      </ButtonGroup>
    </ToggleGroup.Root>
  )
}
