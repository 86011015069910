import { APIs } from '@paper/api-specs'
import { useRouter } from '@paper/route'
import { Packetmeta } from '@paper/schema'
import { Error404Page, NotFoundError } from '~src/blocks/errors'
import { AppTitle } from '~src/components'
import { FullPageLoading } from '~src/components/status'
import { useApiQuery } from '~src/data/useApiQuery'
import { RD_LinkApi, Routes } from '~src/routelist'

export const LinkApi = () => {
  const { dispatchRoute, routeData } = useRouter<RD_LinkApi>()
  const { _illuminate, qLabel } = routeData

  // translate _illuminate into
  const qResult = useApiQuery({
    apiSpec: APIs['packets.illuminate'],
    queryVars: { pathParams: { _illuminate } },
    queryFn: async ({ plainFetch, url }) => {
      const matches = await plainFetch()

      console.log({ matches })

      if (matches.length > 0) {
        // Find a match containing the qLabel
        let match: Packetmeta
        let qId: string
        if (qLabel) {
          match = matches.find((pm) =>
            pm.content.questions.some((q) => {
              if (q.label === qLabel) {
                qId = q.id
                return true
              }
            })
          )
        }
        // default to first  if no qLabel/match not found
        match = match || matches[0]

        // TODO: Seems a bit unsafe to do this here...
        dispatchRoute(
          Routes.sw_jumpToQ.navigateAction(
            {
              curriculumId: match.curriculumId,
              packetId: match.id,
              qId,
              teacherId: null,
            },
            'replace'
          )
        )
      }

      return matches
    },
    useQueryProps: { gcTime: 0 },
  })

  return (
    <AppTitle title="Link API">
      <FullPageLoading qResult={qResult}>
        <Error404Page
          error={
            new NotFoundError({
              friendly: `${_illuminate} not found in Paper`,
              thing: 'packet',
              value: _illuminate,
            })
          }
        />
      </FullPageLoading>
    </AppTitle>
  )
}
