import { Box } from '@chakra-ui/react'
import { useRouter } from '@paper/route'
import { AnyAction } from '@reduxjs/toolkit'
import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useLayoutEffect,
  useReducer,
} from 'react'
import { useCurriculumContext } from '~src/blocks/curriculumAirlock'
import { NotFoundError } from '~src/blocks/errors'
import { useStaticFn } from '~src/blocks/list/listCallbacks'
import { AppTitle, BaseHeader, HStack, Txt } from '~src/components'
import { FullPageLoading } from '~src/components/status'
import type { RD_Publish } from '~src/routelist'
import config from '~src/utils/config'
import { getContentId } from '~src/utils/hash'
import rollbar from '~src/utils/rollbar'
import {
  useGetPacketEntryData,
  usePacketEntryDataStubber,
} from './data-publish'
import { OpenPdf } from './openPdf'
import { pubErrorRender } from './publishError'
import { PublishHome } from './publishHome'
import { PublishShell } from './publishLayout'
import { PublishMenu } from './publishMenu'
import { pubSlice, PubState } from './publishReducer'
import { EntryStub, getEntryStub } from './stubs/entryStubs'
import { StepSummary } from './wizard/stepSummary'
import { WizHeader } from './wizard/wizHeader'
import { useWizContext, WizProvider } from './wizard/wizProvider'

const CAN_STUB = config.meta.where === 'local' || config.meta.where === 'int'
export type PublishContext = ReturnType<typeof usePubState>

const PublishContext = createContext<PublishContext>(null)
export const usePublishContext = () => useContext(PublishContext)

/** redux toolkit types suddenly not working... */
export type ReduxStateTuple<T> = [state: T, dispatch: Dispatch<AnyAction>]

function usePubState() {
  const { dispatchStay } = useRouter<RD_Publish>()
  const { curriculum, curriculumRecord } = useCurriculumContext()
  const { canEdit } = curriculumRecord

  const { actions, getInitialState, reducer } = pubSlice
  const [state, dispatch] = useReducer(
    reducer,
    getInitialState()
  ) as ReduxStateTuple<PubState>
  const { contentId } = state

  // nicer type experience...
  // redux has a bindActionCreators function that would wrap all of these, but it seems to break types
  const dispatchShallowMerge = useStaticFn((state: Partial<PubState>) => {
    dispatch(actions._shallowMerge({ state }))
  })
  const finishEdit = useStaticFn(() => {
    dispatchShallowMerge({ page: 'home' })
  })
  const goToEditPdf = useStaticFn(() =>
    dispatchShallowMerge({ page: 'entryPdf' })
  )
  const goToEditAnswerKey = useStaticFn(() =>
    dispatchShallowMerge({ page: 'entryAnswerKey' })
  )
  const handleRouter = useStaticFn((contentIdOrNew: string) => {
    dispatch(actions.handleRouter({ contentIdOrNew }))
  })
  const openPdf = useStaticFn(async (srcDoc, srcBuf) => {
    const contentId = await getContentId(srcBuf)
    dispatch(actions.openPdf({ contentId, srcBuf, srcDoc }))
  })
  const setError = useStaticFn((error: Error) =>
    dispatch(actions.error({ error }))
  )

  const __stubDev__ = useStaticFn(({ formValues, state }: EntryStub) => {
    if (!CAN_STUB) {
      rollbar.error(new Error(`Can't stub ${config.meta.where}`))
    } else {
      dispatchShallowMerge(state)
    }
  })

  const qResult = useGetPacketEntryData(
    contentId,
    curriculum,
    !!contentId,
    (qResult) => {
      if (qResult.isSuccess) {
        //console.log('qResult in!', state.openedPdf, qResult.data)
        let { packets } = qResult.data

        // if we've come from opening a PDF, we're wondering if there are already packets...
        if (state.openedPdf?.waitingForPackets) {
          if (!packets.length) {
            goToEditPdf()
          } else {
            // packet already exists, go home
            dispatchStay({ contentIdOrNew: state.contentId })
          }
        }
        // otherwise we're at publishHome and there need to be packets
        else if (!packets.length) {
          setError(new NotFoundError({ thing: 'contentId', value: contentId }))
        }
      } else if (qResult.isError) {
        // todo: figure out where to handle this!
        throw qResult.error
      }
    }
  )

  return {
    ...state,
    __stubDev__,
    canEdit,
    curriculum,
    finishEdit,
    goToEditAnswerKey: canEdit && goToEditAnswerKey,
    goToEditPdf: canEdit && goToEditPdf,
    handleRouter,
    isLoading:
      (qResult.isPending && qResult.isFetching) || state.page === 'preinit',
    openPdf,
    packetData: qResult.data,
  }
}

/**
 * Initializes publish from router
 */
function useInitPublish() {
  const { routeData } = useRouter<RD_Publish>()
  const ctx = usePubState()
  const stubPacketEntryData = usePacketEntryDataStubber()

  // initialize with contentId
  useLayoutEffect(() => {
    let unmounted = false

    if (CAN_STUB && routeData.dev_step) {
      getEntryStub(routeData).then((stub) => {
        if (!unmounted) {
          if (!stub) {
            alert(`invalid queryparam dev_step`)
          } else {
            stubPacketEntryData(stub)
            ctx.__stubDev__(stub)
          }
        }
      })
    } else {
      ctx.handleRouter(routeData.contentIdOrNew)
    }

    return () => {
      unmounted = true
    }
  }, [routeData.contentIdOrNew])

  return ctx
}

export function PublishProvider() {
  const ctx = useInitPublish()
  const { error, isLoading, page } = ctx
  // boot from new if can't edit
  if (page === 'new' && !ctx.canEdit) {
    throw new NotFoundError({
      friendly: `There's nothing at this URL for you!`,
      thing: 'page',
      value: location.pathname,
    })
  }

  let main: ReactNode
  if (error) {
    main = pubErrorRender({ error })
  } else if (page === 'home' && ctx.packetData?.packets.length) {
    // todo: there's currently a render in between the packets coming home, and ctx.error being set...
    main = <PublishHome />
  } else if (page === 'new') {
    main = <NewPacket />
  } else if (page?.startsWith('entry')) {
    main = <EditPacket />
  }

  return (
    <PublishContext.Provider value={ctx}>
      <AppTitle
        title={[
          'Publish',
          page === 'new'
            ? 'New packet'
            : ctx.packetData?.focusPacket?.content.name ?? ctx.curriculum?.name,
        ]}
      />
      <FullPageLoading
        qResult={{ isPending: isLoading }}
        type={page === 'home' ? 'opaque' : 'transparent'}
      >
        {main}
      </FullPageLoading>
    </PublishContext.Provider>
  )
}

function NewPacket() {
  return (
    <PublishShell
      header={
        <BaseHeader mb={0} stackGap="2rem">
          <PublishMenu />
          <Txt fontSize="lg">Add a new packet</Txt>
        </BaseHeader>
      }
    >
      <OpenPdf />
    </PublishShell>
  )
}

function EditPacket() {
  return (
    <WizProvider>
      <InnerTmp />
    </WizProvider>
  )
}

function InnerTmp() {
  const { curIdx, curStep, curStepError, formik, isNew, stepList } =
    useWizContext()

  const { getFieldMeta, values } = formik

  return (
    <PublishShell
      header={
        <BaseHeader mb={0} stackGap="2rem">
          <PublishMenu />
          <WizHeader />
        </BaseHeader>
      }
    >
      <HStack alignItems="stretch" flexGrow={1} overflow="hidden">
        <Box
          data-cy="pe-steps"
          data-selected={curStep?.key}
          flexShrink={0}
          overflowY="auto"
          width="176px"
        >
          {stepList.map(({ key, getLocked, getSummary, title }, stepIdx) => {
            const fieldMeta = key && getFieldMeta(key)
            const isCurrent = curIdx === stepIdx
            const summary = getSummary?.({
              isCurrent,
              isNew,
              isFuture: stepIdx > curIdx,
              values,
              ...fieldMeta,
            })

            return (
              <StepSummary
                key={key}
                isCurrent={isCurrent}
                isFuture={stepIdx > curIdx}
                isLocked={getLocked?.({ isNew, isPast: stepIdx < curIdx })}
                isNew={isNew}
                summary={summary}
                title={title}
                uglyOverride={isCurrent ? curStepError : null}
                {...fieldMeta}
              />
            )
          })}
        </Box>
        <Box display="flex" flexGrow={1} overflow="hidden">
          {curStep.Form && <curStep.Form />}
        </Box>
      </HStack>
    </PublishShell>
  )
}
