import {
  PacketPrintSnapshot,
  PdfChecksum,
  PdfGrain,
  WhoPrintSnapshot,
} from './print'

export type DownloadRow = {
  checksum: PdfChecksum
  downloadName: string
  downloadUrl: string
  expires: number
  generatedAt: number
  grain: PdfGrain
  grainId: string
  id: string
  /** Unlike `stapleEvery` we don't print on the trailing blank */
  lastQRPageNum: number
  packetSnap: PacketPrintSnapshot
  partCount: number
  partNumber: number
  printUser: string
  rowStatus: 'downloadable' | 'recalled' | 'superseded'
  sheetCount: number
  stapleEvery: number
  whoSnap: WhoPrintSnapshot
}
