import { DirPacket, Teacher, WebappUser } from '@paper/schema'
import { createContext, ReactNode, useContext } from 'react'
import { usePacketListData } from '~src/data/data-packets'
import { useTeacherContext } from './teacherAirlock'
import { useUser } from './userProvider'

const SWContext = createContext<SWContext>(null)
export const useSWContext = () => useContext(SWContext)

type AdapterProps = {
  packet?: DirPacket
  packets: DirPacket[]
  teacher?: Teacher
  user: WebappUser
}

export type SWContext = {
  can: {
    fix: boolean
    name: boolean
    pin: boolean
    q: boolean
    std: boolean
    tag: boolean
    time: boolean
  }
} & AdapterContext &
  AdapterProps

type AdapterContext = {
  noBlankSwitch: boolean
  noPacketSwitch: boolean
  packetSwitchList: DirPacket[]
}

type SWAdapter = (props: AdapterProps) => AdapterContext

const jumpToAdapter: SWAdapter = (props) => {
  const { packets, teacher } = props
  return {
    noBlankSwitch: false, // can switch to blanks
    noPacketSwitch: false, // can switch packets
    // but only exams with questions
    packetSwitchList: packets.filter(
      (p) =>
        p.type === 'assessment' &&
        p.questions.length &&
        // if !teacher (blanks), include all. if teacher, only include those with scans
        (!teacher || p.scan?.count)
    ),
  }
}

const ticketPilotAdapter: SWAdapter = (props) => {
  return {
    noBlankSwitch: true,
    noPacketSwitch: false,
    packetSwitchList: props.packets.filter((p) => p._isTicketPilot),
  }
}

const timeAdapter: SWAdapter = (props) => {
  return {
    noBlankSwitch: true, // can't switch to blanks
    noPacketSwitch: true, // can't switch packets through the menu (use grid instead)
    packetSwitchList: null,
  }
}

const fixerAdapter: SWAdapter = (props) => {
  return {
    noBlankSwitch: true,
    noPacketSwitch: false,
    packetSwitchList: props.packets,
  }
}

const unnamedAdapter: SWAdapter = (props) => {
  return {
    noBlankSwitch: true,
    noPacketSwitch: false,
    packetSwitchList: props.packets.filter((p) => p.scan?.unnamedOpen > 0),
  }
}

export const swAdapters = {
  nostudent: unnamedAdapter,
  scanlog: fixerAdapter,
  jumpTo: jumpToAdapter,
  ticketPilot: ticketPilotAdapter,
  time: timeAdapter,
} as const

function adapterToContext(adapter: SWAdapter, props: AdapterProps): SWContext {
  const { packet, teacher } = props
  const teacherContext = useTeacherContext()

  let q = packet?.type === 'assessment' && packet?.questions.length > 0
  let pin = q && !!teacherContext.canPin
  let std = q && packet?.stds?.length > 0
  let time = !!teacher
  let tag = time && packet?.type === 'ticket' && teacherContext.canTag
  let fix = time
  let name = !!teacher && !!packet

  return {
    can: { fix, name, pin, q, std, tag, time },
    ...adapter(props),
    ...props,
  }
}

type SWProviderProps = { adapter: SWAdapter; children: ReactNode }

/**
 * React requires the tree to have the same structure to avoid re-rendering
 * This is a workaround to allow the SW views to have a common set of params
 */
export function SWProvider(props: SWProviderProps) {
  const { adapter, children } = props
  const { user } = useUser()
  const { data: packets, selectedItem: packet } = usePacketListData()
  const tc = useTeacherContext()

  const ctx: SWContext = adapterToContext(adapter, {
    packet,
    packets,
    teacher: tc?.teacher,
    user,
  })

  return <SWContext.Provider value={ctx}>{children}</SWContext.Provider>
}
