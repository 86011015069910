import {
  Button,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import { IcoSignpost, IcoUser } from '@paper/icons'
import { RouteMeta, useRouter } from '@paper/route'
import { invertMap } from '@paper/utils'
import { useMemo } from 'react'
import { CurriculumAirlock } from '~src/blocks/curriculumAirlock'
import { MainMenuDrawer } from '~src/blocks/mainMenu/drawer'
import { TeacherAirlock } from '~src/blocks/teacherAirlock'
import { useUser } from '~src/blocks/userProvider'
import { Routes } from '~src/routelist'
import { SchoolYearPicker } from '~src/schoolYearAirlock'
import { CurriculumPanel } from './curriculumPanel'
import { SchoolPanel } from './schoolPanel'
import { TeacherPanel } from './teacherPanel'

function useRouteToTab() {
  const { currentRoute, dispatchRoute } = useRouter()
  const { routeTabMap, setTabIndex } = useMemo(() => {
    // route <-> tabIndex
    const routeTabMap = new Map<RouteMeta<any>, number>([
      [Routes.home_teacher, 0],
      [Routes.home_curriculum, 1],
      [Routes.home_school, 2],
    ])
    const tabRouteMap = invertMap(routeTabMap)

    const setTabIndex = (tabIndex: number) => {
      dispatchRoute(tabRouteMap.get(tabIndex).mergeAction())
    }

    return { routeTabMap, setTabIndex }
  }, [])

  const tabIndex = routeTabMap.get(currentRoute)

  return { tabIndex, setTabIndex }
}

export function Home() {
  const { user } = useUser()
  const { tabIndex, setTabIndex } = useRouteToTab()

  return (
    <Tabs
      display="flex"
      flexDirection="column"
      flexGrow={1}
      height="100%" // important for `overflow:auto`
      index={tabIndex}
      isLazy={true}
      onChange={setTabIndex}
      overflow="hidden"
      pt={'14px'} // pad here so we don't occlude the focus indicator, and to match Header height
      px={12} // and here
      size="lg"
      sx={{ 'button[role=tab]': { fontSize: '1.0625rem' } }}
      variant="enclosed"
    >
      <TabList>
        <MainMenuDrawer
          trigger={(buttonProps) => (
            <Button
              {...buttonProps}
              fontFamily="body"
              flexShrink={0}
              leftIcon={<IcoSignpost fontSize="1.75rem" />}
              mr={3}
              px={3}
              size="lg"
            >
              Ponder Paper
            </Button>
          )}
        />
        <SchoolYearPicker />
        <Tab fontSize="1.1rem">Teacher</Tab>
        <Tab>Curriculum</Tab>
        <Tab>School</Tab>
        <Tab
          data-cy="tab-user"
          as="span"
          fontFamily="body"
          fontSize="md"
          ml="auto"
          pointerEvents="none"
        >
          <Icon as={IcoUser} mr={1} />
          {user.givenName}
        </Tab>
      </TabList>
      <TabPanels display="flex" flexGrow={1} overflow="hidden">
        <TabPanel flexGrow={1} tabIndex={-1} pb={0}>
          <TeacherAirlock behavior="unlocked-clear-curriculum">
            <TeacherPanel />
          </TeacherAirlock>
        </TabPanel>
        <TabPanel flexGrow={1} tabIndex={-1}>
          <CurriculumAirlock behavior="unlocked">
            <CurriculumPanel />
          </CurriculumAirlock>
        </TabPanel>
        <TabPanel flexGrow={1} tabIndex={-1}>
          <SchoolPanel />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
