import { Stat, StatGroup, StatLabel, StatNumber } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { HSep, HStack, Txt, VSep, VStack } from '~src/components'
import { DarkMode } from '~src/utils/forceMode'
import { formatPercent2 } from '~src/utils/messages'
import { useScanVizContext } from './scanVizProvider'
import { ScanVizSettings } from './scanVizSettings'

type ScanVizHeaderProps = {}

export function ScanVizHeader(props: ScanVizHeaderProps) {
  return (
    <DarkMode>
      <VStack
        alignItems="stretch"
        borderBottom="1px solid white"
        color="white"
        flexShrink={0}
        gap={4}
        pt={2}
        px={4}
        pb={6}
      >
        <ScanVizSettings />
        <HSep />
        <ScanVizStats />
      </VStack>
    </DarkMode>
  )
}

type ScanVizStatsProps = {}

function ScanVizStats(props: ScanVizStatsProps) {
  const { data, stats } = useScanVizContext()
  // todo: better handling for no query yet
  if (!data?.length) {
    return <Txt>No batches</Txt>
  }

  return (
    <HStack gap={4}>
      <StatGroup gap={8}>
        <StatX
          label="Batches shown"
          number={
            <>
              {stats.shown.batches} / {stats.total.batches}
            </>
          }
        />
        <VSep />
        <StatX
          label="Success+Infers+Manuals"
          number={<>{stats.total.denom}</>}
        />
        <VSep />
        <StatX label="Success" number={<>{stats.total.success}</>} />
        <VSep />
        <StatX
          label="Infer"
          number={
            <>
              {stats.total.infer} (
              {formatPercent2(stats.total.infer, stats.total.denom)})
            </>
          }
        />
        <StatX
          label="Infer (gap)"
          number={
            <>
              {stats.total.inferGap} (
              {formatPercent2(stats.total.inferGap, stats.total.denom)})
            </>
          }
        />
        <VSep />
        <StatX
          label="Manual"
          number={
            <>
              {stats.total.manual} (
              {formatPercent2(stats.total.manual, stats.total.denom)})
            </>
          }
        />
      </StatGroup>
    </HStack>
  )
}

type StatProps = { label: ReactNode; number: ReactNode }

function StatX(props: StatProps) {
  return (
    <Stat>
      <StatLabel textAlign="center" whiteSpace="nowrap">
        {props.label}
      </StatLabel>
      <StatNumber fontWeight={300} textAlign="center" whiteSpace="nowrap">
        {props.number}
      </StatNumber>
    </Stat>
  )
}
