import { getFullName } from '@paper/utils'
import { IcoScanIssue } from '~src/blocks/icoScanIssue'
import { MainMenuDrawer } from '~src/blocks/mainMenu/drawer'
import { useTeacherContext } from '~src/blocks/teacherAirlock'
import { AppTitle, BaseHeader, HStack, Mono, Txt, VSep } from '~src/components'
import { useFixitContext } from './fixitProvider'
import { FixitRoster } from './fixItRoster'
import { FixitScans } from './fixitScans'

export function FixitPage() {
  const { teacher } = useTeacherContext()
  const { packet } = useFixitContext()

  // icon, maybe a wand (fix), clipboard, check list, export?
  return (
    <BaseHeader.Container>
      <AppTitle title={['Fix-it/Export']} />
      <BaseHeader
        hideLogo={true}
        marginBottom={0}
        minWidth="unset"
        stackGap="1rem"
      >
        <MainMenuDrawer icon={IcoScanIssue} />
        <Txt fontSize="lg" flexShrink={0}>
          Scan report and score export
        </Txt>
        {teacher && (
          <>
            {BaseHeader.Divider}
            {getFullName(teacher)}
          </>
        )}
        {packet && (
          <>
            {BaseHeader.Divider}
            <HStack flexShrink={0} gap={2}>
              <Mono>{packet.number}</Mono>
              <Txt>{packet.name}</Txt>
            </HStack>
          </>
        )}
      </BaseHeader>
      <BaseHeader.Body gap={3} px={4} py={2} sx={{ '>*': { flexShrink: 0 } }}>
        <FixitRoster />
        <VSep boxProps={{ my: 1.5 }} size="12px" />
        <FixitScans />
      </BaseHeader.Body>
    </BaseHeader.Container>
  )
}
