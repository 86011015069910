import {
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
  useClipboard,
} from '@chakra-ui/react'
import { IcoCheckmark, IcoCopy, IconType } from '@paper/icons'
import { forwardRef, ReactNode, useCallback, useEffect, useState } from 'react'

type CopyButtonProps = {
  icon?: IconType
  doneIcon?: IconType
  onCopyDone?(): void
  toConsole?: any
  value: string
} & Omit<IconButtonProps, 'icon' | 'onClick' | 'value'>

/**
 * Copies `props.value` to clipboard on click
 */
export const CopyButton = forwardRef<HTMLButtonElement, CopyButtonProps>(
  function CopyButton(props, ref) {
    const { doneIcon, icon, toConsole, onCopyDone, value, ...btnProps } = props
    const { onCopy, hasCopied } = useClipboard(value)
    const CopyIcon = hasCopied ? doneIcon || IcoCheckmark : icon || IcoCopy
    return (
      <IconButton
        ref={ref}
        icon={<CopyIcon />}
        isRound={true}
        onClick={() => {
          if (toConsole) {
            console.group('Copied value')
            console.log(toConsole)
            console.groupEnd()
          }
          onCopy()
          onCopyDone?.()
        }}
        {...btnProps}
      />
    )
  }
)

type UseClipboardProps = {
  onCopyDone?(): void
  timeout?: number
  value: ClipboardValue
}

/**
 * Copy/pasted from the chakra's useClipboard with:
 * * HTML5 clipboard
 * * Option for lazy value
 * todo: mime? https://www.stefanjudis.com/notes/a-clipboard-magic-trick-how-to-use-different-mime-types-with-the-clipboard/
 * todo: error?
 * todo: allow value to be async with loading?
 */
export function useClipboard2(props: UseClipboardProps) {
  let { onCopyDone, value } = props
  const [hasCopied, setHasCopied] = useState(false)
  const timeout = props.timeout ?? 1500

  // todo: is there a non-awkward way to useCallback this?...
  // todo: onCopyDone and value if functions would also need to be useCallbacked...
  const onCopy = useCallback(async () => {
    // Get value
    value = typeof value === 'function' ? value() : value
    try {
      if (typeof value === 'string') {
        await navigator.clipboard.writeText(value)
      } else {
        await navigator.clipboard.write(value)
      }
      setHasCopied(true)
      onCopyDone?.()
    } catch (err) {
      // no-op, error state?
      console.error(err)
    }
  }, [onCopyDone, value])

  // Reset `hasCopied` after `timeout`
  useEffect(() => {
    let timeoutId: number | null = null
    if (hasCopied) {
      timeoutId = window.setTimeout(() => {
        setHasCopied(false)
      }, timeout)
    }
  }, [timeout, hasCopied])

  return { hasCopied, onCopy }
}

type ClipboardValue_ = string | ClipboardItems
type ClipboardValue = ClipboardValue_ | (() => ClipboardValue_)
type OmitProps = 'icon' | 'leftIcon' | 'onClick' | 'rightIcon' | 'value'

type CopyButton2Props = UseClipboardProps & {
  doneIcon?: IconType
  icon?: IconType
} & (
    | Omit<ButtonProps, OmitProps>
    | Omit<IconButtonProps, OmitProps | 'children'>
  )

export const CopyButton2 = forwardRef<HTMLButtonElement, CopyButton2Props>(
  function CopyButton2(props, ref) {
    const { doneIcon, icon, onCopyDone, timeout, value, ...rest } = props

    const { hasCopied, onCopy } = useClipboard2({
      onCopyDone,
      timeout,
      value,
    })

    const CopyIcon = hasCopied ? doneIcon || IcoCheckmark : icon || IcoCopy
    // @ts-expect-error
    if (props.children) {
      return (
        <Button ref={ref} {...rest} leftIcon={<CopyIcon />} onClick={onCopy} />
      )
    } else {
      return (
        <IconButton
          ref={ref}
          isRound={true}
          {...(rest as IconButtonProps)}
          icon={<CopyIcon />}
          onClick={onCopy}
        />
      )
    }
  }
)
