import { useColorMode } from '@chakra-ui/react'
import { ComboBox, ComboBoxProps, Txt } from '~src/components'
import { useMonsterBoxState } from './monsterState'

type MonsterBoxProps = Pick<ComboBoxProps, 'autoFocus' | 'size'>

export function MonsterBox(props: MonsterBoxProps) {
  const { colorMode } = useColorMode()

  const { breadcrumb, comboProps } = useMonsterBoxState()

  return (
    <ComboBox.Root
      {...props}
      {...comboProps}
      variant={colorMode === 'light' ? 'outline' : 'filled'}
    >
      <ComboBox.Label display="flex" gap={2} overflow="hidden">
        <Txt flexShrink={0} whiteSpace="nowrap">
          Go to:
        </Txt>
        {breadcrumb}
      </ComboBox.Label>
      <ComboBox.Shell>
        <ComboBox.Input />
        <ComboBox.List maxHeight="500px" type="popover" />
      </ComboBox.Shell>
    </ComboBox.Root>
  )
}
