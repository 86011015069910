import { APIs, BodyOf, ResultOf } from '@paper/api-specs'
import type { PDFDocument } from '@paper/pdf'
import { Curriculum, Packetmeta } from '@paper/schema'
import { fetcher } from '@paper/utils'
import {
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query'
import { produce } from 'immer'
import { useLayoutEffect, useRef } from 'react'
import { useUser } from '~src/blocks/userProvider'
import { useApiMutation, useApiQuery } from '~src/data/useApiQuery'
import { SubmitPdfProps, _submitPdf } from '~src/data/_submitPdf'
import { __useGetCypressStub__ } from '~src/utils/cypress'
import rollbar from '~src/utils/rollbar'
import { useShallowMemo } from '~src/utils/useMemos'
import { loadPdf } from './loadPdf'
import { EntryStub } from './stubs/entryStubs'

type OnQuery<T> = (qResult: UseQueryResult<T>) => void
/**
 * @deprecated Has this been addressed in the library since I wrote this?
 * todo: Is there a built-in way to do this?
 * `useQuery` has `onSettled`, but I think I read it only fires if the query function executes
 */
export function useListenQuery<T>(
  qResult: UseQueryResult<T>,
  isActive: boolean,
  onQuery: OnQuery<T>
) {
  const { dataUpdatedAt, isFetching, isPending, status } = qResult

  // todo: feels like this is a particularly ugly way to accomplish...anyway...
  // todo: may be a way to simplify tweaking how isActive works?
  // keep track of whether the query has changed (status or data has updated)
  const listenKey = useShallowMemo({ dataUpdatedAt, status })
  // have a ref to keep track of the most recent value we've fired on
  const lastFiredRef = useRef<typeof listenKey>()
  // check whether the key has changed
  const hasKeyChanged = listenKey !== lastFiredRef.current

  // console.log({ ...listenKey, hasKeyChanged, isFetching, isActive })

  useLayoutEffect(() => {
    // fire (and update ref) if key changes, and active
    // todo: and !isFetching
    // the added wrinkle is that the query can go from idle->success (but fetching)
    // which then caused this to fire with the old data
    // todo: need a better solution to this disaster...maybe revisit with react-query@4
    if (hasKeyChanged && isActive && !isFetching && !isPending) {
      // console.log('firing', listenKey, qResult.data, qResult)
      lastFiredRef.current = listenKey
      onQuery(qResult)
    }
  }, [listenKey, isActive])
}

const QueryKeys = {
  pdfBytes: ({ contentId }): QueryKey => ['pdfBytes', { contentId }],
}

export function usePacketEntryDataStubber() {
  const queryClient = useQueryClient()
  return (stub: EntryStub) => {
    // todo: a downside to doing the factory like this is that this code needs to know which parameter is a searchParam...
    const { queryKey } = APIs['pe.getPackets'].factory({
      searchParams: { contentId: stub.state.contentId },
    })
    queryClient.setQueryData(queryKey, stub.packetData)
  }
}

export type GetPacketEntryData = ResultOf<'pe.getPackets'> & {
  focusPacket: Packetmeta
}

export function useGetPacketEntryData(
  contentId: string,
  curriculum: Curriculum,
  isActive: boolean,
  onQuery: OnQuery<GetPacketEntryData>
) {
  const qResult = useApiQuery({
    apiSpec: APIs['pe.getPackets'],
    queryVars: { searchParams: { contentId } },
    queryFn: async ({ plainFetch }) => {
      // todo: react-query is/was? firing with null when going from stub to not...
      if (!contentId) {
        return null
      }
      console.log('fetching', contentId)
      let result = await plainFetch()
      let { packets } = result
      const focusPacket =
        packets?.find((p) => p.curriculumId === curriculum.id) ?? packets[0]

      return { focusPacket, ...result }
    },
    useQueryProps: { enabled: isActive, staleTime: Infinity, gcTime: Infinity },
  })

  useListenQuery(qResult, isActive, onQuery)

  return qResult
}

type FetchedPdf = { srcBuf: ArrayBuffer; srcDoc: PDFDocument }
type FetchPdfProps = { contentId: string; url: string }
/**
 * Fetch Pdf bytes
 */
export function useFetchPdf(
  props: FetchPdfProps,
  isActive: boolean,
  onQuery: OnQuery<FetchedPdf>
) {
  const qResult = useQuery({
    queryKey: QueryKeys.pdfBytes({ contentId: props?.contentId }),
    queryFn: async () => {
      const fetched = await loadPdf(props.url)
      return fetched
    },
    enabled: !!(isActive && props?.contentId),
  })
  useListenQuery(qResult, isActive, onQuery)
}

/////////////////////////
// Submit Pdf
/////////////////////////
export function useSubmitPdf(
  config?: UseMutationOptions<boolean, unknown, SubmitPdfProps>
) {
  const { fetchAs } = useUser()

  // todo: useSubmitPdf calls multiple APIs, so doesn't match useApiMutation
  // todo: keeping the awkward onSuccess mojo for now, but could use a refactor
  config = config ?? {}
  const queryClient = useQueryClient()
  // pare off the queryKey prefix for the packets query
  const invalidateKey = APIs['pe.getPackets'].invalidateKey
  const origOnSuccess = config.onSuccess
  config = produce(config, (draft) => {
    draft.onSuccess = async (...args) => {
      await queryClient.invalidateQueries({
        queryKey: invalidateKey,
        refetchType: 'all',
      })
      return origOnSuccess?.(...args)
    }
  })

  // Give cypress the opportunity to replace the function
  let getAction = __useGetCypressStub__(_submitPdf, '__cypress_submitPdf__')

  // make this interceptable
  return useMutation({
    mutationFn: async (props: SubmitPdfProps) => {
      // Awkwardly factored this out because it's useful to call this for the headless tests
      let submitPdf = getAction()
      await submitPdf(props, fetchAs, fetcher)
      return true
    },
    ...config,
  })
}

/////////////////////////
// Submit Answerkey
/////////////////////////
type SubmitAnswerkeyProps = BodyOf<'pe.submitAnswerKey'>
export function useSubmitAnswerkey(
  config?: UseMutationOptions<boolean, unknown, SubmitAnswerkeyProps>
) {
  return useApiMutation({
    apiSpec: APIs['pe.submitAnswerKey'],
    mutationFn: async ({ fetchAs, method, json, url }) => {
      let step = 'submitting to Paper'
      try {
        await fetchAs(url, { method, json })
        // Write to clipboard after submit for example data workaround
        // @ts-expect-error
        window.ANSWER_KEY = JSON.stringify(json)
        console.log('Run the following to copy to clipboard:\ncopy(ANSWER_KEY)')
        return true
      } catch (error) {
        // TODO: Copy/pasty from submitPdf
        error.friendly = `There was a problem ${step}`
        error.values = json
        // Send to rollbar
        if (process.env.NODE_ENV === 'production ') {
          rollbar.error(error)
        }
        throw error
      }
    },
    useMutationProps: config,
  })
}

/////////////////////////
// Publish
/////////////////////////
type SubmitPublishProps = BodyOf<'pe.publish'>
export function useSubmitPublish(
  config?: UseMutationOptions<undefined, unknown, SubmitPublishProps>
) {
  return useApiMutation({
    apiSpec: APIs['pe.publish'],
    mutationFn: async ({ fetchAs, json, method, url }) => {
      let step = 'publishing'
      try {
        await fetchAs(url, { method, json })
      } catch (error) {
        // TODO: Copy/pasty from submitPdf
        error.friendly = `There was a problem ${step}`
        error.values = json
        // Send to rollbar
        if (process.env.NODE_ENV === 'production ') {
          rollbar.error(error)
        }
        throw error
      }
    },
    useMutationProps: config,
  })
}

/////////////////////////
// External links
/////////////////////////
export function useSubmitCrossPacket(
  config?: UseMutationOptions<boolean, unknown, BodyOf<'pe.submitCrossPacket'>>
) {
  return useApiMutation({
    apiSpec: APIs['pe.submitCrossPacket'],
    useMutationProps: config,
  })
}

/**
 * Hook to submit standards
 */
export function useSubmitStds(
  config?: UseMutationOptions<boolean, unknown, BodyOf<'pe.submitStds'>>
) {
  return useApiMutation({
    apiSpec: APIs['pe.submitStds'],
    useMutationProps: config,
  })
}
