import { Student } from './school'
import type { ScanImageAbbr } from './types.imgp'
import { TicketRubric } from './types.xpacket'

export type FixitPageIndices = [number, number]

export type FixitActionSet = {
  packet?:
    | 'not-this-packet'
    | 'ignore'
    | 'infer-blank'
    | {
        packetId: string
        pageIndex?: number // todo: server only (scan image)
        pageIndices?: FixitPageIndices // todo: client only (sheet)
        // todo: we have the packet on the client, so could include curriculumId/packetLength
      }
  rubric?: TicketRubric
  score?: {
    pts: number
  }
  student?:
    | 'not-this-roster'
    | 'no-student'
    | {
        studentId: string
        // todo: we have the student and section on the client, so could include those too...
      }
}

export type FixitChange = {
  actionSet: FixitActionSet
  batchId: string
  scanImageId: string
}

// todo: does student need an 'overriden' option?
export type FixitIDStatus = 'ignore' | 'no-qr' | 'not-this' | 'success'

export type FixitBubbleStatus = 'empty' | 'success' | 'unreadable'

export type FixitSheetBase = {
  _actionSet: FixitActionSet
  _dirty: boolean
  _review: boolean
  batchId: string
  id: string
  /** **not** `indexInItsBatch` */
  indexOfItsBatch: number
  scans: ScanImageAbbr[]
}

// todo: FixitSheet/Stu have slots for changes/working values
// todo: Might make more sense to split the values
export type FixitSheet = FixitSheetBase & {
  _fixedKeySet: Set<keyof ScanImageAbbr['data']>
  rubric: TicketRubric
  score: number
  status: {
    packet: FixitIDStatus
    rubric: FixitBubbleStatus
    score: FixitBubbleStatus
    student: FixitIDStatus
  }
  student: Student
}

export type FixitSheetRow = {
  id: string
  slots: { [K in FixitSlotType]?: FixitSlot }
}

export type FixitSlotType = 'packet' | 'student' | 'score' | 'rubric'

export type FixitSlot = {
  _dirty: boolean
  /** e.g. for now, we won't allow editing QR'd packet or student */
  _editable: boolean
  /** we will allow overriding score, but those won't be in the jump-to-review axis */
  _review: boolean
  /** true if has saved changes */
  _savedChanges: boolean
  id: `${string}.${FixitSlotType}`
  rubric: TicketRubric
  score: number
  sheetIndex: number
  sheetId: string
  status: FixitIDStatus | FixitBubbleStatus
  student: Student
  type: FixitSlotType
}

export type FixitRosterItem = {
  _dirty: boolean
  _vacated: boolean
  rubric: TicketRubric
  score: number
  sheets: string[][] // may eventually want to status-ize this...
  section: { id: string; name: string }
  student: Student
}
