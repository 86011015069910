import * as Yup from 'yup'
import { z } from 'zod'

export { Yup }

// todo: (?) Does this need to be a function
export const yupMin0Int = Yup.number().integer().min(0)

export function zenum<S extends string>(
  values: [S, ...S[]] | readonly [S, ...S[]]
): z.ZodEnum<[S, ...S[]]> {
  return z.enum<S, [S, ...S[]]>(values as [S, ...S[]])
}

//////////////////////////////////
// A version of toZod that ignore nulls, and sort of allows enums
// https://github.com/rhinodavid/tozod/blob/david/pr/src/index.ts
//////////////////////////////////
type isAny<T> = [any extends T ? 'true' : 'false'] extends ['true']
  ? true
  : false
type nonoptional<T> = T extends undefined ? never : T
type nonnullable<T> = T extends null ? never : T
type equals<X, Y> = [X] extends [Y] ? ([Y] extends [X] ? true : false) : false

type ignoreNull<Z extends z.ZodTypeAny> =
  | Z
  | z.ZodDefault<Z>
  | z.ZodNullable<Z>
  | z.ZodOptional<Z>

export type toZod<T> = {
  any: never
  // optional: z.ZodOptional<toZod<nonoptional<T>>>
  // nullable: z.ZodNullable<toZod<nonnullable<T>>>
  array: T extends Array<infer U>
    ? z.ZodArray<toZod<U>> | z.ZodDefault<z.ZodArray<toZod<U>>>
    : never
  string: ignoreNull<z.ZodString> //z.ZodString | z.ZodDefault<z.ZodString>
  bigint: ignoreNull<z.ZodBigInt> //z.ZodBigInt | z.ZodDefault<z.ZodBigInt>
  number: ignoreNull<z.ZodNumber> //z.ZodNumber | z.ZodDefault<z.ZodNumber>
  boolean: ignoreNull<z.ZodBoolean> //z.ZodBoolean | z.ZodDefault<z.ZodBoolean>
  date: ignoreNull<z.ZodDate> //z.ZodDate | z.ZodDefault<z.ZodDate>
  object: z.ZodObject<{ [k in keyof T]: toZod<T[k]> }>
  rest: T extends string ? z.ZodEnum<any> : never // todo: how to handle enum properly?
}[zodKey<T>]

type zodKey<T> = isAny<T> extends true
  ? 'any'
  : equals<T, boolean> extends true //[T] extends [booleanUtil.Type]
  ? 'boolean'
  : // : [undefined] extends [T]
  // ? 'optional'
  // : [null] extends [T]
  // ? 'nullable'
  T extends any[]
  ? 'array'
  : equals<T, string> extends true
  ? 'string'
  : equals<T, bigint> extends true //[T] extends [bigintUtil.Type]
  ? 'bigint'
  : equals<T, number> extends true //[T] extends [numberUtil.Type]
  ? 'number'
  : equals<T, Date> extends true //[T] extends [dateUtil.Type]
  ? 'date'
  : T extends { [k: string]: any } //[T] extends [structUtil.Type]
  ? 'object'
  : 'rest'

type toZodBase<T extends object> = toZod<T> extends z.ZodObject<
  infer U,
  any,
  any
>
  ? U
  : never

/**
 * @deprecated zod doesn't like strictNullChecks=false, and doesn't seem to work great with predefined types
 * I didn't realize the extent of the issues until a bunch of this was built
 * Maybe makes sense to switch these to yup 0.30 (yup 1.1 has the same issues) or enable strictNullChecks everywhere
 * 
 * This solution may lose some generic correctness @see https://github.com/colinhacks/zod/issues/372#issuecomment-826380330
 * Also without strictNullChecks, we lose optional-ness in returned types
 * e.g. `z_student.omit('firstName').parse({ ... }) // all remaining fields become optional
 * 
 * Potential benefits include:
 *  * editor hints for keys of the type `T`
 *  * `.parse` returns `T` as opposed to `{ list, of, individual, fields }`
 * 
 * @example
 * export const z_student = zw<Student>({
    firstName: z_nonempty,
    grade: z_nonempty,
    id: z_nonempty,
    lastfirst: z_nonempty,
    lastName: z_nonempty,
    number: z_nonempty,
  })
 */
export const zw = <T extends object>(shape: toZodBase<T>) => {
  let result = z.object(shape)
  return result as typeof result extends z.ZodObject<
    infer RawShape extends z.ZodRawShape,
    infer UnknownKeys,
    infer CatchAll,
    any,
    infer Input
  >
    ? z.ZodObject<RawShape, UnknownKeys, CatchAll, T, Input>
    : never
}
