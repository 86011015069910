import { useEffect, useRef, useState } from 'react'

/**
 * @deprecate maybe...
 */
export const useDumbHeightForCSSAnimation = (
  unexpandedHeight: string | number,
  isSelected: boolean
) => {
  const [height, setHeight] = useState(unexpandedHeight)
  const rootRef = useRef<HTMLElement>()
  useEffect(() => {
    let unmounted = false
    // Set height to scrollHeight if selected
    if (isSelected) {
      setHeight(`${rootRef.current.scrollHeight}px`)
    } else if (height != unexpandedHeight) {
      setHeight(unexpandedHeight)
    }
    return () => {
      unmounted = true
    }
  }, [isSelected])

  return { height, rootRef }
}

/**
 * @deprecated maybe...
 */
export const useHorribleFadeIn = (isDataReady: boolean) => {
  // Horrible fadeIn hack
  const [opacity, setOpacity] = useState(0)
  useEffect(() => {
    let unmounted = false
    if (isDataReady) {
      setOpacity(0)
      window.requestAnimationFrame(() => !unmounted && setOpacity(1))
    }
    return () => {
      unmounted = true
    }
  }, [isDataReady])
  return opacity
}

type UseHackyFadeProps = {
  delay?: number
  duration?: number
  enabled: boolean
  isActive: boolean
}
/**
 * Returns `opacity`, `mounted` state, and `transition` to fade in an element
 */
export function useHackyFade(props: UseHackyFadeProps) {
  const { delay = 0, duration = 750, enabled, isActive } = props
  const [{ mounted, opacity }, setFade] = useState({
    mounted: isActive,
    opacity: enabled ? 0 : 1,
  })

  useEffect(() => {
    let unmounted = false
    // when isActive changes, we always set/keep { mounted: true } and { opacity: 0 }
    setFade({ mounted: true, opacity: enabled ? 0 : 1 })
    if (!isActive) {
      // if we're going to inactive, set opacity to 0 (transition takes care of animation)
      // then unmount after `fadeTime`
      setFade({ mounted: true, opacity: 0 })
      setTimeout(
        () => !unmounted && setFade({ mounted: false, opacity: undefined }),
        duration
      )
    } else if (enabled) {
      // going to active, opacity needs to be zero
      setFade({ mounted: true, opacity: 0 })
      // then set to 1 on next tick
      requestAnimationFrame(
        () => !unmounted && setFade({ mounted: true, opacity: 1 })
      )
    }

    return () => {
      unmounted = true
    }
  }, [isActive])

  return {
    mounted,
    opacity,
    fadeTransition: `opacity ${duration}ms ${delay}ms ease-in`,
  }
}
