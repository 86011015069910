import { Box, HTMLChakraProps } from '@chakra-ui/react'
import { IcoImage, IcoZoomOut } from '@paper/icons'
import { Margin, Padding, Pos, Variant } from '@paper/schema'
import { TheBoxShadow } from '@paper/styles'
import { ReactNode } from 'react'
import { PdfViewer } from '~src/blocks/pdfViewer'
import { HStack, ToggleGroup, ToggleRootProps } from '~src/components'
import { useBlobUrl } from '~src/utils/blob'
import { pxx } from '~src/utils/layout'
import { FieldUI } from '../formHelpers'
import { Wiz } from '../publishLayout'
import type { ContentFormSchema } from './entryBaseData'
import { usePdfPreview } from './usePdfPreview'
import { useWizContext } from './wizProvider'

const defaultToggleProps: Omit<
  ToggleRootProps,
  'children' | 'onChange' | 'value'
> = {
  colorScheme: 'blue',
  preventNone: true,
  shape: '90deg',
  size: 'sm',
  type: 'single',
}

const btnHeightPx = 40
const btnHeight = `${btnHeightPx}px`

export function FormQRLabel() {
  const { formik } = useWizContext()
  const { setFieldValue, values } = formik
  const { style } = formik.values

  const fields = [
    { name: 'position', Component: PositionInput },
    { name: 'variant', label: 'size', options: Variant, Component: EnumInput },
    { name: 'margin', unit: '"', options: Margin, Component: EnumInput },
    { name: 'padding', unit: '"', options: Padding, Component: EnumInput },
  ]

  return (
    <Wiz.FormArea as="section" display="flex" overflowX="auto">
      <HStack alignItems="stretch" flexGrow={1} gap={12}>
        <Box
          alignSelf="start"
          display="grid"
          gridColumnGap={12}
          gridRowGap={12}
          gridTemplateColumns="1fr 1fr"
          justifyItems="center"
        >
          {fields.map(({ name, label, options, unit, Component }) => {
            const onChange = (value) => setFieldValue(`style.${name}`, value)
            return (
              <FieldUI
                key={name}
                data-cy={`field-${name}`}
                alignItems="center"
                display="flex"
                flexDir="column"
                label={label || name}
                input={
                  <Component
                    onChange={onChange}
                    options={options}
                    unit={unit}
                    value={style[name]}
                  />
                }
              />
            )
          })}
        </Box>
        <SplitPdfViewer {...values} />
      </HStack>
    </Wiz.FormArea>
  )
}

type SplitPdfViewerProps = Pick<ContentFormSchema, 'pages' | 'style'>

function SplitPdfViewer(props: SplitPdfViewerProps) {
  const { pages, style } = props
  const qResult = usePdfPreview({ isQRPreview: true })
  const pdfUrl = useBlobUrl(qResult.data)

  // hardcoding the height enables absolute positioning to work
  const isTopOfPage = style.position === Pos.tl || style.position === Pos.tr
  const isRightOfPage = style.position === Pos.tr || style.position === Pos.br
  const PreviewWidth = 550
  const PreviewHeight = (PreviewWidth * 11) / 8.5

  const pdfViewerStyleProps: HTMLChakraProps<'iframe'> = {
    bottom: isTopOfPage ? undefined : 0,
    height: `${PreviewHeight}px`,
    right: isRightOfPage ? 0 : undefined,
    position: 'absolute',
    width: `${PreviewWidth}px`,
  }

  let pagesToShow = pages.length > 1 ? [1, 2] : [1]
  let height = pagesToShow.length > 1 ? 200 : PreviewHeight

  // Show parts of two pages
  return (
    <Box
      alignContent="start"
      display="grid"
      gap={8}
      flexGrow={1}
      minWidth="440px"
    >
      {pagesToShow.map((pageNum, idx) => (
        <Box
          key={idx}
          // TODO: Copy/pasted from viewer.css
          background="#edf2f7;"
          boxShadow={TheBoxShadow}
          height={height}
          overflow="hidden"
          pos="relative"
          width={PreviewWidth}
        >
          <PdfViewer
            {...pdfViewerStyleProps}
            id={`wizard${idx ? idx + 1 : ''}`}
            pageIndexPlus1={pageNum}
            url={pdfUrl}
          />
        </Box>
      ))}
    </Box>
  )
}

type PositionInputProps = {
  onChange(pos: Pos): void
  value: any
  variant?: 'packetentry' | 'scanlog'
}

export const PositionInput = (props: PositionInputProps) => {
  const { onChange, value, variant } = props

  let thumb: ReactNode
  // thumbnail option for scanlog
  if (variant === 'scanlog') {
    const thumbWidthFactor = 0.9
    const thumbWidth = btnHeightPx * thumbWidthFactor
    const thumbHeight = (thumbWidth * 11) / 8.5

    thumb = (
      <ToggleGroup.Icon
        aria-label="Thumbnail"
        bg={!value ? null : 'white'}
        icon={<IcoImage />}
        key="thumb"
        value=""
        position="absolute"
        left={pxx(btnHeightPx - thumbWidth / 2)}
        top={pxx(btnHeightPx - thumbHeight / 2)}
        height={pxx(thumbHeight)}
        width={pxx(thumbWidth)}
      />
    )
  }

  return (
    <ToggleGroup.Root
      {...defaultToggleProps}
      preventNone={
        variant === 'scanlog' ? false : defaultToggleProps?.preventNone
      }
      onChange={onChange as any}
      value={value}
    >
      <Box
        display="grid"
        gridAutoRows="max-content"
        gridTemplateColumns="max-content max-content"
        position="relative"
      >
        {Object.values(Pos).map((value) => (
          <ToggleGroup.Button
            aria-label={value}
            key={value}
            value={value}
            h={btnHeight}
            w={btnHeight}
          >
            {variant !== 'scanlog' && value}
          </ToggleGroup.Button>
        ))}
        {thumb}
      </Box>
    </ToggleGroup.Root>
  )
}

function EnumInput({ onChange, options, unit = '', value }) {
  return (
    <ToggleGroup.Root {...defaultToggleProps} onChange={onChange} value={value}>
      <HStack sx={{ '>:not(:first-of-type)': { mr: '-1px' } }}>
        {Object.values(options).map((value: any) => (
          <ToggleGroup.Button
            key={value}
            value={value}
            minWidth={btnHeight}
            height={btnHeight}
          >
            {value}
            {unit}
          </ToggleGroup.Button>
        ))}
      </HStack>
    </ToggleGroup.Root>
  )
}
