/**
 * Increments `obj[key] by `amt` if exists, or initializes to `amt`
 */
export const incrementOrInit = (obj: object, key: string, amt = 1) => {
  if (!(key in obj)) {
    obj[key] = 0
  }
  obj[key] += amt
  return obj[key]
}

/**
 * Increments `map[key]` by `amt` if exists, or initializes to `amt`
 */
export function incrOrInitMap<T>(map: Map<T, number>, key: T, amt = 1) {
  let value = (map.get(key) ?? 0) + amt
  map.set(key, value)
  return value
}

export function counterMap<T>(initialKeys?: T[]) {
  let counts = new Map<T, number>(initialKeys?.map((key) => [key, 0]))
  return {
    counts,
    increment: (key: T, amt = 1) => {
      incrOrInitMap(counts, key, amt)
    },
  }
}

export function setDefault<T>(
  obj: Record<string | number, T> | T[],
  key: string | number | symbol,
  defaultValue: T
): T {
  if (!(key in obj)) {
    obj[key] = defaultValue
  }
  return obj[key]
}

export type ObjOf<S> = { [key in string | number]: S }

export type KeysMatching<T, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never
}[keyof T]

export function pushDefault<
  O extends Record<string | number, any>,
  K extends KeysMatching<O, any[]>,
>(obj: O, key: K, value: O[K][number]): O[K][number][] {
  const arr = setDefault(obj, key, [])
  arr.push(value)
  return arr
}

export function addToSetDefault<T>(
  obj: ObjOf<Set<T>>,
  key: string | number,
  value: T
): Set<T> {
  const set = setDefault(obj, key, new Set<T>())
  set.add(value)
  return set
}

export function setDefaultMap<V, K = string>(
  map: Map<K, V>,
  key: K,
  value: V | (() => V)
): V {
  if (!map.has(key)) {
    // @ts-expect-error
    map.set(key, typeof value === 'function' ? value() : value)
  }
  return map.get(key)
}

export function pushToMap<T, K = string>(
  map: Map<K, T[]>,
  key: K,
  value: T
): T[] {
  const arr = setDefaultMap(map, key, [])
  arr.push(value)
  return arr
}

export function addToMapSetDefault<T, K = string>(
  map: Map<K, Set<T>>,
  key: K,
  value: T
): Set<T> {
  const set = setDefaultMap(map, key, new Set())
  set.add(value)
  return set
}

/**
 * Returns a map of arrays per key
 */
export function arrayMapify<T, K = string>(
  array: T[],
  keyer: (item: T, idx: number) => K
) {
  const map = new Map<K, T[]>()
  array.forEach((item, idx) => pushToMap(map, keyer(item, idx), item))
  return map
}
