import { Grid, Tag, TagProps } from '@chakra-ui/react'
import type { CrunchBatch, ScanStatus } from '@paper/schema'
import { DEFAULT_FG, GRAY_HAS_SCANS } from '@paper/styles'
import { PAPER_BOT_USER, XOR } from '@paper/utils'
import { sumBy } from 'lodash'
import { ReactNode } from 'react'
import { StatTagProps } from '~src/components'

export function scanStatusToColor(status: ScanStatus) {
  if (!status) {
    return null
  }

  switch (status) {
    case 'infer':
      return 'scanFixedGray'
    case 'infer-gap':
      return 'scanFixedGray'
    case 'manual':
    case 'ignore':
      return 'manualYellow'
    case 'success':
      return 'scanPresentGray'
    case 'infer-blank':
    case 'not-paper-qr':
      return 'gray'
    default:
      return 'scanMissingRed'
  }
}

type StatKey = keyof CrunchBatch['stats']
type ProtoStat = { keys: StatKey[]; label: ReactNode } & XOR<
  { colorScheme: string },
  { bg: string; color?: string }
>

function mapProtoStats<T>(mapper: (proto: ProtoStat, idx: number) => T): T[] {
  let protoStats: ProtoStat[] = [
    {
      keys: ['infer-blank', 'not-paper-qr'],
      label: 'Not from Paper',
      bg: 'white',
      color: 'gray.300',
    },
    { keys: ['success'], label: 'QR success', bg: GRAY_HAS_SCANS },
    {
      keys: ['infer', 'infer-gap'],
      label: `Inferred by ${PAPER_BOT_USER}`,
      colorScheme: scanStatusToColor('infer'),
    },
    {
      keys: ['error', 'pending', 'wrong-location'],
      label: 'Error',
      bg: 'black',
      color: 'white',
    },
    {
      keys: ['no-qr'],
      label: 'QR not readable',
      colorScheme: 'scanMissingRed',
    },
    // todo: extendTheme doesn't seem to be working
    {
      keys: ['ignore', 'manual'],
      label: 'Set by a person',
      bg: 'manualYellow.500',
      color: DEFAULT_FG,
    },
  ]
  return protoStats.map(mapper)
}

export function getScanlogStatProps(batch: CrunchBatch): StatTagProps[] {
  return mapProtoStats(({ keys, ...rest }) => ({
    ...rest,
    count: sumByNull(batch, keys),
  }))
}

function sumByNull(batch: CrunchBatch, keys: StatKey[]) {
  return sumBy(keys, (k) => batch.stats[k] ?? 0)
}

type ScanlogStatsLegendProps = {}

export function ScanlogStatsLegend(props: ScanlogStatsLegendProps) {
  // todo: copy/paste from StatTag/and its column

  return (
    <Grid
      color={DEFAULT_FG}
      gap={1}
      gridTemplateColumns="repeat(3, 1fr)"
      gridTemplateRows="repeat(2, auto)"
      p={4}
    >
      {mapProtoStats(({ bg, color, colorScheme, label }, idx) => {
        // todo: workaround for extra customization...ideally would have everything colorSchemed/varianted
        let colorProps: TagProps = colorScheme
          ? { colorScheme }
          : { bg, color: color ?? 'unset' }

        return (
          <Tag
            {...colorProps}
            key={idx}
            justifyContent="center"
            minWidth="unset"
            p={1}
            pointerEvents="none"
            size="sm"
            textAlign="center"
            userSelect="none"
            variant="solid"
          >
            {label}
          </Tag>
        )
      })}
    </Grid>
  )
}
