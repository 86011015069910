import { ListItem, UnorderedList } from '@chakra-ui/react'

// todo: this name, but i am tired
export function InferCaveats() {
  return (
    <UnorderedList>
      <ListItem>
        Paper was unable to read the QR code, but this image was assigned to
        this page via context clues.
      </ListItem>
      <Unusual />
    </UnorderedList>
  )
}

function Unusual() {
  return (
    <>
      <ListItem>
        This might not be right if something unusual happened, for example, if
        pages got stuck together during scanning.
      </ListItem>
      <ListItem>You can correct it as needed in the scan log.</ListItem>
    </>
  )
}
