import { Button } from '@chakra-ui/react'
import { IcoHome } from '@paper/icons'
import { ActiveRoute, createRouter, useRouter } from '@paper/route'
import { useMemo } from 'react'
import { Error404Page } from './blocks/errors'
import { getEAProps } from './blocks/errors/errorUtils'
import { Routes } from './routelist'
import { SchoolYearAirlock } from './schoolYearAirlock'

export function Root() {
  const Router = useMemo(() => {
    // todo: was considering injecting a default yearCode here...
    //const defaultParams = { yearCode: currentYearCode }
    return createRouter(Routes)
  }, [])
  return (
    <Router notFound={(error) => <Root404 />}>
      <SchoolYearAirlock>
        <ActiveRoute />
      </SchoolYearAirlock>
    </Router>
  )
}

function Root404() {
  const { dispatchRoute } = useRouter()
  return (
    <Error404Page
      actions={
        <Button
          {...getEAProps(<IcoHome />)}
          onClick={() => dispatchRoute(Routes.root.mergeAction())}
        >
          Home
        </Button>
      }
    />
  )
}
