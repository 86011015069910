import { Button } from '@chakra-ui/react'
import { IcoArrowBack } from '@paper/icons'
import { HTTPError } from '@paper/utils'
import { ReactNode } from 'react'
import { ErrorActionSection, ErrorPage } from './errorPageBase'
import { getEAProps } from './errorUtils'

type FetchErrorProps = {
  error?: any
  reset?: () => void
}

/**
 * Very rough draft for generic fetch error handling
 * At the moment, this is a fallback for 'unexpected' errors
 * Expected errors (e.g. curriculum and packet 404s) are currently handled separately
 * As we see more errors in the wild, we'll want more helpful handling (like the 404s),
 * either with more specific logic here or handling them outside.
 * todo: very copy/paste-y from Error404
 */
export function FetchErrorPage(props: FetchErrorProps) {
  let error = props.error as HTTPError & { friendly?: string }

  let subject = `🧑‍💻 📡 👹 🕹️ 💥`
  if (error.response?.status) {
    subject += ' ' + error.response.status
  }

  const verb = props.reset ? 'submitting to' : 'grabbing data from'

  let actions: ReactNode
  if (props.reset) {
    actions = (
      <ErrorActionSection
        message={`❓ If it might be bad connection on your side, you can try again.`}
      >
        {window.history.length && (
          <Button
            data-cy="error-back"
            {...getEAProps(<IcoArrowBack />)}
            onClick={() => props.reset()}
          >
            Go back
          </Button>
        )}
      </ErrorActionSection>
    )
  } else {
    // todo: what are the actions for non-submit...go back? refresh the page?
  }

  return (
    <ErrorPage
      actions={actions}
      contactSectionMsg={`🐛 Otherwise you probably encountered a bug!`}
      defaultFriendly={`Something went wrong ${verb} Paper`}
      error={error}
      subject={subject}
    />
  )
}
