import type { LastUpdate, Xpage, XpageSW } from '@paper/schema'
import { pareUndefined } from './sortKeys'

/**
 * Returns the resolved image for the page taking into account overrides
 */
export const resolveXpageImage = (xpage: Xpage): XpageSW => {
  return resolveOverride(
    xpage.movedIn ?? xpage.movedOut ?? xpage.fix ?? xpage.imgp,
    // todo: kinda messy
    xpage.movedIn
      ? 'move-in'
      : xpage.movedOut
      ? 'move-out'
      : xpage.fix
      ? 'fix'
      : null
  )
}

/**
 * Returns image key that can be added to the `destination.moveIn` when moving a page
 * @note This currently returns null if the page has already been moved in or out.
 * You shouldn't be able to move an image to two locations, and at this point, if you're trying to move and move again, then something has gone of the rails
 */
export const resolveXpageImageForMove = (page: Xpage): string => {
  return page.movedOut || page.movedIn ? null : page.fix?.key ?? page.imgp?.key
}

/**
 * Return true if the page has any override (fix, move)
 */
export const isXpageOverriden = (xpage: Xpage): boolean => {
  return !!(xpage.movedIn ?? xpage.movedOut ?? xpage.fix)
}

/**
 * True if the page has images ignoring moves in or out
 * This is used to determine whether an unnamed packet is empty due to moving vs. no scans
 */
export const doesXpageHavePreMoveImages = (xpage: Xpage): boolean => {
  return !!(xpage.fix?.key ?? xpage.imgp?.key)
}

const resolveOverride = (
  override: { by?: LastUpdate; key?: string },
  type?: XpageSW['_overrideType']
): XpageSW => {
  return pareUndefined({
    _overridenBy: override?.by,
    _overrideType: type,
    key: override?.key ?? null,
  }) as XpageSW
}
