import { listenWindow } from '@paper/route/src/utils'
import { debounce } from 'lodash'
import { RefObject, useEffect, useLayoutEffect, useRef, useState } from 'react'
/**
 * Measures the scrollWidth of an element via resize observer
 * @deprecated Less broken but not sure it's right! See #100, #227
 */
export const useMeasureWidth = () => {
  const [width, setWidth] = useState<number>()
  const widthRef = useRef<HTMLElement>()
  const widthValueRef = useRef({ value: null, ignoreNext: false })

  useResizeObserver(
    widthRef,
    // todo: Mitigate #227, debounce resize events, and
    // ...assume next event after setting is due to setting, and thus ignore it instead of re-measuring, to prevent flash
    debounce((el) => {
      let nextWidth = el.scrollWidth
      if (widthValueRef.current.ignoreNext) {
        widthValueRef.current.ignoreNext = false
      } else if (widthValueRef.current.value !== nextWidth) {
        widthValueRef.current = { value: nextWidth, ignoreNext: true }
        setWidth(nextWidth)
      }
    }, 10)
  )
  return { width, widthRef }
}

// todo: somewhat copy/pasted from useVirtualGrid
function useResizeObserver<T extends HTMLElement>(
  ref: RefObject<T>,
  onChange: (element: T) => void
) {
  useLayoutEffect(() => {
    let unmounted = false
    let el = ref.current
    const ro = new ResizeObserver(() => !unmounted && onChange(el))
    ro.observe(el)
    return () => {
      ro.disconnect()
      unmounted = true
    }
  }, [ref.current])
}

const getWindowSize = () => ({
  height: window.innerHeight,
  width: window.innerWidth,
})

export function useWindowSize() {
  const [value, setValue] = useState(getWindowSize)
  useEffect(() => {
    return listenWindow('resize', () => setValue(getWindowSize()))
  }, [])

  return value
}
