import {
  IcoDiscountIlluminate,
  IcoExperimental,
  IcoHomeSchool,
  IcoPacket,
  IcoSetup,
} from '@paper/icons'
import { RouteMeta } from '@paper/route'
import { WebappUser } from '@paper/schema'
import { ReactNode } from 'react'
import { IcoScanIssue } from '~src/blocks/icoScanIssue'
import { RouterNavLink, RouterNavLinkProps } from '~src/blocks/menu/navLink'
import { IcoTeacher } from '~src/blocks/swMenu/swMenuIcons'
import { useUser } from '~src/blocks/userProvider'
import { HSep, TextStack, Txt } from '~src/components'
import { IcoQRSticker } from '~src/pages/sticker/stickerIcon'
import { Routes } from './routelist'

let linkMap: Map<RouteMeta, Omit<RouterNavLinkProps, 'route'>>

export function getLinkMap(user: WebappUser) {
  if (!linkMap) {
    linkMap = new Map([
      [Routes.home_teacher, { children: 'Teacher', icon: <IcoTeacher /> }],
      [Routes.home_curriculum, { children: 'Curriculum', icon: <IcoPacket /> }],
      [Routes.home_school, { children: 'School', icon: <IcoHomeSchool /> }],
      [
        Routes.illuminateLookup,
        {
          children: <ExperimentalLink title="Illuminate answer key lookup" />,
          icon: <IcoDiscountIlluminate />,
        },
      ],
      [Routes.setup_admin, { children: 'Admin setup', icon: <IcoSetup /> }],
      [
        Routes.setup_curriculum,
        { children: 'Curriculum setup', icon: <IcoSetup /> },
      ],
      [
        Routes.internal_scanviz,
        {
          children: <ExperimentalLink title="Scanviz" />,
          icon: <IcoExperimental />,
        },
      ],
      [
        Routes.setup_school,
        {
          children: 'School setup',
          icon: <IcoSetup />,
          data: { yearCode: 'current-year' }, // todo: so school setup needs to go to the loadYear, not swYear
        },
      ],
      [Routes.sw_scanlog, { children: 'Scan log', icon: <IcoScanIssue /> }],
      [
        Routes.sticker,
        {
          children: <ExperimentalLink title="Generate stickers" />,
          icon: <IcoQRSticker />,
        },
      ],
    ])
  }
  return linkMap
}

type ExperimentalLinkProps = { title: ReactNode }

function ExperimentalLink(props: ExperimentalLinkProps) {
  return (
    <TextStack>
      <TextStack.Top>{props.title}</TextStack.Top>
      <TextStack.Bottom
        fontStyle="italic"
        gap={1}
        opacity={0.8}
        pr={1} // italics gets cutoff without this...
      >
        Experimental
      </TextStack.Bottom>
    </TextStack>
  )
}

export const getRouteNavLink = (route: RouteMeta, user: WebappUser) => {
  const props = getLinkMap(user).get(route)
  return (
    <RouterNavLink
      key={route.path}
      route={route}
      data={props?.data}
      children={props?.children ?? route.path}
      icon={props?.icon}
    />
  )
}

export type NavListSectionProps = {
  title?: string
  children: ReactNode
  noBorder?: boolean
  suffix?: ReactNode
}

export function NavListSection(props: NavListSectionProps) {
  const { children, noBorder, title } = props
  return (
    <>
      {title && (
        <Txt as="h3" fontSize="xs" px={2} userSelect="none">
          {title}
        </Txt>
      )}
      {children}
      {!noBorder && <HSep boxProps={{ mx: 2, my: 1 }} />}
    </>
  )
}

type RouteNavListSectionProps = { routes: RouteMeta[] } & Omit<
  NavListSectionProps,
  'children'
>

export function RouteNavListSection(props: RouteNavListSectionProps) {
  const { routes, suffix, ...rest } = props
  const { user } = useUser()
  return (
    <NavListSection {...rest}>
      {routes.map((route) => getRouteNavLink(route, user))}
      {suffix}
    </NavListSection>
  )
}
