import { useControllableState } from '@chakra-ui/react'
import { APIs, BodyOf } from '@paper/api-specs'
import { PdfGrain, SchoolPacketGroup } from '@paper/schema'
import { getPartLengths, upToEven, XOR } from '@paper/utils'
import { sumBy } from 'lodash'
import {
  createContext,
  ReactNode,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { useApiQuery } from '~src/data/useApiQuery'
import { useSchoolYearContext } from '~src/schoolYearAirlock'
import rollbar from '~src/utils/rollbar'
import { useUser } from '../userProvider'
import {
  PdfBuilderInputItem,
  PdfBuilderPreprintable,
  PdfBuilderPrintable,
  usePdfBuilderPreprintables,
} from './data-pdfBuilder'
import { PdfOptionSets } from './pdfBuilderGrainPicker'

type SetState<T> = React.Dispatch<React.SetStateAction<T>>

type PdfStats = {
  isMultiPart: boolean
  isStapled: boolean
  pageCounts: number[]
  partLengths: number[]
  pdfCount: number
  sectionCount: number
  studentCount: number
  xpacketCount: number
}

export type PdfBuilderParent = 'school' | 'teacher'

type PdfBuilderContext = {
  generate(packet: PdfBuilderPrintable, isExperimental?: boolean): Promise<void>
  grain: PdfGrain
  grainId: string
  hasDownloads: boolean
  hasInflight: boolean
  isDisabled: boolean
  isExperimental: boolean
  isLoading: boolean
  nextPacket(): void
  packetGroup: SchoolPacketGroup
  parentView: PdfBuilderParent
  pdfList: PdfBuilderPreprintable[]
  schoolId: string
  selected: PdfBuilderPrintable
  setGrain: SetState<PdfGrain>
  setGrainId: SetState<string>
  stats: PdfStats
  unnamedPerSection: number
}

const PdfBuilderContext = createContext<PdfBuilderContext>(null)
export const usePdfBuilderContext = () => useContext(PdfBuilderContext)

type PdfBuilderProviderProps = {
  children: ReactNode
  grain?: PdfGrain
  isActive: boolean
  packetGroup: SchoolPacketGroup
  parentView: PdfBuilderParent
  schoolId: string
} & XOR<
  // todo: this is messy, but trying to reuse code between school and teacher views
  { parentView: 'teacher'; data: PdfBuilderInputItem },
  { parentView: 'school'; data: PdfBuilderInputItem[] }
>

export function PdfBuilderProvider(props: PdfBuilderProviderProps) {
  const { data, isActive, packetGroup, parentView, schoolId } = props
  const { fetchAs } = useUser()
  const { isCurrentYear } = useSchoolYearContext()
  const unnamedPerSection = 3

  const [grainId, setGrainId] = useState<string>()
  const [grain, setGrain] = useControllableState<PdfGrain>({
    value: props.grain,
    defaultValue: 'teacher',
  })

  // todo: hmm, add useRoster here?...we have multiple qResults
  // todo: also need to make download urls available for the teacher version
  const { hasDownloads, hasInflight, pdfList, qResultStatus, updateLocal } =
    usePdfBuilderPreprintables({ input: data, grain, schoolId })

  ///////////////////////////////
  // get seleted and toggler
  const { nextPacket, selectedPre } = useMemo(() => {
    const selectedIndex = pdfList.findIndex((p) => p.grainId === grainId)
    const selectedPre = pdfList[selectedIndex]

    const nextId = pdfList[selectedIndex + 1]?.grainId
    const nextPacket = () => {
      if (nextId) {
        setGrainId(nextId)
      }
    }

    return { nextPacket, selectedPre }
  }, [grainId, pdfList])

  ///////////////////////////////
  // select first
  useLayoutEffect(() => {
    // select first
    if (isActive && !selectedPre && pdfList?.length) {
      setGrainId(pdfList[0].grainId)
    }
  }, [isActive, pdfList, selectedPre])

  ///////////////////////////////
  // Grab sections from server
  const qResultRoster = useRoster(schoolId, isActive && selectedPre)

  ///////////////////////////////
  // Merge on seletions and calculate stats
  const { selected, stats } = useMemo<{
    selected: PdfBuilderPrintable
    stats: PdfStats
  }>(() => {
    if (!qResultRoster.isSuccess) {
      return {
        selected: selectedPre ? { ...selectedPre, sections: null } : null,
        stats: null,
      }
    }

    const sections = qResultRoster.data
    const selected = { ...selectedPre, sections }

    // calculate select packet stats
    const partLengths = getPartLengths(selected.packet)
    const sectionCount = sections.length
    const studentCount = sumBy(sections, (s) => s.students.length)
    const xpacketCount = studentCount + unnamedPerSection * sectionCount
    const pdfCount = partLengths.length
    const isMultiPart = pdfCount > 1
    const isStapled = partLengths.some((partLength) => partLength > 2)
    const pageCounts = partLengths.map(
      (partLength) => upToEven(partLength) * xpacketCount
    )

    const stats: PdfStats = {
      isMultiPart,
      isStapled,
      pageCounts,
      partLengths,
      pdfCount,
      sectionCount,
      studentCount,
      xpacketCount,
    }

    return { selected, stats }
  }, [selectedPre, qResultRoster.data, qResultRoster.isSuccess])

  const isExperimental =
    selected?.grain === 'teacher' && selected?.packet.type === 'ticket'

  const generate = async (
    printable: PdfBuilderPrintable,
    isExperimental?: boolean
  ) => {
    // set local status to in-progress
    updateLocal.onStart(printable.grainId, new Date())

    // fire off generate
    const payload: BodyOf<'print.generate'> = {
      grain: isExperimental ? 'generic' : printable.grain,
      grainId: printable.grainId,
      noTrailingBlanks: false,
      packetId: printable.packet.id,
      sections: printable.sections,
      sortedBy: PdfOptionSets[printable.grain].sortedBy,
      unnamedPerSection: isExperimental ? 0 : unnamedPerSection,
    }

    // todo: can i use useMutation?
    APIs['print.generate']
      .fetch({ fetchAs, queryVars: { body: payload } })
      .then((json) => updateLocal.onSuccess(json.manifest.grainId, json))
      .catch((err) => {
        rollbar.error(err) // log to rollbar, since not entirely sure what will cause errors in the wild
        updateLocal.onError(printable.grainId, err)
      })
    // go to next
    nextPacket()
  }

  //console.log('[PdfBuilderContext.selected]', selected)

  const ctx: PdfBuilderContext = {
    generate,
    grain,
    grainId,
    hasDownloads,
    hasInflight,
    isDisabled: !isCurrentYear || !data,
    isExperimental,
    isLoading: qResultRoster.isPending || qResultStatus.isPending,
    nextPacket,
    packetGroup,
    parentView,
    pdfList,
    schoolId,
    selected,
    setGrain,
    setGrainId,
    stats,
    unnamedPerSection,
  }

  return (
    <PdfBuilderContext.Provider value={ctx}>
      {isActive && props.children}
    </PdfBuilderContext.Provider>
  )
}

/**
 * Returns sections for curriculum + school + optionally teacher
 */
const useRoster = (schoolId: string, selected: PdfBuilderPreprintable) => {
  const qResult = useApiQuery({
    apiSpec: APIs['roster.get'],
    queryVars: {
      body: {
        curriculumId: selected?.curriculum?.id,
        schoolId,
        teacherId: selected?.teacher?.id,
      },
    },
    useQueryProps: { enabled: !!selected },
  })

  return qResult
}
