import { Button, ButtonProps, Icon, IconButtonProps } from '@chakra-ui/react'
import { BigIconFontSize } from '@paper/styles'

export function BigIconButton(
  props: IconButtonProps & Pick<ButtonProps, 'leftIcon' | 'rightIcon'>
) {
  const { as, ...rest } = props
  return (
    <Button flexShrink={0} px={1} size="lg" variant="ghost" {...rest}>
      <Icon as={as} fontSize={BigIconFontSize} />
    </Button>
  )
}
