import { Heading, List } from '@chakra-ui/react'
import { IcoMail } from '@paper/icons'
import { HTTPError } from '@paper/utils'
import { ReactNode } from 'react'
import { AppTitle, BLink, HStack, Txt, VStack } from '~src/components'
import { FullPageMessage } from '~src/components/status/fullPage'
import { useContactSupportLink } from '~src/utils/contactSupport'
import { getEAProps, useHttpErrorResponse } from './errorUtils'

type MaybeFriendly<T> = { friendly?: string } & T

type ErrorPageProps = {
  actions?: ReactNode
  actionsGoAfter?: boolean // haha
  contactSectionMsg: string
  'data-cy'?: string
  defaultFriendly: string
  error: MaybeFriendly<Error | HTTPError> | unknown
  subject: string
}

export function ErrorPage(props: ErrorPageProps) {
  const { actions, contactSectionMsg, subject } = props
  const error = props.error as MaybeFriendly<Error>
  const friendly = error?.friendly ?? props.defaultFriendly
  const response = useHttpErrorResponse(error) // todo: response should be a prop?
  const mailToProps = useContactSupportLink({ error, response, subject })

  return (
    <FullPageMessage
      isActive={true}
      title={
        <Heading as="h3" mb={2}>
          {subject}
        </Heading>
      }
      type="opaque"
    >
      <AppTitle title={subject} />
      <VStack
        alignItems="stretch"
        data-cy={props['data-cy'] ?? 'error-page'}
        gap={3}
        maxWidth="600px"
        overflowY="auto"
        p={1} // for outline
      >
        <Txt fontSize="2xl">{friendly}</Txt>
        <List size="md" spacing={6}>
          {!props.actionsGoAfter && actions}
          <ErrorActionSection message={contactSectionMsg}>
            <BLink {...getEAProps(<IcoMail />)} {...mailToProps}>
              Contact support
            </BLink>
          </ErrorActionSection>
          {props.actionsGoAfter && actions}
        </List>
      </VStack>
    </FullPageMessage>
  )
}

type ActionSectionProps = { children: ReactNode; message: ReactNode }
export function ErrorActionSection(props: ActionSectionProps) {
  const { children, message } = props
  return (
    <VStack as="li" alignItems="stretch" gap={1}>
      <Txt as="span">{message}</Txt>
      <HStack flexWrap="wrap" gap={4}>
        {children}
      </HStack>
    </VStack>
  )
}
