import { Box, Link } from '@chakra-ui/react'
import { IcoInfo } from '@paper/icons'
import { TabImageViewer } from '~src/blocks/imageViewer'
import { HStack, Txt, VStack } from '~src/components'
import { zdNamingGuide } from '~src/utils/useHelpLink'
import { SimpleTextField } from '../formHelpers'
import { Wiz } from '../publishLayout'
import { useWizContext } from './wizProvider'

export function FormPacketName() {
  const { formik, goNext, imageUrls } = useWizContext()

  const meta = formik.getFieldMeta('name')

  return (
    <Wiz.FormArea>
      <VStack alignItems="stretch" height="100%" maxWidth="600px">
        <SimpleTextField
          field={formik.getFieldProps('name')}
          inputProps={{
            autoFocus: true,
            // todo: this can't be the best way to go next on enter...
            onKeyPress: (event) => {
              if (event.code === 'Enter' && !meta.error) {
                goNext?.()
              }
            },
          }}
          label="Packet Name"
          meta={meta}
          helperText={
            <HStack>
              <Box as={IcoInfo} mr={1} />
              <Txt lineHeight="1.3em">
                Name is important!{' '}
                <Link
                  color="teal.500"
                  fontWeight={400}
                  href={zdNamingGuide}
                  isExternal={true}
                >
                  Check out our guide
                </Link>{' '}
                on what makes a good one.
              </Txt>
            </HStack>
          }
        />
        <TabImageViewer
          imageType="raw"
          pkt={formik.values}
          urls={imageUrls.raw}
        />
      </VStack>
    </Wiz.FormArea>
  )
}
