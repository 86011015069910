import { useRouter } from '@paper/route'
import { Curriculum, School } from '@paper/schema'
import { createContext, ReactNode, useContext } from 'react'
import { FullPageLoading } from '~src/components/status'
import { RD_Setup_School } from '~src/routelist'
import { useSchoolSetupBaseData } from './data-schoolSetup'

type SchoolSetupContext = {
  activeCurricula: Curriculum[]
  activeFamilyEdMap: Map<string, Curriculum[]>
  allSchools: School[]
  curriculumMap: Map<string, Curriculum>
  school: School
  syId: number
}
const SchoolSetupContext = createContext<SchoolSetupContext>(null)

export const useSchoolSetupContext = () => useContext(SchoolSetupContext)

type SchoolSetupProviderProps = { children: ReactNode }

export function SchoolSetupProvider(props: SchoolSetupProviderProps) {
  const { routeData, useAirlock } = useRouter<RD_Setup_School>()

  const baseQResult = useSchoolSetupBaseData()

  const allSchools = baseQResult.data?.schools
  const syId = baseQResult.data?.syId
  const school = allSchools?.find((p) => p.id === routeData.schoolId)
  // todo: 404 invalid schoolId

  // Select the first school by default
  let firstSchoolId = allSchools?.[0].id
  useAirlock(
    { schoolId: firstSchoolId },
    baseQResult.isSuccess && !routeData.schoolId && firstSchoolId
  )

  const ctx: SchoolSetupContext = {
    activeCurricula: baseQResult.data?.activeCurricula,
    activeFamilyEdMap: baseQResult.data?.activeFamilyEdMap,
    allSchools,
    curriculumMap: baseQResult.data?.curriculumMap,
    school,
    syId,
  }
  return (
    <SchoolSetupContext.Provider value={ctx}>
      <FullPageLoading qResult={baseQResult}>{props.children}</FullPageLoading>
    </SchoolSetupContext.Provider>
  )
}
