import { Box, Icon } from '@chakra-ui/react'
import { IcoCrumbLeft, IcoCrumbRight, IcoLogOut, IcoPacket } from '@paper/icons'
import { DirPacket, Teacher } from '@paper/schema'
import * as Toggle from '@radix-ui/react-toggle-group'
import { StackProps, TextStack } from '~src/components'
import { MenuItem } from '../menu'
import { NavLink } from '../menu/navLink'
import { renderUserListItem } from '../pickers'
import { useSWContext } from '../swContext'
import { useUser } from '../userProvider'
import { IcoTeacher } from './swMenuIcons'

type MISignOutProps = { as?: 'menuitem' | 'navlink' }

export function MISignOut(props: MISignOutProps) {
  const { signOut, user } = useUser()
  // todo: sane way to reuse...
  const Component = props.as === 'navlink' ? NavLink : MenuItem
  const as = props.as === 'navlink' ? 'button' : null
  return (
    <Component
      as={as}
      icon={<IcoLogOut />}
      onSelect={() => signOut(true)}
      overflow="hidden"
      whiteSpace="nowrap"
    >
      <TextStack>
        <TextStack.Top>Log out</TextStack.Top>
        <TextStack.Bottom opacity={0.8}>{user.email}</TextStack.Bottom>
      </TextStack>
    </Component>
  )
}

export type CrumbMode = '' | 'packets' | 'teachers'
type CrumbSectionProps = {
  crumbMode: CrumbMode
  onChange(value: CrumbMode): void
  packet: DirPacket
  teacher: Teacher
}
export function CrumbItems(props: CrumbSectionProps) {
  const { crumbMode, onChange, packet, teacher } = props
  const { noPacketSwitch } = useSWContext()

  return (
    <Toggle.Root
      onValueChange={onChange}
      rovingFocus={false}
      type="single"
      value={crumbMode}
    >
      <Toggle.Item asChild value="teachers">
        <NavLink icon={<IcoTeacher />}>
          <CrumbBody isSelected={crumbMode === 'teachers'}>
            {teacher ? renderUserListItem(teacher) : 'Blank images'}
          </CrumbBody>
        </NavLink>
      </Toggle.Item>
      <Toggle.Item asChild disabled={noPacketSwitch} value="packets">
        <NavLink disabled={noPacketSwitch} icon={<IcoPacket />}>
          <CrumbBody fontFamily="mono" isSelected={crumbMode === 'packets'}>
            {packet?.number ?? '⋯'}
          </CrumbBody>
        </NavLink>
      </Toggle.Item>
    </Toggle.Root>
  )
}

function CrumbBody(props: StackProps & { isSelected: boolean }) {
  let { children, isSelected, ...rest } = props
  return (
    <Box
      alignItems="center"
      display="grid"
      flexGrow={1}
      gridColumnGap={2}
      gridTemplateColumns="1fr auto"
      {...rest}
    >
      {children}
      <Icon
        as={isSelected ? IcoCrumbLeft : IcoCrumbRight}
        color={isSelected ? 'blue.500' : null}
        fontSize="sm"
      />
    </Box>
  )
}
