import { ScanStatus } from '@paper/schema'
import { z, zenum } from '@paper/schema/validation'

export const z_internal_packetfix = z.object({
  contentId: z.string().optional(),
  packetId: z.string().optional(),
})

export type RD_Internal_Packetfix = z.infer<typeof z_internal_packetfix>

export const z_internal_scanviz = z.object({
  emphasize: zenum<ScanStatus>([
    'infer',
    'infer-gap',
    'manual',
    'no-qr',
  ]).optional(),
  endDate: z.string().optional(),
  hide: zenum(['uninteresting']).optional(),
  zoom: z.coerce.number().optional(),
})

export type RD_Internal_Scanviz = z.infer<typeof z_internal_scanviz>
