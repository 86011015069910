// import { datadogRum } from '@datadog/browser-rum-slim'
import config from './utils/config'

// not interested in int telemetry, but maybe slightly more accurate test if code is running
// domain is blocked in cypress configs
const isTelemetryEnabled =
  !config.meta.host.startsWith('localhost') &&
  (config.meta.where === 'prod' || config.meta.where === 'int')

type Telemetry = {
  setUser //: (typeof datadogRum)['setUser']
}

export const Telemetry: Telemetry = {
  setUser(user) {
    //isTelemetryEnabled && datadogRum?.setUser(user)
  },
}

// if (isTelemetryEnabled) {
//   datadogRum.init({
//     applicationId: '50a1cf5c-cc0a-427a-9d07-566fcb44d290',
//     clientToken: 'pub830bb63600cf7e958de97b6f4808f3ca',
//     site: 'datadoghq.com',
//     service: 'paper-webapp',
//     env: config.meta.where === 'int' ? 'int' : config.meta.host,
//     // Specify a version number to identify the deployed version of your application in Datadog
//     // version: '1.0.0',
//     sampleRate: 100,
//     premiumSampleRate: 0,
//     trackInteractions: true,
//   })
// }
