import { PacketType } from '@paper/schema'
import { z, zenum } from '@paper/schema/validation'
import { Merge } from 'type-fest'
import type { ContentFormSchema } from '~src/pages/publish/wizard/entryBaseData'
import { z_year } from './z_year'

export const z_publish = {
  pathParams: z_year.extend({
    contentIdOrNew: z.string(),
    curriculumId: z.string(),
  }),
  queryParams: z
    .object({
      dev_step: z
        .string()
        .transform((value) => value as any as keyof ContentFormSchema),
      dev_type: zenum<PacketType>(['assessment', 'ticket']),
      packetId: z.string(),
    })
    .partial(),
}

export const z_crossNetwork = z.object({
  aStr: z.string().optional(),
  contentId: z.string().optional(), // we don't want to crash...
  qId: z.string().optional(),
  teaId: z.string().optional(),
})

export const z_linkApi = {
  pathParams: z.object({ _illuminate: z.string() }),
  queryParams: z.object({ qLabel: z.string() }).partial(),
}

export const z_illuminateLookup = z.object({
  title: z.string().trim().optional(),
})

// todo: Merge seems to be losing requiredness...
export type RD_Publish = Merge<
  z.infer<typeof z_publish.pathParams>,
  z.infer<typeof z_publish.queryParams>
>

export type RD_PinGrid = z.infer<typeof z_crossNetwork>

// todo: Merge seems to be losing requiredness...
export type RD_LinkApi = Merge<
  z.infer<typeof z_linkApi.pathParams>,
  z.infer<typeof z_linkApi.queryParams>
>

export type RD_IlluminateLookup = z.infer<typeof z_illuminateLookup>
