import { Button, IconButtonProps } from '@chakra-ui/button'
import { ButtonProps, Tooltip } from '@chakra-ui/react'
import * as Base from '@radix-ui/react-toggle-group'
import {
  createContext,
  forwardRef,
  ReactNode,
  useCallback,
  useContext,
} from 'react'

type Value = string

type ToggleGroupContext = {
  getItemStyleProps: (value: Value, childProps: ButtonProps) => ButtonProps
}
const ToggleGroupContext = createContext<ToggleGroupContext>(undefined)

export type ToggleRootProps<T extends Value = any> = {
  // todo: should add asChild...
  children: ReactNode
  colorScheme?: ButtonProps['colorScheme']
  disabled?: boolean
  preventNone?: boolean
  shape?: 'circle' | '90deg' | 'rounded'
  size?: ButtonProps['size']
} & (
  | { onChange(value: T): void; type: 'single'; value: T }
  | { onChange(value: T[]): void; type: 'multiple'; value: T[] }
)

/**
 * ToggleGroup Root
 * child acts as the container for the toggle items
 */
function Root<T extends Value>(props: ToggleRootProps<T>) {
  const {
    children,
    colorScheme,
    onChange,
    preventNone,
    shape,
    size,
    ...radixProps
  } = props
  const styleProps = { colorScheme, shape, size }

  // todo: should the null to '' conversion be external?
  // @ts-expect-error
  radixProps.value = radixProps.value || ''

  const ctx: ToggleGroupContext = {
    getItemStyleProps(value, childProps) {
      const isChecked =
        typeof radixProps.value === 'string'
          ? value === radixProps.value
          : // @ts-expect-error
            radixProps.value.includes(value)

      const colorScheme =
        childProps.colorScheme || styleProps.colorScheme || 'grayBtn'

      return {
        ...childProps,
        borderColor: isChecked ? 'transparent' : undefined,
        borderRadius:
          shape === 'circle' ? '50%' : shape === '90deg' ? 0 : undefined,
        borderWidth: '1px', /// maintain size between variants
        colorScheme: isChecked ? colorScheme : undefined,
        cursor: 'pointer',
        size,
        variant: isChecked ? 'solid' : 'outline',
      }
    },
  }

  const onValueChange = useCallback(
    (value) => {
      if (!preventNone || value) {
        // coalesce empty string to null
        onChange(value || null)
      }
    },
    [onChange, preventNone]
  )

  return (
    <ToggleGroupContext.Provider value={ctx}>
      <Base.Root asChild={true} onValueChange={onValueChange} {...radixProps}>
        {children}
      </Base.Root>
    </ToggleGroupContext.Provider>
  )
}

type WithValue<T> = Omit<T, 'value'> & { value: Value }
type IconProps = WithValue<IconButtonProps> & { noTooltip?: boolean }
/**
 * Toggle rendered like an IconButton
 */
const ToggleIcon = forwardRef<HTMLButtonElement, IconProps>(
  function ToggleIcon(props, ref) {
    const { icon, noTooltip, value, ...btnProps } = props
    const labelText = btnProps['aria-label']
    const { getItemStyleProps } = useContext(ToggleGroupContext)
    const itemProps = getItemStyleProps(value, btnProps)

    return (
      <Tooltip label={noTooltip ? null : labelText}>
        <Base.Item aria-label={labelText} asChild ref={ref} value={value}>
          <Button px={0} {...itemProps}>
            {icon}
          </Button>
        </Base.Item>
      </Tooltip>
    )
  }
)

type ToggleButtonProps = WithValue<Omit<ButtonProps, 'disabled'>>
/**
 * Toggle rendered as a button
 */
const ToggleButton = forwardRef<HTMLButtonElement, ToggleButtonProps>(
  function ToggleButton(props, ref) {
    const { value, ...btnProps } = props
    const { getItemStyleProps } = useContext(ToggleGroupContext)
    const itemProps = getItemStyleProps(value, btnProps)
    return (
      <Base.Item asChild ref={ref} value={value}>
        <Button data-value={value} {...itemProps} />
      </Base.Item>
    )
  }
)

export const ToggleGroup = { Button: ToggleButton, Icon: ToggleIcon, Root }
