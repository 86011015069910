import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { IcoSearch, IcoSetup } from '@paper/icons'
import { useRouter } from '@paper/route'
import { MainMenuDrawer } from '~src/blocks/mainMenu/drawer'
import { SimplePicker } from '~src/blocks/pickers/filterPicker'
import { TableLayout } from '~src/blocks/tableHelpers'
import { BaseHeader, HStack, Txt } from '~src/components'
import { FilterClearButton } from '~src/pages/home/packetListFilters'
import { RD_Setup_School } from '~src/routelist'
import { SchoolYearPicker } from '~src/schoolYearAirlock'
import { useLimitToRole } from '~src/utils/permissions'
import { CourseSetupTable } from './courseSetupTable'
import { useSchoolSetupContext } from './schoolSetupContext'

export function SchoolSetup() {
  useLimitToRole('admin')
  // todo: handle no schools/
  // todo: 404 invalid schoolId

  return (
    <BaseHeader.Container>
      <SchoolYearPicker />
      <BaseHeader stackGap="1rem">
        <MainMenuDrawer icon={IcoSetup} />
        School setup {BaseHeader.Divider}
        <SchoolPicker />
      </BaseHeader>
      <BaseHeader.Body px={12}>
        <TableLayout
          filters={<Filters />}
          pickers={null}
          table={<CourseSetupTable />}
          title={'School Setup'}
        />
      </BaseHeader.Body>
    </BaseHeader.Container>
  )
}

function SchoolPicker() {
  const { dispatchStay, routeData } = useRouter<RD_Setup_School>()
  const { allSchools } = useSchoolSetupContext()
  return (
    allSchools.length && (
      <SimplePicker
        filterType="contains"
        getLabel={(sch) => `(${sch.id}) ${sch.name}`}
        itemPlural="schools"
        items={allSchools}
        listMaxHeight="500px"
        onChange={(schoolId) => dispatchStay({ schoolId }, 'push')}
        placeholder="Select a school"
        renderItem={(item) => (
          <Txt as="span" fontSize="sm">
            <Txt as="span" fontFamily="mono" mr={1}>
              {item.id}
            </Txt>
            {item.label?.split(')')[1]}
          </Txt>
        )}
        selectedId={routeData.schoolId}
        width="500px"
      />
    )
  )
}

function Filters() {
  // todo: c/p with PacketListFilters
  const filterKeys: (keyof RD_Setup_School)[] = ['f_status', 'f_text']
  return (
    <HStack flexWrap="wrap" gap={6} justifyContent="center">
      <TextFilter />
      <FilterClearButton filterKeys={filterKeys} />
    </HStack>
  )
}

/**
 * todo: c/p with PacketTextFilter
 */
function TextFilter() {
  const size = 'sm'
  const { dispatchStay, routeData } = useRouter<RD_Setup_School>()
  const label = 'Filter by course name'
  return (
    <InputGroup size={size} width="300px">
      <InputLeftElement color="gray.400" children={<IcoSearch />} zIndex={0} />
      <Input
        aria-label={label}
        borderRadius="5rem"
        data-cy="textFilter"
        onChange={(event) =>
          dispatchStay({ f_text: event.target.value || null })
        }
        placeholder={label}
        value={routeData.f_text ?? ''}
      />
    </InputGroup>
  )
}
