export type KeyParts = {
  ns: string
  version: string
  type: string
  folder: string
  id: string
}

export type FileLocation = {
  bucket: string
  key: string
  keyParts: KeyParts
}
