import { useRouter } from '@paper/route'
import { BaseData } from '@paper/route/src/types'
import { useCallback } from 'react'
import type { ListAdapter } from '.'

export type ListCallbacks<T> = {
  onNext: () => void
  onPrev: () => void
  onSelect: (item: T) => void
  selectedId: string
  selectedIndex: number
  selectedItem: T
}

export function useListCallbacks<T, RD extends BaseData = BaseData>(
  items: T[],
  adapter: ListAdapter<T, RD>
): ListCallbacks<T> {
  const { dispatchStay, routeData } = useRouter<RD>()
  const selectedId = adapter.idFromRouter(routeData)
  const selectedIndex = items?.findIndex(
    (itm) => adapter.idFromItem(itm) === selectedId
  )
  const selectedItem = items?.[selectedIndex]
  const onSelect = useStaticFn((item: T) => dispatchStay(adapter.select(item)))
  const [onPrev, onNext] = usePrevNext(items, selectedIndex, onSelect)

  return { onNext, onPrev, onSelect, selectedId, selectedIndex, selectedItem }
}

export function useStaticFn<T extends (...args: any[]) => any>(cb: T): T {
  return useCallback(cb, [])
}

export function usePrevNext<T>(
  items: T[],
  selectedIndex: number,
  onSelect: (item: T) => void
) {
  return [-1, 1].map((amt) => {
    // Running this as high up as possible so can't count on items being defined :(
    const nextItem = items?.[selectedIndex + amt]
    return useCallback(() => nextItem && onSelect(nextItem), [nextItem])
  })
}
