import { Heading, HeadingProps } from '@chakra-ui/react'

export function H3(props: HeadingProps) {
  return (
    <Heading
      as="h3"
      fontSize="2xl"
      fontWeight={300}
      userSelect="none"
      {...props}
    />
  )
}
