import { Path } from 'path-parser'
import { ReactElement } from 'react'
import { Merge } from 'type-fest'
import { z } from 'zod'
import { QS_OPTIONS, queryString } from './queryString'
import type { Action, BaseData, MergeType, NavType } from './types'

export const YearSlugs = {
  CurrentYear: 'current-year',
}

type YearTypeSlug = 'load' | 'pe' | 'sw'

/**
 * * `timeless` The route does not use year
 * * `year-`
 *   * `specific` | 'swtichable` - if switching years makes sense
 *   * `load` | `sw` - which year type
 *   * `n` - number of years
 */
export type YearType =
  | 'timeless'
  | `specific-${YearTypeSlug}${'' | '-1'}`
  | `switchable-${'pe' | 'sw'}-${2 | 'all'}`

export type RouteMetaArgs<QP = any, PP = any> = {
  parent?: RouteMeta
  path: string
  pathParams?: PP
  queryParams?: QP
  yearType: YearType
  render: () => ReactElement
}

type NavAction<D extends BaseData> = (data: D, navType?: NavType) => Action

type MergeAction<D extends BaseData> = (
  data?: Partial<D>,
  navType?: NavType
) => Action

export interface RouteMeta<D extends BaseData = any> {
  base: RouteMeta
  buildUrl: (data: D) => string
  mapData: (data: {}) => D
  mergeAction: MergeAction<D>
  navigateAction: NavAction<D>
  parent?: RouteMeta
  path: string
  pathParser: Path
  render: () => ReactElement
  yearType: YearType
}

type MergePQ<QP extends z.AnyZodObject, PP extends z.AnyZodObject> = Merge<
  z.infer<QP>,
  z.infer<PP>
>

/**
 * Router uses `null` as signal to remove param, and zod doesn't like nulls
 * todo: return type
 */
function removeFalsey<T>(obj: T): T {
  return Object.fromEntries(Object.entries(obj).filter(([k, v]) => v)) as T
}

export function routemeta<QP extends z.AnyZodObject, PP extends z.AnyZodObject>(
  props: RouteMetaArgs<QP, PP>
): RouteMeta<MergePQ<QP, PP>> {
  let { parent, path, pathParams, queryParams, yearType, render } = props

  type D = MergePQ<QP, PP>
  let self: RouteMeta<D>

  // Calculate path
  if (parent) {
    path = parent.path + path
  }
  const pathParser = new Path(path)

  // todo: nasty workaround to make sure (?) yearCode has a valid value
  // todo: basically, when going to a year-ed route, we always want there to be a year
  // todo: this shold
  /**
   * todo: nasty workaround to make sure yearCode has a valid value
   * todo: if things get typed correctly, then we'd catch this, and could do:
   * @example
   * const { thisYear } = useSchoolYearContext()
   * Routes.destination.mergeAction({ yearCode: thisYear.code, ...otherProps })
   */
  const getPathData = (data: D) => {
    let pathData = pathParams?.parse(data) ?? {}
    if (yearType !== 'timeless' && !pathData.yearCode) {
      pathData.yearCode = YearSlugs.CurrentYear
    }
    return pathData
  }

  // Pick out data relevant to this route
  const mapData = (state: D) => {
    state = removeFalsey(state)
    // todo: is this the right spot to validate?
    let qp = queryParams?.parse(state) ?? {}
    let pp = getPathData(state)

    return { ...qp, ...pp } as D
  }

  // Url builder
  const buildUrl = (data: D) => {
    // Path
    let pathData = getPathData(data)
    let url = pathParser.build(pathData)
    // Query params
    if (queryParams) {
      let queryData = queryParams.parse(removeFalsey(data))
      const search = queryString.stringify(queryData, QS_OPTIONS)
      if (search) {
        url = `${url}?${search}`
      }
    }

    return url
  }

  interface ActionFactoryFactory {
    (mergeType: 'overwrite'): NavAction<D>
    (mergeType: 'merge'): MergeAction<D>
  }

  const factory: ActionFactoryFactory =
    (mergeType: MergeType) =>
    (data = {} as D, navType: NavType = 'push'): Action => {
      return {
        type: 'navigate',
        mergeType,
        navType,
        data,
        routeMeta: self,
      }
    }

  self = {
    base: null,
    buildUrl,
    mapData,
    mergeAction: factory('merge'),
    navigateAction: factory('overwrite'),
    parent,
    path,
    pathParser,
    render,
    yearType,
  }

  //  `base` gets inherited from `parent`
  self.base = parent ? parent.base : self

  return self
}
