import type { Page, PublishStage, Question, StdEmbed } from '..'

export type StrId = { id: string }
export type PacketType = 'assessment' | 'ticket'

export type DirPacket = {
  _illuminate?: string
  _isTicketPilot?: boolean
  contentId: string
  curriculumId: string
  links: {
    external?: string
    original: string
  }
  id: string
  name: string
  number: string
  pages: Page[]
  parts: number[]
  pub: { date?: number; radioactive?: boolean; stage: PublishStage }
  print: { count?: number; date: number }
  questions: Question[]
  scan?: {
    /** # of packets with >=1 scan */
    count: number
    /** earliest scan date */
    date: number
    /** # of packets >= 90% scanned */
    gte90: number
    /** # of packets < 90% scanned */
    lt90: number
    /** # of packets >=1 and < 100% scanned */
    partial: number
    /** # of packets 100% scanned */
    success: number
    /** # of open unnamed packets */
    unnamedOpen: number
    /** # of packets with scores */
    withScore: number
  }
  stds: StdEmbed[]
  teacherId?: string
  type: PacketType
}

export type PacketAxisItem = {
  date: number
  id: string
  name: string
  number: string
  pages: Page[]
  questions: Question[]
  /** stats are recalculated based on client filters */
  stats?: {
    missing: number
    withScans: number
    withScores: number
  }
  stds: StdEmbed[]
  type: PacketType
}
