import type { StrId } from '@paper/schema'
import { Filterer, useComboBoxItems } from '~src/components'

// todo: Most of these we're just passing to useComboBoxItems *sigh*
export type PickerProps<T, V> = {
  excludeSet?: Set<string>
  filterer: Filterer<T>
  includeSet?: Set<string>
  limit?: number
  selector?: (item: T, value: V) => boolean
  value?: V
}

/**
 * Returns combobox props...
 */
export function usePickerBase<T extends StrId, V = string>(
  qResult: { data: T[]; isLoading: boolean },
  props: PickerProps<T, V>
) {
  const { excludeSet, filterer, includeSet, limit, selector, value } =
    props ?? {}
  let { data, isLoading } = qResult
  // todo: workaround to allow avoiding selecting something twice
  // todo: maybe memoize
  if (excludeSet && data) {
    data = data.filter((item) => !excludeSet.has(item.id))
  }
  if (includeSet && data) {
    data = data.filter((item) => includeSet.has(item.id))
  }

  const comboProps = useComboBoxItems({
    items: data,
    value,
    filterer,
    selector,
    limit: limit || 100, // todo: virtualization
  })
  return { ...comboProps, busy: isLoading }
}
