import { Icon, IconProps, PositionProps } from '@chakra-ui/react'
import {
  IcoExclamationCircle,
  IcoExclamationTriangle,
  IcoInfo,
  IcoOffline,
} from '@paper/icons'
import { forwardRef } from 'react'
import { Except } from 'type-fest'
import { useSystemStatus } from './systemStatusProvider'

type SystemStatusIndicatorProps = PositionProps & {
  isAdminOnly?: boolean
  ifNormal: 'info' | 'nothing'
  size?: IconProps['fontSize']
  type: 'system' | 'scans' | 'loader'
}

export function SystemStatusIndicator(props: SystemStatusIndicatorProps) {
  const { isAdminOnly, type, ...rest } = props
  const { isAdmin, isOnLine, qResult } = useSystemStatus()

  const { data, isSuccess, isError, isLoading } = qResult

  if (isAdminOnly && !isAdmin) {
    return null
  }
  // Render nothing while loading
  else if (isLoading) {
    return null
  }

  let isAlert = null
  if (isSuccess) {
    const isScansAlert = !data.scanbatchStats.isNormal
    const isLoadAlert = !data.load.isNormal

    isAlert =
      type === 'scans'
        ? isScansAlert
        : type === 'loader'
        ? isLoadAlert
        : isScansAlert || isLoadAlert
  }

  // todo: maybe factor this out as a prop, but we only want to show the offline status for system
  const showOffline = type === 'system'

  const status =
    showOffline && !isOnLine
      ? 'offline'
      : isError
      ? 'error'
      : isAlert
      ? 'alert'
      : 'normal'

  return (
    <SystemAlertIcon
      aria-label={`${type} status ${status}`}
      data-cy={`system-status`}
      status={status}
      {...rest}
    />
  )
}

type SystemAlertIconProps = Except<SystemStatusIndicatorProps, 'type'> & {
  'aria-label': string
  'data-cy'?: string
  status: 'alert' | 'error' | 'normal' | 'offline'
}
export const SystemAlertIcon = forwardRef<SVGSVGElement, SystemAlertIconProps>(
  function SystemAlertIcon(props, ref) {
    const { ifNormal, size = 'lg', status, ...rest } = props

    const isNormal = status === 'normal'
    const isOffline = status === 'offline'
    const isError = status === 'error'

    const showIndicator =
      isOffline || isError || !isNormal || (isNormal && ifNormal === 'info')

    const as = isOffline
      ? IcoOffline
      : isError
      ? IcoExclamationTriangle
      : isNormal
      ? IcoInfo
      : IcoExclamationCircle

    let icoProps: IconProps
    if (isOffline) {
      icoProps = {
        color: 'yellow.500',
      }
    } else if (isError) {
      icoProps = {
        color: 'red.500',
      }
    } else if (!isNormal) {
      icoProps = {
        // icon looks dumb/can't sit on top without white exclamation
        bg: 'white',
        clipPath: 'circle(38% at 51% 50%)',
        color: 'orange.500',
      }
    } else {
      icoProps = {
        color: 'white',
      }
    }

    return !showIndicator ? null : (
      <Icon
        as={as}
        data-status={status}
        flexGrow={0}
        fontSize={size}
        ref={ref}
        {...icoProps}
        {...rest}
      />
    )
  }
)
