import { createContext, ReactNode, useContext } from 'react'
import { useSystemStatusData } from '~src/data/data-systemStatus'
import { useOnLine } from '~src/utils/useOnLine'
import { useUser } from '../userProvider'

type SystemStatusContext = {
  isAdmin: boolean
  isOnLine: boolean
  qResult: ReturnType<typeof useSystemStatusData>
}
const SystemStatusContext = createContext<SystemStatusContext>(undefined)
export const useSystemStatus = () => useContext(SystemStatusContext)

type SystemStatusProviderProps = { children: ReactNode }

export function SystemStatusProvider(props: SystemStatusProviderProps) {
  const { children } = props
  const { user } = useUser()
  const isOnLine = useOnLine()
  // Hoisting this because running into issues
  // with react-query retrying over and over when nesting
  const qResult = useSystemStatusData(isOnLine)

  const ctx: SystemStatusContext = {
    // todo: getting a crash here in dev without the `roles?`
    // todo: not sure if it's hot-reload/context madness or something else
    isAdmin: user?.roles?.includes('admin'),
    isOnLine,
    qResult,
  }
  return (
    <SystemStatusContext.Provider value={ctx}>
      {children}
    </SystemStatusContext.Provider>
  )
}
