import { Redirect } from '@paper/route'
import { MainMenu } from '~src/blocks/mainMenu/mainMenu'
import { useUser } from '~src/blocks/userProvider'
import { AppTitle } from '~src/components'
import { FullPageLoading } from '~src/components/status'
import { useDirectoryData } from '~src/data/data-directory'
import { Routes } from '~src/routelist'

/**
 * Redirect from `/` to `/23-24`
 */
export function HomeRedirectNoYear() {
  const { year, years } = useUser()
  const yearCode = (year.pe ?? years[0])?.code
  return (
    <Redirect action={Routes.root.navigateAction({ yearCode }, 'replace')} />
  )
}

/**
 * Redirects teachers to their packet list, shows everyone else the main menu
 */
export function HomeRedirect() {
  const { user, year } = useUser()
  const qResult = useDirectoryData()

  return (
    <FullPageLoading qResult={qResult}>
      {() => {
        const isTeacher = qResult.data?.teacher.map.has(user.teacherId)
        const action = Routes.home_teacher.navigateAction({
          teacherId: user.teacherId,
          yearCode: year.sw?.code,
        })
        return isTeacher ? (
          <Redirect action={action} />
        ) : (
          <AppTitle title="Home">
            <MainMenu />
          </AppTitle>
        )
      }}
    </FullPageLoading>
  )
}
