import { Yup, yupPage, yupPassage, yupQuestion } from '@paper/schema/validation'
import type { ContentFormSchema } from './entryBaseData'

// todo: should rollbar these too...
const codingErrorMessage =
  'Ack! Something went horribly wrong, please contact support.'

const pdf = Yup.object<Partial<ContentFormSchema>>({
  name: Yup.string()
    .ensure() /* todo: this prevents the nullable error */
    .required('Name is required')
    .min(3, 'Name must be at least 3 characters'),
  pages: Yup.array(yupPage).ensure(),
  parts: Yup.array(Yup.number()).ensure(),
}).test({
  name: 'type',
  test: function testTypeAndPages(value: ContentFormSchema) {
    const { pages, parts, type } = value
    // todo: workaround for the type step...
    const path = 'type'
    if (!type) {
      return this.createError({ path, message: 'Select a packet type' })
    } else if (!parts?.length) {
      return this.createError({ path, message: 'Select a page' })
    } else if (!pages?.length) {
      return this.createError({ path, message: codingErrorMessage })
    } else {
      // todo: not sure if it's necessary to validate further...
      // todo: should probably validate these as fields
      return true
    }
  },
})

export const PassageNoQError = 'Passages must have at least one question'
const answerKey = Yup.object<Partial<ContentFormSchema>>({
  _importName: Yup.string()
    .required('Illuminate assessment title is required to add an answer key')
    .max(255, 'Title can be at most 255 characters'),
  pages: Yup.array(yupPage).ensure(),
  passages: Yup.array(yupPassage).ensure(),
  questions: Yup.array(yupQuestion).ensure(),
})
  .test({
    name: 'questions',
    test: function testPQs(value: ContentFormSchema) {
      const { questions, type } = value
      let repeatedLabel = null
      let repeatedLabelIdx = 0
      const labelSet = new Set<string>()
      for (let q of questions) {
        if (labelSet.has(q.label)) {
          repeatedLabel = q.label
          break
        } else {
          labelSet.add(q.label)
          repeatedLabelIdx += 1
        }
      }

      // precedence of errors...
      if (repeatedLabel) {
        return this.createError({
          path: `questions`,
          message: `Labels must be unique (Row ${
            repeatedLabelIdx + 1
          } repeats "${repeatedLabel}")`,
        })
      } else if (type === 'assessment' && questions.length === 0) {
        return this.createError({
          path: 'questions',
          message: 'Complete the question import process to continue',
        })
      } else {
        return true
      }
    },
  })
  .test({
    name: 'passages',
    test: function testPassages(value: ContentFormSchema) {
      const { passages, questions } = value
      // qs could be deleted on import, so we need to check for used-ness
      let hasInvalids = false
      // Need array to keep track of indexes, but Yup seems to require blank instead of undefined
      let invalids = new Array(passages.length)
      let usedIds = new Set(questions.flatMap((q) => q.passageIds))

      passages.forEach((passage, idx) => {
        const hasQs = usedIds.has(passage.id)
        if (!hasQs) {
          hasInvalids = true
          invalids[idx] = this.createError({
            path: `passages[${idx}]`,
            message: PassageNoQError,
          })
        }
      })

      if (hasInvalids) {
        // @ts-expect-error
        return new Yup.ValidationError(invalids)
      }
      return true
    },
  })
  .test({
    name: 'pages',
    /**
     * Checks for:
     *  * P/Q ids in `pages` array that are not defined in { passages, questions }
     *  * P/Q ids defined in { passages, questions } that are not attached to pages
     *  * `pages` length should match `length`
     */
    test: function testPages(value: ContentFormSchema) {
      const { pages, passages, questions } = value

      const pSet = new Set(passages.map((item) => item.id))
      const qSet = new Set(questions.map((item) => item.id))

      const sets = {
        passage: {
          notDefined: new Set<string>(), // add here if not defined in `values`
          unattached: new Set<string>(pSet), // remove from here if attached to pages
          values: pSet,
        },
        question: {
          notDefined: new Set<string>(),
          unattached: new Set<string>(qSet),
          values: qSet,
        },
      }

      // Check ids...
      for (let item of pages.flatMap(({ items }) => items)) {
        const { notDefined, unattached, values } = sets[item.type]
        if (values.has(item.id)) {
          unattached.delete(item.id)
        } else {
          notDefined.add(item.id)
        }
      }

      let codingErrors: string[] = []
      let userErrors: string[] = []
      // check for errors (if sets not empty)
      for (let [itemType, { unattached, notDefined }] of Object.entries(sets)) {
        if (unattached.size > 0) {
          userErrors.push(
            [
              `"${itemType}s" includes items that are not attached to any pages:`,
              JSON.stringify(Array.from(unattached)),
            ].join(' ')
          )
        }
        if (notDefined.size > 0) {
          codingErrors.push(
            [
              `"pages" includes ids that are not defined in ${itemType}s:`,
              JSON.stringify(Array.from(notDefined)),
            ].join(' ')
          )
        }
      }

      // todo: different messages needs for client/server
      if (codingErrors.length > 0) {
        console.error(codingErrors) // todo: rollbar
        return this.createError({ path: 'pages', message: codingErrorMessage })
      } else if (userErrors.length > 0) {
        return this.createError({
          path: 'pages',
          message: `All passages and questions must be attached to at least 1 page.`,
        })
      } else {
        return true
      }
    },
  })

export const validationSchemas = {
  pdf,
  answerKey,
}
