import { BoxProps, Icon } from '@chakra-ui/react'
import { IcoExclamationTriangle } from '@paper/icons'
import { Txt } from '~src/components'

/**
 * @deprecated Need a unified plan for such things!
 */
export function ExclaimRed(props: BoxProps & { type?: 'error' | 'warning' }) {
  const { children, type = 'error', ...boxProps } = props
  const color = type === 'error' ? 'red.500' : null
  const iconColor = type === 'error' ? 'red.500' : 'yellow.500'
  return (
    <Txt color={color} {...boxProps}>
      <Icon as={IcoExclamationTriangle} color={iconColor} mr={1} mb="-2px" />
      {children}
    </Txt>
  )
}
