import { GRAY_BAR, GRAY_BAR_SELECTED, monoFont } from '@paper/styles'
import { memo, ReactNode } from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  LabelProps,
  ResponsiveContainer,
  YAxis,
} from 'recharts'
import { useKeyboard } from '~src/utils/useKeyboard'
import type { BarAgg, QAgg, StdAgg } from './bargraphTypes'
import { useQStdContext } from './qStdProvider'

export function Bargraph() {
  const { digest, mode } = useQStdContext()
  // todo: still figuring out how to switch between stds and qs...
  const isStdMode = mode === 'std'

  const renderLabel = isStdMode ? renderStdLabel : renderQLabel

  //console.log('render <Bargraph/>')

  // Keyboard navigation
  useKeyboard('a', digest.success?.onPrev)
  useKeyboard('d', digest.success?.onNext)

  return (
    <NavGraph
      data={digest.success?.items}
      onSelect={digest.success?.onSelect}
      renderLabel={renderLabel}
      selected={digest.success?.selectedItem}
    />
  )
}

type NavGraphProps<T extends BarAgg> = {
  data: T[]
  onSelect(item: T): void
  renderLabel(props: RenderLabelProps<T>): ReactNode
  selected: T
}
const NavGraph = memo(function NavGraph<T extends BarAgg>(
  props: NavGraphProps<T>
) {
  let { data, onSelect, renderLabel, selected } = props
  data = data ?? []
  const handleClick = onSelect

  // console.log('<NavGraph /> --')

  return (
    <ResponsiveContainer>
      <BarChart
        data={data}
        margin={{ top: 0, right: 5, left: 5, bottom: 24 }}
        maxBarSize={20}
      >
        <CartesianGrid
          horizontalPoints={[16, 32, 48]} // todo: is there a way to not hardcode this?
          strokeDasharray="6 6"
          strokeOpacity="50%"
          vertical={false}
        />
        <YAxis domain={[0, 100]} hide={true} reversed={true} />
        <Bar dataKey="pct" onClick={handleClick}>
          {data.map((item, idx) => {
            const entry = item
            const isSelected = entry === selected
            return (
              <Cell
                cursor="pointer"
                fill={isSelected ? GRAY_BAR_SELECTED : GRAY_BAR}
                key={idx}
              />
            )
          })}
          <LabelList
            // @ts-expect-error
            _data={data}
            _onClick={handleClick}
            _selected={selected}
            content={renderLabel}
            dataKey="label"
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
})

type RenderLabelProps<T> = LabelProps & {
  _data: T[]
  _onClick(item: T): void
  _selected: T
  height: number
  index: number
  width: number
  x: number
  y: number
}

const renderStdLabel = (props: RenderLabelProps<StdAgg>) => {
  const { _data, _onClick, _selected, index, width, x } = props

  const item = _data[index]
  const isSelected = item === _selected
  const isZero = item.pts === 0

  if (!isZero) {
    return null
  }

  return (
    <g>
      <text
        cursor="pointer"
        dominantBaseline="hanging"
        fill={isSelected ? GRAY_BAR_SELECTED : GRAY_BAR}
        fontFamily={monoFont}
        fontSize=".8rem"
        fontWeight={600}
        onClick={() => _onClick(item)}
        textAnchor="middle"
        x={x + width / 2}
        y={1}
      >
        {'{0}'}
      </text>
    </g>
  )
}

const renderQLabel = (props: RenderLabelProps<QAgg>) => {
  const { _data, _onClick, _selected, index, width, x, y = 0, value } = props

  const item = _data[index]
  const isSelected = item === _selected

  const fill = isSelected ? GRAY_BAR_SELECTED : GRAY_BAR

  const radius = 10
  const rectH = radius * 2
  const rectW = radius * 2.4
  const rectY = y + 2
  const rectX = x + (width - rectW) / 2

  return (
    <g onClick={() => _onClick(item)} style={{ cursor: 'pointer' }}>
      <rect
        x={rectX}
        y={rectY}
        width={rectW}
        height={rectH}
        fill={fill}
        ry={radius}
        rx={radius}
      />
      <text
        x={rectX + rectW / 2}
        y={rectY + rectH / 2 + 1}
        fill="#fff"
        fontSize=".75rem"
        fontWeight={400}
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {value}
      </text>
    </g>
  )
}
