/** Useful for these to be isolated since they're used by both the app and test infrastructure */

import { Margin, PacketContent, Padding, Pos, Variant } from '@paper/schema'

/** [S]tep[B]it */
export enum SB {
  PREINIT = 1,
  OPEN_PDF = 2,
  PACKET_TYPE = 4,
  PACKET_NAME = 8,
  QR = 16,
  PARTS = 32,
  STDS = 64,
  QUESTIONS = 128,
  PASSAGES = 256,
  PAGES = 512,
  REVIEW = 1024,
  PUBLISH = 2048,
}

export const contentToFormik = (content?: PacketContent): ContentFormSchema => {
  return {
    _import: content?._import,
    _importName: content?._importName,
    name: content?.name,
    pages: content?.pages ?? [],
    parts: content?.parts ?? [],
    passages: content?.passages ?? [],
    questions: content?.questions ?? [],
    style: content?.style ?? {
      margin: Margin.half,
      padding: Padding.eighth,
      position: Pos.tl,
      variant: Variant.full,
    },
    type: content?.type,
  }
}

export type ContentFormSchema = {
  _idle?: never
  _placeholder?: never
  _pdf?: never
  _uploadChecklist?: never
} & Omit<PacketContent, 'images'>
