import zipObject from 'lodash/zipObject'

type AsyncEveryable<K extends string> = Record<K, () => Promise<boolean>>

export async function asyncEvery<K extends string>(
  arr: AsyncEveryable<K>[],
  key: K
): Promise<boolean> {
  let all = await Promise.all(arr.map((item) => item[key]()))
  return all.every((p) => p)
}

/**
 * I got tired of typing Promise.all
 * @example
 * await asyncMap(arr, item => { ... })
 * // instead of
 * await Promise.all(arr.map(item => { ... }))
 */
export function asyncMap<T, U>(
  arr: readonly T[],
  cb: (item: T, idx: number) => Promise<U>
): Promise<U[]> {
  return Promise.all(arr.map(cb))
}

/**
 * @example
 * await asyncMapToObject(arr, item => { ... return [key, value] })
 * // instead of
 * Object.fromEntries(await Promise.all(arr.map(item => { ... } )))
 */
export async function asyncMapToObject<
  T,
  V,
  K extends string | number | symbol = string,
>(
  arr: T[],
  cb: (item: T, idx: number) => Promise<[K, V]>
): Promise<Record<K, V>> {
  return Object.fromEntries(await asyncMap(arr, cb)) as any
}

type OKey = string | number | symbol
type Obj<T = any> = Record<OKey, T>
type UnwrapObj<O> = O extends Obj<infer T> ? T : never

/**
 * @example
 * // The thought is that this:
 * await asyncMapObj(obj, ([ key, value]) => { ... })
 *
 * // is easier to read than this:
 * await Promise.all(
 *  Object.entries(obj).map(([ key, value]) => { ... }))
 * )
 *
 */
export function asyncMapObj<O extends Obj, U>(
  obj: O,
  cb: (entry: [keyof O, UnwrapObj<O>]) => Promise<U>
): Promise<U[]> {
  return asyncMap(Object.entries(obj) as any, cb)
}

/**
 * Like lodash mapValues by async via `Promise.all`
 */
export async function asyncMapValues<O extends Obj, U>(
  obj: O,
  cb: (value: UnwrapObj<O>, key: OKey) => Promise<U>
): Promise<Record<string, U>> {
  return zipObject(
    Object.keys(obj),
    await asyncMapObj(obj, ([key, value]) => cb(value, key))
  )
}
