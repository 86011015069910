import { Packetmeta } from '@paper/schema'
import { defineApi } from '../defineApi'

export const packet_fixer_get = defineApi<{
  method: 'get'
  searchParams: { contentId: string }
  result: Packetmeta[]
}>({
  method: 'get',
  urlFactory: `unstable-fixer/get`,
})

export const packet_fixer_update = defineApi<{
  method: 'post'
  body: {
    contentId: string
    packets: Pick<Packetmeta, 'id' | 'curriculumId'>[]
  }
  result: null
}>({
  method: 'post',
  urlFactory: `unstable-fixer/update`,
})
