import { createIcon } from '@chakra-ui/react'
import { SVGProps } from 'react'

const icoScanIssue: Parameters<typeof createIcon>[0] = {
  displayName: 'IcoScanIssue',
  path: (
    <g fill="currentcolor">
      <path d="M336 336h80v80h-80zm-64-64h64v64h-64zm144 144h64v64h-64zm16-144h48v48h-48zM272 432h48v48h-48zm64-336h80v80h-80z" />
      <path d="M480 240H272V32h208zm-164-44h120V76H316z" />
      <path d="M96 336h80v80H96z" />
      <path d="M240 480H32V272h208zM76 436h120V316H76z" />
      <path d="M179 26c-21.5 0-37 10-41.5 27-42-3-76.5 27-67.5 47 1 3 0 6-2.5 6-69.5 7-82.5 69-15.5 89 3 1 3.5 5 2 8-16 32 19 56 72 56 54 0 83-19 73.5-49-2-7-1-11 3.5-12 71-15 53-95-8-103-12.5-2-9.5-13-5-15 51-13 20-54-11-54z" />
    </g>
  ),
  viewBox: '0 0 512 512',
}

/**
 * Temporary mash up of IoQrCodeSharp and GiSpill
 */
export const IcoScanIssue = createIcon(icoScanIssue)
export const IcoScanIssueSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} viewBox={icoScanIssue.viewBox}>
    {icoScanIssue.path}
  </svg>
)
