import { Box } from '@chakra-ui/react'
import { Passage, PorQ, Question } from '@paper/schema'
import { CORRECT_COLOR, PASSAGE_COLOR } from '@paper/styles'
import { Fragment, memo } from 'react'
import { Txt } from '~src/components'
import { PassageLabel } from './answerKeyItems'
import { Chunk, PassageChunk } from './chunk'
import { MAGIC_MAX_PASSAGE_LENGTH } from './container'
import { QuestionRow } from './questionRow'
import { ActionFactory, PQMenuShell } from './shell'

////////////////////////////////////////////////////////////////////////////////////
// TODO: Blegh: Still haven't wrapped my head around how to compose these properly
// MenuShell * P/Q + CheckboxShell * P/Q
////////////////////////////////////////////////////////////////////////////////////
type AnswerKeyProps = {
  actionFactory?: ActionFactory
  chunks: PassageChunk[]
  highlightedId?: string
  scrolledToId?: string
  unusedPassages?: Passage[]
  unusedQuestions?: Question[]
}

export const AnswerKey = memo(function AnswerKey(props: AnswerKeyProps) {
  const {
    actionFactory,
    chunks,
    highlightedId,
    scrolledToId,
    unusedPassages,
    unusedQuestions,
  } = props

  return (
    <Fragment>
      {chunks.length === 0 && (
        <Txt
          alignSelf="center"
          as="span"
          fontSize="xs"
          fontStyle="italic"
          p={3}
        >
          (Empty)
        </Txt>
      )}
      {chunks.map(({ passages, questions }, idx) => (
        <Chunk key={idx} clearance="lots">
          {passages.map((passage) => (
            <PassageMenu
              actionFactory={actionFactory}
              highlightedId={highlightedId}
              item={passage}
              key={passage.id}
              maxLength={Math.round(MAGIC_MAX_PASSAGE_LENGTH / passages.length)}
            />
          ))}
          {questions.map((q) => (
            <QuestionMenu
              actionFactory={actionFactory}
              highlightedId={highlightedId}
              item={q}
              key={q.id}
              scrolledToId={scrolledToId}
            />
          ))}
        </Chunk>
      ))}
      {unusedPassages?.length > 0 && (
        <Box mt={4}>
          <UnusedTitle>Unused passages</UnusedTitle>
          {unusedPassages.map((passage) => (
            <Chunk key={passage.id} clearance="some">
              <PassageMenu
                actionFactory={actionFactory}
                highlightedId={highlightedId}
                item={passage}
                scrolledToId={scrolledToId}
              />
            </Chunk>
          ))}
        </Box>
      )}
      {unusedQuestions?.length > 0 && (
        <Box mt={4}>
          <UnusedTitle>Unused questions</UnusedTitle>
          <Chunk key="unusedQs" clearance="some">
            {unusedQuestions.map((q) => (
              <QuestionMenu
                actionFactory={actionFactory}
                highlightedId={highlightedId}
                item={q}
                scrolledToId={scrolledToId}
              />
            ))}
          </Chunk>
        </Box>
      )}
    </Fragment>
  )
})

const UnusedTitle = ({ children }) => {
  return (
    <Box
      color="red.500"
      fontFamily="mono"
      fontSize="sm"
      fontStyle="italic"
      px={2}
    >
      {children}
    </Box>
  )
}

type ItemMenuProps<T extends PorQ> = {
  actionFactory: ActionFactory
  highlightedId: string
  scrolledToId?: string
  item: T
  maxLength?: number
}

const PassageMenu = ({
  actionFactory,
  highlightedId,
  scrolledToId,
  item,
  maxLength = MAGIC_MAX_PASSAGE_LENGTH,
}: ItemMenuProps<Passage>) => {
  const isHighlighted = item.id === highlightedId
  return (
    <PQMenuShell
      actions={actionFactory?.({ item, itemType: 'passage' })}
      color={PASSAGE_COLOR}
      isHighlighted={isHighlighted}
    >
      <PassageLabel
        isScrolledTo={isHighlighted || item.id === scrolledToId}
        data={item}
        maxLength={maxLength}
      />
    </PQMenuShell>
  )
}

const QuestionMenu = ({
  actionFactory,
  highlightedId,
  scrolledToId,
  item,
}: ItemMenuProps<Question>) => {
  const isHighlighted = item.id === highlightedId
  return (
    <PQMenuShell
      actions={actionFactory?.({ item, itemType: 'question' })}
      color={CORRECT_COLOR}
      isHighlighted={isHighlighted}
    >
      <QuestionRow
        isScrolledTo={isHighlighted || item.id === scrolledToId}
        data={item}
      />
    </PQMenuShell>
  )
}
