import { MainMenuDrawer } from '~src/blocks/mainMenu/drawer'
import { AppTitle, BaseHeader, Txt } from '~src/components'
import { StickerForm } from './stickerForm'
import { IcoQRSticker } from './stickerIcon'

export function StickerPage() {
  return (
    <BaseHeader.Container>
      <AppTitle title="Generate stickers" />
      <BaseHeader hideLogo={true} minWidth="unset" mb={0}>
        <MainMenuDrawer icon={IcoQRSticker} />
        <Txt fontSize="lg">Generate student QR stickers</Txt>
      </BaseHeader>
      <BaseHeader.Body overflowY="auto">
        <StickerForm />
      </BaseHeader.Body>
    </BaseHeader.Container>
  )
}
