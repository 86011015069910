import { useRouter } from '@paper/route'
import { IcoScanIssue } from '~src/blocks/icoScanIssue'
import { MainMenuDrawer } from '~src/blocks/mainMenu/drawer'
import { AppTitle, BaseHeader, Column, TextStack, VSep } from '~src/components'
import { LoadingDots } from '~src/components/status'
import { RD_SW_Scanlog } from '~src/routelist'
import { getScanlogPacketLabel } from '~src/utils/messages'
import { ScanlogFix } from './fix/scanlogFix'
import { useScanlogContext } from './scanlogAirlock'
import { ScanlogBatchList } from './scanlogBatchList'
import {
  ClearFilterButton,
  ScanDatePicker,
  ScanDevicePicker,
  ScanWithImagesThatPicker,
} from './scanlogFilters'
import { ScanlogXpacketList } from './scanlogXpacketList'

export function Scanlog() {
  return (
    <BaseHeader.Container>
      <AppTitle title={['Scan log']} />
      <BaseHeader hideLogo={true} minWidth="unset" stackGap="1.5rem">
        <MainMenuDrawer icon={IcoScanIssue} />
        <ScanlogHeader />
      </BaseHeader>
      <Column.Container colProps={{ flexGrow: 1, flexShrink: 0 }} flexGrow={1}>
        <ScanlogXpacketList />
        <ScanlogBatchList />
        <ScanlogFix />
      </Column.Container>
    </BaseHeader.Container>
  )
}

type ScanlogHeaderProps = {}

function ScanlogHeader(props: ScanlogHeaderProps) {
  const { dispatchStay, routeData } = useRouter<RD_SW_Scanlog>()
  const { selectedBatch, selectedXpacketSet, sbDigest } = useScanlogContext()

  const isReady = sbDigest.qResult.isSuccess

  if (!isReady) {
    return <LoadingDots />
  } else if (routeData.packetId && routeData.teacherId) {
    // Set batchNum so we stay more or less in context
    const clear = () =>
      dispatchStay(
        { packetId: null, scanDate: selectedBatch?.scanDate, teacherId: null },
        'push'
      )

    return (
      <TextStack>
        <TextStack.Top>
          {sbDigest.success.empty
            ? 'No batches with these ids'
            : // todo: i'm pretty sure we should be able to show this label prior to selecting a batch
            selectedXpacketSet
            ? getScanlogPacketLabel(selectedXpacketSet)
            : 'Select a batch'}
        </TextStack.Top>
        <TextStack.Bottom>
          <ClearFilterButton onClick={clear}>
            View batches from all packets
          </ClearFilterButton>
        </TextStack.Bottom>
      </TextStack>
    )
  } else {
    return (
      <>
        <ScanDatePicker />
        <VSep boxProps={{ marginBlock: 3 }} />
        <ScanDevicePicker data={sbDigest.success?.otherData.scanDevices} />
        <ScanWithImagesThatPicker />
      </>
    )
  }
}
