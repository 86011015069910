import type { GetPreviewProps, PDFDocument } from '@paper/pdf'
import type {
  Curriculum,
  PacketPrintSnapshot,
  PdfContentSet,
  PrintPageData,
  Section,
  Student,
  Teacher,
} from '@paper/schema'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { ContentFormSchema } from './entryBaseData'
import { useWizContext } from './wizProvider'

type UsePacketPreviewProps = {
  /** Workaround so we can call this from WizContext **/
  ctx?: {
    curriculum: Curriculum
    contentId: string
    formik: { values: ContentFormSchema }
    srcDoc: PDFDocument
  }
  disabled?: boolean
  includeBubbles?: boolean
  isQRPreview?: boolean
}
export function usePdfPreview(props: UsePacketPreviewProps) {
  const { disabled, isQRPreview } = props

  let hookCtx = useWizContext()
  let ctx = props.ctx ?? hookCtx
  // todo: maybe go back to getting it from curriculum context?
  const curriculum = ctx.curriculum

  const { contentId, formik, srcDoc } = ctx
  const { values } = formik

  const queryClient = useQueryClient()

  const previewKey: Omit<GetPreviewProps, 'srcDoc'> = {
    data: getPreviewPrintProps({ curriculum, number: '[X.X]', ...values }),
    isQRPreview,
  }

  return useQuery({
    queryKey: ['packet-preview', { contentId, previewKey }], // todo: should still centralize this...
    queryFn: async ({ queryKey }) => {
      // todo: misusing react-query...only want to keep one preview?
      queryClient.removeQueries({
        predicate: (q) => {
          return q.queryKey !== queryKey && q.queryKey[0] === 'packet-preview'
        },
      })
      const { getPreviewBlob } = await import('@paper/pdf')
      return getPreviewBlob({ ...previewKey, srcDoc })
    },
    enabled: !disabled,
    staleTime: Infinity,
  })
}

/////////////////////////////
// Placeholder data for preview
/////////////////////////////
type PreviewPrintPropsArgs = {
  curriculum: Curriculum
  number: string
} & PdfContentSet // todo: fix this type!

function getPreviewPrintProps(props: PreviewPrintPropsArgs): PrintPageData {
  const { curriculum, name, number, pages, parts, style, type } = props

  const teacher: Teacher = {
    email: 'placeholder.teacher@ponderpaper.co',
    firstName: 'Teacher',
    id: 'placeholder',
    lastName: '[Teacher]',
  }

  const section: Section = {
    course: '[Course]',
    curriculumId: curriculum.id,
    name: '[Section]',
    schoolId: 'sample',
    schoolName: 'Sample School',
    id: 'placeholder',
    teacher,
    teacherIds: [],
    term: {
      code: curriculum.ed,
      firstDay: undefined,
      lastDay: undefined,
      syId: 2023, // todo: replace with curriculum year probably
    },
  }
  const student: Student = {
    grade: curriculum.levels[0],
    id: '0123456789',
    firstName: '[Sample',
    lastName: `Student]`,
    lastfirst: 'Student, Sample',
    number: '0123456789',
  }

  let segments: PacketPrintSnapshot['segments']
  // todo: write some tests!
  if (type === 'ticket') {
    // one segment, full length
    segments = [[parts[0], pages.length]]
  } else {
    segments = []
    let lengthLeft = pages.length
    for (let i = 0; i < parts.length; i++) {
      let startIdx = parts[i]
      let segmentLength = parts[i + 1] ? parts[i + 1] - startIdx : lengthLeft
      segments.push([parts[i], segmentLength])
      lengthLeft -= segmentLength
    }
  }

  const printData: PrintPageData = {
    packetSnap: {
      curriculum,
      groupId: number,
      name,
      number: number || '0.0',
      segments,
      style,
      type,
    },
    qrBackup: '2324EFGHI',
    section,
    student,
    xpageId: '24-abcdefgh.00',
  }

  return printData
}
