import { Button, ButtonGroup, Icon } from '@chakra-ui/react'
import { IcoSearch } from '@paper/icons'
import { useRouter } from '@paper/route'
import { getFullName } from '@paper/utils'
import { ReactNode } from 'react'
import { scanStatusToColor } from '~src/blocks/scanStatus'
import { TableContainer } from '~src/blocks/tableHelpers'
import { Ex, HStack, ToggleGroup, Txt, VStack } from '~src/components'
import { LoadingDots } from '~src/components/status'
import { ExpandeeProps, UghTable } from '~src/components/table'
import { PositionInput } from '~src/pages/publish/wizard/formQR'
import { RD_SW_Scanlog } from '~src/routelist'
import { useScanFixContext, useScanlogContext } from '../scanlogAirlock'
import { Fixable } from './scanFixContext'
import { DirtyColorScheme, scanFixTableCols } from './scanFixTableCols'
import { ScanlogPinput } from './scanlogPinput'

type ScanFixTableProps = {}

const FixExpandeeHeight = 132

export function ScanFixTable(props: ScanFixTableProps) {
  const { dispatchStay, routeData } = useRouter<RD_SW_Scanlog>()
  const { selectedFixable, tableData } = useScanFixContext()

  const colorScheme =
    selectedFixable?.changeState !== 'none'
      ? DirtyColorScheme
      : scanStatusToColor(selectedFixable?.status)

  return (
    <TableContainer fontSize="xs">
      <UghTable
        aria-label="Scan edits"
        colorScheme={colorScheme}
        columns={scanFixTableCols}
        data={tableData}
        Expandee={FixExpandee}
        expandeeHeight={FixExpandeeHeight}
        getId={(item: Fixable) => item.id}
        height="100%"
        noFr={true}
        onSelect={(item: Fixable) => dispatchStay({ si_imageId: item.id })}
        rowFormatter={(item) => ({
          // todo: i want to override the selection behavior...
          sx: { '> *': { bg: 'transparent !important;' } },
        })}
        selectedId={routeData.si_imageId}
        spacingX="snug"
        spacingY="240"
      />
    </TableContainer>
  )
}

function FixExpandee(props: ExpandeeProps<Fixable>) {
  const { height, index, item } = props
  const { scanFix, selectedSheet } = useScanlogContext()
  const { editState, pos, setChanges, setQuadrant } = scanFix

  // useEffect(() => {
  //   console.log(item)
  // }, [item])

  let actions: ReactNode

  if (
    editState === 'no-results' ||
    editState === 'waiting-for-choice' ||
    editState === 'wating-for-submit'
  ) {
    actions = <QRBPicker />
  } else if (editState === 'waiting-for-input') {
    actions = <ScanlogPinput />
  } else if (editState === 'waiting-for-results') {
    actions = <LoadingDots />
  } else if (editState === 'idle') {
    actions = (
      <ButtonGroup
        alignItems="center"
        display="flex"
        isAttached={true}
        size="xs"
        variant="outline"
      >
        <Button onClick={() => setChanges('pending')}>
          Edit {selectedSheet.length === 2 ? 'sheet' : 'page'}
        </Button>
      </ButtonGroup>
    )
  }

  return (
    <HStack
      borderTopWidth="1px"
      borderTopStyle="dashed"
      gap={4}
      height={`${height}px`}
      p={4}
    >
      <VStack gap={1}>
        <PositionInput
          onChange={(next) => setQuadrant(next)}
          value={pos.quadrant}
          variant="scanlog"
        />
        {/* todo: label properly */}
        <HStack fontSize="xs" gap={1}>
          <Icon as={IcoSearch} />
          <Txt as="span" ml={0}>
            Zoom
          </Txt>
        </HStack>
      </VStack>
      {actions}
    </HStack>
  )
}

type QRBPickerProps = {}

function QRBPicker(props: QRBPickerProps) {
  const { candidateInputs, changes, qrbPrefix, selectedFixable, setChanges } =
    useScanFixContext()
  const candidates = selectedFixable?.candidates ?? []

  let matchNote = candidateInputs?.xpageId ? null : candidates.length === 1 ? (
    `There was 1 match`
  ) : candidates.length === 0 ? (
    <>
      There were no matches for{' '}
      <Ex>
        {qrbPrefix}-{candidateInputs?.qrbComplete}
      </Ex>
    </>
  ) : (
    `There were ${candidates.length} matches`
  )

  return (
    <VStack alignItems="start" gap={2}>
      <Txt>{matchNote}</Txt>
      {candidates.length > 0 && (
        <ToggleGroup.Root
          onChange={(value) => setChanges(parseInt(value))}
          preventNone={true}
          type="single"
          value={changes.toString()}
        >
          <ButtonGroup isAttached={true}>
            {candidates.map((page, idx) => (
              <ToggleGroup.Button
                key={idx}
                colorScheme="blue"
                height="unset"
                maxHeight="unset"
                pr={0}
                py={1}
                value={idx.toString()}
              >
                {page.status === 'blank' ? (
                  'Blank'
                ) : (
                  <HStack gap={2}>
                    <Txt flexShrink={0}>{page.packetIndex + 1}</Txt>
                    <VStack width="150px">
                      {[
                        getFullName(page.student) ?? '<unnamed>',
                        page.packet.name,
                        page.course,
                      ].map((p, idx) => (
                        <Txt
                          fontSize="xs"
                          key={idx}
                          overflow="hidden"
                          textAlign="start"
                          textOverflow="ellipsis"
                          whiteSpace="nowrap"
                          width="100%"
                        >
                          {p}
                        </Txt>
                      ))}
                    </VStack>
                  </HStack>
                )}
              </ToggleGroup.Button>
            ))}
          </ButtonGroup>
        </ToggleGroup.Root>
      )}
      <Button onClick={() => setChanges('pending')} size="xs">
        Go back
      </Button>
    </VStack>
  )
}
