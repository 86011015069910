import {
  Button,
  Checkbox,
  CircularProgress,
  useBoolean,
} from '@chakra-ui/react'
import { IcoExperimental, IcoQRCode } from '@paper/icons'
import { getFullName } from '@paper/utils'
import { ReactNode } from 'react'
import { HSep, TextStack, Txt } from '~src/components'
import { formatUnits } from '~src/utils/messages'
import { usePdfBuilderContext } from './pdfBuilderProvider'

type PdfPrintGenerateProps = {
  isDisabled?: boolean
  isWaiting?: boolean
  onClick?(): void
}

export function PdfPrintGenerate(props: PdfPrintGenerateProps) {
  let { isDisabled, isWaiting } = props
  const { generate, isExperimental, selected, stats } = usePdfBuilderContext()
  const { isMultiPart, pageCounts, pdfCount } = stats

  // don't allow if generating in progress
  if (selected.status === 'in-progress') {
    isDisabled = true
  }

  const pageCountsLabel = pageCounts
    .map((c) => formatUnits(c, 'page'))
    .join(', ')

  const body = (
    <TextStack flexShrink={0} minWidth={isMultiPart ? '150px' : null}>
      <TextStack.Top>{formatUnits(pdfCount, 'PDF')}</TextStack.Top>
      <TextStack.Bottom variant="loose">{pageCountsLabel}</TextStack.Bottom>
    </TextStack>
  )

  const dataCy = 'btn-generate-pdf'
  const iconSize = '24px'

  const icon = isWaiting ? (
    <CircularProgress
      color="yellow.500"
      isIndeterminate={true}
      size={iconSize}
    />
  ) : (
    <IcoQRCode style={{ flexShrink: 0, fontSize: iconSize }} />
  )

  const onClick = () => {
    generate(selected, isExperimental)
    props.onClick?.()
  }

  let pilot: ReactNode

  // todo: this is heavily copy/pasted, but not expecting it to live side-by-side for long
  if (isExperimental) {
    // hardcode to J&J so don't they expand the pilot without us
    const section = selected.sections?.[0]
    const teacherName = section && getFullName(section?.teacher)
    const allowedSet = new Set(['Julie Davis', 'Jamelia Joseph'])
    if (section?.schoolId === '202' && allowedSet.has(teacherName)) {
      const icon = isWaiting ? (
        <CircularProgress
          color="yellow.500"
          isIndeterminate={true}
          size={iconSize}
        />
      ) : (
        <IcoExperimental style={{ flexShrink: 0, fontSize: iconSize }} />
      )

      pilot = (
        <>
          <HSep />
          <Button
            autoFocus={true}
            data-cy={dataCy}
            height="auto"
            isDisabled={isDisabled}
            justifyContent="start"
            leftIcon={icon}
            onClick={onClick}
            py={3}
            size="lg"
            textAlign="start"
            variant="outline"
            width="100%"
          >
            <TextStack flexShrink={0} minWidth={isMultiPart ? '150px' : null}>
              <TextStack.Top>
                {formatUnits(pdfCount, 'PDF')} (Experimental)
              </TextStack.Top>
              <TextStack.Bottom variant="loose">
                {formatUnits(selected.packet.pages.length, 'page')}
              </TextStack.Bottom>
            </TextStack>
          </Button>
        </>
      )
    }
  }

  return (
    <>
      <Button
        autoFocus={true}
        data-cy={dataCy}
        height="auto"
        isDisabled={isDisabled}
        justifyContent="start"
        leftIcon={icon}
        onClick={onClick}
        py={3}
        size="lg"
        textAlign="start"
        variant="outline"
        width="100%"
      >
        {body}
      </Button>
      {pilot}
    </>
  )
}

/**
 * Helpers to confirm user wants to regenerate
 */
export const useRegenerateCheck = () => {
  const { selected } = usePdfBuilderContext()
  // todo: auto-reset on selected and/or status change?
  const [confirmed, setConfirmed] = useBoolean(false)

  const needsConfirm = selected?.status === 'success'

  const element = needsConfirm && (
    <Checkbox
      data-cy="cb-confirm"
      isChecked={confirmed}
      onChange={setConfirmed.toggle}
      size="sm"
    >
      <Txt as="span" pl={0.5}>
        Confirm that you need to regenerate
      </Txt>
    </Checkbox>
  )

  const shouldBeDisabled = needsConfirm && !confirmed

  return {
    confirmed,
    element,
    needsConfirm,
    reset: setConfirmed.off,
    shouldBeDisabled,
  }
}
