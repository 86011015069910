import { APIs, ResultOf } from '@paper/api-specs'
import { useRouter } from '@paper/route'
import { DirPacket, SectionAxisItem, XpacketSW } from '@paper/schema'
import orderBy from 'lodash/orderBy'
import { ListDigest, useClientCrunch, useListCallbacks } from '~src/blocks/list'
import { RD_SW_JumpToQ, ScanNoscan } from '~src/routelist'
import { NR_LABEL } from './data-questions'
import { laXpacket } from './listAdapters'
import { useApiQuery } from './useApiQuery'

type HasScanCounts = Record<ScanNoscan, number>
export type XpacketDigest = ListDigest<
  XpacketSW,
  {
    preAnswerFilterXpackets: XpacketSW[]
    sections: SectionAxisItem[]
    scanCounts: HasScanCounts
  }
>

type Matcher = 'filledStr' | 'pts'
type CrunchKey = {
  answer?: { label: string; matcher: Matcher; qIndex: number }
  hasScans: ScanNoscan
  sectionId: string
}

/**
 * @deprecated Not really, but 2 issues...
 * (1) Passing in packet, but getting params from routeData
 * (2) Copy/pasted for the new scan log
 */
export const useXpacketDigest = (packet: DirPacket): XpacketDigest => {
  const adapter = laXpacket
  const { routeData } = useRouter<RD_SW_JumpToQ>()
  const { curriculumId, packetId, teacherId } = routeData
  const { f_ans, f_sectionId, f_scans, qId } = routeData

  ////////////////////////////
  // Server
  ////////////////////////////

  const qResult = useApiQuery({
    apiSpec: APIs['dir.listXpackets'],
    queryVars: { body: { curriculumId, packetIds: [packetId], teacherId } },
    useQueryProps: {
      enabled: !!(teacherId && packet),
      gcTime: 0, // gcTime=0 remove from cache immediately when umounted
    },
  })

  ////////////////////////////
  // Client refinement
  ////////////////////////////
  const qIndex = packet?.questions.findIndex((q) => q.id === qId)
  const crunchKey: CrunchKey = {
    answer: f_ans &&
      qIndex >= 0 && {
        label: f_ans,
        matcher:
          packet.questions[qIndex]?.type === 'GRID' ? 'pts' : 'filledStr',
        qIndex,
      },
    hasScans: f_scans,
    sectionId: f_sectionId,
  }
  const crunched = useClientCrunch(qResult, crunchKey, crunchXpackets)

  ////////////////////////////
  // Selected state and callbacks
  ////////////////////////////
  const listCallbacks = useListCallbacks(crunched?.items, adapter)

  return {
    adapter,
    qResult,
    success: !qResult.isSuccess ? null : { ...crunched, ...listCallbacks },
  }
}

type Predicate<T> = (item: T) => boolean

const crunchXpackets = (
  data: ResultOf<'dir.listXpackets'>,
  filters: CrunchKey
) => {
  let { sections, xpackets: allXpackets } = data
  let sectionMap = new Map(
    sections.map((s) => [s.id, new Set(s.students.map((p) => p.id))])
  )

  // prep filters
  const { answer, hasScans, sectionId } = filters

  const predicates: Predicate<XpacketSW>[] = [
    (xp) => sectionId == null || sectionMap.get(sectionId)?.has(xp.student.id),
    (xp) =>
      hasScans == null ||
      (hasScans === 'noscans' && xp.status === 'missing') ||
      (hasScans === 'scans' && xp.status !== 'missing'),
  ]

  // Accumulators
  let filtered: XpacketSW[] = []
  const scanCounts: HasScanCounts = { scans: 0, noscans: 0 }

  allXpackets.forEach((xp) => {
    // todo: need to clean this up!
    const filterResults = predicates.map((p) => p(xp))
    const passesSection = filterResults[0]
    const passesStatus = filterResults[1]

    // if passes all other filters, then increment counters
    if (passesSection) {
      scanCounts[xp.status === 'missing' ? 'noscans' : 'scans'] += 1
    }
    // if passes all tests, include in filtered
    if (filterResults.every((p) => p)) {
      filtered.push(xp)
    }
  })

  // keep track of pre-answer filter for q/std aggs
  const preAnswerFilterXpackets = filtered
  if (answer) {
    filtered = filtered.filter((xp) => {
      const xpQ = xp.qs?.[answer.qIndex]
      return answer.label === NR_LABEL
        ? xpQ?.filledStr === ''
        : answer.matcher === 'pts'
        ? xpQ?.pts.toString() === answer.label
        : xpQ?.filledStr?.includes(answer.label)
    })
  }

  return {
    empty: allXpackets.length === 0,
    items: orderBy(filtered, (xp) => xp.student?.lastfirst.toLowerCase()),
    otherData: {
      preAnswerFilterXpackets,
      scanCounts,
      sections: orderBy(sections, (p) => p.name),
    },
  }
}
