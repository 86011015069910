import {
  ApiDef,
  ApiDefBase,
  ApiFetcher,
  ApiInput,
  ApiKey,
  ApiOptionsData,
  ResultOf,
} from '@paper/api-specs'
import { asyncMap } from '@paper/utils'
import {
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import type { Except } from 'type-fest'
import { useUser } from '~src/blocks/userProvider'

type QueryFnProps<A extends ApiDefBase> = {
  fetchAs: ApiFetcher<A>
  plainFetch(): Promise<A['result']>
  url: string
} & ApiOptionsData<A>

type UseApiQueryProps<A extends ApiDefBase, U = A['result']> = {
  apiSpec: ApiDef<A>
  /** todo: explain! */
  queryFn?: (props: QueryFnProps<A>) => Promise<U>
  queryVars: ApiInput<A>
  /** Optional extra bit to add to QueryKey */
  queryKeySuffix?: QueryKey
  useQueryProps: Except<UseQueryOptions<U>, 'queryFn' | 'queryKey'>
}

/** @deprecated todo: this is wrong and doesn't account for changes to result */
export type UseApiQueryResult<K extends ApiKey> = UseQueryResult<ResultOf<K>>

export function useApiQuery<A extends ApiDefBase, U = A['result']>(
  props: UseApiQueryProps<A, U>
) {
  const { apiSpec, queryKeySuffix, queryVars, useQueryProps } = props
  const { fetchAs } = useUser()
  const { factory, method } = apiSpec
  // Get queryKey and url
  const { queryKey, url } = factory(queryVars, queryKeySuffix)
  // todo: need to decide on body vs. json terminology and when to convert between them
  // todo: i liked body vs. result since both are generally json, but `json` is what the fetch library uses
  const { body: json, searchParams } = queryVars
  // @ts-expect-error
  const apiOptions: ApiOptionsData<A> = { json, method, searchParams }

  /** Plain fetch function that should cover most cases */
  const plainFetch = () => apiSpec.fetch({ fetchAs, queryVars })

  let queryFn = props.queryFn ?? (({ plainFetch }) => plainFetch())

  return useQuery({
    ...useQueryProps,
    queryFn: () => queryFn({ fetchAs, plainFetch, url, ...apiOptions }),
    queryKey,
  })
}

type MutationFnProps<A extends ApiDefBase> = {
  fetchAs: ApiFetcher<A>
  method: A['method']
  json: A['body']
  url: string
}

type UseApiMutationProps<A extends ApiDefBase, U, P> = {
  apiSpec: ApiDef<A>
  mutationFn?: (props: MutationFnProps<A>) => Promise<U>
  preMutationFn?: (data: P) => A['body']
  useMutationProps: Except<
    UseMutationOptions<U, Error, A['body']>,
    'mutationFn'
  >
}

export function useApiMutation<
  A extends ApiDefBase,
  U = A['result'],
  P = A['body'],
>(props: UseApiMutationProps<A, U, P>) {
  let { apiSpec, mutationFn, preMutationFn, useMutationProps } = props
  const { factory, method } = apiSpec
  const { fetchAs } = useUser()
  const queryClient = useQueryClient()
  let onSuccess = useMutationProps?.onSuccess

  if (!mutationFn) {
    mutationFn = async ({ fetchAs, method, json, url }) => {
      // @ts-expect-error todo: Should not allow `get` mutations
      return await fetchAs(url, { method, json }).json()
    }
  }

  if (apiSpec.invalidateOnMutationSuccess?.length) {
    // todo: may need more granularity on when and in what conditions we validate
    onSuccess = async (...args) => {
      await asyncMap(apiSpec.invalidateOnMutationSuccess, (key) =>
        queryClient.invalidateQueries({ queryKey: [key] })
      )
      await useMutationProps?.onSuccess?.(...args)
    }
  }

  return useMutation({
    ...useMutationProps,
    mutationFn: async (data: P) => {
      // todo: not completely sure that this is typed correctly
      let json = preMutationFn ? preMutationFn(data) : (data as A['body'])
      // todo: same question above of whether factory should pare off url params
      // todo: probably better if `factory` returns json
      const { url } = factory(json)
      console.log(json)
      return mutationFn({ fetchAs, method, json, url })
    },
    onSuccess,
  })
}
