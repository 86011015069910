import { Box, Checkbox, Heading, List, ListItem } from '@chakra-ui/react'
import { produce } from 'immer'
import { Fragment, ReactNode, useState } from 'react'
import { TabImageViewer } from '~src/blocks/imageViewer'
import { VStack } from '~src/components'
import { Wiz } from '../publishLayout'
import { instruct } from './entryInstructions'
import { useSetWizCurStepError, useWizContext } from './wizProvider'

export function FormUpload() {
  const { formik, imageUrls, printFields } = useWizContext()

  const content = formik.values
  const { uploadChecklist } = instruct
  const [checklist, setChecklist] = useState(() =>
    uploadChecklist.map(() => false)
  )

  useSetWizCurStepError(
    !printFields.dirty
      ? `You haven't made any changes.`
      : checklist.some((c) => !c)
      ? `Complete the checklist`
      : null
  )

  let checklistOrError: ReactNode

  if (!printFields.dirty) {
    checklistOrError = `You haven't made any changes so there's nothing to submit.`
  } else {
    checklistOrError = (
      <Fragment>
        <Heading as="h3" fontSize="xl">
          Verify before you submit
        </Heading>
        <List fontSize="md" spacing={2}>
          <ListItem>Scroll through the preview to ensure:</ListItem>
          {uploadChecklist.map((label, idx) => {
            return (
              <ListItem key={idx}>
                <Checkbox
                  isChecked={checklist[idx]}
                  onChange={(event) =>
                    setChecklist(
                      produce((draft) => {
                        draft[idx] = event.target.checked
                      })
                    )
                  }
                  size="md"
                  colorScheme="red"
                >
                  {label}
                </Checkbox>
              </ListItem>
            )
          })}
        </List>
      </Fragment>
    )
  }

  console.log({ imageUrls })

  return (
    <Wiz.FormArea as="section" display="flex" gap={12} overflowX="auto">
      <VStack alignItems="stretch" gap={4} minW="200px">
        {checklistOrError}
      </VStack>
      <Box display="flex" flexGrow={1} minWidth="500px" maxWidth="600px">
        <TabImageViewer
          hideNumbers={true}
          imageType="blank"
          pkt={content}
          urls={imageUrls.blank}
        />
      </Box>
    </Wiz.FormArea>
  )
}
