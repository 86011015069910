import {
  As,
  Box,
  Button,
  ButtonProps,
  Icon,
  MenuButtonProps,
  Portal,
} from '@chakra-ui/react'
import { BigIconFontSize } from '@paper/styles'
import * as _DropdownMenu from '@radix-ui/react-dropdown-menu'
import debounce from 'lodash/debounce'
import { forwardRef, ReactNode, useCallback, useState } from 'react'
import { IcoCaretDown, IcoCaretUp, MenuAnimationLength } from '.'

export const DropdownMenu = _DropdownMenu

type UseMenuProps = { caret?: boolean; defaultOpen?: boolean; shroud?: boolean }
export function useMenu(props: UseMenuProps = {}) {
  const [open, setOpen] = useState(props.defaultOpen)

  // todo: there's some issue with radix where not debouncing causes the menu to pop open again
  // todo: not sure what the issue is yet, so this delay may not work on every machine!
  const onOpenChange = useCallback(
    debounce(setOpen, 200, { leading: true, trailing: false }),
    []
  )

  const menuButtonProps: Partial<ButtonProps> = {
    // prettier-ignore
    rightIcon: props.caret ? (open ? <IcoCaretUp /> : <IcoCaretDown />) : undefined,
    variant: 'ghost',
  }
  const shroud = !props.shroud ? null : (
    // todo: radix menu doesn't support overlay
    // todo: brittle because radix and chakra don't know about each other's overlays...
    <Portal>
      <Box
        bg={open ? 'rgba(0,0,0,.25)' : 'transparent'}
        data-cy="menu-shroud"
        left="-100vw"
        height="201vh"
        onClick={() => setOpen(false)}
        pointerEvents={open ? null : 'none'}
        position="fixed"
        top="-100vh"
        transition={`background-color ${MenuAnimationLength} ease`}
        width="201vw"
      />
    </Portal>
  )

  /**
   * Slight curry shortcut
   * @example
   * const { getIconMenuButton } = useMenu(...)
   * getIconMenuButton({ as: IcoAwesome })
   */
  const getIconMenuButton = (props: { as: As<any> }) => (
    <IconMenuButton {...menuButtonProps} as={props.as} />
  )

  return { getIconMenuButton, menuButtonProps, onOpenChange, open, shroud }
}

export type SimpleMenuProps = UseMenuProps & {
  children: ReactNode
  trigger: (props: Partial<ButtonProps>) => ReactNode
} & Pick<
    _DropdownMenu.DropdownMenuContentProps,
    'align' | 'alignOffset' | 'collisionPadding' | 'side' | 'sideOffset'
  >

/**
 * todo: not sure if this saves enough typing to be worth it!
 */
export function SimpleMenu(props: SimpleMenuProps) {
  const {
    align,
    alignOffset,
    children,
    collisionPadding,
    side,
    sideOffset,
    trigger,
    ...rest
  } = props
  const { menuButtonProps, onOpenChange, open, shroud } = useMenu(rest)
  return (
    <DropdownMenu.Root modal={false} onOpenChange={onOpenChange} open={open}>
      <DropdownMenu.Trigger asChild>
        {trigger(menuButtonProps)}
      </DropdownMenu.Trigger>
      {shroud}
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          asChild
          align={align}
          alignOffset={alignOffset}
          collisionPadding={collisionPadding}
          side={side}
          sideOffset={sideOffset ?? 8}
        >
          {children}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

type IconMenuButtonProps = MenuButtonProps
/**
 * Large icon menu button
 */
export const IconMenuButton = forwardRef<
  HTMLButtonElement,
  IconMenuButtonProps
>(function IconMenuButton(props, ref) {
  const { as, ...menuButtonProps } = props
  return (
    <Button
      aria-label="Main menu"
      data-cy="menu-big-icon"
      {...menuButtonProps}
      flexShrink={0}
      px={1}
      ref={ref}
      size="lg"
    >
      <Icon as={as} fontSize={BigIconFontSize} />
    </Button>
  )
})
