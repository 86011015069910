import { Box, BoxProps, Button, Icon } from '@chakra-ui/react'
import { IcoFunnel, IcoSortAmountAsc, IcoSortAmountDesc } from '@paper/icons'
import { QAxisItem, TeaAxisItem } from '@paper/schema'
import { CORRECT_COLOR } from '@paper/styles'
import { truncate } from 'lodash'
import { CSSProperties, memo } from 'react'
import { HStack, TextStack, Txt, VStack } from '~src/components'
import { Opacity } from '../sw/timeGrid/timeCell'
import { SortOrder } from '../sw/timeGrid/useColSortAirlock'
import { AnswerSubGrid } from './answerSubGrid'

type AxisLabelProps<T> = {
  isFiltering?: boolean
  isSelected: boolean
  item: T
  sortOrder?: SortOrder
  z?: string
}

export const QAxisLabel = memo(function QAxisLabel(
  props: AxisLabelProps<QAxisItem>
) {
  const { isFiltering, isSelected, item, sortOrder, z } = props

  const sortIndicator =
    sortOrder === 'asc' ? (
      <Icon as={IcoSortAmountAsc} />
    ) : sortOrder === 'desc' ? (
      <Icon as={IcoSortAmountDesc} />
    ) : null

  const style: CSSProperties = isSelected
    ? {
        background: '#333',
        opacity: Opacity.focused,
        transform: 'scale(1.15)',
        zIndex: 1,
      }
    : open
    ? { background: 'rgba(255, 255, 255, 0.08)' }
    : null

  const styleZ = (aStr: string): BoxProps =>
    isSelected && aStr === z
      ? {
          borderBottomWidth: '1px',
          opacity: Opacity.focused,
        }
      : { opacity: Opacity.unfocused }

  const answerArray = Object.values(item.answers)

  return (
    <Button
      // not a button props
      as="div"
      borderRadius="unset"
      cursor="default"
      key={item.id} // todo: running into a strange issue where children aren't rendered if key is below {...menuButtonProps}
      alignItems="stretch"
      data-cy={isSelected ? `selected-packet` : undefined}
      display="grid"
      fontFamily="mono"
      gridRowGap={2}
      gridTemplateRows="1fr max-content"
      height="unset"
      justifyItems="center"
      opacity={Opacity.unfocused}
      overflowX="hidden" // #203
      p={2}
      size="xs"
      style={style}
      transition="none"
      variant="ghost"
      _hover={{
        // background: 'rgba(255, 255, 255, 0.08) !important',
        transition: 'background .25s',
      }}
      _active={{
        // background: 'rgba(255, 255, 255, 0.16) !important',
        transition: 'background .25s',
      }}
    >
      {/* note: react was complaining when these don't have keys */}
      <HStack key="ind" fontSize=".6rem">
        {isFiltering && <Icon as={IcoFunnel} />}
        {sortIndicator}
      </HStack>
      <VStack key="num">
        <Box>{item.qLabel}</Box>
        <AnswerSubGrid colCount={answerArray.length}>
          {answerArray.map(({ aStr, correct }) => (
            <Box
              key={aStr}
              {...styleZ(aStr)}
              position="relative"
              textAlign="center"
            >
              {aStr}
              {correct && (
                // todo: copy/paste with XpacketListItem
                <Txt
                  as="span"
                  color={CORRECT_COLOR}
                  fontWeight={500}
                  ml="-1px"
                  mt="-2px"
                  position="absolute"
                >
                  ✱
                </Txt>
              )}
            </Box>
          ))}
        </AnswerSubGrid>
      </VStack>
    </Button>
  )
})

export const TeaAxisLabel = memo(function TeaAxisLabel(
  props: AxisLabelProps<TeaAxisItem>
) {
  // console.log('<SAL/>')
  const { isSelected, item } = props
  const style: CSSProperties = !isSelected
    ? null
    : {
        background: '#333',
        opacity: Opacity.focused,
        //transform: 'scale(1.15)',
        //zIndex: 1,
      }

  return (
    // todo: this is not a button yet!
    <Txt
      // non-button things
      alignItems="center"
      as="div"
      display="flex"
      justifyContent="center"
      // button things
      fontSize="xs"
      height="unset"
      key={item.id}
      opacity={Opacity.unfocused}
      overflow="hidden"
      position="relative"
      size="xs"
      style={style}
      transition="none"
      whiteSpace="nowrap"
      //variant={isSelected ? 'solid' : 'ghost'}
      //_hover={{ background: 'rgba(255, 255, 255, 0.08) !important' }}
    >
      <TextStack>
        <TextStack.Top textAlign="center">{item.fullName}</TextStack.Top>
        <TextStack.Bottom fontSize=".6rem" textAlign="center">
          {truncate(item.school?.name, {
            length: 27,
            separator: ' ',
            omission: '',
          })}
        </TextStack.Bottom>
      </TextStack>
      {/* <Box
        bg="purple.700"
        position="absolute"
        left={0}
        bottom={0}
        width={formatPercent(item.pts, item.outOf)}
        height="4px"
      ></Box> */}
    </Txt>
  )
})
