import { Yup } from '@paper/schema/validation'
import { YearCodeRegex } from '@paper/utils'
import { useMemo } from 'react'
import {
  C2FamilyEd,
  C2NewEd,
  useCurriculumSetupData,
} from '../data-curriculumSetup'

/**
 * Form validator
 */
export const useValidationSchema = (
  type: 'create' | 'manage' | 'newedition'
) => {
  // todo: should probably use async mojo instead?
  // todo: or pass in the data
  // todo: in our case, the curriculum list should already exist
  const { qResult } = useCurriculumSetupData()

  const validationSchema = useMemo(() => {
    let curriculaTest = (value: C2NewEd[]) => {
      switch (type) {
        case 'create':
          if (value.filter((p) => p._status).length === 0) {
            return `You must add at least one variant`
          }
          break
        case 'manage':
          // todo: is there anything to validate here?
          break
        case 'newedition':
          // if (value.some((v) => v.id && !v._action)) {
          //   return `Each previous year variant must be resolved`
          // }

          if (!value.find((p) => p._action === 'create')) {
            return `You must add at least 1 variant`
          }
          break
      }
    }

    let validator = Yup.object<Omit<C2FamilyEd, 'id' | 'statuses' | 'syId'>>({
      curricula: Yup.mixed<C2NewEd[]>()
        .required()
        .test('curricula', null, function (value: C2NewEd[]) {
          let error = curriculaTest(value)
          if (error) {
            throw new Yup.ValidationError(error, value, this.path)
          }
          return true
        }),
      ed: Yup.string()
        .required('Edition is required')
        .matches(YearCodeRegex, 'Edition must match the format shown above'),
      family: Yup.string().required(`Family is required`),
      levels: Yup.array(Yup.string())
        .nullable() // todo: not really, but formik was otherwise ignoring my length check
        .test('levels', null, function (value: string[]) {
          // todo: Formik seems to be ignoring this.
          if (!value || value.length < 1) {
            throw new Yup.ValidationError(
              `Levels is required`,
              value,
              this.path
            )
          }
          // validating here so the error message appears at the right level
          else if (!value.every((str) => str?.trim())) {
            throw new Yup.ValidationError(
              `Check your commas!`,
              value,
              this.path
            )
          }
          return true
        }),
      subject: Yup.string().required(`Subject is required`),
    })

    // Disallow duplicate family+edition
    // This isn't relevant for manage since curricula are immutable
    if (type === 'create' || type === 'newedition') {
      validator = validator.test('ed', null, function (value: C2FamilyEd) {
        if (
          qResult.data?.find(
            (c) => c.ed === value.ed && c.family === value.family
          )
        ) {
          throw new Yup.ValidationError(
            `A "${value.ed} ${value.family}" curriculum family already exists`,
            value,
            'ed'
          )
        }
        return true
      })
    }

    return validator
  }, [qResult.data, type])

  return validationSchema
}
