import { chakra, PropsOf } from '@chakra-ui/react'
import { XOR } from '@paper/utils'
import { Fragment } from 'react'

type SvgXProps = XOR<
  // todo: this isn't memo friendly...
  { xywh: [x: number, y: number, w: number, h: number] },
  { xyxy: [x1: number, y1: number, x2: number, y2: number] }
> & { pad?: number } & PropsOf<'line'>

/**
 * Returns an svg X
 * todo: using `chakra.line` b/c `chakra.path` has a bug with `d`
 * @example
 * <svg>
 *   <SvgX ... />
 * </svg
 */
export function SvgX(props: SvgXProps) {
  let { pad, xywh, xyxy, ...rest } = props
  pad ||= 0

  let [x, y, w, h] = xywh ?? xyxy
  if (xyxy) {
    w = w - x
    h = h - y
  }

  const x1 = x + pad
  const x2 = x + w - pad
  const y1 = y + pad
  const y2 = y + h - pad

  // swap Ys to draw the X
  const y1y2 = [
    [y1, y2],
    [y2, y1],
  ]

  return (
    <Fragment>
      {y1y2.map(([y1, y2], idx) => {
        return (
          <chakra.line
            pointerEvents={'none' as any}
            {...(rest as any)} // todo: typing issues?
            key={idx}
            x1={x1}
            x2={x2}
            y1={y1}
            y2={y2}
          />
        )
      })}
    </Fragment>
  )
}
