import {
  Button,
  ButtonGroup,
  ButtonProps,
  Heading,
  Link,
  ModalBody,
  ModalContent,
  ModalFooter,
} from '@chakra-ui/react'
import { SetupCourse } from '@paper/schema'
import { ENDASH, formatSchoolYear } from '@paper/utils'
import { Form, useFormikContext } from 'formik'
import { HSep, Txt, VStack } from '~src/components'
import { useHelpLink } from '~src/utils/useHelpLink'
import { useSchoolSetupContext } from '../schoolSetupContext'
import { CurriculaForm } from './formCurricula'
import { SectionSetupTable } from './sectionSetupTable'

type CourseFormProps = {}

export function CourseForm(props: CourseFormProps) {
  const { school, syId } = useSchoolSetupContext()
  const { dirty, isSubmitting, isValid, errors, values } =
    useFormikContext<SetupCourse>()

  return (
    <ModalContent
      as={Form}
      borderRadius={4}
      maxHeight="calc(100vh - 32px)"
      minHeight="700px"
      maxW="1200px"
      width="calc(100vw - 32px)"
    >
      <ModalBody display="flex" flexDirection="column" overflow="hidden" pt={6}>
        <Heading as="h3" fontSize="xl" fontWeight={300} userSelect="none">
          {[values.name, school.name, formatSchoolYear(syId)].join(
            ` ${ENDASH} `
          )}
        </Heading>
        <HSep boxProps={{ my: 6 }} />
        <VStack alignItems="stretch" overflowY="auto">
          <CurriculaForm />
          <HSep boxProps={{ my: 6 }} />
          <Heading
            as="h4"
            fontSize="xl"
            fontWeight={300}
            mb={4}
            userSelect="none"
          >
            Sections
          </Heading>
          <SectionSetupTable />
        </VStack>
      </ModalBody>
      <ModalFooter>
        {typeof errors.sections === 'string' && (
          <Txt
            // todo: proper form integration!
            fontStyle="italic"
            fontSize="sm"
            mr={4}
          >
            {errors.sections}
          </Txt>
        )}
        <ButtonGroup gap={1} size="sm">
          <HelpButton />
          <Button type="reset" variant="outline">
            Cancel
          </Button>
          <Button
            data-cy="c2-submit"
            colorScheme="red"
            isDisabled={!dirty || isSubmitting || !isValid}
            type="submit"
          >
            Submit
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </ModalContent>
  )
}

type HelpButtonProps = ButtonProps
function HelpButton(props: HelpButtonProps) {
  const helpLink = useHelpLink()
  return (
    <Link href={helpLink} isExternal={true}>
      <Button as="div" children="Help" {...props} />
    </Link>
  )
}
