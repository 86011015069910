import { useEffect, useRef } from 'react'
import { useListContext } from '.'

// todo: rough plan is to remove unlistedFallback, which will simplify this a bit
type EnsureSelectedProps<T> = {
  unlistedFallback?: T
  nextFromLast?: (list: T[], last: T) => T
}

export function EnsureSelected<T>(props: EnsureSelectedProps<T>) {
  const { unlistedFallback, nextFromLast } = props
  const {
    adapter,
    items: list,
    onSelect,
    selectedId,
    selectedItem,
  } = useListContext()
  const lastSelected = useRef<T>()

  // if already selected (can't shortcircuit b/c hooks)
  let item: T = selectedItem

  // Check if unlisted fallback is selected
  if (unlistedFallback && selectedId === adapter.idFromItem(unlistedFallback)) {
    item = unlistedFallback
  }

  const isReadyToSelect = !item && (list?.length || unlistedFallback)

  useEffect(() => {
    let nextItem: T
    if (isReadyToSelect) {
      // allow preference from lastSelected
      if (lastSelected.current && nextFromLast) {
        nextItem = nextFromLast(list, lastSelected.current)
      }
      // default to first (or fallback)
      nextItem = nextItem || list?.[0] || unlistedFallback
      // navigate to next
      onSelect(nextItem)
    }

    // keep ref up-to-date with selected
    if (item && list) {
      lastSelected.current = item
    }
  }, [item, isReadyToSelect, unlistedFallback])
  return null
}
