import type { DirPacket, Packetmeta } from '@paper/schema'
import max from 'lodash/max'

/**
 * Calculates radioactive date and state for entire packet
 */
export const parseRadioactive = (pm: Packetmeta) => {
  const date = max([pm._updates.pdf, pm._updates.publish].map((u) => u?.time))
  const printDate = pm._printed?.first
  return { date, radioactive: printDate && date > printDate }
}

export const checkRadioactive = (packet: DirPacket, date: number) => {
  if (!packet || !date) {
    return false
  }
  return packet.pub.stage === 'recalled' || packet.pub.date > date
}

export function getPrintState(packet: Packetmeta) {
  const isPrinted = !!packet._printed?.first
  const { radioactive } = parseRadioactive(packet)
  return { isPrinted, radioactive }
}

export function getAggPrintState(packets: Packetmeta[]) {
  const states = packets?.map(getPrintState)
  const isPrinted = states?.some((s) => s.isPrinted)
  const radioactive = states?.some((s) => s.radioactive)
  return { isPrinted, radioactive }
}
