import { Box, Button, ButtonProps, chakra } from '@chakra-ui/react'
import { useRouter } from '@paper/route'
import { ScanStatus, SheetChunk } from '@paper/schema'
import { DEFAULT_FG } from '@paper/styles'
import { times } from 'lodash'
import { RefObject } from 'react'
import { scanStatusToColor } from '~src/blocks/scanStatus'
import type { RD_SW_Scanlog } from '~src/routelist'
import { useScrollIntoView } from '~src/utils/useScroll'

const btnSize = 'xs'
const btnWidth = 38
const btnGap = 4
const clipPct = 65

export function ScanlogChunk(props: SheetChunk) {
  const { items, startIndex } = props

  const { dispatchStay, routeData } = useRouter<RD_SW_Scanlog>()

  // multiple pages in the chunk
  const isDetached = items.length > 1

  // Count pages in the chunk
  const chunkLength = items.flat().length

  // Grab 1st and last page of this chunk
  const firstLast = [items[0][0], items.at(-1).at(-1)]
  if (firstLast[0] === firstLast[1]) {
    firstLast.pop()
  }

  const pages = firstLast.map(
    (p, idx): PageHalfProps => ({
      ...p,
      colorScheme: scanStatusToColor(p.status),
      isDetached,
      imageNumber: startIndex + (idx === 0 ? 1 : chunkLength),
      isSelected: routeData.si_imageId === p.id,
      onClick: () => dispatchStay({ si_imageId: p.id }),
      variant:
        // todo: need to centralize this, color, etc.
        p.status === 'infer-blank' || p.status === 'not-paper-qr'
          ? 'ghost'
          : 'outline',
      whichHalf:
        firstLast.length === 1 ? 'single' : idx === 0 ? 'obverse' : 'reverse',
    })
  )

  const domRef = useScrollIntoView(
    pages.some((p) => p.isSelected),
    { behavior: 'smooth', block: 'nearest', inline: 'center' }
  )

  pages[0].domRef = domRef

  // Add some space for detached
  const width = btnWidth + (isDetached ? btnGap : 0)

  return (
    <Box flexShrink={0} position="relative" width={`${width}px`}>
      {pages.map((pr) => (
        <PageHalf key={pr.id} {...pr} />
      ))}
      {isDetached && (
        <SvgLines colorScheme={pages[0].colorScheme} perpendicular={true} />
      )}
    </Box>
  )
}

// todo: combine these i think?...
type PageBtnProps = {
  colorScheme: ButtonProps['colorScheme']
  id: string
  imageNumber: number
  status: ScanStatus
  variant?: ButtonProps['variant']
}

type PageHalfProps = PageBtnProps & {
  domRef?: RefObject<any>
  isDetached: boolean
  isSelected: boolean
  onClick: ButtonProps['onClick']
  whichHalf: 'obverse' | 'reverse' | 'single'
}

function PageHalf(props: PageHalfProps) {
  const {
    colorScheme,
    domRef,
    imageNumber,
    isDetached,
    isSelected,
    onClick,
    status,
    whichHalf,
  } = props

  // todo: need to adjust the color...
  const adjColor = new Set(['scanPresentGray'])
  const borderColor = adjColor.has(colorScheme) ? `${colorScheme}.500` : null
  const color = adjColor.has(colorScheme) ? DEFAULT_FG : null

  const clipPath =
    whichHalf === 'single'
      ? null
      : whichHalf === 'obverse'
      ? `polygon(0% 0%, ${clipPct}% 0%, ${100 - clipPct}% 100%, 0% 100%)`
      : `polygon(100% 100%, ${100 - clipPct}% 100%, ${clipPct}% 0% , 100% 0%)`

  // Account for button border
  const variant = isSelected ? 'solid' : props.variant ?? 'outline'
  const offset = variant === 'outline' ? 0 : '1px'

  const children =
    whichHalf === 'single' ? (
      imageNumber
    ) : (
      <Box
        position="absolute"
        textAlign="center"
        width="50%"
        // todo: is there a nicer way?
        top={whichHalf === 'obverse' ? offset : null}
        bottom={whichHalf === 'reverse' ? offset : null}
        left={whichHalf === 'obverse' ? offset : null}
        right={whichHalf == 'reverse' ? offset : null}
      >
        {imageNumber}
      </Box>
    )

  return (
    <Button
      borderColor={borderColor}
      clipPath={clipPath}
      color={color}
      colorScheme={colorScheme}
      data-status={status}
      fontFamily="mono"
      fontSize=".65rem"
      left={isDetached && whichHalf === 'reverse' ? `${btnGap}px` : 0}
      onClick={onClick}
      overflow="hidden"
      position={whichHalf === 'reverse' ? 'absolute' : null}
      ref={domRef}
      size={btnSize}
      variant={variant}
      width={`${btnWidth}px`}
    >
      {children}
      {whichHalf !== 'single' && <SvgLines colorScheme={colorScheme} />}
    </Button>
  )
}

// todo: goofy name and interface
type SvgLinesProps = {
  colorScheme: ButtonProps['colorScheme']
  perpendicular?: boolean
}

function SvgLines(props: SvgLinesProps) {
  const { colorScheme, perpendicular } = props
  const diagonalProps = {
    stroke: `${colorScheme}.500`,
    strokeOpacity: 0.75,
    strokeWidth: perpendicular ? 6 : 2,
  }

  const lines = perpendicular ? (
    <>
      {times(2, (i) => {
        const halfLen = 20
        const gap = ((i % 2 === 0 ? -1 : 1) * halfLen) / 2.5
        return (
          <chakra.line
            key={i}
            {...diagonalProps}
            x1={50 + halfLen}
            y1={50 + gap}
            x2={50 - halfLen}
            y2={50 + gap}
          />
        )
      })}
    </>
  ) : (
    <chakra.line
      {...diagonalProps}
      x1={clipPct}
      y1="0"
      x2={100 - clipPct}
      y2="100"
    />
  )

  return (
    <svg
      height="100%"
      preserveAspectRatio={perpendicular ? null : 'none'}
      style={{
        left: 0,
        pointerEvents: 'none',
        position: 'absolute',
        top: 0,
        // todo: eyeballed
        transform: perpendicular ? 'rotate(24deg)' : null,
      }}
      viewBox="0 0 100 100"
      width="100%"
    >
      {lines}
    </svg>
  )
}
