import { Box, BoxProps, Button, Grid, Icon, StyleProps } from '@chakra-ui/react'
import {
  IcoAsterisk,
  IcoFiIgnored,
  IcoFiOtherPacket,
  IcoFiOtherUser,
  IcoFiThisPacket,
  IcoFiThisUser,
  IcoFiUnknown,
  IcoPencil,
} from '@paper/icons'
import {
  FixitBubbleStatus,
  FixitIDStatus,
  FixitSheetRow,
  FixitSlot,
  FixitSlotType,
} from '@paper/schema'
import { MISSING_COLOR } from '@paper/styles'
import { getLastFirst } from '@paper/utils'
import { useVirtualizer } from '@tanstack/react-virtual'
import { sum } from 'lodash'
import { memo, ReactNode, useEffect, useRef } from 'react'
import { HStack, TooltippedIcon, TooltippedIconProps } from '~src/components'
import { pxx } from '~src/utils/layout'
import { scrollBoundingBoxIntoView } from '~src/utils/useScroll'
import { OnSelectSlot, useFixitContext } from './fixitProvider'
import { FixitSym } from './fixitSymbols'
import { RubricXViz } from './rubricXs'

const InnerHeight = 18
const BtnPadX = 8
const BtnPadY = 8
const LabelIconProps: TooltippedIconProps = {
  fontSize: 'lg',
  tooltipProps: { offset: [0, 11], placement: 'start' },
}

const ItemBorderWidth = 2

const ItemCols = [InnerHeight, InnerHeight, 28, InnerHeight]
const ItemColsPadded = ItemCols.map((p) => p + 2 * BtnPadX)
const GridTemplateColumns = ItemColsPadded.map(pxx).join(' ')

const ItemWidth = sum(ItemColsPadded) + ItemBorderWidth * 2

const ButtonHeight = 2 * BtnPadY + InnerHeight
const ItemHeight = ButtonHeight + ItemBorderWidth

type FixitScanListProps = {}

export function FixitScanList(props: FixitScanListProps) {
  const { rows, onSelectSlot, selectedSlot } = useFixitContext()

  const parentRef = useRef()
  const rv = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => ItemHeight,
  })

  // todo: need to clean this up!
  const typeMap = {
    packet: 0,
    student: 1,
    score: 2,
    rubric: 3,
  }

  const highlightColIdx = typeMap[selectedSlot?.type] as number

  const highlight = selectedSlot && highlightColIdx >= 0 && (
    <Highlight
      height={ButtonHeight}
      left={
        ItemBorderWidth +
        sum(ItemColsPadded.map((p, idx) => (idx < highlightColIdx ? p : 0)))
      }
      top={ItemBorderWidth + ItemHeight * selectedSlot.sheetIndex}
      width={ItemColsPadded[highlightColIdx]}
    />
  )

  return (
    <Box
      ref={parentRef}
      height="100%"
      overflow="auto"
      position="relative"
      width={pxx(ItemWidth + 20)}
    >
      <Box height={`${rv.getTotalSize()}`} position="relative">
        {rv.getVirtualItems().map((vr) => {
          const item = rows[vr.index]
          return (
            <Box
              key={vr.key}
              display="flex"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: `${vr.size}px`,
                transform: `translateY(${vr.start}px)`,
              }}
            >
              <FixitScanListItem
                isLast={vr.index === rows.length - 1}
                item={item}
                onSelect={onSelectSlot}
              />
            </Box>
          )
        })}
      </Box>
      {highlight}
    </Box>
  )
}

type HighlightProps = {
  height: number
  left: number
  top: number
  width: number
}

function Highlight(props: HighlightProps) {
  let { height, left, top, width } = props
  const borderWidth = 2.5
  const pad = 1

  height -= 2 * pad
  left += pad
  top += pad
  width -= 2 * pad

  const transition = ['height', 'left', 'top', 'width']
    .map((p) => `${p} 0.2s`)
    .join(', ')

  const ref = useRef<HTMLDivElement>()

  useEffect(() => {
    if (ref.current) {
      const bb = { top, left, height, width }
      scrollBoundingBoxIntoView(ref.current.parentElement, bb)
    }
  }, [left, top])

  return (
    <Box
      borderColor="yellow.500"
      borderRadius="md"
      //borderStyle="dashed"
      borderWidth={borderWidth}
      height={pxx(height)}
      left={pxx(left)}
      pointerEvents="none"
      position="absolute"
      ref={ref}
      top={pxx(top)}
      transition={transition}
      width={pxx(width)}
    />
  )
}

type FixitScanListItemProps = {
  isLast: boolean
  item: FixitSheetRow
  onSelect: OnSelectSlot
}

const FixitScanListItem = memo(function FixitScanListItem(
  props: FixitScanListItemProps
) {
  const { isLast, item, onSelect } = props

  const containerProps: BoxProps = {
    borderBottom: isLast ? null : 0,
    borderColor: 'gray.200',
    borderRadius: 0,
    borderWidth: ItemBorderWidth,
    height: pxx(ItemHeight),
    width: pxx(ItemWidth),
    userSelect: 'none',
  }

  const fieldMap: Record<
    FixitSlotType,
    { Component(props: { slot: FixitSlot }): JSX.Element }
  > = {
    packet: { Component: PacketLabel },
    student: { Component: StudentLabel },
    score: { Component: ScoreLabel },
    rubric: { Component: RubricLabel },
  }

  const columns = Object.entries(fieldMap)
    .map(([key, { Component }], index) => {
      const slot = item.slots[key as FixitSlotType]
      if (slot) {
        return (
          <FieldWrapper
            gridColumn={index + 1}
            hasSavedChanges={slot._savedChanges}
            isDirty={slot._dirty}
            isEditable={slot._editable}
            key={key}
            onClick={() => onSelect(slot)}
          >
            <Component slot={slot} />
          </FieldWrapper>
        )
      }
    })
    .filter((p) => p)

  return (
    <Grid
      {...containerProps}
      fontSize="sm"
      gridTemplateColumns={GridTemplateColumns}
      overflow="hidden"
      placeItems="center"
    >
      {columns}
    </Grid>
  )
})

// todo: does this already exist?
type LayoutishProps = StyleProps & { children: ReactNode }

type FieldWrapperProps = {
  children: ReactNode
  gridColumn: number
  hasSavedChanges: boolean
  isDirty: boolean
  isEditable: boolean
  onClick(): void
}

function FieldWrapper(props: FieldWrapperProps) {
  let { children, isDirty, isEditable, gridColumn, hasSavedChanges, onClick } =
    props

  const iconProps: StyleProps = {
    fontSize: 'xs',
    pointerEvents: 'none',
    position: 'absolute',
    right: 0.5,
    top: 1,
  }

  let icon: ReactNode

  if (isDirty) {
    icon = (
      <Icon
        {...iconProps}
        as={IcoAsterisk}
        aria-label="Unsaved changes"
        color="yellow.500"
      />
    )
  } else if (hasSavedChanges) {
    icon = (
      <Box
        {...iconProps}
        bg="manualYellow.400"
        display="flex"
        borderRadius="full"
        p="0.5px"
        right={0}
      >
        <Icon as={IcoPencil} aria-label="Has saved changes" />
      </Box>
    )
  }

  if (icon) {
    children = (
      <>
        {children}
        {icon}
      </>
    )
  }

  const containerProps: LayoutishProps = {
    borderRadius: 0,
    children,
    gridColumn,
    height: pxx(ButtonHeight),
    minWidth: '100%',
    position: 'relative',
    px: pxx(BtnPadX),
    py: pxx(BtnPadY),
  }

  // todo: better way?
  return isEditable ? (
    <Button {...containerProps} onClick={onClick} variant="ghost" />
  ) : (
    <HStack justifyContent="center" {...containerProps} />
  )
}

const deemphColor: BoxProps['color'] = 'gray.400'

type SlotLabelProps = { slot: FixitSlot }

function PacketLabel(props: SlotLabelProps) {
  const packetLabelOptions: Record<FixitIDStatus, TooltippedIconProps> = {
    success: { 'aria-label': `The current packet`, as: IcoFiThisPacket },
    'not-this': {
      'aria-label': `Not the current packet`,
      as: IcoFiOtherPacket,
      color: deemphColor,
    },
    ignore: {
      'aria-label': `Not a Ponder Paper scan`,
      as: IcoFiIgnored,
      color: deemphColor,
    },
    'no-qr': {
      'aria-label': `Unknown packet`,
      as: IcoFiUnknown,
      color: MISSING_COLOR,
    },
  }

  const which = props.slot.status
  return <TooltippedIcon {...LabelIconProps} {...packetLabelOptions[which]} />
}

function StudentLabel(props: SlotLabelProps) {
  const { slot } = props

  const studentLabelOptions: Record<FixitIDStatus, TooltippedIconProps> = {
    success: { 'aria-label': getLastFirst(slot.student), as: IcoFiThisUser },
    'not-this': {
      'aria-label': `Not in this roster`,
      as: IcoFiOtherUser,
      color: deemphColor,
    },
    ignore: {
      'aria-label': `No student`,
      as: IcoFiIgnored,
      color: deemphColor,
    },
    'no-qr': {
      'aria-label': `Unknown student`,
      as: IcoFiUnknown,
      color: MISSING_COLOR,
    },
  }

  const which = slot.status
  return <TooltippedIcon {...LabelIconProps} {...studentLabelOptions[which]} />
}

function ScoreLabel(props: SlotLabelProps) {
  const { slot } = props
  return (
    <FixitSym.Score
      placement="end"
      score={slot.score}
      status={slot.status as FixitBubbleStatus}
    />
  )
}

function RubricLabel(props: SlotLabelProps) {
  const { slot } = props
  return !slot.rubric ? (
    <FixitSym.Score placement="end" score={null} status={'unreadable'} />
  ) : (
    <RubricXViz data={slot.rubric} size={InnerHeight} />
  )
}
