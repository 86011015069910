import { Box, Icon, List } from '@chakra-ui/react'
import { IcoChevronLeft, IcoSignpost } from '@paper/icons'
import { RouteMeta } from '@paper/route'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import { useMemo } from 'react'
import { SupportNavSection } from '~src/blocks/miSupport'
import { MonsterBox } from '~src/blocks/monsterBox'
import { MISignOut } from '~src/blocks/swMenu/swMenuItems'
import { HSep, HStack, Txt, VStack } from '~src/components'
import { Routes } from '~src/routelist'
import {
  NavListSection,
  NavListSectionProps,
  RouteNavListSection,
} from '~src/routelist/navLinks'
import { SchoolYearPicker } from '~src/schoolYearAirlock'
import { useUser } from '../userProvider'
import { BigIconButton } from './bigIconButton'
import type { MainMenuProps } from './mainMenu'

type NavMenuProps = MainMenuProps

type MainMenuSpec = Omit<NavListSectionProps, 'children'> & {
  routes: RouteMeta[]
}

export function NavMenu(props: NavMenuProps) {
  const { isInternal, user } = useUser()

  const spec = useMemo(() => {
    const isAdmin = user.roles.includes('admin')

    const spec: MainMenuSpec[] = [
      {
        title: 'Packet lists',
        routes: [
          Routes.home_teacher,
          Routes.home_curriculum,
          Routes.home_school,
        ],
      },
      {
        title: 'Tools',
        routes: [
          Routes.sw_scanlog,
          Routes.sticker,
          isAdmin && Routes.illuminateLookup,
        ],
      },
      isAdmin && { title: 'Admin', routes: [Routes.setup_school] },
      isInternal && {
        title: 'Internal',
        routes: [
          Routes.setup_admin,
          Routes.setup_curriculum,
          Routes.internal_scanviz,
        ],
      },
    ]

    return spec
      .filter((p) => p)
      .map((p) => ({ ...p, routes: p.routes.filter((p) => p) }))
  }, [isInternal, user])

  const px = 2

  const titleArea = (
    <HStack flexShrink={0} fontSize="xl" gap={2} px={px} userSelect="none">
      {props.onClose ? (
        <BigIconButton
          aria-label="Close menu"
          as={IcoChevronLeft}
          data-cy="close-menu"
          onClick={props.onClose}
        />
      ) : (
        <HStack height="48px" justifyContent="center" width="48px">
          <Icon as={IcoSignpost} fontSize="3xl" />
        </HStack>
      )}
      <Txt as="h1" my={3}>
        Ponder Paper
      </Txt>
      <Box display="flex" marginLeft="auto">
        <SchoolYearPicker variant="new-home" />
      </Box>
    </HStack>
  )

  return (
    <VStack
      alignItems="stretch"
      height="100%"
      width="308px" // todo:
    >
      {titleArea}
      <HSep boxProps={{ mx: px }} />
      <VStack alignItems="stretch" fontSize="sm" gap={1} mt={2} px={4}>
        <MonsterBox autoFocus={!props.onClose} />
      </VStack>
      <NavigationMenu.Root orientation="vertical">
        <NavigationMenu.List asChild>
          <List mx={1} p={1} width="300px">
            {spec.map((props, idx) => {
              return <RouteNavListSection key={props.title || idx} {...props} />
            })}
            <SupportNavSection />
            <NavListSection>
              <MISignOut as="navlink" />
            </NavListSection>
          </List>
        </NavigationMenu.List>
      </NavigationMenu.Root>
    </VStack>
  )
}
