import { APIs } from '@paper/api-specs'
import { useRouter } from '@paper/route'
import { C2Status, Curriculum } from '@paper/schema'
import { entries, groupBy, mapEntries } from '@paper/utils'
import { orderBy, uniq } from 'lodash'
import { useMemo } from 'react'
import { useApiMutation, useApiQuery } from '~src/data/useApiQuery'
import type { RD_Setup_Curriculum } from '~src/routelist'
import { useFilters } from '~src/utils/useFilters'
import { useDeepMemo } from '~src/utils/useMemos'

export type C2NewEdAction = 'create' | 'retire' | 'createAndRetire'
export type C2NewEd = Curriculum & { _action?: C2NewEdAction }

export type C2FamilyStatus = {
  curricula: Curriculum[]
  status: C2Status
}
export type C2FamilyEd = Omit<Curriculum, '_status' | 'name' | 'variant'> & {
  /** All curricula in the family (as an input for the manage dialog) */
  curricula: C2NewEd[]
  /** grouped by status to summarize in the table */
  statuses: C2FamilyStatus[]
}

export const useCurriculumSetupData = () => {
  let qResult = useApiQuery({
    apiSpec: APIs['setup.curriculum.get'],
    queryVars: {},
    queryFn: async ({ plainFetch }) => {
      const data = await plainFetch()
      // group by family+edition for the list
      let grouped = entries(groupBy(data, (d) => d.family + d.ed))
      let rows = grouped.map(([id, curricula]): C2FamilyEd => {
        let { _status, variant, ...rest } = curricula[0]
        // group by status
        let statuses = mapEntries(
          groupBy(curricula, (p) => p._status),
          ([status, curricula]): C2FamilyStatus => {
            curricula = orderBy(curricula, (c) => c.variant)
            return { curricula, status }
          }
        )

        return { ...rest, id, curricula, statuses }
      })
      // sort
      rows = orderBy(
        rows,
        [(r) => r.levels[0], (r) => r.ed, (r) => r.family],
        ['asc', 'desc', 'asc']
      )
      return rows
    },
    useQueryProps: {},
  })

  // client side filtering
  // todo: reuse digest mechanism?
  const { routeData } = useRouter<RD_Setup_Curriculum>()
  const { ed, f_level, f_status, f_text } = routeData
  const memoKey = useDeepMemo({ ed, f_level, f_status, f_text })
  const filters = useFilters()
  let { data, levels } = useMemo(() => {
    let filtered = qResult.data

    // filter to this edition
    filtered = filtered?.filter((p) => p.ed === ed)

    // grab unique levels for this edition
    let levels = orderBy(uniq(filtered?.flatMap((p) => p.levels)))

    // apply the other filters
    if (f_status) {
      filtered = filtered?.filter((p) =>
        p.statuses.some((s) => s.status === f_status)
      )
    }
    if (f_text) {
      filtered = filtered?.filter((p) =>
        p.curricula.some(({ name }) => filters.startsWithByWord(name, f_text))
      )
    }
    if (f_level) {
      filtered = filtered?.filter((p) =>
        p.curricula.some(({ levels }) => levels.includes(f_level))
      )
    }
    return { data: filtered, levels }
  }, [qResult.data, memoKey])

  return { qResult: { ...qResult, data }, levels }
}

export const useCurriculumSubmitter = () => {
  return useApiMutation({
    apiSpec: APIs['setup.curriculum.submit'],
    useMutationProps: {},
  })
}

export function useVariantData() {
  const qResult = useApiQuery({
    apiSpec: APIs['setup.variants.get'],
    queryVars: {},
    queryFn: async ({ plainFetch }) => {
      let data = await plainFetch()
      return orderBy(data, (v) => v.name)
    },
    useQueryProps: {},
  })
  return qResult
}

export function useVariantSubmitter() {
  return useApiMutation({
    apiSpec: APIs['setup.variants.set'],
    useMutationProps: {},
  })
}
