import { APIs, BodyOf } from '@paper/api-specs'
import {
  Curriculum,
  DirPacket,
  PdfGenerateStatus,
  PdfGrain,
  PrintManifest,
  Teacher,
  TeacherSection,
} from '@paper/schema'
import { getLastFirst, sortNumeric, uniqOf } from '@paper/utils'
import { produce } from 'immer'
import { useMemo, useState } from 'react'
import { useApiQuery } from '~src/data/useApiQuery'

/**
 * Base input for the print dialog
 */
export type PdfBuilderInputItem = {
  curriculum: Curriculum
  packet: DirPacket
  teacher: Teacher
}

/**
 * Everything needed to print except for sections
 * todo: I think there may be no reason to have downloadUrls/generatedAt/printUser/status since they're always null until the next step?
 */
export type PdfBuilderPreprintable = PdfBuilderInputItem & {
  currentManifest: PrintManifest
  downloadUrls: string[]
  error?: Error
  grain: PdfGrain
  grainId: string
  inflight?: number
  status: PdfGenerateStatus
}

/**
 * Everything needed to print
 */
export type PdfBuilderPrintable = PdfBuilderPreprintable & {
  sections: TeacherSection[]
}

type UsePdfBuilderDataProps = {
  grain: PdfGrain
  input: PdfBuilderInputItem | PdfBuilderInputItem[]
  schoolId: string
}

export const usePdfBuilderPreprintables = (props: UsePdfBuilderDataProps) => {
  const { grain, input, schoolId } = props

  // Filter to packets that are printable and grainify
  const { premerged, packetIds } = useMemo(() => {
    const inputArr = !Array.isArray(input) ? [input] : input
    // Filter non-published
    const filtered = inputArr.filter((p) => p.packet.pub.stage === 'published')
    const preprintableMap = new Map<string, PdfBuilderPreprintable>()
    // Grainify
    for (let item of filtered) {
      const { curriculum } = item
      const packet =
        grain === 'packet'
          ? produce(item.packet, (draft) => {
              // remove per-teacher
              draft.scan = null
            })
          : item.packet
      const teacher = grain === 'packet' ? null : item.teacher
      const grainId =
        grain === 'packet' ? packet.id : `${packet.id}:${teacher.id}`

      preprintableMap.set(grainId, {
        currentManifest: null,
        curriculum,
        downloadUrls: null,
        grain,
        grainId,
        packet,
        status: 'none',
        teacher,
      })
    }

    const premerged = Array.from(preprintableMap.values())

    // Extract packetIds
    const packetIds = uniqOf(premerged, (p) => p.packet.id)
    return { packetIds, premerged }
  }, [grain, input])

  // todo: need to fix this api, messily trying to reuse this between teacher and school...
  // todo: infer single packet mode from input type
  const modeProps: Pick<UseGrainGenerateStatusProps, 'grainId' | 'mode'> =
    !Array.isArray(input)
      ? { grainId: premerged[0].grainId, mode: 'single' }
      : { grainId: null, mode: 'multi' }

  // Fetch generate status data
  const {
    local,
    qResult: qResultStatus,
    updateLocal,
  } = useGrainGenerateStatus({
    grain,
    packetIds,
    schoolId,
    ...modeProps,
  })

  // Merge preprintables and status
  const { hasDownloads, hasInflight, pdfList } = useMemo(() => {
    let pdfList: PdfBuilderPreprintable[] = []

    if (qResultStatus.isSuccess) {
      const { localMap } = local
      const remoteMap = qResultStatus.data
      const getStatusItem = (grainId: string) => {
        // merge local+remote
        const localItem = localMap.get(grainId)
        const remoteItem = remoteMap.get(grainId)
        return {
          downloadUrls:
            localItem?.downloadUrls ?? remoteItem?.downloadUrls ?? [],
          error: localItem?.error,
          inflight: localItem?.inflight,
          manifests: [
            ...(localItem?.manifests ?? []),
            ...(remoteItem?.manifests ?? []),
          ],
          status: localItem?.inflight
            ? 'in-progress'
            : localItem?.status ?? remoteItem?.status,
        }
      }

      for (const item of premerged) {
        const statusItem = getStatusItem(item.grainId)
        const currentManifest = statusItem?.manifests[0]

        pdfList.push({
          ...item,
          currentManifest,
          downloadUrls: statusItem?.downloadUrls,
          error: statusItem?.error,
          inflight: statusItem?.inflight,
          status: statusItem?.status ?? 'none',
        })
      }
    }

    // Sort
    pdfList = sortNumeric(pdfList, [
      (p) => p.curriculum.name,
      (p) => p.packet.name,
      (p) => getLastFirst(p.teacher),
    ])

    const hasDownloads = pdfList.some((p) => p.downloadUrls?.length)
    const hasInflight = [...local.localMap.values()].some((p) => p.inflight)

    return { hasDownloads, hasInflight, pdfList }
  }, [premerged, qResultStatus.data, local])

  return { hasDownloads, hasInflight, pdfList, qResultStatus, updateLocal }
}

type LocalItem = {
  downloadUrls: string[]
  error: Error
  inflight: number
  manifests: PrintManifest[]
  status: PdfGenerateStatus
}

type UseGrainGenerateStatusProps = BodyOf<'school.generatestatus'> & {
  mode: 'single' | 'multi'
}

/**
 * Grain generate status
 */
const useGrainGenerateStatus = (props: UseGrainGenerateStatusProps) => {
  // local
  // todo: how should this work?
  const getResetLocal = () => ({
    counter: 0,
    localMap: new Map<string, LocalItem>(),
  })

  const [local, setLocal] = useState(getResetLocal)

  const updateLocal = useMemo(() => {
    const updater = (
      grainId: string,
      item: {
        downloadUrls?: string[]
        error?: Error
        inflight?: number
        manifest?: PrintManifest
      }
    ) => {
      const { downloadUrls, error, inflight, manifest } = item
      const status: PdfGenerateStatus = item.error
        ? 'error'
        : item.inflight
        ? 'in-progress'
        : 'success'
      // Using set so things update
      setLocal(({ counter, localMap }) => {
        let prevItem = localMap.get(grainId)
        let manifests = prevItem?.manifests ?? []
        if (manifest) {
          manifests = [manifest, ...manifests]
        }
        localMap.set(grainId, {
          downloadUrls,
          error,
          inflight,
          manifests,
          status,
        })

        return { counter: counter++, localMap }
      })
    }

    const onError = (grainId: string, error: Error) => {
      updater(grainId, { error, inflight: null })
    }

    const onSuccess = updater
    const onStart = (grainId: string, when: Date) => {
      updater(grainId, { inflight: when.valueOf() })
    }

    return { onError, onStart, onSuccess }
  }, [setLocal])

  // remote
  const { grain, grainId, mode, packetIds, schoolId } = props

  // todo: this got really messy...
  let body: BodyOf<'school.generatestatus'> = { grain, packetIds, schoolId }
  if (mode === 'single') {
    body.grainId = grainId
  }

  const enabled = Object.values(body).every((value) => !!value)
  const qResult = useApiQuery({
    apiSpec: APIs['school.generatestatus'],
    queryVars: { body },
    queryFn: async ({ plainFetch }) => {
      const result = await plainFetch()
      const remote = new Map(result)
      // reset local
      setLocal(getResetLocal)
      return remote
    },
    useQueryProps: { enabled },
  })

  return { local, qResult, updateLocal }
}
