import { Box, Button, Icon, List, ListIcon, ListItem } from '@chakra-ui/react'
import { APIs } from '@paper/api-specs'
import { IcoChevronRight, IcoSecretDoor } from '@paper/icons'
import { useRouter } from '@paper/route'
import { Packetmeta } from '@paper/schema'
import { BigIconFontSize } from '@paper/styles'
import { produce } from 'immer'
import { useState } from 'react'
import { NotFoundError } from '~src/blocks/errors'
import { TabImageViewer } from '~src/blocks/imageViewer'
import { useActiveCurriculaPicker } from '~src/blocks/pickers'
import { TableContainer } from '~src/blocks/tableHelpers'
import {
  AppTitle,
  BaseHeader,
  ComboBox,
  HStack,
  Txt,
  VStack,
} from '~src/components'
import { FullPageLoading } from '~src/components/status'
import { ExpandeeProps, UghTable } from '~src/components/table'
import { useApiMutation, useApiQuery } from '~src/data/useApiQuery'
import { RD_Internal_Packetfix } from '~src/routelist'
import { useLimitToInternal } from '~src/utils/permissions'
import { WarningBullet } from '../publish/openPdf'
import { packetfixCols } from '../publish/publish/publishListCols'

export function SecretHeader({ title, children = null }) {
  title = `Secret ${title}`
  return (
    <BaseHeader mb={4} stackGap="2rem">
      <AppTitle title={title} />
      <Button onClick={() => window.history.back()} p={0} variant="ghost">
        <Icon as={IcoSecretDoor} fontSize={BigIconFontSize} />
      </Button>
      <Txt fontSize="lg">{title}</Txt>
      {children}
    </BaseHeader>
  )
}

export function PacketFixPage() {
  useLimitToInternal()

  const { dispatchStay, routeData } = useRouter<RD_Internal_Packetfix>()
  const { contentId } = routeData

  const [packets, setPackets] = useState<Packetmeta[]>()

  const qResult = useApiQuery({
    apiSpec: APIs['packet.fixer.get'],
    queryVars: { searchParams: { contentId } },
    queryFn: async ({ plainFetch }) => {
      const packets = await plainFetch()
      if (!packets?.length) {
        throw new NotFoundError({ thing: 'contentId', value: contentId })
      }
      // todo: probably not supposed to do this...
      setPackets(packets)

      return packets
    },
    useQueryProps: {},
  })

  // all packets have the same content
  const content = packets?.[0]?.content
  const selectedPacket = packets?.find((p) => p.id === routeData.packetId)

  // submitter
  const mutation = useApiMutation({
    apiSpec: APIs['packet.fixer.update'],
    useMutationProps: {
      onSuccess: () => window.location.reload(),
    },
  })

  const submitChanges = () => {
    // get packet subset
    let pkts = packets.map((p) => ({ id: p.id, curriculumId: p.curriculumId }))
    console.log('[fixer]submitting', { packets: pkts })

    mutation.mutate(
      { contentId: packets[0].contentId, packets: pkts },
      { onSuccess: () => window.location.reload() }
    )
  }

  const Expandee = (props: ExpandeeProps<Packetmeta>) => {
    const { item } = props
    const isPrinted = !!item._printed.first

    const comboProps = useActiveCurriculaPicker({
      excludeSet: new Set(qResult.data?.map((p) => p.curriculumId)),
    })

    return (
      <VStack p={4}>
        {isPrinted ? (
          <Txt>
            You can't change the curriculum once a packet has been printed.
          </Txt>
        ) : (
          <ComboBox
            {...comboProps}
            caret={true}
            onSelect={(c) =>
              setPackets(
                produce((draft) => {
                  draft.find((p) => p.id === item.id).curriculumId = c.id
                })
              )
            }
            placeholder="Change the curriculum"
            width="300px"
          />
        )}
      </VStack>
    )
  }

  return (
    <FullPageLoading qResult={qResult}>
      <BaseHeader.Container>
        <SecretHeader title="Packet Fixer" />
        <BaseHeader.Body overflowX="auto">
          {content && (
            <Box
              display="grid"
              gridColumnGap={8}
              gridTemplateColumns="640px 1fr"
              pb={8}
              px={8}
            >
              <TabImageViewer
                imageType="blank"
                pages={content.images}
                pkt={content}
              />
              <TableContainer fontSize="sm">
                <HStack gap={4} mb={4}>
                  <Button
                    alignSelf="start"
                    colorScheme="red"
                    onClick={submitChanges}
                    size="lg"
                  >
                    Submit
                  </Button>
                  <List fontSize="xs" spacing={1}>
                    <WarningBullet>
                      This tool is still an offroad stopgap, so be careful!
                    </WarningBullet>
                    <WarningBullet>
                      It's intended for the scenario where we accidentally
                      create a packet with the wrong curriculum and (fairly
                      immediately) realize and want to fix it.
                    </WarningBullet>
                    <ListItem ml={3}>
                      <ListIcon as={IcoChevronRight} />
                      In the non-secret app, you can recall-then-attach (to any
                      curriculum), but in some situations it may be preferable
                      to move the packet instead.
                    </ListItem>
                    <ListItem ml={3}>
                      <ListIcon as={IcoChevronRight} />
                      You should not use the tool if it's likely that others are
                      interacting with the packet (editing/publishing/printing)
                    </ListItem>
                    <WarningBullet>
                      After you press submit, the page will reload with the
                      (hopefully) newly updated curriculum.
                    </WarningBullet>
                    <ListItem ml={3}>
                      <ListIcon as={IcoChevronRight} />
                      If the values instead revert to the old values, then it
                      failed silently, probably 🐛
                    </ListItem>
                  </List>
                </HStack>
                <UghTable
                  aria-label="Publish list"
                  columns={packetfixCols}
                  data={packets}
                  Expandee={Expandee}
                  expandeeHeight={360}
                  onSelect={(item: Packetmeta) =>
                    dispatchStay({ packetId: item.id })
                  }
                  selectedId={selectedPacket?.id}
                  spacingX="normal"
                  spacingY="airy"
                />
              </TableContainer>
            </Box>
          )}
        </BaseHeader.Body>
      </BaseHeader.Container>
    </FullPageLoading>
  )
}
